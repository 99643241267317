import React, {useState} from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer, Pie, PieChart, Cell } from 'recharts';
import CountUp from 'react-countup';
import ShowerIcon from '@mui/icons-material/Shower';
import swsvLogo from '../../../../media/swsvTemp.png';
import mwsslogo from '../../../../media/mwsslogo.svg';

import './metricsStyle.css';

const pData = [{ name: "Gas GHG %", value: 92, fillV: '#fff' }, { name: "Elec GHG %", value: 6, fillV: '#eb4034' }, { name: "Water GHG %", value: 2, fillV: '#eb4034' }];

const data = [
    {
      "name": "Feb",
      "cumulative": 4000,
      "usage": 2400,
      "amt": 2612
    },
    {
      "name": "Mar",
      "cumulative": 5000,
      "usage": 2384,
      "amt": 2514
    },
    {
      "name": "Apr",
      "cumulative": 6000,
      "usage": 2145,
      "amt": 2451
    },
    {
      "name": "May",
      "cumulative": 7780,
      "usage": 3215,
      "amt": 3612
    },
    {
      "name": "Jun",
      "cumulative": 8890,
      "usage": 4410,
      "amt": 5124
    },
    {
      "name": "Jul",
      "cumulative": 9390,
      "usage": 4611,
      "amt": 5254
    },
    {
      "name": "Aug",
      "cumulative": 10490,
      "usage": 4894,
      "amt": 5841
    },
    {
        "name": "Sep",
        "cumulative": 11000,
        "usage": 4511,
        "amt": 5012
      },
      {
        "name": "Oct",
        "cumulative": 12000,
        "usage": 3521,
        "amt": 3812
      },
      {
        "name": "Nov",
        "cumulative": 13000,
        "usage": 2257,
        "amt": 2841
      },
      {
        "name": "Dec",
        "cumulative": 14080,
        "usage": 2312,
        "amt": 2741
      },
  ]

  const nData = [
    {
      "name": "Feb",
      "cumulative": 400,
      "usage": 840,
      "amt": 1141
    },
    {
      "name": "Mar",
      "cumulative": 500,
      "usage": 841,
      "amt": 1041
    },
    {
      "name": "Apr",
      "cumulative": 6000,
      "usage": 754,
      "amt": 874
    },
    {
      "name": "May",
      "cumulative": 7780,
      "usage": 354,
      "amt": 547
    },
    {
      "name": "Jun",
      "cumulative": 8890,
      "usage": 245,
      "amt": 455
    },
    {
      "name": "Jul",
      "cumulative": 9390,
      "usage":284,
      "amt": 441
    },
    {
      "name": "Aug",
      "cumulative": 10490,
      "usage": 274,
      "amt": 474
    },
    {
        "name": "Sep",
        "cumulative": 11000,
        "usage": 351,
        "amt": 501
      },
      {
        "name": "Oct",
        "cumulative": 12000,
        "usage": 425,
        "amt": 542
      },
      {
        "name": "Nov",
        "cumulative": 13000,
        "usage": 584,
        "amt": 754
      },
      {
        "name": "Dec",
        "cumulative": 14080,
        "usage": 754,
        "amt": 954
      },
  ]


const MetricsSlide = props => {
    return(
        <div className='metrics-slide-container'>
            <div className='metrics-slide-row-top'>

                <div className='metrics-slide-top-lf'>
                    <div className='metrics-slide-top-lf-1'>
                        <h4>Greenhouse Gas Reduction</h4>
                        <br/>
                        <div className="gauge">
                            <div class="needle"></div>
                            <div class="gauge-center">
                                <div class="label"></div>
                                <div class="number">
                                    <span><CountUp end={26} duration={1.1}/>%</span>
                                </div>
                            </div> 
                        </div>
                        <br/>
                        <p style={{fontSize:'75%', margin:'0'}}>That's a reduction of 30 lbs. of CO<sub>2</sub> per swimmer!</p>
                    </div>

                    <div className='metrics-slide-top-lf-2'></div>

                    <div className='metrics-slide-top-lf-3'>
                        <h4>Tracking Our Impact</h4>

                        <ResponsiveContainer width="95%" height="70%">
                            <PieChart height={200}>
                                <Pie
                                    data={pData}
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={75}
                                    fill="rgb(0,177,168)"
                                    dataKey="value"
                                    label={({
                                    cx,
                                    cy,
                                    midAngle,
                                    innerRadius,
                                    outerRadius,
                                    value,
                                    index
                                    }) => {
                                    console.log("handling label?");
                                    const RADIAN = Math.PI / 180;
                                    // eslint-disable-next-line
                                    const radius = 25 + innerRadius + (outerRadius - innerRadius);
                                    // eslint-disable-next-line
                                    const x = cx + radius * Math.cos(-midAngle * RADIAN);
                                    // eslint-disable-next-line
                                    const y = cy + radius * Math.sin(-midAngle * RADIAN);

                                    return (
                                        <text
                                        x={x}
                                        y={y}
                                        fill="#4e4f53"
                                        textAnchor={x > cx ? "start" : "end"}
                                        dominantBaseline="central"
                                        >
                                        {pData[index].name} ({value})
                                        {
          }
                                        </text>
                                    );
                                    }}
                                />
                            </PieChart>
                            {/* <PieChart>
                                    <Pie data={data02} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={35} outerRadius={50} fill="#82ca9d" label />
                            </PieChart> */}
                        </ResponsiveContainer>

                        <p style={{fontSize:'75%', margin:'0'}}>We monitor our utility consumption 24/7 to continuously reduce our footprint!</p>
                    </div>
                </div>

                <div style={{width:'1vw'}}></div>

                <div className='metrics-slide-top-md'>
                    <h4>Supporting Our Social Ventures</h4>
                    <p style={{fontSize:'75%', margin:'0'}}>Through partnership with Enviro-Stewards' Safe Water Social Ventures program, MWSS has helped:</p>
                    <br/>
                    <h5 className='swsv-metrics-slide'>provide <span className='swsv-metrics-slide' style={{color:'rgb(8,114,182)'}}>
                        <CountUp style={{fontSize:'1.72rem'}} end={10541} duration={1.1}/> </span>L of safe water</h5>
                        
                    <br/>

                    <h5 className='swsv-metrics-slide'>prevent <span className='swsv-metrics-slide' style={{color:'rgb(8,114,182)'}}>
                        <CountUp style={{fontSize:'1.72rem'}} end={250} duration={1.1}/> </span>tonnes of GHGs</h5>

                    <br/>

                    <h5 className='swsv-metrics-slide'>save <span className='swsv-metrics-slide' style={{color:'rgb(8,114,182)'}}>
                        <CountUp style={{fontSize:'1.72rem'}} end={3} duration={1.1}/> </span>hectares of forest</h5>

                    <br/>

                    <img style={{width:'40%', height:'auto', borderRadius:'5%', marginLeft:'15%', marginRight:'5%'}} src={swsvLogo} />
                    {/* <div style={{width:'5%'}}></div> */}
                    <img src={mwsslogo} style={{width:'20%', height:'auto'}}/>
                </div>

                <div style={{width:'1vw'}}></div>

                <div className='metrics-slide-top-rt'>
                    <h4>Water Conservation</h4>

                    <div style={{height:'75%', width:'100%', display:'flex', flexWrap:'wrap'}}>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>
                        <ShowerIcon style={{height:'5vh', width:'auto', color:'rgb(8,114,182)'}}/>                    
                    </div>

                    <p style={{fontSize:'75%', margin:'0'}}>We saved more than <strong>6.1 MILLION</strong> litres of water last year. The average shower consumes around 100 L. Each showerhead represents 10
                    showers worth of water saved.</p>
                </div>
            </div>

            <div className='metrics-slide-row-md'></div>

            <div className='metrics-slide-row-bottom'>
                <div className='metrics-slide-bottom-lf'>
                    <div style={{height:'fit-content', width:'80%', display:'flex', flexDirection:'row'}}>
                        <h4 style={{marginBottom:'5px', width:'11vw'}}>Electricity</h4>
                        
                        <div style={{display:'flex', flexDirection:'row'}}>
                                <div style={{width:'12vw', color:'rgb(0,177,168)'}}>Usage this year (kWh)</div>
                                <div style={{width:'12vw', color:'rgb(8,114,182)'}}>Usage last year (kWh)</div>
                        </div>
                    </div>
                    
                    <ResponsiveContainer height='98%' width='100%'>
                        <BarChart data={data} >
                            <CartesianGrid stroke="#f5f5f5"/>
                            <XAxis dataKey="name" />
                            <YAxis orientation='left' axisLine={false}/>
                            <Bar dataKey="usage" fill="rgb(0,177,168)" />
                            <Bar dataKey="amt" fill="rgb(8,114,182)" />
                        </BarChart>
                    </ResponsiveContainer>
                </div>

                <div style={{width:'1vw'}}></div>

                <div className='metrics-slide-bottom-rt'>
                    <div style={{height:'fit-content', width:'80%', display:'flex', flexDirection:'row'}}>
                        <h4 style={{marginBottom:'5px', width:'11vw'}}>Natural Gas</h4>
                        
                        <div style={{display:'flex', flexDirection:'row'}}>
                                <div style={{width:'12vw', color:'rgb(0,177,168)'}}>Usage this year (m<sup>3</sup>)</div>
                                <div style={{width:'12vw', color:'rgb(8,114,182)'}}>Usage last year (m<sup>3</sup>)</div>
                        </div>
                    </div>
                    
                    <ResponsiveContainer height='98%' width='100%'>
                        <BarChart data={nData} >
                            <CartesianGrid stroke="#f5f5f5"/>
                            <XAxis dataKey="name" />
                            <YAxis orientation='left' axisLine={false}/>
                            <Bar dataKey="usage" fill="rgb(0,177,168)" />
                            <Bar dataKey="amt" fill="rgb(8,114,182)"  />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    )
}

export default MetricsSlide;