import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';

const ReviewPage = props => {
    return (
        <>
        <TableContainer style={{backgroundColor:'white', boxShadow:'2px 6px 12px rgb(164, 164, 164)', flex:'4'}}>
            <Table sx={{
                [`& .${tableCellClasses.root}`]: {
                borderBottom: "none"
                }
            }} aria-label="simple table">
                <TableBody>
                    <TableRow>
                        <Button onClick={() => {
                            const link = document.createElement('a');
                            link.href = `${process.env.PUBLIC_URL}/pricing_proposal_template.docx`;
                            link.download = 'pricing_proposal_template.docx';
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }}>Generate Proposal Template</Button>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2}><strong>Contract details</strong></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Duration</TableCell>
                        <TableCell>
                            {`${props.contractSpecs.contractYears} year${props.contractSpecs.contractYears === 1 ? '' : 's'} (${props.contractSpecs.contractYears * 12} months)`}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2}>{`${props.contractSpecs.meetingsPerYear} meeting${props.contractSpecs.meetingsPerYear === 1 ? '' : 's'} per year`}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2}><strong>Payment details</strong></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Payment interval</TableCell>
                        <TableCell>
                            {props.paymentSpecs.frequency.value.substring(0,1).toUpperCase()}{props.paymentSpecs.frequency.value.substring(1)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Method</TableCell>
                        <TableCell>
                            {props.paymentSpecs.method.value.substring(0,1).toUpperCase()}{props.paymentSpecs.method.value.substring(1)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Terms</TableCell>
                        <TableCell>
                            {props.paymentSpecs.terms.value.substring(0,1).toUpperCase()}{props.paymentSpecs.terms.value.substring(1)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Total contract cost</TableCell>
                        <TableCell>
                            {`${(Math.round(12 * props.contractSpecs.contractYears * 100 * props.computeTotalCost(props.paymentSpecs.frequency.value === 'upfront')) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
        </>
    );
}

export default ReviewPage;