import React, {useState, useEffect} from 'react';
import LeakGraph from './compressed_air/LeakGraph';
import SystemNotes from './compressed_air/SystemNotes';
import AmpTrends from './compressed_air/AmpTrends';
import PressureGauge from './compressed_air/PressureGauge';
import { IconButton } from '@mui/material';
import { HelpCenter, InfoRounded } from '@mui/icons-material';
import LoadPieChart from './compressed_air/LoadPieChart';
import CompressorStagingTrend from './compressed_air/CompressorStagingTrend';

const MaintenanceContainer = props => {
    const [ctrHeight, setCtrHeight] = useState(0);

    const handleCtrResize = () => {
        try{
            let navbarHeight = document.getElementsByClassName('navbar navbar-expand-lg navbar-light sticky-top')[0].offsetHeight;
            setCtrHeight(window.innerHeight - navbarHeight);
            
        }catch(err){
            setCtrHeight(window.innerHeight);
        }
    }

    useEffect(() => {
        handleCtrResize();
        window.addEventListener('resize', handleCtrResize);

        return () => {
            window.removeEventListener('resize', handleCtrResize);
        }
    }, []);

    return(
        <div style={{padding:'2vh 2vw 0 2vw', minHeight:`${ctrHeight}px`, maxHeight:'fit-content', backgroundColor:'#f5f5f5'}}>

            <div style={{height:`${ctrHeight / 3.25}px`, display:'flex', flexDirection:'row', padding:'0.5%'}}>
                <div style={{flex:'50', backgroundColor:'white', boxShadow:'2px 6px 12px rgb(164, 164, 164)', borderRadius:'1%'}}>
                    <LeakGraph/>
                </div>

                <div style={{flex:'1'}}></div>

                <div style={{flex:'50', backgroundColor:'white', boxShadow:'2px 6px 12px rgb(164, 164, 164)', borderRadius:'1%'}}>
                    <SystemNotes/>
                </div>

            </div>

            <div style={{height:'fit-content', display:'flex', flexDirection:'row', padding:'0.5%'}}>
                <div style={{flex:'110', backgroundColor:'white', boxShadow:'2px 6px 12px rgb(164, 164, 164)', borderRadius:'1%'}}>
                    <AmpTrends/>
                </div>

                <div style={{flex:'1'}}></div>

                <div style={{flex:'30', backgroundColor:'white', boxShadow:'2px 6px 12px rgb(164, 164, 164)', borderRadius:'2%'}}>
                    <IconButton style={{position:'absolute'}}>
                        <InfoRounded/>
                    </IconButton>
                    <PressureGauge/>
                </div>
            </div>

            <div style={{height:`${ctrHeight / 3.25}px`, display:'flex', flexDirection:'row', padding:'0.5%'}}>
                <div style={{flex:'30', backgroundColor:'white', boxShadow:'2px 6px 12px rgb(164, 164, 164)', borderRadius:'2%'}}>
                    <LoadPieChart/>
                </div>

                <div style={{flex:'1'}}></div>

                <div style={{flex:'100', backgroundColor:'white', boxShadow:'2px 6px 12px rgb(164, 164, 164)', borderRadius:'1%'}}>
                    <CompressorStagingTrend/>
                </div>
            </div>
        </div>
    );
}

export default MaintenanceContainer;