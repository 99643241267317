import React, { useEffect, useState} from "react";
import { Button, Modal,  } from "react-bootstrap";
import { TextField, Box, InputLabel, Select, MenuItem, FormControl, Snackbar, IconButton } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import countries from '../../../example_data/dropdowns/clientModal/countries';
import provinces from '../../../example_data/dropdowns/clientModal/provinces';
import states from '../../../example_data/dropdowns/clientModal/states';
import gas_types from '../../../example_data/dropdowns/clientModal/gasTypes';
import useAxiosProtect from "../../../hooks/useAxiosProtect";

// GHG Modal
const GHGModal = props => {
    const axiosProtect = useAxiosProtect();

    // GHG Factor Variables
    const [territory, setTerritory] = useState('');
    const [territoryList, setTerritoryList] = useState(provinces);
    const [country, setCountry] = useState('');
    const [water_kg_co2_m3, setWater] = useState(''); // convention: <name>, <setName> (e.g., [water, setWater])
    const [elec_kg_co2_kwh, setElec] = useState('');
    const [gas_kg_co2_btu, setGas] = useState('');
    const [gas_type, setGasType] = useState('');
    const [gasTypeList, setGasTypeList] = useState(gas_types)
    const [posted_year, setPostedYear] = useState('');

    // Errors
    const [territoryErr, setTerritoryErr] = useState('');
    const [countryErr, setCountryErr] = useState('');
    const [waterErr, setWaterErr] = useState('');
    const [elecErr, setElecErr] = useState('');
    const [gasErr, setGasErr] = useState('');
    const [gasTypeErr, setGasTypeErr] = useState('');
    const [postedYearErr, setPostedYearErr] = useState('');

    // Snackbar
    const [openSnack, setSnackOpen] = useState(false);

    const changeCountry = e => {
        if(e.target.value === 'Canada') {
            setTerritoryList(provinces)
        } else if (e.target.value === 'USA') {
            setTerritoryList(states)
        } else {
            setTerritoryList([])
        }

        setTerritory('');
        setCountry(e.target.value);
    }

    const floatRegex = /^(|\d*\.?\d+|\d+\.)$/;

    const changeWater = e => {
        if(e.target.value === '') setWater('');
        else if(floatRegex.test(e.target.value)) setWater(e.target.value);
    }

    const changeElec = e => {
        if(e.target.value === '') setElec("");
        else if(floatRegex.test(e.target.value)) setElec(e.target.value);
    }

    const changeGas = e => {
        if(e.target.value === '') setGas("");
        else if(floatRegex.test(e.target.value)) setGas(e.target.value);
    }

    const changeGasType = e => {
        setGasType(e.target.value);
    }

    const changePostedYear = e => {
        const intRegex = /^(|\d+)$/; // only allows digits and ""

        if(intRegex.test(e.target.value)) setPostedYear(e.target.value);
    }

    // Close model to refresh data
    const closeModal = () => {
        setTerritory('')
        setCountry('')
        setWater('')
        setElec('')
        setGas('')
        setGasType('')
        setPostedYear('')

        resetErrors()

        props.toggleModalState(undefined);
    }

    const resetErrors = () => {
        setTerritoryErr('')
        setCountryErr('')
        setWaterErr('')
        setElecErr('')
        setGasErr('')
        setGasTypeErr('')
        setPostedYearErr('')
    }

    const isValidForm = () => {

        let isValid = true;
        // resetErrors() // setState hooks are async, reset conditionally to ensure synchronous behaviour

        if(country === ''){
            setCountryErr('Required');
            isValid = false;
        }else setCountryErr('');

        if (territory === ''){
            setTerritoryErr('Required');
            isValid = false;
        }else setTerritoryErr('');

        if(isNaN(parseFloat(water_kg_co2_m3))){
            setWaterErr('Invalid');
            isValid = false;
        }else setWaterErr('');

        if(isNaN(parseFloat(elec_kg_co2_kwh))){
            setElecErr('Invalid');
            isValid = false;
        }else setElecErr('');

        if(isNaN(parseFloat(gas_kg_co2_btu))){
            setGasErr('Invalid');
            isValid = false;
        }else setGasErr('');

        if (gas_type === ''){
            setGasTypeErr('Required');
            isValid = false;
        }else setGasTypeErr('');

        if(isNaN(parseInt(posted_year))){
            setPostedYearErr('Invalid');
            isValid = false;
        }else setPostedYearErr('');

        return isValid;
    }

    const createGHGFactors = async (event) => {
        // Stop from refreshing page after submit
        event.preventDefault();
        // Validate form
        if (isValidForm()) {
            try {
                const response = await axiosProtect.post('/ghg/ghg-factors', {
                    country: country,
                    territory: territory,
                    water_kg_co2_m3: parseFloat(water_kg_co2_m3),
                    elec_kg_co2_kwh: parseFloat(elec_kg_co2_kwh),
                    gas_kg_co2_btu: parseFloat(gas_kg_co2_btu),
                    gas_type: gas_type,
                    posted_year: parseInt(posted_year)
                });
                // Add the new row to the table
                props.setFactors(factors => [...factors, response.data.ghg_factor])
                closeModal()
            } catch(err) {
                console.log(err.response.data)
            }
        } else {
            console.log('Form is incorrect')
        }
    }

    const editGHGFactor = async (event) => {
        // Stop from refreshing page after submit
        event.preventDefault();
        // Validate form
        if (isValidForm()) {
            try {
                const response = await axiosProtect.put(`/ghg/ghg-factors?id=${props.editRow.id}`, {
                    country: country,
                    territory: territory,
                    water_kg_co2_m3: parseFloat(water_kg_co2_m3),
                    elec_kg_co2_kwh: parseFloat(elec_kg_co2_kwh),
                    gas_kg_co2_btu: parseFloat(gas_kg_co2_btu),
                    gas_type: gas_type,
                    posted_year: parseInt(posted_year)
                });
                // Get rid of old row and put updated row into the table
                props.removeRow(props.editRow.id)
                props.setFactors(factors => [...factors, response.data.update])
                closeModal()
            } catch(err) {
                console.log(err.response.data)
            }
        } else {
            console.log('Form is incorrect')
        }
    }

    const deleteRow = async () => {
        const controller = new AbortController();

        try{
            const response = await axiosProtect.delete(`/ghg/ghg-factors?id=${props.delRow.id}`, {
                signal: controller.signal,
            });

            if(response.status === 200){
                props.removeRow(props.delRow.id)
            }

            closeModal();
        }catch(err){
            console.log(err)
            // If the row can't be deleted, notify the user
            if (err.response.status === 500) {
                setSnackOpen(true)
            }
        }
    }

    // For Autocomplete functionality
    const filter = createFilterOptions();
  
    useEffect(() => {
        // Update form with default values for editing
        if (props.factorModalState === 'edit') {
            setEditFactors()
        }
    }, [props.factorModalState])

    const setEditFactors = () => {
        setCountry(props.editRow.country)
        setTerritory(props.editRow.territory)
        setWater(props.editRow.water)
        setElec(props.editRow.elec)
        setGas(props.editRow.gas)
        setGasType(props.editRow.gas_type)
        setPostedYear(props.editRow.year)
    }

    // Close the snackbar
    const closeSnack = () => {
        setSnackOpen(false)
    }
    // Delete the snackbar
    const snackAction = (
        <>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={closeSnack}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
    );


    

    return (
        <>
        <Modal show={props.factorModalState !== undefined} onHide={() => closeModal()}>
            <Modal.Header closebutton="true">
                <Modal.Title>{props.factorModalState === 'add' ? "Add Factors" 
                            : props.factorModalState === 'delete' ? "Delete Factors" 
                            : props.factorModalState === 'edit' ? "Edit Factors": ""}</Modal.Title>
            </Modal.Header>
            {props.factorModalState === 'add' || props.factorModalState === 'edit' ?
                <>
                <form onSubmit={props.factorModalState === 'add' ? createGHGFactors : editGHGFactor }>
                <Modal.Body>
                    <FormControl style={{width:'100%', marginBottom:'1.25vh'}}>
                        <InputLabel id="demo-simple-select-label">Country</InputLabel>
                        <Select
                            id="es-client-add-sector"
                            value={country}
                            label="Country"
                            onChange={changeCountry}
                            error={countryErr !== ''}
                            helperText={countryErr} 
                        >
                            {countries.map((_, i) => (
                                <MenuItem value={countries[i]} key={i}>{countries[i]}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {(country === "Canada" || country === "USA") ? <Autocomplete
                        value={territory}
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                setTerritory(newValue);
                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                setTerritory(newValue.inputValue);
                            } else {
                                setTerritory(newValue);
                            }
                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option.title);
                            if (inputValue !== '' && !isExisting) {
                                filtered.push(inputValue);
                            }
                            return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        id="territory"
                        options={territoryList}
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                            return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                            return option.inputValue;
                            }
                            // Regular option
                            return option;
                        }}
                        renderOption={(props, option) => <li {...props} >{option}</li>}
                        freeSolo
                        renderInput={(params) => (
                            <TextField {...params} 
                            label="Territory" 
                            style={{width:'100%', marginBottom:'1.25vh'}}
                            error={territoryErr !== ''}
                            helperText={territoryErr}/>
                        )}
                    /> : 
                    
                    <TextField
                        label="Territory"
                        variant="outlined"
                        name="territory"
                        value={territory}
                        onChange={e => setTerritory(e.target.value)}
                        error={waterErr !== ''}
                        helperText={waterErr}
                        style={{width:'100%', marginBottom:'1.25vh'}}
                    />}
                
                    <TextField
                        label="Water kg-co2/m3"
                        variant="outlined"
                        name="water_kg_co2_m3"
                        value={water_kg_co2_m3}
                        onChange={e => changeWater(e)}
                        error={waterErr !== ''}
                        helperText={waterErr}
                        style={{width:'100%', marginBottom:'1.25vh'}}
                    />
                    <br />
                    <TextField
                        label="Elec kg-co2/kwh"
                        variant="outlined"
                        name="elec_kg_co2_kwh"
                        value={elec_kg_co2_kwh}
                        onChange={e => changeElec(e)}
                        error={elecErr !== ''}
                        helperText={elecErr}
                        style={{width:'100%', marginBottom:'1.25vh'}}
                    />
                    <br />
                    <TextField
                        label="Gas kg-co2/btu"
                        variant="outlined"
                        name="gas_kg_co2_btu"
                        value={gas_kg_co2_btu}
                        onChange={e => changeGas(e)}
                        error={gasErr !== ''}
                        helperText={gasErr}
                        style={{width:'100%', marginBottom:'1.25vh'}}
                    />
                    <br />
                    
                    <FormControl style={{width:'100%', marginBottom:'1.25vh'}}>
                        <InputLabel id="demo-simple-select-label">Gas Type</InputLabel>
                        <Select
                            id="es-client-add-sector"
                            value={gas_type}
                            label="Gas Type"
                            onChange={changeGasType}
                            error={gasTypeErr !== ''}
                            helperText={gasTypeErr}
                        >
                            {gasTypeList.map((_, i) => (
                                <MenuItem value={gasTypeList[i][0]} key={i}>{gasTypeList[i][1]}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <br />
                    <TextField
                        label="Posted Year"
                        variant="outlined"
                        name="posted_year"
                        type="number"
                        value={posted_year}
                        onChange={e => changePostedYear(e)}
                        error={postedYearErr !== ''}
                        helperText={postedYearErr}
                        style={{width:'100%', marginBottom:'1.25vh'}}
                    />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant='primary'
                        style={{backgroundColor: 'rgb(8,114,182)'}}
                        type='submit'>
                            {props.factorModalState === 'add' ? 'Create' : 'Edit'}
                    </Button>
                </Modal.Footer>
                </form>
                </>
            
            : props.factorModalState === 'delete' ?
            <>
            <Modal.Body>
            <Box>
                <p>Are you sure you want to delete the following row?</p>
                <p>
                    {props.delRow.country} - {props.delRow.territory} - {props.delRow.year}
                </p>
            </Box>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='danger' 
                        style={{backgroundColor:'rgb(213,43,0)'}}
                        onClick={() => { 
                            if (props.factorModalState === 'delete') {
                                deleteRow() 
                            }
                        }}
                        >Delete
                    </Button>
            </Modal.Footer>
            </>
            : <div></div>
            }
        </Modal>
        <Snackbar
            open={openSnack}
            autoHideDuration={6000}
            onClose={closeSnack}
            action={snackAction}
            message="This row can't be deleted!"
        />

        </>
      );
  }

export default GHGModal;