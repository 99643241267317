import React, { useEffect, useRef } from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import './BreadcrumbStyle.css';

const BreadcrumbBar = props => {
  var init = useRef(true);

  useEffect(() => {
    if(!init.current) return;
    init.current = false;
  }, []);

  return(
    <div className="col s12 es-breadcrumb" style={{backgroundColor:'rgb(0,177,168)'}}>
      <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon style={{color:'whitesmoke'}} fontSize="small" />} style={{ width:'fit-content', margin:'auto'}}>
        {
          props.currentPath.map((crumb, i) => {
            if(i === props.currentPath.length - 1){
              return(
                <Typography
                  sx={{ display: 'flex', alignItems: 'center', color:'whitesmoke' }}
                >
                  {crumb.icon}
                  {crumb.title}
                </Typography>
              )
            }else{
              return(
                <Link
                  underline="hover"
                  sx={{ display: 'flex', alignItems: 'center',
                  "&:hover": {
                    color: "white",
                    textDecoration: "underline white"
                  }}}
                  color="whitesmoke"
                  href={crumb.linkURL}
                >
                  {crumb.icon}
                  {crumb.title}
                </Link>
              )
            }
          })
        }
      </Breadcrumbs>
    </div>
  );
}

export default BreadcrumbBar;