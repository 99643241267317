import React from 'react';
import { Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, ComposedChart, Line, Legend } from 'recharts';

const OppSavingsGraph = props => {
    const formatXAxisTick = (tick) => {
        const date = new Date(tick);
        const year = date.getFullYear();
        const month = date.toLocaleString('default', { month: 'numeric' });
        const day = date.getDate();
    
        return `${day}/${month}/${year}`;
    };

    return (
        <ResponsiveContainer height='100%' width='100%'>
            <ComposedChart data={props.savingsData}>
                <XAxis dataKey="time" tickLine={false} axisLine={false} tick={{ fontSize: '14px', fill: 'rgb(78,79,83)'}} 
                        textAnchor='middle' tickFormatter={formatXAxisTick}/>

                <YAxis yAxisId="left" tickLine={false} axisLine={false} tick={{ fontSize: '14px', fill: 'rgb(78,79,83)'}}
                    label={{value: `${props.type === 'ghg' ? 'GHG' : 'Financial'} savings (${props.type === 'ghg' ? 'kg CO2' : '$'})`,
                    position: 'outside', offset: 0, angle: -90, dx: -24, style: { fontSize: '12px', fill: 'rgb(78,79,83)'}}}/>
                
                <YAxis yAxisId="right" orientation='right' tickLine={false} axisLine={false} tick={{ fontSize: '14px', fill: 'rgb(78,79,83)'}}
                    label={{value: `Total savings realized (${props.type === 'ghg' ? 'kg CO2' : '$'})`,
                    position: 'outside', offset: 0, angle: 90, dx: 24, style: { fontSize: '12px', fill: 'rgb(78,79,83)' }}}
                />

                <Legend verticalAlign='top' iconType='line' align='center' iconSize={6} wrapperStyle={{fontSize: "0.85rem"}}
                    payload={
                        [
                            {value: `Daily savings (${props.type === 'ghg' ? 'GHG' : 'Financial'})`, type: 'circle', color:'rgb(8,114,182)'},
                            {value: `Total savings (${props.type === 'ghg' ? 'GHG' : 'Financial'})`, type: 'circle', color:'rgb(0,177,168)'}
                        ]
                    }
                />

                <CartesianGrid stroke="#f5f5f5"/>
                
                <Bar yAxisId="left" dataKey={props.type} fill={'rgb(8,114,182)'}  />
                <Line yAxisId="right" type="monotone" dataKey={`${props.type}Sum`} strokeWidth={2} stroke={'rgb(0,177,168)'} dot={false}/>
            </ComposedChart>
        </ResponsiveContainer>
    );
}

export default OppSavingsGraph;