import React, { useState } from "react";
import FlowieComponent from './alertLabs/FlowieComponent';
import CentricaRegistry from './centrica/CentricaRegistry';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GasRegistry from "./gas/GasRegistry";

const SensorContainer = props => {
    const [flowieCount, setFlowieCount] = useState(null);
    const [ctCount, setCTCount] = useState(null);
    const [gasCount, setGasCount] = useState(null);

    const updateFlowieCount = (inc, value) => {
        let temp = flowieCount;
        if(temp === null){
            setFlowieCount(value);
            return;
        }

        temp += inc ? value : -1 * value;
        setFlowieCount(temp);
    }

    const updateCTCount = (inc, value) => {
        let temp = ctCount;
        if(temp === null){
            setCTCount(value);
            return;
        }

        temp += inc ? value : -1 * value;
        setCTCount(temp);
    }

    const updateGasUnitCount = (inc, value) => {
        let temp = gasCount;
        if(temp == null){
            setGasCount(value);
            return;
        }

        temp += inc ? value : -1 * value;
        setGasCount(temp);
    }

    return(
        <div style={{width: '100%', padding:'1%'}}>
            <Accordion defaultExpanded>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                    <h4>{`Flowies${flowieCount ? ` (${flowieCount})` : ''}`}</h4>
                </AccordionSummary>
                <AccordionDetails>
                    <FlowieComponent updateFlowieCount={updateFlowieCount}/>
                </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                >
                    <h4>{`Current transducers${ctCount ? ` (${ctCount})` : ''}`}</h4>
                </AccordionSummary>
                <AccordionDetails>
                    <CentricaRegistry updateCTCount={updateCTCount}/>
                </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
                >
                    <h4>{`Gas units${gasCount ? ` (${gasCount})` : ''}`}</h4>
                </AccordionSummary>
                <AccordionDetails>
                    <GasRegistry updateGasUnitCount={updateGasUnitCount}/>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export default SensorContainer;