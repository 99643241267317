import React, {useState, useEffect} from 'react';
// ui deps
import './landingstyle.css';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ESLogo from '../../media/logo.png';
import FooterLogo from '../../media/flogo.png';
// auth deps
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';
import jwtDecode from 'jwt-decode';
import { useNavigate, useLocation } from 'react-router-dom';
import ForgotPasswordModal from './ForgotPasswordModal';

const LandingPage = props => {
    // ui functionality
    const [values, setValues] = useState({
      amount: '',
      password: '',
      weight: '',
      weightRange: '',
      showPassword: false,
    });

    const handleClickShowPassword = () => {
      setValues({
        ...values,
        showPassword: !values.showPassword,
      });
    };

    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    // auth functions
    const {auth, setAuth, persist, setPersist} = useAuth();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailErr, setEmailErr] = useState("");
    const [passErr, setPassErr] = useState("");

    const [forgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/dashboard";

    useEffect(() => {
      if(auth?.accessToken && auth?.role){
        // navigate(`/dashboard`,
        // // navigate(`/${(auth.role === process.env.REACT_APP_ES_USER || auth.role === process.env.REACT_APP_ES_ADMIN) ? 'staff' : 'dashboard'}`,
        // {replace: true})

        window.location.replace('/dashboard');
      }

    }, [navigate]);

    useEffect(() => {
      setPersist(true);
      localStorage.setItem("persist", true);
    }, []);

    const validateInput = () => {
      let isValid = true;

      if(email === ''){
        setEmailErr('Required');
        isValid = false;
      }else{
        setEmailErr('');
      }

      if(password === ''){
        setPassErr('Required');
        isValid = false;
      }else{
        setPassErr('');
      }

      return isValid;
    }

    const handleSubmit = async (event) => {
      event.preventDefault();

      let isValid = validateInput();
      if(!isValid) return;

      try{
        let payload = {email, password}
        let headers = {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
      };

        let response = await axios.post('/auth/login', payload, headers);

        let accessToken = response.data;
        let tokenObj = jwtDecode(accessToken);
        let userEmail = tokenObj.email;
        let id = tokenObj.id;
        let org_id = tokenObj.org_id;
        let role = tokenObj.role;

        setAuth({ email: userEmail, id, org_id, role, accessToken });
        setEmail('');
        setPassword('');

        // navigate((role == process.env.REACT_APP_ES_USER || role === process.env.REACT_APP_CLIENT_ADMIN ||
        //   role == process.env.REACT_APP_ES_ADMIN || role === process.env.REACT_APP_CLIENT_STAFF) ? "/dashboard" : from, {replace: true});
        
        window.location.replace((role == process.env.REACT_APP_ES_USER || role === process.env.REACT_APP_CLIENT_ADMIN ||
          role == process.env.REACT_APP_ES_ADMIN || role === process.env.REACT_APP_CLIENT_STAFF) ? "/dashboard" : from)
        
      }catch(err){
        console.log(err)
        if(err.response.status === 400){
          setEmailErr(err.response.data);
        }
      }
    }

    return(
        <div id='landingbackground'>
          {forgotPasswordModalOpen && <ForgotPasswordModal forgotPasswordModalOpen={forgotPasswordModalOpen} setForgotPasswordModalOpen={setForgotPasswordModalOpen}/>}
          <img src={FooterLogo} style={{position:'fixed', height:'10vh', width:'auto', margin:'auto', left:'1%', top:"2%"}}/>

          {/* <h4 id='guest-fct' style={{position:'fixed', bottom:'0', right:'0', color:'white', marginBottom:'2vh',marginRight:'2vw'}}
            onMouseEnter={() => {
              document.getElementById('guest-fct').style.textDecoration = 'underline';
              document.body.style.cursor = "pointer";
              
            }}
            onMouseLeave={() => {
              document.getElementById('guest-fct').style.textDecoration = 'none';
              document.body.style.cursor = "auto";
              
            }}
            onClick={() => window.location.href='/guest'}
          >
            Continue as guest
          </h4> */}

          <div id='auth-box' style={{textAlign:'center'}}>
              <img src={ESLogo} style={{height:'10vh', width:'auto', marginBottom:"2%"}}/>

              <Box sx={{ '& > :not(style)': { m: 1 } }} component='form' onSubmit={handleSubmit}>
                  <TextField
                      id="rmis-email"
                      label="Email"
                      error={emailErr !== ''}
                      helperText={emailErr}
                      onChange={e => setEmail(e.target.value)}
                      style={{width:"70%"}}
                      variant="outlined"
                  />
                  <br/>
                  <TextField
                      id="rmis-password"
                      label="Password"
                      error={passErr !== ''}
                      helperText={passErr}
                      onChange={e => setPassword(e.target.value)}
                      style={{width:"70%"}}
                      type={values.showPassword ? 'text' : 'password'}
                      InputProps={{
                      endAdornment: (
                          <InputAdornment position="start">
                              <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              >
                              {values.showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                          </InputAdornment>
                      ),
                      }}
                      variant="outlined"
                  />
                  <br/>
                  <Button style={{width:"70%"}} variant="contained" type='submit'>Sign in</Button>
                  
                  <br/>
                  <small id="pass-reset" onClick={() => setForgotPasswordModalOpen(true)}>Forgot password?</small>
              </Box>
          </div>
      </div>
    )
}

export default LandingPage;