import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import OppSavingsGraph from '../graphs/OppSavingsGraph';
import ESLogo from '../../../media/logo.png';

const OpportunityPage = props => {      
    const computeAnnualGHGSavings = () => {
        let ghgKgs = props.opp.water_savings_m3_year * props.opp.water_kg_co2_m3 + 
        props.opp.electricity_savings_kwh_year * props.opp.electricity_kg_co2_kwh + 
        props.opp.gas_savings_units_year * props.opp.gas_kg_co2_btu * props.opp.gas_btu_per_unit;

        return ghgKgs / 1000;
    }

    const computeAnnualCostSavings = () => {
        return props.opp.water_savings_m3_year * props.opp.water_unit_cost_m3 + 
        props.opp.electricity_savings_kwh_year * props.opp.electricity_unit_cost_kwh + 
        props.opp.gas_savings_units_year * props.opp.gas_unit_cost;
    }

    return(
        <div className="rmis-report-opps" style={{height:'11in', width:'8.5in', margin:'auto', backgroundColor:'white', padding: '1in 0.75in', display:'flex', flexDirection:'column'}}>
            {props.firstPage && 
            <>
            <h4 style={{fontWeight:'bold', fontSize:'1.78rem', color:'rgb(8,114,182)', flex:'1'}}>Implemented opportunities.</h4>
            </>}

            <div style={{height:'0.3in', width:'7.5in', position:'absolute', marginTop:'9.5in', display:'flex', flexDirection:'row'}}>
                <img src={ESLogo} style={{height:'100%', width:'auto'}}/>
                <div style={{flex:'6'}}></div>
                <p style={{margin:'0', flex:'1', fontSize:'0.9rem'}}>page {props.page}</p>
            </div>
            
            <h4 style={{fontSize:'1.3rem', color:'rgb(78,79,83)', fontWeight:'bold', flex:'1'}}>{props.oppTitle}</h4>
            
            <div style={{flex:'15'}}>
                <Table sx={{width:'100%', backgroundColor:'white', borderRadius:'1%', boxShadow:'none'}} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <strong>Parameter</strong>
                            </TableCell>
                            <TableCell align="center">
                                <strong>Annual projected savings rate</strong>
                            </TableCell>
                            <TableCell align="center">
                                <strong>Savings realized in timeframe</strong>
                            </TableCell>
                            <TableCell align="center">
                                <strong>Savings realized to date (annualized)</strong>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.opp.has_water && <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="tr" scope="row">Water savings (m<sup>3</sup>)</TableCell>
                            <TableCell align="center">{Math.round(props.opp.water_savings_m3_year).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/yr</TableCell>
                            <TableCell align="center">{Math.round(props.opp.savingsData.totalWaterSavings).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                            <TableCell align="center">{Math.round(365 * props.opp.savingsData.totalWaterSavings / props.opp.savingsData.tsData.length).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/yr</TableCell>
                        </TableRow>}
                        {props.opp.has_electricity && <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="tr" scope="row">Electricity savings (kWh)</TableCell>
                            <TableCell align="center">{Math.round(props.opp.electricity_savings_kwh_year).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/yr</TableCell>
                            <TableCell align="center">{Math.round(props.opp.savingsData.totalElectricitySavings).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                            <TableCell align="center">{Math.round(365 * props.opp.savingsData.totalElectricitySavings / props.opp.savingsData.tsData.length).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/yr</TableCell>
                        </TableRow>}
                        {props.opp.has_gas && <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="tr" scope="row">Gas savings (m<sup>3</sup>)</TableCell>
                            <TableCell align="center">{Math.round(props.opp.gas_savings_units_year).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/yr</TableCell>
                            <TableCell align="center">{Math.round(props.opp.savingsData.totalGasSavings).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                            <TableCell align="center">{Math.round(365 * props.opp.savingsData.totalGasSavings / props.opp.savingsData.tsData.length).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/yr</TableCell>
                        </TableRow>}
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="tr" scope="row">GHG reduction (MT CO<sub>2</sub>e)</TableCell>
                            <TableCell align="center">{Number(Math.round(computeAnnualGHGSavings() * 100)/ 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/yr</TableCell>
                            <TableCell align="center">{Number(Math.round(props.opp.savingsData.totalGHGSavings * 100) / 1000 / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                            <TableCell align="center">{Number((Math.round(365 * props.opp.savingsData.totalGHGSavings * 100 / props.opp.savingsData.tsData.length) / 1000 / 100)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/yr</TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="tr" scope="row" >Financial savings</TableCell>
                            <TableCell component="tr" align="center">${Math.round(computeAnnualCostSavings()).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/yr</TableCell>
                            <TableCell component="tr" align="center">${Math.round(props.opp.savingsData.totalCostSavings).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                            <TableCell component="tr" align="center">${Math.round(365 * props.opp.savingsData.totalCostSavings / props.opp.savingsData.tsData.length).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/yr</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

                <br/>

                <div style={{height:'32%'}}>
                    <OppSavingsGraph savingsData={props.opp.savingsData.tsData} type='ghg'/>
                </div>

                <br/>
                
                <div style={{height:'32%'}}>
                    <OppSavingsGraph savingsData={props.opp.savingsData.tsData} type='cost'/>
                </div>
            </div>
        </div>
    );
}

export default OpportunityPage;