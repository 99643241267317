import React, { useEffect, useRef, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ESLogo from '../../../media/logo.png';

const SummaryPage = props => {
    var init = useRef(true);

    const [savings, setSavings] = useState({
        water: {
            active: false,
            savings: 0,
            total: 0,
            units: 'm3'
        },
        electricity: {
            active: false,
            savings: 0,
            total: 0,
            units: 'kWh'
        },
        gas: {
            active: false,
            savings: 0,
            total: 0,
            units: 'm3'
        },
        ghg: {
            savings: 0,
            total: 0,
        },
        cost: {
            savings: 0,
            total: 0,
        }
    });

    useEffect(() => {
        if(!init.current) return;
        init.current = false;

        let sCopy = {...savings};

        for(let i = 0;i<props.opps.length;++i){
            if(props.opps[i].has_water){
                sCopy.water.active = true;
                sCopy.water.savings += props.opps[i].water_savings_m3_year;
                sCopy.ghg.savings += props.opps[i].water_kg_co2_m3 * props.opps[i].water_savings_m3_year;
                sCopy.cost.savings += props.opps[i].water_unit_cost_m3 * props.opps[i].water_savings_m3_year
            }

            if(props.opps[i].has_electricity){
                sCopy.electricity.active = true;
                sCopy.electricity.savings += props.opps[i].electricity_savings_kwh_year;
                sCopy.ghg.savings += props.opps[i].electricity_kg_co2_kwh * props.opps[i].electricity_savings_kwh_year;
                sCopy.cost.savings += props.opps[i].electricity_unit_cost_kwh * props.opps[i].electricity_savings_kwh_year;
            }

            if(props.opps[i].has_gas){
                sCopy.gas.active = true;
                sCopy.gas.savings += props.opps[i].gas_savings_units_year;
                sCopy.ghg.savings += props.opps[i].gas_kg_co2_btu * props.opps[i].gas_btu_per_unit * props.opps[i].gas_savings_units_year
                sCopy.cost.savings += props.opps[i].gas_unit_cost * props.opps[i].gas_savings_units_year;
            }
        }

        sCopy.water.total = props.metrics.total_water_m3;
        sCopy.electricity.total = props.metrics.total_elec_kwh;
        sCopy.gas.total = props.metrics.total_gas;
        sCopy.ghg.total = props.metrics.water_ghg_kg + props.metrics.elec_ghg_kg + props.metrics.gas_ghg_kg;
        sCopy.cost.total = props.metrics.total_water_m3 * props.metrics.water_unit_cost + props.metrics.total_elec_kwh * props.metrics.elec_unit_cost + props.metrics.total_gas * props.metrics.gas_unit_cost;

        setSavings(sCopy);
    }, []);

    return(
        <div className="rmis-report-page" style={{height:'11in', width:'8.5in', margin:'auto', backgroundColor:'white', padding: '1in 0.75in'}} id='summary-page'>
            <div style={{height:'0.3in', width:'7.5in', position:'absolute', marginTop:'9.5in', display:'flex', flexDirection:'row'}}>
                <img src={ESLogo} style={{height:'100%', width:'auto'}}/>
                <div style={{flex:'6'}}></div>
                <p style={{margin:'0', flex:'1', fontSize:'0.9rem'}}>page {props.page}</p>
            </div>

            <h4 style={{fontWeight:'bold', fontSize:'1.78rem', color:'rgb(8,114,182)'}}>Overview of conservation measures.</h4>
            <br/>
            <p style={{color:'rgb(78,79,83)'}}>The table below provides an overview of all estimated potential savings identified on an annual basis. Savings realized from <span style={{textDecoration:'underline'}}>implemented</span> resource conservation measures are detailed at the end of this report.</p>

            <TableContainer>
                <Table aria-label="collapsible table">
                    <TableHead style={{backgroundColor:'rgb(3,117,176)'}}>
                        <TableRow>
                            <TableCell style={{color:'white'}}>Resource</TableCell>
                            <TableCell style={{color:'white'}}>Potential Annual Resource Savings</TableCell>
                            <TableCell style={{color:'white'}}>Potential Percent Reduction</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            savings.water.active &&
                            <TableRow>
                                <TableCell><p style={{margin:'0'}}>Water</p></TableCell>
                                <TableCell align='right'>
                                    <p style={{margin:'0'}}><span style={{color:'rgb(0,177,168)', fontSize:'110%'}}>{Math.round(savings.water.savings).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> <span style={{fontSize:'90%'}}>m<sup>3</sup></span></p>
                                </TableCell>
                                <TableCell align='right'><p style={{margin:'0',color:'rgb(0,177,168)'}}>{`${Math.round(100 * savings.water.savings / savings.water.total).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%`}</p></TableCell>
                            </TableRow>
                        }
                        {
                            savings.electricity.active &&
                            <TableRow>
                                <TableCell><p style={{margin:'0'}}>Electricity</p></TableCell>
                                <TableCell align='right'><p style={{margin:'0'}}><span style={{color:'rgb(0,177,168)', fontSize:'110%'}}>{Math.round(savings.electricity.savings).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> <span style={{fontSize:'90%'}}>kWh</span></p></TableCell>
                                <TableCell align='right'><p style={{margin:'0',color:'rgb(0,177,168)'}}>{`${Math.round(100 * savings.electricity.savings / savings.electricity.total).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%`}</p></TableCell>
                            </TableRow>
                        }
                        {
                            savings.gas.active &&
                            <TableRow>
                                <TableCell><p style={{margin:'0'}}>Natural gas</p></TableCell>
                                <TableCell align='right'><p style={{margin:'0'}}><span style={{color:'rgb(0,177,168)', fontSize:'110%'}}>{Math.round(savings.gas.savings).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> <span style={{fontSize:'90%'}}>m<sup>3</sup></span></p></TableCell>
                                <TableCell align='right'><p style={{margin:'0',color:'rgb(0,177,168)'}}>{`${Math.round(100 * savings.gas.savings / savings.gas.total).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%`}</p></TableCell>
                            </TableRow>
                        }
                        <TableRow>
                            <TableCell><p style={{margin:'0', fontWeight:'bold'}}>Scope 1 & 2 GHG</p></TableCell>
                            <TableCell align='right'><p style={{margin:'0'}}><span style={{color:'rgb(0,177,168)', fontSize:'110%', fontWeight:'bold'}}>{Number(Math.round(100 * savings.ghg.savings / 1000) / 100).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> <span style={{fontSize:'90%',fontWeight:'bold'}}>MT CO<sub>2</sub>e</span></p></TableCell>
                            <TableCell align='right'><p style={{margin:'0',color:'rgb(0,177,168)', fontWeight:'bold'}}>{`${Math.round(100 * savings.ghg.savings / savings.ghg.total).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%`}</p></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><p style={{margin:'0', fontWeight:'bold'}}>Financial savings</p></TableCell>
                            <TableCell align='right'><p style={{margin:'0'}}><span style={{color:'rgb(0,177,168)', fontSize:'110%', fontWeight:'bold'}}>${Math.round(savings.cost.savings).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></p></TableCell>
                            <TableCell align='right'><p style={{margin:'0',color:'rgb(0,177,168)', fontWeight:'bold'}}>{`${Math.round(100 * savings.cost.savings / savings.cost.total).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%`}</p></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            
            <br/>

            <p style={{color:'rgb(78,79,83)'}}>The following pages depict each utility's monthly consumption trend for the last 12 months where utility data has been entered.</p>
        </div>
    );
}

export default SummaryPage;