import React, { useEffect, useRef, useState} from "react";
import { Button } from "react-bootstrap";
import GHGModal from './GHGModal'
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { IconButton, Box } from '@mui/material';
import gas_types from '../../../example_data/dropdowns/clientModal/gasTypes';
import { DataGrid } from '@mui/x-data-grid';
import useAxiosProtect from "../../../hooks/useAxiosProtect";

const GHGFactors = props => {
    var init = useRef(true);
    const axiosProtect = useAxiosProtect();
    
    // State vars
    const [factors, setFactors] = useState([]);
    const [factorModalState, setFactorModalState] = useState(undefined);

    // Modal delete a row
    const [delRow, setDelRow] = useState('');
    // Modal edit a row
    const [editRow, setEdRow] = useState('')

    // Change modal state
    const toggleModalState = newState => {
        setFactorModalState(newState)
    }

    useEffect(() => {
        const controller = new AbortController();
        const getGHGFactors = async () => {
            try {
                const response = await axiosProtect.get('/ghg/ghg-factors', {
                    signal:  controller.signal
                });

                // Set the factors into state
                setFactors(response.data.factors)

            } catch(err) {
                console.log(err)
            }
        }

        if(init.current){
            init.current = false;
            getGHGFactors()
        }
        
    }, []);

    // To delete a row
    const setDeleteRow = (id, country, territory, year) => {
        setDelRow({
            id,
            country,
            territory,
            year,
        })
    }
    // Remove the row after deleting the row
    const removeRow = id => {
        let tempArray = [...factors];

        for(let i = 0;i<tempArray.length;++i){
            if(tempArray[i].id === id){
                tempArray.splice(i, 1);
                break;
            }
        }
        setFactors(tempArray)
    }

    // Make the gas types easier to understand
    const readableGas = (gas_type) => {
        let readable = '';
        for (let i = 0; i < gas_types.length; i++) {
            if (gas_types[i][0] === gas_type) {
                readable = gas_types[i][1];
            }
        }
        return readable;
    }

    // Pass variables to edit function
    const setEditRow = (id, country, territory, water, elec, gas, gas_type, year) => {
        setEdRow({
            id,
            country,
            territory,
            water,
            elec,
            gas,
            gas_type, 
            year,
        })
        
        // Toggle modal state after setting edit row
        toggleModalState('edit');
    }

    const renderButtons = (params) => {
        return (
            <span>
                <IconButton onClick={() => {toggleModalState('delete'); 
                    setDeleteRow(params.row.id, params.row.country, params.row.territory, params.row.posted_year);}}>
                    <DeleteTwoToneIcon style={{fill: "red"}}/>
                </IconButton>
                <IconButton>
                    <EditTwoToneIcon onClick={() => {
                    setEditRow(params.row.id, params.row.country, params.row.territory, params.row.water_kg_co2_m3, params.row.elec_kg_co2_kwh, params.row.gas_kg_co2_btu, params.row.gas_type, params.row.posted_year)}}/>
                </IconButton>
            </span>
        )
    }

    const renderGasType = (params) => {
        return (
            readableGas(params.row.gas_type)
        )
    }

    const columns = [
        {field: 'id', headerName: 'ID', hide: true},
        {field: 'country',
            flex: 1,
            renderHeader: () => {return(<strong>Country</strong>)},
        },
        {field: 'territory',
            flex: 1,
            renderHeader: () => {return(<strong>Province/State</strong>)},
        },
        {field: 'water_kg_co2_m3',
            flex: 1,
            renderHeader: () => {return (<strong>Water(kg CO<sub>2</sub>e/m<sup>3</sup>)</strong>)}
        },
        {field: 'elec_kg_co2_kwh',
            flex: 1,
            renderHeader: () => {return (<strong>Electricity (kg CO<sub>2</sub>e/kWh)</strong>)}
        },
        {field: 'gas_kg_co2_btu',
            flex: 1,
            renderHeader: () => {return (<strong>Gas (kg CO<sub>2</sub>e/BTU)</strong>)}
        },
        {field: 'gas_type',
        renderHeader: () => {return(<strong>Gas Type</strong>)},
            renderCell: renderGasType,
            flex: 1,
        },
        {field: 'posted_year', 
            renderHeader: () => {return(<strong>Year</strong>)}, 
            flex: 1,
        },
        {field: 'buttons', 
            renderHeader: () => {return(<strong>Delete or Edit</strong>)},
            renderCell: renderButtons,
            disableClickEventBubbling: true,
            flex: 1,
        },
    ]

    return (
        <>
        <Box textAlign='center'>
            <Button onClick={() => {toggleModalState("add")}} variant='outlined'>
                <strong>Add Factors</strong>
            </Button>
        </Box>
        

        <GHGModal factorModalState={factorModalState} toggleModalState={toggleModalState} factors={factors} setFactors={setFactors} delRow={delRow} editRow={editRow} removeRow={removeRow}/>
        
        <DataGrid
            rows={factors}
            columns={columns}
            columnVisibilityModel={{
                // Hide columns status and traderName, the other columns will remain visible
                id: false,
            }}
            autoHeight
            hideFooterPagination
            hideFooterSelectedRowCount
            sx={{
                columnHeader: 'bold',
            }}
        />
        </>
    )
}



export default GHGFactors;