import { Divider } from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import KPICard from '../dashboard_components/metrics/weekly_kpi_cards/KPICard';
import SiteCard from '../dashboard_components/sites/SiteCard';
import TargetEmissions from '../../emissions/components/superceded/TargetEmissions';
import useAuth from '../../../hooks/useAuth';
import jwtDecode from 'jwt-decode';
import KPICardDemo from '../dashboard_components/metrics/weekly_kpi_cards/KPICardDemo';
import useAxiosProtect from '../../../hooks/useAxiosProtect';

const ClientDashContainer = props => {
    const axiosProtect = useAxiosProtect();

    var init = useRef(true);
    const {auth, persist} = useAuth();
    const [isTestClient, setIsTestClient] = useState(false);

    const [watData, setWatData] = useState([]);
    const [elecData, setElecData] = useState([]);
    const [gasData, setGasData] = useState([]);
    const [prodData, setProdData] = useState([]);

    const [watMax, setWatMax] = useState(6500);
    const [elecMax, setElecMax] = useState(30000);
    const [gasMax, setGasMax] = useState(2800);
    const [prodMax, setProdMax] = useState(2200);

    const [expanded, setExpanded] = useState([false,false,false,false]);
    const [sites, setSites] = useState([]);

    useEffect(() => {
        if(!init.current) return;
        init.current = false;

        let isTest = resolveClientType();

        if(isTest == null) return;

        if(isTest){
            populateDemoData();
        }
        else{
            let tokenObj = jwtDecode(auth.accessToken);

            let orgID = tokenObj.org_id;
            let role = tokenObj.role;

            populateKPICardData(role, orgID);
            populateSites(role, orgID);
        }
    }, []);

    const resolveClientType = () => {
        if(auth?.accessToken){
            let tokenObj = jwtDecode(auth.accessToken);
            setIsTestClient(tokenObj.email === "testcorporateclient@test.com")

            return tokenObj.email === "testcorporateclient@test.com";
        }

        return null;
    }

    const toggleExpanded = index => {
        setExpanded(prevExpanded => {
            const updatedExpanded = [...prevExpanded];
            updatedExpanded[index] = !updatedExpanded[index];
            return updatedExpanded;
        });
    }

    const populateDemoData = () => {
        let tempWat = [1645,1794,845,544,2145,1747,1827];
        let tempElec = [2400,1398,9800,3908,4800,3800,4300];
        let tempGas = [847,714,548,647,912,697,761];
        let tempProd = [722,612,152,120,520,713,596];

        for(let i = 0;i<7;++i){
            
            var now = new Date();
            let currDay = now.getDate();
            now.setDate(currDay - 6 + i);

            tempWat[i] = {'name': now.toISOString().split('T')[0], 'use': tempWat[i] * 3};
            tempElec[i] = {'name': now.toISOString().split('T')[0], 'use': tempElec[i] * 3};
            tempGas[i] = {'name': now.toISOString().split('T')[0], 'use': tempGas[i] * 3};
            tempProd[i] = {'name': now.toISOString().split('T')[0], 'use': tempProd[i] * 3};
        }

        setWatData(tempWat);
        setElecData(tempElec);
        setGasData(tempGas);
        setProdData(tempProd);
    }

    const computeMaxValue = array => {
        let max = 0;

        for(let i = 0;i<array.length;++i){
            if(parseFloat(array[i].usage) > max) max = parseFloat(array[i].usage);
        }

        return 1.05 * (max + 1);
    }

    const populateKPICardData = async (role, orgID) => {
        try{
            let response = await axiosProtect.get(`/c/client/kpis?role=${role}&orgID=${orgID}`);
            
            setWatData(response.data.readings.water ? response.data.readings.water.last_week_readings : null);
            setElecData(response.data.readings.elec ? response.data.readings.elec.last_week_readings : null);
            
            if(response.data.readings.gas?.last_week_readings){
                for(let i = 0;i<response.data.readings.gas.last_week_readings.length;++i){
                    response.data.readings.gas.last_week_readings[i].usage /= 35300;
                }
            }

            setGasData(response.data.readings.gas ? response.data.readings.gas.last_week_readings : null);
            setProdData(null);

            if(response.data.readings.water?.last_week_readings){
                setWatMax(computeMaxValue(response.data.readings.water.last_week_readings));
            }

            if(response.data.readings.elec?.last_week_readings){
                setElecMax(computeMaxValue(response.data.readings.elec.last_week_readings));
            }

            if(response.data.readings.gas?.last_week_readings){
                setGasMax(computeMaxValue(response.data.readings.gas.last_week_readings));
            }

        }catch(err){
            return;
        }
    }

    const populateSites = async (role, orgID) => {
        try{
            let response = await axiosProtect.get(`/c/client/sites?role=${role}&orgID=${orgID}`);

            for(let i = 0;i<response.data.sites.length;++i){
                response.data.sites[i].expanded = false;
            }

            setSites(response.data.sites);

        }catch(err){
            return;
        }
    }

    return(
        <div style={{backgroundColor: '#f5f5f5', minHeight:'95vh'}}>

            <div id="client-dash-body" style={{padding:'2% 2vw'}}>

                <div id='client-dash-metrics' style={{display:'flex', flexWrap:'wrap', paddingLeft:'5vw'}}>
                    {
                        isTestClient ? <>
                            <KPICardDemo data={watData} parameter='Water' value={1827 * 3} units='m3' graphColor='rgb(8,114,182)' graphId='waterPv' pctChange={4} events={0} maxYValue={watMax}/>
                            <div style={{width:'2vw'}}></div>
                            <KPICardDemo data={elecData} parameter='Electricity' value={8745 * 3} units='kWh' graphColor='rgb(0,177,168)' graphId='elecPv' pctChange={2} events={2} maxYValue={elecMax}/>
                            <div style={{width:'2vw'}}></div>
                            <KPICardDemo data={gasData} parameter='Natural gas' value={761 * 3} units='m3' graphColor='rgb(213,43,0)' graphId='gasPv' pctChange={4} events={1} maxYValue={gasMax}/>
                            <div style={{width:'2vw'}}></div>
                            <KPICardDemo data={prodData} parameter='Product waste' value={596 * 3} units='kg' graphColor='rgb(78,79,83)' graphId='productPv' pctChange={-5} events={0} maxYValue={prodMax}/>
                        </> :

                        <>
                            <KPICard data={watData} parameter='Water' value={1827 * 3} units='m3' graphColor='rgb(8,114,182)' graphId='waterPv' pctChange={4} events={0} maxYValue={watMax}/>
                            <div style={{width:'2vw'}}></div>
                            <KPICard data={elecData} parameter='Electricity' value={8745 * 3} units='kWh' graphColor='rgb(0,177,168)' graphId='elecPv' pctChange={2} events={0} maxYValue={elecMax}/>
                            <div style={{width:'2vw'}}></div>
                            <KPICard data={gasData} parameter='Natural gas' value={761 * 3} units='m3' graphColor='rgb(213,43,0)' graphId='gasPv' pctChange={4} events={1} maxYValue={gasMax}/>
                            <div style={{width:'2vw'}}></div>
                            <KPICard data={prodData} parameter='Product waste' value={596 * 3} units='kg' graphColor='rgb(78,79,83)' graphId='productPv' pctChange={-5} events={0} maxYValue={prodMax}/>
                        </>
                    }
                </div>

                <Divider style={{margin:'1vh 0 2vh 0'}}/>

                <div id='client-dash-sites' style={{display:'flex', flexWrap:'wrap', paddingLeft:'4vw'}}>
                    {
                        isTestClient ? <>
                            <SiteCard expanded={expanded} index={0} toggleExpanded={toggleExpanded} 
                                siteName="Example Plant - Toronto"
                                address="123 Example St, Toronto, ON"
                                hasWater={true}
                                hasElec={true}
                                hasGas={true}
                                hasProduct={true}
                                hasSRCM={false}
                                activeSensors={18}
                                inactiveSensors={2}
                                events={0}
                                navRef='/guest'
                                imageURL="https://shrm-res.cloudinary.com/image/upload/c_crop,h_710,w_1262,x_0,y_121/w_auto:100,w_1200,q_35,f_auto/v1/Employee%20Relations/iStock-175426710_dteiyx.jpg"
                                description="Concise description of the facility, main processes, notable points"
                                sqft={130000}
                            />

                            <div style={{width:'2vw'}}></div>

                            <SiteCard expanded={expanded} index={1} toggleExpanded={toggleExpanded}
                                siteName="Distribution Center - Rochester"
                                address="123 Example Blvd, Rochester, NY"
                                hasWater={true}
                                hasElec={true}
                                hasGas={true}
                                hasProduct={false}
                                hasSRCM={false}
                                activeSensors={12}
                                inactiveSensors={0}
                                navRef='/guest'
                                events={0}
                                imageURL="https://media.istockphoto.com/id/643517702/photo/facade-of-an-industrial-building-and-warehouse.jpg?s=612x612&w=0&k=20&c=EA5Z_HPb-TWamjLuZ9Mjz2i6eJyQbxH4gkCp5JcAw40="
                                description="Concise description of the facility, main processes, notable points"
                                sqft={85000}
                            />

                            <div style={{width:'2vw'}}></div>

                            <SiteCard expanded={expanded} index={2} toggleExpanded={toggleExpanded}
                                siteName="Example Plant - Edmonton"
                                address="123 Example St, Edmonton, AB"
                                hasWater={true}
                                hasElec={true}
                                hasGas={true}
                                hasProduct={false}
                                hasSRCM={true}
                                activeSensors={15}
                                inactiveSensors={2}
                                navRef='/guest'
                                events={1}
                                description="Concise description of the facility, main processes, notable points"
                                imageURL="https://t4.ftcdn.net/jpg/00/87/23/37/360_F_87233777_4f5HOwPPOx2YB04SENdBmcyEFd6awSvq.jpg"
                                sqft={112000}
                            />

                            <div style={{width:'2vw'}}></div>

                            <SiteCard expanded={expanded} index={3} toggleExpanded={toggleExpanded}
                                siteName="Example Plant - Bridgewater"
                                address="123 Example St, Bridgewater, NS"
                                hasWater={true}
                                hasElec={false}
                                hasGas={false}
                                hasProduct={true}
                                hasSRCM={true}
                                activeSensors={25}
                                inactiveSensors={0}
                                events={0}
                                navRef='/guest'
                                imageURL="https://upload.wikimedia.org/wikipedia/commons/e/e3/Zilina_zapalkaren.jpg"
                                description="Concise description of the facility, main processes, notable points"
                                sqft={150000}
                            />

                            <div style={{width:'2vw'}}></div>

                            <SiteCard expanded={expanded} index={3} toggleExpanded={toggleExpanded}
                                siteName="Example Plant - Laval"
                                address="123 Example St, Laval, QC"
                                hasWater={true}
                                hasElec={true}
                                hasGas={true}
                                hasSRCM={true}
                                hasProduct={true}
                                activeSensors={31}
                                inactiveSensors={1}
                                events={2}
                                navRef='/guest'
                                imageURL="https://image.made-in-china.com/202f0j00DZftuMBIaprn/Prefabricated-Light-Steel-Structure-Industrial-Maufacturing-Processing-Hall-Construction.jpg"

                                description="Concise description of the facility, main processes, notable points"
                                sqft={125000}
                            />

                            <div style={{width:'2vw'}}></div>

                            <SiteCard expanded={expanded} index={3} toggleExpanded={toggleExpanded}
                                siteName="Distribution Center - Charlotte"
                                address="123 Example St, Charlotte, NC"
                                hasWater={true}
                                hasElec={true}
                                hasGas={true}
                                hasSRCM={true}
                                hasProduct={false}
                                activeSensors={22}
                                inactiveSensors={1}
                                events={1}
                                navRef='/guest'
                                imageURL="https://media.istockphoto.com/id/177329177/photo/modern-business-unit.jpg?s=612x612&w=0&k=20&c=Ku6YpsPOh2M0YRZ9xAfjbqRtPxSkonFwDjN8_vG-Ft0="
                                description="Concise description of the facility, main processes, notable points"
                                sqft={140000}
                            />
                        </> : <>

                            {sites.map((site, i) => (
                                <SiteCard expanded={site.expanded} index={i} toggleExpanded={() => {
                                        setSites(prev => {
                                            let siteArr = [...prev];
                                            siteArr[i] = {
                                                ...siteArr[i],
                                                expanded: !siteArr[i].expanded
                                            };
                                        });
                                    }}
                                    siteName={site.name}
                                    address={site.address}
                                    hasWater={site.has_water}
                                    hasElec={site.has_elec}
                                    hasGas={site.has_gas}
                                    hasProduct={site.has_prod}
                                    hasSRCM={site.has_srcm}
                                    activeSensors={site.active_sensors}
                                    inactiveSensors={site.disabled_sensors}
                                    navRef={`/site/${site.id}`}
                                    events={0}
                                    imageURL={site.id == 92 ? "https://newportnewsva.com/wp-content/uploads/2022/06/highliner-foods.jpg" : site.id == 91 ? "https://img.marinas.com/v2/bee54dac3361051db83cf8dd3525ae34440ad65b51fa0ceacc6ebdf23337b6e6.jpg" : ""}
                                    description="Concise description of the facility, main processes, notable points"
                                    sqft={site.sqft}
                                />
                            ))}
                        </>
                    }
                    

                </div>
                <Divider style={{margin:'1vh 0 2vh 0'}}/>

                {isTestClient && <div id="client-dash-ghg" style={{width:'90vw', backgroundColor:'white', marginLeft:'3vw', padding:'1vh 1vw', height:'40vh', boxShadow:"2px 6px 12px rgb(164, 164, 164)"}}>
                    <h4>Carbon Reduction Progress</h4>
                    <TargetEmissions parameter='Water' value={1827 * 3} units='m3' graphColor='rgb(8,114,182)' graphId='waterPv' pctChange={4} events={0}graphColor2='rgb(213,43,0)'/>
                </div>}
            </div>

        </div>
    );
}

export default ClientDashContainer;