import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { OutlinedInput, FormControl } from '@mui/material';
import useAxiosProtect from '../../hooks/useAxiosProtect';

const ForgotPasswordModal = props => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const axiosProtect = useAxiosProtect();

    const [formData, setFormData] = useState({
        email: '',
        error: '',
        isSubmitted: false
    });

    const validateAndSubmitForm = () => {
        let isValid = true;
        let tempForm = {...formData};

        if(tempForm.email.trim() === ''){
            tempForm.error = 'Required';
            isValid = false;
        }else if(!emailRegex.test(tempForm.email.trim())){
            tempForm.error = 'Invalid email';
            isValid = false;
        }else tempForm.error = '';

        if(!isValid){
            setFormData(tempForm);
            return;
        }
        
        axiosProtect.put('/auth/pass/set-forgot-password', {email: tempForm.email.trim()}).then(response => {
            if(response.status === 200){
                tempForm.isSubmitted = true;
                setFormData(tempForm);
            }
        }).catch(error => {
            tempForm.error = error.response.data;
            setFormData(tempForm);
        });
    }

    return(
        <Modal
        show={props.forgotPasswordModalOpen}
        onHide={() => props.setForgotPasswordModalOpen(false)}
        backdrop="static"
        centered
        dialogClassName="opp-modal-cw"
    >
        <Modal.Header closeButton>
            <h4>Reset your password</h4>
        </Modal.Header>

        <Modal.Body>
            {
                formData.isSubmitted ?
                <p>An email has been sent to <strong>{formData.email}</strong>. Follow the instructions in that email to reset your password.</p>
                :
                <FormControl fullWidth>
                    <OutlinedInput
                        id="outlined-adornment-amount"
                        placeholder='Email'
                        value={formData.email}
                        error={formData.error !== ''}
                        onChange={e => {
                            setFormData(prev => ({
                                ...prev,
                                email: e.target.value
                            }));
                        }}
                    />
                    <small style={{visibility:`${formData.error === '' ? 'hidden' : 'visible'}`, color:'red'}}>{formData.error}</small>
                </FormControl>
            }
        </Modal.Body>
        
        <Modal.Footer>
            <Button style={{width:'fit-content', marginTop:'2%', backgroundColor:'rgb(8,114,182)', border:'none'}} onClick={() => {
                if(formData.isSubmitted) props.setForgotPasswordModalOpen(false);
                else validateAndSubmitForm();
            }}>{formData.isSubmitted ? 'Done' : 'Reset'}</Button>
        </Modal.Footer>
    </Modal>
    );
}

export default ForgotPasswordModal;