import React, {useEffect, useRef, useState} from 'react';
import { Modal } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import DoneIcon from '@mui/icons-material/Done';
import useAxiosProtect from '../../../../hooks/useAxiosProtect';

const CreateUserModal = props => {
    const [email, setEmail] = useState({value: '', error: ''});
    const [firstName, setFirstName] = useState({value: '', error: ''});
    const [lastName, setLastName] = useState({value: '', error: ''});
    const [role, setRole] = useState({value: 'staff', error: ''});
    
    const [addUserMethod, setAddUserMethod] = useState('create');
    const [addError, setAddError] = useState(false);

    const [nonSiteStaffUsers, setNonSiteStaffUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null)

    const axiosProtect = useAxiosProtect();

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    let init = useRef(true);

    useEffect(() => {
        if(!init.current) return;

        init.current = false;

        axiosProtect.get(`/auth/query/get-org-staff-users?site_id=${window.location.href.split('/').slice(-1)}`).then(response => {
            setNonSiteStaffUsers(response.data.availableUsers);
        }).catch(err => {});
    }, []);

    const validateUserForm = () => {
        let emailError = '', firstNameError = '', lastNameError = '';

        if(email.value === ''){
            emailError = 'Required';
        }else if(!emailRegex.test(email.value)){
            emailError = 'Invalid format';
        }

        setEmail(prev => ({
            ...prev,
            error: emailError
        }));

        if(firstName.value === '') firstNameError = 'Required';

        setFirstName(prev => ({
            ...prev,
            error: firstNameError
        }));

        if(lastName.value === '' ) lastNameError = 'Required';

        setLastName(prev => ({
            ...prev,
            error: lastNameError
        }));

        return emailError === '' && firstNameError === '' && lastNameError === '';
    }

    const submitUserForm = () => {
        if(addUserMethod === 'create'){
            if(!validateUserForm()) return;

            let payload = {
                email: email.value,
                org_id: props.orgID,
                first_name: firstName.value,
                last_name: lastName.value,
                role: role.value === 'staff' ? parseInt(process.env.REACT_APP_CLIENT_STAFF) : parseInt(process.env.REACT_APP_CLIENT_ADMIN),
            };
    
            if(role.value === 'staff') payload.site_id = window.location.href.split('/').slice(-1);
    
            axiosProtect.post('/auth/register/auto-register', payload).then(response => {
                if(response.status !== 200) return;
    
                props.addUserToTable({
                    email: email.value,
                    first_name: firstName.value,
                    last_name: lastName.value,
                    role: role.value === 'staff' ? parseInt(process.env.REACT_APP_CLIENT_STAFF) : parseInt(process.env.REACT_APP_CLIENT_ADMIN),
                    date_created: 'now'
                });
    
                props.setUserModalOpen(false);
            }).catch(error => {
                console.log(error)
            });
        }else{
            if(selectedUser == null){
                setAddError(true);
                return;
            }

            setAddError(false);

            let payload = {
                _id: selectedUser._id,
                site_id: window.location.href.split('/').slice(-1)
            }

            axiosProtect.put('/auth/query/add-staff-user-to-site', payload).then(response => {
                if(response.status !== 200) return;
    
                props.addUserToTable({
                    email: selectedUser.email,
                    first_name: selectedUser.first_name,
                    last_name: selectedUser.last_name,
                    role: parseInt(process.env.REACT_APP_CLIENT_STAFF),
                    date_created: selectedUser.date_created
                });
    
                props.setUserModalOpen(false);
            }).catch(error => {
                console.log(error)
            });
        }
    }

    return(
        <Modal
            show={props.userModalOpen}
            onHide={() => props.setUserModalOpen(false)}
            backdrop="static"
            centered
        >
            <Modal.Header closeButton>
                <h4 style={{fontSize:'1.2rem', margin:'0'}}>Add client user</h4>
            </Modal.Header>

            <Modal.Body>
                <FormControl style={{marginBottom:'1vh'}}>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={addUserMethod}
                        onChange={e => setAddUserMethod(e.target.value)}
                    >
                        <FormControlLabel value="create" control={<Radio />} label="Create new user" />
                        <FormControlLabel value="add" control={<Radio />} label="Add existing user" />
                    </RadioGroup>
                </FormControl>

                {addUserMethod === 'create' ? 
                <>
                <TextField
                    label="Email"
                    fullWidth
                    value={email.value}
                    onChange={e => {
                        setEmail(prev => ({
                            ...prev,
                            value: e.target.value
                        }))
                    }}
                    error={email.error !== ''}
                    helperText={email.error}
                />

                <br/>
                <br/>
                
                <TextField
                    label="First name"
                    fullWidth
                    value={firstName.value}
                    onChange={e => {
                        setFirstName(prev => ({
                            ...prev,
                            value: e.target.value
                        }))
                    }}
                    error={firstName.error !== ''}
                    helperText={firstName.error}
                />

                <br/>
                <br/>

                <TextField
                    label="Last name"
                    fullWidth
                    value={lastName.value}
                    onChange={e => {
                        setLastName(prev => ({
                            ...prev,
                            value: e.target.value
                        }))
                    }}
                    error={lastName.error !== ''}
                    helperText={lastName.error}
                />

                <br/>
                <br/>

                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Role</InputLabel>
                    <Select
                        label="Role"
                        value={role.value}
                        onChange={e => {
                            setRole(prev => ({
                                ...prev,
                                value: e.target.value 
                            }))
                        }}
                    >
                        <MenuItem value='staff'>Staff member</MenuItem>
                        <MenuItem value='corporate'>Corporate user</MenuItem>
                    </Select>
                    <small style={{color:'rgb(78,79,83)', marginTop:'0.5rem'}}><strong>NOTE:</strong> staff members will only be granted access to the site <strong>'{props.siteName}'</strong> by default. Corporate users will have access to all sites within the organization.</small>
                </FormControl> 
                </>:
                <>
                <Autocomplete
                    fullWidth
                    options={nonSiteStaffUsers}
                    value={selectedUser}
                    getOptionLabel={option => `${option.email} | ${option.first_name} ${option.last_name}`}
                    onChange={(_, option) => {
                        setSelectedUser(option)
                    }}
                    renderOption={(props, option) => (
                        <Box component="li" {...props}>
                            <span>{`${option.email} | ${option.first_name} ${option.last_name}`}</span>
                        </Box>
                    )}
                    renderInput={(params) => <TextField {...params} inputProps={{...params.inputProps}} label="Project lead" />}
                />
                {addError && <small style={{color:'red'}}>No user selected</small>}

                <br/>

                <small style={{color:'rgb(78,79,83)', marginTop:'0.5rem'}}><strong>NOTE:</strong> this form is solely intended to grant staff-level clients access to <strong>'{props.siteName}'</strong>, if they have existing accounts.</small>
                </>
                }
            </Modal.Body>

            <Modal.Footer>
                <IconButton onClick={() => {
                    submitUserForm();
                }}>
                    <DoneIcon/>
                </IconButton>
            </Modal.Footer>
        </Modal>
    );
}

export default CreateUserModal;