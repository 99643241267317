import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import EnbridgeLogo from '../../../media/enbridge_logo.png';
import EnwinLogo from '../../../media/enwin_logo.png';
import HaltonLogo from '../../../media/halton_hydro_logo.jpeg';

const Cofunding = props => {
    const [funds, setFunds] = useState([
        {
            logo: EnbridgeLogo,
            altText: 'Enbridge',
            primary: 'Enbridge',
            status: 'Approved',
            secondary: '$5,000 covered for cost of Opportunity 1'
        },
        {
            logo: EnwinLogo,
            altText: 'Enwin',
            primary: 'Enwin',
            status: 'Pending approval',
            secondary: 'Application submitted for up to 50% co-funding on natural gas reduction projects'
        },
        {
            logo: EnbridgeLogo,
            altText: 'Enbridge',
            primary: 'Enbridge',
            status: 'Pending approval',
            secondary: 'Application submitted for up to $10,000 co-funding on electricity reduction projects'
        },
        {
            logo: HaltonLogo,
            altText: 'Halton Hills Hydro',
            primary: 'Halton Hills Hydro',
            status: 'Drafting application',
            secondary: 'Application for up to $2,500 co-funding of Opportunity 4'
        },
    ]);

    return (
        <div style={{height:'39vh', backgroundColor:'white', boxShadow:'2px 6px 12px rgb(164, 164, 164)', borderRadius:'1%', padding:'1%', overflowY:'scroll'}} className='hide-scrollbar'>
            <h4 style={{fontSize:'1.25rem'}}>Cofunding Programs</h4>

            <div>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    {
                        funds.map((fund, i) => {
                            return(
                                <>
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                    <Avatar alt={fund.altText} src={fund.logo} />
                                    </ListItemAvatar>
                                    <ListItemText
                                    primary={fund.primary}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                {fund.status}
                                            </Typography>
                                            {` - ${fund.secondary}`}
                                        </React.Fragment>
                                    }
                                    />
                                </ListItem>
                                {i + 1 !== funds.length && <Divider variant="inset" component="li" />}
                                </>
                            );
                        })
                    }
                </List>
            </div>
        </div>
    );
}

export default Cofunding;