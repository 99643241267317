import React, {useEffect, useRef, useState} from 'react';
import { EnergySavingsLeaf, Visibility, VisibilityOff } from '@mui/icons-material';
import { ListItem, ListItemButton, ListItemIcon, ListItemText, TextField, InputAdornment, IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import useAxiosProtect from '../../hooks/useAxiosProtect';
import useAuth from '../../hooks/useAuth';
import jwtDecode from 'jwt-decode';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const ProfilePage = props => {
    const [activePage, setActivePage] = useState("General");
    const [containerHeight, setContainerHeight] = useState(0);
    const [showPassChangeModal, setShowPassChangeModal] = useState(false);
    const axiosProtect = useAxiosProtect();
    const {auth, persist} = useAuth();

    const [uid, setUID] = useState('');
    const [email, setEmail] = useState('');

    const [passwordStates, setPasswordStates] = useState({
        passEditOn: false,
        current: {
            value: '',
            show: false,
            error: ''
        },
        new: {
            value: '',
            show: false,
            error: ''
        }
    });

    const [open, setOpen] = useState(false);

  const showToast = () => {
    setOpen(true);
  };

  const closeToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

    var init = useRef(true);

    useEffect(() => {
        const handleResize = () => {
            try{
                setContainerHeight(window.innerHeight - document.getElementsByClassName('navbar navbar-expand-lg navbar-light sticky-top')[0].offsetHeight);
            }catch(err){
                setContainerHeight(-1);
            }
        }

        if(init.current){
            init.current = false;
            handleResize();

            if(auth?.accessToken){
                let tokenObj = jwtDecode(auth.accessToken);
                setUID(tokenObj.id);
                setEmail(tokenObj.email);
            }
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize)
        }

    }, []);

    const handleClickShowPassword = field => {
        setPasswordStates(prev => ({
            ...prev,
            [field]: {
                ...prev[field],
                show: !prev[field].show
            }
        }));
    }

    const changePage = page => {
        if(activePage === page) return;
        setActivePage(page);
    }

    const updatePassValue = (value, field) => {
        setPasswordStates(prev => ({
            ...prev,
            [field]: {
                ...prev[field],
                value: value
            }
        }));
    }

    const updatePassFormVisibility = show => {
        setPasswordStates(prev => ({
            ...prev,
            passEditOn: show
        }));
    }

    const requestPassChange = async () => {
        try{
            let response = await axiosProtect.post('/auth/pass/change', {
                uid,
                old_pass: passwordStates.current.value,
                new_pass: passwordStates.new.value,
            });

            return response.data;
        }catch(error){
            return error.response;
        }
    }

    const validatePassChange = async () => {
        let currError = '';
        let newError = '';
        let isValid = true;

        if(passwordStates.current.value === ''){
            currError = 'Required';
            isValid = false;
        }else currError = '';

        if(passwordStates.new.value === ''){
            newError = 'Required';
            isValid= false;
        }else newError = '';

        if(!isValid){
            setPasswordStates(prev => ({
                ...prev,
                current: {
                    ...prev.current,
                    error: currError
                },
                new: {
                    ...prev.new,
                    error: newError
                },
            }));

            return isValid;
        }

        let response = await requestPassChange();

        if(response === 'Password changed'){
            clearPassForm();
            return true;

        }else if(response.data === 'Invalid password'){
            newError = 'Length must be >= 8, contain >= 1 number, >= 1 letter, >= 1 special character (@$!%*#?&)';
            currError = '';
        }else if(response.data === 'Incorrect password'){
            currError = response.data;
            newError = '';
        }else{
            newError = response.status === 400 ? response.data : "Something went wrong";
            currError = '';
        }

        setPasswordStates(prev => ({
            ...prev,
            current: {
                ...prev.current,
                error: currError
            },
            new: {
                ...prev.new,
                error: newError
            },
        }));

        return false;
    }

    const clearPassForm = () => {
        setPasswordStates({
            passEditOn: false,
            current: {
                value: '',
                show: false,
                error: ''
            },
            new: {
                value: '',
                show: false,
                error: ''
            }
        });
    }

    const submitPassChange = async () => {
        let isValidPassChange = await validatePassChange();

        if(isValidPassChange) showToast();
    }

    return(
        <div style={{ width:'100%', display:'flex', flexDirection:'row', height:`${containerHeight < 0 ? 'fit-content' : `${containerHeight}px`}`,overflowY:'hidden'}}>
            <div style={{height:'100%',  flex:'1'}}>

                <h4 style={{padding:'0 5%'}}>Account settings</h4>

                <ListItem disablePadding={activePage !== 'General'}>
                    <ListItemButton selected={activePage === 'General'} onClick={() => changePage('General')}>
                    <ListItemIcon>
                        <EnergySavingsLeaf style={{color:'rgb(8,114,182)'}}/>
                    </ListItemIcon>
                    <ListItemText primary="General" style={{color:'rgb(78,79,83)'}}/>
                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding={activePage !== 'Staff'}>
                    <ListItemButton disabled selected={activePage === 'Staff'} onClick={() => changePage('Staff')}>
                    <ListItemIcon>
                        <EnergySavingsLeaf style={{color:'rgb(8,114,182)'}}/>
                    </ListItemIcon>
                    <ListItemText primary="Staff accounts" style={{color:'rgb(78,79,83)'}}/>
                    </ListItemButton>
                </ListItem>


                <ListItem disablePadding={activePage !== 'Sites'}>
                    <ListItemButton disabled selected={activePage === 'Sites'} onClick={() => changePage('Sites')}>
                    <ListItemIcon>
                        <EnergySavingsLeaf style={{color:'rgb(8,114,182)'}}/>
                    </ListItemIcon>
                    <ListItemText primary="Sites" style={{color:'rgb(78,79,83)'}}/>
                    </ListItemButton>
                </ListItem>
            </div>

            <div style={{height:'100%', padding:'2%', backgroundColor:'#f5f5f5', flex:'3.8', overflowY:'auto'}}>
                <div style={{width:'100%', backgroundColor:'white', height:'fit-content', borderRadius:'1%', boxShadow:'2px 6px 12px rgb(164, 164, 164)', padding:'1%'}}>
                    <h4>Account information</h4>
                    <p style={{margin:'0'}}>{`${email}`}</p>
                </div>

                <br/>
                
                <Button style={{backgroundColor:'rgb(8,114,182)'}} variant="contained" onClick={() => updatePassFormVisibility(true)}>Change password</Button>

                {passwordStates.passEditOn && <div style={{width:'33%', backgroundColor:'white', height:'fit-content', borderRadius:'1%', boxShadow:'2px 6px 12px rgb(164, 164, 164)', padding:'1.5%', marginTop:'2%'}}>
                    <TextField
                        label="Current password"
                        error={passwordStates.current.error !== ''}
                        helperText={passwordStates.current.error}
                        value={passwordStates.current.value}
                        onChange={e => updatePassValue(e.target.value, 'current')}
                        style={{width:"100%"}}
                        type={passwordStates.current.show ? 'text' : 'password'}
                        InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => handleClickShowPassword('current')}
                                >
                                {passwordStates.current.show ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                        }}
                        variant="outlined"
                    />

                    <br/><br/>

                    <TextField
                        label="New password"
                        error={passwordStates.new.error !== ''}
                        helperText={passwordStates.new.error}
                        value={passwordStates.new.value}
                        onChange={e => updatePassValue(e.target.value, 'new')}
                        style={{width:"100%"}}
                        type={passwordStates.new.show ? 'text' : 'password'}
                        InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => handleClickShowPassword('new')}
                                >
                                {passwordStates.new.show ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                        }}
                        variant="outlined"
                    />

                    <br/>
                    <br/>

                    <div style={{display:'flex', flexDirection:'row'}}>
                        <Button style={{flex:'8'}} variant="text" onClick={() => clearPassForm()}>Cancel</Button>
                        <div style={{flex:'3'}}></div>
                        <Button style={{backgroundColor:'rgb(8,114,182)', flex:'10'}} variant="contained" onClick={() => submitPassChange()}>Confirm</Button>
                    </div>

                    
                </div>}

                <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar anchorOrigin={{ vertical:'bottom', horizontal:'right' }} open={open} autoHideDuration={6000} onClose={closeToast}>
        <Alert onClose={closeToast} severity="success" sx={{ width: '100%' }}>
          Password changed
        </Alert>
      </Snackbar>
    </Stack>
            </div>
        </div>
    )
}

export default ProfilePage;