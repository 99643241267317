import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ESLogo from '../../../media/logo.png';


const RecentActivity = props => {
    const [activities, setActivities] = useState([
        {
            logo: ESLogo,
            altText: 'Enviro-Stewards',
            primary: 'Enviro-Stewards',
            secondary: 'Updated the implementation cost for Opportunity 1'
        },
        {
            logo: ESLogo,
            altText: 'Enviro-Stewards',
            primary: 'Enviro-Stewards',
            secondary: 'Updated the implementation cost for Opportunity 2'
        },
        {
            logo: ESLogo,
            altText: 'Enviro-Stewards',
            primary: 'Enviro-Stewards',
            secondary: 'Updated the implementation cost for Opportunity 3'
        },
        {
            logo: ESLogo,
            altText: 'Enviro-Stewards',
            primary: 'Enviro-Stewards',
            secondary: 'Updated the implementation cost for Opportunity 4'
        },
    ]);

    return (
        <div style={{height:'39vh', backgroundColor:'white', boxShadow:'2px 6px 12px rgb(164, 164, 164)', borderRadius:'1%', padding:'1%', overflowY:'scroll'}} className='hide-scrollbar'>
            <h4 style={{fontSize:'1.25rem'}}>Recent Activity</h4>

            <div>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    {
                        activities.map((activity, i) => {
                            return(
                                <>
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                    <Avatar alt={activity.altText} src={activity.logo} />
                                    </ListItemAvatar>
                                    <ListItemText
                                    primary={activity.primary}
                                    secondary={
                                        <React.Fragment>
                                        {activity.secondary}
                                        </React.Fragment>
                                    }
                                    />
                                </ListItem>
                                {i + 1 !== activities.length && <Divider variant="inset" component="li" />}
                                </>
                            );
                        })
                    }
                </List>
            </div>
        </div>
    );
}

export default RecentActivity;