import React, { useEffect, useRef, useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Area, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, AreaChart } from 'recharts';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Skeleton } from '@mui/material';

const KPICard = props => {
    var init = useRef(true);

    useEffect(() => {
        if(!init.current) return;
        init.current = false;
    }, []);

    const formatDate = daysAgo => {
        const today = new Date();
        const targetDate = new Date(today);
        targetDate.setDate(today.getDate() - daysAgo);

        const options = { weekday: 'short', month: 'short', day: 'numeric' };
        return targetDate.toLocaleDateString(undefined, options);
    };

    const formatReadings = (reading) => {
        return (Math.round(reading * 100) / 100).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const computeYesterdayPctChange = () => {
        if(props.data?.length < 2) return 0;

        let today = parseFloat(props.data[props.data.length - 1].usage);
        let yesterday = parseFloat(props.data[props.data.length - 2].usage);

        let pctChange = 100 * ((today - yesterday) / yesterday);

        return (Math.round(pctChange * 100) / 100).toFixed(0)
    }
    
    return(
        <Card sx={{ width:'20vw', boxShadow:'2px 6px 12px rgb(164, 164, 164)', marginBottom:'2%', height:'fit-content'}}>
            <CardMedia sx={{ height: 140, }}>
                {props.data != null ? 

                props.data.length === 0 ? 

                <Skeleton variant="rounded" animation='wave' style={{width:'100%'}} height={140}/> 
                    :
                    <ResponsiveContainer height='100%' width='100%'>
                        <AreaChart data={props.data}
                        margin={{ top: 5, right: 0, left: 0, bottom: 0 }}>
                            <defs>
                                <linearGradient id={`${props.graphId}`} x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor={props.graphColor} stopOpacity={0.8}/>
                                <stop offset="95%" stopColor={props.graphColor} stopOpacity={0}/>
                                </linearGradient>
                            </defs>
                            <XAxis hide dataKey="days_ago" />
                            <YAxis hide domain={[0, props.maxYValue]}/>
                            <Tooltip
                                contentStyle={{
                                    backgroundColor: '#F5F5F5',
                                    color: '#333',
                                    border: '1px solid lightgrey',
                                    fontSize: '0.75em',
                                }}
                                wrapperStyle={{ outline: 'none' }}
                                labelFormatter={formatDate}
                                formatter={(value, name) => [
                                    formatReadings(value),
                                    `Usage (${props.parameter === 'Water' ? 'L' : props.parameter === 'Electricity' ? 'kWh' : 'm3'})`, // make units dynamic
                                ]}
                            />
                            <Area type="monotone" dataKey="usage" stroke={props.graphColor} fillOpacity={1} fill={`url(#${props.graphId})`} />
                        </AreaChart>
                    </ResponsiveContainer> 
                    :
                    <div style={{backgroundColor:'rgba(78,79,83, 0.05)', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <div>
                        <h4>Not in scope</h4>
                        <p style={{textAlign:'center', fontSize:'0.9rem', color:'rgb(78,79,83)'}}>{props.parameter}</p>
                        </div>
                    </div>
                }
            </CardMedia>

            {props.data?.length > 0 && <CardContent>
                <Typography gutterBottom variant="h2" component="div">
                    <div style={{display:'flex', flexDirection:'row', width:'100%'}}>
                        <span style={{flex:'1', fontSize:'1.32rem'}}>{props.parameter}</span>

                        <span style={{flex:'1', textAlign:'right', fontSize:'1.32rem'}}>
                            <strong style={{color:'rgb(8,114,182)'}}>
                                {((Math.round(props.data[props.data.length - 1].usage * 100) / 100) / (props.parameter === 'Water' ? 1000 : 1)).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </strong> <small style={{fontSize:'1.05rem'}}>{props.units}</small>
                        </span>
                    </div>
                </Typography>

                <Typography variant="body2" color="text.secondary">
                    <p style={{margin:'0'}}>
                    <strong style={{color:`${computeYesterdayPctChange() > 0 ? 'rgb(213,43,0)' : 'rgb(0,177,168)'}`}}>
                        {computeYesterdayPctChange() > 0 ? <ArrowDropUpIcon style={{marginTop:'-0.5%'}}/> : <ArrowDropDownIcon style={{marginTop:'-0.5%'}}/>}
                        {Math.abs(computeYesterdayPctChange())}%</strong> from yesterday
                    </p>

                {props.events === 0 ? <p style={{margin:'0'}}>No new events past 7 days</p> :
                <p style={{margin:'0', fontWeight:'bold'}}>{`${props.events} new event${props.events === 1 ? '' : 's'} this week`}</p>}

                </Typography>
            </CardContent>}

            {props.data?.length > 0 && <CardActions>
                {/* <Button size="small">Learn More</Button> */}
            </CardActions>}
        </Card>
    )
}

export default KPICard;