import React, {useState} from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import { useNavigate } from 'react-router-dom';

const OrgCard = props => {
    const navigate = useNavigate();

    return(
        <Card sx={{ width:'18vw', padding:'0.5%', marginBottom:'1vh', marginRight:'1vw'}}>
            <CardActionArea onClick={() => window.location.href = `/client/${props.id}`}>
                <CardContent>
                    <Typography variant="h5" component="div" style={{color:'rgb(8,114,182)'}}>
                        {props.name}
                    </Typography>
                    <Typography sx={{ mb: 1.25 }} color="text.secondary">
                        {props.industry}
                    </Typography>
                    <Typography variant="body2" style={{color:'rgb(78,79,83)'}}>
                        {`${props.hq_city},${props.hq_state === '' ? ' ' : ` ${props.hq_state}, `}${props.hq_country}`}
                        <br/>
                        {`${props.sites} site${props.sites === 1 ? '' : 's'}`}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Button size="small" style={{color:'rgb(0,177,168)'}}>Settings</Button>
                {/* <Button size="small" style={{color:'rgb(213,43,0)'}} onClick={() => {
                    props.updateDelClient(props.name, props.id);
                    props.toggleModalState('delete');
                }}>Delete</Button> */}
            </CardActions>
        </Card>
    );
}

export default OrgCard;