import React from 'react';
import {
    ResponsiveContainer,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
  } from "recharts";

const data = [
    {off: 9, loaded: 34, unloaded: 57}
]

const SystemNotes = props => {
    return(
        <div style={{height:'100%', width:'100%', padding:'2%', display:'flex', flexDirection:'row'}}>

            <div style={{flex:'98', overflowY:'scroll'}}>
                <h4 style={{fontSize:'1.3rem'}}><strong>Leaks</strong></h4>

                <p style={{margin:'0', fontSize:'0.9rem'}}>Energy usage of leaks above audit threshold (annual)</p>
                <p style={{margin:'0 0 4% 0'}}><strong style={{color:'rgb(8,114,182)', fontSize:'0.95rem'}}>10,544 kWh</strong> | <strong style={{color:'rgb(78,79,83)', fontSize:'0.95rem'}}>$874</strong> | <strong style={{color:'rgb(0,177,168)', fontSize:'0.95rem'}}>0.5 MT CO2e</strong></p>

                <p style={{margin:'0', fontSize:'0.9rem'}}>Energy usage of leaks at current level</p>
                <p style={{margin:'0 0 4% 0'}}><strong style={{color:'rgb(8,114,182)', fontSize:'0.95rem'}}>10,544 kWh</strong> | <strong style={{color:'rgb(78,79,83)', fontSize:'0.95rem'}}>$874</strong> | <strong style={{color:'rgb(0,177,168)', fontSize:'0.95rem'}}>0.5 MT CO2e</strong></p>

                <p style={{margin:'0', fontSize:'0.9rem'}}>Compressed air leak audit cost: <strong style={{color:'rgb(8,114,182)', fontSize:'0.95rem'}}>$3,500</strong></p>
            </div>

            <div style={{flex:'2'}}></div>

            <div style={{flex:'98', overflowY:'scroll'}}>
                <h4 style={{fontSize:'1.3rem'}}><strong>Performance</strong></h4>

                {/* <ResponsiveContainer height='40%' width='90%'>
                    <BarChart data={data} layout='vertical'>
                        <XAxis tickLine={false} axisLine={false} type='number' domain={[0,100]} tickSize={0} tickCount={5} tick={{ fontSize: '14px', fill: 'rgb(78,79,83)'}} />
                        <YAxis dataKey="name" tickLine={false} axisLine={false} type='category' width={12}/>
                        <Tooltip />
                        <Bar dataKey="off" fill='rgb(78,79,83)' stackId="a"/>
                        <Bar dataKey="loaded" fill='rgb(0,177,168)' stackId="a"/>
                        <Bar dataKey="unloaded" fill='rgb(213,43,0)' stackId="a"/>
                    </BarChart>
                </ResponsiveContainer> */}

                <p style={{margin:'0', fontSize:'0.9rem'}}>Percent run time <strong style={{color:'rgb(8,114,182)', fontSize:'0.9rem'}}>Loaded</strong> | <strong style={{color:'rgb(78,79,83)', fontSize:'0.9rem'}}>Unloaded</strong> | <strong style={{color:'rgb(0,177,168)', fontSize:'0.9rem'}}>Off</strong>:</p>
                <p style={{margin:'0 0 4% 0'}}><strong style={{color:'rgb(8,114,182)', fontSize:'0.95rem'}}>37%</strong> | <strong style={{color:'rgb(78,79,83)', fontSize:'0.95rem'}}>58%</strong> | <strong style={{color:'rgb(0,177,168)', fontSize:'0.95rem'}}>5%</strong></p>

                <p style={{margin:'0', fontSize:'0.9rem'}}>Cost of running unloaded (annual)</p>
                <p style={{margin:'0 0 4% 0'}}><strong style={{color:'rgb(8,114,182)', fontSize:'0.95rem'}}>10,544 kWh</strong> | <strong style={{color:'rgb(78,79,83)', fontSize:'0.95rem'}}>$874</strong> | <strong style={{color:'rgb(0,177,168)', fontSize:'0.95rem'}}>0.5 MT CO2e</strong></p>

                <p style={{margin:'0', fontSize:'0.9rem'}}>Potential savings with VFD</p>
                <p style={{margin:'0'}}><strong style={{color:'rgb(8,114,182)', fontSize:'0.95rem'}}>10,544 kWh</strong> | <strong style={{color:'rgb(78,79,83)', fontSize:'0.95rem'}}>$874</strong> | <strong style={{color:'rgb(0,177,168)', fontSize:'0.95rem'}}>0.5 MT CO2e</strong></p>
            </div>
        </div>
    );
}

export default SystemNotes;