import { Water } from '@mui/icons-material';
import { Slide } from '@mui/material';
import React, {useEffect, useState, useRef} from 'react';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import Co2Icon from '@mui/icons-material/Co2';
import PowerIcon from '@mui/icons-material/Power';
import mwsslogo from '../../../../media/mwsslogo.svg';
import Zoom from '@mui/material/Zoom';
import './SlideStyle.css';

const ListSlide = props => {
    const init = useRef(true);
    const transitionCount = useRef(0);

    const [bodyIn, setBodyIn] = useState(false);
    const [s1Checked, setS1Checked] = useState(false);
    const [s2Checked, setS2Checked] = useState(false);
    const [s3Checked, setS3Checked] = useState(false);
    const [s4Checked, setS4Checked] = useState(false);
    const [logoChecked, setLogoChecked] = useState(false);

    useEffect(() => {
        const listSlideInterval = setInterval(() => {
            if(transitionCount.current === 0){
                setBodyIn(true);
            }else if(transitionCount.current === 1){
                setS1Checked(true);
            }else if(transitionCount.current === 2){
                setS2Checked(true);
            }else if(transitionCount.current === 3){
                setS3Checked(true);
            }else if(transitionCount.current === 4){
                setS4Checked(true);
            }else if(transitionCount.current === 5){
                setLogoChecked(true);
            }

            transitionCount.current += 1;
        }, 400);

        return () => {
            clearInterval(listSlideInterval);
        };
    }, []);

    return(
        <Slide direction="down" in={bodyIn} mountOnEnter unmountOnExit>
            <div id='list-container'>
                <h4 id='info-header'>Environmental Impacts</h4>

                <br/>

                <Slide direction="right" in={s1Checked} mountOnEnter unmountOnExit>
                    <div className='info-box' id='ib1'>
                        <div id='test' style={{backgroundColor:'rgba(8,114,182)'}}>
                            <Water style={{height:'75%', width:'75%', marginTop:'10%'}}/>
                        </div>

                        <div style={{width:'3vw'}}></div>

                        <div style={{textAlign:'left', width:'26vw'}}>
                            <h4 className='info-box-header'>Water</h4>
                            <p className='info-box-text'>Through our investments in water conservation initiatives, we are saving more than 5,000 m<sup>3</sup> of water per year.</p>
                            <p className='info-box-text'>That's equivalent to <strong>2</strong> Olympic-sized swimming pools, or <strong>10 MILLION</strong> bottles of water!</p>
                        </div>
                    </div>
                </Slide>

                <Slide direction="left" in={s2Checked} mountOnEnter unmountOnExit>
                    <div className='info-box' id='ib2'>
                        <div id='test' style={{backgroundColor:'rgba(0,177,168)'}}>
                            <PowerIcon style={{height:'75%', width:'75%', marginTop:'10%'}}/>
                        </div>

                        <div style={{width:'3vw'}}></div>

                        <div style={{textAlign:'left', width:'26vw'}}>
                            <h4 className='info-box-header'>Electricity</h4>
                            <p className='info-box-text'>We have reduced electricity consumption by 62,000 kWh per year.</p>
                            <p className='info-box-text'>That's equivalent to charging more than <strong>850</strong> Tesla Model Y cars at the same time...</p>
                            <p className='info-box-text'>or charging <strong>5.3 MILLION</strong> smartphones!</p>
                        </div>
                    </div>
                </Slide>

                <Slide direction="right" in={s3Checked} mountOnEnter unmountOnExit>
                    <div className='info-box' id='ib3'>
                        <div id='test' style={{backgroundColor:'rgba(247,230,83)'}}>
                            <LocalFireDepartmentIcon style={{height:'75%', width:'75%', marginTop:'10%'}}/>
                        </div>

                        <div style={{width:'3vw'}}></div>

                        <div style={{textAlign:'left', width:'26vw'}}>
                            <h4 className='info-box-header'>Natural Gas</h4>
                            <p className='info-box-text'>Our initiatives have resulted cutting our natural gas use by 5,200 m<sup>3</sup> annually.</p>
                            <p className='info-box-text'>The emissions saved are equivlent to <strong>10,700</strong> pounds of coal being offset per year!</p>
                        </div>
                    </div>
                </Slide>

                <Slide direction="left" in={s4Checked} mountOnEnter unmountOnExit>
                    <div className='info-box' id='ib4'>
                        <div id='test' style={{backgroundColor:'rgba(213,43,0)'}}>
                            <Co2Icon style={{height:'75%', width:'75%', marginTop:'10%'}}/>
                        </div>

                        <div style={{width:'3vw'}}></div>

                        <div style={{textAlign:'left', width:'26vw'}}>
                            <h4 className='info-box-header'>Carbon Emissions</h4>
                            <p className='info-box-text'>All of these utility reductions have resulted in MWSS cutting back emissions by 12 tonnes of CO<sub>2</sub> per year.</p>
                            <p className='info-box-text'>You would have to burn <strong>3,750</strong> litres of gasoline to emit that much!</p>
                        </div>
                    </div>
                </Slide>
                


                <br/><br/>

                <Zoom in={logoChecked}>
                    <img src={mwsslogo} style={{width:'10vh', height:'auto'}}/>
                </Zoom>
            </div>
        </Slide>
    );
}

export default ListSlide;