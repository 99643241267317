const data = [
    { "name:":"10/12/21 11:07:51  AM","Dehumidifier":"0.0076","Pump #2":"4.4968"},
      { "name:":"10/12/21 11:08:51  AM","Dehumidifier":"0.8255","Pump #2":"11.1589"},
      { "name:":"10/12/21 11:09:51  AM","Dehumidifier":"8.1315","Pump #2":"11.2367"},
      { "name:":"10/12/21 11:10:51  AM","Dehumidifier":"7.9515","Pump #2":"11.2795"},
      { "name:":"10/12/21 11:11:51  AM","Dehumidifier":"7.9088","Pump #2":"11.6075"},
      { "name:":"10/12/21 11:12:51  AM","Dehumidifier":"7.9072","Pump #2":"11.6838"},
      { "name:":"10/12/21 11:13:51  AM","Dehumidifier":"7.9210","Pump #2":"11.5602"},
      { "name:":"10/12/21 11:14:51  AM","Dehumidifier":"7.9271","Pump #2":"11.6594"},
      { "name:":"10/12/21 11:15:51  AM","Dehumidifier":"7.9164","Pump #2":"11.9158"},
      { "name:":"10/12/21 11:16:51  AM","Dehumidifier":"33.4127","Pump #2":"11.6457"},
      { "name:":"10/12/21 11:17:51  AM","Dehumidifier":"32.2026","Pump #2":"11.8059"},
      { "name:":"10/12/21 11:18:51  AM","Dehumidifier":"32.0317","Pump #2":"11.2108"},
      { "name:":"10/12/21 11:19:51  AM","Dehumidifier":"32.3613","Pump #2":"11.2016"},
      { "name:":"10/12/21 11:20:51  AM","Dehumidifier":"32.5551","Pump #2":"11.6548"},
      { "name:":"10/12/21 11:21:51  AM","Dehumidifier":"32.4010","Pump #2":"11.6579"},
      { "name:":"10/12/21 11:22:51  AM","Dehumidifier":"33.1960","Pump #2":"11.5999"},
      { "name:":"10/12/21 11:23:51  AM","Dehumidifier":"33.9696","Pump #2":"10.8934"},
      { "name:":"10/12/21 11:24:51  AM","Dehumidifier":"33.6294","Pump #2":"11.1696"},
      { "name:":"10/12/21 11:25:51  AM","Dehumidifier":"33.5241","Pump #2":"11.4839"},
      { "name:":"10/12/21 11:26:51  AM","Dehumidifier":"33.4951","Pump #2":"10.8492"},
      { "name:":"10/12/21 11:27:51  AM","Dehumidifier":"33.7942","Pump #2":"10.9651"},
      { "name:":"10/12/21 11:28:51  AM","Dehumidifier":"33.7072","Pump #2":"10.8141"},
      { "name:":"10/12/21 11:29:51  AM","Dehumidifier":"33.7301","Pump #2":"11.1666"},
      { "name:":"10/12/21 11:30:51  AM","Dehumidifier":"33.7789","Pump #2":"11.0506"},
      { "name:":"10/12/21 11:31:51  AM","Dehumidifier":"32.8481","Pump #2":"11.4595"},
      { "name:":"10/12/21 11:32:51  AM","Dehumidifier":"32.4819","Pump #2":"11.4260"},
      { "name:":"10/12/21 11:33:51  AM","Dehumidifier":"32.3217","Pump #2":"11.4046"},
      { "name:":"10/12/21 11:34:51  AM","Dehumidifier":"32.3461","Pump #2":"12.0943"},
      { "name:":"10/12/21 11:35:51  AM","Dehumidifier":"32.1904","Pump #2":"11.7571"},
      { "name:":"10/12/21 11:36:51  AM","Dehumidifier":"32.1370","Pump #2":"11.7128"},
      { "name:":"10/12/21 11:37:51  AM","Dehumidifier":"31.9799","Pump #2":"11.8868"},
      { "name:":"10/12/21 11:38:51  AM","Dehumidifier":"31.8120","Pump #2":"11.7128"},
      { "name:":"10/12/21 11:39:51  AM","Dehumidifier":"32.4254","Pump #2":"11.9356"},
      { "name:":"10/12/21 11:40:51  AM","Dehumidifier":"33.8872","Pump #2":"11.8456"},
      { "name:":"10/12/21 11:41:51  AM","Dehumidifier":"33.9727","Pump #2":"11.9768"},
      { "name:":"10/12/21 11:42:51  AM","Dehumidifier":"33.6355","Pump #2":"11.7159"},
      { "name:":"10/12/21 11:43:51  AM","Dehumidifier":"33.7652","Pump #2":"11.2566"},
      { "name:":"10/12/21 11:44:51  AM","Dehumidifier":"33.8628","Pump #2":"11.1467"},
      { "name:":"10/12/21 11:45:51  AM","Dehumidifier":"33.8415","Pump #2":"11.5053"},
      { "name:":"10/12/21 11:46:51  AM","Dehumidifier":"33.8582","Pump #2":"11.6136"},
      { "name:":"10/12/21 11:47:51  AM","Dehumidifier":"33.9467","Pump #2":"11.4015"},
      { "name:":"10/12/21 11:48:51  AM","Dehumidifier":"32.7642","Pump #2":"11.3725"},
      { "name:":"10/12/21 11:49:51  AM","Dehumidifier":"32.3812","Pump #2":"11.6854"},
      { "name:":"10/12/21 11:50:51  AM","Dehumidifier":"32.1462","Pump #2":"11.6381"},
      { "name:":"10/12/21 11:51:51  AM","Dehumidifier":"32.1874","Pump #2":"11.2871"},
      { "name:":"10/12/21 11:52:51  AM","Dehumidifier":"32.0882","Pump #2":"11.3970"},
      { "name:":"10/12/21 11:53:51  AM","Dehumidifier":"32.1767","Pump #2":"11.1559"},
      { "name:":"10/12/21 11:54:51  AM","Dehumidifier":"31.9951","Pump #2":"11.0521"},
      { "name:":"10/12/21 11:55:51  AM","Dehumidifier":"31.7998","Pump #2":"11.6976"},
      { "name:":"10/12/21 11:56:51  AM","Dehumidifier":"31.8074","Pump #2":"11.8349"},
      { "name:":"10/12/21 11:57:51  AM","Dehumidifier":"32.2469","Pump #2":"11.8029"},
      { "name:":"10/12/21 11:58:51  AM","Dehumidifier":"33.7545","Pump #2":"11.4717"},
      { "name:":"10/12/21 11:59:51  AM","Dehumidifier":"34.0627","Pump #2":"11.6197"},
      { "name:":"10/12/21 12:00:51  PM","Dehumidifier":"33.6538","Pump #2":"11.8898"},
      { "name:":"10/12/21 12:01:51  PM","Dehumidifier":"33.5241","Pump #2":"11.3512"},
      { "name:":"10/12/21 12:02:51  PM","Dehumidifier":"33.5409","Pump #2":"11.4824"},
      { "name:":"10/12/21 12:03:51  PM","Dehumidifier":"33.8064","Pump #2":"11.4580"},
      { "name:":"10/12/21 12:04:51  PM","Dehumidifier":"33.7407","Pump #2":"11.4702"},
      { "name:":"10/12/21 12:05:51  PM","Dehumidifier":"33.5760","Pump #2":"11.4046"},
      { "name:":"10/12/21 12:06:51  PM","Dehumidifier":"32.5750","Pump #2":"11.0918"},
      { "name:":"10/12/21 12:07:51  PM","Dehumidifier":"32.2026","Pump #2":"11.4107"},
      { "name:":"10/12/21 12:08:51  PM","Dehumidifier":"32.0882","Pump #2":"11.3848"},
      { "name:":"10/12/21 12:09:51  PM","Dehumidifier":"32.0729","Pump #2":"11.3970"},
      { "name:":"10/12/21 12:10:51  PM","Dehumidifier":"32.1630","Pump #2":"11.4366"},
      { "name:":"10/12/21 12:11:51  PM","Dehumidifier":"32.1111","Pump #2":"11.5373"},
      { "name:":"10/12/21 12:12:51  PM","Dehumidifier":"32.0333","Pump #2":"11.7632"},
      { "name:":"10/12/21 12:13:51  PM","Dehumidifier":"31.8837","Pump #2":"11.1803"},
      { "name:":"10/12/21 12:14:51  PM","Dehumidifier":"31.7586","Pump #2":"11.4611"},
      { "name:":"10/12/21 12:15:51  PM","Dehumidifier":"31.7647","Pump #2":"11.6487"},
      { "name:":"10/12/21 12:16:51  PM","Dehumidifier":"33.4096","Pump #2":"11.7723"},
      { "name:":"10/12/21 12:17:51  PM","Dehumidifier":"34.1039","Pump #2":"11.2749"},
      { "name:":"10/12/21 12:18:51  PM","Dehumidifier":"33.7118","Pump #2":"11.7113"},
      { "name:":"10/12/21 12:19:51  PM","Dehumidifier":"33.6309","Pump #2":"12.0439"},
      { "name:":"10/12/21 12:20:51  PM","Dehumidifier":"33.5088","Pump #2":"11.3558"},
      { "name:":"10/12/21 12:21:51  PM","Dehumidifier":"33.5348","Pump #2":"12.0302"},
      { "name:":"10/12/21 12:22:51  PM","Dehumidifier":"33.8460","Pump #2":"11.9997"},
      { "name:":"10/12/21 12:23:51  PM","Dehumidifier":"33.8430","Pump #2":"11.5694"},
      { "name:":"10/12/21 12:24:51  PM","Dehumidifier":"32.6619","Pump #2":"11.7021"},
      { "name:":"10/12/21 12:25:51  PM","Dehumidifier":"32.2103","Pump #2":"11.6396"},
      { "name:":"10/12/21 12:26:51  PM","Dehumidifier":"31.9310","Pump #2":"12.0592"},
      { "name:":"10/12/21 12:27:51  PM","Dehumidifier":"32.1111","Pump #2":"11.9570"},
      { "name:":"10/12/21 12:28:51  PM","Dehumidifier":"32.0180","Pump #2":"11.4412"},
      { "name:":"10/12/21 12:29:51  PM","Dehumidifier":"31.9707","Pump #2":"11.8929"},
      { "name:":"10/12/21 12:30:51  PM","Dehumidifier":"31.8410","Pump #2":"11.8639"},
      { "name:":"10/12/21 12:31:51  PM","Dehumidifier":"31.7601","Pump #2":"11.7327"},
      { "name:":"10/12/21 12:32:51  PM","Dehumidifier":"31.7037","Pump #2":"12.0485"},
      { "name:":"10/12/21 12:33:51  PM","Dehumidifier":"32.1965","Pump #2":"11.8410"},
      { "name:":"10/12/21 12:34:51  PM","Dehumidifier":"33.8064","Pump #2":"12.0012"},
      { "name:":"10/12/21 12:35:51  PM","Dehumidifier":"34.1451","Pump #2":"11.9280"},
      { "name:":"10/12/21 12:36:51  PM","Dehumidifier":"33.7591","Pump #2":"11.7906"},
      { "name:":"10/12/21 12:37:51  PM","Dehumidifier":"33.6614","Pump #2":"11.4549"},
      { "name:":"10/12/21 12:38:51  PM","Dehumidifier":"33.7667","Pump #2":"12.1294"},
      { "name:":"10/12/21 12:39:51  PM","Dehumidifier":"33.9193","Pump #2":"11.8471"},
      { "name:":"10/12/21 12:40:51  PM","Dehumidifier":"33.8857","Pump #2":"11.8746"},
      { "name:":"10/12/21 12:41:51  PM","Dehumidifier":"34.0627","Pump #2":"11.9677"},
      { "name:":"10/12/21 12:42:51  PM","Dehumidifier":"32.9656","Pump #2":"11.7159"},
      { "name:":"10/12/21 12:43:51  PM","Dehumidifier":"32.4773","Pump #2":"11.6686"},
      { "name:":"10/12/21 12:44:51  PM","Dehumidifier":"32.1706","Pump #2":"11.9768"},
      { "name:":"10/12/21 12:45:51  PM","Dehumidifier":"32.2347","Pump #2":"11.9661"},
      { "name:":"10/12/21 12:46:51  PM","Dehumidifier":"32.3339","Pump #2":"12.2286"},
      { "name:":"10/12/21 12:47:51  PM","Dehumidifier":"32.1218","Pump #2":"11.5801"},
      { "name:":"10/12/21 12:48:51  PM","Dehumidifier":"31.9997","Pump #2":"11.7403"},
      { "name:":"10/12/21 12:49:51  PM","Dehumidifier":"31.9966","Pump #2":"11.4183"},
      { "name:":"10/12/21 12:50:51  PM","Dehumidifier":"31.8868","Pump #2":"11.3970"},
      { "name:":"10/12/21 12:51:51  PM","Dehumidifier":"31.8502","Pump #2":"11.9860"},
      { "name:":"10/12/21 12:52:51  PM","Dehumidifier":"33.3730","Pump #2":"11.9341"},
      { "name:":"10/12/21 12:53:51  PM","Dehumidifier":"34.2428","Pump #2":"11.8303"},
      { "name:":"10/12/21 12:54:51  PM","Dehumidifier":"33.7026","Pump #2":"11.7891"},
      { "name:":"10/12/21 12:55:51  PM","Dehumidifier":"33.7453","Pump #2":"11.7494"},
      { "name:":"10/12/21 12:56:51  PM","Dehumidifier":"33.8125","Pump #2":"11.6213"},
      { "name:":"10/12/21 12:57:51  PM","Dehumidifier":"33.6400","Pump #2":"11.9066"},
      { "name:":"10/12/21 12:58:51  PM","Dehumidifier":"33.6873","Pump #2":"11.6945"},
      { "name:":"10/12/21 12:59:51  PM","Dehumidifier":"33.6538","Pump #2":"12.2972"},
      { "name:":"10/12/21 01:00:51  PM","Dehumidifier":"32.6101","Pump #2":"11.6396"},
      { "name:":"10/12/21 01:01:51  PM","Dehumidifier":"32.1096","Pump #2":"11.2825"},
      { "name:":"10/12/21 01:02:51  PM","Dehumidifier":"31.9463","Pump #2":"11.7876"},
      { "name:":"10/12/21 01:03:51  PM","Dehumidifier":"31.9860","Pump #2":"11.8975"},
      { "name:":"10/12/21 01:04:51  PM","Dehumidifier":"31.9356","Pump #2":"11.7800"},
      { "name:":"10/12/21 01:05:51  PM","Dehumidifier":"31.8120","Pump #2":"11.7311"},
      { "name:":"10/12/21 01:06:51  PM","Dehumidifier":"31.6350","Pump #2":"12.0165"},
      { "name:":"10/12/21 01:07:51  PM","Dehumidifier":"31.5877","Pump #2":"11.0979"},
      { "name:":"10/12/21 01:08:51  PM","Dehumidifier":"31.5465","Pump #2":"11.8257"},
      { "name:":"10/12/21 01:09:51  PM","Dehumidifier":"31.5328","Pump #2":"11.5648"},
      { "name:":"10/12/21 01:10:51  PM","Dehumidifier":"33.1456","Pump #2":"11.5892"},
      { "name:":"10/12/21 01:11:51  PM","Dehumidifier":"34.1848","Pump #2":"11.3115"},
      { "name:":"10/12/21 01:12:51  PM","Dehumidifier":"33.9681","Pump #2":"11.0399"},
      { "name:":"10/12/21 01:13:51  PM","Dehumidifier":"33.6034","Pump #2":"11.3359"},
      { "name:":"10/12/21 01:14:51  PM","Dehumidifier":"33.4844","Pump #2":"11.6213"},
      { "name:":"10/12/21 01:15:51  PM","Dehumidifier":"33.5348","Pump #2":"11.1177"},
      { "name:":"10/12/21 01:16:51  PM","Dehumidifier":"33.5943","Pump #2":"11.4824"},
      { "name:":"10/12/21 01:17:51  PM","Dehumidifier":"33.5866","Pump #2":"11.3237"},
      { "name:":"10/12/21 01:18:51  PM","Dehumidifier":"32.9335","Pump #2":"10.6905"},
      { "name:":"10/12/21 01:19:51  PM","Dehumidifier":"32.3217","Pump #2":"11.0231"},
      { "name:":"10/12/21 01:20:51  PM","Dehumidifier":"31.8853","Pump #2":"11.1772"},
      { "name:":"10/12/21 01:21:51  PM","Dehumidifier":"31.8990","Pump #2":"11.1864"},
      { "name:":"10/12/21 01:22:51  PM","Dehumidifier":"32.1553","Pump #2":"11.2566"},
      { "name:":"10/12/21 01:23:51  PM","Dehumidifier":"32.0455","Pump #2":"11.2001"},
      { "name:":"10/12/21 01:24:51  PM","Dehumidifier":"32.1157","Pump #2":"11.7235"},
      { "name:":"10/12/21 01:25:51  PM","Dehumidifier":"31.9249","Pump #2":"11.6793"},
      { "name:":"10/12/21 01:26:51  PM","Dehumidifier":"31.8700","Pump #2":"11.7082"},
      { "name:":"10/12/21 01:27:51  PM","Dehumidifier":"32.1752","Pump #2":"11.3817"},
      { "name:":"10/12/21 01:28:51  PM","Dehumidifier":"33.6599","Pump #2":"11.2917"},
      { "name:":"10/12/21 01:29:51  PM","Dehumidifier":"34.0597","Pump #2":"11.2840"},
      { "name:":"10/12/21 01:30:51  PM","Dehumidifier":"33.7469","Pump #2":"11.2108"},
      { "name:":"10/12/21 01:31:51  PM","Dehumidifier":"33.5698","Pump #2":"11.5770"},
      { "name:":"10/12/21 01:32:51  PM","Dehumidifier":"33.5363","Pump #2":"11.2398"},
      { "name:":"10/12/21 01:33:51  PM","Dehumidifier":"33.8888","Pump #2":"11.5190"},
      { "name:":"10/12/21 01:34:51  PM","Dehumidifier":"33.7636","Pump #2":"11.0658"},
      { "name:":"10/12/21 01:35:51  PM","Dehumidifier":"33.7331","Pump #2":"11.3649"},
      { "name:":"10/12/21 01:36:51  PM","Dehumidifier":"33.1655","Pump #2":"11.5694"},
      { "name:":"10/12/21 01:37:51  PM","Dehumidifier":"32.5612","Pump #2":"11.4900"},
      { "name:":"10/12/21 01:38:51  PM","Dehumidifier":"32.0775","Pump #2":"11.3252"},
      { "name:":"10/12/21 01:39:51  PM","Dehumidifier":"32.0043","Pump #2":"11.0643"},
      { "name:":"10/12/21 01:40:51  PM","Dehumidifier":"32.1614","Pump #2":"11.0338"},
      { "name:":"10/12/21 01:41:51  PM","Dehumidifier":"31.9753","Pump #2":"11.3115"},
      { "name:":"10/12/21 01:42:51  PM","Dehumidifier":"31.8318","Pump #2":"11.2474"},
      { "name:":"10/12/21 01:43:51  PM","Dehumidifier":"31.7205","Pump #2":"11.0613"},
      { "name:":"10/12/21 01:44:51  PM","Dehumidifier":"31.7449","Pump #2":"11.0674"},
      { "name:":"10/12/21 01:45:51  PM","Dehumidifier":"32.0806","Pump #2":"11.1986"},
      { "name:":"10/12/21 01:46:51  PM","Dehumidifier":"33.7148","Pump #2":"11.5343"},
      { "name:":"10/12/21 01:47:51  PM","Dehumidifier":"33.9590","Pump #2":"11.2490"},
      { "name:":"10/12/21 01:48:51  PM","Dehumidifier":"33.6736","Pump #2":"11.0506"},
      { "name:":"10/12/21 01:49:51  PM","Dehumidifier":"33.5958","Pump #2":"11.2902"},
      { "name:":"10/12/21 01:50:51  PM","Dehumidifier":"33.6645","Pump #2":"11.6289"},
      { "name:":"10/12/21 01:51:51  PM","Dehumidifier":"33.7774","Pump #2":"11.4122"},
      { "name:":"10/12/21 01:52:51  PM","Dehumidifier":"33.8170","Pump #2":"11.5648"},
      { "name:":"10/12/21 01:53:51  PM","Dehumidifier":"33.8170","Pump #2":"11.8730"},
      { "name:":"10/12/21 01:54:51  PM","Dehumidifier":"33.2418","Pump #2":"11.9478"},
      { "name:":"10/12/21 01:55:51  PM","Dehumidifier":"32.4117","Pump #2":"11.7174"},
      { "name:":"10/12/21 01:56:51  PM","Dehumidifier":"31.9371","Pump #2":"11.6670"},
      { "name:":"10/12/21 01:57:51  PM","Dehumidifier":"32.0165","Pump #2":"11.5099"},
      { "name:":"10/12/21 01:58:51  PM","Dehumidifier":"32.0821","Pump #2":"11.6594"},
      { "name:":"10/12/21 01:59:51  PM","Dehumidifier":"32.0851","Pump #2":"11.5770"},
      { "name:":"10/12/21 02:00:51  PM","Dehumidifier":"32.0592","Pump #2":"11.5160"},
      { "name:":"10/12/21 02:01:51  PM","Dehumidifier":"31.8547","Pump #2":"11.1803"},
      { "name:":"10/12/21 02:02:51  PM","Dehumidifier":"31.7647","Pump #2":"11.2566"},
      { "name:":"10/12/21 02:03:51  PM","Dehumidifier":"32.0790","Pump #2":"11.3863"},
      { "name:":"10/12/21 02:04:51  PM","Dehumidifier":"33.5058","Pump #2":"11.0124"},
      { "name:":"10/12/21 02:05:51  PM","Dehumidifier":"34.1314","Pump #2":"11.2398"},
      { "name:":"10/12/21 02:06:51  PM","Dehumidifier":"33.8125","Pump #2":"11.3848"},
      { "name:":"10/12/21 02:07:51  PM","Dehumidifier":"33.8720","Pump #2":"11.6640"},
      { "name:":"10/12/21 02:08:51  PM","Dehumidifier":"33.9895","Pump #2":"11.2917"},
      { "name:":"10/12/21 02:09:51  PM","Dehumidifier":"34.0414","Pump #2":"11.0628"},
      { "name:":"10/12/21 02:10:51  PM","Dehumidifier":"33.9620","Pump #2":"11.4824"},
      { "name:":"10/12/21 02:11:51  PM","Dehumidifier":"33.9147","Pump #2":"11.3237"},
      { "name:":"10/12/21 02:12:51  PM","Dehumidifier":"33.9315","Pump #2":"11.6899"},
      { "name:":"10/12/21 02:13:51  PM","Dehumidifier":"32.7077","Pump #2":"11.4397"},
      { "name:":"10/12/21 02:14:51  PM","Dehumidifier":"32.2377","Pump #2":"11.1162"},
      { "name:":"10/12/21 02:15:51  PM","Dehumidifier":"32.1706","Pump #2":"11.3451"},
      { "name:":"10/12/21 02:16:51  PM","Dehumidifier":"32.2011","Pump #2":"11.2642"},
      { "name:":"10/12/21 02:17:51  PM","Dehumidifier":"32.3110","Pump #2":"11.5099"},
      { "name:":"10/12/21 02:18:51  PM","Dehumidifier":"32.0867","Pump #2":"11.3573"},
      { "name:":"10/12/21 02:19:51  PM","Dehumidifier":"32.0790","Pump #2":"11.3375"},
      { "name:":"10/12/21 02:20:51  PM","Dehumidifier":"31.9905","Pump #2":"11.7113"},
      { "name:":"10/12/21 02:21:51  PM","Dehumidifier":"31.8853","Pump #2":"11.0323"},
      { "name:":"10/12/21 02:22:51  PM","Dehumidifier":"33.2113","Pump #2":"11.3176"},
      { "name:":"10/12/21 02:23:51  PM","Dehumidifier":"34.2245","Pump #2":"11.3359"},
      { "name:":"10/12/21 02:24:51  PM","Dehumidifier":"34.0246","Pump #2":"11.9356"},
      { "name:":"10/12/21 02:25:51  PM","Dehumidifier":"33.8781","Pump #2":"11.2947"},
      { "name:":"10/12/21 02:26:51  PM","Dehumidifier":"33.8689","Pump #2":"11.6945"},
      { "name:":"10/12/21 02:27:51  PM","Dehumidifier":"34.0230","Pump #2":"11.7205"},
      { "name:":"10/12/21 02:28:51  PM","Dehumidifier":"33.8415","Pump #2":"11.6075"},
      { "name:":"10/12/21 02:29:51  PM","Dehumidifier":"33.8064","Pump #2":"11.2016"},
      { "name:":"10/12/21 02:30:51  PM","Dehumidifier":"33.7942","Pump #2":"11.4534"},
      { "name:":"10/12/21 02:31:51  PM","Dehumidifier":"32.6192","Pump #2":"11.7571"},
      { "name:":"10/12/21 02:32:51  PM","Dehumidifier":"32.2362","Pump #2":"11.7494"},
      { "name:":"10/12/21 02:33:51  PM","Dehumidifier":"32.1782","Pump #2":"11.2840"},
      { "name:":"10/12/21 02:34:51  PM","Dehumidifier":"32.1401","Pump #2":"11.2093"},
      { "name:":"10/12/21 02:35:51  PM","Dehumidifier":"32.3491","Pump #2":"11.0246"},
      { "name:":"10/12/21 02:36:51  PM","Dehumidifier":"32.4788","Pump #2":"11.4916"},
      { "name:":"10/12/21 02:37:51  PM","Dehumidifier":"32.1187","Pump #2":"11.3680"},
      { "name:":"10/12/21 02:38:51  PM","Dehumidifier":"32.0500","Pump #2":"11.3451"},
      { "name:":"10/12/21 02:39:51  PM","Dehumidifier":"32.1050","Pump #2":"11.6793"},
      { "name:":"10/12/21 02:40:51  PM","Dehumidifier":"33.7377","Pump #2":"11.3573"},
      { "name:":"10/12/21 02:41:51  PM","Dehumidifier":"34.3435","Pump #2":"12.1630"},
      { "name:":"10/12/21 02:42:51  PM","Dehumidifier":"33.6477","Pump #2":"11.6197"},
      { "name:":"10/12/21 02:43:51  PM","Dehumidifier":"33.8003","Pump #2":"11.7082"},
      { "name:":"10/12/21 02:44:51  PM","Dehumidifier":"33.8186","Pump #2":"11.2078"},
      { "name:":"10/12/21 02:45:51  PM","Dehumidifier":"33.7316","Pump #2":"11.9341"},
      { "name:":"10/12/21 02:46:51  PM","Dehumidifier":"33.8872","Pump #2":"11.9722"},
      { "name:":"10/12/21 02:47:51  PM","Dehumidifier":"34.1024","Pump #2":"11.4137"},
      { "name:":"10/12/21 02:48:51  PM","Dehumidifier":"33.4112","Pump #2":"11.2871"},
      { "name:":"10/12/21 02:49:51  PM","Dehumidifier":"32.5566","Pump #2":"11.6899"},
      { "name:":"10/12/21 02:50:51  PM","Dehumidifier":"32.2225","Pump #2":"10.8461"},
      { "name:":"10/12/21 02:51:51  PM","Dehumidifier":"32.0409","Pump #2":"10.8385"},
      { "name:":"10/12/21 02:52:51  PM","Dehumidifier":"32.0531","Pump #2":"11.6747"},
      { "name:":"10/12/21 02:53:51  PM","Dehumidifier":"32.1324","Pump #2":"11.1238"},
      { "name:":"10/12/21 02:54:51  PM","Dehumidifier":"32.0928","Pump #2":"11.2123"},
      { "name:":"10/12/21 02:55:51  PM","Dehumidifier":"32.0684","Pump #2":"11.3909"},
      { "name:":"10/12/21 02:56:51  PM","Dehumidifier":"32.1721","Pump #2":"11.4504"},
      { "name:":"10/12/21 02:57:51  PM","Dehumidifier":"32.3735","Pump #2":"11.6182"},
      { "name:":"10/12/21 02:58:51  PM","Dehumidifier":"33.8521","Pump #2":"11.7235"},
      { "name:":"10/12/21 02:59:51  PM","Dehumidifier":"34.1054","Pump #2":"11.6640"},
      { "name:":"10/12/21 03:00:51  PM","Dehumidifier":"33.8125","Pump #2":"11.3466"},
      { "name:":"10/12/21 03:01:51  PM","Dehumidifier":"33.6904","Pump #2":"11.8379"},
      { "name:":"10/12/21 03:02:51  PM","Dehumidifier":"33.6141","Pump #2":"11.7601"},
      { "name:":"10/12/21 03:03:51  PM","Dehumidifier":"33.5714","Pump #2":"11.1345"},
      { "name:":"10/12/21 03:04:51  PM","Dehumidifier":"33.4676","Pump #2":"11.6503"},
      { "name:":"10/12/21 03:05:51  PM","Dehumidifier":"33.2464","Pump #2":"11.2795"},
      { "name:":"10/12/21 03:06:51  PM","Dehumidifier":"32.7382","Pump #2":"11.7021"},
      { "name:":"10/12/21 03:07:51  PM","Dehumidifier":"32.3308","Pump #2":"10.9590"},
      { "name:":"10/12/21 03:08:51  PM","Dehumidifier":"32.0043","Pump #2":"11.3603"},
      { "name:":"10/12/21 03:09:51  PM","Dehumidifier":"32.0378","Pump #2":"11.0796"},
      { "name:":"10/12/21 03:10:51  PM","Dehumidifier":"32.0714","Pump #2":"11.2566"},
      { "name:":"10/12/21 03:11:51  PM","Dehumidifier":"32.0348","Pump #2":"11.7678"},
      { "name:":"10/12/21 03:12:51  PM","Dehumidifier":"31.9066","Pump #2":"11.4702"},
      { "name:":"10/12/21 03:13:51  PM","Dehumidifier":"32.0058","Pump #2":"11.4717"},
      { "name:":"10/12/21 03:14:51  PM","Dehumidifier":"32.2332","Pump #2":"11.0414"},
      { "name:":"10/12/21 03:15:51  PM","Dehumidifier":"32.7764","Pump #2":"11.2078"},
      { "name:":"10/12/21 03:16:51  PM","Dehumidifier":"34.2702","Pump #2":"10.7393"},
      { "name:":"10/12/21 03:17:51  PM","Dehumidifier":"35.5917","Pump #2":"4.0848"},
      { "name:":"10/12/21 03:18:51  PM","Dehumidifier":"34.4808","Pump #2":"12.5154"},
      { "name:":"10/12/21 03:19:51  PM","Dehumidifier":"33.9361","Pump #2":"12.5597"},
      { "name:":"10/12/21 03:20:51  PM","Dehumidifier":"35.0393","Pump #2":"0.1022"},
      { "name:":"10/12/21 03:21:51  PM","Dehumidifier":"33.9712","Pump #2":"6.5904"},
      { "name:":"10/12/21 03:22:51  PM","Dehumidifier":"33.6065","Pump #2":"12.6909"},
      { "name:":"10/12/21 03:23:51  PM","Dehumidifier":"33.6477","Pump #2":"0.1022"},
      { "name:":"10/12/21 03:24:51  PM","Dehumidifier":"32.6116","Pump #2":"5.3178"},
      { "name:":"10/12/21 03:25:51  PM","Dehumidifier":"32.1569","Pump #2":"12.6589"},
      { "name:":"10/12/21 03:26:51  PM","Dehumidifier":"32.0378","Pump #2":"12.6818"},
      { "name:":"10/12/21 03:27:51  PM","Dehumidifier":"32.0729","Pump #2":"12.8161"},
      { "name:":"10/12/21 03:28:51  PM","Dehumidifier":"32.6421","Pump #2":"12.9442"},
      { "name:":"10/12/21 03:29:51  PM","Dehumidifier":"32.2927","Pump #2":"12.7184"},
      { "name:":"10/12/21 03:30:51  PM","Dehumidifier":"31.9768","Pump #2":"10.9575"},
      { "name:":"10/12/21 03:31:51  PM","Dehumidifier":"31.8761","Pump #2":"0.8515"},
      { "name:":"10/12/21 03:32:51  PM","Dehumidifier":"31.8029","Pump #2":"5.7603"},
      { "name:":"10/12/21 03:33:51  PM","Dehumidifier":"33.3639","Pump #2":"8.9052"},
      { "name:":"10/12/21 03:34:51  PM","Dehumidifier":"34.0871","Pump #2":"9.5995"},
      { "name:":"10/12/21 03:35:51  PM","Dehumidifier":"33.7713","Pump #2":"9.7566"},
      { "name:":"10/12/21 03:36:51  PM","Dehumidifier":"33.6767","Pump #2":"9.8116"},
      { "name:":"10/12/21 03:37:51  PM","Dehumidifier":"33.7148","Pump #2":"9.8024"},
      { "name:":"10/12/21 03:38:51  PM","Dehumidifier":"33.7240","Pump #2":"10.1534"},
      { "name:":"10/12/21 03:39:51  PM","Dehumidifier":"33.6568","Pump #2":"10.2403"},
      { "name:":"10/12/21 03:40:51  PM","Dehumidifier":"33.5973","Pump #2":"9.8741"},
      { "name:":"10/12/21 03:41:51  PM","Dehumidifier":"32.5307","Pump #2":"10.1808"},
      { "name:":"10/12/21 03:42:51  PM","Dehumidifier":"32.2194","Pump #2":"9.6544"},
      { "name:":"10/12/21 03:43:51  PM","Dehumidifier":"31.9677","Pump #2":"10.0374"},
      { "name:":"10/12/21 03:44:51  PM","Dehumidifier":"32.0195","Pump #2":"10.7759"},
      { "name:":"10/12/21 03:45:51  PM","Dehumidifier":"32.0226","Pump #2":"10.2312"},
      { "name:":"10/12/21 03:46:51  PM","Dehumidifier":"31.9188","Pump #2":"9.9031"},
      { "name:":"10/12/21 03:47:51  PM","Dehumidifier":"31.5724","Pump #2":"10.1778"},
      { "name:":"10/12/21 03:48:51  PM","Dehumidifier":"31.6274","Pump #2":"9.8238"},
      { "name:":"10/12/21 03:49:51  PM","Dehumidifier":"31.6396","Pump #2":"9.9977"},
      { "name:":"10/12/21 03:50:51  PM","Dehumidifier":"32.7413","Pump #2":"9.7826"},
      { "name:":"10/12/21 03:51:51  PM","Dehumidifier":"33.9864","Pump #2":"9.7826"},
      { "name:":"10/12/21 03:52:51  PM","Dehumidifier":"33.8033","Pump #2":"10.3227"},
      { "name:":"10/12/21 03:53:51  PM","Dehumidifier":"33.6019","Pump #2":"9.6635"},
      { "name:":"10/12/21 03:54:51  PM","Dehumidifier":"33.8842","Pump #2":"10.1747"},
      { "name:":"10/12/21 03:55:51  PM","Dehumidifier":"33.8003","Pump #2":"10.1976"},
      { "name:":"10/12/21 03:56:51  PM","Dehumidifier":"33.6934","Pump #2":"9.8634"},
      { "name:":"10/12/21 03:57:51  PM","Dehumidifier":"33.6126","Pump #2":"10.1549"},
      { "name:":"10/12/21 03:58:51  PM","Dehumidifier":"33.6614","Pump #2":"10.6157"},
      { "name:":"10/12/21 03:59:51  PM","Dehumidifier":"32.5414","Pump #2":"10.2220"},
      { "name:":"10/12/21 04:00:51  PM","Dehumidifier":"32.2103","Pump #2":"9.8268"},
      { "name:":"10/12/21 04:01:51  PM","Dehumidifier":"32.1065","Pump #2":"9.7215"},
      { "name:":"10/12/21 04:02:51  PM","Dehumidifier":"32.0882","Pump #2":"10.5074"},
      { "name:":"10/12/21 04:03:51  PM","Dehumidifier":"32.0134","Pump #2":"10.3761"},
      { "name:":"10/12/21 04:04:51  PM","Dehumidifier":"31.9860","Pump #2":"10.1869"},
      { "name:":"10/12/21 04:05:51  PM","Dehumidifier":"31.7769","Pump #2":"9.8772"},
      { "name:":"10/12/21 04:06:51  PM","Dehumidifier":"31.7388","Pump #2":"10.0145"},
      { "name:":"10/12/21 04:07:51  PM","Dehumidifier":"32.3659","Pump #2":"9.9596"},
      { "name:":"10/12/21 04:08:51  PM","Dehumidifier":"33.9513","Pump #2":"10.0816"},
      { "name:":"10/12/21 04:09:51  PM","Dehumidifier":"34.1085","Pump #2":"10.2144"},
      { "name:":"10/12/21 04:10:51  PM","Dehumidifier":"33.7560","Pump #2":"10.4372"},
      { "name:":"10/12/21 04:11:51  PM","Dehumidifier":"33.4234","Pump #2":"10.1549"},
      { "name:":"10/12/21 04:12:51  PM","Dehumidifier":"33.6584","Pump #2":"10.5180"},
      { "name:":"10/12/21 04:13:51  PM","Dehumidifier":"33.7987","Pump #2":"10.6249"},
      { "name:":"10/12/21 04:14:51  PM","Dehumidifier":"33.8689","Pump #2":"10.1762"},
      { "name:":"10/12/21 04:15:51  PM","Dehumidifier":"33.8369","Pump #2":"10.4906"},
      { "name:":"10/12/21 04:16:51  PM","Dehumidifier":"33.8720","Pump #2":"10.0267"},
      { "name:":"10/12/21 04:17:51  PM","Dehumidifier":"32.5856","Pump #2":"10.2235"},
      { "name:":"10/12/21 04:18:51  PM","Dehumidifier":"32.2057","Pump #2":"10.1106"},
      { "name:":"10/12/21 04:19:51  PM","Dehumidifier":"32.2499","Pump #2":"10.2586"},
      { "name:":"10/12/21 04:20:51  PM","Dehumidifier":"32.1569","Pump #2":"10.3304"},
      { "name:":"10/12/21 04:21:51  PM","Dehumidifier":"32.1050","Pump #2":"10.4997"},
      { "name:":"10/12/21 04:22:51  PM","Dehumidifier":"31.9783","Pump #2":"10.0313"},
      { "name:":"10/12/21 04:23:51  PM","Dehumidifier":"31.7449","Pump #2":"10.4311"},
      { "name:":"10/12/21 04:24:51  PM","Dehumidifier":"31.9814","Pump #2":"10.1732"},
      { "name:":"10/12/21 04:25:51  PM","Dehumidifier":"32.4483","Pump #2":"10.6325"},
      { "name:":"10/12/21 04:26:51  PM","Dehumidifier":"33.9300","Pump #2":"10.9026"},
      { "name:":"10/12/21 04:27:51  PM","Dehumidifier":"34.1939","Pump #2":"10.2617"},
      { "name:":"10/12/21 04:28:51  PM","Dehumidifier":"33.9849","Pump #2":"10.4784"},
      { "name:":"10/12/21 04:29:51  PM","Dehumidifier":"33.8048","Pump #2":"10.8080"},
      { "name:":"10/12/21 04:30:51  PM","Dehumidifier":"33.7102","Pump #2":"10.8919"},
      { "name:":"10/12/21 04:31:51  PM","Dehumidifier":"33.7835","Pump #2":"10.9834"},
      { "name:":"10/12/21 04:32:51  PM","Dehumidifier":"33.7804","Pump #2":"10.2922"},
      { "name:":"10/12/21 04:33:51  PM","Dehumidifier":"33.7484","Pump #2":"9.9107"},
      { "name:":"10/12/21 04:34:51  PM","Dehumidifier":"33.6767","Pump #2":"10.2831"},
      { "name:":"10/12/21 04:35:51  PM","Dehumidifier":"32.6314","Pump #2":"10.1732"},
      { "name:":"10/12/21 04:36:51  PM","Dehumidifier":"32.2683","Pump #2":"10.5196"},
      { "name:":"10/12/21 04:37:51  PM","Dehumidifier":"32.1324","Pump #2":"10.5135"},
      { "name:":"10/12/21 04:38:51  PM","Dehumidifier":"32.2484","Pump #2":"10.1289"},
      { "name:":"10/12/21 04:39:51  PM","Dehumidifier":"32.2103","Pump #2":"10.5577"},
      { "name:":"10/12/21 04:40:51  PM","Dehumidifier":"32.2271","Pump #2":"10.4219"},
      { "name:":"10/12/21 04:41:51  PM","Dehumidifier":"32.0882","Pump #2":"10.2983"},
      { "name:":"10/12/21 04:42:51  PM","Dehumidifier":"31.9615","Pump #2":"10.1518"},
      { "name:":"10/12/21 04:43:51  PM","Dehumidifier":"32.8161","Pump #2":"10.7958"},
      { "name:":"10/12/21 04:44:51  PM","Dehumidifier":"34.1344","Pump #2":"10.3212"},
      { "name:":"10/12/21 04:45:51  PM","Dehumidifier":"33.9025","Pump #2":"10.6813"},
      { "name:":"10/12/21 04:46:51  PM","Dehumidifier":"33.6065","Pump #2":"10.6157"},
      { "name:":"10/12/21 04:47:51  PM","Dehumidifier":"33.7163","Pump #2":"10.4311"},
      { "name:":"10/12/21 04:48:51  PM","Dehumidifier":"33.7346","Pump #2":"9.7414"},
      { "name:":"10/12/21 04:49:51  PM","Dehumidifier":"33.8766","Pump #2":"10.2892"},
      { "name:":"10/12/21 04:50:51  PM","Dehumidifier":"33.7774","Pump #2":"10.2998"},
      { "name:":"10/12/21 04:51:51  PM","Dehumidifier":"33.7713","Pump #2":"10.2632"},
      { "name:":"10/12/21 04:52:51  PM","Dehumidifier":"33.4508","Pump #2":"10.4128"},
      { "name:":"10/12/21 04:53:51  PM","Dehumidifier":"32.4392","Pump #2":"10.3548"},
      { "name:":"10/12/21 04:54:51  PM","Dehumidifier":"32.2087","Pump #2":"10.2953"},
      { "name:":"10/12/21 04:55:51  PM","Dehumidifier":"32.2728","Pump #2":"10.3487"},
      { "name:":"10/12/21 04:56:51  PM","Dehumidifier":"32.1798","Pump #2":"10.5226"},
      { "name:":"10/12/21 04:57:51  PM","Dehumidifier":"32.1263","Pump #2":"10.5333"},
      { "name:":"10/12/21 04:58:51  PM","Dehumidifier":"32.0363","Pump #2":"10.8095"},
      { "name:":"10/12/21 04:59:51  PM","Dehumidifier":"31.9646","Pump #2":"10.6188"},
      { "name:":"10/12/21 05:00:51  PM","Dehumidifier":"32.2484","Pump #2":"10.2586"},
      { "name:":"10/12/21 05:01:51  PM","Dehumidifier":"33.7514","Pump #2":"10.4402"},
      { "name:":"10/12/21 05:02:51  PM","Dehumidifier":"34.1970","Pump #2":"10.2678"},
      { "name:":"10/12/21 05:03:51  PM","Dehumidifier":"33.9559","Pump #2":"10.6996"},
      { "name:":"10/12/21 05:04:51  PM","Dehumidifier":"33.9315","Pump #2":"10.3532"},
      { "name:":"10/12/21 05:05:51  PM","Dehumidifier":"33.9895","Pump #2":"10.8782"},
      { "name:":"10/12/21 05:06:51  PM","Dehumidifier":"33.8125","Pump #2":"10.6889"},
      { "name:":"10/12/21 05:07:51  PM","Dehumidifier":"33.8048","Pump #2":"10.5226"},
      { "name:":"10/12/21 05:08:51  PM","Dehumidifier":"33.8262","Pump #2":"10.5577"},
      { "name:":"10/12/21 05:09:51  PM","Dehumidifier":"33.8506","Pump #2":"10.1335"},
      { "name:":"10/12/21 05:10:51  PM","Dehumidifier":"33.7758","Pump #2":"10.8232"},
      { "name:":"10/12/21 05:11:51  PM","Dehumidifier":"32.8038","Pump #2":"10.3059"},
      { "name:":"10/12/21 05:12:51  PM","Dehumidifier":"32.4193","Pump #2":"10.2190"},
      { "name:":"10/12/21 05:13:51  PM","Dehumidifier":"32.3354","Pump #2":"10.3532"},
      { "name:":"10/12/21 05:14:51  PM","Dehumidifier":"32.2972","Pump #2":"10.5425"},
      { "name:":"10/12/21 05:15:51  PM","Dehumidifier":"32.2927","Pump #2":"10.5592"},
      { "name:":"10/12/21 05:16:51  PM","Dehumidifier":"32.2042","Pump #2":"10.9590"},
      { "name:":"10/12/21 05:17:51  PM","Dehumidifier":"32.1492","Pump #2":"10.8705"},
      { "name:":"10/12/21 05:18:51  PM","Dehumidifier":"32.5750","Pump #2":"11.0933"},
      { "name:":"10/12/21 05:19:51  PM","Dehumidifier":"33.8552","Pump #2":"10.8370"},
      { "name:":"10/12/21 05:20:51  PM","Dehumidifier":"34.1039","Pump #2":"10.4997"},
      { "name:":"10/12/21 05:21:51  PM","Dehumidifier":"33.7926","Pump #2":"10.1732"},
      { "name:":"10/12/21 05:22:51  PM","Dehumidifier":"33.7392","Pump #2":"10.3944"},
      { "name:":"10/12/21 05:23:51  PM","Dehumidifier":"33.7667","Pump #2":"10.2907"},
      { "name:":"10/12/21 05:24:51  PM","Dehumidifier":"33.8827","Pump #2":"10.5135"},
      { "name:":"10/12/21 05:25:51  PM","Dehumidifier":"33.7377","Pump #2":"10.4036"},
      { "name:":"10/12/21 05:26:51  PM","Dehumidifier":"33.6767","Pump #2":"10.5730"},
      { "name:":"10/12/21 05:27:51  PM","Dehumidifier":"33.8277","Pump #2":"10.4494"},
      { "name:":"10/12/21 05:28:51  PM","Dehumidifier":"33.8415","Pump #2":"10.3777"},
      { "name:":"10/12/21 05:29:51  PM","Dehumidifier":"32.7947","Pump #2":"10.4280"},
      { "name:":"10/12/21 05:30:51  PM","Dehumidifier":"32.3095","Pump #2":"10.7576"},
      { "name:":"10/12/21 05:31:51  PM","Dehumidifier":"32.2835","Pump #2":"10.7469"},
      { "name:":"10/12/21 05:32:51  PM","Dehumidifier":"32.3430","Pump #2":"10.7073"},
      { "name:":"10/12/21 05:33:51  PM","Dehumidifier":"32.3537","Pump #2":"10.7561"},
      { "name:":"10/12/21 05:34:51  PM","Dehumidifier":"32.2042","Pump #2":"11.0613"},
      { "name:":"10/12/21 05:35:51  PM","Dehumidifier":"32.1157","Pump #2":"10.5806"},
      { "name:":"10/12/21 05:36:51  PM","Dehumidifier":"32.4926","Pump #2":"10.6783"},
      { "name:":"10/12/21 05:37:51  PM","Dehumidifier":"34.0047","Pump #2":"10.9422"},
      { "name:":"10/12/21 05:38:51  PM","Dehumidifier":"33.9437","Pump #2":"10.6386"},
      { "name:":"10/12/21 05:39:51  PM","Dehumidifier":"33.6706","Pump #2":"10.9514"},
      { "name:":"10/12/21 05:40:51  PM","Dehumidifier":"33.7179","Pump #2":"11.0674"},
      { "name:":"10/12/21 05:41:51  PM","Dehumidifier":"33.7026","Pump #2":"11.0277"},
      { "name:":"10/12/21 05:42:51  PM","Dehumidifier":"33.7285","Pump #2":"10.5943"},
      { "name:":"10/12/21 05:43:51  PM","Dehumidifier":"33.7606","Pump #2":"11.2123"},
      { "name:":"10/12/21 05:44:51  PM","Dehumidifier":"33.7728","Pump #2":"11.1330"},
      { "name:":"10/12/21 05:45:51  PM","Dehumidifier":"33.7453","Pump #2":"10.6737"},
      { "name:":"10/12/21 05:46:51  PM","Dehumidifier":"33.6706","Pump #2":"11.0094"},
      { "name:":"10/12/21 05:47:51  PM","Dehumidifier":"32.5917","Pump #2":"10.7561"},
      { "name:":"10/12/21 05:48:51  PM","Dehumidifier":"32.1904","Pump #2":"10.4662"},
      { "name:":"10/12/21 05:49:51  PM","Dehumidifier":"32.1965","Pump #2":"10.8141"},
      { "name:":"10/12/21 05:50:51  PM","Dehumidifier":"32.2972","Pump #2":"11.1360"},
      { "name:":"10/12/21 05:51:51  PM","Dehumidifier":"32.2698","Pump #2":"11.5618"},
      { "name:":"10/12/21 05:52:51  PM","Dehumidifier":"32.1416","Pump #2":"12.0638"},
      { "name:":"10/12/21 05:53:51  PM","Dehumidifier":"32.1141","Pump #2":"11.6136"},
      { "name:":"10/12/21 05:54:51  PM","Dehumidifier":"32.1080","Pump #2":"11.3985"},
      { "name:":"10/12/21 05:55:51  PM","Dehumidifier":"33.1609","Pump #2":"11.6106"},
      { "name:":"10/12/21 05:56:51  PM","Dehumidifier":"34.0246","Pump #2":"11.7327"},
      { "name:":"10/12/21 05:57:51  PM","Dehumidifier":"33.7102","Pump #2":"11.5099"},
      { "name:":"10/12/21 05:58:51  PM","Dehumidifier":"33.5561","Pump #2":"11.2978"},
      { "name:":"10/12/21 05:59:51  PM","Dehumidifier":"33.7484","Pump #2":"11.8791"},
      { "name:":"10/12/21 06:00:51  PM","Dehumidifier":"33.7942","Pump #2":"11.0430"},
      { "name:":"10/12/21 06:01:51  PM","Dehumidifier":"33.7896","Pump #2":"11.5404"},
      { "name:":"10/12/21 06:02:51  PM","Dehumidifier":"33.8628","Pump #2":"11.5480"},
      { "name:":"10/12/21 06:03:51  PM","Dehumidifier":"33.8613","Pump #2":"11.2093"},
      { "name:":"10/12/21 06:04:51  PM","Dehumidifier":"33.9361","Pump #2":"11.3039"},
      { "name:":"10/12/21 06:05:51  PM","Dehumidifier":"33.0022","Pump #2":"11.1360"},
      { "name:":"10/12/21 06:06:51  PM","Dehumidifier":"32.7199","Pump #2":"11.4504"},
      { "name:":"10/12/21 06:07:51  PM","Dehumidifier":"32.3842","Pump #2":"11.2840"},
      { "name:":"10/12/21 06:08:51  PM","Dehumidifier":"32.4880","Pump #2":"11.2917"},
      { "name:":"10/12/21 06:09:51  PM","Dehumidifier":"32.5124","Pump #2":"11.6136"},
      { "name:":"10/12/21 06:10:51  PM","Dehumidifier":"32.5704","Pump #2":"10.8553"},
      { "name:":"10/12/21 06:11:51  PM","Dehumidifier":"32.3751","Pump #2":"11.5831"},
      { "name:":"10/12/21 06:12:51  PM","Dehumidifier":"32.3262","Pump #2":"11.6304"},
      { "name:":"10/12/21 06:13:51  PM","Dehumidifier":"32.9839","Pump #2":"11.2947"},
      { "name:":"10/12/21 06:14:51  PM","Dehumidifier":"34.1711","Pump #2":"11.1742"},
      { "name:":"10/12/21 06:15:51  PM","Dehumidifier":"33.8842","Pump #2":"11.4824"},
      { "name:":"10/12/21 06:16:51  PM","Dehumidifier":"33.8033","Pump #2":"11.8212"},
      { "name:":"10/12/21 06:17:51  PM","Dehumidifier":"33.7804","Pump #2":"11.2749"},
      { "name:":"10/12/21 06:18:51  PM","Dehumidifier":"33.6507","Pump #2":"11.2200"},
      { "name:":"10/12/21 06:19:51  PM","Dehumidifier":"33.6889","Pump #2":"11.1162"},
      { "name:":"10/12/21 06:20:51  PM","Dehumidifier":"33.5805","Pump #2":"11.0719"},
      { "name:":"10/12/21 06:21:51  PM","Dehumidifier":"33.6080","Pump #2":"11.0353"},
      { "name:":"10/12/21 06:22:51  PM","Dehumidifier":"33.7911","Pump #2":"11.4946"},
      { "name:":"10/12/21 06:23:51  PM","Dehumidifier":"33.0770","Pump #2":"11.2871"},
      { "name:":"10/12/21 06:24:51  PM","Dehumidifier":"32.6482","Pump #2":"11.1833"},
      { "name:":"10/12/21 06:25:51  PM","Dehumidifier":"32.3674","Pump #2":"11.6625"},
      { "name:":"10/12/21 06:26:51  PM","Dehumidifier":"32.5673","Pump #2":"10.9957"},
      { "name:":"10/12/21 06:27:51  PM","Dehumidifier":"32.4926","Pump #2":"11.7861"},
      { "name:":"10/12/21 06:28:51  PM","Dehumidifier":"32.4788","Pump #2":"11.3298"},
      { "name:":"10/12/21 06:29:51  PM","Dehumidifier":"32.4117","Pump #2":"11.2535"},
      { "name:":"10/12/21 06:30:51  PM","Dehumidifier":"32.4575","Pump #2":"11.8532"},
      { "name:":"10/12/21 06:31:51  PM","Dehumidifier":"32.9534","Pump #2":"11.2078"},
      { "name:":"10/12/21 06:32:51  PM","Dehumidifier":"34.1619","Pump #2":"11.3283"},
      { "name:":"10/12/21 06:33:51  PM","Dehumidifier":"33.9406","Pump #2":"11.5328"},
      { "name:":"10/12/21 06:34:51  PM","Dehumidifier":"33.8018","Pump #2":"11.6304"},
      { "name:":"10/12/21 06:35:51  PM","Dehumidifier":"33.8094","Pump #2":"11.3069"},
      { "name:":"10/12/21 06:36:51  PM","Dehumidifier":"33.6767","Pump #2":"11.6701"},
      { "name:":"10/12/21 06:37:51  PM","Dehumidifier":"33.6889","Pump #2":"11.6045"},
      { "name:":"10/12/21 06:38:51  PM","Dehumidifier":"33.7209","Pump #2":"11.7784"},
      { "name:":"10/12/21 06:39:51  PM","Dehumidifier":"33.7423","Pump #2":"11.2856"},
      { "name:":"10/12/21 06:40:51  PM","Dehumidifier":"33.6980","Pump #2":"11.1955"},
      { "name:":"10/12/21 06:41:51  PM","Dehumidifier":"33.7453","Pump #2":"11.1971"},
      { "name:":"10/12/21 06:42:51  PM","Dehumidifier":"32.9473","Pump #2":"11.0521"},
      { "name:":"10/12/21 06:43:51  PM","Dehumidifier":"32.7321","Pump #2":"11.6625"},
      { "name:":"10/12/21 06:44:51  PM","Dehumidifier":"32.6299","Pump #2":"11.1574"},
      { "name:":"10/12/21 06:45:51  PM","Dehumidifier":"32.7871","Pump #2":"11.5602"},
      { "name:":"10/12/21 06:46:51  PM","Dehumidifier":"32.6467","Pump #2":"11.7082"},
      { "name:":"10/12/21 06:47:51  PM","Dehumidifier":"32.5002","Pump #2":"11.4397"},
      { "name:":"10/12/21 06:48:51  PM","Dehumidifier":"32.3659","Pump #2":"11.2932"},
      { "name:":"10/12/21 06:49:51  PM","Dehumidifier":"32.7977","Pump #2":"11.3725"},
      { "name:":"10/12/21 06:50:51  PM","Dehumidifier":"33.8888","Pump #2":"11.1940"},
      { "name:":"10/12/21 06:51:51  PM","Dehumidifier":"33.9910","Pump #2":"11.1147"},
      { "name:":"10/12/21 06:52:51  PM","Dehumidifier":"33.8140","Pump #2":"11.4824"},
      { "name:":"10/12/21 06:53:51  PM","Dehumidifier":"33.6706","Pump #2":"11.3893"},
      { "name:":"10/12/21 06:54:51  PM","Dehumidifier":"33.8613","Pump #2":"11.5145"},
      { "name:":"10/12/21 06:55:51  PM","Dehumidifier":"34.0536","Pump #2":"11.1299"},
      { "name:":"10/12/21 06:56:51  PM","Dehumidifier":"33.9834","Pump #2":"12.0226"},
      { "name:":"10/12/21 06:57:51  PM","Dehumidifier":"33.7606","Pump #2":"11.7479"},
      { "name:":"10/12/21 06:58:51  PM","Dehumidifier":"33.8766","Pump #2":"11.5999"},
      { "name:":"10/12/21 06:59:51  PM","Dehumidifier":"33.8735","Pump #2":"11.3313"},
      { "name:":"10/12/21 07:00:51  PM","Dehumidifier":"32.9412","Pump #2":"12.0592"},
      { "name:":"10/12/21 07:01:51  PM","Dehumidifier":"32.7016","Pump #2":"11.3725"},
      { "name:":"10/12/21 07:02:51  PM","Dehumidifier":"32.5902","Pump #2":"11.7937"},
      { "name:":"10/12/21 07:03:51  PM","Dehumidifier":"32.5978","Pump #2":"12.0684"},
      { "name:":"10/12/21 07:04:51  PM","Dehumidifier":"32.6452","Pump #2":"11.5282"},
      { "name:":"10/12/21 07:05:51  PM","Dehumidifier":"32.5826","Pump #2":"11.5450"},
      { "name:":"10/12/21 07:06:51  PM","Dehumidifier":"32.5185","Pump #2":"11.8135"},
      { "name:":"10/12/21 07:07:51  PM","Dehumidifier":"32.3384","Pump #2":"11.2917"},
      { "name:":"10/12/21 07:08:51  PM","Dehumidifier":"33.3745","Pump #2":"11.6915"},
      { "name:":"10/12/21 07:09:51  PM","Dehumidifier":"34.0841","Pump #2":"11.7693"},
      { "name:":"10/12/21 07:10:51  PM","Dehumidifier":"33.8094","Pump #2":"11.7174"},
      { "name:":"10/12/21 07:11:51  PM","Dehumidifier":"33.7194","Pump #2":"11.7830"},
      { "name:":"10/12/21 07:12:51  PM","Dehumidifier":"33.7881","Pump #2":"11.1757"},
      { "name:":"10/12/21 07:13:51  PM","Dehumidifier":"33.7423","Pump #2":"11.9356"},
      { "name:":"10/12/21 07:14:51  PM","Dehumidifier":"33.6492","Pump #2":"11.8349"},
      { "name:":"10/12/21 07:15:51  PM","Dehumidifier":"33.7133","Pump #2":"11.4977"},
      { "name:":"10/12/21 07:16:51  PM","Dehumidifier":"33.5973","Pump #2":"11.6457"},
      { "name:":"10/12/21 07:17:51  PM","Dehumidifier":"33.6233","Pump #2":"11.3802"},
      { "name:":"10/12/21 07:18:51  PM","Dehumidifier":"32.8008","Pump #2":"11.3924"},
      { "name:":"10/12/21 07:19:51  PM","Dehumidifier":"32.5826","Pump #2":"10.9194"},
      { "name:":"10/12/21 07:20:51  PM","Dehumidifier":"32.3735","Pump #2":"11.6243"},
      { "name:":"10/12/21 07:21:51  PM","Dehumidifier":"32.5109","Pump #2":"11.5129"},
      { "name:":"10/12/21 07:22:51  PM","Dehumidifier":"32.3873","Pump #2":"11.1452"},
      { "name:":"10/12/21 07:23:51  PM","Dehumidifier":"32.2683","Pump #2":"11.4458"},
      { "name:":"10/12/21 07:24:51  PM","Dehumidifier":"32.3400","Pump #2":"12.2255"},
      { "name:":"10/12/21 07:25:51  PM","Dehumidifier":"32.1828","Pump #2":"11.2352"},
      { "name:":"10/12/21 07:26:51  PM","Dehumidifier":"32.7031","Pump #2":"11.2459"},
      { "name:":"10/12/21 07:27:51  PM","Dehumidifier":"33.9727","Pump #2":"11.2718"},
      { "name:":"10/12/21 07:28:51  PM","Dehumidifier":"33.7896","Pump #2":"11.5816"},
      { "name:":"10/12/21 07:29:51  PM","Dehumidifier":"33.7972","Pump #2":"11.7372"},
      { "name:":"10/12/21 07:30:51  PM","Dehumidifier":"33.6721","Pump #2":"11.6960"},
      { "name:":"10/12/21 07:31:51  PM","Dehumidifier":"33.6294","Pump #2":"11.9234"},
      { "name:":"10/12/21 07:32:51  PM","Dehumidifier":"33.9010","Pump #2":"11.2642"},
      { "name:":"10/12/21 07:33:51  PM","Dehumidifier":"33.8705","Pump #2":"11.0750"},
      { "name:":"10/12/21 07:34:51  PM","Dehumidifier":"33.8018","Pump #2":"11.4946"},
      { "name:":"10/12/21 07:35:51  PM","Dehumidifier":"33.7972","Pump #2":"10.9712"},
      { "name:":"10/12/21 07:36:51  PM","Dehumidifier":"32.8481","Pump #2":"11.1238"},
      { "name:":"10/12/21 07:37:51  PM","Dehumidifier":"32.7016","Pump #2":"11.4168"},
      { "name:":"10/12/21 07:38:51  PM","Dehumidifier":"32.4697","Pump #2":"11.4244"},
      { "name:":"10/12/21 07:39:51  PM","Dehumidifier":"32.4941","Pump #2":"11.3466"},
      { "name:":"10/12/21 07:40:51  PM","Dehumidifier":"32.5368","Pump #2":"11.4122"},
      { "name:":"10/12/21 07:41:51  PM","Dehumidifier":"32.5856","Pump #2":"11.5877"},
      { "name:":"10/12/21 07:42:51  PM","Dehumidifier":"32.5124","Pump #2":"11.1864"},
      { "name:":"10/12/21 07:43:51  PM","Dehumidifier":"32.3232","Pump #2":"11.5877"},
      { "name:":"10/12/21 07:44:51  PM","Dehumidifier":"33.0571","Pump #2":"11.7647"},
      { "name:":"10/12/21 07:45:51  PM","Dehumidifier":"34.1894","Pump #2":"11.7617"},
      { "name:":"10/12/21 07:46:51  PM","Dehumidifier":"33.8445","Pump #2":"11.5663"},
      { "name:":"10/12/21 07:47:51  PM","Dehumidifier":"33.7636","Pump #2":"11.9661"},
      { "name:":"10/12/21 07:48:51  PM","Dehumidifier":"33.7652","Pump #2":"11.4122"},
      { "name:":"10/12/21 07:49:51  PM","Dehumidifier":"33.8979","Pump #2":"11.4565"},
      { "name:":"10/12/21 07:50:51  PM","Dehumidifier":"33.8476","Pump #2":"11.6579"},
      { "name:":"10/12/21 07:51:51  PM","Dehumidifier":"33.7621","Pump #2":"11.7464"},
      { "name:":"10/12/21 07:52:51  PM","Dehumidifier":"33.9101","Pump #2":"11.8135"},
      { "name:":"10/12/21 07:53:51  PM","Dehumidifier":"33.8125","Pump #2":"11.9249"},
      { "name:":"10/12/21 07:54:51  PM","Dehumidifier":"33.0938","Pump #2":"11.8791"},
      { "name:":"10/12/21 07:55:51  PM","Dehumidifier":"32.6452","Pump #2":"11.6701"},
      { "name:":"10/12/21 07:56:51  PM","Dehumidifier":"32.3705","Pump #2":"11.2612"},
      { "name:":"10/12/21 07:57:51  PM","Dehumidifier":"32.4971","Pump #2":"11.9081"},
      { "name:":"10/12/21 07:58:51  PM","Dehumidifier":"32.5124","Pump #2":"11.4519"},
      { "name:":"10/12/21 07:59:51  PM","Dehumidifier":"32.5673","Pump #2":"11.5816"},
      { "name:":"10/12/21 08:00:51  PM","Dehumidifier":"32.5460","Pump #2":"11.2871"},
      { "name:":"10/12/21 08:01:51  PM","Dehumidifier":"32.3339","Pump #2":"11.7311"},
      { "name:":"10/12/21 08:02:51  PM","Dehumidifier":"32.7169","Pump #2":"11.1131"},
      { "name:":"10/12/21 08:03:51  PM","Dehumidifier":"33.6965","Pump #2":"11.4794"},
      { "name:":"10/12/21 08:04:51  PM","Dehumidifier":"33.8369","Pump #2":"10.7729"},
      { "name:":"10/12/21 08:05:51  PM","Dehumidifier":"33.7438","Pump #2":"11.4443"},
      { "name:":"10/12/21 08:06:51  PM","Dehumidifier":"33.6568","Pump #2":"11.3893"},
      { "name:":"10/12/21 08:07:51  PM","Dehumidifier":"33.5714","Pump #2":"11.5816"},
      { "name:":"10/12/21 08:08:51  PM","Dehumidifier":"33.9254","Pump #2":"11.5343"},
      { "name:":"10/12/21 08:09:51  PM","Dehumidifier":"33.7652","Pump #2":"11.4260"},
      { "name:":"10/12/21 08:10:51  PM","Dehumidifier":"33.6217","Pump #2":"11.0964"},
      { "name:":"10/12/21 08:11:51  PM","Dehumidifier":"33.5409","Pump #2":"11.5389"},
      { "name:":"10/12/21 08:12:51  PM","Dehumidifier":"33.6034","Pump #2":"11.6793"},
      { "name:":"10/12/21 08:13:51  PM","Dehumidifier":"32.9595","Pump #2":"11.6670"},
      { "name:":"10/12/21 08:14:51  PM","Dehumidifier":"32.5948","Pump #2":"11.4122"},
      { "name:":"10/12/21 08:15:51  PM","Dehumidifier":"32.6528","Pump #2":"11.5251"},
      { "name:":"10/12/21 08:16:51  PM","Dehumidifier":"32.6345","Pump #2":"11.9036"},
      { "name:":"10/12/21 08:17:51  PM","Dehumidifier":"32.6513","Pump #2":"11.2337"},
      { "name:":"10/12/21 08:18:51  PM","Dehumidifier":"32.5811","Pump #2":"11.4565"},
      { "name:":"10/12/21 08:19:51  PM","Dehumidifier":"32.4025","Pump #2":"10.8110"},
      { "name:":"10/12/21 08:20:51  PM","Dehumidifier":"32.3842","Pump #2":"11.7769"},
      { "name:":"10/12/21 08:21:51  PM","Dehumidifier":"33.2174","Pump #2":"11.2245"},
      { "name:":"10/12/21 08:22:51  PM","Dehumidifier":"33.9223","Pump #2":"11.6014"},
      { "name:":"10/12/21 08:23:51  PM","Dehumidifier":"33.5760","Pump #2":"11.0506"},
      { "name:":"10/12/21 08:24:51  PM","Dehumidifier":"33.6248","Pump #2":"11.3146"},
      { "name:":"10/12/21 08:25:51  PM","Dehumidifier":"33.7392","Pump #2":"11.4946"},
      { "name:":"10/12/21 08:26:51  PM","Dehumidifier":"33.6461","Pump #2":"11.3558"},
      { "name:":"10/12/21 08:27:51  PM","Dehumidifier":"33.8186","Pump #2":"11.2215"},
      { "name:":"10/12/21 08:28:51  PM","Dehumidifier":"33.6126","Pump #2":"11.2871"},
      { "name:":"10/12/21 08:29:51  PM","Dehumidifier":"33.6995","Pump #2":"11.4549"},
      { "name:":"10/12/21 08:30:51  PM","Dehumidifier":"32.6375","Pump #2":"10.8873"},
      { "name:":"10/12/21 08:31:51  PM","Dehumidifier":"32.6085","Pump #2":"11.3161"},
      { "name:":"10/12/21 08:32:51  PM","Dehumidifier":"32.4468","Pump #2":"11.2688"},
      { "name:":"10/12/21 08:33:51  PM","Dehumidifier":"32.5612","Pump #2":"11.3909"},
      { "name:":"10/12/21 08:34:51  PM","Dehumidifier":"32.7092","Pump #2":"11.3802"},
      { "name:":"10/12/21 08:35:51  PM","Dehumidifier":"32.5200","Pump #2":"11.7159"},
      { "name:":"10/12/21 08:36:51  PM","Dehumidifier":"32.4056","Pump #2":"11.5389"},
      { "name:":"10/12/21 08:37:51  PM","Dehumidifier":"32.2484","Pump #2":"11.4336"},
      { "name:":"10/12/21 08:38:51  PM","Dehumidifier":"32.5338","Pump #2":"11.6701"},
      { "name:":"10/12/21 08:39:51  PM","Dehumidifier":"33.5943","Pump #2":"11.3878"},
      { "name:":"10/12/21 08:40:51  PM","Dehumidifier":"33.8857","Pump #2":"11.6609"},
      { "name:":"10/12/21 08:41:51  PM","Dehumidifier":"33.6980","Pump #2":"11.1391"},
      { "name:":"10/12/21 08:42:51  PM","Dehumidifier":"33.6995","Pump #2":"11.1986"},
      { "name:":"10/12/21 08:43:51  PM","Dehumidifier":"33.7057","Pump #2":"10.6966"},
      { "name:":"10/12/21 08:44:51  PM","Dehumidifier":"33.6339","Pump #2":"11.2505"},
      { "name:":"10/12/21 08:45:51  PM","Dehumidifier":"33.7346","Pump #2":"11.1040"},
      { "name:":"10/12/21 08:46:51  PM","Dehumidifier":"33.8247","Pump #2":"11.7922"},
      { "name:":"10/12/21 08:47:51  PM","Dehumidifier":"33.1823","Pump #2":"10.8873"},
      { "name:":"10/12/21 08:48:51  PM","Dehumidifier":"32.7184","Pump #2":"11.6869"},
      { "name:":"10/12/21 08:49:51  PM","Dehumidifier":"32.5475","Pump #2":"11.4763"},
      { "name:":"10/12/21 08:50:51  PM","Dehumidifier":"32.5460","Pump #2":"11.5480"},
      { "name:":"10/12/21 08:51:51  PM","Dehumidifier":"32.5719","Pump #2":"11.6030"},
      { "name:":"10/12/21 08:52:51  PM","Dehumidifier":"32.5048","Pump #2":"11.2688"},
      { "name:":"10/12/21 08:53:51  PM","Dehumidifier":"32.4590","Pump #2":"10.9133"},
      { "name:":"10/12/21 08:54:51  PM","Dehumidifier":"32.2911","Pump #2":"11.6747"},
      { "name:":"10/12/21 08:55:51  PM","Dehumidifier":"32.2927","Pump #2":"11.2718"},
      { "name:":"10/12/21 08:56:51  PM","Dehumidifier":"32.1660","Pump #2":"11.6548"},
      { "name:":"10/12/21 08:57:51  PM","Dehumidifier":"32.1904","Pump #2":"11.3313"},
      { "name:":"10/12/21 08:58:51  PM","Dehumidifier":"32.7581","Pump #2":"11.2978"},
      { "name:":"10/12/21 08:59:51  PM","Dehumidifier":"34.2031","Pump #2":"11.7952"},
      { "name:":"10/12/21 09:00:51  PM","Dehumidifier":"33.9498","Pump #2":"11.7891"},
      { "name:":"10/12/21 09:01:51  PM","Dehumidifier":"33.7804","Pump #2":"11.4015"},
      { "name:":"10/12/21 09:02:51  PM","Dehumidifier":"33.7194","Pump #2":"11.7159"},
      { "name:":"10/12/21 09:03:51  PM","Dehumidifier":"33.5927","Pump #2":"11.5541"},
      { "name:":"10/12/21 09:04:51  PM","Dehumidifier":"33.6126","Pump #2":"11.6213"},
      { "name:":"10/12/21 09:05:51  PM","Dehumidifier":"33.7133","Pump #2":"11.7098"},
      { "name:":"10/12/21 09:06:51  PM","Dehumidifier":"32.6650","Pump #2":"11.5618"},
      { "name:":"10/12/21 09:07:51  PM","Dehumidifier":"32.2820","Pump #2":"11.4443"},
      { "name:":"10/12/21 09:08:51  PM","Dehumidifier":"32.0943","Pump #2":"11.5267"},
      { "name:":"10/12/21 09:09:51  PM","Dehumidifier":"32.2301","Pump #2":"11.4580"},
      { "name:":"10/12/21 09:10:51  PM","Dehumidifier":"32.2942","Pump #2":"11.5114"},
      { "name:":"10/12/21 09:11:51  PM","Dehumidifier":"32.2377","Pump #2":"11.3939"},
      { "name:":"10/12/21 09:12:51  PM","Dehumidifier":"32.1050","Pump #2":"11.5160"},
      { "name:":"10/12/21 09:13:51  PM","Dehumidifier":"31.9631","Pump #2":"11.5892"},
      { "name:":"10/12/21 09:14:51  PM","Dehumidifier":"31.9295","Pump #2":"11.3893"},
      { "name:":"10/12/21 09:15:51  PM","Dehumidifier":"31.8410","Pump #2":"11.5114"},
      { "name:":"10/12/21 09:16:51  PM","Dehumidifier":"31.8486","Pump #2":"11.0292"},
      { "name:":"10/12/21 09:17:51  PM","Dehumidifier":"31.8074","Pump #2":"11.5129"},
      { "name:":"10/12/21 09:18:51  PM","Dehumidifier":"31.7906","Pump #2":"11.1879"},
      { "name:":"10/12/21 09:19:51  PM","Dehumidifier":"31.7632","Pump #2":"11.5007"},
      { "name:":"10/12/21 09:20:51  PM","Dehumidifier":"31.7861","Pump #2":"11.0185"},
      { "name:":"10/12/21 09:21:51  PM","Dehumidifier":"31.8181","Pump #2":"11.4061"},
      { "name:":"10/12/21 09:22:51  PM","Dehumidifier":"31.7739","Pump #2":"11.1559"},
      { "name:":"10/12/21 09:23:51  PM","Dehumidifier":"31.7510","Pump #2":"10.9010"},
      { "name:":"10/12/21 09:24:51  PM","Dehumidifier":"32.5689","Pump #2":"11.7189"},
      { "name:":"10/12/21 09:25:51  PM","Dehumidifier":"34.0764","Pump #2":"11.2505"},
      { "name:":"10/12/21 09:26:51  PM","Dehumidifier":"34.2611","Pump #2":"10.9316"},
      { "name:":"10/12/21 09:27:51  PM","Dehumidifier":"33.9742","Pump #2":"11.3848"},
      { "name:":"10/12/21 09:28:51  PM","Dehumidifier":"33.7377","Pump #2":"11.3527"},
      { "name:":"10/12/21 09:29:51  PM","Dehumidifier":"33.8140","Pump #2":"11.3237"},
      { "name:":"10/12/21 09:30:51  PM","Dehumidifier":"33.8140","Pump #2":"11.3283"},
      { "name:":"10/12/21 09:31:51  PM","Dehumidifier":"32.1614","Pump #2":"11.3603"},
      { "name:":"10/12/21 09:32:51  PM","Dehumidifier":"31.6396","Pump #2":"10.9957"},
      { "name:":"10/12/21 09:33:51  PM","Dehumidifier":"31.6594","Pump #2":"11.6045"},
      { "name:":"10/12/21 09:34:51  PM","Dehumidifier":"31.8944","Pump #2":"11.4900"},
      { "name:":"10/12/21 09:35:51  PM","Dehumidifier":"31.7418","Pump #2":"11.7571"},
      { "name:":"10/12/21 09:36:51  PM","Dehumidifier":"31.8242","Pump #2":"10.9316"},
      { "name:":"10/12/21 09:37:51  PM","Dehumidifier":"31.7739","Pump #2":"11.3390"},
      { "name:":"10/12/21 09:38:51  PM","Dehumidifier":"31.4000","Pump #2":"11.1376"},
      { "name:":"10/12/21 09:39:51  PM","Dehumidifier":"31.8318","Pump #2":"11.4153"},
      { "name:":"10/12/21 09:40:51  PM","Dehumidifier":"31.5373","Pump #2":"11.2444"},
      { "name:":"10/12/21 09:41:51  PM","Dehumidifier":"31.5297","Pump #2":"11.0414"},
      { "name:":"10/12/21 09:42:51  PM","Dehumidifier":"31.3787","Pump #2":"11.4473"},
      { "name:":"10/12/21 09:43:51  PM","Dehumidifier":"31.3970","Pump #2":"11.4916"},
      { "name:":"10/12/21 09:44:51  PM","Dehumidifier":"31.3420","Pump #2":"11.2566"},
      { "name:":"10/12/21 09:45:51  PM","Dehumidifier":"31.3649","Pump #2":"11.0567"},
      { "name:":"10/12/21 09:46:51  PM","Dehumidifier":"31.4488","Pump #2":"11.5496"},
      { "name:":"10/12/21 09:47:51  PM","Dehumidifier":"31.5419","Pump #2":"11.3863"},
      { "name:":"10/12/21 09:48:51  PM","Dehumidifier":"31.5480","Pump #2":"11.2474"},
      { "name:":"10/12/21 09:49:51  PM","Dehumidifier":"31.4977","Pump #2":"11.3283"},
      { "name:":"10/12/21 09:50:51  PM","Dehumidifier":"31.4427","Pump #2":"11.0140"},
      { "name:":"10/12/21 09:51:51  PM","Dehumidifier":"31.4092","Pump #2":"11.6701"},
      { "name:":"10/12/21 09:52:51  PM","Dehumidifier":"31.3573","Pump #2":"11.5496"},
      { "name:":"10/12/21 09:53:51  PM","Dehumidifier":"31.4351","Pump #2":"11.4321"},
      { "name:":"10/12/21 09:54:51  PM","Dehumidifier":"31.3710","Pump #2":"11.3680"},
      { "name:":"10/12/21 09:55:51  PM","Dehumidifier":"31.4168","Pump #2":"11.5251"},
      { "name:":"10/12/21 09:56:51  PM","Dehumidifier":"31.2703","Pump #2":"11.2200"},
      { "name:":"10/12/21 09:57:51  PM","Dehumidifier":"31.4641","Pump #2":"10.8446"},
      { "name:":"10/12/21 09:58:51  PM","Dehumidifier":"31.2886","Pump #2":"11.4763"},
      { "name:":"10/12/21 09:59:51  PM","Dehumidifier":"31.3710","Pump #2":"11.2108"},
      { "name:":"10/12/21 10:00:51  PM","Dehumidifier":"31.5511","Pump #2":"11.7967"},
      { "name:":"10/12/21 10:01:51  PM","Dehumidifier":"31.3405","Pump #2":"11.8685"},
      { "name:":"10/12/21 10:02:51  PM","Dehumidifier":"31.3603","Pump #2":"11.5511"},
      { "name:":"10/12/21 10:03:51  PM","Dehumidifier":"31.5297","Pump #2":"11.3603"},
      { "name:":"10/12/21 10:04:51  PM","Dehumidifier":"7.8859","Pump #2":"11.0613"},
      { "name:":"10/12/21 10:05:51  PM","Dehumidifier":"7.9561","Pump #2":"11.7449"},
      { "name:":"10/12/21 10:06:51  PM","Dehumidifier":"7.9469","Pump #2":"11.0170"},
      { "name:":"10/12/21 10:07:51  PM","Dehumidifier":"7.9377","Pump #2":"11.2551"},
      { "name:":"10/12/21 10:08:51  PM","Dehumidifier":"7.9377","Pump #2":"11.2306"},
      { "name:":"10/12/21 10:09:51  PM","Dehumidifier":"7.9423","Pump #2":"11.5038"},
      { "name:":"10/12/21 10:10:51  PM","Dehumidifier":"7.8996","Pump #2":"11.2016"},
      { "name:":"10/12/21 10:11:51  PM","Dehumidifier":"7.8996","Pump #2":"11.5801"},
      { "name:":"10/12/21 10:12:51  PM","Dehumidifier":"7.9011","Pump #2":"11.6670"},
      { "name:":"10/12/21 10:13:51  PM","Dehumidifier":"7.8843","Pump #2":"11.7739"},
      { "name:":"10/12/21 10:14:51  PM","Dehumidifier":"7.9072","Pump #2":"11.7250"},
      { "name:":"10/12/21 10:15:51  PM","Dehumidifier":"7.8874","Pump #2":"11.5465"},
      { "name:":"10/12/21 10:16:51  PM","Dehumidifier":"7.8508","Pump #2":"11.2016"},
      { "name:":"10/12/21 10:17:51  PM","Dehumidifier":"7.8828","Pump #2":"11.6182"},
      { "name:":"10/12/21 10:18:51  PM","Dehumidifier":"7.8660","Pump #2":"11.6518"},
      { "name:":"10/12/21 10:19:51  PM","Dehumidifier":"7.8508","Pump #2":"11.4748"},
      { "name:":"10/12/21 10:20:51  PM","Dehumidifier":"7.8355","Pump #2":"11.1589"},
      { "name:":"10/12/21 10:21:51  PM","Dehumidifier":"7.8813","Pump #2":"11.4336"},
      { "name:":"10/12/21 10:22:51  PM","Dehumidifier":"7.8935","Pump #2":"11.6854"},
      { "name:":"10/12/21 10:23:51  PM","Dehumidifier":"7.8691","Pump #2":"11.5785"},
      { "name:":"10/12/21 10:24:51  PM","Dehumidifier":"7.9042","Pump #2":"10.9895"},
      { "name:":"10/12/21 10:25:51  PM","Dehumidifier":"7.8843","Pump #2":"11.1925"},
      { "name:":"10/12/21 10:26:51  PM","Dehumidifier":"7.8981","Pump #2":"11.4534"},
      { "name:":"10/12/21 10:27:51  PM","Dehumidifier":"7.8767","Pump #2":"11.7144"},
      { "name:":"10/12/21 10:28:51  PM","Dehumidifier":"7.8721","Pump #2":"11.5496"},
      { "name:":"10/12/21 10:29:51  PM","Dehumidifier":"7.8950","Pump #2":"11.3710"},
      { "name:":"10/12/21 10:30:51  PM","Dehumidifier":"7.8355","Pump #2":"11.0643"},
      { "name:":"10/12/21 10:31:51  PM","Dehumidifier":"7.8492","Pump #2":"11.5160"},
      { "name:":"10/12/21 10:32:51  PM","Dehumidifier":"7.8676","Pump #2":"11.5190"},
      { "name:":"10/12/21 10:33:51  PM","Dehumidifier":"7.8279","Pump #2":"11.2490"},
      { "name:":"10/12/21 10:34:51  PM","Dehumidifier":"7.8599","Pump #2":"11.3268"},
      { "name:":"10/12/21 10:35:51  PM","Dehumidifier":"7.8492","Pump #2":"11.3329"},
      { "name:":"10/12/21 10:36:51  PM","Dehumidifier":"7.8660","Pump #2":"11.2367"},
      { "name:":"10/12/21 10:37:51  PM","Dehumidifier":"7.8981","Pump #2":"11.5541"},
      { "name:":"10/12/21 10:38:51  PM","Dehumidifier":"8.1117","Pump #2":"11.2428"},
      { "name:":"10/12/21 10:39:51  PM","Dehumidifier":"31.3878","Pump #2":"11.5190"},
      { "name:":"10/12/21 10:40:51  PM","Dehumidifier":"32.2087","Pump #2":"11.4870"},
      { "name:":"10/12/21 10:41:51  PM","Dehumidifier":"32.5780","Pump #2":"11.2749"},
      { "name:":"10/12/21 10:42:51  PM","Dehumidifier":"32.5383","Pump #2":"11.5312"},
      { "name:":"10/12/21 10:43:51  PM","Dehumidifier":"33.1334","Pump #2":"11.4260"},
      { "name:":"10/12/21 10:44:51  PM","Dehumidifier":"34.2641","Pump #2":"11.3222"},
      { "name:":"10/12/21 10:45:51  PM","Dehumidifier":"33.7682","Pump #2":"10.8568"},
      { "name:":"10/12/21 10:46:51  PM","Dehumidifier":"33.6202","Pump #2":"11.4900"},
      { "name:":"10/12/21 10:47:51  PM","Dehumidifier":"33.1044","Pump #2":"11.2093"},
      { "name:":"10/12/21 10:48:51  PM","Dehumidifier":"31.8593","Pump #2":"11.1192"},
      { "name:":"10/12/21 10:49:51  PM","Dehumidifier":"31.4076","Pump #2":"10.9285"},
      { "name:":"10/12/21 10:50:51  PM","Dehumidifier":"31.4122","Pump #2":"10.9651"},
      { "name:":"10/12/21 10:51:51  PM","Dehumidifier":"31.5846","Pump #2":"11.3329"},
      { "name:":"10/12/21 10:52:51  PM","Dehumidifier":"31.6197","Pump #2":"10.8843"},
      { "name:":"10/12/21 10:53:51  PM","Dehumidifier":"31.6228","Pump #2":"10.9422"},
      { "name:":"10/12/21 10:54:51  PM","Dehumidifier":"31.5328","Pump #2":"11.2261"},
      { "name:":"10/12/21 10:55:51  PM","Dehumidifier":"31.5450","Pump #2":"11.2566"},
      { "name:":"10/12/21 10:56:51  PM","Dehumidifier":"31.4549","Pump #2":"10.9636"},
      { "name:":"10/12/21 10:57:51  PM","Dehumidifier":"31.3512","Pump #2":"11.3039"},
      { "name:":"10/12/21 10:58:51  PM","Dehumidifier":"31.4351","Pump #2":"11.5328"},
      { "name:":"10/12/21 10:59:51  PM","Dehumidifier":"31.2306","Pump #2":"11.2825"},
      { "name:":"10/12/21 11:00:51  PM","Dehumidifier":"31.1086","Pump #2":"10.8721"},
      { "name:":"10/12/21 11:01:51  PM","Dehumidifier":"31.1543","Pump #2":"10.9514"},
      { "name:":"10/12/21 11:02:51  PM","Dehumidifier":"31.2169","Pump #2":"10.9804"},
      { "name:":"10/12/21 11:03:51  PM","Dehumidifier":"31.2276","Pump #2":"11.0903"},
      { "name:":"10/12/21 11:04:51  PM","Dehumidifier":"31.1604","Pump #2":"10.8995"},
      { "name:":"10/12/21 11:05:51  PM","Dehumidifier":"7.9164","Pump #2":"10.7881"},
      { "name:":"10/12/21 11:06:51  PM","Dehumidifier":"7.9576","Pump #2":"11.0124"},
      { "name:":"10/12/21 11:07:51  PM","Dehumidifier":"7.9438","Pump #2":"11.2413"},
      { "name:":"10/12/21 11:08:51  PM","Dehumidifier":"7.9713","Pump #2":"10.9560"},
      { "name:":"10/12/21 11:09:51  PM","Dehumidifier":"7.9164","Pump #2":"11.0399"},
      { "name:":"10/12/21 11:10:51  PM","Dehumidifier":"7.9255","Pump #2":"11.4122"},
      { "name:":"10/12/21 11:11:51  PM","Dehumidifier":"7.9301","Pump #2":"11.3130"},
      { "name:":"10/12/21 11:12:51  PM","Dehumidifier":"7.8813","Pump #2":"10.9590"},
      { "name:":"10/12/21 11:13:51  PM","Dehumidifier":"7.9316","Pump #2":"11.2734"},
      { "name:":"10/12/21 11:14:51  PM","Dehumidifier":"7.8889","Pump #2":"11.3924"},
      { "name:":"10/12/21 11:15:51  PM","Dehumidifier":"7.9454","Pump #2":"11.4382"},
      { "name:":"10/12/21 11:16:51  PM","Dehumidifier":"7.8950","Pump #2":"11.2413"},
      { "name:":"10/12/21 11:17:51  PM","Dehumidifier":"7.9133","Pump #2":"11.4366"},
      { "name:":"10/12/21 11:18:51  PM","Dehumidifier":"7.8676","Pump #2":"11.1727"},
      { "name:":"10/12/21 11:19:51  PM","Dehumidifier":"7.9088","Pump #2":"10.9026"},
      { "name:":"10/12/21 11:20:51  PM","Dehumidifier":"7.8904","Pump #2":"11.2734"},
      { "name:":"10/12/21 11:21:51  PM","Dehumidifier":"7.9026","Pump #2":"10.7286"},
      { "name:":"10/12/21 11:22:51  PM","Dehumidifier":"7.8782","Pump #2":"11.2657"},
      { "name:":"10/12/21 11:23:51  PM","Dehumidifier":"7.8737","Pump #2":"11.1620"},
      { "name:":"10/12/21 11:24:51  PM","Dehumidifier":"7.8920","Pump #2":"10.9300"},
      { "name:":"10/12/21 11:25:51  PM","Dehumidifier":"7.8370","Pump #2":"10.9895"},
      { "name:":"10/12/21 11:26:51  PM","Dehumidifier":"7.8706","Pump #2":"11.1910"},
      { "name:":"10/12/21 11:27:51  PM","Dehumidifier":"7.8538","Pump #2":"11.0857"},
      { "name:":"10/12/21 11:28:51  PM","Dehumidifier":"7.8676","Pump #2":"11.0689"},
      { "name:":"10/12/21 11:29:51  PM","Dehumidifier":"7.8706","Pump #2":"11.2551"},
      { "name:":"10/12/21 11:30:51  PM","Dehumidifier":"7.8828","Pump #2":"10.9026"},
      { "name:":"10/12/21 11:31:51  PM","Dehumidifier":"7.8599","Pump #2":"10.9941"},
      { "name:":"10/12/21 11:32:51  PM","Dehumidifier":"7.8630","Pump #2":"11.1696"},
      { "name:":"10/12/21 11:33:51  PM","Dehumidifier":"7.8340","Pump #2":"10.8675"},
      { "name:":"10/12/21 11:34:51  PM","Dehumidifier":"7.8965","Pump #2":"11.0948"},
      { "name:":"10/12/21 11:35:51  PM","Dehumidifier":"7.8889","Pump #2":"10.9071"},
{ "name:":"10/12/21 11:36:51  PM","Dehumidifier":"7.8462","Pump #2":"11.0323"},
{ "name:":"10/12/21 11:37:51  PM","Dehumidifier":"7.8508","Pump #2":"11.2871"},
{ "name:":"10/12/21 11:38:51  PM","Dehumidifier":"7.8904","Pump #2":"10.9819"},
{ "name:":"10/12/21 11:39:51  PM","Dehumidifier":"7.8477","Pump #2":"11.0491"},
{ "name:":"10/12/21 11:40:51  PM","Dehumidifier":"7.8477","Pump #2":"11.3832"},
{ "name:":"10/12/21 11:41:51  PM","Dehumidifier":"7.8553","Pump #2":"11.1238"},
{ "name:":"10/12/21 11:42:51  PM","Dehumidifier":"7.8462","Pump #2":"11.1040"},
{ "name:":"10/12/21 11:43:51  PM","Dehumidifier":"7.8523","Pump #2":"11.1055"},
{ "name:":"10/12/21 11:44:51  PM","Dehumidifier":"7.8645","Pump #2":"11.4244"},
{ "name:":"10/12/21 11:45:51  PM","Dehumidifier":"7.8492","Pump #2":"10.5653"},
{ "name:":"10/12/21 11:46:51  PM","Dehumidifier":"7.8492","Pump #2":"10.7485"},
{ "name:":"10/12/21 11:47:51  PM","Dehumidifier":"7.8538","Pump #2":"10.8904"},
{ "name:":"10/12/21 11:48:51  PM","Dehumidifier":"7.8340","Pump #2":"10.9102"},
{ "name:":"10/12/21 11:49:51  PM","Dehumidifier":"7.8309","Pump #2":"11.2459"},
{ "name:":"10/12/21 11:50:51  PM","Dehumidifier":"7.8325","Pump #2":"10.9010"},
{ "name:":"10/12/21 11:51:51  PM","Dehumidifier":"7.8538","Pump #2":"11.1742"},
{ "name:":"10/12/21 11:52:51  PM","Dehumidifier":"7.8904","Pump #2":"11.1086"},
{ "name:":"10/12/21 11:53:51  PM","Dehumidifier":"7.8645","Pump #2":"11.1482"},
{ "name:":"10/12/21 11:54:51  PM","Dehumidifier":"7.8569","Pump #2":"11.2856"},
{ "name:":"10/12/21 11:55:51  PM","Dehumidifier":"7.8538","Pump #2":"11.1879"},
{ "name:":"10/12/21 11:56:51  PM","Dehumidifier":"7.8431","Pump #2":"11.1315"},
{ "name:":"10/12/21 11:57:51  PM","Dehumidifier":"7.8599","Pump #2":"11.0033"},
{ "name:":"10/12/21 11:58:51  PM","Dehumidifier":"7.8737","Pump #2":"10.7485"},
{ "name:":"10/12/21 11:59:51  PM","Dehumidifier":"7.8706","Pump #2":"11.1391"},
{ "name:":"10/13/21 12:00:51  AM","Dehumidifier":"7.8523","Pump #2":"11.2642"},
{ "name:":"10/13/21 12:01:51  AM","Dehumidifier":"7.8737","Pump #2":"11.1742"},
{ "name:":"10/13/21 12:02:51  AM","Dehumidifier":"7.8523","Pump #2":"11.2810"},
{ "name:":"10/13/21 12:03:51  AM","Dehumidifier":"7.8279","Pump #2":"10.7408"},
{ "name:":"10/13/21 12:04:51  AM","Dehumidifier":"7.8141","Pump #2":"10.8598"},
{ "name:":"10/13/21 12:05:51  AM","Dehumidifier":"7.8614","Pump #2":"10.9575"},
{ "name:":"10/13/21 12:06:51  AM","Dehumidifier":"7.8752","Pump #2":"11.1131"},
{ "name:":"10/13/21 12:07:51  AM","Dehumidifier":"7.8477","Pump #2":"10.6126"},
{ "name:":"10/13/21 12:08:51  AM","Dehumidifier":"7.8462","Pump #2":"10.7561"},
{ "name:":"10/13/21 12:09:51  AM","Dehumidifier":"7.8645","Pump #2":"11.0750"},
{ "name:":"10/13/21 12:10:51  AM","Dehumidifier":"7.8737","Pump #2":"10.8797"},
{ "name:":"10/13/21 12:11:51  AM","Dehumidifier":"7.8798","Pump #2":"11.1894"},
{ "name:":"10/13/21 12:12:51  AM","Dehumidifier":"7.8355","Pump #2":"10.2892"},
{ "name:":"10/13/21 12:13:51  AM","Dehumidifier":"7.8386","Pump #2":"10.6691"},
{ "name:":"10/13/21 12:14:51  AM","Dehumidifier":"7.8126","Pump #2":"10.9651"},
{ "name:":"10/13/21 12:15:51  AM","Dehumidifier":"7.8599","Pump #2":"11.0536"},
{ "name:":"10/13/21 12:16:51  AM","Dehumidifier":"7.8569","Pump #2":"10.8400"},
{ "name:":"10/13/21 12:17:51  AM","Dehumidifier":"7.8630","Pump #2":"11.1025"},
{ "name:":"10/13/21 12:18:51  AM","Dehumidifier":"7.8553","Pump #2":"11.1070"},
{ "name:":"10/13/21 12:19:51  AM","Dehumidifier":"7.8660","Pump #2":"11.2444"},
{ "name:":"10/13/21 12:20:51  AM","Dehumidifier":"7.8477","Pump #2":"11.2169"},
{ "name:":"10/13/21 12:21:51  AM","Dehumidifier":"7.8935","Pump #2":"10.5867"},
{ "name:":"10/13/21 12:22:51  AM","Dehumidifier":"7.8721","Pump #2":"11.0613"},
{ "name:":"10/13/21 12:23:51  AM","Dehumidifier":"7.8630","Pump #2":"10.4372"},
{ "name:":"10/13/21 12:24:51  AM","Dehumidifier":"7.8538","Pump #2":"10.8598"},
{ "name:":"10/13/21 12:25:51  AM","Dehumidifier":"7.8935","Pump #2":"10.7240"},
{ "name:":"10/13/21 12:26:51  AM","Dehumidifier":"7.8767","Pump #2":"11.0735"},
{ "name:":"10/13/21 12:27:51  AM","Dehumidifier":"7.8614","Pump #2":"10.6874"},
{ "name:":"10/13/21 12:28:51  AM","Dehumidifier":"7.8752","Pump #2":"11.0521"},
{ "name:":"10/13/21 12:29:51  AM","Dehumidifier":"7.8569","Pump #2":"10.8186"},
{ "name:":"10/13/21 12:30:51  AM","Dehumidifier":"29.9413","Pump #2":"10.8782"},
{ "name:":"10/13/21 12:31:51  AM","Dehumidifier":"31.5221","Pump #2":"10.9987"},
{ "name:":"10/13/21 12:32:51  AM","Dehumidifier":"32.8573","Pump #2":"10.9636"},
{ "name:":"10/13/21 12:33:51  AM","Dehumidifier":"33.6049","Pump #2":"10.8110"},
{ "name:":"10/13/21 12:34:51  AM","Dehumidifier":"33.8613","Pump #2":"11.3909"},
{ "name:":"10/13/21 12:35:51  AM","Dehumidifier":"34.1192","Pump #2":"10.9529"},
{ "name:":"10/13/21 12:36:51  AM","Dehumidifier":"34.0261","Pump #2":"11.0994"},
{ "name:":"10/13/21 12:37:51  AM","Dehumidifier":"33.7530","Pump #2":"11.2261"},
{ "name:":"10/13/21 12:38:51  AM","Dehumidifier":"33.5637","Pump #2":"10.7652"},
{ "name:":"10/13/21 12:39:51  AM","Dehumidifier":"33.1106","Pump #2":"10.8980"},
{ "name:":"10/13/21 12:40:51  AM","Dehumidifier":"32.5216","Pump #2":"10.8568"},
{ "name:":"10/13/21 12:41:51  AM","Dehumidifier":"32.1187","Pump #2":"10.7073"},
{ "name:":"10/13/21 12:42:51  AM","Dehumidifier":"32.0256","Pump #2":"11.2932"},
{ "name:":"10/13/21 12:43:51  AM","Dehumidifier":"31.8227","Pump #2":"10.9987"},
{ "name:":"10/13/21 12:44:51  AM","Dehumidifier":"31.6884","Pump #2":"10.7210"},
{ "name:":"10/13/21 12:45:51  AM","Dehumidifier":"31.5877","Pump #2":"10.9728"},
{ "name:":"10/13/21 12:46:51  AM","Dehumidifier":"7.8874","Pump #2":"10.9255"},
{ "name:":"10/13/21 12:47:51  AM","Dehumidifier":"7.9408","Pump #2":"11.1162"},
{ "name:":"10/13/21 12:48:51  AM","Dehumidifier":"7.9072","Pump #2":"10.7897"},
{ "name:":"10/13/21 12:49:51  AM","Dehumidifier":"7.9255","Pump #2":"11.0780"},
{ "name:":"10/13/21 12:50:51  AM","Dehumidifier":"7.9026","Pump #2":"11.3191"},
{ "name:":"10/13/21 12:51:51  AM","Dehumidifier":"7.8981","Pump #2":"11.2932"},
{ "name:":"10/13/21 12:52:51  AM","Dehumidifier":"7.8813","Pump #2":"11.2032"},
{ "name:":"10/13/21 12:53:51  AM","Dehumidifier":"7.9026","Pump #2":"11.1635"},
{ "name:":"10/13/21 12:54:51  AM","Dehumidifier":"7.9072","Pump #2":"10.7134"},
{ "name:":"10/13/21 12:55:51  AM","Dehumidifier":"7.8920","Pump #2":"11.0506"},
{ "name:":"10/13/21 12:56:51  AM","Dehumidifier":"7.8935","Pump #2":"10.8278"},
{ "name:":"10/13/21 12:57:51  AM","Dehumidifier":"7.9240","Pump #2":"10.7027"},
{ "name:":"10/13/21 12:58:51  AM","Dehumidifier":"7.8782","Pump #2":"10.8431"},
{ "name:":"10/13/21 12:59:51  AM","Dehumidifier":"7.8950","Pump #2":"10.8385"},
{ "name:":"10/13/21 01:00:51  AM","Dehumidifier":"7.8935","Pump #2":"10.4082"},
{ "name:":"10/13/21 01:01:51  AM","Dehumidifier":"7.9316","Pump #2":"11.0262"},
{ "name:":"10/13/21 01:02:51  AM","Dehumidifier":"7.8859","Pump #2":"10.9209"},
{ "name:":"10/13/21 01:03:51  AM","Dehumidifier":"7.8859","Pump #2":"10.6691"},
{ "name:":"10/13/21 01:04:51  AM","Dehumidifier":"7.8660","Pump #2":"10.9102"},
{ "name:":"10/13/21 01:05:51  AM","Dehumidifier":"7.8157","Pump #2":"10.9667"},
{ "name:":"10/13/21 01:06:51  AM","Dehumidifier":"7.9149","Pump #2":"10.8583"},
{ "name:":"10/13/21 01:07:51  AM","Dehumidifier":"7.8706","Pump #2":"11.0628"},
{ "name:":"10/13/21 01:08:51  AM","Dehumidifier":"7.8965","Pump #2":"10.2892"},
{ "name:":"10/13/21 01:09:51  AM","Dehumidifier":"7.9118","Pump #2":"11.1482"},
{ "name:":"10/13/21 01:10:51  AM","Dehumidifier":"7.8325","Pump #2":"10.9926"},
{ "name:":"10/13/21 01:11:51  AM","Dehumidifier":"7.8645","Pump #2":"10.9804"},
{ "name:":"10/13/21 01:12:51  AM","Dehumidifier":"7.8721","Pump #2":"10.8949"},
{ "name:":"10/13/21 01:13:51  AM","Dehumidifier":"7.8996","Pump #2":"10.8843"},
{ "name:":"10/13/21 01:14:51  AM","Dehumidifier":"7.9042","Pump #2":"11.1971"},
{ "name:":"10/13/21 01:15:51  AM","Dehumidifier":"7.9194","Pump #2":"10.5470"},
{ "name:":"10/13/21 01:16:51  AM","Dehumidifier":"7.8828","Pump #2":"10.9651"},
{ "name:":"10/13/21 01:17:51  AM","Dehumidifier":"7.8859","Pump #2":"11.8730"},
{ "name:":"10/13/21 01:18:51  AM","Dehumidifier":"7.8935","Pump #2":"11.3802"},
{ "name:":"10/13/21 01:19:51  AM","Dehumidifier":"7.8828","Pump #2":"10.9300"},
{ "name:":"10/13/21 01:20:51  AM","Dehumidifier":"7.8920","Pump #2":"11.0124"},
{ "name:":"10/13/21 01:21:51  AM","Dehumidifier":"7.9225","Pump #2":"10.5364"},
{ "name:":"10/13/21 01:22:51  AM","Dehumidifier":"7.8492","Pump #2":"11.0521"},
{ "name:":"10/13/21 01:23:51  AM","Dehumidifier":"7.8813","Pump #2":"10.7912"},
{ "name:":"10/13/21 01:24:51  AM","Dehumidifier":"7.8706","Pump #2":"10.8705"},
{ "name:":"10/13/21 01:25:51  AM","Dehumidifier":"7.9271","Pump #2":"11.0628"},
{ "name:":"10/13/21 01:26:51  AM","Dehumidifier":"7.8737","Pump #2":"11.0552"},
{ "name:":"10/13/21 01:27:51  AM","Dehumidifier":"7.8645","Pump #2":"10.6874"},
{ "name:":"10/13/21 01:28:51  AM","Dehumidifier":"7.8950","Pump #2":"10.8034"},
{ "name:":"10/13/21 01:29:51  AM","Dehumidifier":"7.8859","Pump #2":"10.9972"},
{ "name:":"10/13/21 01:30:51  AM","Dehumidifier":"7.9133","Pump #2":"10.5699"},
{ "name:":"10/13/21 01:31:51  AM","Dehumidifier":"7.8950","Pump #2":"10.5013"},
{ "name:":"10/13/21 01:32:51  AM","Dehumidifier":"7.8737","Pump #2":"11.1528"},
{ "name:":"10/13/21 01:33:51  AM","Dehumidifier":"7.8492","Pump #2":"10.9133"},
{ "name:":"10/13/21 01:34:51  AM","Dehumidifier":"7.8492","Pump #2":"11.1696"},
{ "name:":"10/13/21 01:35:51  AM","Dehumidifier":"7.8965","Pump #2":"11.1925"},
{ "name:":"10/13/21 01:36:51  AM","Dehumidifier":"7.8309","Pump #2":"10.9834"},
{ "name:":"10/13/21 01:37:51  AM","Dehumidifier":"7.8981","Pump #2":"11.0033"},
{ "name:":"10/13/21 01:38:51  AM","Dehumidifier":"7.8630","Pump #2":"10.6920"},
{ "name:":"10/13/21 01:39:51  AM","Dehumidifier":"7.8416","Pump #2":"10.9468"},
{ "name:":"10/13/21 01:40:51  AM","Dehumidifier":"7.8614","Pump #2":"11.0018"},
{ "name:":"10/13/21 01:41:51  AM","Dehumidifier":"7.8630","Pump #2":"11.0353"},
{ "name:":"10/13/21 01:42:51  AM","Dehumidifier":"7.8508","Pump #2":"10.6798"},
{ "name:":"10/13/21 01:43:51  AM","Dehumidifier":"7.8752","Pump #2":"10.8705"},
{ "name:":"10/13/21 01:44:51  AM","Dehumidifier":"7.8737","Pump #2":"11.1162"},
{ "name:":"10/13/21 01:45:51  AM","Dehumidifier":"7.8889","Pump #2":"10.8659"},
{ "name:":"10/13/21 01:46:51  AM","Dehumidifier":"7.8752","Pump #2":"10.8965"},
{ "name:":"10/13/21 01:47:51  AM","Dehumidifier":"7.8767","Pump #2":"10.7378"},
{ "name:":"10/13/21 01:48:51  AM","Dehumidifier":"7.8950","Pump #2":"10.8751"},
{ "name:":"10/13/21 01:49:51  AM","Dehumidifier":"7.8782","Pump #2":"11.1589"},
{ "name:":"10/13/21 01:50:51  AM","Dehumidifier":"7.8294","Pump #2":"10.8492"},
{ "name:":"10/13/21 01:51:51  AM","Dehumidifier":"7.8874","Pump #2":"10.5760"},
{ "name:":"10/13/21 01:52:51  AM","Dehumidifier":"7.9026","Pump #2":"11.0063"},
{ "name:":"10/13/21 01:53:51  AM","Dehumidifier":"7.8584","Pump #2":"10.8034"},
{ "name:":"10/13/21 01:54:51  AM","Dehumidifier":"7.8782","Pump #2":"11.0445"},
{ "name:":"10/13/21 01:55:51  AM","Dehumidifier":"7.8569","Pump #2":"10.9041"},
{ "name:":"10/13/21 01:56:51  AM","Dehumidifier":"7.8477","Pump #2":"10.4356"},
{ "name:":"10/13/21 01:57:51  AM","Dehumidifier":"7.8782","Pump #2":"10.7485"},
{ "name:":"10/13/21 01:58:51  AM","Dehumidifier":"7.8843","Pump #2":"11.0018"},
{ "name:":"10/13/21 01:59:51  AM","Dehumidifier":"7.8828","Pump #2":"11.0597"},
{ "name:":"10/13/21 02:00:51  AM","Dehumidifier":"7.8599","Pump #2":"10.9239"},
{ "name:":"10/13/21 02:01:51  AM","Dehumidifier":"7.8828","Pump #2":"11.2810"},
{ "name:":"10/13/21 02:02:51  AM","Dehumidifier":"7.8676","Pump #2":"10.7729"},
{ "name:":"10/13/21 02:03:51  AM","Dehumidifier":"7.8706","Pump #2":"10.9041"},
{ "name:":"10/13/21 02:04:51  AM","Dehumidifier":"7.8508","Pump #2":"10.9010"},
{ "name:":"10/13/21 02:05:51  AM","Dehumidifier":"7.8752","Pump #2":"10.7805"},
{ "name:":"10/13/21 02:06:51  AM","Dehumidifier":"7.8782","Pump #2":"10.9483"},
{ "name:":"10/13/21 02:07:51  AM","Dehumidifier":"7.9103","Pump #2":"11.1254"},
{ "name:":"10/13/21 02:08:51  AM","Dehumidifier":"7.8645","Pump #2":"10.4219"},
{ "name:":"10/13/21 02:09:51  AM","Dehumidifier":"7.8691","Pump #2":"11.0750"},
{ "name:":"10/13/21 02:10:51  AM","Dehumidifier":"7.8523","Pump #2":"10.8324"},
{ "name:":"10/13/21 02:11:51  AM","Dehumidifier":"7.8294","Pump #2":"10.8614"},
{ "name:":"10/13/21 02:12:51  AM","Dehumidifier":"7.8798","Pump #2":"10.9895"},
{ "name:":"10/13/21 02:13:51  AM","Dehumidifier":"7.8737","Pump #2":"11.0491"},
{ "name:":"10/13/21 02:14:51  AM","Dehumidifier":"7.8614","Pump #2":"10.7637"},
{ "name:":"10/13/21 02:15:51  AM","Dehumidifier":"7.8843","Pump #2":"10.8415"},
{ "name:":"10/13/21 02:16:51  AM","Dehumidifier":"7.8859","Pump #2":"11.1421"},
{ "name:":"10/13/21 02:17:51  AM","Dehumidifier":"7.8370","Pump #2":"11.0933"},
{ "name:":"10/13/21 02:18:51  AM","Dehumidifier":"7.8645","Pump #2":"10.9239"},
{ "name:":"10/13/21 02:19:51  AM","Dehumidifier":"7.8309","Pump #2":"10.7866"},
{ "name:":"10/13/21 02:20:51  AM","Dehumidifier":"7.8569","Pump #2":"10.6111"},
{ "name:":"10/13/21 02:21:51  AM","Dehumidifier":"7.8614","Pump #2":"10.8598"},
{ "name:":"10/13/21 02:22:51  AM","Dehumidifier":"7.8202","Pump #2":"10.6676"},
{ "name:":"10/13/21 02:23:51  AM","Dehumidifier":"7.8553","Pump #2":"10.6600"},
{ "name:":"10/13/21 02:24:51  AM","Dehumidifier":"7.8462","Pump #2":"10.6752"},
{ "name:":"10/13/21 02:25:51  AM","Dehumidifier":"7.8492","Pump #2":"10.8705"},
{ "name:":"10/13/21 02:26:51  AM","Dehumidifier":"7.8508","Pump #2":"10.4784"},
{ "name:":"10/13/21 02:27:51  AM","Dehumidifier":"7.8340","Pump #2":"10.4662"},
{ "name:":"10/13/21 02:28:51  AM","Dehumidifier":"7.8538","Pump #2":"10.4204"},
{ "name:":"10/13/21 02:29:51  AM","Dehumidifier":"7.8721","Pump #2":"10.7591"},
{ "name:":"10/13/21 02:30:51  AM","Dehumidifier":"7.8431","Pump #2":"11.0338"},
{ "name:":"10/13/21 02:31:51  AM","Dehumidifier":"7.8706","Pump #2":"10.9346"},
{ "name:":"10/13/21 02:32:51  AM","Dehumidifier":"7.8431","Pump #2":"10.6981"},
{ "name:":"10/13/21 02:33:51  AM","Dehumidifier":"7.9164","Pump #2":"10.9957"},
{ "name:":"10/13/21 02:34:51  AM","Dehumidifier":"7.8676","Pump #2":"10.8232"},
{ "name:":"10/13/21 02:35:51  AM","Dehumidifier":"7.8752","Pump #2":"11.2856"},
{ "name:":"10/13/21 02:36:51  AM","Dehumidifier":"7.8523","Pump #2":"10.6523"},
{ "name:":"10/13/21 02:37:51  AM","Dehumidifier":"7.8645","Pump #2":"10.9407"},
{ "name:":"10/13/21 02:38:51  AM","Dehumidifier":"7.8569","Pump #2":"10.6828"},
{ "name:":"10/13/21 02:39:51  AM","Dehumidifier":"7.8828","Pump #2":"10.9621"},
{ "name:":"10/13/21 02:40:51  AM","Dehumidifier":"7.8508","Pump #2":"10.7286"},
{ "name:":"10/13/21 02:41:51  AM","Dehumidifier":"7.8752","Pump #2":"10.4997"},
{ "name:":"10/13/21 02:42:51  AM","Dehumidifier":"7.8370","Pump #2":"10.8782"},
{ "name:":"10/13/21 02:43:51  AM","Dehumidifier":"7.8492","Pump #2":"11.2001"},
{ "name:":"10/13/21 02:44:51  AM","Dehumidifier":"7.9088","Pump #2":"11.0292"},
{ "name:":"10/13/21 02:45:51  AM","Dehumidifier":"7.8508","Pump #2":"10.8080"},
{ "name:":"10/13/21 02:46:51  AM","Dehumidifier":"7.8676","Pump #2":"10.4677"},
{ "name:":"10/13/21 02:47:51  AM","Dehumidifier":"7.8538","Pump #2":"10.9987"},
{ "name:":"10/13/21 02:48:51  AM","Dehumidifier":"7.8508","Pump #2":"10.3456"},
{ "name:":"10/13/21 02:49:51  AM","Dehumidifier":"7.8523","Pump #2":"10.8248"},
{ "name:":"10/13/21 02:50:51  AM","Dehumidifier":"7.8553","Pump #2":"10.5730"},
{ "name:":"10/13/21 02:51:51  AM","Dehumidifier":"7.8691","Pump #2":"10.6218"},
{ "name:":"10/13/21 02:52:51  AM","Dehumidifier":"7.8340","Pump #2":"10.9987"},
{ "name:":"10/13/21 02:53:51  AM","Dehumidifier":"7.8477","Pump #2":"10.8309"},
{ "name:":"10/13/21 02:54:51  AM","Dehumidifier":"7.8706","Pump #2":"10.9789"},
{ "name:":"10/13/21 02:55:51  AM","Dehumidifier":"7.8492","Pump #2":"11.4778"},
{ "name:":"10/13/21 02:56:51  AM","Dehumidifier":"7.8431","Pump #2":"10.8217"},
{ "name:":"10/13/21 02:57:51  AM","Dehumidifier":"7.8416","Pump #2":"10.4952"},
{ "name:":"10/13/21 02:58:51  AM","Dehumidifier":"7.8370","Pump #2":"10.9422"},
{ "name:":"10/13/21 02:59:51  AM","Dehumidifier":"7.8462","Pump #2":"10.8171"},
{ "name:":"10/13/21 03:00:51  AM","Dehumidifier":"7.8569","Pump #2":"10.7485"},
{ "name:":"10/13/21 03:01:51  AM","Dehumidifier":"7.8538","Pump #2":"10.5852"},
{ "name:":"10/13/21 03:02:51  AM","Dehumidifier":"7.8431","Pump #2":"11.0002"},
{ "name:":"10/13/21 03:03:51  AM","Dehumidifier":"7.8202","Pump #2":"10.9987"},
{ "name:":"10/13/21 03:04:51  AM","Dehumidifier":"7.8950","Pump #2":"10.8064"},
{ "name:":"10/13/21 03:05:51  AM","Dehumidifier":"7.8676","Pump #2":"10.6691"},
{ "name:":"10/13/21 03:06:51  AM","Dehumidifier":"7.8569","Pump #2":"10.5852"},
{ "name:":"10/13/21 03:07:51  AM","Dehumidifier":"7.8279","Pump #2":"10.8003"},
{ "name:":"10/13/21 03:08:51  AM","Dehumidifier":"7.8660","Pump #2":"10.6950"},
{ "name:":"10/13/21 03:09:51  AM","Dehumidifier":"7.8614","Pump #2":"10.5455"},
{ "name:":"10/13/21 03:10:51  AM","Dehumidifier":"7.8233","Pump #2":"10.7027"},
{ "name:":"10/13/21 03:11:51  AM","Dehumidifier":"7.8538","Pump #2":"10.9483"},
{ "name:":"10/13/21 03:12:51  AM","Dehumidifier":"7.8248","Pump #2":"10.6081"},
{ "name:":"10/13/21 03:13:51  AM","Dehumidifier":"7.8676","Pump #2":"10.4921"},
{ "name:":"10/13/21 03:14:51  AM","Dehumidifier":"7.8584","Pump #2":"10.6065"},
{ "name:":"10/13/21 03:15:51  AM","Dehumidifier":"7.8859","Pump #2":"10.6722"},
{ "name:":"10/13/21 03:16:51  AM","Dehumidifier":"7.8508","Pump #2":"10.6752"},
{ "name:":"10/13/21 03:17:51  AM","Dehumidifier":"7.8401","Pump #2":"10.7210"},
{ "name:":"10/13/21 03:18:51  AM","Dehumidifier":"7.8508","Pump #2":"10.8202"},
{ "name:":"10/13/21 03:19:51  AM","Dehumidifier":"7.8386","Pump #2":"10.8476"},
{ "name:":"10/13/21 03:20:51  AM","Dehumidifier":"7.8325","Pump #2":"11.0933"},
{ "name:":"10/13/21 03:21:51  AM","Dehumidifier":"7.8782","Pump #2":"10.4875"},
{ "name:":"10/13/21 03:22:51  AM","Dehumidifier":"7.8569","Pump #2":"10.9438"},
{ "name:":"10/13/21 03:23:51  AM","Dehumidifier":"7.8676","Pump #2":"10.4341"},
{ "name:":"10/13/21 03:24:51  AM","Dehumidifier":"7.8630","Pump #2":"11.2535"},
{ "name:":"10/13/21 03:25:51  AM","Dehumidifier":"7.8737","Pump #2":"10.6889"},
{ "name:":"10/13/21 03:26:51  AM","Dehumidifier":"7.8691","Pump #2":"10.8598"},
{ "name:":"10/13/21 03:27:51  AM","Dehumidifier":"7.8218","Pump #2":"11.3222"},
{ "name:":"10/13/21 03:28:51  AM","Dehumidifier":"7.8721","Pump #2":"10.9972"},
{ "name:":"10/13/21 03:29:51  AM","Dehumidifier":"7.8569","Pump #2":"11.1467"},
{ "name:":"10/13/21 03:30:51  AM","Dehumidifier":"7.8477","Pump #2":"10.7271"},
{ "name:":"10/13/21 03:31:51  AM","Dehumidifier":"7.8645","Pump #2":"11.2215"},
{ "name:":"10/13/21 03:32:51  AM","Dehumidifier":"7.8462","Pump #2":"10.8858"},
{ "name:":"10/13/21 03:33:51  AM","Dehumidifier":"7.8737","Pump #2":"10.4891"},
{ "name:":"10/13/21 03:34:51  AM","Dehumidifier":"7.8950","Pump #2":"10.7362"},
{ "name:":"10/13/21 03:35:51  AM","Dehumidifier":"7.8981","Pump #2":"10.4219"},
{ "name:":"10/13/21 03:36:51  AM","Dehumidifier":"7.8843","Pump #2":"10.5180"},
{ "name:":"10/13/21 03:37:51  AM","Dehumidifier":"7.8660","Pump #2":"10.3883"},
{ "name:":"10/13/21 03:38:51  AM","Dehumidifier":"7.8737","Pump #2":"10.5241"},
{ "name:":"10/13/21 03:39:51  AM","Dehumidifier":"7.9011","Pump #2":"10.5425"},
{ "name:":"10/13/21 03:40:51  AM","Dehumidifier":"7.8782","Pump #2":"10.2602"},
{ "name:":"10/13/21 03:41:51  AM","Dehumidifier":"7.8767","Pump #2":"10.9346"},
{ "name:":"10/13/21 03:42:51  AM","Dehumidifier":"7.8706","Pump #2":"10.8934"},
{ "name:":"10/13/21 03:43:51  AM","Dehumidifier":"7.8355","Pump #2":"10.7713"},
{ "name:":"10/13/21 03:44:51  AM","Dehumidifier":"7.8340","Pump #2":"10.2708"},
{ "name:":"10/13/21 03:45:51  AM","Dehumidifier":"7.8691","Pump #2":"10.5821"},
{ "name:":"10/13/21 03:46:51  AM","Dehumidifier":"7.8584","Pump #2":"10.5669"},
{ "name:":"10/13/21 03:47:51  AM","Dehumidifier":"7.8920","Pump #2":"10.7027"},
{ "name:":"10/13/21 03:48:51  AM","Dehumidifier":"7.8431","Pump #2":"10.7744"},
{ "name:":"10/13/21 03:49:51  AM","Dehumidifier":"7.8721","Pump #2":"10.6035"},
{ "name:":"10/13/21 03:50:51  AM","Dehumidifier":"7.8523","Pump #2":"10.9224"},
{ "name:":"10/13/21 03:51:51  AM","Dehumidifier":"7.8416","Pump #2":"10.9941"},
{ "name:":"10/13/21 03:52:51  AM","Dehumidifier":"7.8599","Pump #2":"10.5974"},
{ "name:":"10/13/21 03:53:51  AM","Dehumidifier":"7.8431","Pump #2":"11.1238"},
{ "name:":"10/13/21 03:54:51  AM","Dehumidifier":"7.7943","Pump #2":"10.5119"},
{ "name:":"10/13/21 03:55:51  AM","Dehumidifier":"7.8416","Pump #2":"10.7317"},
{ "name:":"10/13/21 03:56:51  AM","Dehumidifier":"7.8630","Pump #2":"11.0430"},
{ "name:":"10/13/21 03:57:51  AM","Dehumidifier":"7.8355","Pump #2":"10.9102"},
{ "name:":"10/13/21 03:58:51  AM","Dehumidifier":"7.8401","Pump #2":"11.0155"},
{ "name:":"10/13/21 03:59:51  AM","Dehumidifier":"7.8508","Pump #2":"10.9697"},
{ "name:":"10/13/21 04:00:51  AM","Dehumidifier":"7.8706","Pump #2":"10.4997"},
{ "name:":"10/13/21 04:01:51  AM","Dehumidifier":"7.9194","Pump #2":"10.6111"},
{ "name:":"10/13/21 04:02:51  AM","Dehumidifier":"7.9042","Pump #2":"10.1076"},
{ "name:":"10/13/21 04:03:51  AM","Dehumidifier":"7.8843","Pump #2":"10.5592"},
{ "name:":"10/13/21 04:04:51  AM","Dehumidifier":"7.9103","Pump #2":"10.7317"},
{ "name:":"10/13/21 04:05:51  AM","Dehumidifier":"7.8859","Pump #2":"10.7866"},
{ "name:":"10/13/21 04:06:51  AM","Dehumidifier":"7.9316","Pump #2":"10.8537"},
{ "name:":"10/13/21 04:07:51  AM","Dehumidifier":"7.9057","Pump #2":"11.0201"},
{ "name:":"10/13/21 04:08:51  AM","Dehumidifier":"7.8859","Pump #2":"11.0246"},
{ "name:":"10/13/21 04:09:51  AM","Dehumidifier":"7.8798","Pump #2":"10.8003"},
{ "name:":"10/13/21 04:10:51  AM","Dehumidifier":"7.8843","Pump #2":"10.9911"},
{ "name:":"10/13/21 04:11:51  AM","Dehumidifier":"7.8584","Pump #2":"11.0567"},
{ "name:":"10/13/21 04:12:51  AM","Dehumidifier":"7.8691","Pump #2":"10.9697"},
{ "name:":"10/13/21 04:13:51  AM","Dehumidifier":"7.8904","Pump #2":"11.2062"},
{ "name:":"10/13/21 04:14:51  AM","Dehumidifier":"7.8828","Pump #2":"10.7332"},
{ "name:":"10/13/21 04:15:51  AM","Dehumidifier":"7.8737","Pump #2":"11.0521"},
{ "name:":"10/13/21 04:16:51  AM","Dehumidifier":"7.8508","Pump #2":"11.3176"},
{ "name:":"10/13/21 04:17:51  AM","Dehumidifier":"7.9194","Pump #2":"11.0414"},
{ "name:":"10/13/21 04:18:51  AM","Dehumidifier":"7.8492","Pump #2":"10.3273"},
{ "name:":"10/13/21 04:19:51  AM","Dehumidifier":"7.8874","Pump #2":"11.0521"},
{ "name:":"10/13/21 04:20:51  AM","Dehumidifier":"7.9088","Pump #2":"11.2261"},
{ "name:":"10/13/21 04:21:51  AM","Dehumidifier":"7.9301","Pump #2":"11.0338"},
{ "name:":"10/13/21 04:22:51  AM","Dehumidifier":"7.8996","Pump #2":"10.7515"},
{ "name:":"10/13/21 04:23:51  AM","Dehumidifier":"7.8813","Pump #2":"10.7607"},
{ "name:":"10/13/21 04:24:51  AM","Dehumidifier":"7.9072","Pump #2":"11.1269"},
{ "name:":"10/13/21 04:25:51  AM","Dehumidifier":"7.8355","Pump #2":"10.7103"},
{ "name:":"10/13/21 04:26:51  AM","Dehumidifier":"7.8874","Pump #2":"10.6584"},
{ "name:":"10/13/21 04:27:51  AM","Dehumidifier":"7.8813","Pump #2":"11.1681"},
{ "name:":"10/13/21 04:28:51  AM","Dehumidifier":"7.8889","Pump #2":"11.0140"},
{ "name:":"10/13/21 04:29:51  AM","Dehumidifier":"7.9118","Pump #2":"10.7698"},
{ "name:":"10/13/21 04:30:51  AM","Dehumidifier":"7.9011","Pump #2":"10.9651"},
{ "name:":"10/13/21 04:31:51  AM","Dehumidifier":"7.9026","Pump #2":"11.3893"},
{ "name:":"10/13/21 04:32:51  AM","Dehumidifier":"7.8889","Pump #2":"10.7118"},
{ "name:":"10/13/21 04:33:51  AM","Dehumidifier":"7.8752","Pump #2":"11.1360"},
{ "name:":"10/13/21 04:34:51  AM","Dehumidifier":"7.8920","Pump #2":"10.7469"},
{ "name:":"10/13/21 04:35:51  AM","Dehumidifier":"7.9118","Pump #2":"10.9560"},
{ "name:":"10/13/21 04:36:51  AM","Dehumidifier":"7.8920","Pump #2":"11.0048"},
{ "name:":"10/13/21 04:37:51  AM","Dehumidifier":"7.8767","Pump #2":"10.4784"},
{ "name:":"10/13/21 04:38:51  AM","Dehumidifier":"7.9286","Pump #2":"10.6935"},
{ "name:":"10/13/21 04:39:51  AM","Dehumidifier":"7.8737","Pump #2":"10.5470"},
{ "name:":"10/13/21 04:40:51  AM","Dehumidifier":"7.8981","Pump #2":"10.4662"},
{ "name:":"10/13/21 04:41:51  AM","Dehumidifier":"7.8645","Pump #2":"10.9804"},
{ "name:":"10/13/21 04:42:51  AM","Dehumidifier":"7.8813","Pump #2":"10.8156"},
{ "name:":"10/13/21 04:43:51  AM","Dehumidifier":"7.8401","Pump #2":"11.1711"},
{ "name:":"10/13/21 04:44:51  AM","Dehumidifier":"7.8431","Pump #2":"10.4723"},
{ "name:":"10/13/21 04:45:51  AM","Dehumidifier":"7.8752","Pump #2":"10.5440"},
{ "name:":"10/13/21 04:46:51  AM","Dehumidifier":"7.8721","Pump #2":"10.3151"},
{ "name:":"10/13/21 04:47:51  AM","Dehumidifier":"7.8767","Pump #2":"10.6767"},
{ "name:":"10/13/21 04:48:51  AM","Dehumidifier":"7.9164","Pump #2":"10.5211"},
{ "name:":"10/13/21 04:49:51  AM","Dehumidifier":"7.8279","Pump #2":"10.9682"},
{ "name:":"10/13/21 04:50:51  AM","Dehumidifier":"7.8599","Pump #2":"10.8721"},
{ "name:":"10/13/21 04:51:51  AM","Dehumidifier":"7.8447","Pump #2":"10.5425"},
{ "name:":"10/13/21 04:52:51  AM","Dehumidifier":"7.8660","Pump #2":"10.6386"},
{ "name:":"10/13/21 04:53:51  AM","Dehumidifier":"7.8828","Pump #2":"10.5837"},
{ "name:":"10/13/21 04:54:51  AM","Dehumidifier":"7.8904","Pump #2":"10.7256"},
{ "name:":"10/13/21 04:55:51  AM","Dehumidifier":"7.8889","Pump #2":"10.8965"},
{ "name:":"10/13/21 04:56:51  AM","Dehumidifier":"7.8920","Pump #2":"10.5516"},
{ "name:":"10/13/21 04:57:51  AM","Dehumidifier":"7.8874","Pump #2":"10.8675"},
{ "name:":"10/13/21 04:58:51  AM","Dehumidifier":"7.8828","Pump #2":"11.1894"},
{ "name:":"10/13/21 04:59:51  AM","Dehumidifier":"7.8920","Pump #2":"11.0246"},
{ "name:":"10/13/21 05:00:51  AM","Dehumidifier":"7.9057","Pump #2":"11.1559"},
{ "name:":"10/13/21 05:01:51  AM","Dehumidifier":"7.8950","Pump #2":"10.8248"},
{ "name:":"10/13/21 05:02:51  AM","Dehumidifier":"7.9347","Pump #2":"10.6020"},
{ "name:":"10/13/21 05:03:51  AM","Dehumidifier":"7.8798","Pump #2":"10.8995"},
{ "name:":"10/13/21 05:04:51  AM","Dehumidifier":"7.9622","Pump #2":"11.0719"},
{ "name:":"10/13/21 05:05:51  AM","Dehumidifier":"7.9072","Pump #2":"11.1162"},
{ "name:":"10/13/21 05:06:51  AM","Dehumidifier":"7.9011","Pump #2":"10.8934"},
{ "name:":"10/13/21 05:07:51  AM","Dehumidifier":"7.8965","Pump #2":"10.8553"},
{ "name:":"10/13/21 05:08:51  AM","Dehumidifier":"7.9301","Pump #2":"10.8110"},
{ "name:":"10/13/21 05:09:51  AM","Dehumidifier":"7.8965","Pump #2":"10.9606"},
{ "name:":"10/13/21 05:10:51  AM","Dehumidifier":"7.9042","Pump #2":"11.2078"},
{ "name:":"10/13/21 05:11:51  AM","Dehumidifier":"7.8965","Pump #2":"10.3731"},
{ "name:":"10/13/21 05:12:51  AM","Dehumidifier":"7.8843","Pump #2":"10.8629"},
{ "name:":"10/13/21 05:13:51  AM","Dehumidifier":"7.8569","Pump #2":"10.9438"},
{ "name:":"10/13/21 05:14:51  AM","Dehumidifier":"7.8950","Pump #2":"10.7988"},
{ "name:":"10/13/21 05:15:51  AM","Dehumidifier":"7.8828","Pump #2":"10.8843"},
{ "name:":"10/13/21 05:16:51  AM","Dehumidifier":"7.8981","Pump #2":"11.2505"},
{ "name:":"10/13/21 05:17:51  AM","Dehumidifier":"7.8691","Pump #2":"10.7088"},
{ "name:":"10/13/21 05:18:51  AM","Dehumidifier":"7.8660","Pump #2":"11.0033"},
{ "name:":"10/13/21 05:19:51  AM","Dehumidifier":"7.8889","Pump #2":"11.3924"},
{ "name:":"10/13/21 05:20:51  AM","Dehumidifier":"7.9042","Pump #2":"11.2108"},
{ "name:":"10/13/21 05:21:51  AM","Dehumidifier":"7.8355","Pump #2":"11.4427"},
{ "name:":"10/13/21 05:22:51  AM","Dehumidifier":"7.8981","Pump #2":"11.0948"},
{ "name:":"10/13/21 05:23:51  AM","Dehumidifier":"7.9072","Pump #2":"11.2490"},
{ "name:":"10/13/21 05:24:51  AM","Dehumidifier":"7.8950","Pump #2":"10.6752"},
{ "name:":"10/13/21 05:25:51  AM","Dehumidifier":"7.8843","Pump #2":"10.2571"},
{ "name:":"10/13/21 05:26:51  AM","Dehumidifier":"7.8737","Pump #2":"10.8766"},
{ "name:":"10/13/21 05:27:51  AM","Dehumidifier":"7.8447","Pump #2":"10.4601"},
{ "name:":"10/13/21 05:28:51  AM","Dehumidifier":"7.8721","Pump #2":"11.2963"},
{ "name:":"10/13/21 05:29:51  AM","Dehumidifier":"7.8904","Pump #2":"11.0658"},
{ "name:":"10/13/21 05:30:51  AM","Dehumidifier":"7.8874","Pump #2":"11.0430"},
{ "name:":"10/13/21 05:31:51  AM","Dehumidifier":"7.8843","Pump #2":"10.5531"},
{ "name:":"10/13/21 05:32:51  AM","Dehumidifier":"7.8630","Pump #2":"11.0521"},
{ "name:":"10/13/21 05:33:51  AM","Dehumidifier":"7.8920","Pump #2":"11.3695"},
{ "name:":"10/13/21 05:34:51  AM","Dehumidifier":"7.8553","Pump #2":"11.1452"},
{ "name:":"10/13/21 05:35:51  AM","Dehumidifier":"7.9286","Pump #2":"10.7942"},
{ "name:":"10/13/21 05:36:51  AM","Dehumidifier":"7.9011","Pump #2":"10.6691"},
{ "name:":"10/13/21 05:37:51  AM","Dehumidifier":"7.9103","Pump #2":"10.7744"},
{ "name:":"10/13/21 05:38:51  AM","Dehumidifier":"7.9301","Pump #2":"11.0658"},
{ "name:":"10/13/21 05:39:51  AM","Dehumidifier":"7.8737","Pump #2":"11.1376"},
{ "name:":"10/13/21 05:40:51  AM","Dehumidifier":"7.9057","Pump #2":"11.1177"},
{ "name:":"10/13/21 05:41:51  AM","Dehumidifier":"7.8981","Pump #2":"11.1650"},
{ "name:":"10/13/21 05:42:51  AM","Dehumidifier":"7.9072","Pump #2":"10.9972"},
{ "name:":"10/13/21 05:43:51  AM","Dehumidifier":"7.8904","Pump #2":"11.1192"},
{ "name:":"10/13/21 05:44:51  AM","Dehumidifier":"7.8981","Pump #2":"10.8827"},
{ "name:":"10/13/21 05:45:51  AM","Dehumidifier":"7.9194","Pump #2":"10.7485"},
{ "name:":"10/13/21 05:46:51  AM","Dehumidifier":"7.9088","Pump #2":"10.9651"},
{ "name:":"10/13/21 05:47:51  AM","Dehumidifier":"7.9179","Pump #2":"10.8156"},
{ "name:":"10/13/21 05:48:51  AM","Dehumidifier":"7.9042","Pump #2":"11.0170"},
{ "name:":"10/13/21 05:49:51  AM","Dehumidifier":"7.8950","Pump #2":"10.5776"},
{ "name:":"10/13/21 05:50:51  AM","Dehumidifier":"7.9194","Pump #2":"10.7546"},
{ "name:":"10/13/21 05:51:51  AM","Dehumidifier":"7.9377","Pump #2":"10.9422"},
{ "name:":"10/13/21 05:52:51  AM","Dehumidifier":"7.8614","Pump #2":"10.6615"},
{ "name:":"10/13/21 05:53:51  AM","Dehumidifier":"7.9133","Pump #2":"10.8766"},
{ "name:":"10/13/21 05:54:51  AM","Dehumidifier":"7.8996","Pump #2":"10.8614"},
{ "name:":"10/13/21 05:55:51  AM","Dehumidifier":"7.8660","Pump #2":"10.6386"},
{ "name:":"10/13/21 05:56:51  AM","Dehumidifier":"7.9210","Pump #2":"10.8659"},
{ "name:":"10/13/21 05:57:51  AM","Dehumidifier":"7.9149","Pump #2":"11.2245"},
{ "name:":"10/13/21 05:58:51  AM","Dehumidifier":"7.8904","Pump #2":"11.1604"},
{ "name:":"10/13/21 05:59:51  AM","Dehumidifier":"7.9133","Pump #2":"10.6172"},
{ "name:":"10/13/21 06:00:51  AM","Dehumidifier":"7.9271","Pump #2":"11.1528"},
{ "name:":"10/13/21 06:01:51  AM","Dehumidifier":"7.8935","Pump #2":"10.9743"},
{ "name:":"10/13/21 06:02:51  AM","Dehumidifier":"7.9423","Pump #2":"11.1269"},
{ "name:":"10/13/21 06:03:51  AM","Dehumidifier":"7.9286","Pump #2":"11.0307"},
{ "name:":"10/13/21 06:04:51  AM","Dehumidifier":"7.8782","Pump #2":"11.2474"},
{ "name:":"10/13/21 06:05:51  AM","Dehumidifier":"7.9149","Pump #2":"11.3985"},
{ "name:":"10/13/21 06:06:51  AM","Dehumidifier":"7.9057","Pump #2":"11.0780"},
{ "name:":"10/13/21 06:07:51  AM","Dehumidifier":"7.9026","Pump #2":"11.1528"},
{ "name:":"10/13/21 06:08:51  AM","Dehumidifier":"7.9026","Pump #2":"10.8186"},
{ "name:":"10/13/21 06:09:51  AM","Dehumidifier":"7.8981","Pump #2":"11.0536"},
{ "name:":"10/13/21 06:10:51  AM","Dehumidifier":"7.9011","Pump #2":"10.8690"},
{ "name:":"10/13/21 06:11:51  AM","Dehumidifier":"7.8904","Pump #2":"11.0445"},
{ "name:":"10/13/21 06:12:51  AM","Dehumidifier":"7.8599","Pump #2":"10.8003"},
{ "name:":"10/13/21 06:13:51  AM","Dehumidifier":"7.8599","Pump #2":"11.3649"},
{ "name:":"10/13/21 06:14:51  AM","Dehumidifier":"7.8645","Pump #2":"10.6523"},
{ "name:":"10/13/21 06:15:51  AM","Dehumidifier":"7.8584","Pump #2":"10.5333"},
{ "name:":"10/13/21 06:16:51  AM","Dehumidifier":"7.8904","Pump #2":"10.4967"},
{ "name:":"10/13/21 06:17:51  AM","Dehumidifier":"7.8584","Pump #2":"10.1930"},
{ "name:":"10/13/21 06:18:51  AM","Dehumidifier":"7.8630","Pump #2":"10.5867"},
{ "name:":"10/13/21 06:19:51  AM","Dehumidifier":"7.8721","Pump #2":"11.2703"},
{ "name:":"10/13/21 06:20:51  AM","Dehumidifier":"7.8752","Pump #2":"10.8232"},
{ "name:":"10/13/21 06:21:51  AM","Dehumidifier":"7.8706","Pump #2":"10.9987"},
{ "name:":"10/13/21 06:22:51  AM","Dehumidifier":"7.8569","Pump #2":"10.8766"},
{ "name:":"10/13/21 06:23:51  AM","Dehumidifier":"7.8981","Pump #2":"11.0353"},
{ "name:":"10/13/21 06:24:51  AM","Dehumidifier":"7.9347","Pump #2":"11.0918"},
{ "name:":"10/13/21 06:25:51  AM","Dehumidifier":"7.8965","Pump #2":"10.7897"},
{ "name:":"10/13/21 06:26:51  AM","Dehumidifier":"7.9057","Pump #2":"10.7881"},
{ "name:":"10/13/21 06:27:51  AM","Dehumidifier":"7.9011","Pump #2":"10.9087"},
{ "name:":"10/13/21 06:28:51  AM","Dehumidifier":"7.8904","Pump #2":"10.9773"},
{ "name:":"10/13/21 06:29:51  AM","Dehumidifier":"7.9454","Pump #2":"11.4260"},
{ "name:":"10/13/21 06:30:51  AM","Dehumidifier":"7.8965","Pump #2":"11.1620"},
{ "name:":"10/13/21 06:31:51  AM","Dehumidifier":"7.9118","Pump #2":"11.0079"},
{ "name:":"10/13/21 06:32:51  AM","Dehumidifier":"7.9561","Pump #2":"10.9117"},
{ "name:":"10/13/21 06:33:51  AM","Dehumidifier":"7.9347","Pump #2":"11.1925"},
{ "name:":"10/13/21 06:34:51  AM","Dehumidifier":"7.8920","Pump #2":"11.0033"},
{ "name:":"10/13/21 06:35:51  AM","Dehumidifier":"7.8874","Pump #2":"11.4305"},
{ "name:":"10/13/21 06:36:51  AM","Dehumidifier":"7.9042","Pump #2":"10.9987"},
{ "name:":"10/13/21 06:37:51  AM","Dehumidifier":"7.9088","Pump #2":"11.1757"},
{ "name:":"10/13/21 06:38:51  AM","Dehumidifier":"7.9393","Pump #2":"10.9514"},
{ "name:":"10/13/21 06:39:51  AM","Dehumidifier":"7.9210","Pump #2":"11.0964"},
{ "name:":"10/13/21 06:40:51  AM","Dehumidifier":"7.9072","Pump #2":"10.9133"},
{ "name:":"10/13/21 06:41:51  AM","Dehumidifier":"7.9606","Pump #2":"10.8446"},
{ "name:":"10/13/21 06:42:51  AM","Dehumidifier":"7.9026","Pump #2":"11.1940"},
{ "name:":"10/13/21 06:43:51  AM","Dehumidifier":"7.9225","Pump #2":"11.0018"},
{ "name:":"10/13/21 06:44:51  AM","Dehumidifier":"7.8981","Pump #2":"11.3329"},
{ "name:":"10/13/21 06:45:51  AM","Dehumidifier":"7.9103","Pump #2":"11.2215"},
{ "name:":"10/13/21 06:46:51  AM","Dehumidifier":"7.8828","Pump #2":"10.7378"},
{ "name:":"10/13/21 06:47:51  AM","Dehumidifier":"7.8950","Pump #2":"11.2444"},
{ "name:":"10/13/21 06:48:51  AM","Dehumidifier":"7.9194","Pump #2":"11.0063"},
{ "name:":"10/13/21 06:49:51  AM","Dehumidifier":"7.9225","Pump #2":"11.2566"},
{ "name:":"10/13/21 06:50:51  AM","Dehumidifier":"7.9362","Pump #2":"11.4733"},
{ "name:":"10/13/21 06:51:51  AM","Dehumidifier":"7.9362","Pump #2":"11.0460"},
{ "name:":"10/13/21 06:52:51  AM","Dehumidifier":"7.9271","Pump #2":"10.9178"},
{ "name:":"10/13/21 06:53:51  AM","Dehumidifier":"7.8706","Pump #2":"10.8507"},
{ "name:":"10/13/21 06:54:51  AM","Dehumidifier":"7.9133","Pump #2":"11.5511"},
{ "name:":"10/13/21 06:55:51  AM","Dehumidifier":"7.9271","Pump #2":"11.2383"},
{ "name:":"10/13/21 06:56:51  AM","Dehumidifier":"7.8859","Pump #2":"11.1406"},
{ "name:":"10/13/21 06:57:51  AM","Dehumidifier":"7.8965","Pump #2":"10.9026"},
{ "name:":"10/13/21 06:58:51  AM","Dehumidifier":"7.9103","Pump #2":"11.0414"},
{ "name:":"10/13/21 06:59:51  AM","Dehumidifier":"7.8538","Pump #2":"10.9468"},
{ "name:":"10/13/21 07:00:51  AM","Dehumidifier":"7.8965","Pump #2":"10.7210"},
{ "name:":"10/13/21 07:01:51  AM","Dehumidifier":"7.8782","Pump #2":"10.9865"},
{ "name:":"10/13/21 07:02:51  AM","Dehumidifier":"7.9011","Pump #2":"10.8659"},
{ "name:":"10/13/21 07:03:51  AM","Dehumidifier":"7.8996","Pump #2":"11.4107"},
{ "name:":"10/13/21 07:04:51  AM","Dehumidifier":"7.8569","Pump #2":"10.7393"},
{ "name:":"10/13/21 07:05:51  AM","Dehumidifier":"7.8996","Pump #2":"11.2383"},
{ "name:":"10/13/21 07:06:51  AM","Dehumidifier":"7.9301","Pump #2":"10.8293"},
{ "name:":"10/13/21 07:07:51  AM","Dehumidifier":"7.8660","Pump #2":"11.0994"},
{ "name:":"10/13/21 07:08:51  AM","Dehumidifier":"7.8920","Pump #2":"11.1650"},
{ "name:":"10/13/21 07:09:51  AM","Dehumidifier":"7.8874","Pump #2":"10.8568"},
{ "name:":"10/13/21 07:10:51  AM","Dehumidifier":"7.8676","Pump #2":"10.8614"},
{ "name:":"10/13/21 07:11:51  AM","Dehumidifier":"7.9057","Pump #2":"10.9728"},
{ "name:":"10/13/21 07:12:51  AM","Dehumidifier":"7.8996","Pump #2":"11.1101"},
{ "name:":"10/13/21 07:13:51  AM","Dehumidifier":"7.8813","Pump #2":"11.1360"},
{ "name:":"10/13/21 07:14:51  AM","Dehumidifier":"7.8767","Pump #2":"10.9911"},
{ "name:":"10/13/21 07:15:51  AM","Dehumidifier":"7.8645","Pump #2":"11.0445"},
{ "name:":"10/13/21 07:16:51  AM","Dehumidifier":"7.8935","Pump #2":"11.0780"},
{ "name:":"10/13/21 07:17:51  AM","Dehumidifier":"7.9164","Pump #2":"10.8644"},
{ "name:":"10/13/21 07:18:51  AM","Dehumidifier":"7.8813","Pump #2":"11.1742"},
{ "name:":"10/13/21 07:19:51  AM","Dehumidifier":"7.9500","Pump #2":"11.1254"},
{ "name:":"10/13/21 07:20:51  AM","Dehumidifier":"7.9194","Pump #2":"11.4717"},
{ "name:":"10/13/21 07:21:51  AM","Dehumidifier":"7.8981","Pump #2":"11.2947"},
{ "name:":"10/13/21 07:22:51  AM","Dehumidifier":"7.8996","Pump #2":"11.0994"},
{ "name:":"10/13/21 07:23:51  AM","Dehumidifier":"7.8935","Pump #2":"10.9102"},
{ "name:":"10/13/21 07:24:51  AM","Dehumidifier":"7.8996","Pump #2":"11.3008"},
{ "name:":"10/13/21 07:25:51  AM","Dehumidifier":"7.8904","Pump #2":"10.7805"},
{ "name:":"10/13/21 07:26:51  AM","Dehumidifier":"7.9133","Pump #2":"11.2322"},
{ "name:":"10/13/21 07:27:51  AM","Dehumidifier":"7.9362","Pump #2":"10.6783"},
{ "name:":"10/13/21 07:28:51  AM","Dehumidifier":"7.9377","Pump #2":"10.7576"},
{ "name:":"10/13/21 07:29:51  AM","Dehumidifier":"7.9362","Pump #2":"10.8965"},
{ "name:":"10/13/21 07:30:51  AM","Dehumidifier":"7.9332","Pump #2":"10.9453"},
{ "name:":"10/13/21 07:31:51  AM","Dehumidifier":"7.8706","Pump #2":"11.0201"},
{ "name:":"10/13/21 07:32:51  AM","Dehumidifier":"7.8920","Pump #2":"10.9331"},
{ "name:":"10/13/21 07:33:51  AM","Dehumidifier":"7.8935","Pump #2":"10.8156"},
{ "name:":"10/13/21 07:34:51  AM","Dehumidifier":"7.8752","Pump #2":"10.9682"},
{ "name:":"10/13/21 07:35:51  AM","Dehumidifier":"7.8645","Pump #2":"10.6111"},
{ "name:":"10/13/21 07:36:51  AM","Dehumidifier":"7.8798","Pump #2":"10.9346"},
{ "name:":"10/13/21 07:37:51  AM","Dehumidifier":"7.8798","Pump #2":"11.1711"},
{ "name:":"10/13/21 07:38:51  AM","Dehumidifier":"7.8950","Pump #2":"11.0704"},
{ "name:":"10/13/21 07:39:51  AM","Dehumidifier":"7.9072","Pump #2":"10.9438"},
{ "name:":"10/13/21 07:40:51  AM","Dehumidifier":"7.8904","Pump #2":"10.7271"},
{ "name:":"10/13/21 07:41:51  AM","Dehumidifier":"7.8981","Pump #2":"10.8385"},
{ "name:":"10/13/21 07:42:51  AM","Dehumidifier":"7.8843","Pump #2":"10.9270"},
{ "name:":"10/13/21 07:43:51  AM","Dehumidifier":"7.8981","Pump #2":"10.9575"},
{ "name:":"10/13/21 07:44:51  AM","Dehumidifier":"7.8920","Pump #2":"11.0384"},
{ "name:":"10/13/21 07:45:51  AM","Dehumidifier":"7.9332","Pump #2":"11.0979"},
{ "name:":"10/13/21 07:46:51  AM","Dehumidifier":"7.8889","Pump #2":"11.0903"},
{ "name:":"10/13/21 07:47:51  AM","Dehumidifier":"7.9179","Pump #2":"10.9224"},
{ "name:":"10/13/21 07:48:51  AM","Dehumidifier":"7.9088","Pump #2":"11.3848"},
{ "name:":"10/13/21 07:49:51  AM","Dehumidifier":"7.9057","Pump #2":"11.1925"},
{ "name:":"10/13/21 07:50:51  AM","Dehumidifier":"7.8676","Pump #2":"10.7317"},
{ "name:":"10/13/21 07:51:51  AM","Dehumidifier":"7.8538","Pump #2":"11.2840"},
{ "name:":"10/13/21 07:52:51  AM","Dehumidifier":"7.9118","Pump #2":"10.9957"},
{ "name:":"10/13/21 07:53:51  AM","Dehumidifier":"7.8782","Pump #2":"10.7576"},
{ "name:":"10/13/21 07:54:51  AM","Dehumidifier":"7.8584","Pump #2":"10.8934"},
{ "name:":"10/13/21 07:55:51  AM","Dehumidifier":"7.9545","Pump #2":"10.4036"},
{ "name:":"10/13/21 07:56:51  AM","Dehumidifier":"7.8889","Pump #2":"11.0262"},
{ "name:":"10/13/21 07:57:51  AM","Dehumidifier":"7.8584","Pump #2":"11.0582"},
{ "name:":"10/13/21 07:58:51  AM","Dehumidifier":"7.9057","Pump #2":"11.1696"},
{ "name:":"10/13/21 07:59:51  AM","Dehumidifier":"7.9057","Pump #2":"11.1635"},
{ "name:":"10/13/21 08:00:51  AM","Dehumidifier":"7.8264","Pump #2":"10.7652"},
{ "name:":"10/13/21 08:01:51  AM","Dehumidifier":"7.8096","Pump #2":"10.9499"},
{ "name:":"10/13/21 08:02:51  AM","Dehumidifier":"7.8920","Pump #2":"11.3085"},
{ "name:":"10/13/21 08:03:51  AM","Dehumidifier":"7.9042","Pump #2":"10.7332"},
{ "name:":"10/13/21 08:04:51  AM","Dehumidifier":"7.8737","Pump #2":"11.1818"},
{ "name:":"10/13/21 08:05:51  AM","Dehumidifier":"7.8676","Pump #2":"10.8583"},
{ "name:":"10/13/21 08:06:51  AM","Dehumidifier":"7.8981","Pump #2":"11.1101"},
{ "name:":"10/13/21 08:07:51  AM","Dehumidifier":"7.9103","Pump #2":"11.0231"},
{ "name:":"10/13/21 08:08:51  AM","Dehumidifier":"7.8874","Pump #2":"11.5007"},
{ "name:":"10/13/21 08:09:51  AM","Dehumidifier":"7.8843","Pump #2":"11.3588"},
{ "name:":"10/13/21 08:10:51  AM","Dehumidifier":"7.9088","Pump #2":"10.8827"},
{ "name:":"10/13/21 08:11:51  AM","Dehumidifier":"7.8828","Pump #2":"11.1681"},
{ "name:":"10/13/21 08:12:51  AM","Dehumidifier":"7.9072","Pump #2":"11.1131"},
{ "name:":"10/13/21 08:13:51  AM","Dehumidifier":"7.9057","Pump #2":"10.5806"},
{ "name:":"10/13/21 08:14:51  AM","Dehumidifier":"7.8737","Pump #2":"11.0842"},
{ "name:":"10/13/21 08:15:51  AM","Dehumidifier":"7.9118","Pump #2":"10.9682"},
{ "name:":"10/13/21 08:16:51  AM","Dehumidifier":"7.8935","Pump #2":"10.9300"},
{ "name:":"10/13/21 08:17:51  AM","Dehumidifier":"7.8828","Pump #2":"10.9514"},
{ "name:":"10/13/21 08:18:51  AM","Dehumidifier":"7.8645","Pump #2":"10.4829"},
{ "name:":"10/13/21 08:19:51  AM","Dehumidifier":"7.9118","Pump #2":"10.8415"},
{ "name:":"10/13/21 08:20:51  AM","Dehumidifier":"7.8706","Pump #2":"10.8034"},
{ "name:":"10/13/21 08:21:51  AM","Dehumidifier":"7.8904","Pump #2":"11.1986"},
{ "name:":"10/13/21 08:22:51  AM","Dehumidifier":"7.8737","Pump #2":"10.8904"},
{ "name:":"10/13/21 08:23:51  AM","Dehumidifier":"7.9026","Pump #2":"10.6722"},
{ "name:":"10/13/21 08:24:51  AM","Dehumidifier":"7.9042","Pump #2":"10.4906"},
{ "name:":"10/13/21 08:25:51  AM","Dehumidifier":"7.8889","Pump #2":"10.8782"},
{ "name:":"10/13/21 08:26:51  AM","Dehumidifier":"7.9194","Pump #2":"10.9789"},
{ "name:":"10/13/21 08:27:51  AM","Dehumidifier":"7.8996","Pump #2":"10.4021"},
{ "name:":"10/13/21 08:28:51  AM","Dehumidifier":"7.9240","Pump #2":"10.5501"},
{ "name:":"10/13/21 08:29:51  AM","Dehumidifier":"7.9164","Pump #2":"10.9270"},
{ "name:":"10/13/21 08:30:51  AM","Dehumidifier":"7.8721","Pump #2":"11.0521"},
{ "name:":"10/13/21 08:31:51  AM","Dehumidifier":"7.8752","Pump #2":"11.0018"},
{ "name:":"10/13/21 08:32:51  AM","Dehumidifier":"7.9057","Pump #2":"10.7820"},
{ "name:":"10/13/21 08:33:51  AM","Dehumidifier":"7.8325","Pump #2":"10.9697"},
{ "name:":"10/13/21 08:34:51  AM","Dehumidifier":"7.9164","Pump #2":"10.8034"},
{ "name:":"10/13/21 08:35:51  AM","Dehumidifier":"7.8889","Pump #2":"10.7408"},
{ "name:":"10/13/21 08:36:51  AM","Dehumidifier":"7.8920","Pump #2":"10.8461"},
{ "name:":"10/13/21 08:37:51  AM","Dehumidifier":"7.8996","Pump #2":"10.6584"},
{ "name:":"10/13/21 08:38:51  AM","Dehumidifier":"7.9057","Pump #2":"10.5272"},
{ "name:":"10/13/21 08:39:51  AM","Dehumidifier":"7.9118","Pump #2":"10.6371"},
{ "name:":"10/13/21 08:40:51  AM","Dehumidifier":"7.9194","Pump #2":"10.5211"},
{ "name:":"10/13/21 08:41:51  AM","Dehumidifier":"7.8920","Pump #2":"10.6661"},
{ "name:":"10/13/21 08:42:51  AM","Dehumidifier":"7.8904","Pump #2":"10.7485"},
{ "name:":"10/13/21 08:43:51  AM","Dehumidifier":"7.9194","Pump #2":"10.4158"},
{ "name:":"10/13/21 08:44:51  AM","Dehumidifier":"7.9011","Pump #2":"10.6661"},
{ "name:":"10/13/21 08:45:51  AM","Dehumidifier":"7.9133","Pump #2":"10.1839"},
{ "name:":"10/13/21 08:46:51  AM","Dehumidifier":"7.8813","Pump #2":"10.5501"},
{ "name:":"10/13/21 08:47:51  AM","Dehumidifier":"7.9149","Pump #2":"10.5898"},
{ "name:":"10/13/21 08:48:51  AM","Dehumidifier":"7.8859","Pump #2":"11.0689"},
{ "name:":"10/13/21 08:49:51  AM","Dehumidifier":"7.8843","Pump #2":"11.0857"},
{ "name:":"10/13/21 08:50:51  AM","Dehumidifier":"7.8660","Pump #2":"10.7240"},
{ "name:":"10/13/21 08:51:51  AM","Dehumidifier":"7.9362","Pump #2":"10.8965"},
{ "name:":"10/13/21 08:52:51  AM","Dehumidifier":"7.9149","Pump #2":"10.3410"},
{ "name:":"10/13/21 08:53:51  AM","Dehumidifier":"7.9286","Pump #2":"10.9804"},
{ "name:":"10/13/21 08:54:51  AM","Dehumidifier":"7.8843","Pump #2":"10.2907"},
{ "name:":"10/13/21 08:55:51  AM","Dehumidifier":"7.9820","Pump #2":"11.0246"},
{ "name:":"10/13/21 08:56:51  AM","Dehumidifier":"30.7317","Pump #2":"10.8476"},
{ "name:":"10/13/21 08:57:51  AM","Dehumidifier":"31.7327","Pump #2":"10.7439"},
{ "name:":"10/13/21 08:58:51  AM","Dehumidifier":"32.6177","Pump #2":"10.7469"},
{ "name:":"10/13/21 08:59:51  AM","Dehumidifier":"33.3043","Pump #2":"10.4616"},
{ "name:":"10/13/21 09:00:51  AM","Dehumidifier":"33.5912","Pump #2":"10.4921"},
{ "name:":"10/13/21 09:01:51  AM","Dehumidifier":"33.8720","Pump #2":"10.7942"},
{ "name:":"10/13/21 09:02:51  AM","Dehumidifier":"33.8125","Pump #2":"10.6569"},
{ "name:":"10/13/21 09:03:51  AM","Dehumidifier":"33.9010","Pump #2":"10.6844"},
{ "name:":"10/13/21 09:04:51  AM","Dehumidifier":"32.5811","Pump #2":"10.9407"},
{ "name:":"10/13/21 09:05:51  AM","Dehumidifier":"32.0577","Pump #2":"11.1879"},
{ "name:":"10/13/21 09:06:51  AM","Dehumidifier":"31.9280","Pump #2":"10.6249"},
{ "name:":"10/13/21 09:07:51  AM","Dehumidifier":"31.8242","Pump #2":"10.7607"},
{ "name:":"10/13/21 09:08:51  AM","Dehumidifier":"32.0607","Pump #2":"10.4860"},
{ "name:":"10/13/21 09:09:51  AM","Dehumidifier":"32.2499","Pump #2":"10.7439"},
{ "name:":"10/13/21 09:10:51  AM","Dehumidifier":"32.1828","Pump #2":"11.0170"},
{ "name:":"10/13/21 09:11:51  AM","Dehumidifier":"32.1752","Pump #2":"10.6325"},
{ "name:":"10/13/21 09:12:51  AM","Dehumidifier":"32.2469","Pump #2":"11.1757"},
{ "name:":"10/13/21 09:13:51  AM","Dehumidifier":"32.5277","Pump #2":"11.0079"},
{ "name:":"10/13/21 09:14:51  AM","Dehumidifier":"33.9925","Pump #2":"10.9148"},
{ "name:":"10/13/21 09:15:51  AM","Dehumidifier":"33.9910","Pump #2":"10.8370"},
{ "name:":"10/13/21 09:16:51  AM","Dehumidifier":"34.2748","Pump #2":"10.4448"},
{ "name:":"10/13/21 09:17:51  AM","Dehumidifier":"33.8643","Pump #2":"10.9957"},
{ "name:":"10/13/21 09:18:51  AM","Dehumidifier":"33.8323","Pump #2":"10.5516"},
{ "name:":"10/13/21 09:19:51  AM","Dehumidifier":"33.7133","Pump #2":"10.2632"},
{ "name:":"10/13/21 09:20:51  AM","Dehumidifier":"33.7224","Pump #2":"10.3197"},
{ "name:":"10/13/21 09:21:51  AM","Dehumidifier":"33.7789","Pump #2":"10.0511"},
{ "name:":"10/13/21 09:22:51  AM","Dehumidifier":"32.7443","Pump #2":"10.6798"},
{ "name:":"10/13/21 09:23:51  AM","Dehumidifier":"32.3903","Pump #2":"10.5043"},
{ "name:":"10/13/21 09:24:51  AM","Dehumidifier":"32.1309","Pump #2":"10.9560"},
{ "name:":"10/13/21 09:25:51  AM","Dehumidifier":"32.3827","Pump #2":"10.6493"},
{ "name:":"10/13/21 09:26:51  AM","Dehumidifier":"32.4178","Pump #2":"11.1711"},
{ "name:":"10/13/21 09:27:51  AM","Dehumidifier":"32.4727","Pump #2":"10.9468"},
{ "name:":"10/13/21 09:28:51  AM","Dehumidifier":"32.4498","Pump #2":"10.8949"},
{ "name:":"10/13/21 09:29:51  AM","Dehumidifier":"32.3873","Pump #2":"11.1864"},
{ "name:":"10/13/21 09:30:51  AM","Dehumidifier":"32.2438","Pump #2":"11.0506"},
{ "name:":"10/13/21 09:31:51  AM","Dehumidifier":"32.2072","Pump #2":"10.7301"},
{ "name:":"10/13/21 09:32:51  AM","Dehumidifier":"33.3455","Pump #2":"10.9697"},
{ "name:":"10/13/21 09:33:51  AM","Dehumidifier":"34.2184","Pump #2":"11.1086"},
{ "name:":"10/13/21 09:34:51  AM","Dehumidifier":"34.4747","Pump #2":"11.0002"},
{ "name:":"10/13/21 09:35:51  AM","Dehumidifier":"33.9803","Pump #2":"10.8415"},
{ "name:":"10/13/21 09:36:51  AM","Dehumidifier":"33.7453","Pump #2":"10.2785"},
{ "name:":"10/13/21 09:37:51  AM","Dehumidifier":"33.7728","Pump #2":"10.1762"},
{ "name:":"10/13/21 09:38:51  AM","Dehumidifier":"33.9803","Pump #2":"10.7073"},
{ "name:":"10/13/21 09:39:51  AM","Dehumidifier":"32.8695","Pump #2":"10.8553"},
{ "name:":"10/13/21 09:40:51  AM","Dehumidifier":"32.4437","Pump #2":"11.1589"},
{ "name:":"10/13/21 09:41:51  AM","Dehumidifier":"32.2530","Pump #2":"10.9026"},
{ "name:":"10/13/21 09:42:51  AM","Dehumidifier":"32.5093","Pump #2":"10.7881"},
{ "name:":"10/13/21 09:43:51  AM","Dehumidifier":"32.4483","Pump #2":"11.2154"},
{ "name:":"10/13/21 09:44:51  AM","Dehumidifier":"32.4727","Pump #2":"11.0811"},
{ "name:":"10/13/21 09:45:51  AM","Dehumidifier":"32.4422","Pump #2":"10.7195"},
{ "name:":"10/13/21 09:46:51  AM","Dehumidifier":"32.3262","Pump #2":"10.5501"},
{ "name:":"10/13/21 09:47:51  AM","Dehumidifier":"32.2454","Pump #2":"10.5470"},
{ "name:":"10/13/21 09:48:51  AM","Dehumidifier":"32.2072","Pump #2":"10.5455"},
{ "name:":"10/13/21 09:49:51  AM","Dehumidifier":"32.2271","Pump #2":"10.7836"},
{ "name:":"10/13/21 09:50:51  AM","Dehumidifier":"32.7703","Pump #2":"10.4158"},
{ "name:":"10/13/21 09:51:51  AM","Dehumidifier":"33.9742","Pump #2":"10.3700"},
{ "name:":"10/13/21 09:52:51  AM","Dehumidifier":"33.9635","Pump #2":"10.4540"},
{ "name:":"10/13/21 09:53:51  AM","Dehumidifier":"34.0154","Pump #2":"10.4280"},
{ "name:":"10/13/21 09:54:51  AM","Dehumidifier":"33.8003","Pump #2":"10.6416"},
{ "name:":"10/13/21 09:55:51  AM","Dehumidifier":"33.6904","Pump #2":"10.4387"},
{ "name:":"10/13/21 09:56:51  AM","Dehumidifier":"33.6690","Pump #2":"10.3609"},
{ "name:":"10/13/21 09:57:51  AM","Dehumidifier":"33.8354","Pump #2":"10.0114"},
{ "name:":"10/13/21 09:58:51  AM","Dehumidifier":"32.8756","Pump #2":"9.9443"},
{ "name:":"10/13/21 09:59:51  AM","Dehumidifier":"32.5887","Pump #2":"10.3288"},
{ "name:":"10/13/21 10:00:51  AM","Dehumidifier":"32.4407","Pump #2":"10.6874"},
{ "name:":"10/13/21 10:01:51  AM","Dehumidifier":"32.6604","Pump #2":"10.6996"},
{ "name:":"10/13/21 10:02:51  AM","Dehumidifier":"32.5978","Pump #2":"10.5928"},
{ "name:":"10/13/21 10:03:51  AM","Dehumidifier":"32.6345","Pump #2":"11.0277"},
{ "name:":"10/13/21 10:04:51  AM","Dehumidifier":"32.4392","Pump #2":"10.6706"},
{ "name:":"10/13/21 10:05:51  AM","Dehumidifier":"32.3491","Pump #2":"10.9377"},
{ "name:":"10/13/21 10:06:51  AM","Dehumidifier":"32.0363","Pump #2":"10.9483"},
{ "name:":"10/13/21 10:07:51  AM","Dehumidifier":"32.3156","Pump #2":"10.9346"},
{ "name:":"10/13/21 10:08:51  AM","Dehumidifier":"32.3903","Pump #2":"10.7195"},
{ "name:":"10/13/21 10:09:51  AM","Dehumidifier":"33.3074","Pump #2":"11.0872"},
{ "name:":"10/13/21 10:10:51  AM","Dehumidifier":"34.0520","Pump #2":"10.5806"},
{ "name:":"10/13/21 10:11:51  AM","Dehumidifier":"34.0703","Pump #2":"10.3563"},
{ "name:":"10/13/21 10:12:51  AM","Dehumidifier":"33.8384","Pump #2":"10.4295"},
{ "name:":"10/13/21 10:13:51  AM","Dehumidifier":"33.6980","Pump #2":"10.3746"},
{ "name:":"10/13/21 10:14:51  AM","Dehumidifier":"33.7636","Pump #2":"9.9687"},
{ "name:":"10/13/21 10:15:51  AM","Dehumidifier":"33.7499","Pump #2":"10.0832"},
{ "name:":"10/13/21 10:16:51  AM","Dehumidifier":"33.6995","Pump #2":"10.2296"},
{ "name:":"10/13/21 10:17:51  AM","Dehumidifier":"33.0251","Pump #2":"10.3120"},
{ "name:":"10/13/21 10:18:51  AM","Dehumidifier":"32.7626","Pump #2":"10.2296"},
{ "name:":"10/13/21 10:19:51  AM","Dehumidifier":"32.6329","Pump #2":"10.4768"},
{ "name:":"10/13/21 10:20:51  AM","Dehumidifier":"32.7306","Pump #2":"10.6538"},
{ "name:":"10/13/21 10:21:51  AM","Dehumidifier":"32.7199","Pump #2":"10.9743"},
{ "name:":"10/13/21 10:22:51  AM","Dehumidifier":"32.7321","Pump #2":"10.5745"},
{ "name:":"10/13/21 10:23:51  AM","Dehumidifier":"32.5933","Pump #2":"10.0526"},
{ "name:":"10/13/21 10:24:51  AM","Dehumidifier":"32.4544","Pump #2":"10.0206"},
{ "name:":"10/13/21 10:25:51  AM","Dehumidifier":"32.5902","Pump #2":"10.4143"},
{ "name:":"10/13/21 10:26:51  AM","Dehumidifier":"32.4773","Pump #2":"10.4967"},
{ "name:":"10/13/21 10:27:51  AM","Dehumidifier":"33.0068","Pump #2":"10.5241"},
{ "name:":"10/13/21 10:28:51  AM","Dehumidifier":"34.0352","Pump #2":"10.5547"},
{ "name:":"10/13/21 10:29:51  AM","Dehumidifier":"33.4264","Pump #2":"10.4250"},
{ "name:":"10/13/21 10:30:51  AM","Dehumidifier":"34.1588","Pump #2":"10.9743"},
{ "name:":"10/13/21 10:31:51  AM","Dehumidifier":"33.8796","Pump #2":"11.1574"},
{ "name:":"10/13/21 10:32:51  AM","Dehumidifier":"33.7652","Pump #2":"10.2113"},
{ "name:":"10/13/21 10:33:51  AM","Dehumidifier":"33.8277","Pump #2":"10.8400"},
{ "name:":"10/13/21 10:34:51  AM","Dehumidifier":"33.6034","Pump #2":"10.9041"},
{ "name:":"10/13/21 10:35:51  AM","Dehumidifier":"33.6614","Pump #2":"10.5867"},
{ "name:":"10/13/21 10:36:51  AM","Dehumidifier":"32.8313","Pump #2":"10.3914"},
{ "name:":"10/13/21 10:37:51  AM","Dehumidifier":"32.5734","Pump #2":"10.5699"},
{ "name:":"10/13/21 10:38:51  AM","Dehumidifier":"32.4025","Pump #2":"10.8186"},
{ "name:":"10/13/21 10:39:51  AM","Dehumidifier":"32.6619","Pump #2":"11.0201"},
{ "name:":"10/13/21 10:40:51  AM","Dehumidifier":"32.6040","Pump #2":"10.7744"},
{ "name:":"10/13/21 10:41:51  AM","Dehumidifier":"32.5917","Pump #2":"11.2047"},
{ "name:":"10/13/21 10:42:51  AM","Dehumidifier":"32.5063","Pump #2":"10.4967"},
{ "name:":"10/13/21 10:43:51  AM","Dehumidifier":"32.5628","Pump #2":"11.0262"},
{ "name:":"10/13/21 10:44:51  AM","Dehumidifier":"32.3339","Pump #2":"10.8309"},
{ "name:":"10/13/21 10:45:51  AM","Dehumidifier":"32.3445","Pump #2":"10.8293"},
{ "name:":"10/13/21 10:46:51  AM","Dehumidifier":"33.1151","Pump #2":"10.8522"},
{ "name:":"10/13/21 10:47:51  AM","Dehumidifier":"34.0993","Pump #2":"10.8339"},
{ "name:":"10/13/21 10:48:51  AM","Dehumidifier":"33.9727","Pump #2":"11.3848"},
{ "name:":"10/13/21 10:49:51  AM","Dehumidifier":"34.2641","Pump #2":"10.6081"},
{ "name:":"10/13/21 10:50:51  AM","Dehumidifier":"33.8689","Pump #2":"10.3792"},
{ "name:":"10/13/21 10:51:51  AM","Dehumidifier":"33.7667","Pump #2":"10.4189"},
{ "name:":"10/13/21 10:52:51  AM","Dehumidifier":"33.9361","Pump #2":"10.6813"},
{ "name:":"10/13/21 10:53:51  AM","Dehumidifier":"33.8476","Pump #2":"10.1564"},
{ "name:":"10/13/21 10:54:51  AM","Dehumidifier":"33.7499","Pump #2":"10.6310"},
{ "name:":"10/13/21 10:55:51  AM","Dehumidifier":"32.8267","Pump #2":"10.2129"},
{ "name:":"10/13/21 10:56:51  AM","Dehumidifier":"32.6055","Pump #2":"10.2632"},
{ "name:":"10/13/21 10:57:51  AM","Dehumidifier":"32.2850","Pump #2":"10.8080"},
{ "name:":"10/13/21 10:58:51  AM","Dehumidifier":"32.4834","Pump #2":"10.4707"},
{ "name:":"10/13/21 10:59:51  AM","Dehumidifier":"32.6513","Pump #2":"10.8782"},
{ "name:":"10/13/21 11:00:51  AM","Dehumidifier":"32.5628","Pump #2":"10.6767"},
{ "name:":"10/13/21 11:01:51  AM","Dehumidifier":"32.6696","Pump #2":"10.7881"},
{ "name:":"10/13/21 11:02:51  AM","Dehumidifier":"32.5322","Pump #2":"11.0643"},
{ "name:":"10/13/21 11:03:51  AM","Dehumidifier":"32.5261","Pump #2":"10.8919"},
{ "name:":"10/13/21 11:04:51  AM","Dehumidifier":"32.3827","Pump #2":"11.0933"},
{ "name:":"10/13/21 11:05:51  AM","Dehumidifier":"33.1182","Pump #2":"10.5333"},
{ "name:":"10/13/21 11:06:51  AM","Dehumidifier":"34.1863","Pump #2":"10.7179"},
{ "name:":"10/13/21 11:07:51  AM","Dehumidifier":"34.0063","Pump #2":"10.3960"},
{ "name:":"10/13/21 11:08:51  AM","Dehumidifier":"34.3221","Pump #2":"10.5928"},
{ "name:":"10/13/21 11:09:51  AM","Dehumidifier":"33.9956","Pump #2":"10.7286"},
{ "name:":"10/13/21 11:10:51  AM","Dehumidifier":"33.9605","Pump #2":"10.7362"},
{ "name:":"10/13/21 11:11:51  AM","Dehumidifier":"33.6904","Pump #2":"10.6584"},
{ "name:":"10/13/21 11:12:51  AM","Dehumidifier":"33.6126","Pump #2":"10.4616"},
{ "name:":"10/13/21 11:13:51  AM","Dehumidifier":"33.9223","Pump #2":"10.8949"},
{ "name:":"10/13/21 11:14:51  AM","Dehumidifier":"33.1273","Pump #2":"10.9453"},
{ "name:":"10/13/21 11:15:51  AM","Dehumidifier":"32.9992","Pump #2":"11.0628"},
{ "name:":"10/13/21 11:16:51  AM","Dehumidifier":"32.7443","Pump #2":"10.9422"},
{ "name:":"10/13/21 11:17:51  AM","Dehumidifier":"32.9854","Pump #2":"10.7805"},
{ "name:":"10/13/21 11:18:51  AM","Dehumidifier":"32.9152","Pump #2":"11.2535"},
{ "name:":"10/13/21 11:19:51  AM","Dehumidifier":"33.1991","Pump #2":"11.0018"},
{ "name:":"10/13/21 11:20:51  AM","Dehumidifier":"32.7886","Pump #2":"11.1147"},
{ "name:":"10/13/21 11:21:51  AM","Dehumidifier":"32.5856","Pump #2":"11.3603"},
{ "name:":"10/13/21 11:22:51  AM","Dehumidifier":"32.5628","Pump #2":"10.9712"},
{ "name:":"10/13/21 11:23:51  AM","Dehumidifier":"32.9976","Pump #2":"10.6767"},
{ "name:":"10/13/21 11:24:51  AM","Dehumidifier":"34.4396","Pump #2":"11.1849"},
{ "name:":"10/13/21 11:25:51  AM","Dehumidifier":"33.9254","Pump #2":"11.1757"},
{ "name:":"10/13/21 11:26:51  AM","Dehumidifier":"34.4076","Pump #2":"10.7378"},
{ "name:":"10/13/21 11:27:51  AM","Dehumidifier":"34.0200","Pump #2":"11.3985"},
{ "name:":"10/13/21 11:28:51  AM","Dehumidifier":"33.9971","Pump #2":"10.8156"},
{ "name:":"10/13/21 11:29:51  AM","Dehumidifier":"33.9086","Pump #2":"10.4738"},
{ "name:":"10/13/21 11:30:51  AM","Dehumidifier":"34.0826","Pump #2":"10.6401"},
{ "name:":"10/13/21 11:31:51  AM","Dehumidifier":"34.0703","Pump #2":"11.1360"},
{ "name:":"10/13/21 11:32:51  AM","Dehumidifier":"33.9895","Pump #2":"10.5684"},
{ "name:":"10/13/21 11:33:51  AM","Dehumidifier":"32.9366","Pump #2":"10.7042"},
{ "name:":"10/13/21 11:34:51  AM","Dehumidifier":"32.5246","Pump #2":"10.6706"},
{ "name:":"10/13/21 11:35:51  AM","Dehumidifier":"32.4544","Pump #2":"10.6584"},
{ "name:":"10/13/21 11:36:51  AM","Dehumidifier":"32.6482","Pump #2":"10.8248"},
{ "name:":"10/13/21 11:37:51  AM","Dehumidifier":"32.5750","Pump #2":"11.2215"},
{ "name:":"10/13/21 11:38:51  AM","Dehumidifier":"32.6925","Pump #2":"11.0765"},
{ "name:":"10/13/21 11:39:51  AM","Dehumidifier":"32.6116","Pump #2":"10.7439"},
{ "name:":"10/13/21 11:40:51  AM","Dehumidifier":"32.5444","Pump #2":"10.8080"},
{ "name:":"10/13/21 11:41:51  AM","Dehumidifier":"32.6253","Pump #2":"11.5969"},
{ "name:":"10/13/21 11:42:51  AM","Dehumidifier":"33.0037","Pump #2":"10.7988"},
{ "name:":"10/13/21 11:43:51  AM","Dehumidifier":"34.5083","Pump #2":"11.2703"},
{ "name:":"10/13/21 11:44:51  AM","Dehumidifier":"34.2885","Pump #2":"11.3893"},
{ "name:":"10/13/21 11:45:51  AM","Dehumidifier":"34.2901","Pump #2":"11.4046"},
{ "name:":"10/13/21 11:46:51  AM","Dehumidifier":"33.9452","Pump #2":"10.7836"},
{ "name:":"10/13/21 11:47:51  AM","Dehumidifier":"34.0215","Pump #2":"11.0170"},
{ "name:":"10/13/21 11:48:51  AM","Dehumidifier":"34.0520","Pump #2":"11.2825"},
{ "name:":"10/13/21 11:49:51  AM","Dehumidifier":"34.0414","Pump #2":"10.8309"},
{ "name:":"10/13/21 11:50:51  AM","Dehumidifier":"34.1283","Pump #2":"10.6447"},
{ "name:":"10/13/21 11:51:51  AM","Dehumidifier":"33.2113","Pump #2":"10.8690"},
{ "name:":"10/13/21 11:52:51  AM","Dehumidifier":"32.9137","Pump #2":"11.0903"},
{ "name:":"10/13/21 11:53:51  AM","Dehumidifier":"32.6833","Pump #2":"10.9575"},
{ "name:":"10/13/21 11:54:51  AM","Dehumidifier":"32.8695","Pump #2":"10.5043"},
{ "name:":"10/13/21 11:55:51  AM","Dehumidifier":"32.7687","Pump #2":"10.6325"},
{ "name:":"10/13/21 11:56:51  AM","Dehumidifier":"32.6665","Pump #2":"10.6645"},
{ "name:":"10/13/21 11:57:51  AM","Dehumidifier":"32.5078","Pump #2":"10.8034"},
{ "name:":"10/13/21 11:58:51  AM","Dehumidifier":"32.3552","Pump #2":"10.6126"},
{ "name:":"10/13/21 11:59:51  AM","Dehumidifier":"32.4392","Pump #2":"10.6645"},
{ "name:":"10/13/21 12:00:51  PM","Dehumidifier":"32.4346","Pump #2":"10.4433"},
{ "name:":"10/13/21 12:01:51  PM","Dehumidifier":"33.5470","Pump #2":"10.8858"},
{ "name:":"10/13/21 12:02:51  PM","Dehumidifier":"33.6812","Pump #2":"11.0872"},
{ "name:":"10/13/21 12:03:51  PM","Dehumidifier":"34.3694","Pump #2":"11.6548"},
{ "name:":"10/13/21 12:04:51  PM","Dehumidifier":"33.9010","Pump #2":"11.0399"},
{ "name:":"10/13/21 12:05:51  PM","Dehumidifier":"33.8415","Pump #2":"11.4458"},
{ "name:":"10/13/21 12:06:51  PM","Dehumidifier":"33.8598","Pump #2":"11.1421"},
{ "name:":"10/13/21 12:07:51  PM","Dehumidifier":"34.0520","Pump #2":"11.1742"},
{ "name:":"10/13/21 12:08:51  PM","Dehumidifier":"34.1100","Pump #2":"10.9590"},
{ "name:":"10/13/21 12:09:51  PM","Dehumidifier":"33.3806","Pump #2":"11.1742"},
{ "name:":"10/13/21 12:10:51  PM","Dehumidifier":"32.6650","Pump #2":"11.0613"},
{ "name:":"10/13/21 12:11:51  PM","Dehumidifier":"32.3659","Pump #2":"10.7973"},
{ "name:":"10/13/21 12:12:51  PM","Dehumidifier":"32.3598","Pump #2":"10.7881"},
{ "name:":"10/13/21 12:13:51  PM","Dehumidifier":"32.5216","Pump #2":"10.4723"},
{ "name:":"10/13/21 12:14:51  PM","Dehumidifier":"32.6497","Pump #2":"10.9789"},
{ "name:":"10/13/21 12:15:51  PM","Dehumidifier":"32.5399","Pump #2":"10.8263"},
{ "name:":"10/13/21 12:16:51  PM","Dehumidifier":"32.4559","Pump #2":"10.5715"},
{ "name:":"10/13/21 12:17:51  PM","Dehumidifier":"32.3644","Pump #2":"10.6630"},
{ "name:":"10/13/21 12:18:51  PM","Dehumidifier":"32.4041","Pump #2":"10.7927"},
{ "name:":"10/13/21 12:19:51  PM","Dehumidifier":"33.2555","Pump #2":"10.8110"},
{ "name:":"10/13/21 12:20:51  PM","Dehumidifier":"34.5418","Pump #2":"10.8675"},
{ "name:":"10/13/21 12:21:51  PM","Dehumidifier":"34.6258","Pump #2":"10.8003"},
{ "name:":"10/13/21 12:22:51  PM","Dehumidifier":"34.2123","Pump #2":"10.6798"},
{ "name:":"10/13/21 12:23:51  PM","Dehumidifier":"34.1192","Pump #2":"10.9300"},
{ "name:":"10/13/21 12:24:51  PM","Dehumidifier":"34.0764","Pump #2":"10.4112"},
{ "name:":"10/13/21 12:25:51  PM","Dehumidifier":"34.2718","Pump #2":"10.8614"},
{ "name:":"10/13/21 12:26:51  PM","Dehumidifier":"33.9376","Pump #2":"10.9682"},
{ "name:":"10/13/21 12:27:51  PM","Dehumidifier":"33.8964","Pump #2":"10.8812"},
{ "name:":"10/13/21 12:28:51  PM","Dehumidifier":"33.0892","Pump #2":"10.2892"},
{ "name:":"10/13/21 12:29:51  PM","Dehumidifier":"32.7520","Pump #2":"10.5364"},
{ "name:":"10/13/21 12:30:51  PM","Dehumidifier":"32.5444","Pump #2":"11.1315"},
{ "name:":"10/13/21 12:31:51  PM","Dehumidifier":"32.6299","Pump #2":"10.6798"},
{ "name:":"10/13/21 12:32:51  PM","Dehumidifier":"32.5734","Pump #2":"10.9819"},
{ "name:":"10/13/21 12:33:51  PM","Dehumidifier":"32.7092","Pump #2":"11.0964"},
{ "name:":"10/13/21 12:34:51  PM","Dehumidifier":"32.4743","Pump #2":"10.9194"},
{ "name:":"10/13/21 12:35:51  PM","Dehumidifier":"32.5063","Pump #2":"11.4275"},
{ "name:":"10/13/21 12:36:51  PM","Dehumidifier":"32.4254","Pump #2":"11.2139"},
{ "name:":"10/13/21 12:37:51  PM","Dehumidifier":"32.7443","Pump #2":"10.6859"},
{ "name:":"10/13/21 12:38:51  PM","Dehumidifier":"34.4549","Pump #2":"10.8751"},
{ "name:":"10/13/21 12:39:51  PM","Dehumidifier":"33.7682","Pump #2":"10.6767"},
{ "name:":"10/13/21 12:40:51  PM","Dehumidifier":"34.7036","Pump #2":"10.8080"},
{ "name:":"10/13/21 12:41:51  PM","Dehumidifier":"34.1650","Pump #2":"10.9255"},
{ "name:":"10/13/21 12:42:51  PM","Dehumidifier":"34.0291","Pump #2":"11.0475"},
{ "name:":"10/13/21 12:43:51  PM","Dehumidifier":"34.0505","Pump #2":"10.6371"},
{ "name:":"10/13/21 12:44:51  PM","Dehumidifier":"34.1329","Pump #2":"11.2047"},
{ "name:":"10/13/21 12:45:51  PM","Dehumidifier":"34.1314","Pump #2":"11.1971"},
{ "name:":"10/13/21 12:46:51  PM","Dehumidifier":"34.1482","Pump #2":"11.1315"},
{ "name:":"10/13/21 12:47:51  PM","Dehumidifier":"33.4813","Pump #2":"11.0185"},
{ "name:":"10/13/21 12:48:51  PM","Dehumidifier":"32.9122","Pump #2":"11.0140"},
{ "name:":"10/13/21 12:49:51  PM","Dehumidifier":"32.5521","Pump #2":"10.4585"},
{ "name:":"10/13/21 12:50:51  PM","Dehumidifier":"32.5078","Pump #2":"11.3375"},
{ "name:":"10/13/21 12:51:51  PM","Dehumidifier":"32.6650","Pump #2":"11.1910"},
{ "name:":"10/13/21 12:52:51  PM","Dehumidifier":"32.8069","Pump #2":"11.2169"},
{ "name:":"10/13/21 12:53:51  PM","Dehumidifier":"32.6970","Pump #2":"11.1482"},
{ "name:":"10/13/21 12:54:51  PM","Dehumidifier":"32.6574","Pump #2":"11.0994"},
{ "name:":"10/13/21 12:55:51  PM","Dehumidifier":"32.5170","Pump #2":"10.9392"},
{ "name:":"10/13/21 12:56:51  PM","Dehumidifier":"32.6589","Pump #2":"11.0079"},
{ "name:":"10/13/21 12:57:51  PM","Dehumidifier":"34.5083","Pump #2":"11.0307"},
{ "name:":"10/13/21 12:58:51  PM","Dehumidifier":"34.0749","Pump #2":"11.1650"},
{ "name:":"10/13/21 12:59:51  PM","Dehumidifier":"34.5785","Pump #2":"11.2657"},
{ "name:":"10/13/21 01:00:51  PM","Dehumidifier":"34.3572","Pump #2":"11.0079"},
{ "name:":"10/13/21 01:01:51  PM","Dehumidifier":"34.1344","Pump #2":"11.0399"},
{ "name:":"10/13/21 01:02:51  PM","Dehumidifier":"34.1833","Pump #2":"10.7103"},
{ "name:":"10/13/21 01:03:51  PM","Dehumidifier":"34.1344","Pump #2":"10.4463"},
{ "name:":"10/13/21 01:04:51  PM","Dehumidifier":"34.1390","Pump #2":"10.8476"},
{ "name:":"10/13/21 01:05:51  PM","Dehumidifier":"34.0764","Pump #2":"10.5623"},
{ "name:":"10/13/21 01:06:51  PM","Dehumidifier":"34.1726","Pump #2":"10.7729"},
{ "name:":"10/13/21 01:07:51  PM","Dehumidifier":"33.2570","Pump #2":"10.5745"},
{ "name:":"10/13/21 01:08:51  PM","Dehumidifier":"32.8542","Pump #2":"10.8492"},
{ "name:":"10/13/21 01:09:51  PM","Dehumidifier":"32.6833","Pump #2":"10.7881"},
{ "name:":"10/13/21 01:10:51  PM","Dehumidifier":"32.8222","Pump #2":"10.2678"},
{ "name:":"10/13/21 01:11:51  PM","Dehumidifier":"32.8939","Pump #2":"10.4814"},
{ "name:":"10/13/21 01:12:51  PM","Dehumidifier":"32.8634","Pump #2":"10.9133"},
{ "name:":"10/13/21 01:13:51  PM","Dehumidifier":"32.8130","Pump #2":"10.4280"},
{ "name:":"10/13/21 01:14:51  PM","Dehumidifier":"32.8084","Pump #2":"10.8232"},
{ "name:":"10/13/21 01:15:51  PM","Dehumidifier":"32.6421","Pump #2":"11.1162"},
{ "name:":"10/13/21 01:16:51  PM","Dehumidifier":"33.7560","Pump #2":"10.5287"},
{ "name:":"10/13/21 01:17:51  PM","Dehumidifier":"34.7311","Pump #2":"10.1686"},
{ "name:":"10/13/21 01:18:51  PM","Dehumidifier":"34.7539","Pump #2":"10.1991"},
{ "name:":"10/13/21 01:19:51  PM","Dehumidifier":"34.5052","Pump #2":"10.4356"},
{ "name:":"10/13/21 01:20:51  PM","Dehumidifier":"34.4320","Pump #2":"10.1472"},
{ "name:":"10/13/21 01:21:51  PM","Dehumidifier":"34.2336","Pump #2":"10.4005"},
{ "name:":"10/13/21 01:22:51  PM","Dehumidifier":"34.1680","Pump #2":"10.4555"},
{ "name:":"10/13/21 01:23:51  PM","Dehumidifier":"34.1695","Pump #2":"10.7469"},
{ "name:":"10/13/21 01:24:51  PM","Dehumidifier":"34.1588","Pump #2":"11.0948"},
{ "name:":"10/13/21 01:25:51  PM","Dehumidifier":"34.1146","Pump #2":"10.7210"},
{ "name:":"10/13/21 01:26:51  PM","Dehumidifier":"34.2214","Pump #2":"10.6706"},
{ "name:":"10/13/21 01:27:51  PM","Dehumidifier":"33.0617","Pump #2":"10.8644"},
{ "name:":"10/13/21 01:28:51  PM","Dehumidifier":"32.6390","Pump #2":"10.7179"},
{ "name:":"10/13/21 01:29:51  PM","Dehumidifier":"32.5963","Pump #2":"10.5486"},
{ "name:":"10/13/21 01:30:51  PM","Dehumidifier":"32.9488","Pump #2":"10.1198"},
{ "name:":"10/13/21 01:31:51  PM","Dehumidifier":"32.9442","Pump #2":"10.4524"},
{ "name:":"10/13/21 01:32:51  PM","Dehumidifier":"32.8283","Pump #2":"10.4341"},
{ "name:":"10/13/21 01:33:51  PM","Dehumidifier":"32.7001","Pump #2":"10.4723"},
{ "name:":"10/13/21 01:34:51  PM","Dehumidifier":"32.7016","Pump #2":"10.9239"},
{ "name:":"10/13/21 01:35:51  PM","Dehumidifier":"32.6162","Pump #2":"10.3487"},
{ "name:":"10/13/21 01:36:51  PM","Dehumidifier":"33.7682","Pump #2":"10.9178"},
{ "name:":"10/13/21 01:37:51  PM","Dehumidifier":"34.6746","Pump #2":"11.0567"},
{ "name:":"10/13/21 01:38:51  PM","Dehumidifier":"34.7036","Pump #2":"11.2367"},
{ "name:":"10/13/21 01:39:51  PM","Dehumidifier":"34.4228","Pump #2":"10.8919"},
{ "name:":"10/13/21 01:40:51  PM","Dehumidifier":"34.2016","Pump #2":"11.1116"},
{ "name:":"10/13/21 01:41:51  PM","Dehumidifier":"34.1497","Pump #2":"11.0643"},
{ "name:":"10/13/21 01:42:51  PM","Dehumidifier":"34.1527","Pump #2":"10.9392"},
{ "name:":"10/13/21 01:43:51  PM","Dehumidifier":"34.1558","Pump #2":"10.9316"},
{ "name:":"10/13/21 01:44:51  PM","Dehumidifier":"34.3587","Pump #2":"10.7683"},
{ "name:":"10/13/21 01:45:51  PM","Dehumidifier":"33.9223","Pump #2":"11.0704"},
{ "name:":"10/13/21 01:46:51  PM","Dehumidifier":"33.0343","Pump #2":"11.2596"},
{ "name:":"10/13/21 01:47:51  PM","Dehumidifier":"32.8069","Pump #2":"10.8492"},
{ "name:":"10/13/21 01:48:51  PM","Dehumidifier":"32.7611","Pump #2":"10.7668"},
{ "name:":"10/13/21 01:49:51  PM","Dehumidifier":"32.8801","Pump #2":"10.5013"},
{ "name:":"10/13/21 01:50:51  PM","Dehumidifier":"32.8374","Pump #2":"10.8202"},
{ "name:":"10/13/21 01:51:51  PM","Dehumidifier":"32.7703","Pump #2":"10.8980"},
{ "name:":"10/13/21 01:52:51  PM","Dehumidifier":"32.6970","Pump #2":"10.6538"},
{ "name:":"10/13/21 01:53:51  PM","Dehumidifier":"32.6558","Pump #2":"10.8766"},
{ "name:":"10/13/21 01:54:51  PM","Dehumidifier":"32.6238","Pump #2":"10.5638"},
{ "name:":"10/13/21 01:55:51  PM","Dehumidifier":"34.5205","Pump #2":"10.3059"},
{ "name:":"10/13/21 01:56:51  PM","Dehumidifier":"34.9264","Pump #2":"10.9056"},
{ "name:":"10/13/21 01:57:51  PM","Dehumidifier":"34.5235","Pump #2":"10.8049"},
{ "name:":"10/13/21 01:58:51  PM","Dehumidifier":"34.2382","Pump #2":"10.6096"},
{ "name:":"10/13/21 01:59:51  PM","Dehumidifier":"34.1192","Pump #2":"10.9407"},
{ "name:":"10/13/21 02:00:51  PM","Dehumidifier":"34.2580","Pump #2":"10.9468"},
{ "name:":"10/13/21 02:01:51  PM","Dehumidifier":"34.2260","Pump #2":"10.9697"},
{ "name:":"10/13/21 02:02:51  PM","Dehumidifier":"34.0337","Pump #2":"10.9804"},
{ "name:":"10/13/21 02:03:51  PM","Dehumidifier":"34.1085","Pump #2":"11.1345"},
{ "name:":"10/13/21 02:04:51  PM","Dehumidifier":"33.6095","Pump #2":"11.0567"},
{ "name:":"10/13/21 02:05:51  PM","Dehumidifier":"33.2052","Pump #2":"10.5104"},
{ "name:":"10/13/21 02:06:51  PM","Dehumidifier":"32.9580","Pump #2":"11.1955"},
{ "name:":"10/13/21 02:07:51  PM","Dehumidifier":"33.0068","Pump #2":"10.7225"},
{ "name:":"10/13/21 02:08:51  PM","Dehumidifier":"32.9641","Pump #2":"10.9071"},
{ "name:":"10/13/21 02:09:51  PM","Dehumidifier":"32.9122","Pump #2":"10.7973"},
{ "name:":"10/13/21 02:10:51  PM","Dehumidifier":"32.8161","Pump #2":"10.9010"},
{ "name:":"10/13/21 02:11:51  PM","Dehumidifier":"32.6146","Pump #2":"10.2968"},
{ "name:":"10/13/21 02:12:51  PM","Dehumidifier":"32.6345","Pump #2":"10.9071"},
{ "name:":"10/13/21 02:13:51  PM","Dehumidifier":"33.1777","Pump #2":"10.6859"},
{ "name:":"10/13/21 02:14:51  PM","Dehumidifier":"34.5052","Pump #2":"10.9056"},
{ "name:":"10/13/21 02:15:51  PM","Dehumidifier":"33.9117","Pump #2":"10.9499"},
{ "name:":"10/13/21 02:16:51  PM","Dehumidifier":"34.7478","Pump #2":"10.5913"},
{ "name:":"10/13/21 02:17:51  PM","Dehumidifier":"34.2382","Pump #2":"11.1925"},
{ "name:":"10/13/21 02:18:51  PM","Dehumidifier":"34.1466","Pump #2":"10.3410"},
{ "name:":"10/13/21 02:19:51  PM","Dehumidifier":"34.4396","Pump #2":"10.4707"},
{ "name:":"10/13/21 02:20:51  PM","Dehumidifier":"34.1665","Pump #2":"10.0771"},
{ "name:":"10/13/21 02:21:51  PM","Dehumidifier":"34.2687","Pump #2":"10.7179"},
{ "name:":"10/13/21 02:22:51  PM","Dehumidifier":"34.2290","Pump #2":"10.9163"},
{ "name:":"10/13/21 02:23:51  PM","Dehumidifier":"33.3257","Pump #2":"10.9148"},
{ "name:":"10/13/21 02:24:51  PM","Dehumidifier":"32.8801","Pump #2":"10.2083"},
{ "name:":"10/13/21 02:25:51  PM","Dehumidifier":"32.6085","Pump #2":"10.5684"},
{ "name:":"10/13/21 02:26:51  PM","Dehumidifier":"32.7626","Pump #2":"10.6661"},
{ "name:":"10/13/21 02:27:51  PM","Dehumidifier":"32.8954","Pump #2":"10.4875"},
{ "name:":"10/13/21 02:28:51  PM","Dehumidifier":"32.8374","Pump #2":"10.8125"},
{ "name:":"10/13/21 02:29:51  PM","Dehumidifier":"32.5582","Pump #2":"11.1742"},
{ "name:":"10/13/21 02:30:51  PM","Dehumidifier":"32.5383","Pump #2":"10.9529"},
{ "name:":"10/13/21 02:31:51  PM","Dehumidifier":"32.5414","Pump #2":"10.6142"},
{ "name:":"10/13/21 02:32:51  PM","Dehumidifier":"32.9320","Pump #2":"10.8965"},
{ "name:":"10/13/21 02:33:51  PM","Dehumidifier":"34.2367","Pump #2":"10.3731"},
{ "name:":"10/13/21 02:34:51  PM","Dehumidifier":"33.9040","Pump #2":"10.8949"},
{ "name:":"10/13/21 02:35:51  PM","Dehumidifier":"34.3755","Pump #2":"10.6630"},
{ "name:":"10/13/21 02:36:51  PM","Dehumidifier":"34.1634","Pump #2":"10.0114"},
{ "name:":"10/13/21 02:37:51  PM","Dehumidifier":"34.0841","Pump #2":"10.9773"},
{ "name:":"10/13/21 02:38:51  PM","Dehumidifier":"34.0764","Pump #2":"10.7561"},
{ "name:":"10/13/21 02:39:51  PM","Dehumidifier":"34.1558","Pump #2":"10.9880"},
{ "name:":"10/13/21 02:40:51  PM","Dehumidifier":"34.4091","Pump #2":"10.5272"},
{ "name:":"10/13/21 02:41:51  PM","Dehumidifier":"34.3099","Pump #2":"10.6325"},
{ "name:":"10/13/21 02:42:51  PM","Dehumidifier":"33.1182","Pump #2":"10.7347"},
{ "name:":"10/13/21 02:43:51  PM","Dehumidifier":"32.8923","Pump #2":"10.6691"},
{ "name:":"10/13/21 02:44:51  PM","Dehumidifier":"32.8145","Pump #2":"10.3075"},
{ "name:":"10/13/21 02:45:51  PM","Dehumidifier":"32.9793","Pump #2":"10.6767"},
{ "name:":"10/13/21 02:46:51  PM","Dehumidifier":"32.8923","Pump #2":"11.0735"},
{ "name:":"10/13/21 02:47:51  PM","Dehumidifier":"32.9244","Pump #2":"10.8537"},
{ "name:":"10/13/21 02:48:51  PM","Dehumidifier":"32.7245","Pump #2":"10.8980"},
{ "name:":"10/13/21 02:49:51  PM","Dehumidifier":"32.8710","Pump #2":"11.1391"},
{ "name:":"10/13/21 02:50:51  PM","Dehumidifier":"32.7504","Pump #2":"10.8186"},
{ "name:":"10/13/21 02:51:51  PM","Dehumidifier":"33.5744","Pump #2":"11.3481"},
{ "name:":"10/13/21 02:52:51  PM","Dehumidifier":"34.7753","Pump #2":"11.0399"},
{ "name:":"10/13/21 02:53:51  PM","Dehumidifier":"34.3511","Pump #2":"11.0704"},
{ "name:":"10/13/21 02:54:51  PM","Dehumidifier":"34.5068","Pump #2":"10.7485"},
{ "name:":"10/13/21 02:55:51  PM","Dehumidifier":"34.2504","Pump #2":"11.0048"},
{ "name:":"10/13/21 02:56:51  PM","Dehumidifier":"34.2580","Pump #2":"10.6096"},
{ "name:":"10/13/21 02:57:51  PM","Dehumidifier":"34.2672","Pump #2":"10.1640"},
{ "name:":"10/13/21 02:58:51  PM","Dehumidifier":"34.5449","Pump #2":"10.2876"},
{ "name:":"10/13/21 02:59:51  PM","Dehumidifier":"34.2962","Pump #2":"11.0491"},
{ "name:":"10/13/21 03:00:51  PM","Dehumidifier":"34.1924","Pump #2":"10.4753"},
{ "name:":"10/13/21 03:01:51  PM","Dehumidifier":"33.0388","Pump #2":"10.4189"},
{ "name:":"10/13/21 03:02:51  PM","Dehumidifier":"32.7581","Pump #2":"10.3319"},
{ "name:":"10/13/21 03:03:51  PM","Dehumidifier":"32.6116","Pump #2":"10.4082"},
{ "name:":"10/13/21 03:04:51  PM","Dehumidifier":"32.7398","Pump #2":"11.0811"},
{ "name:":"10/13/21 03:05:51  PM","Dehumidifier":"32.8191","Pump #2":"10.2907"},
{ "name:":"10/13/21 03:06:51  PM","Dehumidifier":"32.7535","Pump #2":"10.7866"},
{ "name:":"10/13/21 03:07:51  PM","Dehumidifier":"32.7718","Pump #2":"10.5211"},
{ "name:":"10/13/21 03:08:51  PM","Dehumidifier":"32.6040","Pump #2":"10.6813"},
{ "name:":"10/13/21 03:09:51  PM","Dehumidifier":"32.7291","Pump #2":"10.6798"},
{ "name:":"10/13/21 03:10:51  PM","Dehumidifier":"33.7697","Pump #2":"10.4280"},
{ "name:":"10/13/21 03:11:51  PM","Dehumidifier":"35.1873","Pump #2":"10.5669"},
{ "name:":"10/13/21 03:12:51  PM","Dehumidifier":"34.6532","Pump #2":"10.3624"},
{ "name:":"10/13/21 03:13:51  PM","Dehumidifier":"34.3496","Pump #2":"10.3151"},
{ "name:":"10/13/21 03:14:51  PM","Dehumidifier":"34.3923","Pump #2":"10.6783"},
{ "name:":"10/13/21 03:15:51  PM","Dehumidifier":"34.2565","Pump #2":"10.5425"},
{ "name:":"10/13/21 03:16:51  PM","Dehumidifier":"34.2596","Pump #2":"10.1961"},
{ "name:":"10/13/21 03:17:51  PM","Dehumidifier":"34.3038","Pump #2":"10.4387"},
{ "name:":"10/13/21 03:18:51  PM","Dehumidifier":"34.1741","Pump #2":"10.6920"},
{ "name:":"10/13/21 03:19:51  PM","Dehumidifier":"33.1899","Pump #2":"10.7042"},
{ "name:":"10/13/21 03:20:51  PM","Dehumidifier":"32.8557","Pump #2":"10.6813"},
{ "name:":"10/13/21 03:21:51  PM","Dehumidifier":"32.5765","Pump #2":"10.3197"},
{ "name:":"10/13/21 03:22:51  PM","Dehumidifier":"32.6741","Pump #2":"10.7317"},
{ "name:":"10/13/21 03:23:51  PM","Dehumidifier":"32.7459","Pump #2":"10.9758"},
{ "name:":"10/13/21 03:24:51  PM","Dehumidifier":"32.8267","Pump #2":"10.7103"},
{ "name:":"10/13/21 03:25:51  PM","Dehumidifier":"32.7230","Pump #2":"10.8766"},
{ "name:":"10/13/21 03:26:51  PM","Dehumidifier":"32.6635","Pump #2":"10.5303"},
{ "name:":"10/13/21 03:27:51  PM","Dehumidifier":"32.5490","Pump #2":"10.9773"},
{ "name:":"10/13/21 03:28:51  PM","Dehumidifier":"33.0861","Pump #2":"10.7836"},
{ "name:":"10/13/21 03:29:51  PM","Dehumidifier":"34.5602","Pump #2":"10.3792"},
{ "name:":"10/13/21 03:30:51  PM","Dehumidifier":"34.0734","Pump #2":"10.0267"},
{ "name:":"10/13/21 03:31:51  PM","Dehumidifier":"34.5693","Pump #2":"10.7744"},
{ "name:":"10/13/21 03:32:51  PM","Dehumidifier":"34.4152","Pump #2":"10.7256"},
{ "name:":"10/13/21 03:33:51  PM","Dehumidifier":"34.3069","Pump #2":"10.7301"},
{ "name:":"10/13/21 03:34:51  PM","Dehumidifier":"34.3008","Pump #2":"10.4616"},
{ "name:":"10/13/21 03:35:51  PM","Dehumidifier":"34.3786","Pump #2":"11.1604"},
{ "name:":"10/13/21 03:36:51  PM","Dehumidifier":"34.5586","Pump #2":"10.1915"},
{ "name:":"10/13/21 03:37:51  PM","Dehumidifier":"34.3801","Pump #2":"10.6737"},
{ "name:":"10/13/21 03:38:51  PM","Dehumidifier":"33.1685","Pump #2":"10.9728"},
{ "name:":"10/13/21 03:39:51  PM","Dehumidifier":"32.6787","Pump #2":"11.0170"},
{ "name:":"10/13/21 03:40:51  PM","Dehumidifier":"32.5566","Pump #2":"10.9621"},
{ "name:":"10/13/21 03:41:51  PM","Dehumidifier":"32.6726","Pump #2":"10.8644"},
{ "name:":"10/13/21 03:42:51  PM","Dehumidifier":"32.7993","Pump #2":"10.8904"},
{ "name:":"10/13/21 03:43:51  PM","Dehumidifier":"32.8710","Pump #2":"10.4067"},
{ "name:":"10/13/21 03:44:51  PM","Dehumidifier":"32.7199","Pump #2":"10.4692"},
{ "name:":"10/13/21 03:45:51  PM","Dehumidifier":"32.6024","Pump #2":"10.2663"},
{ "name:":"10/13/21 03:46:51  PM","Dehumidifier":"32.5048","Pump #2":"10.7088"},
{ "name:":"10/13/21 03:47:51  PM","Dehumidifier":"33.2494","Pump #2":"10.8476"},
{ "name:":"10/13/21 03:48:51  PM","Dehumidifier":"34.3313","Pump #2":"10.2815"},
{ "name:":"10/13/21 03:49:51  PM","Dehumidifier":"33.9452","Pump #2":"10.7332"},
{ "name:":"10/13/21 03:50:51  PM","Dehumidifier":"34.3908","Pump #2":"10.6416"},
{ "name:":"10/13/21 03:51:51  PM","Dehumidifier":"34.0642","Pump #2":"10.6554"},
{ "name:":"10/13/21 03:52:51  PM","Dehumidifier":"34.0719","Pump #2":"10.4036"},
{ "name:":"10/13/21 03:53:51  PM","Dehumidifier":"34.0719","Pump #2":"10.9865"},
{ "name:":"10/13/21 03:54:51  PM","Dehumidifier":"33.9849","Pump #2":"10.9102"},
{ "name:":"10/13/21 03:55:51  PM","Dehumidifier":"34.0063","Pump #2":"11.2749"},
{ "name:":"10/13/21 03:56:51  PM","Dehumidifier":"33.3913","Pump #2":"10.9361"},
{ "name:":"10/13/21 03:57:51  PM","Dehumidifier":"32.7550","Pump #2":"11.3649"},
{ "name:":"10/13/21 03:58:51  PM","Dehumidifier":"32.4208","Pump #2":"11.0491"},
{ "name:":"10/13/21 03:59:51  PM","Dehumidifier":"32.5475","Pump #2":"11.2932"},
{ "name:":"10/13/21 04:00:51  PM","Dehumidifier":"32.6436","Pump #2":"10.8476"},
{ "name:":"10/13/21 04:01:51  PM","Dehumidifier":"32.7199","Pump #2":"11.3924"},
{ "name:":"10/13/21 04:02:51  PM","Dehumidifier":"32.7413","Pump #2":"10.9239"},
{ "name:":"10/13/21 04:03:51  PM","Dehumidifier":"32.5643","Pump #2":"10.9010"},
{ "name:":"10/13/21 04:04:51  PM","Dehumidifier":"32.4880","Pump #2":"10.3944"},
{ "name:":"10/13/21 04:05:51  PM","Dehumidifier":"32.4743","Pump #2":"10.6935"},
{ "name:":"10/13/21 04:06:51  PM","Dehumidifier":"34.1833","Pump #2":"10.8797"},
{ "name:":"10/13/21 04:07:51  PM","Dehumidifier":"34.5861","Pump #2":"10.9545"},
{ "name:":"10/13/21 04:08:51  PM","Dehumidifier":"34.5998","Pump #2":"10.5898"},
{ "name:":"10/13/21 04:09:51  PM","Dehumidifier":"34.3343","Pump #2":"10.6767"},
{ "name:":"10/13/21 04:10:51  PM","Dehumidifier":"34.1161","Pump #2":"10.6905"},
{ "name:":"10/13/21 04:11:51  PM","Dehumidifier":"33.9635","Pump #2":"10.8766"},
{ "name:":"10/13/21 04:12:51  PM","Dehumidifier":"34.0108","Pump #2":"10.8232"},
{ "name:":"10/13/21 04:13:51  PM","Dehumidifier":"34.1939","Pump #2":"11.0903"},
{ "name:":"10/13/21 04:14:51  PM","Dehumidifier":"34.1299","Pump #2":"11.0323"},
{ "name:":"10/13/21 04:15:51  PM","Dehumidifier":"33.2097","Pump #2":"11.2062"},
{ "name:":"10/13/21 04:16:51  PM","Dehumidifier":"32.8939","Pump #2":"10.9834"},
{ "name:":"10/13/21 04:17:51  PM","Dehumidifier":"32.5109","Pump #2":"11.4748"},
{ "name:":"10/13/21 04:18:51  PM","Dehumidifier":"32.4987","Pump #2":"11.2016"},
{ "name:":"10/13/21 04:19:51  PM","Dehumidifier":"32.6787","Pump #2":"10.4891"},
{ "name:":"10/13/21 04:20:51  PM","Dehumidifier":"32.7504","Pump #2":"10.5608"},
{ "name:":"10/13/21 04:21:51  PM","Dehumidifier":"32.7245","Pump #2":"11.3893"},
{ "name:":"10/13/21 04:22:51  PM","Dehumidifier":"32.5719","Pump #2":"10.9773"},
{ "name:":"10/13/21 04:23:51  PM","Dehumidifier":"32.4224","Pump #2":"11.0399"},
{ "name:":"10/13/21 04:24:51  PM","Dehumidifier":"32.8878","Pump #2":"11.0735"},
{ "name:":"10/13/21 04:25:51  PM","Dehumidifier":"34.2412","Pump #2":"10.8217"},
{ "name:":"10/13/21 04:26:51  PM","Dehumidifier":"34.7097","Pump #2":"11.2322"},
{ "name:":"10/13/21 04:27:51  PM","Dehumidifier":"34.5556","Pump #2":"11.1849"},
{ "name:":"10/13/21 04:28:51  PM","Dehumidifier":"34.3267","Pump #2":"11.3634"},
{ "name:":"10/13/21 04:29:51  PM","Dehumidifier":"34.1924","Pump #2":"11.1727"},
{ "name:":"10/13/21 04:30:51  PM","Dehumidifier":"34.2016","Pump #2":"11.2276"},
{ "name:":"10/13/21 04:31:51  PM","Dehumidifier":"34.2596","Pump #2":"11.1254"},
{ "name:":"10/13/21 04:32:51  PM","Dehumidifier":"34.1512","Pump #2":"11.2566"},
{ "name:":"10/13/21 04:33:51  PM","Dehumidifier":"34.1085","Pump #2":"10.8309"},
{ "name:":"10/13/21 04:34:51  PM","Dehumidifier":"33.4203","Pump #2":"11.5618"},
{ "name:":"10/13/21 04:35:51  PM","Dehumidifier":"33.0526","Pump #2":"11.2322"},
{ "name:":"10/13/21 04:36:51  PM","Dehumidifier":"32.7306","Pump #2":"11.1269"},
{ "name:":"10/13/21 04:37:51  PM","Dehumidifier":"32.8359","Pump #2":"10.9331"},
{ "name:":"10/13/21 04:38:51  PM","Dehumidifier":"32.9244","Pump #2":"11.4946"},
{ "name:":"10/13/21 04:39:51  PM","Dehumidifier":"32.7092","Pump #2":"11.2551"},
{ "name:":"10/13/21 04:40:51  PM","Dehumidifier":"32.9915","Pump #2":"11.2444"},
{ "name:":"10/13/21 04:41:51  PM","Dehumidifier":"32.6726","Pump #2":"11.2154"},
{ "name:":"10/13/21 04:42:51  PM","Dehumidifier":"32.7138","Pump #2":"11.2413"},
{ "name:":"10/13/21 04:43:51  PM","Dehumidifier":"33.0327","Pump #2":"11.3771"},
{ "name:":"10/13/21 04:44:51  PM","Dehumidifier":"34.3389","Pump #2":"11.4687"},
{ "name:":"10/13/21 04:45:51  PM","Dehumidifier":"34.9294","Pump #2":"10.8751"},
{ "name:":"10/13/21 04:46:51  PM","Dehumidifier":"34.5281","Pump #2":"10.7073"},
{ "name:":"10/13/21 04:47:51  PM","Dehumidifier":"34.1024","Pump #2":"10.4540"},
{ "name:":"10/13/21 04:48:51  PM","Dehumidifier":"33.8735","Pump #2":"10.6981"},
{ "name:":"10/13/21 04:49:51  PM","Dehumidifier":"33.8415","Pump #2":"10.6630"},
{ "name:":"10/13/21 04:50:51  PM","Dehumidifier":"33.9162","Pump #2":"10.1656"},
{ "name:":"10/13/21 04:51:51  PM","Dehumidifier":"33.8872","Pump #2":"10.6371"},
{ "name:":"10/13/21 04:52:51  PM","Dehumidifier":"33.9239","Pump #2":"10.7301"},
{ "name:":"10/13/21 04:53:51  PM","Dehumidifier":"34.1375","Pump #2":"10.6661"},
{ "name:":"10/13/21 04:54:51  PM","Dehumidifier":"33.8170","Pump #2":"10.8385"},
{ "name:":"10/13/21 04:55:51  PM","Dehumidifier":"33.1579","Pump #2":"10.5135"},
{ "name:":"10/13/21 04:56:51  PM","Dehumidifier":"32.8634","Pump #2":"11.0719"},
{ "name:":"10/13/21 04:57:51  PM","Dehumidifier":"32.8878","Pump #2":"11.0262"},
{ "name:":"10/13/21 04:58:51  PM","Dehumidifier":"33.0617","Pump #2":"11.1925"},
{ "name:":"10/13/21 04:59:51  PM","Dehumidifier":"33.0510","Pump #2":"11.5221"},
{ "name:":"10/13/21 05:00:51  PM","Dehumidifier":"33.0159","Pump #2":"10.9545"},
{ "name:":"10/13/21 05:01:51  PM","Dehumidifier":"32.9290","Pump #2":"10.8309"},
{ "name:":"10/13/21 05:02:51  PM","Dehumidifier":"32.7123","Pump #2":"11.4366"},
{ "name:":"10/13/21 05:03:51  PM","Dehumidifier":"33.0541","Pump #2":"11.3542"},
{ "name:":"10/13/21 05:04:51  PM","Dehumidifier":"34.4717","Pump #2":"11.0338"},
{ "name:":"10/13/21 05:05:51  PM","Dehumidifier":"34.1955","Pump #2":"10.7469"},
{ "name:":"10/13/21 05:06:51  PM","Dehumidifier":"34.7189","Pump #2":"11.1681"},
{ "name:":"10/13/21 05:07:51  PM","Dehumidifier":"34.2168","Pump #2":"10.8919"},
{ "name:":"10/13/21 05:08:51  PM","Dehumidifier":"34.0780","Pump #2":"10.8415"},
{ "name:":"10/13/21 05:09:51  PM","Dehumidifier":"34.0536","Pump #2":"11.1406"},
{ "name:":"10/13/21 05:10:51  PM","Dehumidifier":"33.9590","Pump #2":"11.1055"},
{ "name:":"10/13/21 05:11:51  PM","Dehumidifier":"33.8445","Pump #2":"10.5180"},
{ "name:":"10/13/21 05:12:51  PM","Dehumidifier":"33.9864","Pump #2":"10.7561"},
{ "name:":"10/13/21 05:13:51  PM","Dehumidifier":"34.0200","Pump #2":"11.1666"},
{ "name:":"10/13/21 05:14:51  PM","Dehumidifier":"33.8750","Pump #2":"10.8705"},
{ "name:":"10/13/21 05:15:51  PM","Dehumidifier":"32.9183","Pump #2":"11.1910"},
{ "name:":"10/13/21 05:16:51  PM","Dehumidifier":"32.5277","Pump #2":"11.4183"},
{ "name:":"10/13/21 05:17:51  PM","Dehumidifier":"32.4147","Pump #2":"11.0475"},
{ "name:":"10/13/21 05:18:51  PM","Dehumidifier":"32.6345","Pump #2":"11.3146"},
{ "name:":"10/13/21 05:19:51  PM","Dehumidifier":"32.8008","Pump #2":"11.5190"},
{ "name:":"10/13/21 05:20:51  PM","Dehumidifier":"32.7871","Pump #2":"11.0765"},
{ "name:":"10/13/21 05:21:51  PM","Dehumidifier":"32.5887","Pump #2":"11.2016"},
{ "name:":"10/13/21 05:22:51  PM","Dehumidifier":"32.7428","Pump #2":"11.4122"},
{ "name:":"10/13/21 05:23:51  PM","Dehumidifier":"32.1508","Pump #2":"11.4733"},
{ "name:":"10/13/21 05:24:51  PM","Dehumidifier":"32.4575","Pump #2":"11.4626"},
{ "name:":"10/13/21 05:25:51  PM","Dehumidifier":"32.4926","Pump #2":"11.1482"},
{ "name:":"10/13/21 05:26:51  PM","Dehumidifier":"32.5429","Pump #2":"11.6930"},
{ "name:":"10/13/21 05:27:51  PM","Dehumidifier":"32.4681","Pump #2":"12.1599"},
{ "name:":"10/13/21 05:28:51  PM","Dehumidifier":"32.7886","Pump #2":"11.4122"},
{ "name:":"10/13/21 05:29:51  PM","Dehumidifier":"34.2214","Pump #2":"11.3573"},
{ "name:":"10/13/21 05:30:51  PM","Dehumidifier":"34.4778","Pump #2":"11.5999"},
{ "name:":"10/13/21 05:31:51  PM","Dehumidifier":"33.7301","Pump #2":"11.3222"},
{ "name:":"10/13/21 05:32:51  PM","Dehumidifier":"34.4793","Pump #2":"11.3527"},
{ "name:":"10/13/21 05:33:51  PM","Dehumidifier":"34.0490","Pump #2":"11.4992"},
{ "name:":"10/13/21 05:34:51  PM","Dehumidifier":"34.0276","Pump #2":"11.2840"},
{ "name:":"10/13/21 05:35:51  PM","Dehumidifier":"33.8872","Pump #2":"11.3146"},
{ "name:":"10/13/21 05:36:51  PM","Dehumidifier":"33.7728","Pump #2":"11.1437"},
{ "name:":"10/13/21 05:37:51  PM","Dehumidifier":"33.6797","Pump #2":"11.5679"},
{ "name:":"10/13/21 05:38:51  PM","Dehumidifier":"33.7270","Pump #2":"11.7998"},
{ "name:":"10/13/21 05:39:51  PM","Dehumidifier":"33.6873","Pump #2":"11.4763"},
{ "name:":"10/13/21 05:40:51  PM","Dehumidifier":"33.6339","Pump #2":"11.5175"},
{ "name:":"10/13/21 05:41:51  PM","Dehumidifier":"33.7591","Pump #2":"11.2963"},
{ "name:":"10/13/21 05:42:51  PM","Dehumidifier":"32.9488","Pump #2":"11.3176"},
{ "name:":"10/13/21 05:43:51  PM","Dehumidifier":"32.7214","Pump #2":"11.4534"},
{ "name:":"10/13/21 05:44:51  PM","Dehumidifier":"32.6543","Pump #2":"11.3634"},
{ "name:":"10/13/21 05:45:51  PM","Dehumidifier":"32.8450","Pump #2":"11.5358"},
{ "name:":"10/13/21 05:46:51  PM","Dehumidifier":"32.8237","Pump #2":"11.3741"},
{ "name:":"10/13/21 05:47:51  PM","Dehumidifier":"32.7504","Pump #2":"11.2703"},
{ "name:":"10/13/21 05:48:51  PM","Dehumidifier":"32.7764","Pump #2":"11.7128"},
{ "name:":"10/13/21 05:49:51  PM","Dehumidifier":"33.3379","Pump #2":"11.0338"},
{ "name:":"10/13/21 05:50:51  PM","Dehumidifier":"34.0536","Pump #2":"11.8074"},
{ "name:":"10/13/21 05:51:51  PM","Dehumidifier":"33.6446","Pump #2":"11.5099"},
{ "name:":"10/13/21 05:52:51  PM","Dehumidifier":"34.3664","Pump #2":"11.2779"},
{ "name:":"10/13/21 05:53:51  PM","Dehumidifier":"33.9193","Pump #2":"11.4000"},
{ "name:":"10/13/21 05:54:51  PM","Dehumidifier":"34.0383","Pump #2":"11.3359"},
{ "name:":"10/13/21 05:55:51  PM","Dehumidifier":"33.9422","Pump #2":"11.5297"},
{ "name:":"10/13/21 05:56:51  PM","Dehumidifier":"33.6294","Pump #2":"11.4305"},
{ "name:":"10/13/21 05:57:51  PM","Dehumidifier":"33.8277","Pump #2":"11.5846"},
{ "name:":"10/13/21 05:58:51  PM","Dehumidifier":"34.0185","Pump #2":"10.9422"},
{ "name:":"10/13/21 05:59:51  PM","Dehumidifier":"33.1914","Pump #2":"11.7830"},
{ "name:":"10/13/21 06:00:51  PM","Dehumidifier":"32.8267","Pump #2":"11.0231"},
{ "name:":"10/13/21 06:01:51  PM","Dehumidifier":"32.6711","Pump #2":"11.3832"},
{ "name:":"10/13/21 06:02:51  PM","Dehumidifier":"32.6848","Pump #2":"11.9097"},
{ "name:":"10/13/21 06:03:51  PM","Dehumidifier":"32.8649","Pump #2":"11.4046"},
{ "name:":"10/13/21 06:04:51  PM","Dehumidifier":"32.7962","Pump #2":"11.8318"},
{ "name:":"10/13/21 06:05:51  PM","Dehumidifier":"32.6375","Pump #2":"11.2612"},
{ "name:":"10/13/21 06:06:51  PM","Dehumidifier":"32.4895","Pump #2":"11.6548"},
{ "name:":"10/13/21 06:07:51  PM","Dehumidifier":"32.4575","Pump #2":"11.6258"},
{ "name:":"10/13/21 06:08:51  PM","Dehumidifier":"33.5454","Pump #2":"11.4168"},
{ "name:":"10/13/21 06:09:51  PM","Dehumidifier":"34.3069","Pump #2":"11.0964"},
{ "name:":"10/13/21 06:10:51  PM","Dehumidifier":"33.7148","Pump #2":"11.5419"},
{ "name:":"10/13/21 06:11:51  PM","Dehumidifier":"34.2061","Pump #2":"11.3222"},
{ "name:":"10/13/21 06:12:51  PM","Dehumidifier":"33.8216","Pump #2":"11.3497"},
{ "name:":"10/13/21 06:13:51  PM","Dehumidifier":"33.8750","Pump #2":"11.1635"},
{ "name:":"10/13/21 06:14:51  PM","Dehumidifier":"33.8338","Pump #2":"11.9707"},
{ "name:":"10/13/21 06:15:51  PM","Dehumidifier":"33.9391","Pump #2":"11.7876"},
{ "name:":"10/13/21 06:16:51  PM","Dehumidifier":"33.7652","Pump #2":"11.5465"},
{ "name:":"10/13/21 06:17:51  PM","Dehumidifier":"34.0307","Pump #2":"11.5145"},
{ "name:":"10/13/21 06:18:51  PM","Dehumidifier":"33.9803","Pump #2":"11.3817"},
{ "name:":"10/13/21 06:19:51  PM","Dehumidifier":"33.2067","Pump #2":"11.5984"},
{ "name:":"10/13/21 06:20:51  PM","Dehumidifier":"32.9931","Pump #2":"11.5923"},
{ "name:":"10/13/21 06:21:51  PM","Dehumidifier":"32.8557","Pump #2":"11.7250"},
{ "name:":"10/13/21 06:22:51  PM","Dehumidifier":"33.0907","Pump #2":"11.4122"},
{ "name:":"10/13/21 06:23:51  PM","Dehumidifier":"33.0159","Pump #2":"11.4351"},
{ "name:":"10/13/21 06:24:51  PM","Dehumidifier":"33.0632","Pump #2":"11.5114"},
{ "name:":"10/13/21 06:25:51  PM","Dehumidifier":"32.9458","Pump #2":"11.9448"},
{ "name:":"10/13/21 06:26:51  PM","Dehumidifier":"32.4773","Pump #2":"11.7235"},
{ "name:":"10/13/21 06:27:51  PM","Dehumidifier":"32.3308","Pump #2":"11.8029"},
{ "name:":"10/13/21 06:28:51  PM","Dehumidifier":"32.3201","Pump #2":"11.7189"},
{ "name:":"10/13/21 06:29:51  PM","Dehumidifier":"32.4147","Pump #2":"11.3878"},
{ "name:":"10/13/21 06:30:51  PM","Dehumidifier":"32.4071","Pump #2":"11.6838"},
{ "name:":"10/13/21 06:31:51  PM","Dehumidifier":"32.4361","Pump #2":"11.4549"},
{ "name:":"10/13/21 06:32:51  PM","Dehumidifier":"32.4849","Pump #2":"11.6243"},
{ "name:":"10/13/21 06:33:51  PM","Dehumidifier":"34.0444","Pump #2":"11.6045"},
{ "name:":"10/13/21 06:34:51  PM","Dehumidifier":"34.4900","Pump #2":"11.5801"},
{ "name:":"10/13/21 06:35:51  PM","Dehumidifier":"34.8806","Pump #2":"11.9677"},
{ "name:":"10/13/21 06:36:51  PM","Dehumidifier":"34.1207","Pump #2":"11.5587"},
{ "name:":"10/13/21 06:37:51  PM","Dehumidifier":"34.5113","Pump #2":"11.5938"},
{ "name:":"10/13/21 06:38:51  PM","Dehumidifier":"34.2184","Pump #2":"11.4672"},
{ "name:":"10/13/21 06:39:51  PM","Dehumidifier":"33.9773","Pump #2":"11.5709"},
{ "name:":"10/13/21 06:40:51  PM","Dehumidifier":"33.9864","Pump #2":"11.7601"},
{ "name:":"10/13/21 06:41:51  PM","Dehumidifier":"33.9101","Pump #2":"12.0211"},
{ "name:":"10/13/21 06:42:51  PM","Dehumidifier":"33.9696","Pump #2":"11.8090"},
{ "name:":"10/13/21 06:43:51  PM","Dehumidifier":"33.9620","Pump #2":"11.7113"},
{ "name:":"10/13/21 06:44:51  PM","Dehumidifier":"33.9117","Pump #2":"11.3634"},
{ "name:":"10/13/21 06:45:51  PM","Dehumidifier":"33.8064","Pump #2":"11.5221"},
{ "name:":"10/13/21 06:46:51  PM","Dehumidifier":"33.9864","Pump #2":"11.7556"},
{ "name:":"10/13/21 06:47:51  PM","Dehumidifier":"33.3715","Pump #2":"11.1330"},
{ "name:":"10/13/21 06:48:51  PM","Dehumidifier":"33.1380","Pump #2":"11.5053"},
{ "name:":"10/13/21 06:49:51  PM","Dehumidifier":"32.9244","Pump #2":"11.6823"},
{ "name:":"10/13/21 06:50:51  PM","Dehumidifier":"33.1930","Pump #2":"11.2505"},
{ "name:":"10/13/21 06:51:51  PM","Dehumidifier":"33.1258","Pump #2":"11.5099"},
{ "name:":"10/13/21 06:52:51  PM","Dehumidifier":"33.1685","Pump #2":"11.3085"},
{ "name:":"10/13/21 06:53:51  PM","Dehumidifier":"32.9686","Pump #2":"11.8318"},
{ "name:":"10/13/21 06:54:51  PM","Dehumidifier":"33.7270","Pump #2":"11.7815"},
{ "name:":"10/13/21 06:55:51  PM","Dehumidifier":"34.2748","Pump #2":"11.8959"},
{ "name:":"10/13/21 06:56:51  PM","Dehumidifier":"34.4656","Pump #2":"11.9982"},
{ "name:":"10/13/21 06:57:51  PM","Dehumidifier":"34.0047","Pump #2":"11.7144"},
{ "name:":"10/13/21 06:58:51  PM","Dehumidifier":"34.2504","Pump #2":"11.9524"},
{ "name:":"10/13/21 06:59:51  PM","Dehumidifier":"33.8949","Pump #2":"11.5023"},
{ "name:":"10/13/21 07:00:51  PM","Dehumidifier":"33.9437","Pump #2":"11.9829"},
{ "name:":"10/13/21 07:01:51  PM","Dehumidifier":"33.8231","Pump #2":"11.5938"},
{ "name:":"10/13/21 07:02:51  PM","Dehumidifier":"33.8369","Pump #2":"11.5602"},
{ "name:":"10/13/21 07:03:51  PM","Dehumidifier":"33.8643","Pump #2":"11.4809"},
{ "name:":"10/13/21 07:04:51  PM","Dehumidifier":"33.8293","Pump #2":"11.2856"},
{ "name:":"10/13/21 07:05:51  PM","Dehumidifier":"33.1518","Pump #2":"11.2184"},
{ "name:":"10/13/21 07:06:51  PM","Dehumidifier":"32.8466","Pump #2":"11.4839"},
{ "name:":"10/13/21 07:07:51  PM","Dehumidifier":"32.9061","Pump #2":"11.8257"},
{ "name:":"10/13/21 07:08:51  PM","Dehumidifier":"32.9870","Pump #2":"10.9407"},
{ "name:":"10/13/21 07:09:51  PM","Dehumidifier":"33.0816","Pump #2":"11.4305"},
{ "name:":"10/13/21 07:10:51  PM","Dehumidifier":"32.9976","Pump #2":"11.2169"},
{ "name:":"10/13/21 07:11:51  PM","Dehumidifier":"32.8923","Pump #2":"11.1498"},
{ "name:":"10/13/21 07:12:51  PM","Dehumidifier":"32.1111","Pump #2":"11.3817"},
{ "name:":"10/13/21 07:13:51  PM","Dehumidifier":"31.8349","Pump #2":"11.3725"},
{ "name:":"10/13/21 07:14:51  PM","Dehumidifier":"31.8303","Pump #2":"11.2032"},
{ "name:":"10/13/21 07:15:51  PM","Dehumidifier":"31.9005","Pump #2":"11.8471"},
{ "name:":"10/13/21 07:16:51  PM","Dehumidifier":"31.8379","Pump #2":"11.6365"},
{ "name:":"10/13/21 07:17:51  PM","Dehumidifier":"32.0439","Pump #2":"11.6960"},
{ "name:":"10/13/21 07:18:51  PM","Dehumidifier":"32.4407","Pump #2":"11.6152"},
{ "name:":"10/13/21 07:19:51  PM","Dehumidifier":"34.2458","Pump #2":"11.9051"},
{ "name:":"10/13/21 07:20:51  PM","Dehumidifier":"34.6151","Pump #2":"11.7723"},
{ "name:":"10/13/21 07:21:51  PM","Dehumidifier":"34.5602","Pump #2":"11.8486"},
{ "name:":"10/13/21 07:22:51  PM","Dehumidifier":"34.5190","Pump #2":"11.5740"},
{ "name:":"10/13/21 07:23:51  PM","Dehumidifier":"34.0536","Pump #2":"11.2779"},
{ "name:":"10/13/21 07:24:51  PM","Dehumidifier":"34.0841","Pump #2":"11.6365"},
{ "name:":"10/13/21 07:25:51  PM","Dehumidifier":"33.9956","Pump #2":"11.3664"},
{ "name:":"10/13/21 07:26:51  PM","Dehumidifier":"34.0093","Pump #2":"11.3390"},
{ "name:":"10/13/21 07:27:51  PM","Dehumidifier":"34.0078","Pump #2":"11.5984"},
{ "name:":"10/13/21 07:28:51  PM","Dehumidifier":"33.9574","Pump #2":"11.8151"},
{ "name:":"10/13/21 07:29:51  PM","Dehumidifier":"33.9879","Pump #2":"11.5755"},
{ "name:":"10/13/21 07:30:51  PM","Dehumidifier":"33.9742","Pump #2":"12.1401"},
{ "name:":"10/13/21 07:31:51  PM","Dehumidifier":"33.9590","Pump #2":"11.8364"},
{ "name:":"10/13/21 07:32:51  PM","Dehumidifier":"33.2677","Pump #2":"12.1019"},
{ "name:":"10/13/21 07:33:51  PM","Dehumidifier":"32.9107","Pump #2":"11.9875"},
{ "name:":"10/13/21 07:34:51  PM","Dehumidifier":"33.0022","Pump #2":"11.9310"},
{ "name:":"10/13/21 07:35:51  PM","Dehumidifier":"33.0449","Pump #2":"11.8105"},
{ "name:":"10/13/21 07:36:51  PM","Dehumidifier":"33.1319","Pump #2":"11.9402"},
{ "name:":"10/13/21 07:37:51  PM","Dehumidifier":"32.9808","Pump #2":"11.9066"},
{ "name:":"10/13/21 07:38:51  PM","Dehumidifier":"32.7428","Pump #2":"11.4916"},
{ "name:":"10/13/21 07:39:51  PM","Dehumidifier":"33.5256","Pump #2":"11.6548"},
{ "name:":"10/13/21 07:40:51  PM","Dehumidifier":"34.1314","Pump #2":"11.5099"},
{ "name:":"10/13/21 07:41:51  PM","Dehumidifier":"34.0414","Pump #2":"11.4626"},
{ "name:":"10/13/21 07:42:51  PM","Dehumidifier":"34.2199","Pump #2":"11.6823"},
{ "name:":"10/13/21 07:43:51  PM","Dehumidifier":"33.8308","Pump #2":"11.8395"},
{ "name:":"10/13/21 07:44:51  PM","Dehumidifier":"33.6080","Pump #2":"11.6579"},
{ "name:":"10/13/21 07:45:51  PM","Dehumidifier":"33.6385","Pump #2":"11.3466"},
{ "name:":"10/13/21 07:46:51  PM","Dehumidifier":"33.7636","Pump #2":"11.3573"},
{ "name:":"10/13/21 07:47:51  PM","Dehumidifier":"33.8247","Pump #2":"11.3848"},
{ "name:":"10/13/21 07:48:51  PM","Dehumidifier":"33.1060","Pump #2":"11.2367"},
{ "name:":"10/13/21 07:49:51  PM","Dehumidifier":"32.7520","Pump #2":"11.5557"},
{ "name:":"10/13/21 07:50:51  PM","Dehumidifier":"32.7901","Pump #2":"11.6014"},
{ "name:":"10/13/21 07:51:51  PM","Dehumidifier":"32.9488","Pump #2":"11.5373"},
{ "name:":"10/13/21 07:52:51  PM","Dehumidifier":"32.8847","Pump #2":"11.5602"},
{ "name:":"10/13/21 07:53:51  PM","Dehumidifier":"32.8481","Pump #2":"11.4717"},
{ "name:":"10/13/21 07:54:51  PM","Dehumidifier":"32.8786","Pump #2":"11.7525"},
{ "name:":"10/13/21 07:55:51  PM","Dehumidifier":"32.8222","Pump #2":"11.3588"},
{ "name:":"10/13/21 07:56:51  PM","Dehumidifier":"32.5109","Pump #2":"11.5831"},
{ "name:":"10/13/21 07:57:51  PM","Dehumidifier":"32.2316","Pump #2":"11.7586"},
{ "name:":"10/13/21 07:58:51  PM","Dehumidifier":"32.3842","Pump #2":"11.7525"},
{ "name:":"10/13/21 07:59:51  PM","Dehumidifier":"32.3720","Pump #2":"11.8013"},
{ "name:":"10/13/21 08:00:51  PM","Dehumidifier":"32.2957","Pump #2":"11.7662"},
{ "name:":"10/13/21 08:01:51  PM","Dehumidifier":"32.3125","Pump #2":"11.9295"},
{ "name:":"10/13/21 08:02:51  PM","Dehumidifier":"32.1691","Pump #2":"11.4168"},
{ "name:":"10/13/21 08:03:51  PM","Dehumidifier":"33.6461","Pump #2":"11.7250"},
{ "name:":"10/13/21 08:04:51  PM","Dehumidifier":"34.2306","Pump #2":"11.9692"},
{ "name:":"10/13/21 08:05:51  PM","Dehumidifier":"34.1863","Pump #2":"11.6106"},
{ "name:":"10/13/21 08:06:51  PM","Dehumidifier":"34.0230","Pump #2":"11.7403"},
{ "name:":"10/13/21 08:07:51  PM","Dehumidifier":"33.9040","Pump #2":"11.8547"},
{ "name:":"10/13/21 08:08:51  PM","Dehumidifier":"33.7163","Pump #2":"11.6793"},
{ "name:":"10/13/21 08:09:51  PM","Dehumidifier":"33.7453","Pump #2":"10.9285"},
{ "name:":"10/13/21 08:10:51  PM","Dehumidifier":"33.7758","Pump #2":"11.1528"},
{ "name:":"10/13/21 08:11:51  PM","Dehumidifier":"33.7591","Pump #2":"11.2505"},
{ "name:":"10/13/21 08:12:51  PM","Dehumidifier":"33.8567","Pump #2":"11.6716"},
{ "name:":"10/13/21 08:13:51  PM","Dehumidifier":"33.7240","Pump #2":"11.0246"},
{ "name:":"10/13/21 08:14:51  PM","Dehumidifier":"33.7057","Pump #2":"11.2581"},
{ "name:":"10/13/21 08:15:51  PM","Dehumidifier":"33.1685","Pump #2":"11.7174"},
{ "name:":"10/13/21 08:16:51  PM","Dehumidifier":"32.9915","Pump #2":"11.5511"},
{ "name:":"10/13/21 08:17:51  PM","Dehumidifier":"32.6741","Pump #2":"10.3716"},
{ "name:":"10/13/21 08:18:51  PM","Dehumidifier":"32.8405","Pump #2":"11.1971"},
{ "name:":"10/13/21 08:19:51  PM","Dehumidifier":"32.9305","Pump #2":"11.1513"},
{ "name:":"10/13/21 08:20:51  PM","Dehumidifier":"32.7916","Pump #2":"11.2795"},
{ "name:":"10/13/21 08:21:51  PM","Dehumidifier":"32.8405","Pump #2":"10.9331"},
{ "name:":"10/13/21 08:22:51  PM","Dehumidifier":"32.7581","Pump #2":"11.2886"},
{ "name:":"10/13/21 08:23:51  PM","Dehumidifier":"33.5088","Pump #2":"11.3405"},
{ "name:":"10/13/21 08:24:51  PM","Dehumidifier":"34.1024","Pump #2":"11.1467"},
{ "name:":"10/13/21 08:25:51  PM","Dehumidifier":"34.1512","Pump #2":"11.1498"},
{ "name:":"10/13/21 08:26:51  PM","Dehumidifier":"33.9422","Pump #2":"11.7617"},
{ "name:":"10/13/21 08:27:51  PM","Dehumidifier":"33.7957","Pump #2":"11.3710"},
{ "name:":"10/13/21 08:28:51  PM","Dehumidifier":"33.5393","Pump #2":"11.0399"},
{ "name:":"10/13/21 08:29:51  PM","Dehumidifier":"33.6477","Pump #2":"11.0826"},
{ "name:":"10/13/21 08:30:51  PM","Dehumidifier":"33.7896","Pump #2":"11.2123"},
{ "name:":"10/13/21 08:31:51  PM","Dehumidifier":"33.9925","Pump #2":"11.3863"},
{ "name:":"10/13/21 08:32:51  PM","Dehumidifier":"32.9335","Pump #2":"11.2886"},
{ "name:":"10/13/21 08:33:51  PM","Dehumidifier":"32.6375","Pump #2":"11.6182"},
{ "name:":"10/13/21 08:34:51  PM","Dehumidifier":"32.4636","Pump #2":"11.5862"},
{ "name:":"10/13/21 08:35:51  PM","Dehumidifier":"32.7550","Pump #2":"11.3420"},
{ "name:":"10/13/21 08:36:51  PM","Dehumidifier":"32.6345","Pump #2":"11.6396"},
{ "name:":"10/13/21 08:37:51  PM","Dehumidifier":"32.6986","Pump #2":"11.2978"},
{ "name:":"10/13/21 08:38:51  PM","Dehumidifier":"32.6619","Pump #2":"11.5709"},
{ "name:":"10/13/21 08:39:51  PM","Dehumidifier":"32.6757","Pump #2":"11.5785"},
{ "name:":"10/13/21 08:40:51  PM","Dehumidifier":"32.5689","Pump #2":"11.8044"},
{ "name:":"10/13/21 08:41:51  PM","Dehumidifier":"33.4539","Pump #2":"11.4916"},
{ "name:":"10/13/21 08:42:51  PM","Dehumidifier":"34.3679","Pump #2":"11.1315"},
{ "name:":"10/13/21 08:43:51  PM","Dehumidifier":"34.1268","Pump #2":"11.4565"},
{ "name:":"10/13/21 08:44:51  PM","Dehumidifier":"34.0764","Pump #2":"11.5953"},
{ "name:":"10/13/21 08:45:51  PM","Dehumidifier":"33.9666","Pump #2":"11.8227"},
{ "name:":"10/13/21 08:46:51  PM","Dehumidifier":"34.0414","Pump #2":"11.5023"},
{ "name:":"10/13/21 08:47:51  PM","Dehumidifier":"33.9940","Pump #2":"11.2612"},
{ "name:":"10/13/21 08:48:51  PM","Dehumidifier":"33.7545","Pump #2":"11.0979"},
{ "name:":"10/13/21 08:49:51  PM","Dehumidifier":"32.6513","Pump #2":"11.5648"},
{ "name:":"10/13/21 08:50:51  PM","Dehumidifier":"32.3781","Pump #2":"11.8273"},
{ "name:":"10/13/21 08:51:51  PM","Dehumidifier":"32.2011","Pump #2":"10.9834"},
{ "name:":"10/13/21 08:52:51  PM","Dehumidifier":"32.3873","Pump #2":"11.4290"},
{ "name:":"10/13/21 08:53:51  PM","Dehumidifier":"32.4437","Pump #2":"11.1574"},
{ "name:":"10/13/21 08:54:51  PM","Dehumidifier":"32.5566","Pump #2":"11.3268"},
{ "name:":"10/13/21 08:55:51  PM","Dehumidifier":"32.5048","Pump #2":"10.9255"},
{ "name:":"10/13/21 08:56:51  PM","Dehumidifier":"32.3095","Pump #2":"11.1742"},
{ "name:":"10/13/21 08:57:51  PM","Dehumidifier":"32.1843","Pump #2":"11.2078"},
{ "name:":"10/13/21 08:58:51  PM","Dehumidifier":"32.1538","Pump #2":"11.2703"},
{ "name:":"10/13/21 08:59:51  PM","Dehumidifier":"32.1065","Pump #2":"11.3161"},
{ "name:":"10/13/21 09:00:51  PM","Dehumidifier":"32.1843","Pump #2":"11.5770"},
{ "name:":"10/13/21 09:01:51  PM","Dehumidifier":"33.7697","Pump #2":"11.3344"},
{ "name:":"10/13/21 09:02:51  PM","Dehumidifier":"34.0536","Pump #2":"11.3100"},
{ "name:":"10/13/21 09:03:51  PM","Dehumidifier":"34.4152","Pump #2":"11.3710"},
{ "name:":"10/13/21 09:04:51  PM","Dehumidifier":"33.9651","Pump #2":"11.2047"},
{ "name:":"10/13/21 09:05:51  PM","Dehumidifier":"34.0261","Pump #2":"11.5053"},
{ "name:":"10/13/21 09:06:51  PM","Dehumidifier":"34.0078","Pump #2":"11.5740"},
{ "name:":"10/13/21 09:07:51  PM","Dehumidifier":"33.2036","Pump #2":"11.2917"},
{ "name:":"10/13/21 09:08:51  PM","Dehumidifier":"32.4697","Pump #2":"11.6075"},
{ "name:":"10/13/21 09:09:51  PM","Dehumidifier":"32.0485","Pump #2":"11.6945"},
{ "name:":"10/13/21 09:10:51  PM","Dehumidifier":"32.0897","Pump #2":"11.1330"},
{ "name:":"10/13/21 09:11:51  PM","Dehumidifier":"32.1752","Pump #2":"11.1254"},
{ "name:":"10/13/21 09:12:51  PM","Dehumidifier":"32.1965","Pump #2":"10.8751"},
{ "name:":"10/13/21 09:13:51  PM","Dehumidifier":"32.0623","Pump #2":"11.1879"},
{ "name:":"10/13/21 09:14:51  PM","Dehumidifier":"32.0195","Pump #2":"11.2718"},
{ "name:":"10/13/21 09:15:51  PM","Dehumidifier":"31.8914","Pump #2":"11.9112"},
{ "name:":"10/13/21 09:16:51  PM","Dehumidifier":"31.7586","Pump #2":"11.3405"},
{ "name:":"10/13/21 09:17:51  PM","Dehumidifier":"31.8334","Pump #2":"11.5282"},
{ "name:":"10/13/21 09:18:51  PM","Dehumidifier":"31.8181","Pump #2":"11.5602"},
{ "name:":"10/13/21 09:19:51  PM","Dehumidifier":"31.4565","Pump #2":"11.2490"},
{ "name:":"10/13/21 09:20:51  PM","Dehumidifier":"31.5587","Pump #2":"11.1162"},
{ "name:":"10/13/21 09:21:51  PM","Dehumidifier":"33.0434","Pump #2":"11.4763"},
{ "name:":"10/13/21 09:22:51  PM","Dehumidifier":"34.3267","Pump #2":"10.9407"},
{ "name:":"10/13/21 09:23:51  PM","Dehumidifier":"34.3053","Pump #2":"11.1208"},
{ "name:":"10/13/21 09:24:51  PM","Dehumidifier":"34.0322","Pump #2":"11.4580"},
{ "name:":"10/13/21 09:25:51  PM","Dehumidifier":"33.6843","Pump #2":"11.1711"},
{ "name:":"10/13/21 09:26:51  PM","Dehumidifier":"33.6034","Pump #2":"11.0170"},
{ "name:":"10/13/21 09:27:51  PM","Dehumidifier":"32.3995","Pump #2":"11.3298"},
{ "name:":"10/13/21 09:28:51  PM","Dehumidifier":"31.8288","Pump #2":"10.9773"},
{ "name:":"10/13/21 09:29:51  PM","Dehumidifier":"31.8227","Pump #2":"11.0750"},
{ "name:":"10/13/21 09:30:51  PM","Dehumidifier":"31.8486","Pump #2":"11.3298"},
{ "name:":"10/13/21 09:31:51  PM","Dehumidifier":"31.8120","Pump #2":"11.3207"},
{ "name:":"10/13/21 09:32:51  PM","Dehumidifier":"31.8425","Pump #2":"11.1971"},
{ "name:":"10/13/21 09:33:51  PM","Dehumidifier":"31.8288","Pump #2":"11.3771"},
{ "name:":"10/13/21 09:34:51  PM","Dehumidifier":"31.6655","Pump #2":"10.7347"},
{ "name:":"10/13/21 09:35:51  PM","Dehumidifier":"31.5419","Pump #2":"10.9453"},
{ "name:":"10/13/21 09:36:51  PM","Dehumidifier":"31.5068","Pump #2":"11.2215"},
{ "name:":"10/13/21 09:37:51  PM","Dehumidifier":"31.4702","Pump #2":"11.2367"},
{ "name:":"10/13/21 09:38:51  PM","Dehumidifier":"31.3970","Pump #2":"10.9514"},
{ "name:":"10/13/21 09:39:51  PM","Dehumidifier":"31.3741","Pump #2":"11.2688"},
{ "name:":"10/13/21 09:40:51  PM","Dehumidifier":"31.4595","Pump #2":"11.2642"},
{ "name:":"10/13/21 09:41:51  PM","Dehumidifier":"31.3756","Pump #2":"11.0857"},
{ "name:":"10/13/21 09:42:51  PM","Dehumidifier":"31.4504","Pump #2":"11.0857"},
{ "name:":"10/13/21 09:43:51  PM","Dehumidifier":"31.4000","Pump #2":"11.4412"},
{ "name:":"10/13/21 09:44:51  PM","Dehumidifier":"31.3954","Pump #2":"11.1009"},
{ "name:":"10/13/21 09:45:51  PM","Dehumidifier":"7.9026","Pump #2":"10.9926"},
{ "name:":"10/13/21 09:46:51  PM","Dehumidifier":"7.9683","Pump #2":"11.1009"},
{ "name:":"10/13/21 09:47:51  PM","Dehumidifier":"7.9683","Pump #2":"10.8080"},
{ "name:":"10/13/21 09:48:51  PM","Dehumidifier":"7.9500","Pump #2":"10.9071"},
{ "name:":"10/13/21 09:49:51  PM","Dehumidifier":"7.9347","Pump #2":"10.9850"},
{ "name:":"10/13/21 09:50:51  PM","Dehumidifier":"7.9545","Pump #2":"10.9026"},
{ "name:":"10/13/21 09:51:51  PM","Dehumidifier":"7.9347","Pump #2":"11.1299"},
{ "name:":"10/13/21 09:52:51  PM","Dehumidifier":"7.9408","Pump #2":"11.5099"},
{ "name:":"10/13/21 09:53:51  PM","Dehumidifier":"7.9500","Pump #2":"11.4061"},
{ "name:":"10/13/21 09:54:51  PM","Dehumidifier":"7.9332","Pump #2":"11.4748"},
{ "name:":"10/13/21 09:55:51  PM","Dehumidifier":"7.9149","Pump #2":"11.3527"},
{ "name:":"10/13/21 09:56:51  PM","Dehumidifier":"7.9057","Pump #2":"11.1940"},
{ "name:":"10/13/21 09:57:51  PM","Dehumidifier":"7.9438","Pump #2":"10.8568"},
{ "name:":"10/13/21 09:58:51  PM","Dehumidifier":"7.9026","Pump #2":"11.3619"},
{ "name:":"10/13/21 09:59:51  PM","Dehumidifier":"7.8950","Pump #2":"11.2490"},
{ "name:":"10/13/21 10:00:51  PM","Dehumidifier":"7.9072","Pump #2":"11.3680"},
{ "name:":"10/13/21 10:01:51  PM","Dehumidifier":"7.9301","Pump #2":"11.0353"},
{ "name:":"10/13/21 10:02:51  PM","Dehumidifier":"7.8965","Pump #2":"10.9331"},
{ "name:":"10/13/21 10:03:51  PM","Dehumidifier":"7.8813","Pump #2":"10.9682"},
{ "name:":"10/13/21 10:04:51  PM","Dehumidifier":"7.8981","Pump #2":"11.2856"},
{ "name:":"10/13/21 10:05:51  PM","Dehumidifier":"7.8523","Pump #2":"11.4092"},
{ "name:":"10/13/21 10:06:51  PM","Dehumidifier":"7.8981","Pump #2":"11.0704"},
{ "name:":"10/13/21 10:07:51  PM","Dehumidifier":"7.8569","Pump #2":"11.3024"},
{ "name:":"10/13/21 10:08:51  PM","Dehumidifier":"7.8370","Pump #2":"11.2123"},
{ "name:":"10/13/21 10:09:51  PM","Dehumidifier":"7.8584","Pump #2":"10.9789"},
{ "name:":"10/13/21 10:10:51  PM","Dehumidifier":"7.9133","Pump #2":"11.0155"},
{ "name:":"10/13/21 10:11:51  PM","Dehumidifier":"7.8798","Pump #2":"11.1955"},
{ "name:":"10/13/21 10:12:51  PM","Dehumidifier":"7.8798","Pump #2":"10.8644"},
{ "name:":"10/13/21 10:13:51  PM","Dehumidifier":"7.8737","Pump #2":"11.2459"},
{ "name:":"10/13/21 10:14:51  PM","Dehumidifier":"7.9026","Pump #2":"11.5862"},
{ "name:":"10/13/21 10:15:51  PM","Dehumidifier":"7.8965","Pump #2":"11.0399"},
{ "name:":"10/13/21 10:16:51  PM","Dehumidifier":"7.8538","Pump #2":"10.8202"},
{ "name:":"10/13/21 10:17:51  PM","Dehumidifier":"7.8889","Pump #2":"11.1864"},
{ "name:":"10/13/21 10:18:51  PM","Dehumidifier":"7.9271","Pump #2":"10.6432"},
{ "name:":"10/13/21 10:19:51  PM","Dehumidifier":"7.8859","Pump #2":"11.3939"},
{ "name:":"10/13/21 10:20:51  PM","Dehumidifier":"7.8767","Pump #2":"10.9483"},
{ "name:":"10/13/21 10:21:51  PM","Dehumidifier":"7.8920","Pump #2":"11.0506"},
{ "name:":"10/13/21 10:22:51  PM","Dehumidifier":"7.8553","Pump #2":"11.0506"},
{ "name:":"10/13/21 10:23:51  PM","Dehumidifier":"7.8996","Pump #2":"11.1986"},
{ "name:":"10/13/21 10:24:51  PM","Dehumidifier":"7.8767","Pump #2":"11.2215"},
{ "name:":"10/13/21 10:25:51  PM","Dehumidifier":"7.9103","Pump #2":"11.1727"},
{ "name:":"10/13/21 10:26:51  PM","Dehumidifier":"7.8767","Pump #2":"11.2810"},
{ "name:":"10/13/21 10:27:51  PM","Dehumidifier":"7.8981","Pump #2":"10.8125"},
{ "name:":"10/13/21 10:28:51  PM","Dehumidifier":"7.8874","Pump #2":"11.3527"},
{ "name:":"10/13/21 10:29:51  PM","Dehumidifier":"7.8782","Pump #2":"11.0140"},
{ "name:":"10/13/21 10:30:51  PM","Dehumidifier":"7.8874","Pump #2":"11.1040"},
{ "name:":"10/13/21 10:31:51  PM","Dehumidifier":"7.9011","Pump #2":"11.4061"},
{ "name:":"10/13/21 10:32:51  PM","Dehumidifier":"7.8782","Pump #2":"11.2657"},
{ "name:":"10/13/21 10:33:51  PM","Dehumidifier":"7.8645","Pump #2":"11.3222"},
{ "name:":"10/13/21 10:34:51  PM","Dehumidifier":"7.8828","Pump #2":"11.4061"},
{ "name:":"10/13/21 10:35:51  PM","Dehumidifier":"7.8935","Pump #2":"11.2322"},
{ "name:":"10/13/21 10:36:51  PM","Dehumidifier":"7.8676","Pump #2":"11.4748"},
{ "name:":"10/13/21 10:37:51  PM","Dehumidifier":"7.8889","Pump #2":"11.4961"},
{ "name:":"10/13/21 10:38:51  PM","Dehumidifier":"7.8447","Pump #2":"11.5724"},
{ "name:":"10/13/21 10:39:51  PM","Dehumidifier":"7.8843","Pump #2":"11.6258"},
{ "name:":"10/13/21 10:40:51  PM","Dehumidifier":"7.8599","Pump #2":"11.2139"},
{ "name:":"10/13/21 10:41:51  PM","Dehumidifier":"7.8660","Pump #2":"11.7861"},
{ "name:":"10/13/21 10:42:51  PM","Dehumidifier":"7.8798","Pump #2":"11.4000"},
{ "name:":"10/13/21 10:43:51  PM","Dehumidifier":"7.8676","Pump #2":"11.2490"},
{ "name:":"10/13/21 10:44:51  PM","Dehumidifier":"7.8935","Pump #2":"11.1727"},
{ "name:":"10/13/21 10:45:51  PM","Dehumidifier":"7.8721","Pump #2":"10.9651"},
{ "name:":"10/13/21 10:46:51  PM","Dehumidifier":"7.8981","Pump #2":"11.6854"},
{ "name:":"10/13/21 10:47:51  PM","Dehumidifier":"7.9088","Pump #2":"11.5389"},
{ "name:":"10/13/21 10:48:51  PM","Dehumidifier":"7.9210","Pump #2":"11.1940"},
{ "name:":"10/13/21 10:49:51  PM","Dehumidifier":"7.9042","Pump #2":"10.9361"},
{ "name:":"10/13/21 10:50:51  PM","Dehumidifier":"7.8950","Pump #2":"11.0765"},
{ "name:":"10/13/21 10:51:51  PM","Dehumidifier":"7.8935","Pump #2":"10.0298"},
{ "name:":"10/13/21 10:52:51  PM","Dehumidifier":"7.8828","Pump #2":"10.6874"},
{ "name:":"10/13/21 10:53:51  PM","Dehumidifier":"7.9164","Pump #2":"11.3573"},
{ "name:":"10/13/21 10:54:51  PM","Dehumidifier":"7.9301","Pump #2":"11.2978"},
{ "name:":"10/13/21 10:55:51  PM","Dehumidifier":"7.9484","Pump #2":"11.2016"},
{ "name:":"10/13/21 10:56:51  PM","Dehumidifier":"7.9072","Pump #2":"11.1742"},
{ "name:":"10/13/21 10:57:51  PM","Dehumidifier":"7.8691","Pump #2":"11.2840"},
{ "name:":"10/13/21 10:58:51  PM","Dehumidifier":"7.9118","Pump #2":"11.0536"},
{ "name:":"10/13/21 10:59:51  PM","Dehumidifier":"7.8798","Pump #2":"11.7830"},
{ "name:":"10/13/21 11:00:51  PM","Dehumidifier":"7.8752","Pump #2":"10.9911"},
{ "name:":"10/13/21 11:01:51  PM","Dehumidifier":"7.9210","Pump #2":"11.4015"},
{ "name:":"10/13/21 11:02:51  PM","Dehumidifier":"7.8859","Pump #2":"11.1696"},
{ "name:":"10/13/21 11:03:51  PM","Dehumidifier":"7.8462","Pump #2":"11.3756"},
{ "name:":"10/13/21 11:04:51  PM","Dehumidifier":"7.8798","Pump #2":"11.2840"},
{ "name:":"10/13/21 11:05:51  PM","Dehumidifier":"7.8553","Pump #2":"11.3863"},
{ "name:":"10/13/21 11:06:51  PM","Dehumidifier":"7.8706","Pump #2":"11.6838"},
{ "name:":"10/13/21 11:07:51  PM","Dehumidifier":"7.8752","Pump #2":"11.2840"},
{ "name:":"10/13/21 11:08:51  PM","Dehumidifier":"7.8630","Pump #2":"11.5206"},
{ "name:":"10/13/21 11:09:51  PM","Dehumidifier":"7.8889","Pump #2":"11.5373"},
{ "name:":"10/13/21 11:10:51  PM","Dehumidifier":"7.8889","Pump #2":"11.0872"},
{ "name:":"10/13/21 11:11:51  PM","Dehumidifier":"7.9240","Pump #2":"11.0826"},
{ "name:":"10/13/21 11:12:51  PM","Dehumidifier":"7.8813","Pump #2":"11.3512"},
{ "name:":"10/13/21 11:13:51  PM","Dehumidifier":"7.9286","Pump #2":"11.3725"},
{ "name:":"10/13/21 11:14:51  PM","Dehumidifier":"7.9271","Pump #2":"11.2917"},
{ "name:":"10/13/21 11:15:51  PM","Dehumidifier":"7.8935","Pump #2":"11.3634"},
{ "name:":"10/13/21 11:16:51  PM","Dehumidifier":"7.8813","Pump #2":"11.2627"},
{ "name:":"10/13/21 11:17:51  PM","Dehumidifier":"7.8676","Pump #2":"11.7037"},
{ "name:":"10/13/21 11:18:51  PM","Dehumidifier":"7.8523","Pump #2":"11.3527"},
{ "name:":"10/13/21 11:19:51  PM","Dehumidifier":"7.8904","Pump #2":"11.1620"},
{ "name:":"10/13/21 11:20:51  PM","Dehumidifier":"7.8447","Pump #2":"11.3970"},
{ "name:":"10/13/21 11:21:51  PM","Dehumidifier":"7.8813","Pump #2":"11.4092"},
{ "name:":"10/13/21 11:22:51  PM","Dehumidifier":"7.8874","Pump #2":"11.0689"},
{ "name:":"10/13/21 11:23:51  PM","Dehumidifier":"7.9103","Pump #2":"11.1803"},
{ "name:":"10/13/21 11:24:51  PM","Dehumidifier":"7.8889","Pump #2":"10.8736"},
{ "name:":"10/13/21 11:25:51  PM","Dehumidifier":"7.8813","Pump #2":"11.0430"},
{ "name:":"10/13/21 11:26:51  PM","Dehumidifier":"7.8660","Pump #2":"11.0185"},
{ "name:":"10/13/21 11:27:51  PM","Dehumidifier":"7.8981","Pump #2":"11.3039"},
{ "name:":"10/13/21 11:28:51  PM","Dehumidifier":"7.9301","Pump #2":"10.9529"},
{ "name:":"10/13/21 11:29:51  PM","Dehumidifier":"7.9042","Pump #2":"11.2001"},
{ "name:":"10/13/21 11:30:51  PM","Dehumidifier":"7.9011","Pump #2":"11.2612"},
{ "name:":"10/13/21 11:31:51  PM","Dehumidifier":"7.8965","Pump #2":"11.4778"},
{ "name:":"10/13/21 11:32:51  PM","Dehumidifier":"7.8599","Pump #2":"11.3664"},
{ "name:":"10/13/21 11:33:51  PM","Dehumidifier":"7.8996","Pump #2":"11.1604"},
{ "name:":"10/13/21 11:34:51  PM","Dehumidifier":"7.8965","Pump #2":"11.3054"},
{ "name:":"10/13/21 11:35:51  PM","Dehumidifier":"7.8767","Pump #2":"10.6935"},
{ "name:":"10/13/21 11:36:51  PM","Dehumidifier":"7.8920","Pump #2":"11.4290"},
{ "name:":"10/13/21 11:37:51  PM","Dehumidifier":"7.8737","Pump #2":"11.3115"},
{ "name:":"10/13/21 11:38:51  PM","Dehumidifier":"7.8782","Pump #2":"11.2276"},
{ "name:":"10/13/21 11:39:51  PM","Dehumidifier":"7.8813","Pump #2":"11.1269"},
{ "name:":"10/13/21 11:40:51  PM","Dehumidifier":"7.8294","Pump #2":"11.5801"},
{ "name:":"10/13/21 11:41:51  PM","Dehumidifier":"7.9057","Pump #2":"11.3085"},
{ "name:":"10/13/21 11:42:51  PM","Dehumidifier":"7.8599","Pump #2":"11.2230"},
{ "name:":"10/13/21 11:43:51  PM","Dehumidifier":"7.8965","Pump #2":"11.0323"},
{ "name:":"10/13/21 11:44:51  PM","Dehumidifier":"7.8508","Pump #2":"11.2718"},
{ "name:":"10/13/21 11:45:51  PM","Dehumidifier":"7.8538","Pump #2":"10.9438"},
{ "name:":"10/13/21 11:46:51  PM","Dehumidifier":"7.9042","Pump #2":"10.9392"},
{ "name:":"10/13/21 11:47:51  PM","Dehumidifier":"7.8477","Pump #2":"10.8019"},
{ "name:":"10/13/21 11:48:51  PM","Dehumidifier":"7.8630","Pump #2":"11.1009"},
{ "name:":"10/13/21 11:49:51  PM","Dehumidifier":"7.8401","Pump #2":"11.0445"},
{ "name:":"10/13/21 11:50:51  PM","Dehumidifier":"7.8874","Pump #2":"11.0338"},
{ "name:":"10/13/21 11:51:51  PM","Dehumidifier":"7.8447","Pump #2":"10.7683"},
{ "name:":"10/13/21 11:52:51  PM","Dehumidifier":"7.8462","Pump #2":"10.9850"},
{ "name:":"10/13/21 11:53:51  PM","Dehumidifier":"7.8950","Pump #2":"10.5821"},
{ "name:":"10/13/21 11:54:51  PM","Dehumidifier":"7.8767","Pump #2":"10.7073"},
{ "name:":"10/13/21 11:55:51  PM","Dehumidifier":"7.8767","Pump #2":"10.7637"},
{ "name:":"10/13/21 11:56:51  PM","Dehumidifier":"7.8630","Pump #2":"10.5959"},
{ "name:":"10/13/21 11:57:51  PM","Dehumidifier":"7.8264","Pump #2":"10.3853"},
{ "name:":"10/13/21 11:58:51  PM","Dehumidifier":"7.8752","Pump #2":"10.0404"},
{ "name:":"10/13/21 11:59:51  PM","Dehumidifier":"7.9057","Pump #2":"10.9026"},
{ "name:":"10/14/21 12:00:51  AM","Dehumidifier":"7.8676","Pump #2":"11.0765"},
{ "name:":"10/14/21 12:01:51  AM","Dehumidifier":"7.8553","Pump #2":"11.1467"},
{ "name:":"10/14/21 12:02:51  AM","Dehumidifier":"7.8370","Pump #2":"11.2200"},
{ "name:":"10/14/21 12:03:51  AM","Dehumidifier":"7.8645","Pump #2":"10.6218"},
{ "name:":"10/14/21 12:04:51  AM","Dehumidifier":"7.9072","Pump #2":"11.5908"},
{ "name:":"10/14/21 12:05:51  AM","Dehumidifier":"7.8523","Pump #2":"11.0948"},
{ "name:":"10/14/21 12:06:51  AM","Dehumidifier":"7.8904","Pump #2":"11.0872"},
{ "name:":"10/14/21 12:07:51  AM","Dehumidifier":"7.8874","Pump #2":"10.8141"},
{ "name:":"10/14/21 12:08:51  AM","Dehumidifier":"7.9225","Pump #2":"11.0964"},
{ "name:":"10/14/21 12:09:51  AM","Dehumidifier":"7.8935","Pump #2":"11.2779"},
{ "name:":"10/14/21 12:10:51  AM","Dehumidifier":"7.9438","Pump #2":"10.8797"},
{ "name:":"10/14/21 12:11:51  AM","Dehumidifier":"7.9042","Pump #2":"11.1620"},
{ "name:":"10/14/21 12:12:51  AM","Dehumidifier":"7.9057","Pump #2":"10.6203"},
{ "name:":"10/14/21 12:13:51  AM","Dehumidifier":"7.8889","Pump #2":"11.1635"},
{ "name:":"10/14/21 12:14:51  AM","Dehumidifier":"7.9103","Pump #2":"11.1452"},
{ "name:":"10/14/21 12:15:51  AM","Dehumidifier":"7.8965","Pump #2":"10.9773"},
{ "name:":"10/14/21 12:16:51  AM","Dehumidifier":"7.9301","Pump #2":"11.4290"},
{ "name:":"10/14/21 12:17:51  AM","Dehumidifier":"7.8935","Pump #2":"11.5251"},
{ "name:":"10/14/21 12:18:51  AM","Dehumidifier":"7.9194","Pump #2":"11.3390"},
{ "name:":"10/14/21 12:19:51  AM","Dehumidifier":"7.9103","Pump #2":"10.9987"},
{ "name:":"10/14/21 12:20:51  AM","Dehumidifier":"7.9164","Pump #2":"10.9758"},
{ "name:":"10/14/21 12:21:51  AM","Dehumidifier":"7.8920","Pump #2":"11.0338"},
{ "name:":"10/14/21 12:22:51  AM","Dehumidifier":"7.8965","Pump #2":"10.6798"},
{ "name:":"10/14/21 12:23:51  AM","Dehumidifier":"7.9103","Pump #2":"11.0628"},
{ "name:":"10/14/21 12:24:51  AM","Dehumidifier":"7.8904","Pump #2":"10.8904"},
{ "name:":"10/14/21 12:25:51  AM","Dehumidifier":"7.9301","Pump #2":"10.4692"},
{ "name:":"10/14/21 12:26:51  AM","Dehumidifier":"7.8889","Pump #2":"11.0658"},
{ "name:":"10/14/21 12:27:51  AM","Dehumidifier":"7.8981","Pump #2":"11.0552"},
{ "name:":"10/14/21 12:28:51  AM","Dehumidifier":"7.9408","Pump #2":"11.1009"},
{ "name:":"10/14/21 12:29:51  AM","Dehumidifier":"7.8996","Pump #2":"11.1574"},
{ "name:":"10/14/21 12:30:51  AM","Dehumidifier":"7.8965","Pump #2":"10.9178"},
{ "name:":"10/14/21 12:31:51  AM","Dehumidifier":"7.8813","Pump #2":"11.1971"},
{ "name:":"10/14/21 12:32:51  AM","Dehumidifier":"7.8676","Pump #2":"10.8919"},
{ "name:":"10/14/21 12:33:51  AM","Dehumidifier":"7.8660","Pump #2":"10.7683"},
{ "name:":"10/14/21 12:34:51  AM","Dehumidifier":"7.9179","Pump #2":"10.5272"},
];

module.exports = {
    data
}