const clientSectors = [
    'Arena/park',
    'Automotive/aerospace',
    'Bakery',
    'Brewery',
    'Commercial',
    'Dairy',
    'Grocery',
    'Farm/food processing',
    'Hospital',
    'Hotel',
    'Institution',
    'Manufacturing',
    'Meat/seafood processing',
    'Pharmaceuticals',
    'Property management',
    'Restaurant',
    'Winery',
    'Other'
];

export default clientSectors;