import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const InventoryDrawer = props => {
    const updateQueryResults = value => {
        let temp = {...props.equipmentItems};
        let queryText = value.trim();
        let adjQuery = queryText.toLowerCase();

        for(var key in temp){
            if(!temp[key].cost_type.includes(props.drawerState)) continue;

            temp[key].visible = key.toLowerCase().includes(adjQuery);
        }

        props.setEquipmentItems(temp);
        props.setInventorySearch(queryText);
    }

    const addSelectedItems = () => {
        let temp = {...props.equipmentItems};

        for(var key in temp){
            if(!temp[key].cost_type.includes(props.drawerState)) continue;

            if(temp[key].pendingSelect){
                temp[key].pendingSelect = false;
                temp[key].selected = true;
                temp[key].quantity = 1;
            }

            temp[key].visible = true;
        }
        
        props.setEquipmentItems(temp);
        props.setInventorySearch('');
        props.setDrawerState('');
    }

    return (
        <Box sx={{ width: 'fit-content', padding:'1vh 1vw' }} role="presentation">
            <TextField 
                label="Search" 
                variant="standard" 
                fullWidth
                value={props.inventorySearch}
                onChange={e => updateQueryResults(e.target.value)}
                autoComplete="off"
            />
            <Divider />
            <List>
                {
                    Object.values(props.equipmentItems).filter(item => item.cost_type.includes(props.drawerState)).some(item => item.pendingSelect) && 
                    <Button style={{backgroundColor:'rgba(8,114,182, 0.2)', width:'100%'}} onClick={() => {
                        addSelectedItems();
                    }}>
                        <strong>Add selected items</strong>
                    </Button>
                }

                {Object.keys(props.equipmentItems).map((item, index) => {
                    if(props.equipmentItems[item].cost_type.includes(props.drawerState) && props.equipmentItems[item].visible) return(
                        <ListItem key={index} disablePadding>
                            <ListItemButton 
                                disabled={props.equipmentItems[item].selected}
                                onClick={() => {
                                    let currState = props.equipmentItems[item].pendingSelect;

                                    props.setEquipmentItems(prev => ({
                                        ...prev,
                                        [`${item}`]: {
                                            ...prev[`${item}`],
                                            pendingSelect: !currState
                                        }
                                    }));
                                }}
                            >
                                <FormControlLabel control={<Checkbox checked={props.equipmentItems[item].pendingSelect || props.equipmentItems[item].selected} style={{padding:'0'}}/>} />
                                <ListItemText primary={`${item}${props.equipmentItems[item].selected ? ' (selected)' : ''}`} secondary={`${props.equipmentItems[item].supplier} | $${(Math.round(parseFloat(props.equipmentItems[item].msrp_cad) * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} unit MSRP`}/>
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>
        </Box>
    );
}

export default InventoryDrawer;