import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const ParameterTable = props => {
    const columns = [
        {
            id: 'parameter',
            label: 'Parameter',
            minWidth: 'fit-content',
            align: 'left'
        },
        {
            id: 'value',
            label: 'Value',
            minWidth: 'fit-content',
            align: 'center'
        },
        {
            id: 'units',
            label: 'Units',
            minWidth: 'fit-content',
            align: 'center'
        },
        {
            id: 'date_updated',
            label: 'Last updated',
            minWidth: 'fit-content',
            align: 'right'
        },
    ];

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                        {columns.map((column) => (
                            <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                            >
                                <strong>{column.label}</strong>
                            </TableCell>
                        ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.parameters.map((param, i) => (
                            <TableRow key={param.id}>
                                <TableCell align='left'>{param.screen_name}</TableCell>
                                <TableCell align='center'>{param.last_value == null ? '-' : param.last_value}</TableCell>
                                <TableCell align='center'>{param.units === 'degc' ? <span>&deg;C</span> : param.units}</TableCell>
                                <TableCell align='right'>{props.formatDate(param.last_value_time)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

export default ParameterTable;