const waterUnitTypes = [

];

const electricUnitTypes = [
    {value:"ahu", text:"Air handling unit"},
    {value:"amu", text:"Air makeup unit"},
    {value:"battery", text:"Battery"},
    {value:"aircomp", text:"Compressor - air"},
    {value:"nh3comp", text:"Compressor - ammonia"},
    {value:"co2comp", text:"Compressor - CO2"},
    {value:"refcomp", text:"Compressor - misc"},
    {value:"condenser", text:"Condenser"},
    {value:"dehumidifier", text:"Dehumidifier"},
    {value:"evaporator", text:"Evaporator"},
    {value:"fan", text:"Fan"},
    {value:"heating_elem", text:"Heating element"},
    {value:"lighting", text:"Lighting"},
    {value:"main", text:"Main"},
    {value:"motor", text:"Motor"},
    {value:"pump", text:"Pump"},
    {value:"rtu", text:"RTU"},
];

const gasUnitTypes = [

];

module.exports = {
    waterUnitTypes,
    electricUnitTypes,
    gasUnitTypes
}