import React, {useEffect, useState} from 'react';
import { Divider, TextField } from '@mui/material';
import OrgCard from '../dashboard_components/orgs/OrgCard';
import AddCardIcon from '@mui/icons-material/AddCard';
import OrgModal from '../dashboard_components/orgs/OrgModal';
import useAxiosProtect from '../../../hooks/useAxiosProtect';

const StaffDashContainer = props => {
    const axiosProtect = useAxiosProtect();
    
    //state vars
    const [orgs, setOrgs] = useState([]);
    const [orgModalState, setOrgModalState] = useState(undefined)

    // modal - delete client
    const [delClient, setDelClient] = useState('');

    const addClientCard = client => {
        setOrgs([...orgs, client])
    }

    const removeClientCard = name => {
        let tempArray = [...orgs];

        for(let i = 0;i<tempArray.length;++i){
            if(tempArray[i].name === name){
                tempArray.splice(i, 1);
                break;
            }
        }

        setOrgs(tempArray)
    }

    useEffect(() => {
       const controller = new AbortController();

       const getOrgs = async () => {
            try{
                const response = await axiosProtect.get('/auth/org/list', {
                    signal:  controller.signal
                });

                setOrgs([...response.data.orgs])
            }catch(err) {
                console.log(err)
            }
       }

       getOrgs();

    }, []);

    const toggleModalState = newState => {
        setOrgModalState(newState)
    }

    const updateDelClient = (name, id) => {
        setDelClient({
            name,
            id
        })
    }

    return(
        <div style={{padding:'2%', backgroundColor:'#f5f5f5', minHeight:'95vh'}}>

            <OrgModal orgModalState={orgModalState} toggleModalState={toggleModalState} delClient={delClient} addClientCard={addClientCard} removeClientCard={removeClientCard}/>

            <div id='es-sites'>
                <div style={{width:'100%', display:'flex', flexDirection:'row'}}>
                    <div style={{flex:'1', display:'flex', flexDirection:'row'}}>
                        <h4>Client Manager</h4>
                        <div style={{marginLeft:'1vw'}}></div>
                        <AddCardIcon style={{marginTop:'0.9%', color:'rgb(78,79,83)', cursor:'pointer'}} onClick={() => {
                            toggleModalState('add');
                        }}/>
                    </div>

                    <div style={{flex:'3'}}></div>

                    <div style={{flex:'1'}}>
                        {/* client search bar */}
                    </div>

                    <div style={{flex:'0.12'}}></div>
                </div>

                <Divider style={{margin:'0.5vh 0 1vh 0'}}/>
                
                <div style={{display:'flex', flexWrap:'wrap'}}>
                    {orgs.map((_, i) => (
                        <OrgCard key={orgs[i]._id} name={orgs[i].name} id={orgs[i]._id}
                        hq_city={orgs[i].hq_city} hq_state={orgs[i].hq_state} hq_country={orgs[i].hq_country}
                        industry={orgs[i].industry} sites={orgs[i].sites} toggleModalState={toggleModalState} updateDelClient={updateDelClient}/>
                    ))}
                </div>
            </div>

            <br/>
        </div>
    )
}

export default StaffDashContainer;