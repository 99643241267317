import React, {useEffect, useRef, useState} from 'react';
import { Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, ComposedChart, Line } from 'recharts';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Skeleton } from '@mui/material';
import useAxiosProtect from '../../../../hooks/useAxiosProtect';

const DashGraph = props => {
    const [range, setRange] = useState('day');
    const [resolution, setResolution] = useState('hour');
    const [isLoading, setIsLoading] = useState(true);
    
    const axiosProtect = useAxiosProtect();
    var init = useRef(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        if(init.current){
            init.current = false;

            updateGraph(resolution, range);
        }
    }, []);

    const handleRange = (event, newRange) => {
        if(!newRange) return;

        setIsLoading(true);

        let newRes = newRange === 'day' ? 'hour' : 'day';

        setResolution(newRes);
        setRange(newRange);

        updateGraph(newRes, newRange);
    }

    const handleResolution = (event, newResolution) => {
        if(!newResolution) return;

        setIsLoading(true);

        setResolution(newResolution);
        updateGraph(newResolution, range);
    }

    const updateGraph = async (resolution, range) => {
        let siteID = window.location.href.split('/')[window.location.href.split('/').length - 1];

        try{
            let response = await axiosProtect.get(`/c/client/barcharts?siteID=${siteID}&range=${range}&resolution=${resolution}&utility=${props.utility}`);

            setData(response.data.data)
        }catch(err){
            setData(null)
        }
        
        setIsLoading(false)
    }

    const formatYAxisTick = tick => {
        if (Math.abs(tick) >= 1e6) return (tick / 1e6).toFixed(1) + 'M'; 
        else if (Math.abs(tick) >= 1e5) return (tick / 1e3).toFixed(0) + 'k';
        else return tick.toLocaleString();
    }

    const formatReadings = reading => {
        return (Math.round(reading * 100) / 100).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const formatXAxisTick = (tick) => {
        const date = new Date(tick);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;
        const month = date.toLocaleString('default', { month: 'numeric' });
        const day = date.getDate();
    
        return `${formattedHours}:${minutes.toString().padStart(2, '0')} ${ampm} ${month}/${day}`;
    };

    return(
        <>
        {!isLoading ? 
        (data != null ? <div style={{width:'100%', height:'100%'}}>
            <div style={{height:'25%'}}>
                <h4>{props.title}</h4>

                <div style={{width:'40%', height:'40%', margin:'1% auto'}}>

                    <div style={{display:'inline-block'}}>
                        <ToggleButtonGroup
                            value={range}
                            exclusive
                            onChange={handleRange}
                            aria-label="text alignment"
                            size='small'
                        >
                            <ToggleButton value="day" aria-label="left aligned">
                                <p style={{margin:'0'}}>Day</p>
                            </ToggleButton>
                            <ToggleButton value="week" aria-label="centered">
                                <p style={{margin:'0'}}>Week</p>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>

                    <div style={{float:'right'}}>
                        <ToggleButtonGroup
                            value={resolution}
                            exclusive
                            onChange={handleResolution}
                            aria-label="text alignment"
                            size='small'
                        >
                            <ToggleButton value={range === 'day' ? 'min' : 'hour'} aria-label="left aligned">
                                <p style={{margin:'0'}}>{range === 'day' ? 'min' : 'hour'}</p>
                            </ToggleButton>
                            <ToggleButton value={range === 'day' ? 'hour' : 'day'} aria-label="centered">
                                <p style={{margin:'0'}}>{range === 'day' ? 'hour' : 'day'}</p>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </div>
            </div>

            <ResponsiveContainer height='78%' width='100%'>
                <ComposedChart data={data}>
                    <XAxis dataKey="time" tickLine={false} axisLine={false} tick={{ fontSize: '14px', fill: 'rgb(78,79,83)'}} 
                        tickFormatter={formatXAxisTick} interval={Math.ceil(data.length / 7)} textAnchor='start'/>

                    <YAxis yAxisId="left" tickLine={false} axisLine={false} tickFormatter={formatYAxisTick} tick={{ fontSize: '12px', fill: 'rgb(78,79,83)'}}
                    label={{value: `${props.title.split(' ')[0]} usage (${props.title.split(' ')[0] === 'Water' ? 'L' : 'kWh'})`, position: 'outside', offset: 0, angle: -90, dx: -30, style: { fontSize: '12px', fill: 'rgb(78,79,83)'}}}/>

                    <YAxis yAxisId="right" orientation='right' tickLine={false} axisLine={false} tickFormatter={formatYAxisTick} tick={{ fontSize: '12px', fill: 'rgb(78,79,83)'}}
                    label={{value: `Total usage (${props.title.split(' ')[0] === 'Water' ? 'L' : 'kWh'})`,position: 'outside', offset: 0, angle: 90, dx: 30, style: { fontSize: '12px', fill: 'rgb(78,79,83)' }}}/>
                    
                    <Tooltip 
                        contentStyle={{
                            backgroundColor: '#F5F5F5',
                            color: '#333',
                            border:'1px solid lightgrey',
                            fontSize: '0.75em',
                        }}
                        labelFormatter={formatXAxisTick}
                        wrapperStyle={{ outline: "none" }}
                        formatter={formatReadings}
                    />

                    <CartesianGrid stroke="#f5f5f5" />
                    <Bar yAxisId="left" dataKey={`${props.utility === 'water' ? 'Litres' : 'kWh'}`} fill={props.primary} />
                    <Line yAxisId="right" type="monotone" dataKey="cumulative" stroke={props.secondary} dot={false}/>
                </ComposedChart>
            </ResponsiveContainer>
        </div> : 
        <>
        <h4>{props.title}</h4>
        <p>Not in scope</p>
        </>
        )
        :
        <div style={{height:'100%', width:'100%'}}>
            <Skeleton height='100%' variant='rounded'/>
        </div>
        }
        </>
    );
}

export default DashGraph;