import React from 'react';
import { PieChart, Pie, ResponsiveContainer, Cell, Label, Legend } from 'recharts';

const data = [
    {
      "name": "Water",
      "value": 0
    },
    {
      "name": "Electricity",
      "value": 8000
    },
    {
      "name": "Natural gas",
      "value": 6000
    },
  ];

const FundingBreakdownChart = props => {
    const COLORS = [
        'rgb(8,114,182)', 'rgb(0,177,168)', 'rgb(213,43,0)', 'rgb(247,230,83)', '#3a9efd', '#3e4491', '#292a73', '#1a1b4b',
        '#490a3d', '#bd1550', '#e97f02', '#f8ca00', '#8a9b0f','#99b898', '#fecea8', '#ff847c', '#e84a5f', '#2a363b',
        '#00a0b0', '#6a4a3c', '#cc333f', '#eb6841', '#edc951', '#ecd078', '#d95b43', '#c02942', '#542437', '#53777a',
        '#556270', '#4ecdc4', '#c7f464', '#ff6b6b', '#c44d58', '#343090', '#5f59f7', '#6592fd', '#44c2fd', '#8c61ff',
        '#072448', '#54d2d2', '#ffcb00', '#f8aa4b', '#ff6150', '#a2cdf2', '#0367a6', '#49b1f2', '#027373', '#7f8c1c'
    ];

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const RADIAN = Math.PI / 180;

        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
    
        return(
            <g>
                {percent >= 0.08 && <text x={x + 10} y={y-8} fill="white" textAnchor='middle' dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
                </text>}
            </g>
        );
    };

    return(
        <ResponsiveContainer height='85%' width='100%'>
           <PieChart width={props.pageWidth/2} height={props.pageWidth/2} style={{margin:'auto'}}>
                <Pie
                    data={data}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={props.pageWidth/6}
                    fill="#8884d8"
                    dataKey="value"
                >
                    {data.map((data, i) => (
                        <Cell key={`cell-${i}`} fill={data.name === 'Water' ? 'rgb(8,114,182)' : data.name === 'Electricity' ? 'rgb(213,43,0)' : data.name === 'Natural gas' ? 'rgb(0,177,168)' : COLORS[i % COLORS.length]} />
                    ))}
                    <Label position='outside'/>
                </Pie>
                <Legend layout="horizontal" verticalAlign="bottom" align="center" wrapperStyle={{fontSize: "0.85rem"}}/>
            </PieChart>
        </ResponsiveContainer>
    );
}

export default FundingBreakdownChart;