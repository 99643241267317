import React from 'react';
import GaugeComponent from 'react-gauge-component';

const PressureGauge = props => {
    return(
        <div style={{height:'100%', width:'100%', padding:'0.5%'}}>
            {/* <h4 style={{fontSize:'1.3rem'}}>Operating Pressure</h4> */}
            <GaugeComponent
                type="radial"
                labels={{
                    valueLabel: {
                        formatTextValue: value => value + ' psi',
                        style: {fontSize: "35px", fill: "rgb(78,79,83)"}
                    },
                    style: {color:'black'},
                    tickLabels: {
                        type: "outer",
                        ticks: [
                            { value: 0 },
                            { value: 10 },
                            { value: 20 },
                            { value: 30 },
                            { value: 40 },
                            { value: 50 },
                            { value: 60 },
                            { value: 70 },
                            { value: 80 },
                            { value: 90 },
                            { value: 100 },
                            { value: 110 },
                            { value: 120 },
                            { value: 130 },
                            { value: 140 },
                        ],
                        defaultTickLineConfig: {
                            style:{fill:'#000'}
                        },
                        defaultTickValueConfig: {
                            style:{fill:'#000'}
                        }
                    }
                }}
                arc={{
                    colorArray: ['#5BE12C','#EA4228'],
                    nbSubArcs:14,
                    padding: 0.05,
                    width: 0.2
                }}
                pointer={{
                    elastic: true,
                    animationDelay: 0
                }}
                value={115}
                minValue={0}
                maxValue={140}
            />
        </div>
    );
}

export default PressureGauge;