import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import useAuth from '../../../../../hooks/useAuth';
import useAxiosProtect from '../../../../../hooks/useAxiosProtect';
import { Typography } from '@mui/material';

const EventInfoModal = props => {
    const generateHeader = () => {
        if(props.eventData.category === 'leak') return 'Leak';
        else if(props.eventData.category === 'production') return 'Production Event';
        else if(props.eventData.category === 'malfunction') return 'Equipment Malfunction';
        else if(props.eventData.category === 'alert') return 'Sensor Alert';

        return 'Miscellaneous Event';
    }

    const {auth, persist} = useAuth();
    const axiosProtect = useAxiosProtect();
    
    return(
        <Modal
            show={props.eventInfoModalOpen}
            onHide={() => props.setEventInfoModalOpen(false)}
            backdrop="static"
            centered
        >
            <Modal.Header closeButton>
                <h4>{generateHeader()}</h4>
            </Modal.Header>

            <Modal.Body style={{overflow:'hidden'}}>
                <Typography variant='body'>
                    <strong>{props.eventData.title}</strong>
                </Typography>

                <br/>

                <Typography variant='body2'>
                    {`Event time: ${props.formatEventTime(props.eventData.event_time_utc)}`}
                </Typography>

                <br/>

                {
                    props.eventData.description !== '' &&
                    <>
                    <Typography variant='body'>
                        {props.eventData.description}
                    </Typography>

                    <br/>
                    <br/>    
                    </>
                }

                <Typography variant='body2'>
                    <span style={{color:'blue', textDecoration:'underline', cursor:'pointer'}} onClick={() => {
                        let eventDate = new Date(props.eventData.event_time_utc);

                        let start = new Date(eventDate);
                        start.setDate(eventDate.getDate() - 1);

                        let end = new Date(eventDate);
                        end.setDate(eventDate.getDate() + 1);

                        props.toolLoad.current = true;
                        props.setData([]);
                        props.setErrorMessage('');
                        
                        props.setPlotSpecs(prev => ({
                            ...prev,
                            ['resolution']: '15m',
                            ['timeframe']: 'custom',
                            ['customStart']: `${start.getDate()}-${start.getMonth()}-${start.getFullYear()}`,
                            ['customEnd']: `${end.getDate()}-${end.getMonth()}-${end.getFullYear()}`
                        }));
                    }}>Go to event</span>
                </Typography>

                <Typography variant='body'>
                    {`Event created by ${auth?.id === props.eventData.creator_uid ? 'you' : `${props.eventData.first_name} ${props.eventData.last_name}`}`}
                </Typography>
            </Modal.Body>

            {
                auth?.id === props.eventData.creator_uid && 
                <Modal.Footer>
                    <Button style={{border:'none', outline:'none', backgroundColor:'rgb(213,43,0)'}} onClick={() => {
                        axiosProtect.delete(`/s/sites/delete-event?eventID=${props.eventData.id}`).then(response => {
                            props.removeEvent(response.data.deletedEvent.id)
                            props.setEventInfoModalOpen(false);
                        }).catch(err => {
                            props.setEventInfoModalOpen(false);
                        });
                    }}>Delete</Button>
                </Modal.Footer>
            }
        </Modal>
    );
}

export default EventInfoModal;