import React, { useState } from 'react';
import esLogo from '../../media/logo.png';
import Button from 'react-bootstrap/Button';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import useAxiosProtect from '../../hooks/useAxiosProtect';

const SetPassword = props => {
    const passRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

    const axiosProtect = useAxiosProtect();

    const [formData, setFormData] = useState({
        password: '',
        passErr: '',
        confirmPassword: '',
        confirmPassErr: '',
        showPass: false,
        showConfirmPass: false
    });

    const [isNewAccount, setIsNewAccount] = useState(window.location.pathname.startsWith('/activate'));

    const validateForm = () => {
        let isValid = true;
        let tempForm = {...formData};

        if(tempForm.password.trim() === ''){
            tempForm.passErr = 'Required';
            isValid = false;
        }else if(!passRegex.test(tempForm.password.trim())){
            tempForm.passErr = 'Password must be at least 8 characters long, and include at least one letter, one number, and one special character (@$!%*#?&).';
            isValid = false;
        }else{
            tempForm.passErr = '';
        }

        if(!isValid){
            setFormData(tempForm);
            return isValid;
        }
        
        if(tempForm.confirmPassword.trim() === ''){
            tempForm.confirmPassErr = 'Required';
            isValid = false;
        }else if(tempForm.confirmPassword.trim() !== tempForm.password.trim()){
            tempForm.confirmPassErr = 'Passwords do not match';
            isValid = false;
        }else{
            tempForm.confirmPassErr = '';
        }

        setFormData(tempForm);
        return isValid;
    }

    const submitPassChange = () => {
        let payload = {
            uid: window.location.href.split('/').slice(-1),
            password: formData.password
        };

        axiosProtect.put(`/auth/pass/set-new-password`, payload).then(response => {
            if(response.status === 200) window.location.replace('/');
        }).catch(error => {
            setFormData(prev => ({
                ...prev,
                ['passErr']: error.response.data
            }))
        });
    }

    return (
        <div style={{height:'100vh', width:'100vw', backgroundColor:'rgb(8,114,182)',display:'flex',justifyContent:'center',alignItems:'center'}}>
            <div style={{backgroundColor:'white', height:'fit-content', width:'fit-content', padding:'5vh', borderRadius:'2%', boxShadow:'8px 25px 45px rgba(4, 57, 91, 0.9)',display:'flex',justifyContent:'center',alignItems:'center'}}>
                <div style={{height:'fit-content', width:'40vh',display:'flex', flexDirection:'column', justifyContent:'center',alignItems:'center'}}>
                    <img src={esLogo} style={{height:'4.25rem', width:'auto'}}/>
                    <br/>
                    <h4 style={{textAlign:'center'}}>{isNewAccount ? 'Welcome to STEWWI' : 'Reset your password'}</h4>
                    {isNewAccount && <p style={{textAlign:'center'}}>Create a password to get started</p>}

                    <TextField
                      id="rmis-password"
                      label="Password"
                      fullWidth
                      error={formData.passErr !== ''}
                      helperText={formData.passErr}
                      value={formData.password}
                      onChange={e => setFormData(prev => ({
                        ...prev,
                        ['password']: e.target.value
                      }))}
                      type={formData.showPass ? 'text' : 'password'}
                      InputProps={{
                      endAdornment: (
                          <InputAdornment position="start">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={e => setFormData(prev => ({
                                    ...prev,
                                    ['showPass']: !formData.showPass
                                }))}
                                onMouseDown={e => e.preventDefault()}
                              >
                              {formData.showPass ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                          </InputAdornment>
                      ),
                      }}
                      variant="outlined"
                    />

                    <br/>

                    <TextField
                      id="rmis-password-confirm"
                      label="Confirm password"
                      fullWidth
                      error={formData.confirmPassErr !== ''}
                      helperText={formData.confirmPassErr}
                      value={formData.confirmPassword}
                      onChange={e => setFormData(prev => ({
                        ...prev,
                        ['confirmPassword']: e.target.value
                      }))}
                      type={formData.showConfirmPass ? 'text' : 'password'}
                      InputProps={{
                      endAdornment: (
                          <InputAdornment position="start">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={e => setFormData(prev => ({
                                    ...prev,
                                    ['showConfirmPass']: !formData.showConfirmPass
                                }))}
                                onMouseDown={e => e.preventDefault()}
                              >
                              {formData.showConfirmPass ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                          </InputAdornment>
                      ),
                      }}
                      variant="outlined"
                    />

                    <br/>

                    <Button style={{backgroundColor:'rgb(8,114,182)',border:'none', width:'100%'}} onClick={() => {
                        if(validateForm()){
                            submitPassChange();
                        }
                    }}>
                        Continue
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default SetPassword;