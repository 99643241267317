import React, {useEffect, useRef, useState} from "react";
import html2canvas from 'html2canvas';
import {jsPDF} from 'jspdf';
import {Button} from 'react-bootstrap';

const ReportDownload = props => {
    // const [client, setClient] = useState('');
    // const [account, setAccount] = useState('');
    // const [date, setDate] = useState('');
    // var init = useRef(true);

    var pagesProcessed = 0, totalPages = 9;

    useEffect(() => {
        // if(init.current){
        //     init.current = false;

        //     let assessmentID = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
        //     axios.get(process.env.REACT_APP_SWS_SERVER + `assessment-get/get-assessment-name?assessment_id=${assessmentID}`).then(res => {
        //         setClient(res.data.client[0].client);
        //         setAccount(res.data.client[0].account_no);
        //         setDate(res.data.client[0].assessment_date.split("T")[0])
        //     });
        // }
    }, []);

    const downloadFileDocument = async () => {
        totalPages += document.getElementsByClassName('rmis-report-opps').length;

        const input = document.getElementById('title-page');
        var doc = new jsPDF('p', 'in');

        html2canvas(input).then(async (canvas) => {
            var imgData = canvas.toDataURL('image/jpeg', 0.9);
            const imgWidth = 8.25; 
            const imgHeight = 11.25;

            doc.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
            doc.addPage();

            props.setDLProgress(Math.round(100 * (++pagesProcessed / totalPages)));

            const summaryInput = document.getElementById('summary-page');
            canvas = await html2canvas(summaryInput);
            imgData = canvas.toDataURL('image/jpeg', 0.9);
            doc.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

            props.setDLProgress(Math.round(100 * (++pagesProcessed / totalPages)));

            const oppTableInput = document.getElementById('opportunity-table-page');
            canvas = await html2canvas(oppTableInput);
            imgData = canvas.toDataURL('image/jpeg', 0.9);
            doc.addPage('a4','l');
            doc.addImage(imgData, 'PNG', 0, 0, imgHeight, imgWidth);

            props.setDLProgress(Math.round(100 * (++pagesProcessed / totalPages)));

            const utilityInputs = document.getElementsByClassName('rmis-report-utility-page');
            for(let i = 0;i<utilityInputs.length;++i){
                const utilityInput = utilityInputs[i];

                let canvas = await html2canvas(utilityInput);
                doc.addPage('a4','l');
                imgData = canvas.toDataURL('image/jpeg', 0.9);
                doc.addImage(imgData, 'PNG', 0, 0, imgHeight, imgWidth);

                props.setDLProgress(Math.round(100 * (++pagesProcessed / totalPages)));
            }

            const financialInput = document.getElementById('rmis-report-financial');
            canvas = await html2canvas(financialInput);
            imgData = canvas.toDataURL('image/jpeg', 0.9);
            doc.addPage('a4','p');
            doc.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

            props.setDLProgress(Math.round(100 * (++pagesProcessed / totalPages)));

            const ghgInput = document.getElementById('rmis-report-ghg');
            canvas = await html2canvas(ghgInput);
            imgData = canvas.toDataURL('image/jpeg', 0.9);
            doc.addPage('a4','p');
            doc.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

            props.setDLProgress(Math.round(100 * (++pagesProcessed / totalPages)));

            const oppPages = document.getElementsByClassName('rmis-report-opps');
            for(let i = 0;i<oppPages.length;++i){
                const oppPage = oppPages[i];

                let canvas = await html2canvas(oppPage);
                doc.addPage('a4','p');
                imgData = canvas.toDataURL('image/jpeg', 0.9);
                doc.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

                props.setDLProgress(Math.round(100 * (++pagesProcessed / totalPages)));
            }

            const notesInput = document.getElementById('rmis-report-notes');
            canvas = await html2canvas(notesInput);
            imgData = canvas.toDataURL('image/jpeg', 0.9);
            doc.addPage('a4','p');
            doc.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

            props.setDLProgress(Math.round(100 * (++pagesProcessed / totalPages)));
            
            props.setIsLoading(false);
            doc.save(`${props.siteData.name} utility report (${document.getElementById('title-page-date-bounds').innerText}).pdf`);
        });        
    }
    
    return(
        <div>
            <Button id='report-download-btn' style={{position:'fixed', bottom:'5%', left:'3%', border:'none', backgroundColor:'rgba(8,114,182,1)'}} 
            onClick={() => {props.setIsLoading(true); downloadFileDocument();}}>Download PDF</Button>
        </div>
    );
}

export default ReportDownload;