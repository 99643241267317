import axios from "axios";
import useAuth from "./useAuth";

const useRefreshToken = () => {
    const {setAuth} = useAuth();

    const refresh = async() => {
        const res = await axios.get(process.env.REACT_APP_LOAD_BALANCER + '/auth/refresh', {
            withCredentials: true
        });
        
        setAuth(prev => {
            // console.log(JSON.stringify(prev));

            // console.log(res.data.accessToken);
            // console.log(res.data)
            return {
                ...prev, 
                accessToken: res.data.accessToken,
                role: res.data.role,
                id: res.data.id,
                org_id: res.data.org_id,
                agreed_to_tos: res.data.agreed_to_tos,
                email: res.data.email
            }
        });

        return res.data.accessToken;
    }

    return refresh;
}

export default useRefreshToken;