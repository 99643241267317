import React, { useEffect, useState } from "react";
import EmissionTrends from "./components/emission_trends/EmissionTrends";
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import PublicIcon from '@mui/icons-material/Public';
import CloseIcon from '@mui/icons-material/Close';
import TimelineIcon from '@mui/icons-material/Timeline';
import FactoryIcon from '@mui/icons-material/Factory';
import WaterIcon from '@mui/icons-material/Water';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import GasMeterIcon from '@mui/icons-material/GasMeter';
import SiteGHGDashboard from "./components/siteGHGs/SiteGHGDashboard";

const actions = [
    { icon: <GasMeterIcon />, name: 'Gas' },
    { icon: <ElectricBoltIcon />, name: 'Electricity' },
    { icon: <WaterIcon />, name: 'Water' },
    { icon: <FactoryIcon />, name: 'Sites' },
    { icon: <TimelineIcon />, name: 'Trends' },
  ];

const EmissionsDashboard = props => {
    const [page, setPage] = useState('Trends');
    const [dialOpen, setDialOpen] = useState(false);
    const [ctrHeight, setCtrHeight] = useState(0);

    const handleCtrResize = () => {
        try{
            let navbarHeight = document.getElementsByClassName('navbar navbar-expand-lg navbar-light sticky-top')[0].offsetHeight;
            setCtrHeight(window.innerHeight - navbarHeight);
            
        }catch(err){
            setCtrHeight(window.innerHeight);
        }
    }

    useEffect(() => {
        handleCtrResize();
        window.addEventListener('resize', handleCtrResize);

        return () => {
            window.removeEventListener('resize', handleCtrResize);
        }
    }, []);

    return(
        <div style={{padding:'2vh 2vw', minHeight:`${ctrHeight}px`, maxHeight:'fit-content', backgroundColor:'#f5f5f5'}}>
            {page === 'Trends' && <EmissionTrends/>}
            {page === 'Sites' && <SiteGHGDashboard/>}

            <Box sx={{ position:'absolute'}}>
                <Backdrop open={dialOpen} />
                <SpeedDial
                    ariaLabel="Emissions tooltip dial"
                    sx={{ position: 'fixed', bottom: 25, left: 25 }}
                    icon={<SpeedDialIcon icon={<PublicIcon />} openIcon={<CloseIcon/>}/>}
                    onClick={() => {setDialOpen(!dialOpen)}}
                    open={dialOpen}
                    FabProps={{
                        sx: {
                            bgcolor: 'rgba(0,177,168, 0.75)',
                            '&:hover': {
                            bgcolor: 'rgba(0,177,168, 1)',
                            }
                        }
                    }}
                >
                    {actions.map((action) => (
                        <SpeedDialAction
                            style={{marginBottom:'2vh'}}
                            tooltipPlacement="right"
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                            tooltipOpen
                            onClick={() => {
                                if(action.name !== page) setPage(action.name);
                                setDialOpen(false);
                            }}
                            FabProps={{
                                sx: {
                                    bgcolor: page === action.name ? 'rgb(0,177,168)' : 'rgba(255,255,255,0.5)',
                                    '&:hover': {
                                        bgcolor: page === action.name ? 'rgb(0,177,168)' : 'rgb(255,255,255)',
                                    }
                                }
                            }}
                        />
                    ))}
                </SpeedDial>
            </Box>
        </div>
    )
}

export default EmissionsDashboard;