import React, {useEffect, useRef, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import CreateUserModal from './CreateUserModal';
import useAxiosProtect from '../../../../hooks/useAxiosProtect';
import ReportModal from './ReportModal';

const SettingsContainer = props => {
    const [userModalOpen, setUserModalOpen] = useState(false);
    const [users, setUsers] = useState([]);

    const [reportModalOpen, setReportModalOpen] = useState(false);

    var init = useRef(true);
    const axiosProtect = useAxiosProtect();

    const [siteName, setSiteName] = useState('');
    const [orgID, setOrgID] = useState('');
    const siteID = window.location.href.split('/')[window.location.href.split('/').length - 1];
    
    useEffect(() => {
        if(!init.current) return;
        init.current = false;

        axiosProtect.get(`/s/sites/get-site-fields?fields=%5B%22id,name,org_id%22%5D&siteID=${siteID}`).then(response => {
            setOrgID(response.data.siteData.org_id);
            setSiteName(response.data.siteData.name);

            axiosProtect.get(`/auth/query/site-client-users?org_id=${response.data.siteData.org_id}&site_id=${siteID}`).then(response => {
                setUsers(response.data.users);
            });
        });
    }, []);

    const addUserToTable = user => {
        let userCopy = [...users];
        
        userCopy.push(user);
        setUsers(userCopy);
    }

    const generateDateString = unixMS => {
        if(unixMS === 'now') return 'now';
        
        let date = new Date(parseInt(unixMS));
    
        let dateOptions = {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        }
    
        return date.toLocaleDateString('en-US', dateOptions);
    }

    return(
        <>
        {userModalOpen && <CreateUserModal userModalOpen={userModalOpen} setUserModalOpen={setUserModalOpen} addUserToTable={addUserToTable} siteName={siteName} orgID={orgID} />}
        {reportModalOpen && <ReportModal reportModalOpen={reportModalOpen} setReportModalOpen={setReportModalOpen} siteName={siteName} />}

        <div>
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead style={{backgroundColor:'rgb(3,117,176)'}}>
                        <TableRow>
                            <TableCell colSpan={5} style={{color:'whitesmoke', padding:'0.9rem 0.75rem'}}>
                                <h4 style={{color:'whitesmoke', margin:'0'}}>Client accounts and access</h4>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <IconButton>
                                    <AddCircleIcon style={{color:'white'}} onClick={() => {setUserModalOpen(true);}}/>
                                </IconButton>
                            </TableCell>
                            <TableCell style={{color:'whitesmoke'}}>Name</TableCell>
                            <TableCell style={{color:'whitesmoke'}}>Email</TableCell>
                            <TableCell style={{color:'whitesmoke'}}>Date created</TableCell>
                            <TableCell align="right" style={{color:'whitesmoke'}}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        
                            {
                                users.map((user, i) => (
                                    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} id={`user-table-entry-${i}`}>
                                        <TableCell>{user.role == parseInt(process.env.REACT_APP_CLIENT_STAFF) ? 'Staff' : 'Corporate'}</TableCell>
                                        <TableCell>{user.first_name.substring(0,1).toUpperCase()}{user.first_name.length > 1 ? user.first_name.substring(1, user.first_name.length) : ''} {user.last_name.substring(0,1).toUpperCase()}{user.last_name.length > 1 ? user.last_name.substring(1, user.last_name.length) : ''} </TableCell>
                                        <TableCell>{user.email}</TableCell>
                                        <TableCell>{generateDateString(user.date_created)}</TableCell>
                                        <TableCell align='right'></TableCell>
                                        {/* <TableCell align='right'>delete</TableCell> */}
                                    </TableRow>
                                ))
                            }
                    </TableBody>
                </Table>
            </TableContainer>

            <br/>

            <IconButton style={{flex:'1', borderRadius:'2%', backgroundColor:'rgba(8,114,182, 0.1)'}} color="info">
                <p style={{margin:'1% 0', fontSize:'1.25rem'}} onClick={() => {
                    setReportModalOpen(true);
                }}>Generate site report</p>
            </IconButton>
        </div>
        </>
    );
}

export default SettingsContainer;