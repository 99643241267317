import React from 'react';
import './styling.css';
import esLogo from '../media/logo.png';

const MobileLandingPage = () => {
    return(
        <div id="mobile-landing-container">
            <img src={esLogo} id='mobile-landing-logo'/>
            <p style={{marginTop:'5vh', width:'50vw', textAlign:'center'}}>We are working to bring a mobile-friendly version of the platform to our clients. Please continue using the system on a desktop device for the time being.</p>
            <p style={{width:'50vw', textAlign:'center'}}>Sorry for any inconvenience.</p>
        </div>
    );
}

export default MobileLandingPage;