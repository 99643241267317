import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import TextField from '@mui/material/TextField';

const ASBRSiteModal = props => {
    const floatRegex = /^(-?\d*\.?\d*)?$/;

    return (
        <Modal
            show={props.asbrModalOpen !== undefined}
            onHide={() => props.setASBRModalOpen(false)}
            backdrop="static"
            keyboard={false}
            centered
        >
        <Modal.Header closeButton>
            <h4>{props.modalState ==='create' ? 'Create ASBR Site' : <span>Delete <strong>{props.siteToDelete?.name}</strong>?</span>}</h4>
        </Modal.Header>
        {
            props.modalState === 'create' && 
            <Modal.Body>
                <TextField 
                    label="Site name" 
                    variant="outlined"
                    style={{width:'100%', marginBottom:'1.25vh'}}
                    value={props.createForm.name}
                    onChange={e => {
                        props.setCreateForm(prev => ({
                            ...prev,
                            name: e.target.value
                        }));
                    }}
                    error={props.createForm.nameError !== ''}
                    helperText={props.createForm.nameError}
                />

                <TextField 
                    label="Address" 
                    variant="outlined"
                    style={{width:'100%', marginBottom:'1.25vh'}}
                    value={props.createForm.address}
                    onChange={e => {
                        props.setCreateForm(prev => ({
                            ...prev,
                            address: e.target.value
                        }));
                    }}
                    error={props.createForm.addressError !== ''}
                    helperText={props.createForm.addressError}
                />

                <TextField 
                    label="Latitude" 
                    variant="outlined"
                    style={{width:'100%', marginBottom:'1.25vh'}}
                    value={props.createForm.latitude}
                    onChange={e => {
                        if(!floatRegex.test(e.target.value)) return;

                        props.setCreateForm(prev => ({
                            ...prev,
                            latitude: e.target.value
                        }));
                    }}
                    error={props.createForm.latitudeError !== ''}
                    helperText={props.createForm.latitudeError}
                />

                <TextField 
                    label="Longitude" 
                    variant="outlined"
                    style={{width:'100%', marginBottom:'1.25vh'}}
                    value={props.createForm.longitude}
                    onChange={e => {
                        if(!floatRegex.test(e.target.value)) return;

                        props.setCreateForm(prev => ({
                            ...prev,
                            longitude: e.target.value
                        }));
                    }}
                    error={props.createForm.longitudeError !== ''}
                    helperText={props.createForm.longitudeError}
                />
            </Modal.Body>
        }
        <Modal.Footer>
            <Button variant={props.modalState === 'create' ? 'primary' : 'danger'} style={{backgroundColor:`${props.modalState === 'create' ? 'rgb(8,114,182)' : 'rgb(213,43,0)'}`}} onClick={() => {
                if(props.modalState === 'create') props.submitSiteCreation();
                else props.deleteSite();
            }}>
                {props.modalState === 'create' ? 'Create' : 'Delete'}
            </Button>
        </Modal.Footer>
        </Modal>
    );
}

export default ASBRSiteModal;