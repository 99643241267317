import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const DeleteOppModal = props => {
    return(
        <Modal
            show={props.deleteModalOpen}
            onHide={() => props.setDeleteModalOpen(false)}
            backdrop="static"
            centered
        >
            <Modal.Header closeButton>
                <h4 style={{fontSize:'1.2rem', margin:'0'}}>Delete Opportunity?</h4>
            </Modal.Header>

            <Modal.Body>
                Confirming this will permanently delete '{props.oppToDelete?.name}'
            </Modal.Body>

            <Modal.Footer>
                <Button variant='danger' onClick={() => {
                    props.deleteOpportunity();
                }}>Delete</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default DeleteOppModal;