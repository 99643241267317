import React from 'react';
import ESLogo from '../../../media/logo.png';

const ConclusionPage = props => {   

    return(
        <div id='rmis-report-notes' style={{height:'11in', width:'8.5in', margin:'auto', backgroundColor:'white', padding: '1in 0.75in'}}>
            <div style={{height:'0.3in', width:'7.5in', position:'absolute', marginTop:'9.5in', display:'flex', flexDirection:'row'}}>
                <img src={ESLogo} style={{height:'100%', width:'auto'}}/>
                <div style={{flex:'6'}}></div>
                <p style={{margin:'0', flex:'1', fontSize:'0.9rem'}}>page {props.page}</p>
            </div>
            
            <h4 style={{fontWeight:'bold', fontSize:'1.78rem', color:'rgb(8,114,182)'}}>{`${props.name}`}.</h4>
            <br/>

            <p style={{color:'rgb(78,79,83)'}}>If you have any questions about this report, next steps or other conservation measures you are considering, contact Enviro-Stewards at 519-578-5100, or reach out to the staff member(s) you are in contact with.</p>

            <br/>
        </div>
    );
}

export default ConclusionPage;