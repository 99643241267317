import React from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip, Legend } from 'recharts';

const days = [
    {date:'Sun'},
    {date:'Mon'},
    {date:'Tue'},
    {date:'Wed'},
    {date:'Thu'},
    {date:'Fri'},
    {date:'Sat'},
];

const colors = [
    'rgb(0,177,168)',
    'rgb(213,43,0)',
    'rgb(156, 156, 156)',
]

const data = [
    {
        day: 'Sun',
        shift:'prod',
        total: 1000,
        compressors: {
            air_comp_1: 600,
            air_comp_2: 300,
            air_comp_vfd: 100,
        },
    },
    {
        day: 'Sun',
        shift:'down',
        total: 1500 * 0.3,
        compressors: {
            air_comp_1: 750 * 0.3,
            air_comp_2: 550 * 0.3,
            air_comp_vfd: 200 * 0.3,
        },
    },
    {
        day: 'Mon',
        shift:'prod',
        total: 1500,
        compressors: {
            air_comp_1: 750,
            air_comp_2: 550,
            air_comp_vfd: 200,
        },
    },

    {
        day: 'Mon',
        shift:'down',
        total: 1500 * 0.45,
        compressors: {
            air_comp_1: 750 * 0.45,
            air_comp_2: 550 * 0.45,
            air_comp_vfd: 200 * 0.45,
        },
    },
    {
        day: 'Tue',
        shift:'prod',
        total: 1650,
        compressors: {
            air_comp_1: 800,
            air_comp_2: 600,
            air_comp_vfd: 250,
        }
    },
    {
        day: 'Tue',
        shift:'down',
        total: 1500 * 0.39,
        compressors: {
            air_comp_1: 750 * 0.39,
            air_comp_2: 550 * 0.39,
            air_comp_vfd: 200 * 0.39,
        },
    },
    {
        day: 'Wed',
        shift:'prod',
        total: 1300,
        compressors: {
            air_comp_1: 700,
            air_comp_2: 500,
            air_comp_vfd: 100,
        },
    },
    {
        day: 'Wed',
        shift:'down',
        total: 900,
        compressors: {
            air_comp_1: 500,
            air_comp_2: 200,
            air_comp_vfd: 200,
        },
    },
    {
        day: 'Thu',
        shift:'prod',
        total: 1500,
        compressors: {
            air_comp_1: 750,
            air_comp_2: 550,
            air_comp_vfd: 200,
        },
    },
    {
        day: 'Thu',
        shift:'down',
        total: 700,
        compressors: {
            air_comp_1: 150,
            air_comp_2: 250,
            air_comp_vfd: 300,
        },
    },
    {
        day: 'Fri',
        shift:'prod',
        total: 1500,
        compressors: {
            air_comp_1: 750,
            air_comp_2: 550,
            air_comp_vfd: 200,
        },

    },
    {
        day: 'Fri',
        shift:'down',
        total: 1500 * 0.45,
        compressors: {
            air_comp_1: 750 * 0.45,
            air_comp_2: 550 * 0.45,
            air_comp_vfd: 200 * 0.45,
        },
    },
    {
        day: 'Sat',
        shift:'prod',
        total: 1000,
        compressors: {
            air_comp_1: 600,
            air_comp_2: 300,
            air_comp_vfd: 100,
        },
    },
    {
        day: 'Sat',
        shift:'down',
        total: 1500 * 0.35,
        compressors: {
            air_comp_1: 750 * 0.35,
            air_comp_2: 550 * 0.35,
            air_comp_vfd: 200 * 0.35,
        },
    },
  ];
  
  const renderQuarterTick = (tickProps) => {
    const { y, payload } = tickProps;
    const x = 70 + tickProps.index * 90;
    const { value, offset } = payload;
    // console.log(offset)
    // const date = new Date(value);
    // const month = date.getMonth();
    // const quarterNo = Math.floor(month / 3) + 1;
    // const isMidMonth = month % 3 === 1;
    
  
    if (tickProps.index % 2 === 1) {
        let textValue = days[parseInt(tickProps.index / 2)].date
      return <text x={x} y={y - 4} textAnchor="middle" style={{fontSize:'14px'}}>{`${textValue}`}</text>;
    }
  
    const isLast = tickProps.index === 13;
  
    if (tickProps.index % 2 === 0 || isLast) {
    //   const pathX = Math.floor(isLast ? x + offset : x - offset) + 0.5;
    const pathX = tickProps.index;
    //   console.log(x + offset)
    //   console.log(x)
  
      return <path d={`M${pathX},${y - 4}v${-35}`} />;
    }
    return null;
  };

const CompressorStagingTrend = props => {
    return(
        <ResponsiveContainer height='100%' width='96%'>
            <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="shift" />
          <XAxis
            dataKey="day"
            axisLine={false}
            tickLine={false}
            interval={0}
            tick={renderQuarterTick}
            height={1}
            scale="band"
            xAxisId="quarter"
          />
          <YAxis />
          <Tooltip />
          <Legend />
          
          <Bar dataKey="compressors.air_comp_1" stackId="compressors" fill={colors[0]} />
          <Bar dataKey="compressors.air_comp_2" stackId="compressors" fill={colors[1]} />
          <Bar dataKey="compressors.air_comp_vfd" stackId="compressors" fill={colors[2]} />

          <Bar dataKey="total" stackId="total" fill="rgb(8,114,182)" />

        </BarChart>
        </ResponsiveContainer>
    );
}

export default CompressorStagingTrend;