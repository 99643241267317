const gas_types = [
    ['ng', 'Natural Gas'],
    ['prop', 'Propane'],
    ['df', 'Diesel'],
    ['fo', 'Furnace/Bunker Oil/Kerosene'],
    ['oil', 'Crude Oil'],
    ['coal', 'Coal'], 
    ['gas', 'Gasoline'],
];

export default gas_types;