import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const CustomTimeframeModal = props => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [dateError, setDateError] = useState('');

    const [resolution, setResolution] = useState('');
    const [resolutionError, setResolutionError] = useState('');
    
    const [daysBetween, setDaysBetween] = useState(null);

    const computeDaysBetween = () => {
        if(startDate == null || endDate == null){
            setDaysBetween(null);
            return;
        }

        const startDayjs = dayjs(startDate);
        const endDayjs = dayjs(endDate);
        const diff = endDayjs.diff(startDayjs, 'day');
        setDaysBetween(diff);
    }

    useEffect(() => {
        computeDaysBetween();
    }, [startDate, endDate]);

    const getMenuItems = () => {
        if (startDate != null && endDate != null && daysBetween > 0) {
          const items = [];
          if (daysBetween <= 3) {
            items.push(<MenuItem key='1m' value='1m'>1 minute</MenuItem>);
            items.push(<MenuItem key='5m' value='5m'>5 minutes</MenuItem>);
          }
          if (daysBetween <= 7) {
            items.push(<MenuItem key='15m' value='15m'>15 minutes</MenuItem>);
          }
          if (daysBetween <= 30) {
            items.push(<MenuItem key='1h' value='1h'>1 hour</MenuItem>);
          }
          if (daysBetween >= 7) {
            items.push(<MenuItem key='1d' value='1d'>1 day</MenuItem>);
          }
          return items;
        }
        
        return [<MenuItem key='' value='' disabled>Enter valid date range first</MenuItem>];
    };

    return(
        <Modal
            show={props.customTimeframeModalOpen}
            onHide={() => props.setCustomTimeframeModalOpen(false)}
            backdrop="static"
            centered
        >
            <Modal.Header closeButton>
                <h4>Site utility report</h4>
            </Modal.Header>

            <Modal.Body style={{overflow:'hidden'}}>
                <>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker', 'DatePicker']}>
                        <DatePicker
                        label="Start"
                        value={startDate}
                        onChange={newValue => {
                            setResolution('');
                            setStartDate(newValue);
                        }}
                        />
                        <DatePicker
                        label="End"
                        value={endDate}
                        onChange={newValue => {
                            setResolution('');
                            setEndDate(newValue);
                        }}
                        />
                    </DemoContainer>
                </LocalizationProvider>

                {dateError !== '' && <p style={{margin:'0', color:'red'}}>{dateError}</p>}
                </>

                <br/>

                <>
                <Box sx={{ width: '100%'}}>
                    <FormControl fullWidth margin="dense">
                        <InputLabel id="demo-simple-select-label">Resolution</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={resolution}
                            label="Resolution"
                            onChange={e => setResolution(e.target.value)}
                        >
                            {getMenuItems()}
                        </Select>
                    </FormControl>
                </Box>
                {resolutionError !== '' && <p style={{margin:'0', color:'red'}}>{resolutionError}</p>}
                </>
            </Modal.Body>

            <Modal.Footer>
                <Button style={{border:'none',  backgroundColor:'rgb(8,114,182)'}} onClick={() => {
                    let isValid = true;

                    if(startDate == null || endDate == null){
                        setDateError("Missing start and/or end date");
                        isValid = false;
                    }else if(new Date(startDate['$d']).getTime() >= new Date(endDate['$d']).getTime()){
                        setDateError("Start must be earlier than end date");
                        isValid = false;
                    }else if(new Date(endDate['$d']).getTime() > Date.now()){
                        setDateError("End date is in the future");
                        isValid = false;
                    }else{
                        setDateError('');
                    }

                    if(resolution === ''){
                        setResolutionError('Resolution not specified');
                        isValid = false;
                    }else{
                        setResolutionError('');
                    }

                    if(!isValid) return;

                    let start = new Date(startDate);
                    let end = new Date(endDate);

                    props.toolLoad.current = true;
                    props.setData([]);
                    props.setErrorMessage('');

                    props.setPlotSpecs(prev => ({
                        ...prev,
                        ['resolution']: resolution,
                        ['timeframe']: 'custom',
                        ['customStart']: `${start.getDate()}-${start.getMonth()}-${start.getFullYear()}`,
                        ['customEnd']: `${end.getDate()}-${end.getMonth()}-${end.getFullYear()}`
                    }));
                }}>Confirm</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default CustomTimeframeModal;