import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { PieChart, Pie, Cell, Label, Legend } from 'recharts';
import ESLogo from '../../../media/logo.png';

const COLORS = [
    'rgb(8,114,182)', 'rgb(0,177,168)', 'rgb(213,43,0)', 'rgb(247,230,83)', '#3a9efd', '#3e4491', '#292a73', '#1a1b4b',
    '#490a3d', '#bd1550', '#e97f02', '#f8ca00', '#8a9b0f','#99b898', '#fecea8', '#ff847c', '#e84a5f', '#2a363b',
    '#00a0b0', '#6a4a3c', '#cc333f', '#eb6841', '#edc951', '#ecd078', '#d95b43', '#c02942', '#542437', '#53777a',
    '#556270', '#4ecdc4', '#c7f464', '#ff6b6b', '#c44d58', '#343090', '#5f59f7', '#6592fd', '#44c2fd', '#8c61ff',
    '#072448', '#54d2d2', '#ffcb00', '#f8aa4b', '#ff6150', '#a2cdf2', '#0367a6', '#49b1f2', '#027373', '#7f8c1c'
];

const FinancialPage = props => {
    const [data, setData] = useState([
        {name: 'Electricity', value: props.metrics.elec_unit_cost * props.metrics.total_elec_kwh},
        {name: 'Natural gas', value: props.metrics.gas_unit_cost * props.metrics.total_gas},
        {name: 'Water', value: props.metrics.water_unit_cost * props.metrics.total_water_m3}
    ]);

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const RADIAN = Math.PI / 180;

        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
    
        return(
            <g>
                {percent >= 0.08 && <text x={x + 10} y={y-8} fill="white" textAnchor='middle' dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
                </text>}
            </g>
        );
    };
      
    return(
        <div className="rmis-report-page" id='rmis-report-financial' style={{height:'11in', width:'8.5in', margin:'auto', backgroundColor:'white', padding: '1in 0.75in'}}>
            <div style={{height:'0.3in', width:'7.5in', position:'absolute', marginTop:'9.5in', display:'flex', flexDirection:'row'}}>
                <img src={ESLogo} style={{height:'100%', width:'auto'}}/>
                <div style={{flex:'6'}}></div>
                <p style={{margin:'0', flex:'1', fontSize:'0.9rem'}}>page {props.page}</p>
            </div>

            <h4 style={{fontWeight:'bold', fontSize:'1.78rem', color:'rgb(8,114,182)'}}>Utility expenditures.</h4>
            <br/>
            <h4 style={{fontSize:'1.3rem', color:'rgb(78,79,83)', fontWeight:'bold', marginBottom:'1rem'}}>Annual utility consumption</h4>
            <p style={{color:'rgb(78,79,83)'}}>
                A breakdown of the facility's utility consumption is summarized in the following table and figure.
                The facility consumed {
                Math.round(props.metrics.total_water_m3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } m<sup>3</sup> of water, {
                Math.round(props.metrics.total_elec_kwh).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } kWh of electricity, and {
                Math.round(props.metrics.total_gas).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } m<sup>3</sup> of natural gas based on the most recent 12 months of utility invoices.
            </p>

            <TableContainer>
                <Table aria-label="collapsible table">
                    <TableHead style={{backgroundColor:'rgb(3,117,176)'}}>
                        <TableRow>
                            <TableCell style={{color:'white'}}>Resource</TableCell>
                            <TableCell style={{color:'white'}}>Annual Consumption</TableCell>
                            <TableCell style={{color:'white'}}>Annual Cost</TableCell>
                            <TableCell style={{color:'white'}}>Adjusted Unit Cost</TableCell>
                            <TableCell style={{color:'white'}}>Percent of Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell><p style={{margin:'0'}}>Electricity</p></TableCell>
                            <TableCell align='right'><p style={{margin:'0'}}>{Math.round(props.metrics.total_elec_kwh).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} kWh</p></TableCell>
                            <TableCell align='right'><p style={{margin:'0'}}>${Math.round(props.metrics.total_elec_kwh * props.metrics.elec_unit_cost).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p></TableCell>
                            <TableCell align='right'><p style={{margin:'0'}}>${Number(Math.round(100 * props.metrics.elec_unit_cost) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p></TableCell>
                            <TableCell align='right'>
                                <p style={{margin:'0'}}>
                                    {
                                    Math.round(100 * props.metrics.total_elec_kwh * props.metrics.elec_unit_cost / (props.metrics.total_elec_kwh * props.metrics.elec_unit_cost + props.metrics.total_gas * props.metrics.gas_unit_cost + props.metrics.total_water_m3 * props.metrics.water_unit_cost)) < 1 ?
                                    '<1' :
                                    Math.round(100 * props.metrics.total_elec_kwh * props.metrics.elec_unit_cost / (props.metrics.total_elec_kwh * props.metrics.elec_unit_cost + props.metrics.total_gas * props.metrics.gas_unit_cost + props.metrics.total_water_m3 * props.metrics.water_unit_cost)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    }%
                                </p>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><p style={{margin:'0'}}>{props.gasType === 'df' ? 'Diesel' : 'Natural gas'}</p></TableCell>
                            <TableCell align='right'><p style={{margin:'0'}}>{`${Math.round(props.metrics.total_gas).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${props.gasUnits === 'm3'? 'm3' : props.gasUnits.toUpperCase()}`}</p></TableCell>
                            <TableCell align='right'><p style={{margin:'0'}}>${Math.round(props.metrics.total_gas * props.metrics.gas_unit_cost).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p></TableCell>
                            <TableCell align='right'><p style={{margin:'0'}}>${Number(Math.round(100 * props.metrics.gas_unit_cost) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p></TableCell>
                            <TableCell align='right'>
                                <p style={{margin:'0'}}>
                                    {
                                    Math.round(100 * props.metrics.total_gas * props.metrics.gas_unit_cost / (props.metrics.total_elec_kwh * props.metrics.elec_unit_cost + props.metrics.total_gas * props.metrics.gas_unit_cost + props.metrics.total_water_m3 * props.metrics.water_unit_cost)) < 1 ?
                                    '<1' :
                                    Math.round(100 * props.metrics.total_gas * props.metrics.gas_unit_cost / (props.metrics.total_elec_kwh * props.metrics.elec_unit_cost + props.metrics.total_gas * props.metrics.gas_unit_cost + props.metrics.total_water_m3 * props.metrics.water_unit_cost)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    }%
                                </p>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><p style={{margin:'0'}}>Water</p></TableCell>
                            <TableCell align='right'><p style={{margin:'0'}}>{Math.round(props.metrics.total_water_m3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} m<sup>3</sup></p></TableCell>
                            <TableCell align='right'><p style={{margin:'0'}}>${Math.round(props.metrics.total_water_m3 * props.metrics.water_unit_cost).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p></TableCell>
                            <TableCell align='right'><p style={{margin:'0'}}>${Number(Math.round(100 * props.metrics.water_unit_cost) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p></TableCell>
                            <TableCell align='right'>
                                <p style={{margin:'0'}}>
                                    {
                                    Math.round(100 * props.metrics.total_water_m3 * props.metrics.water_unit_cost / (props.metrics.total_elec_kwh * props.metrics.elec_unit_cost + props.metrics.total_gas * props.metrics.gas_unit_cost + props.metrics.total_water_m3 * props.metrics.water_unit_cost)) < 1 ?
                                    '<1' :
                                    Math.round(100 * props.metrics.total_water_m3 * props.metrics.water_unit_cost / (props.metrics.total_elec_kwh * props.metrics.elec_unit_cost + props.metrics.total_gas * props.metrics.gas_unit_cost + props.metrics.total_water_m3 * props.metrics.water_unit_cost)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    }%
                                </p>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <br/>
            <div>
                <PieChart width={props.pageWidth/2} height={props.pageWidth/2} style={{margin:'auto'}}>
                    <Pie
                        data={data}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={props.pageWidth/6}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {data.map((data, i) => (
                            <Cell key={`cell-${i}`} fill={data.name === 'Water' ? 'rgb(8,114,182)' : data.name === 'Electricity' ? 'rgb(213,43,0)' : data.name === 'Natural gas' ? 'rgb(0,177,168)' : COLORS[i % COLORS.length]} />
                        ))}
                        <Label position='outside'/>
                    </Pie>
                    <Legend layout="horizontal" verticalAlign="bottom" align="center" wrapperStyle={{fontSize: "0.85rem"}}/>
                </PieChart>
            </div>
        </div>
    );
}

export default FinancialPage;