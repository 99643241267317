import React, {useEffect, useRef, useState} from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import Skeleton from '@mui/material/Skeleton';
import FactoryIcon from '@mui/icons-material/Factory';
import { CardActionArea, Divider } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DefaultLogo from '../../../../media/mainLogo.png';
import useAxiosProtect from '../../../../hooks/useAxiosProtect';
import DeleteSiteModal from './DeleteSiteModal';
import jwtDecode from 'jwt-decode';
import useAuth from '../../../../hooks/useAuth';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;

})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const SiteCard = props => {
  const [expanded, setExpanded] = useState(false);
  const [imageURL, setImageURL] = useState(null);
  const [cardHeight, setCardHeight] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [pendingDeletion, setPendingDeletion] = useState(false);

  const axiosProtect = useAxiosProtect();

  const {auth, persist} = useAuth();
  const [isESUser, setIsESUser] = useState(false);

  var init = useRef(true);

  const handleCardResize = () => {
    try{
        let cardOffset = document.getElementById(`site-card-${props.id}`).offsetHeight;
        setCardHeight(cardOffset);
        
    }catch(err){
        setCardHeight(0);
    }
  }
  
  useEffect(() => {
    handleCardResize();
    window.addEventListener('resize', handleCardResize);

    if(init.current){
      init.current = false;

      if(auth?.accessToken){
        let tokenObj = jwtDecode(auth.accessToken);
        setIsESUser(tokenObj.role == process.env.REACT_APP_ES_ADMIN || tokenObj.role == process.env.REACT_APP_ES_USER)
      }

      if(props.imageURL) setImageURL(props.imageURL);
      else{
        // const getMedia = async () => {
        //   let response = await axiosProtect.get(`/s/sites/get-media?id=${props.id}`);
        //   console.log(response.data.imageString)
        // }
  
        // getMedia();
        // use block above when image upload is implemented
        setTimeout(() => {
          setImageURL(DefaultLogo);

        }, 500) // timeout to demo load state render
      }
    }

    return () => {
      window.removeEventListener('resize', handleCardResize);
    }
  }, []);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const invokeSiteDeletion = () => {
    setShowDeleteModal(false);
    setPendingDeletion(true);
    props.deleteSite(props.id);
  }

  return(
    <>
    {showDeleteModal && <DeleteSiteModal setShowDeleteModal={setShowDeleteModal} invokeSiteDeletion={invokeSiteDeletion}/>}

    <Card sx={{ width: '750', height:'fit-content', marginBottom:'2%', marginRight:'2vw' }} className="siteCard" id={`site-card-${props.id}`}>

      {pendingDeletion &&
        <div style={{backgroundColor:'rgba(0,0,0,0.25)', height:`${cardHeight}px`, width:'16vw', position:'fixed', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        </div>
      }

      <CardActionArea onClick={() => window.location.href = props.navRef} className="primary">
        <CardHeader
          className="primary"
          avatar={
          <FactoryIcon style={{color:'rgb(8,114,182)'}}/>
          }
          title={props.siteName}
          subheader={<Typography sx={{fontSize: 13}} variant="body" className="secondary">{props.address}</Typography>}
        />

        {imageURL ? (
          <CardMedia
            component="img"
            style={{ width: '100%', height: '194px' }}
            image={imageURL === '' ? DefaultLogo : imageURL}
            alt="Site icon"
          />
        ) : (
          <Skeleton variant="rectangular" width='100%' height={150} />
        )}

        <CardContent className="primary">
          <Typography variant="body2" className="secondary">
              <p style={{margin:'0'}}>Active sensors: <strong>{props.activeSensors}</strong></p>
              {
                  props.inactiveSensors === 0 || props.inactiveSensors === undefined ? <p style={{margin:'0'}}>No disabled sensors</p>:
                  <p style={{margin:'0'}}>Disabled sensors: <strong>{props.inactiveSensors}</strong></p>
              }
              {
                  props.events === 0 || props.events === undefined ? <p style={{margin:'0'}}>No outstanding events</p>:
                  <p style={{margin:'0'}}><strong>{props.events}</strong> {`event${props.events === 1 ? '' : 's'}`}</p>
              }
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions disableSpacing className="secondary">
        {/* {isESUser && <IconButton aria-label="edit-site" className="secondary">
          <EditIcon />
        </IconButton>} */}
        {(isESUser && props.id != 96) &&<IconButton aria-label="delete-site" disabled={pendingDeletion} onClick={() => setShowDeleteModal(true)} className="secondary">
          <DeleteIcon />
        </IconButton>}
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
          className="primary"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit className="secondary">
        <CardContent>
          <Typography paragraph>
            <div style={{display:'flex', flexWrap:'wrap'}}>
                {props.hasWater ? <CheckIcon style={{color:'#28a745'}}/> : <CloseIcon style={{color:'#dc3545'}}/>}
                <p style={{margin:'0 0 0 4%'}}>Water</p>
            </div>

            <div style={{display:'flex', flexWrap:'wrap'}}>
                {props.hasElec ? <CheckIcon style={{color:'#28a745'}}/> : <CloseIcon style={{color:'#dc3545'}}/>}
                <p style={{margin:'0 0 0 4%'}}>Electricity</p>
            </div>

            <div style={{display:'flex', flexWrap:'wrap'}}>
                {props.hasGas ? <CheckIcon style={{color:'#28a745'}}/> : <CloseIcon style={{color:'#dc3545'}}/>}
                <p style={{margin:'0 0 0 4%'}}>Natural gas</p>
            </div>

            <div style={{display:'flex', flexWrap:'wrap'}}>
                {props.hasProduct ? <CheckIcon style={{color:'#28a745'}}/> : <CloseIcon style={{color:'#dc3545'}}/>}
                <p style={{margin:'0 0 0 4%'}}>Waste reduction</p>
            </div>

            <div style={{display:'flex', flexWrap:'wrap'}}>
                {props.hasSRCM ? <CheckIcon style={{color:'#28a745'}}/> : <CloseIcon style={{color:'#dc3545'}}/>}
                <p style={{margin:'0 0 0 4%'}}>SRCM controllers</p>
            </div>
          </Typography>

          <Divider style={{margin:'3% 0 4% 0'}}/>

          <Typography paragraph>
            {props.description}
          </Typography>
          <Typography paragraph style={{margin:'0'}}>
            Facility area: {props.sqft?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} sqft.
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
    </>
  );
}

export default SiteCard;