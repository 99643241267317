import axios from 'axios';

export default axios.create({
    baseURL: process.env.REACT_APP_LOAD_BALANCER
});

export const axiosProtect = axios.create({
    baseURL: process.env.REACT_APP_LOAD_BALANCER,
    headers: {'Content-Type':'application/json', 'Cache-Control': 'no-cache'},
    withCredentials: true,
});