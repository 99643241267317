import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const ProjectionsTable = props => {
    const [annualEntries, setAnnualEntries] = useState([]);
    const [totalMonthlyCost, setTotalMonthlyCost] = useState(0);
    const [totalRevenue, setTotalRevenue] = useState(0);

    const [esCosts, setESCosts] = useState({
        hardware: 0,
        subscriptions: 0
    });

    const [msrpCosts, setMSRPCosts] = useState({
        hardware: 0,
        subscriptions: 0
    });

    useEffect(() => {
        let entries = [];

        let esCost = {
            hardware: 0,
            subscriptions: 0
        };

        let msrpCost = {
            hardware: 0,
            subscriptions: 0
        };

        for(var key in props.equipmentItems){
            if(props.equipmentItems[key].selected){
                esCost[props.equipmentItems[key].cost_type.startsWith('cap_cost') ? 'hardware' : 'subscriptions'] += props.equipmentItems[key].msrp_cad * props.equipmentItems[key].quantity * (1 - props.equipmentItems[key].discount);
                msrpCost[props.equipmentItems[key].cost_type.startsWith('cap_cost') ? 'hardware' : 'subscriptions'] += props.equipmentItems[key].msrp_cad * props.equipmentItems[key].quantity;
            }
        }

        setESCosts(esCost);
        setMSRPCosts(msrpCost);

        let revTotal = 0;

        for(let i = 0;i<props.contractSpecs.contractYears;++i){
            let annualRevenue = 0;

            if(props.paymentSpecs.frequency.value === 'upfront'){
                if(i === 0){
                    annualRevenue = props.contractSpecs.contractYears * 12 * props.computeTotalCost(props.paymentSpecs.frequency.value === 'upfront');
                }

            }else{
                annualRevenue = 12 * props.computeTotalCost(props.paymentSpecs.frequency.value === 'upfront') + (i === 0 ? parseFloat(props.contractSpecs.deposit.toString().replace(/,/g,'')) : 0);
            }

            revTotal += annualRevenue;

            entries.push({
                annualRevenue
            });
        }

        setAnnualEntries(entries);
        setTotalRevenue(revTotal);
    }, []);

    return(
        <>
        <TableContainer style={{backgroundColor:'white', boxShadow:'2px 6px 12px rgb(164, 164, 164)', flex:'4'}}>
            <Table sx={{
                [`& .${tableCellClasses.root}`]: {
                borderBottom: "none"
                }
            }} aria-label="simple table">
                <TableHead>
                    <TableCell style={{backgroundColor:'#05B2A9', color:'white'}}>Year</TableCell>
                    <TableCell style={{backgroundColor:'#05B2A9', color:'white'}}>Annual Revenue</TableCell>
                    <TableCell style={{backgroundColor:'#05B2A9', color:'white'}}>Hardware E-S Costs</TableCell>
                    <TableCell style={{backgroundColor:'#05B2A9', color:'white'}}>LTE/Subscription E-S Costs</TableCell>
                    <TableCell style={{backgroundColor:'#05B2A9', color:'white'}}>Profit</TableCell>
                    <TableCell style={{backgroundColor:'#05B2A9', color:'white'}}>Cancellation Fee</TableCell>
                </TableHead>
                <TableBody>
                    {
                        annualEntries.map((entry, i) => (
                            <TableRow>
                                <TableCell>{i + 1}</TableCell>
                                <TableCell>${(Math.round(100 * entry.annualRevenue) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                                {/* switch calcs to state array objs */}
                                <TableCell>{i === 0 ? `$${(Math.round(100 * esCosts.hardware) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : '-'}</TableCell>
                                <TableCell>${(Math.round(100 * esCosts.subscriptions) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                                <TableCell>${(Math.round(100 * (entry.annualRevenue - (i === 0 ? esCosts.hardware : 0)  - esCosts.subscriptions)) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                                <TableCell>-</TableCell>
                            </TableRow>
                        ))
                    }
                    <TableRow style={{borderTop:'1px solid grey'}}>
                        <TableCell>Total</TableCell>
                        <TableCell>${(Math.round(100 * totalRevenue) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                        <TableCell>${(Math.round(100 * esCosts.hardware) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                        <TableCell>${(Math.round(100 * annualEntries.length * esCosts.subscriptions) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                        <TableCell>${(Math.round(100 * (totalRevenue - esCosts.hardware - annualEntries.length * esCosts.subscriptions)) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                        <TableCell>-</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
        
        <br/>

        <TableContainer style={{backgroundColor:'white', boxShadow:'2px 6px 12px rgb(164, 164, 164)', flex:'4'}}>
            <Table sx={{
                [`& .${tableCellClasses.root}`]: {
                borderBottom: "none"
                }
            }} aria-label="simple table">
                <TableBody>
                    <TableRow>
                        <TableCell>Percent of subscription costs tied to E-S service</TableCell>
                        <TableCell>
                            {
                                (
                                    Math.round(100 * 10 * (totalRevenue - esCosts.hardware - annualEntries.length * esCosts.subscriptions) / totalRevenue) / 10
                                ).toFixed(1)
                            }%
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Effective platform profit</TableCell>
                        <TableCell>
                            {
                                (Math.round(
                                    100 * 10 * 
                                    (props.computeMonthlyCost(props.unitCostSpecs.platformUC, 150) + 
                                    (msrpCosts.hardware - esCosts.hardware) / 12 / annualEntries.length + 
                                    (msrpCosts.subscriptions - esCosts.subscriptions) / 12)
                                    /
                                    props.computeTotalCost(props.paymentSpecs.frequency.value === 'upfront')
                                ) / 10).toFixed(1)
                            }%
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
        </>
    );
}

export default ProjectionsTable;