import React, { useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import Zoom from '@mui/material/Zoom';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ValveTable from './ValveTable';
import ParameterTable from './ParameterTable';

const SettingsModal = props => {
    const floatRegex = /^(-?\d*\.?\d*)?$/;
    const fieldRegex = /^[a-z0-9_]*$/;
    
    var pendingRequest = useRef(false);

    const [tabValue, setTabValue] = useState('valve');

    const [addingValve, setAddingValve] = useState(false);
    const [newValveForm, setNewValveForm] = useState({
        name: '',
        nameError: '',
        currentState: 'open'
    });

    const [addingParameter, setAddingParameter] = useState(false);
    const [newParamForm, setNewParamForm] = useState({
        screenName: '',
        screenNameError: '',
        fieldName: '',
        fieldNameError: '',
        units: 'cm'
    });

    const handleTabChange = (e, v) => {
        setTabValue(v);
    }

    return (
        <Modal
            show={props.settingsModalOpen !== undefined}
            onHide={() => props.setSettingsModalOpen(false)}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header closeButton>
                <Box sx={{width: '100%', backgroundColor:'rgba(255,255,255,0.4)'}}>
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        sx={{
                            '& .MuiTabs-indicator': {
                                backgroundColor: 'rgb(0,177,168)'
                            },
                            '& .MuiTab-textColorSecondary.Mui-selected': {
                                color: 'rgb(0,177,168)'
                            },
                            '& .MuiTab-textColorSecondary': {
                                color: 'rgb(8,114,182)'
                            },
                        }}
                        aria-label="secondary tabs example"
                    >
                        <Tab value="valve" label="Valves" />
                        <Tab value="parameters" label="Parameters" />
                    </Tabs>
                </Box>
            </Modal.Header>
            <Modal.Body>
                {
                    tabValue === 'valve' &&
                    <div>
                        <Button onClick={() => {
                            if(addingValve){
                                setNewValveForm({name: '', nameError: '', currentState: 'open'});
                            }
                            setAddingValve(!addingValve);
                        }}>
                            {addingValve ? 'Cancel' : 'Add valve'}
                        </Button>

                        {addingValve && <Zoom in={addingValve}>
                            <div style={{backgroundColor:'#f5f5f5', width:'100%', height:'fit-content', padding:'1%'}}>
                                <TextField 
                                    label="Valve name" 
                                    variant="outlined"
                                    style={{width:'100%', margin:'1.25vh 0'}}
                                    value={newValveForm.name}
                                    onChange={e => {
                                        setNewValveForm(prev => ({
                                            ...prev,
                                            name: e.target.value
                                        }));
                                    }}
                                    error={newValveForm.nameError !== ''}
                                    helperText={newValveForm.nameError}
                                />

                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <p style={{margin: '0', display: 'inline-flex', alignItems: 'center', fontSize:'0.9rem'}}>Valve is currently:</p>
                                </div>

                                <FormControl style={{width:'100%', marginBottom:'1.25vh'}}>
                                    <Select
                                        value={newValveForm.currentState}
                                        onChange={e => {
                                            setNewValveForm(prev => ({
                                                ...prev,
                                                currentState: e.target.value
                                            }));
                                        }}
                                    >
                                        <MenuItem value='open'>Open</MenuItem>
                                        <MenuItem value='close'>Closed</MenuItem>
                                    </Select>
                                </FormControl>

                                <Button onClick={async () => {
                                    if(pendingRequest.current) return;
                                    pendingRequest.current = true;

                                    let isValid = true;
                                    if(newValveForm.name === ''){
                                        isValid = false;

                                        setNewValveForm(prev => ({
                                            ...prev,
                                            nameError:'Required'
                                        }));

                                        pendingRequest.current = false;
                                    }

                                    if(!isValid) return;

                                    let newValve = await props.addValve(newValveForm);

                                    if(newValve == null){
                                        setNewValveForm(prev => ({
                                            ...prev,
                                            nameError:'Could not add valve'
                                        }));
                                    }else{
                                        setNewValveForm({name: '', nameError: '', currentState: 'open'});
                                        setAddingValve(false);
                                    }

                                    pendingRequest.current = false;
                                }}>Add</Button>
                            </div>
                        </Zoom>}

                        <ValveTable valves={props.valves} formatDate={props.formatDate}/>
                    </div>
                }
                {
                    tabValue === 'parameters' &&
                    <div>
                        <Button onClick={() => {
                            if(addingParameter){
                                setNewParamForm({
                                    screenName: '',
                                    screenNameError: '',
                                    fieldName: '',
                                    fieldNameError: '',
                                    units: 'cm'
                                });
                            }
                            setAddingParameter(!addingParameter);
                        }}>
                            {addingParameter ? 'Cancel' : 'Add parameter'}
                        </Button>

                        {addingParameter && <Zoom in={addingParameter}>
                            <div style={{backgroundColor:'#f5f5f5', width:'100%', height:'fit-content', padding:'1%'}}>
                                <TextField 
                                    label="Screen name" 
                                    variant="outlined"
                                    style={{width:'100%', margin:'1.25vh 0'}}
                                    value={newParamForm.screenName}
                                    onChange={e => {
                                        setNewParamForm(prev => ({
                                            ...prev,
                                            screenName: e.target.value
                                        }));
                                    }}
                                    error={newParamForm.screenNameError !== ''}
                                    helperText={newParamForm.screenNameError}
                                />

                                <TextField 
                                    label="Field name" 
                                    variant="outlined"
                                    style={{width:'100%', margin:'1.25vh 0'}}
                                    value={newParamForm.fieldName}
                                    onChange={e => {
                                        setNewParamForm(prev => ({
                                            ...prev,
                                            fieldName: e.target.value
                                        }));
                                    }}
                                    error={newParamForm.fieldNameError !== ''}
                                    helperText={newParamForm.fieldNameError}
                                />

                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <p style={{margin: '0', display: 'inline-flex', alignItems: 'center', fontSize:'0.9rem'}}>Measured units:</p>
                                </div>

                                <FormControl style={{width:'100%', marginBottom:'1.25vh'}}>
                                    <Select
                                        value={newParamForm.units}
                                        onChange={e => {
                                            setNewParamForm(prev => ({
                                                ...prev,
                                                units: e.target.value
                                            }));
                                        }}
                                    >
                                        <MenuItem value='cm'>cm (level)</MenuItem>
                                        <MenuItem value='degc'>&deg;C (temperature)</MenuItem>
                                    </Select>
                                </FormControl>

                                <Button onClick={async () => {
                                     if(pendingRequest.current) return;
                                     pendingRequest.current = true;
 
                                     let isValid = true;
                                     if(newParamForm.screenName === ''){
                                         isValid = false;
 
                                         setNewParamForm(prev => ({
                                             ...prev,
                                             screenNameError:'Required'
                                         }));
                                     }

                                     if(newParamForm.fieldName === ''){
                                        isValid = false;

                                        setNewParamForm(prev => ({
                                            ...prev,
                                            fieldNameError:'Required'
                                        }));
                                    }else if(!fieldRegex.test(newParamForm.fieldName)){
                                        isValid = false;

                                        setNewParamForm(prev => ({
                                            ...prev,
                                            fieldNameError:'Only lower case letters, numbers, and underscores'
                                        }));
                                    }
 
                                     if(!isValid){
                                        pendingRequest.current = false;
                                        return;
                                     }
 
                                     let newParam = await props.addParameter(newParamForm);
 
                                     if(newParam == null){
                                         setNewParamForm(prev => ({
                                             ...prev,
                                             screenNameError:'Could not add parameter'
                                         }));
                                     }else{
                                         setNewParamForm(
                                            {
                                                screenName: '',
                                                screenNameError: '',
                                                fieldName: '',
                                                fieldNameError: '',
                                                units: 'cm'
                                            }
                                         );
                                         setAddingParameter(false);
                                     }
 
                                     pendingRequest.current = false;
                                }}>
                                    Add
                                </Button>
                            </div>
                        </Zoom>}

                        <ParameterTable parameters={props.parameters} formatDate={props.formatDate}/>
                    </div>
                }
            </Modal.Body>
        </Modal>
    );
}

export default SettingsModal;