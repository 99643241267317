import React from 'react';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const GasMeterForm = props => {
    return(
        <div>
            <FormControlLabel control={<Checkbox checked={props.gasMeterFormData.isMain} onChange={e => {
                let temp = props.gasMeterFormData.isMain;

                props.setGasMeterFormData(prev => ({
                    ...prev,
                    isMain: !temp
                }))
            }}/>} label="This meter is a gas main" />
            
            <br/>
            <br/>

            <TextField
                variant='outlined'
                style={{width:'100%'}}
                label='Centrica meter name'
                value={props.gasMeterFormData.centricaSensorName.value}
                onChange={e => {
                    props.setGasMeterFormData(prev => ({
                        ...prev,
                        centricaSensorName: {
                            ...prev.centricaSensorName,
                            value: e.target.value
                        }
                    }));
                }}
                error={props.gasMeterFormData.centricaSensorName.error !== ''}
                helperText={props.gasMeterFormData.centricaSensorName.error === '' ? 'Name of the gas meter as it appears in Power Radar.' : props.gasMeterFormData.centricaSensorName.error}
            />

            <br/>
            <br/>

            <TextField
                variant='outlined'
                style={{width:'100%'}}
                label='Centrica site name'
                value={props.gasMeterFormData.centricaSiteName.value}
                onChange={e => {
                    props.setGasMeterFormData(prev => ({
                        ...prev,
                        centricaSiteName: {
                            ...prev.centricaSiteName,
                            value: e.target.value
                        }
                    }));
                }}
                error={props.gasMeterFormData.centricaSiteName.error !== ''}
                helperText={props.gasMeterFormData.centricaSiteName.error === '' ? 'Name of the site as it appears in Power Radar.' : props.gasMeterFormData.centricaSiteName.error}
            />

            <br/>
            <br/>

            <p style={{margin:'0', fontSize:'0.8rem'}}>*It is recommended to copy+paste these values from Power Radar to avoid discrepancies.</p>
        </div>
    );
}

export default GasMeterForm;