import React, {useState} from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import NavLogo from '../../media/mainLogo.png';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import MenuOpen from '@mui/icons-material/MenuOpen';
import CanvasDrawer from './CanvasDrawer';
import NavbarSearch from './NavbarSearch';
import './NavStyle.css';
import useLogout from '../../hooks/useLogout';
import { useNavigate } from 'react-router-dom';
import BreadcrumbBar from './BreadcrumbBar';

const RMISNavbar = props => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const logout = useLogout();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
    <Navbar collapseOnSelect expand="lg" sticky="top" >
      <Container>
        <a className="navbar-brand" href="/dashboard">
          <img src={NavLogo} alt="..." height="60"/>
        </a>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>

          <Nav>
            <NavbarSearch/>

            {/* <Nav.Link>
              <HelpOutlineOutlinedIcon className="nav-icon"/>
            </Nav.Link> */}

            <NavDropdown title={<AccountCircleIcon className="nav-icon"/>} id="collasible-nav-dropdown">
              <NavDropdown.Item href="/profile" style={{color:'rgb(78,79,83)'}}>Profile</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4" style={{color:'rgb(78,79,83)'}} onClick={async () => {
                await logout();
                navigate('/');
              }}>
                Log out
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link onClick={handleShow}>
              <MenuOpen className="nav-icon"/>
            </Nav.Link>

            <Offcanvas show={show} placement='end' onHide={handleClose}>
              <CanvasDrawer/>
            </Offcanvas>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <BreadcrumbBar currentPath={props.currentPath}/>
    </>
  );
}

export default RMISNavbar;