import React, { useEffect, useRef, useState } from "react";
import TitlePage from "./pages/TitlePage";
import SummaryPage from "./pages/SummaryPage";
import FinancialPage from "./pages/FinancialPage";
import GHGPage from "./pages/GHGPage";
import UtilityPage from "./pages/UtilityPage";
import OpportunityPage from "./pages/OpportunityPage";
import useAxiosProtect from "../../hooks/useAxiosProtect";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import ReportDownload from "./ReportDownload";
import ConclusionPage from "./pages/ConclusionPage";
import OpportunityOverviewPage from "./pages/OpportunityOverviewPage";
import OpportunityTablePage from "./pages/OpportunityTablePage";

const ReportContainer = () => {
    const axiosProtect = useAxiosProtect();

    var init = useRef(true);
    var oppPageCount = 0;

    const [pageWidth, setPageWidth] = useState(400);
    const [isLoading, setIsLoading] = useState(false);
    const [reportIsUnavailable, setReportIsUnavailable] = useState(false);
    const [dlProgess, setDLProgress] = useState(0);

    const [siteData, setSiteData] = useState(null);
    const [opps, setOpps] = useState([]);
    const [allOpps, setAllOpps] = useState([]);
    const [utilities, setUtilities] = useState(null);
    const [metrics, setMetrics] = useState(null);

    const [start, setStart] = useState(0);
    const [end, setEnd] = useState(new Date());

    const getSiteID = () => {
        let segments = window.location.pathname.split('/').filter(segment => segment !== '');

        return segments[segments.length - 1];
    }

    const getQueryString = () => {
        let url = new URL(window.location.href);
        return url.search;
    }

    useEffect(() => {
        if(init.current){
            init.current = false;

            const siteID = getSiteID();
            let queryParams = getQueryString();

            axiosProtect.get(`rep/reporting/report-data${queryParams}&site_id=${siteID}`).then(response => {
                setSiteData(response.data.siteData);
                setStart(response.data.start);
                setEnd(response.data.end);
                setUtilities(response.data.utilities);
                setOpps(response.data.opps);
                setMetrics(response.data.metrics);
                setAllOpps(response.data.allOpps);

                if(response.data != null && (
                    response.data.siteData == null || 
                    response.data.opps == null || 
                    response.data.metrics == null || 
                    response.data.utilities == null || 
                    response.data.allOpps == null
                )){
                    setReportIsUnavailable(true);
                }
            }).catch(error => {
                console.log(error)
            });
        }
    }, []);

    useEffect(() => {
        if(siteData != null && opps != null && metrics != null && utilities != null) setPageWidth(document.getElementById('summary-page').offsetWidth);
    }, [metrics, opps]);

    return(
        <div id="report-container" style={{backgroundColor:'grey',height:`${(siteData == null || opps == null || metrics == null || utilities == null) ? '100vh' : 'fit-content'}`, padding:`${isLoading ? '0' : '2vh'} 0`}}>
            {
            (siteData == null || opps == null || metrics == null || utilities == null || allOpps == null) ?
            <div style={{textAlign:'center', marginTop:'25vh'}}>
                {!reportIsUnavailable && <Box>
                    <CircularProgress style={{width:'10vw', height:'auto', color:'rgb(0,177,168)'}}/>
                </Box>}
                <br/>
                <p style={{color:'whitesmoke'}}>{reportIsUnavailable ? 'A report is not available for this site. This may be due to a lack of utility data or no active conservation opportunities. Please contact us if you any questions.' : 'Generating report'}</p>
            </div>
            :
            <>
            <ReportDownload setIsLoading={setIsLoading} siteData={siteData} setDLProgress={setDLProgress}/>

            {isLoading && <div style={{position:'fixed', height:'100vh', width:'100vw', backgroundColor:'rgba(84, 84, 84, 0.9)', zIndex:'9999', textAlign:'center'}}>
                <Box style={{ position: 'relative', marginTop: '25vh', display: 'inline-flex' }}>
                    <CircularProgress style={{width:'10vw', height:'auto', color:'rgb(0,177,168)'}}/>
                    <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Typography variant="h5" style={{color:'whitesmoke'}} component="div" color="textPrimary">{`${dlProgess}%`}</Typography>
                    </Box>
                </Box>
                <p style={{color:'whitesmoke'}}>Downloading PDF</p>
            </div>}

            {siteData != null && <TitlePage siteData={siteData} start={start} end={end}/>}
            <br/>
            {(allOpps != null && metrics != null) && <SummaryPage opps={allOpps} metrics={metrics} page={1}/>}
            <br/>
            {(allOpps != null && metrics != null) && <OpportunityTablePage opps={allOpps} metrics={metrics} page={2}/>}
            <br/>
            {(utilities != null && metrics != null) && <UtilityPage name='Water' metrics={metrics} utilities={utilities} page={2}/>}
            <br/>
            {(utilities != null && metrics != null) && <UtilityPage name='Electricity' metrics={metrics} utilities={utilities} page={3}/>}
            <br/>
            {((utilities != null && siteData != null) && metrics != null) && <UtilityPage name={`${siteData.gas_type === 'df' ? 'Diesel' : 'Natural gas'}`} metrics={metrics} utilities={utilities} page={4} gasUnits={siteData.gas_units}/>}
            <br/>
            {(metrics != null && siteData != null) && <FinancialPage pageWidth={pageWidth} metrics={metrics} page={6} gasUnits={siteData.gas_units} gasType={siteData.gas_type}/>}
            <br/>
            {(metrics != null && siteData != null) && <GHGPage pageWidth={pageWidth} metrics={metrics} page={7} gasUnits={siteData.gas_units} gasType={siteData.gas_type}/>}
            <br/>
            {allOpps.length != 0 && <OpportunityOverviewPage page={8} allOpps={allOpps}/>}
            <br/>
            {
                opps.map((opp, i) => {
                    let firstPage = oppPageCount === 0;
                    ++oppPageCount;

                    return <>
                        <OpportunityPage firstPage={firstPage} opp={opp} oppTitle={`3.${oppPageCount} ${opp.title}`} page={oppPageCount + 8}/>
                        <br/>
                    </>
                })
            }
            </>}
            {metrics != null && <ConclusionPage name='Notes' page={oppPageCount + 9}/>}
        </div>
    );
}

export default ReportContainer;