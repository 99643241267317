import React from 'react';
import {Line, Tooltip, CartesianGrid, XAxis,YAxis, LineChart, ResponsiveContainer} from 'recharts';
import { data } from './testData';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Form from 'react-bootstrap/Form';

const AmpTrends = props => {
    return(
        <div style={{height:'100%', width:'100%', display:'flex', flexDirection:'row', paddingTop:'0.25%'}}>
            <div style={{flex:'10'}}>
            <ResponsiveContainer>
                <LineChart data={data} id='metrics-linechart' margin={{ top: 10, right: 0, left: 0, bottom: 10 }} animation={{duration: 250}}>
                    <CartesianGrid stroke="#f5f5f5" />

                    <XAxis dataKey="name" interval={Math.ceil(data.length / 7)} grid={false} className="x-axis" 
                        // tick={<CustomizedTick />}
                        />
                        
                    <YAxis grid={false} className="y-axis" tick={{ fontSize: '12px', fill: 'rgb(78,79,83)'}} domain={[0, 50]}
                        label={{value: 'Current (A)', position: 'outside', offset: 0, angle: -90, dx: -14, style: { fontSize: '12px', fill: 'rgb(78,79,83)' }}}/>

                    {/* <Tooltip 
                        contentStyle={{
                            backgroundColor: '#F5F5F5',
                            color: '#333',
                            border:'1px solid lightgrey',
                            fontSize: '0.75em',
                        }} 
                        wrapperStyle={{ outline: "none" }}
                        labelFormatter={formatXAxisLabel}
                        formatter={formatReadings}
                    /> */}

                    <Line type="monotone" dataKey='Dehumidifier' stroke='rgb(8,114,182)' strokeWidth={1.5} dot={false} />
                    <Line type="monotone" dataKey='Pump #2' stroke='rgb(0,177,168)' strokeWidth={1.5} dot={false} />

                    
                </LineChart>
            
        </ResponsiveContainer>
            </div>

            <div style={{flex:'0.2'}}></div>

            <div style={{flex:'2', paddingTop:'0.3%'}}>
                        <Form.Check
                            style={{fontSize:'0.95rem'}}
                            type='checkbox'
                            label='Total'
                            checked={false}
                        />

                        <Form.Check 
                        style={{fontSize:'0.95rem'}}
                            type='checkbox'
                            label='Air compressor #1'
                            checked={true}
                        />

                        <Form.Check 
                        style={{fontSize:'0.95rem'}}
                            type='checkbox'
                            label='Air compressor #2'
                            checked={true}
                        />
            </div>
            
        </div>
    );
}

export default AmpTrends;