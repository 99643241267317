import { FormControl, InputLabel, Select, MenuItem, TextField, IconButton, Divider } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import HotWaterForm from './modal_forms/HotWaterForm';
import { ArrowBack, ArrowForward, Done, Grading } from '@mui/icons-material';
import useAxiosProtect from '../../../../../../hooks/useAxiosProtect';
import GasMeterForm from './modal_forms/GasMeterForm';

const GasModal = props => {
    const axiosProtect = useAxiosProtect();

    const numRegex = /^(\d+\.?\d*|\.\d+)?$/;

    var init = useRef(true);

    const [gasTypes, setGasTypes] = useState([]);

    const [initValues, setInitValues] = useState({
        initPage: true,
        measurePage: false,
        reviewPage: false,
        gasUserName: "",
        gasNameError: '',
        unitType: "fluid_dt",
        gasType: null,
        gasTypeError: '',
        gasUnitCost: '',
        gasUnitCostError: '',
    });

    const [measureType, setMeasureType] = useState('hot water');

    const [hotFluidFormData, setHotFluidFormData] = useState({
        ti: {
            type: 'fixed',
            fixed_value: '',
            measured_value: '',
            units: 'celsius',
            error: ''
        },

        tf: {
            type: 'fixed',
            fixed_value: '',
            measured_value: '',
            units: 'celsius',
            error: ''
        },
        flow: {
            type: 'calc',
            fixed_value: '',
            measured_value: '',
            units: 'lpm',
            error: ''
        },
        alertLabsLocation: '',
        locationError: '',
        isWater: true,
        fluidName:'',
        fluidNameError: '',
        fluidCp: '',
        fluidCpError: '',
        htEfficiency:'',
        efficiencyError: ''
    });

    const [gasMeterFormData, setGasMeterFormData] = useState({
        centricaSensorName: {
            value: '',
            error: ''
        },
        centricaSiteName: {
            value: '',
            error: ''
        },
        isMain: false
    });

    useEffect(() => {
        if(init.current){
            init.current = false;

            axiosProtect.get('/gsr/gas-sensors/get-gas-types').then(response => {
                setGasTypes([...response.data.gas_types]);
            })
        }
    }, []);

    const validateInitPage = async () => {
        let isValid = true;
        if(initValues.gasUserName === ''){
            setInitValues(prev => ({
                ...prev,
                gasNameError: 'Required'
            }));

            isValid = false;
        }else{
            setInitValues(prev => ({
                ...prev,
                gasNameError: ''
            }));
        }

        if(!initValues.gasType){
            setInitValues(prev => ({
                ...prev,
                gasTypeError: 'Required'
            }));

            isValid = false;
        }else{
            setInitValues(prev => ({
                ...prev,
                gasTypeError: ''
            }));
        }

        if(initValues.gasUnitCost === ''){
            setInitValues(prev => ({
                ...prev,
                gasUnitCostError: 'Required'
            }));

            isValid = false;
        }else{
            setInitValues(prev => ({
                ...prev,
                gasUnitCostError: ''
            }));
        }

        if(!isValid) return false;

        // check if name is taken
        try{
            let response = await axiosProtect.get(`/gsr/gas-sensors/check?name=${initValues.gasUserName}&siteID=${window.location.href.split('/')[window.location.href.split('/').length - 1]}`);
        }catch(err) {
            setInitValues(prev => ({
                ...prev,
                gasNameError: err.response.status === 400 ? err.response.data : 'An error occurred'
            }));

            return false;
        }
        

        setInitValues(prev => ({
            ...prev,
            gasNameError: ''
        }));

        return true;
    }

    const validateMeasurePage = () => {
        if(initValues.unitType === 'fluid_dt'){
            if(measureType === 'hot water') return validateHWForm();

        }else if(initValues.unitType === 'centrica_gas_meter'){
            return validateGasMeterForm();
        }

        return false;
    }

    const validateHWForm = () => {
        let isValid = true;
        let formCopy = {...hotFluidFormData}

        if(
            (hotFluidFormData.ti.type === 'fixed' && hotFluidFormData.ti.fixed_value === '') ||
            (hotFluidFormData.ti.type === 'calc' && hotFluidFormData.ti.measured_value === '')
        ){
                formCopy.ti.error = 'Required';
                isValid = false;
        }else{
                formCopy.ti.error = '';
        }

        if(
            (hotFluidFormData.tf.type === 'fixed' && hotFluidFormData.tf.fixed_value === '') ||
            (hotFluidFormData.tf.type === 'calc' && hotFluidFormData.tf.measured_value === '')
        ){
                formCopy.tf.error = 'Required';
                isValid = false;
        }else{
                formCopy.tf.error = '';
        }

        if(
            (hotFluidFormData.flow.type === 'fixed' && hotFluidFormData.flow.fixed_value === '') ||
            (hotFluidFormData.flow.type === 'calc' && hotFluidFormData.flow.measured_value === '')
        ){
                formCopy.flow.error = 'Required';
                isValid = false;
        }else{
                formCopy.flow.error = '';
        }

        if(hotFluidFormData.ti.type === 'calc' || hotFluidFormData.tf.type === 'calc' || hotFluidFormData.flow.type === 'calc'){
            if(hotFluidFormData.alertLabsLocation === ''){
                formCopy.locationError = 'Required';
                isValid = false;
            }else{
                formCopy.locationError = '';
            }
        }

        if(!hotFluidFormData.isWater){
            if(hotFluidFormData.fluidName === ''){
                formCopy.fluidNameError = 'Required';
                isValid = false;
            }else{
                formCopy.fluidNameError = '';
            }

            if(hotFluidFormData.fluidCp === ''){
                formCopy.fluidCpError = 'Required';
                isValid = false;
            }else{
                formCopy.fluidCpError = '';
            }
        }

        if(hotFluidFormData.htEfficiency === ''){
            formCopy.efficiencyError = 'Required';
            isValid = false;
        }else if(parseFloat(hotFluidFormData.htEfficiency) < 0 || parseFloat(hotFluidFormData.htEfficiency) > 100 || isNaN(parseFloat(hotFluidFormData.htEfficiency))){
            formCopy.efficiencyError = 'Invalid. 0 <= efficiency <= 100';
            isValid = false;
        }else{
            formCopy.efficiencyError = '';
        }

        setHotFluidFormData(formCopy);
        return isValid;
    }

    const validateGasMeterForm = () => {
        let isValid = true;
        let formCopy = {...gasMeterFormData};

        if(formCopy.centricaSensorName.value.trim() === ''){
            formCopy.centricaSensorName.error = 'Required';
            isValid = false;
        }else formCopy.centricaSensorName.error = '';

        if(formCopy.centricaSiteName.value.trim() === ''){
            formCopy.centricaSiteName.error = 'Required';
            isValid = false;
        }else formCopy.centricaSiteName.error = '';

        setGasMeterFormData(formCopy);
        return isValid;
    }

    const submitNewGasUser = async () => {
        let payload = {
            site_id: parseInt(window.location.href.split('/')[window.location.href.split('/').length - 1]),
            name: initValues.gasUserName,
            unit_type: initValues.unitType,
            gas_type: initValues.gasType,
            gas_unit_cost: isNaN(parseFloat(initValues.gasUnitCost)) ? 0 : parseFloat(initValues.gasUnitCost)
        };

        if(initValues.unitType === 'fluid_dt'){
            if(measureType === 'hot water'){
                payload.cold_temp_device_name = hotFluidFormData.ti.type === 'fixed' ? null : hotFluidFormData.ti.measured_value;
                payload.cold_temp_fixed_value = hotFluidFormData.ti.type === 'fixed' ? parseFloat(hotFluidFormData.ti.fixed_value) : null;
                payload.cold_temp_units = hotFluidFormData.ti.units;
    
                payload.hot_temp_device_name = hotFluidFormData.tf.type === 'fixed' ? null : hotFluidFormData.tf.measured_value;
                payload.hot_temp_fixed_value = hotFluidFormData.tf.type === 'fixed' ? parseFloat(hotFluidFormData.tf.fixed_value) : null;
                payload.hot_temp_units = hotFluidFormData.tf.units;
    
                payload.fluid_flowie_name = hotFluidFormData.flow.type === 'fixed' ? null : hotFluidFormData.flow.measured_value;
                payload.fixed_fluid_flow = hotFluidFormData.flow.type === 'fixed' ? parseFloat(hotFluidFormData.flow.fixed_value) : null;
                payload.fixed_flow_units = hotFluidFormData.flow.units;
    
                payload.fluid_flowie_location = hotFluidFormData.alertLabsLocation;
    
                payload.heat_transfer_efficiency = parseFloat(hotFluidFormData.htEfficiency);
    
                payload.heat_capacity_btu_lb_f = hotFluidFormData.isWater ? 1 : parseFloat(hotFluidFormData.fluidCp);
                payload.fluid_name = hotFluidFormData.isWater ? 'water' : hotFluidFormData.fluidName;
    
                payload.measure_type = measureType;
            }

        }else if(initValues.unitType === 'centrica_gas_meter'){
            payload.centrica_sensor_name = gasMeterFormData.centricaSensorName.value;
            payload.centrica_site_name = gasMeterFormData.centricaSiteName.value;
            payload.is_main = gasMeterFormData.isMain;
            payload.measure_type = 'centrica gas meter';
        }

        try{
            let response = await axiosProtect.post('/gsr/gas-sensors/gas-sensor-system', payload)
            props.addGasUnit(response.data.gasUser);
            props.setShowGasModal(false);

        }catch(err){
            return;
        }
    }

    const generateMeasureString = () => {
        if(measureType === 'hot water') return 'Hot water/fluid';
        else if(measureType === 'temperature change') return 'Temperature gradient';
        
        return measureType.charAt(0).toUpperCase() + measureType.slice(1);
    }

    return(
        <Modal
            show={props.ctModalState !== ''}
            onHide={() => props.closeModal()}
            backdrop="static"
            centered
        >
            <Modal.Header closeButton>
                {props.modalType === 'create' && <h4>{`${initValues.initPage ? 'Add gas unit' : initValues.measurePage ? 
                `${initValues.unitType === 'fluid_dt' ? 'How is it being measured?' : 'Gas meter details'}` :
                 'Review details'}`}</h4>}
            </Modal.Header>

            {props.modalType === 'create' && <Modal.Body>
                {
                    initValues.initPage ? 
                    <>
                        <TextField
                            label='Name'
                            style={{width:'100%'}}
                            value={initValues.gasUserName}
                            onChange={e => {
                                setInitValues(prev => ({
                                    ...prev,
                                    gasUserName: e.target.value
                                }));
                            }}
                            error={initValues.gasNameError}
                            helperText={initValues.gasNameError}
                        />

                        <br/>
                        <br/>

                        <FormControl fullWidth margin="dense">
                            <InputLabel id="gas-create-init-type">Unit type</InputLabel>
                            <Select
                                value={initValues.unitType}
                                label="Unit type"
                                onChange={e => {
                                    setInitValues(prev => ({
                                        ...prev,
                                        unitType: e.target.value
                                    }));
                                }}
                            >
                                <MenuItem value='fluid_dt'>Hot fluid flow</MenuItem>
                                <MenuItem value='centrica_gas_meter'>Gas line</MenuItem>
                            </Select>
                        </FormControl>

                        <br/>
                        <br/>

                        <FormControl fullWidth margin="dense">
                            <InputLabel id="gas-create-init-gas-type">Gas type used</InputLabel>
                            <Select
                                value={initValues.gasType?.gas_name}
                                label="Gas type used"
                                onChange={e => {
                                    for(let i = 0;i<gasTypes.length;++i){
                                        if(gasTypes[i].id == e.target.value){
                                            setInitValues(prev => ({
                                                ...prev,
                                                gasType: gasTypes[i]
                                            }));
                                            break;
                                        }
                                    }
                                }}
                            >
                                {gasTypes.map(gt => (
                                    <MenuItem value={`${gt.id}`}>{gt.gas_name}</MenuItem>
                                ))}
                            </Select>
                            <small style={{color:'red'}}>{initValues.gasTypeError}</small>
                        </FormControl>

                        <br/>
                        <br/>

                        <TextField
                            label={`Gas unit cost ($/${initValues.gasType ? initValues.gasType.units === 'l' ? 'L' : initValues.gasType.units : 'unit'})`}
                            style={{width:'100%'}}
                            value={initValues.gasUnitCost}
                            onChange={e => {
                                if(!numRegex.test(e.target.value)) return;

                                setInitValues(prev => ({
                                    ...prev,
                                    gasUnitCost: e.target.value
                                }));
                            }}
                            error={initValues.gasUnitCostError}
                            helperText={initValues.gasUnitCostError}
                        />

                    </> : initValues.measurePage ?
                    <>
                        {
                            initValues.unitType === 'fluid_dt' &&
                            <>
                            <FormControl fullWidth margin="dense">
                                <InputLabel id="demo-simple-select-label">Measurement type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={measureType}
                                    label="Measurement type"
                                    onChange={e => setMeasureType(e.target.value)}
                                >
                                    <MenuItem value='hot water'>Hot water/fluid</MenuItem>
                                    <MenuItem value='gas meter' disabled>Gas meter</MenuItem>
                                    <MenuItem value='space heating' disabled>Space heating</MenuItem>
                                    <MenuItem value='temperature change' disabled>Custom &#8710;T</MenuItem>
                                    <MenuItem value='electric element' disabled>Electric element</MenuItem>
                                </Select>
                            </FormControl>
                        

                            <br/>
                            <br/>

                            {measureType === 'hot water' && <HotWaterForm hotFluidFormData={hotFluidFormData} setHotFluidFormData={setHotFluidFormData}/>}
                            </>
                        }

                        {initValues.unitType === 'centrica_gas_meter' && <GasMeterForm gasMeterFormData={gasMeterFormData} setGasMeterFormData={setGasMeterFormData}/>}
                    </> :
                    <>
                        {
                            initValues.unitType === 'fluid_dt' && 
                            <div>
                                <h4 style={{color:'rgb(8,114,182)', margin:'0', fontSize:'1.65rem'}}>{initValues.gasUserName}</h4>
                                <small>{generateMeasureString()}</small>

                                <Divider style={{margin:'2% 0'}}/>

                                {
                                    measureType === 'hot water' &&
                                    <>
                                        {hotFluidFormData.ti.type === 'fixed' ? 
                                            <p style={{margin:'0', fontSize:'0.95rem'}}>T<sub>i</sub> = {hotFluidFormData.ti.fixed_value} &deg;{hotFluidFormData.ti.units.charAt(0).toUpperCase()}</p> :
                                            <p style={{margin:'0', fontSize:'0.95rem'}}>T<sub>i</sub> measured from device '{hotFluidFormData.ti.measured_value}'</p>
                                        }

                                        {hotFluidFormData.tf.type === 'fixed' ? 
                                            <p style={{margin:'0', fontSize:'0.95rem'}}>T<sub>f</sub> = {hotFluidFormData.tf.fixed_value} &deg;{hotFluidFormData.tf.units.charAt(0).toUpperCase()}</p> :
                                            <p style={{margin:'0', fontSize:'0.95rem'}}>T<sub>f</sub> measured from device '{hotFluidFormData.tf.measured_value}'</p>
                                        }

                                        {hotFluidFormData.flow.type === 'fixed' ? 
                                            <p style={{margin:'0', fontSize:'0.95rem'}}>Q = {hotFluidFormData.flow.fixed_value} {hotFluidFormData.flow.units.toUpperCase()}</p> :
                                            <p style={{margin:'0', fontSize:'0.95rem'}}>Q measured from device '{hotFluidFormData.flow.measured_value}'</p>
                                        }

                                        <br/>

                                        {(hotFluidFormData.ti.type === 'calc' || hotFluidFormData.tf.type === 'calc' || hotFluidFormData.flow.type === 'calc') && 
                                            <>
                                                <p style={{margin:'0', fontSize:'0.95rem'}}>AlertLabs device(s) registered to location '{hotFluidFormData.alertLabsLocation}'</p>
                                                <br/>
                                            </>
                                        }

                                        {hotFluidFormData.isWater ? 
                                            <>
                                                <p style={{margin:'0', fontSize:'0.95rem'}}>Fluid: water</p>
                                                <p style={{fontSize:'0.95rem'}}>C<sub>p</sub> @ SATP: 1 BTU/lb&#183;&#176;F</p>
                                            </> : 
                                            <>
                                                <p style={{margin:'0', fontSize:'0.95rem'}}>Fluid: {hotFluidFormData.fluidName}</p>
                                                <p style={{fontSize:'0.95rem'}}>C<sub>p</sub> @ SATP: {hotFluidFormData.fluidCp} BTU/lb&#183;&#176;F</p>
                                            </>
                                        }

                                        <p style={{fontSize:'0.95rem'}}>Overall heat transfer efficiency: {hotFluidFormData.htEfficiency}%</p>

                                        <p style={{fontSize:'0.95rem'}}>Fuel source: {initValues.gasType.gas_name} (${initValues.gasUnitCost}/{initValues.gasType.units})</p>

                                    </>
                                }
                            </div>
                        }

                        {
                            initValues.unitType === 'centrica_gas_meter' &&
                            <div>
                                <h4 style={{color:'rgb(8,114,182)', margin:'0', fontSize:'1.65rem'}}>{initValues.gasUserName}</h4>
                                <small>{`Gas meter${gasMeterFormData.isMain ? ' (main)' : ''}`}</small>

                                <br/>
                                <br/>

                                <p style={{margin:'0', fontSize:'0.95rem'}}><strong>Centrica unit name: </strong>{gasMeterFormData.centricaSensorName.value}</p>
                                <p style={{margin:'0', fontSize:'0.95rem'}}><strong>Centrica site name: </strong>{gasMeterFormData.centricaSiteName.value}</p>
                            </div>
                        }
                    </>
                }
            </Modal.Body>}

            {props.modalType === 'toggle' && 
                <Modal.Body>
                    <p>
                        <strong>WARNING</strong> disabling a gas sensor system will halt data acquisition for the unit. Until it is reactivated, readings in the interim will not be recorded.
                    </p>
                </Modal.Body>
            }

            {props.modalType === 'delete' && 
                <Modal.Body>
                    <p>
                        Delete <strong>{props.pendingGasSensorDelName}</strong>? This action cannot be undone.
                    </p>
                </Modal.Body>
            }

            <Modal.Footer>
                {props.modalType == 'create' ? <div style={{display:'flex', flexDirection:'row', width:'100%'}}>
                    {!initValues.initPage && <IconButton aria-label="gas-init-modal-back" onClick={() => {
                        setInitValues(prevState => {
                            if (prevState.measurePage) {
                                return {
                                    ...prevState,
                                    initPage: true,
                                    measurePage: false
                                }
                            } else if (prevState.reviewPage) {
                                return {
                                    ...prevState,
                                    measurePage: true,
                                    reviewPage: false
                                }
                            }
                            
                            return prevState;
                        });
                    }}>
                        <ArrowBack/>
                    </IconButton>}

                    <div style={{flex:'10'}}></div>

                    <IconButton aria-label="gas-init-modal-next" onClick={async () => {
                        if(initValues.reviewPage){
                            await submitNewGasUser();

                        }else{
                            if(initValues.initPage){
                                let isValid = await validateInitPage();
                                if(!isValid) return;
    
                            }else if(initValues.measurePage){
                                let isValid = validateMeasurePage();
                                if(!isValid) return;
                            }
    
                            setInitValues(prevState => {
                                if(prevState.initPage) {
                                    return {
                                        ...prevState,
                                        initPage: false,
                                        measurePage: true
                                    }
                                }else if (prevState.measurePage) {
                                    return {
                                        ...prevState,
                                        measurePage: false,
                                        reviewPage: true
                                    }
                                }
                                
                                return prevState;
                            });
                        }
                    }}>
                        {initValues.measurePage ? <Grading/> : initValues.reviewPage ? <Done /> : <ArrowForward />}
                    </IconButton>
                </div> 
                :
                props.modalType === 'toggle' ? 
                    <Button style={{backgroundColor:'rgb(8,114,182)'}} onClick={() => props.toggleGasSensorStatus()}>Confirm</Button>
                : 
                    <Button variant='danger' onClick={() => props.handleCTDeletion(props.gasIDToDelete)}>Delete</Button>
                }
            </Modal.Footer>
        </Modal>
    );
}

export default GasModal;