import React, {useEffect, useRef, useState} from 'react';
import IconButton from '@mui/material/IconButton';
import AddCommentIcon from '@mui/icons-material/AddComment';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import SendIcon from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField';
import Skeleton from '@mui/material/Skeleton';
import useAxiosProtect from '../../../hooks/useAxiosProtect';
import jwtDecode from 'jwt-decode';
import useAuth from '../../../hooks/useAuth';
import Card from '@mui/material/Card';
import DeleteIcon from '@mui/icons-material/Delete';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';

const CommentSection = props => {
    const commentMaxLength = 256;
    const [composedComment, setComposedComment] = useState('');
    const [commentError, setCommentError] = useState('');

    const axiosProtect = useAxiosProtect();
    const [comments, setComments] = useState(null);
    const [composeOn, setComposeOn] = useState(false);

    var init = useRef(true);
    const oppID = window.location.href.split('/')[window.location.href.split('/').length - 1];
    const [uid, setUID] = useState('');
    const {auth, persist} = useAuth();

    useEffect(() => {
        if(!init.current) return;
        init.current = false;

        if(auth?.accessToken){
            let tokenObj = jwtDecode(auth.accessToken);
            setUID(tokenObj.id);
        }

        axiosProtect.get(`/opp/opportunities/opp-comments?opp_id=${oppID}`).then(response => {
            if(response.status === 200){
                setComments(response.data.comments);
            }
        }).catch(error => {});

    }, []);

    const validateComment = () => {
        if(composedComment.trim() === ''){
            setCommentError('Required');
            return false;
        }

        setCommentError('');
        return true;
    }

    const submitComment = () => {
        if(!validateComment()) return;

        axiosProtect.post('/opp/opportunities/add-comment', {
            comment_text: composedComment.trim(),
            user_id: uid,
            opp_id: parseInt(oppID)
        }).then(response => {
            if(response.status === 200){
                setComments([response.data.comment, ...comments])
                setComposedComment('')
                setComposeOn(false);
            }
        }).catch(error => {});
    }

    const deleteComment = commentID => {
        axiosProtect.delete(`/opp/opportunities/delete-comment?comment_id=${commentID}`).then(response => {
            if(response.status === 200){
                let commentCopy = comments.filter(comment => comment.id !== commentID);
                setComments(commentCopy);
            }
        }).catch(err => {});
    }

    const generateDateString = date => {
        return new Date(date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    }

    return(
        <div style={{height:'88%', width:'100%', display:'flex', flexDirection:'row', padding:'0.5%', position: 'relative', backgroundColor:'rgba(0,0,0,0.1)'}}>
            <div style={{width:'fit-content', padding:'0 1%', borderRadius:'1%'}}>
                <IconButton onClick={() => {
                    if(composeOn){
                        setCommentError('');
                        setComposedComment('');
                    }
                    setComposeOn(!composeOn);
                }} disabled={comments == null}>
                    {composeOn ? <ClearIcon/> : <AddCommentIcon/>}
                </IconButton>
                <br/>
                <IconButton disabled>
                    <SearchIcon/>
                </IconButton>
                <br/>
                {composeOn && <IconButton style={{position: 'absolute', bottom: 10}} disabled={comments == null} onClick={() => submitComment()}>
                    <SendIcon/>
                </IconButton>}
            </div>

            <div style={{flex:'1'}}></div>

            <div style={{flex:'52'}}>
                <div style={{width:'100%', height:'100%', backgroundColor:'#f5f5f5', overflowY:'scroll', position: 'relative'}}>
                    {
                    comments == null ? 
                    <div style={{width:'100%', height:'100%'}}>
                        <Skeleton variant="rounded" style={{height:'100%'}} animation="wave"/>
                    </div>
                    :
                    <>
                    {composeOn && <div style={{width:'100%', height:'100%', backgroundColor:'rgba(0,0,0,0.25)', position:'sticky', top:'0', zIndex:'9999'}}>
                        <div style={{height:'fit-content', backgroundColor:'white', padding:'1% 0'}}>
                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            value={composedComment}
                            onChange={e => {
                                if(e.target.value.length > commentMaxLength) return;
                                setComposedComment(e.target.value);
                            }}
                            label="New comment"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={commentError !== ''}
                            helperText={`${commentError ? commentError : `${commentMaxLength - composedComment.length} character${commentMaxLength - composedComment.length === 1 ? '' : 's'} remaining`}`}
                        />
                        </div>
                    </div>}

                    {comments?.map((comment, i) => (
                        <Card sx={{ maxWidth: 300, margin:'1vh 1vw', marginLeft: comment?.user_id === uid ? 'auto' : 'none'}}>
                            <CardHeader
                                title={<span style={{fontSize:'90%'}}>{`${comment?.user_id === uid ? 'Me' : `${comment?.first_name} ${comment?.last_name}`}`}</span>}
                                subheader={generateDateString(comment?.date_created)}
                            />
                            <CardContent sx={{ py: 0 }}>
                                <Typography variant="body2" color="text.secondary">
                                    {comment?.comment_text}
                                </Typography>
                            </CardContent>
                            {comment?.user_id === uid && <CardActions disableSpacing>
                                <IconButton aria-label="add to favorites">
                                    <DeleteIcon onClick={() => deleteComment(comment?.id)}/>
                                </IconButton>
                            </CardActions>}
                        </Card>
                    ))}
                    </>
                    }
                </div>
            </div>
        </div>
    );
}

export default CommentSection;