import { axiosProtect } from "../api/axios";
import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";

const useAxiosProtect = () => {
    const refresh = useRefreshToken();
    const { auth } = useAuth();

    useEffect(() => {

        const requestInterceptor = axiosProtect.interceptors.request.use(
            config => {
                if(!config.headers['Authorization']){
                    config.headers['Authorization'] = auth?.accessToken;
                }
                return config;
            }, err => Promise.reject(err)
        )

        const responseInterceptor = axiosProtect.interceptors.response.use(
            response => response,
            async (err) => {
                const prevReq = err?.config;
                if(err?.response?.status === 403 && !prevReq?.sent){
                    prevReq.sent = true;
                    const newToken = await refresh();
                    prevReq.headers['Authorization'] = newToken;
                    return axiosProtect(prevReq);
                }
                return Promise.reject(err);
            }
        );

        return () => {
            axiosProtect.interceptors.request.eject(requestInterceptor);
            axiosProtect.interceptors.response.eject(responseInterceptor);
        }

    }, [auth, refresh]);

    return axiosProtect;
}

export default useAxiosProtect;