import React, { useEffect, useState} from "react";
import { Button, Modal,  } from "react-bootstrap";
import { TextField, Box, InputLabel, Select, MenuItem, FormControl, FormHelperText } from "@mui/material"
import gas_units from '../../../example_data/dropdowns/clientModal/gasUnits';
import currencies_list from '../../../example_data/dropdowns/clientModal/currencies';
import demand_units from '../../../example_data/dropdowns/clientModal/demand';
import months_list from '../../../example_data/dropdowns/clientModal/months';
import useAxiosProtect from "../../../hooks/useAxiosProtect";

// GHG Modal
const UtilityReadingsModal = props => {
    const axiosProtect = useAxiosProtect();

    // Utility Readings Variables
    const [rowId, setRowId] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [cddBaseline, setCddBaseline] = useState('');
    const [hddBaseline, setHddBaseline] = useState('');
    const [waterReading, setWaterReading] = useState('');
    const [waterUnitCost, setWaterUnitCost] = useState('');
    const [elecReading, setElecReading] = useState('');
    const [elecUnitCost,setElecUnitCost] = useState('');
    const [demandReading, setDemandReading] = useState('');
    const [demandUnitCost, setDemandUnitCost] = useState('');
    const [demandUnits, setDemandUnits] = useState('kw');
    const [powerFactor, setPowerFactor] = useState(0.9);
    const [gasReading, setGasReading] = useState('');
    const [gasUnitCost, setGasUnitCost] = useState('');
    const [gasUnits, setGasUnits] = useState('m3');
    const [currency, setCurrency] = useState('cad');

    // Errors
    const [monthErr, setMonthErr] = useState('');
    const [yearErr, setYearErr] = useState('');
    const [cddBaselineErr, setCddBaselineErr] = useState('');
    const [hddBaselineErr, setHddBaselineErr] = useState('');
    const [waterReadingErr, setWaterReadingErr] = useState('');
    const [waterUnitCostErr, setWaterUnitCostErr] = useState('');
    const [elecReadingErr, setElecReadingErr] = useState('');
    const [elecUnitCostErr,setElecUnitCostErr] = useState('');
    const [demandReadingErr, setDemandReadingErr] = useState('');
    const [demandUnitCostErr, setDemandUnitCostErr] = useState('');
    const [demandUnitsErr, setDemandUnitsErr] = useState('');
    const [powerFactorErr, setPowerFactorErr] = useState('');
    const [gasReadingErr, setGasReadingErr] = useState('');
    const [gasUnitCostErr, setGasUnitCostErr] = useState('');
    const [gasUnitsErr, setGasUnitsErr] = useState('');
    const [currencyErr, setCurrencyErr] = useState('');

    // Change variables
    const floatRegex = /^(|\d*\.?\d+|\d+\.)$/;
    const intRegex = /^(|\d+)$/;
    const changeYear = e => {
        if(e.target.value === '') setYear('');
        else if(intRegex.test(e.target.value)) setYear(e.target.value);
    }
    const changeMonth = e => {
        const list_of_months = months_list.map(m => m[0])
        if(e.target.value === '') setMonth('');
        else if(intRegex.test(e.target.value) && list_of_months.includes(e.target.value)) setMonth(e.target.value);
    }

    const changeCddBaseline = e => {
        if(e.target.value === '') setCddBaseline('');
        else if(floatRegex.test(e.target.value)) setCddBaseline(e.target.value);
        //if(floatRegex.test(e.target.value)) setCddBaseline(e.target.value);
    }

    const changeHddBaseline = e => {
        if(e.target.value === '') setHddBaseline('');
        else if(floatRegex.test(e.target.value)) setHddBaseline(e.target.value);
    }

    const changeWaterReading = e => {
        if(e.target.value === '') setWaterReading('');
        else if(floatRegex.test(e.target.value)) setWaterReading(e.target.value);
    }

    const changeWaterUnitCost = e => {
        if(e.target.value === '') setWaterUnitCost('');
        else if(floatRegex.test(e.target.value)) setWaterUnitCost(e.target.value);
    }

    const changeElecReading = e => {
        if(e.target.value === '') setElecReading('');
        else if(floatRegex.test(e.target.value)) setElecReading(e.target.value);
    }

    const changeElecUnitCost = e => {
        if(e.target.value === '') setElecUnitCost('');
        else if(floatRegex.test(e.target.value)) setElecUnitCost(e.target.value);
    }

    const changeDemandReading = e => {
        if(e.target.value === '') setDemandReading('');
        else if(floatRegex.test(e.target.value)) setDemandReading(e.target.value);
    }

    const changeDemandUnitCost = e => {
        if(e.target.value === '') setDemandUnitCost('');
        else if(floatRegex.test(e.target.value)) setDemandUnitCost(e.target.value);
    }
    const changeDemandUnits = e => {
        const d_units = demand_units.map(d => d[0])
        if(e.target.value === '') setDemandUnits("");
        else if (d_units.includes(e.target.value)) setDemandUnits(e.target.value);
    }
    const changePowerFactor = e => {
        if(e.target.value === '') setPowerFactor('');
        else if(floatRegex.test(e.target.value) && e.target.value >= 0 && e.target.value <= 1) setPowerFactor(e.target.value);
    }
    const changeGasReading = e => {
        if(e.target.value === '') setGasReading('');
        else if(floatRegex.test(e.target.value)) setGasReading(e.target.value);
    }
    const changeGasUnitCost = e => {
        if(e.target.value === '') setGasUnitCost('');
        else if(floatRegex.test(e.target.value)) setGasUnitCost(e.target.value);
    }
    const changeGasUnits = e => {
        const g_units = gas_units.map(g => g[0])
        if(e.target.value === '') setDemandUnits("");
        else if (g_units.includes(e.target.value)) setGasUnits(e.target.value);
    }
    const changeCurrency = e => {
        const curr = currencies_list.map(c => c[0])
        if(e.target.value === '') setCurrency("");
        else if (curr.includes(e.target.value)) setCurrency(e.target.value);
    }

    const isValidForm = () => {

        let isValid = true;

        const isNumeric = (num) => {
            return (!isNaN(parseFloat(num)) && isFinite(num));
        }

        // Only one resource field needs to be filled out
        let filled_one = false
        if ((isNumeric(waterReading) && isNumeric(waterUnitCost))
            || (isNumeric(elecReading) && isNumeric(elecUnitCost) && isNumeric(demandReading) && isNumeric(demandUnitCost) && (demandUnits !== '') && isNumeric(powerFactor))
            || (isNumeric(gasReading) && isNumeric(gasUnitCost) && (gasUnits !== ''))) {
            filled_one = true;
        } else {
            filled_one = false;
        }

        console.log((isNumeric(waterReading) && isNumeric(waterUnitCost)))
        console.log((isNumeric(elecReading) && isNumeric(elecUnitCost) && isNumeric(demandReading) && isNumeric(demandUnitCost) && (demandUnits !== '') && isNumeric(powerFactor)))
        console.log((isNumeric(gasReading) && isNumeric(gasUnitCost) && (gasUnits !== '')))


        if(isNaN(parseInt(year))){
            setYearErr('Invalid year');
            isValid = false;
        }else setYearErr('');

        if(isNaN(parseInt(month))){
            setMonthErr('Invalid month');
            isValid = false;
        }else setMonthErr('');

        if(isNaN(parseFloat(cddBaseline))){
            setCddBaselineErr('Invalid');
            isValid = false;
        }else{
            setCddBaselineErr('');
        }

        if(isNaN(parseFloat(hddBaseline))){
            setHddBaselineErr('Invalid');
            isValid = false;
        }else setHddBaselineErr('');

        if(isNaN(parseFloat(waterReading)) && !filled_one ){
            setWaterReadingErr('Invalid'); 
            isValid = false;
        }else setWaterReadingErr('');

        if(isNaN(parseFloat(waterUnitCost)) && !filled_one) {
            setWaterUnitCostErr('Invalid');
            isValid = false;
        }else setWaterUnitCostErr('');

        if(isNaN(parseFloat(elecReading)) && !filled_one){
            setElecReadingErr('Invalid');
            isValid = false;
        }else setElecReadingErr('');

        if(isNaN(parseFloat(elecUnitCost)) && !filled_one){
            setElecUnitCostErr('Invalid');
            isValid = false;
        }else setElecUnitCostErr('');

        if(isNaN(parseFloat(demandReading)) && !filled_one){
            setDemandReadingErr('Invalid');
            isValid = false;
        }else setDemandReadingErr('');

        if(isNaN(parseFloat(demandUnitCost)) && !filled_one){
            setDemandUnitCostErr('Invalid');
            isValid = false;
        }else setDemandUnitCostErr('');

        if ((demandUnits === '') && !filled_one){
            setDemandUnitsErr('Required');
            isValid = false;
        }else setDemandUnitsErr('');

        if(isNaN(parseFloat(powerFactor)) && !filled_one){
            setPowerFactorErr('Invalid');
            isValid = false;
        }else setPowerFactorErr('');

        if(isNaN(parseFloat(gasReading)) && !filled_one){
            setGasReadingErr('Invalid');
            isValid = false;
        }else setGasReadingErr('');

        if(isNaN(parseFloat(gasUnitCost)) && !filled_one){
            setGasUnitCostErr('Invalid');
            isValid = false;
        }else setGasUnitCostErr('');

        if ((gasUnits === '') && !filled_one){
            setGasUnitsErr('Required');
            isValid = false;
        }else setGasUnitsErr('');

        if (currency === ''){
            setCurrencyErr('Required');
            isValid = false;
        }else setCurrencyErr('');

        return isValid;
    }


    const closeModal = () => {
        setRowId('')
        setYear('')
        setMonth('')
        setCddBaseline('')
        setHddBaseline('')
        setWaterReading('')
        setWaterUnitCost('')
        setElecReading('')
        setElecUnitCost('')
        setDemandReading('')
        setDemandUnitCost('')
        setDemandUnits('kw')
        setPowerFactor(0.9)
        setGasReading('')
        setGasUnitCost('')
        setGasUnits('m3')
        setCurrency('cad')

        setYearErr('')
        setMonthErr('')
        setCddBaselineErr('')
        setHddBaselineErr('')
        setWaterReadingErr('')
        setWaterUnitCostErr('')
        setElecReadingErr('')
        setElecUnitCostErr('')
        setDemandReadingErr('')
        setDemandUnitCostErr('')
        setDemandUnitsErr('')
        setPowerFactorErr('')
        setGasReadingErr('')
        setGasUnitCostErr('')
        setGasUnitsErr('')
        setCurrencyErr('')

        props.toggleModalState(undefined);
    }

    const createUtilityReadings = async (event) => {
        // Stop from refreshing page after submit
        event.preventDefault();
        // Validate form
        if (isValidForm()) {
            console.log({
                site_id: props.siteID,
                month: parseInt(month),
                year: parseInt(year),
                cdd_baseline_18c: parseFloat(cddBaseline),
                hdd_baseline_18c: parseFloat(hddBaseline),
                water_reading_m3: isNaN(parseFloat(waterReading)) ? 0 : parseFloat(waterReading),
                water_unit_cost: isNaN(parseFloat(waterUnitCost)) ? 0 : parseFloat(waterUnitCost),
                elec_reading_kwh: isNaN(parseFloat(demandReading)) ? 0 : parseFloat(elecReading),
                elec_unit_cost: isNaN(parseFloat(elecUnitCost)) ? 0 : parseFloat(elecUnitCost),
                demand_reading: isNaN(parseFloat(demandReading)) ? 0 : parseFloat(demandReading),
                demand_unit_cost: isNaN(parseFloat(demandUnitCost)) ? 0 : parseFloat(demandUnitCost),
                demand_units: demandUnits,
                power_factor: isNaN(parseFloat(powerFactor)) ? 0.9 : parseFloat(powerFactor),
                gas_reading: isNaN(parseFloat(gasReading)) ? 0 : parseFloat(gasReading),
                gas_unit_cost: isNaN(parseFloat(gasUnitCost)) ? 0 : parseFloat(gasUnitCost),
                gas_units: gasUnits,
                currency: currency,
            })
            try {
                const response = await axiosProtect.post('/u/utilities', {
                    site_id: props.siteID,
                    month: parseInt(month),
                    year: parseInt(year),
                    cdd_baseline_18c: parseFloat(cddBaseline),
                    hdd_baseline_18c: parseFloat(hddBaseline),
                    water_reading_m3: isNaN(parseFloat(waterReading)) ? 0 : parseFloat(waterReading),
                    water_unit_cost: isNaN(parseFloat(waterUnitCost)) ? 0 : parseFloat(waterUnitCost),
                    elec_reading_kwh: isNaN(parseFloat(demandReading)) ? 0 : parseFloat(elecReading),
                    elec_unit_cost: isNaN(parseFloat(elecUnitCost)) ? 0 : parseFloat(elecUnitCost),
                    demand_reading: isNaN(parseFloat(demandReading)) ? 0 : parseFloat(demandReading),
                    demand_unit_cost: isNaN(parseFloat(demandUnitCost)) ? 0 : parseFloat(demandUnitCost),
                    demand_units: demandUnits,
                    power_factor: isNaN(parseFloat(powerFactor)) ? 0.9 : parseFloat(powerFactor),
                    gas_reading: isNaN(parseFloat(gasReading)) ? 0 : parseFloat(gasReading),
                    gas_unit_cost: isNaN(parseFloat(gasUnitCost)) ? 0 : parseFloat(gasUnitCost),
                    gas_units: gasUnits,
                    currency: currency,
                });
                // Add the new row to the table
                console.log(response.data)
                console.log(response.data.reading)
                props.setUtilityReadings(utilityReadings => [...utilityReadings, response.data.reading])
                closeModal()
            } catch(err) {
                console.log(err.response.data)
            }
        } else {
            console.log('Form is incorrect')
        }
    }

    const editUtilityReadings = async (event) => {
        // Stop from refreshing page after submit
        event.preventDefault();
        //Validate form
        if (isValidForm()) {
            try {
                const response = await axiosProtect.put('/u/utilities', {
                    id: rowId,
                    month: parseInt(month),
                    year: parseInt(year),
                    cdd_baseline_18c: parseFloat(cddBaseline),
                    hdd_baseline_18c: parseFloat(hddBaseline),
                    water_reading_m3: parseFloat(waterReading),
                    water_unit_cost: parseFloat(waterUnitCost),
                    elec_reading_kwh: parseFloat(elecReading),
                    elec_unit_cost: parseFloat(elecUnitCost),
                    demand_reading: parseFloat(demandReading),
                    demand_unit_cost: parseFloat(demandUnitCost),
                    demand_units: demandUnits,
                    power_factor: parseFloat(powerFactor),
                    gas_reading: parseFloat(gasReading),
                    gas_unit_cost: parseFloat(gasUnitCost),
                    gas_units: gasUnits,
                    currency: currency,
                });
                //Get rid of old row and put updated row into the table
                props.removeRow(props.editRow.id)
                props.setUtilityReadings(utilityReadings => [...utilityReadings, response.data.entry])
                closeModal()
            } catch(err) {
                console.log(err.response.data)
            }
        } else {
            console.log('Form is incorrect')
        }
    }

    const deleteRow = async () => {
        const controller = new AbortController();

        try{
            const response = await axiosProtect.delete(`/u/utilities?id=${props.delRow.id}`, {
                signal: controller.signal,
            });

            if(response.status === 200){
                props.removeRow(props.delRow.id)
            }

            closeModal();
        }catch(err){
            console.log(err)
        }
        console.log('Delete hit!!!')
    }

    const setEditReadings = () => {
        setRowId(props.editRow.id)
        setYear(props.editRow.year)
        setMonth(props.editRow.month)
        setCddBaseline(props.editRow.cdd)
        setHddBaseline(props.editRow.hdd)
        setWaterReading(props.editRow.wr)
        setWaterUnitCost(props.editRow.wuc)
        setElecReading(props.editRow.er)
        setElecUnitCost(props.editRow.euc)
        setDemandReading(props.editRow.dr)
        setDemandUnitCost(props.editRow.duc)
        setDemandUnits(props.editRow.dus)
        setPowerFactor(props.editRow.pf)
        setGasReading(props.editRow.gr)
        setGasUnitCost(props.editRow.guc)
        setGasUnits(props.editRow.gus)
        setCurrency(props.editRow.curr)
    }

    useEffect(() => {
        // Update form with default values for editing
        if (props.modalState === 'edit') {
            setEditReadings()
        }
    }, [props.modalState])

    return (
        <>
        <Modal show={props.modalState !== undefined} onHide={() => closeModal()}>
            <Modal.Header closebutton="true">
                <Modal.Title>{props.modalState === 'add' ? "Add Utility Readings" 
                            : props.modalState === 'delete' ? "Delete Utility Readings" 
                            : props.modalState === 'edit' ? "Edit Utility Readings": ""}</Modal.Title>
            </Modal.Header>
            {props.modalState === 'add' || props.modalState === 'edit' ?
                <form onSubmit={props.modalState === 'add' ? createUtilityReadings : editUtilityReadings }>
                <Modal.Body>
                    
                    <Box sx={{ fontWeight: 'light', marginBottom:'3vh' }}>
                        Form fields left blank will default to 0
                    </Box>
                    <TextField
                        label="Year"
                        variant="outlined"
                        name="Year"
                        value={year}
                        onChange={e => changeYear(e)}
                        error={yearErr !== ''}
                        helperText={yearErr}
                        style={{width:'100%', marginBottom:'1.25vh'}}
                    />
                    <FormControl style={{width:'100%', marginBottom:'1.25vh'}}>
                        <InputLabel id="month">Month</InputLabel>
                        <Select
                            id="select_month"
                            value={month}
                            label="Month"
                            onChange={changeMonth}
                            error={monthErr !== ''}
                        >
                            {months_list.map((_, i) => (
                                <MenuItem value={months_list[i][0]} key={months_list[i][0]}>{months_list[i][1]}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{monthErr}</FormHelperText>
                    </FormControl>
                    <TextField
                        label="CDD Baseline"
                        variant="outlined"
                        name="CDD Baseline"
                        value={cddBaseline}
                        onChange={e => changeCddBaseline(e)}
                        error={cddBaselineErr !== ''}
                        helperText={cddBaselineErr}
                        style={{width:'100%', marginBottom:'1.25vh'}}
                    />
                    <TextField
                        label="HDD Baseline"
                        variant="outlined"
                        name="HDD Baseline"
                        value={hddBaseline}
                        onChange={e => changeHddBaseline(e)}
                        error={hddBaselineErr !== ''}
                        helperText={hddBaselineErr}
                        style={{width:'100%', marginBottom:'1.25vh'}}
                    />
                    <TextField
                        label="Water Reading (m3)"
                        variant="outlined"
                        name="Water Reading (m3)"
                        value={waterReading}
                        onChange={e => changeWaterReading(e)}
                        error={waterReadingErr !== ''}
                        helperText={waterReadingErr}
                        style={{width:'100%', marginBottom:'1.25vh'}}
                    />
                    <TextField
                        label="Water Unit Cost"
                        variant="outlined"
                        name="Water Unit Cost"
                        value={waterUnitCost}
                        onChange={e => changeWaterUnitCost(e)}
                        error={waterUnitCostErr !== ''}
                        helperText={waterUnitCostErr}
                        style={{width:'100%', marginBottom:'1.25vh'}}
                    />
                    <TextField
                        label="Electricity Reading (kWh)"
                        variant="outlined"
                        name="Electricity Reading (kWh)"
                        value={elecReading}
                        onChange={e => changeElecReading(e)}
                        error={elecReadingErr !== ''}
                        helperText={elecReadingErr}
                        style={{width:'100%', marginBottom:'1.25vh'}}
                    />
                    <TextField
                        label="Electricity Unit Cost"
                        variant="outlined"
                        name="Electricity Unit Cost"
                        value={elecUnitCost}
                        onChange={e => changeElecUnitCost(e)}
                        error={elecUnitCostErr !== ''}
                        helperText={elecUnitCostErr}
                        style={{width:'100%', marginBottom:'1.25vh'}}
                    />
                    <TextField
                        label="Demand Reading"
                        variant="outlined"
                        name="Demand Reading"
                        value={demandReading}
                        onChange={e => changeDemandReading(e)}
                        error={demandReadingErr !== ''}
                        helperText={demandReadingErr}
                        style={{width:'100%', marginBottom:'1.25vh'}}
                    />
                    <TextField
                        label="Demand Unit Cost"
                        variant="outlined"
                        name="Demand Unit Cost"
                        value={demandUnitCost}
                        onChange={e => changeDemandUnitCost(e)}
                        error={demandUnitCostErr !== ''}
                        helperText={demandUnitCostErr}
                        style={{width:'100%', marginBottom:'1.25vh'}}
                    />
                    <FormControl style={{width:'100%', marginBottom:'1.25vh'}}>
                        <InputLabel id="demand_units">Demand Units</InputLabel>
                        <Select
                            id="Demand Units"
                            value={demandUnits}
                            label="Demand Units"
                            onChange={e => changeDemandUnits(e)}
                            error={demandUnitsErr !== ''}
                            helperText={demandUnitsErr} 
                        >
                            {demand_units.map((_, i) => (
                                <MenuItem value={demand_units[i][0]} key={demand_units[i][0]}>{demand_units[i][1]}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{demandUnitsErr}</FormHelperText>
                    </FormControl>
                    <TextField
                        label="Power Factor"
                        variant="outlined"
                        name="Power Factor"
                        value={powerFactor}
                        onChange={e => changePowerFactor(e)}
                        error={powerFactorErr !== ''}
                        helperText={powerFactorErr}
                        style={{width:'100%', marginBottom:'1.25vh'}}
                    />
                    <TextField
                        label="Gas Reading"
                        variant="outlined"
                        name="Gas Reading"
                        value={gasReading}
                        onChange={e => changeGasReading(e)}
                        error={gasReadingErr !== ''}
                        helperText={gasReadingErr}
                        style={{width:'100%', marginBottom:'1.25vh'}}
                    />
                    <TextField
                        label="Gas Unit Cost"
                        variant="outlined"
                        name="Gas Unit Cost"
                        value={gasUnitCost}
                        onChange={e => changeGasUnitCost(e)}
                        error={gasUnitCostErr !== ''}
                        helperText={gasUnitCostErr}
                        style={{width:'100%', marginBottom:'1.25vh'}}
                    />
                    <FormControl style={{width:'100%', marginBottom:'1.25vh'}}>
                        <InputLabel id="gas_units">Gas Units</InputLabel>
                        <Select
                            id="Gas Units"
                            value={gasUnits}
                            label="Gas Units"
                            onChange={e => changeGasUnits(e)}
                            error={gasUnitsErr !== ''}
                        >
                            {gas_units.map((_, i) => (
                                <MenuItem value={gas_units[i][0]} key={gas_units[i][0]}>{gas_units[i][1]}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{gasUnitsErr}</FormHelperText>
                    </FormControl>
                    <FormControl style={{width:'100%', marginBottom:'1.25vh'}}>
                        <InputLabel id="currencies">Currency</InputLabel>
                        <Select
                            id="Currency"
                            value={currency}
                            label="Currency"
                            onChange={changeCurrency}
                            error={currencyErr !== ''}
                        >
                            {currencies_list.map((_, i) => (
                                <MenuItem value={currencies_list[i][0]} key={currencies_list[i][0]}>{currencies_list[i][1]}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{currencyErr}</FormHelperText>
                    </FormControl>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='primary'
                        style={{backgroundColor: 'rgb(8,114,182)'}}
                        type='submit'>
                            {props.modalState === 'add' ? 'Create' : 'Edit'}
                    </Button>
                </Modal.Footer>
                </form>
            : props.modalState === 'delete' ? 
                <>
                <Modal.Body>
                    <p>Are you sure you want to delete the following row?</p>
                    <p>
                        {props.delRow.year} - {props.delRow.month}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='danger' 
                        style={{backgroundColor:'rgb(213,43,0)'}}
                        onClick={() => { deleteRow() }}
                        >Delete
                    </Button>
                </Modal.Footer>
                </>
            : <div></div>
            }

        </Modal>
        </>
    )
}


export default UtilityReadingsModal;