import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ESLogo from '../../../media/logo.png';

const OpportunityOverviewPage = props => {  
    const computeFinancialPercent = () => {
        let unrealizedSavings = 0, totalSavings = 0;

        for(let i = 0;i<props.allOpps.length;++i){
            let finSavings = props.allOpps[i].water_savings_m3_year * props.allOpps[i].water_unit_cost_m3 + 
            props.allOpps[i].electricity_savings_kwh_year * props.allOpps[i].electricity_unit_cost_kwh + 
            props.allOpps[i].gas_savings_units_year * props.allOpps[i].gas_unit_cost;
            
            if(props.allOpps[i].project_progress_status !== 'complete') unrealizedSavings += finSavings;

            totalSavings += finSavings;
        }

        return `${Math.round(100 * (1 - unrealizedSavings / totalSavings))}%`;
    }

    const computeGHGPercent = () => {
        let unrealizedSavings = 0, totalSavings = 0;
        
        for(let i = 0;i<props.allOpps.length;++i){
            let ghgSavings = (props.allOpps[i].water_savings_m3_year * props.allOpps[i].water_kg_co2_m3 + 
                props.allOpps[i].electricity_savings_kwh_year * props.allOpps[i].electricity_kg_co2_kwh + 
                props.allOpps[i].gas_savings_units_year * props.allOpps[i].gas_kg_co2_btu * props.allOpps[i].gas_btu_per_unit) / 1000;
            
            if(props.allOpps[i].project_progress_status !== 'complete') unrealizedSavings += ghgSavings;

            totalSavings += ghgSavings;
        }

        return `${Math.round(100 * (unrealizedSavings / totalSavings))}%`;
    }

    return(
        <div className="rmis-report-opps" style={{height:'11in', width:'8.5in', margin:'auto', backgroundColor:'white', padding: '1in 0.75in', display:'flex', flexDirection:'column'}}>
            <h4 style={{fontWeight:'bold', fontSize:'1.78rem', color:'rgb(8,114,182)', flex:'1'}}>Overview of opportunities.</h4>

            <div style={{height:'0.3in', width:'7.5in', position:'absolute', marginTop:'9.5in', display:'flex', flexDirection:'row'}}>
                <img src={ESLogo} style={{height:'100%', width:'auto'}}/>
                <div style={{flex:'6'}}></div>
                <p style={{margin:'0', flex:'1', fontSize:'0.9rem'}}>page {props.page}</p>
            </div>

            <p style={{color:'rgb(78,79,83)'}}>
                The following table lists utility conservation opportunities identified that have not been implemented. 
                These measures constitute <strong style={{color:'rgb(8,114,182)'}}>{computeFinancialPercent()}</strong> of potential 
                financial savings and <strong style={{color:'rgb(0,177,168)'}}>{computeGHGPercent()}</strong> of potential GHG utility emissions that have been identified. 
            </p>
            
            <div style={{flex:'15'}}>
                <Table sx={{width:'100%', backgroundColor:'white', borderRadius:'1%', boxShadow:'none'}} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <strong>Opportunity</strong>
                            </TableCell>
                            <TableCell align='center'>
                                <strong>Annual Financial Savings</strong>
                            </TableCell>
                            <TableCell align='center'>
                                <strong>GHG Savings (MTCO<sub>2</sub>e/yr)</strong>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            props.allOpps.map((opp, _) => {
                                if(opp.project_progress_status !== 'complete'){
                                    return(
                                        <TableRow>
                                            <TableCell component="tr" scope="row">{opp.title}</TableCell>
                                            <TableCell align="center">
                                                ${Math.round(
                                                    opp.water_savings_m3_year * opp.water_unit_cost_m3 + 
                                                    opp.electricity_savings_kwh_year * opp.electricity_unit_cost_kwh + 
                                                    opp.gas_savings_units_year * opp.gas_unit_cost
                                                ).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            </TableCell>
                                            <TableCell align="center">
                                                {Math.round(
                                                    (opp.water_savings_m3_year * opp.water_kg_co2_m3 + 
                                                    opp.electricity_savings_kwh_year * opp.electricity_kg_co2_kwh + 
                                                    opp.gas_savings_units_year * opp.gas_kg_co2_btu * opp.gas_btu_per_unit) / 1000
                                                ).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            })
                        }
                    </TableBody>
                </Table>
            </div>
        </div>
    );
}

export default OpportunityOverviewPage;