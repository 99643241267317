import React, { useEffect, useRef, useState } from "react";
import OpportunityTable from "./OpportunityTable";
import OpportunityPage from "./opportunity_page/OpportunityPage";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import OpportunityImpactGraph from './OpportunityImpactGraph';

const OpportunityContainer = props => {
    const [viewType, setViewType] = useState(null);
    const [ctrHeight, setCtrHeight] = useState(0);
    const [tabValue, setTabValue] = useState('list');

    var init = useRef(true);

    const handleCtrResize = () => {
        try{
            let navbarHeight = document.getElementsByClassName('navbar navbar-expand-lg navbar-light sticky-top')[0].offsetHeight;
            setCtrHeight(window.innerHeight - navbarHeight);
            
        }catch(err){
            setCtrHeight(window.innerHeight);
        }
    }

    useEffect(() => {
        handleCtrResize();
        window.addEventListener('resize', handleCtrResize);

        if(init.current){
            init.current = false;

            if(window.location.pathname.startsWith('/sites')) setViewType('table');
            else setViewType('page');
        }

        return () => {
            window.removeEventListener('resize', handleCtrResize);
        }
        
    }, []);

    return(
        <div>
            {/* <OpportunityTable/> */}
            {
                viewType == null ? <></> :
                (
                    viewType === 'table' ? 
                    <>
                    <Box sx={{width: '100%', backgroundColor:'rgba(255,255,255,0.4)'}}>
                        <Tabs
                            value={tabValue}
                            onChange={(e, v) => setTabValue(v)}
                            sx={{
                                '& .MuiTabs-indicator': {
                                    backgroundColor: 'rgb(0,177,168)'
                                },
                                '& .MuiTab-textColorSecondary.Mui-selected': {
                                    color: 'rgb(0,177,168)'
                                },
                                '& .MuiTab-textColorSecondary': {
                                    color: 'rgb(8,114,182)'
                                },
                            }}
                            aria-label="secondary tabs example"
                        >
                            <Tab value="list" label="List" />
                            <Tab value="projects" label="Project Impacts" />
                        </Tabs>
                    </Box>
                    {
                        tabValue === 'list' ? 
                        <OpportunityTable hideDelete={false}/>
                        :
                        <OpportunityImpactGraph siteID={window.location.href.split('/').slice(-1)[0]} adminView={true}/>
                    }
                    </>
                    :
                    // else opportunity page
                    <div style={{padding:'2vh 2vw 0 2vw', minHeight:`${ctrHeight}px`, maxHeight:'fit-content', backgroundColor:'#f5f5f5', overflowY:'hidden'}}>
                        <OpportunityPage ctrHeight={ctrHeight}/>
                    </div>
                )
            }
        </div>
    );
}

export default OpportunityContainer;