import React from 'react';
import { Legend, PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer } from 'recharts';

const data = [
    {
      "subject": "Water",
      "2022": 10,
      "2023": 30,
      "2024": 25,
      "fullMark": 150
    },
    {
      "subject": "Electricity",
      "2022": 50,
      "2023": 45,
      "2024": 25,
      "fullMark": 150
    },
    {
      "subject": "Thermal",
      "2022": 35,
      "2023": 25,
      "2024": 48,
      "fullMark": 150
    },
    {
      "subject": "Other",
      "2022": 5,
      "2023": 24,
      "2024": 10,
      "fullMark": 150
    },
  ]

const RadarSpendingChart = props => {
    return(
        <ResponsiveContainer height='90%' width='100%'>
            <RadarChart outerRadius={100} width={730} height={250} data={data}>
              <PolarGrid />
              <PolarAngleAxis dataKey="subject" />
              <PolarRadiusAxis angle={40} domain={[0, 50]} />
              <Radar name="2022" dataKey="2022" stroke="#8884d8" fill="rgb(8,114,182)" fillOpacity={0.6} />
              <Radar name="2023" dataKey="2023" stroke="#82ca9d" fill="rgb(0,177,168)" fillOpacity={0.6} />
              <Radar name="2024" dataKey="2024" stroke="rgb(213,43,0)" fill="rgba(213,43,0, 0.85)" fillOpacity={0.6} />
              <Legend />
            </RadarChart>
        </ResponsiveContainer>
    )
}

export default RadarSpendingChart;