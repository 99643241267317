import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const ReportModal = props => {
    const [timeframe, setTimeframe] = useState('last30d');

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [dateError, setDateError] = useState('');

    const generateQueryParams =  () => {
        let start = 0;
        let end = Date.now();

        if(timeframe === 'last30d'){
            start = end - 30 * 24 * 60 * 60 * 1000;

        }else if(timeframe === 'last90d'){
            start = end - 90 * 24 * 60 * 60 * 1000;

        }else if(timeframe === 'ytd'){
            let currentYear = new Date().getUTCFullYear();
            let janFirst = new Date(Date.UTC(currentYear, 0, 1, 0, 0, 0));

            start = janFirst.getTime();

        }else if(timeframe === 'calmonth'){
            let now = new Date();

            let currentYear = now.getUTCFullYear();
            let currentMonth = now.getUTCMonth();

            let firstDayOfMonth = new Date(Date.UTC(currentYear, currentMonth, 1, 0, 0, 0));

            start = firstDayOfMonth.getTime();
            
        }else if(timeframe === 'calq'){
            let now = new Date();

            let currentYear = now.getUTCFullYear();

            let currentMonth = now.getUTCMonth();
            let firstMonthOfQuarter = 3 * Math.floor(currentMonth / 3);

            let firstDayOfQuarter = new Date(Date.UTC(currentYear, firstMonthOfQuarter, 1, 0, 0, 0));

            start = firstDayOfQuarter.getTime();
            
        }else if(timeframe === 'alltime'){
            //keep at 0
        }else if(timeframe === 'custom'){
            return `?start=${new Date(startDate).getTime()}&end=${new Date(endDate).getTime()}`;
        }

        return `?start=${start}&end=${Date.now()}`;
    }

    return(
        <Modal
            show={props.reportModalOpen}
            onHide={() => props.setReportModalOpen(false)}
            backdrop="static"
            centered
        >
            <Modal.Header closeButton>
                <h4>Site utility report</h4>
            </Modal.Header>

            <Modal.Body style={{overflow:'hidden'}}>
                
                <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">Generate report for timeframe:</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                    >
                        <FormControlLabel onClick={() => setTimeframe('last30d')} control={<Radio checked={timeframe==='last30d'}/>} label="Last 30 days"/>
                        <FormControlLabel onClick={() => setTimeframe('last90d')} control={<Radio checked={timeframe==='last90d'}/>} label="Last 90 days"/>
                        <FormControlLabel onClick={() => setTimeframe('ytd')} control={<Radio checked={timeframe==='ytd'}/>} label="Calendar year to date"/>
                        <FormControlLabel onClick={() => setTimeframe('calmonth')} control={<Radio checked={timeframe==='calmonth'}/>} label="This month (calendar)"/>
                        <FormControlLabel onClick={() => setTimeframe('calq')} control={<Radio checked={timeframe==='calq'}/>} label="This quarter (calendar)"/>
                        <FormControlLabel onClick={() => setTimeframe('alltime')} control={<Radio checked={timeframe==='alltime'}/>} label="Since site added to RMIS"/>
                        <FormControlLabel onClick={() => setTimeframe('custom')} control={<Radio checked={timeframe==='custom'}/>} label="Custom"/>
                    </RadioGroup>
                </FormControl>

                {
                    timeframe === 'custom' &&
                    <>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                            <DatePicker
                            label="Start"
                            value={startDate}
                            onChange={newValue => setStartDate(newValue)}
                            />
                            <DatePicker
                            label="End"
                            value={endDate}
                            onChange={newValue => setEndDate(newValue)}
                            />
                        </DemoContainer>
                    </LocalizationProvider>

                    {dateError !== '' && <p style={{margin:'0', color:'red'}}>{dateError}</p>}
                    </>
                }

            </Modal.Body>

            <Modal.Footer>
                <Button style={{border:'none',  backgroundColor:'rgb(8,114,182)'}} onClick={() => {
                    if(timeframe === 'custom'){
                        if(startDate == null || endDate == null){
                            setDateError("Missing start and/or end date");
                            return;
                        }else if(new Date(startDate['$d']).getTime() >= new Date(endDate['$d']).getTime()){
                            setDateError("Start must be earlier than end date");
                            return;
                        }else if(new Date(endDate['$d']).getTime() > Date.now()){
                            setDateError("End date is in the future");
                            return;
                        }else{
                            setDateError('');
                        }
                    }

                    props.setReportModalOpen(false);
                    window.open(`/report/${window.location.href.split('/').slice(-1)}${generateQueryParams()}`, '_blank');
                }}>Generate</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ReportModal;