import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const InventorySummary = props => {
    const [msrpCosts, setMSRPCosts] = useState({
        hardware: 0,
        subscriptions: 0
    });

    const [esCosts, setESCosts] = useState({
        hardware: 0,
        subscriptions: 0
    });

    const [profitMargins, setProfitMargins] = useState({
        hardware: 0,
        subscriptions: 0
    })

    useEffect(() => {
        let msrpCost = {
            hardware: 0,
            subscriptions: 0
        };

        let esCost = {
            hardware: 0,
            subscriptions: 0
        };

        for(var key in props.equipmentItems){
            if(props.equipmentItems[key].selected){
                msrpCost[props.equipmentItems[key].cost_type.startsWith('cap_cost') ? 'hardware' : 'subscriptions'] += props.equipmentItems[key].msrp_cad * props.equipmentItems[key].quantity;
                esCost[props.equipmentItems[key].cost_type.startsWith('cap_cost') ? 'hardware' : 'subscriptions'] += props.equipmentItems[key].msrp_cad * props.equipmentItems[key].quantity * (1 - props.equipmentItems[key].discount);
            }
        }

        setMSRPCosts(msrpCost);
        setESCosts(esCost);
        setProfitMargins({
            hardware: (msrpCost.hardware - esCost.hardware) / msrpCost.hardware,
            subscriptions: (msrpCost.subscriptions - esCost.subscriptions) / msrpCost.subscriptions,
        })
    }, [props.equipmentItems]);

    return(
        <TableContainer style={{backgroundColor:'white', boxShadow:'2px 6px 12px rgb(164, 164, 164)', width:'35%'}}>
            <Table sx={{
                    [`& .${tableCellClasses.root}`]: {
                    borderBottom: "none"
                    }
            }} aria-label="simple table">
                <TableHead>
                    <TableCell style={{backgroundColor:'#05B2A9', color:'white'}}></TableCell>
                    <TableCell align='center'><strong>MSRP</strong></TableCell>
                    {props.isDetailedView && <TableCell align='center'><strong>E-S Price</strong></TableCell>}
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell style={{backgroundColor:'#05B2A9', color:'white'}}><strong>Hardware cost</strong></TableCell>
                        <TableCell align='right'>
                            ${
                                (Math.round(parseFloat(msrpCosts.hardware) * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                        </TableCell>
                        {props.isDetailedView && <TableCell align='right'>
                            ${
                                (Math.round(parseFloat(esCosts.hardware) * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                        </TableCell>}
                    </TableRow>
                    <TableRow>
                        <TableCell style={{backgroundColor:'#05B2A9', color:'white'}}><strong>Subscriptions</strong></TableCell>
                        <TableCell align='right'>
                            ${
                                (Math.round(parseFloat(msrpCosts.subscriptions) * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }/year
                        </TableCell>
                        {props.isDetailedView && <TableCell align='right'>
                            ${
                                (Math.round(parseFloat(esCosts.subscriptions) * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }/year
                        </TableCell>}
                    </TableRow>
                    <TableRow>
                        <TableCell style={{backgroundColor:'#05B2A9', color:'white'}}><strong>Total cost</strong></TableCell>
                        <TableCell align='right'>
                            {
                                `$${(Math.round(parseFloat(msrpCosts.hardware) * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} hardware + $${(Math.round(parseFloat(msrpCosts.subscriptions) * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/year`
                            }
                        </TableCell>
                        {props.isDetailedView && <TableCell align='right'>
                            {
                                `$${(Math.round(parseFloat(esCosts.hardware) * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} hardware + $${(Math.round(parseFloat(esCosts.subscriptions) * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/year`
                            }
                        </TableCell>}
                    </TableRow>
                    {/* <TableRow>
                        <TableCell style={{backgroundColor:'#05B2A9', color:'white'}}><strong>Profit margins</strong></TableCell>
                        <TableCell align='center' colSpan={2}>
                            {
                                `$${(Math.round(parseFloat(msrpCosts.hardware - esCosts.hardware) * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} hardware (${(Math.round(parseFloat(profitMargins.hardware * 100) * 10) / 10).toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%) | $${(Math.round(parseFloat(msrpCosts.subscriptions - esCosts.subscriptions) * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/year (${(Math.round(parseFloat(profitMargins.subscriptions * 100) * 10) / 10).toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%)`
                            }
                        </TableCell>
                    </TableRow> */}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default InventorySummary;