import React, {useState, useRef, useEffect} from 'react';
import {Table, Button} from 'react-bootstrap';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { IconButton, Box } from '@mui/material';
import FlowieModal from './FlowieModal'
import useAxiosProtect from '../../../../../../hooks/useAxiosProtect';
import Form from 'react-bootstrap/Form';

const FlowieComponent = props => {
    const axiosProtect = useAxiosProtect();
    var init = useRef(true);

    const [siteID, setSiteID] = useState('');
    const [flowies, setFlowies] = useState('');
    const [modalState, setModalState] = useState(undefined);

    // Modal delete a row
    const [delRow, setDelRow] = useState('');
    // Modal edit a row
    const [editRow, setEdRow] = useState('');
    // Modal set flowie data
    const [flowieModalData, setFlowieModalData] = useState(null)

    // for date values in table:
    const timestampFormat = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "America/New_York",
        timeZoneName: "short",
    };

    // Change modal state
    const toggleModalState = newState => {
        setModalState(newState)
    }

    useEffect(() => {
        const controller = new AbortController();
        let site_id = parseInt(window.location.href.split('/').slice(-1))
        if (site_id) {
            // Set the site id
            setSiteID(site_id)
        }
        const getFlowies = async () => {
            try {
                const response = await axiosProtect.get(`/sensors/alertLabs?site_id=${site_id}`, {
                //const response = await axiosProtect.get(`/u/utilities/metrics?site_id=${site_id}`, {
                    signal:  controller.signal
                });

                setFlowies(response.data.flowies);
                props.updateFlowieCount(true, response.data.flowies.length);

                // Check if utility_readings is empty, and if not -> continue

            } catch(err) {
                console.log(err)
            }
        }

        if(init.current){
            init.current = false;
            getFlowies()
        }
    
    }, []);


    const setDeleteRow = (flowie_id, name,) => {
        setDelRow({
            flowie_id,
            name
        })
    }
    // Remove the row after deleting the row
    const removeRow = id => {

        let tempArray = [...flowies];

        for(let i = 0;i<tempArray.length;++i){
            if(tempArray[i].id === id){
                tempArray.splice(i, 1);
                break;
            }
        }

        props.updateFlowieCount(false, 1);
        setFlowies(tempArray)
    }
    // Pass variables to edit function
    const setEditRow = (name, flowie_name, flowie_id) => {
        setEdRow({
            name,
            flowie_name,
            flowie_id
        });
        
        // Toggle modal state after setting edit row
        toggleModalState('edit');
    }

    const toggleFlowieStatus = async flowie_id => {
        // Toggle the status of the flowie on/off
        try{
            const response = await axiosProtect.post('/sensors/alertLabs/toggle', {
                flowie_id: flowie_id,
            });

            if(response.status === 200){
                let temp = [...flowies];

                for(let i = 0;i<flowies.length;++i){
                    if(flowies[i].id === flowie_id){
                        temp[i].active = !temp[i].active
                    }
                }

                setFlowies(temp);
            }
        }catch(err){
            console.log(err)
        }

        toggleModalState(undefined);
        setFlowieModalData(null);
    }
    

    return(
        <>
            <Box textAlign='center'>
                <Button onClick={() => toggleModalState('add')} variant='outline-dark' style={{marginBottom:'2%'}}>
                    <strong>Add Flowie</strong>
                </Button>
            </Box>
            <Table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Flowie Name</th>
                        {/* <th>Active Status</th> */}
                        {/* <th>Last Sync</th> */}
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {flowies ? flowies.map((flowie, i) => (
                        <tr key={flowies[i].flowie_name}>
                            <td>{flowies[i].name}</td>
                            <td>
                                <Form.Check
                                    type="switch"
                                    id={`flowie-switch-${i}`}
                                    className='centrica-table-switch'
                                    onClick={() => {
                                        if(flowie.active){
                                            setFlowieModalData(flowie);
                                            setModalState('toggle');
                                        }else{
                                            toggleFlowieStatus(flowie.id);
                                        }
                                    }}
                                    label={flowie.active ? 'active' : 'disabled'}
                                    checked={flowie.active}
                                />
                            </td>
                            <td>{flowies[i].flowie_name}</td>
                            {/* <td>{flowies[i].last_sync ? new Date(flowies[i].last_sync).toLocaleString("en-US", timestampFormat) : "N/A"}</td> */}
                            <td>
                                <IconButton onClick={() => {setFlowieModalData(flowie); setModalState('delete')}}>
                                    <DeleteTwoToneIcon style={{fill: "red"}}/>
                                </IconButton>
                                <IconButton>
                                    <EditTwoToneIcon onClick={() => {setFlowieModalData(flowie); setModalState('edit')}}/>
                                </IconButton>
                            </td>
                        </tr>
                        )
                    ) : <></>}
                </tbody>
            </Table>

            <FlowieModal modalState={modalState} toggleModalState={toggleModalState} flowies={flowies} setFlowies={setFlowies} removeRow={removeRow} delRow={delRow} editRow={editRow} flowieModalData={flowieModalData} setFlowieModalData={setFlowieModalData} toggleFlowieStatus={toggleFlowieStatus}/>
        </>
    );
}


export default FlowieComponent;