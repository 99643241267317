import React, { useState } from 'react';
import EmissionTrendGraph from './EmissionTrendGraph';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { scope12Emissions, scope12IntensityEmissions, scope12IntensityReductions, scope12Reductions, financialSavings, financialSavingsProd, scope123Emissions, scope123Reductions, financialSavingsScope123, financialSavingsScope123Intensity, financialSavingsScope123Reductions, scope123Intensity, scope123IntensityReductions } from './testData';

const EmissionTrends = props => {
    const [showScope3, setShowScope3] = useState(false);

    return(
        <>
            <div style={{width:'100%'}}>
                <FormGroup style={{paddingLeft:'0.5%'}}>
                    <FormControlLabel style={{width:'fit-content'}} control={
                            <Switch style={{color:'rgb(8,114,182)'}} checked={showScope3} onClick={() => setShowScope3(!showScope3)}/>
                        } label={<p style={{margin:'0', fontSize:'95%'}}>Include Scope 3 emissions</p>} />
                </FormGroup>

                <div style={{display:'flex', flexDirection: 'row', padding:'0.25% 0.5%'}}>

                    <div style={{flex:'50', height:'40vh', backgroundColor:'white', borderRadius:'1%', boxShadow: '2px 6px 12px rgb(164, 164, 164)', padding:'0.5%'}}>
                        <EmissionTrendGraph title={`Scope ${showScope3 ? '1, 2, and 3' : '1 and 2'} GHG Emissions`} subTitle='' 
                            data={showScope3 ? scope123Emissions : scope12Emissions} pct={22.5} isFinancial={false} yAxisLabel='tonnes CO2eq/year' showScope3={showScope3}/>
                    </div>

                    <div style={{flex:'1'}}></div>


                    <div style={{flex:'50', height:'40vh', backgroundColor:'white', borderRadius:'1%', boxShadow: '2px 6px 12px rgb(164, 164, 164)', padding:'0.5%'}}>
                        <EmissionTrendGraph title={`Scope ${showScope3 ? '1, 2, and 3' : '1 and 2'} GHG Emission Intensity`} subTitle='(Normalized by production)' 
                        data={showScope3 ? scope123Intensity : scope12IntensityEmissions} pct={22.5} isFinancial={false} yAxisLabel='tonnes CO2eq/tonne product' showScope3={showScope3}/>
                    </div>

                    <div style={{flex:'1'}}></div>

                    <div style={{flex:'50', height:'40vh', backgroundColor:'white', borderRadius:'1%', boxShadow: '2px 6px 12px rgb(164, 164, 164)', padding:'0.5%'}}>
                        <EmissionTrendGraph title={`Financial Savings (Scope ${showScope3 ? '1, 2, and 3' : '1 and 2'} Projects)`} subTitle='' 
                            data={showScope3 ? financialSavingsScope123 : financialSavings} pct={22.5} isFinancial={true} yAxisLabel='$/year' showScope3={showScope3}/>
                    </div>

                </div>

                <div style={{display:'flex', flexDirection: 'row', padding:'0.25% 0.5%'}}>

                    <div style={{flex:'50', height:'40vh', backgroundColor:'white', borderRadius:'1%', boxShadow: '2px 6px 12px rgb(164, 164, 164)', padding:'0.5%'}}>
                        <EmissionTrendGraph title={`Scope ${showScope3 ? '1, 2, and 3' : '1 and 2'} GHG Emission Reductions`} subTitle='' 
                        data={showScope3 ? scope123Reductions : scope12Reductions} pct={22.5} isFinancial={false} yAxisLabel='tonnes CO2eq/year' showScope3={showScope3}/>
                    </div>

                    <div style={{flex:'1'}}></div>

                    <div style={{flex:'50', height:'40vh', backgroundColor:'white', borderRadius:'1%', boxShadow: '2px 6px 12px rgb(164, 164, 164)', padding:'0.5%'}}>
                        <EmissionTrendGraph title={`Scope ${showScope3 ? '1, 2, and 3' : '1 and 2'} GHG Emission Intensity Reductions`} subTitle='(Normalized by production)' 
                            data={showScope3 ? scope123IntensityReductions : scope12IntensityReductions} pct={22.5} isFinancial={false} yAxisLabel='tonnes CO2eq/tonne product' showScope3={showScope3}/>
                    </div>

                    <div style={{flex:'1'}}></div>

                    <div style={{flex:'50', height:'40vh', backgroundColor:'white', borderRadius:'1%', boxShadow: '2px 6px 12px rgb(164, 164, 164)', padding:'0.5%'}}>
                        <EmissionTrendGraph title={`Financial Savings (Scope ${showScope3 ? '1, 2, and 3' : '1 and 2'} Projects)`} subTitle='(Normalized by production)' 
                            data={showScope3 ? financialSavingsScope123Reductions : financialSavingsProd} pct={22.5} isFinancial={true} yAxisLabel='$/tonne product' showScope3={showScope3}/>
                    </div>
                </div>
            </div>

            {/* <div style={{display:'flex', flexDirection:'row'}}>
                <div style={{flex:'50', height:'42vh', backgroundColor:'white', padding:'1vh 1vw', borderRadius:'1%', boxShadow:"2px 6px 12px rgb(164, 164, 164)"}}>
                    <h4>Carbon Reduction Progress</h4>
                    <TargetEmissions parameter='Water' value={1827 * 3} units='m3' graphColor='rgb(8,114,182)' graphId='waterPv' pctChange={4} events={0}graphColor2='rgb(213,43,0)'/>
                </div>

                <div style={{flex:'1'}}></div>

                <div style={{flex:'40', height:'42vh', backgroundColor:'white', padding:'1vh 1vw', borderRadius:'1%', boxShadow:"2px 6px 12px rgb(164, 164, 164)"}}>
                    <h4>Emissions by Component</h4>
                    <UtilityEmissions/>
                </div>
            </div>

            <Divider style={{margin:'1.75vh 0 1.75vh 0'}}/>

            <div style={{display:'flex', flexWrap:'wrap', width:'100%'}}>
                <div style={{height:'42vh', width:'49.25%'}}>
                    <OppEmissions/>
                </div>

                <div style={{width:'1.5%'}}></div>

                <div style={{height:'42vh', width:'49.25%'}}>
                    <OppSavings/>
                </div>
            </div> */}
        </>
    );
}

export default EmissionTrends;