import React, { useEffect, useRef, useState } from 'react';
import ParameterTable from './ParameterTable';
import ValveTable from './ValveTable';
import Button from '@mui/material/Button';
import SettingsModal from './SettingsModal';
import useAxiosProtect from '../../../hooks/useAxiosProtect';

const BlueRoofSitePage = props => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var init = useRef(true);

    const [valves, setValves] = useState([]);
    const [parameters, setParameters] = useState([]);
    const [settingsModalOpen, setSettingsModalOpen] = useState(false);

    const axiosProtect = useAxiosProtect();

    useEffect(() => {
        if(!init.current) return;
        init.current = false;

        axiosProtect.get(`/asbr/blueroof/site-data?site_id=${window.location.href.split('/').slice(-1)[0]}`).then(response => {
            setValves(response.data.asbrData.valves == null ? [] : response.data.asbrData.valves.sort((a, b) => a.valve_name.localeCompare(b.valve_name)))
            setParameters(response.data.asbrData.parameters == null ? [] : response.data.asbrData.parameters.sort((a, b) => a.screen_name.localeCompare(b.screen_name)))
        }).catch(err => {});

    }, []);

    const addValve = async valve => {
        try{
            let response = await axiosProtect.post('/asbr/blueroof/valve', {
                site_id: parseInt(window.location.href.split('/').slice(-1)[0]),
                valve_name: valve.name,
                is_open: valve.currentState === 'open'
            });
            
            let allValves = [...valves, response.data.newValve];
            allValves.sort((a, b) => a.valve_name.localeCompare(b.valve_name));
            setValves(allValves);

            return response.data.newValve;
        }catch(err){
            return null;
        }
    }

    const deleteValve = async valve => {

    }

    const addParameter = async param => {
        try{
            let response = await axiosProtect.post('/asbr/blueroof/field', {
                site_id: parseInt(window.location.href.split('/').slice(-1)[0]),
                screen_name: param.screenName,
                field_name: param.fieldName,
                units: param.units
            });
            
            let allParams = [...parameters, response.data.newField];
            allParams.sort((a, b) => a.screen_name.localeCompare(b.screen_name));
            setParameters(allParams);

            return response.data.newField;
        }catch(err){
            return null;
        }
    }

    const deleteParameter = async param => {

    }

    const formatDate = dateString => {
        const date = new Date(dateString);

        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const monthIndex = date.getMonth();
        const year = date.getFullYear();

        const month = monthNames[monthIndex];
        return `${hours}:${minutes} ${day} ${month} ${year}`;
    }

    return (
        <div style={{height:'100%', width:'100%'}}>
            {
                settingsModalOpen && 
                <SettingsModal 
                    settingsModalOpen={settingsModalOpen} setSettingsModalOpen={setSettingsModalOpen}
                    valves={valves} parameters={parameters}
                    addValve={addValve} deleteValve={deleteValve}
                    addParameter={addParameter} deleteParameter={deleteParameter}
                    formatDate={formatDate}
                />
            }

            <div style={{height:'4%'}}>
                <Button style={{height:'fit-content', padding:'1px 4px'}} onClick={() => setSettingsModalOpen(true)}>Settings</Button>
            </div>

            <div style={{display:'flex', flexDirection:'row', height:'47%'}}>
                <div style={{flex:'3.5', backgroundColor:'white', borderRadius:'10px', boxShadow:'2px 6px 12px rgb(164, 164, 164)'}}>
                    Schematic coming soon
                </div>
                
                <div style={{width:'1vw'}}></div>
                
                <div style={{flex:'5', backgroundColor:'white', borderRadius:'10px', boxShadow:'2px 6px 12px rgb(164, 164, 164)'}}>
                    Graph coming soon
                </div>
            </div>

            <div style={{height:'2%'}}></div>

            <div style={{display:'flex', flexDirection:'row', height:'47%'}}>
                <div style={{flex:'3.5', backgroundColor:'white', borderRadius:'10px', boxShadow:'2px 6px 12px rgb(164, 164, 164)', overflowY:'scroll', scrollbarWidth: 'none', msOverflowStyle: 'none'}}>
                    <ValveTable valves={valves} formatDate={formatDate}/>
                </div>

                <div style={{width:'1vw'}}></div>

                <div style={{flex:'5', backgroundColor:'white', borderRadius:'10px', boxShadow:'2px 6px 12px rgb(164, 164, 164)', overflowY:'scroll', scrollbarWidth: 'none', msOverflowStyle: 'none'}}>
                    <ParameterTable parameters={parameters} formatDate={formatDate}/>
                </div>
            </div>
        </div>
    );
}

export default BlueRoofSitePage;