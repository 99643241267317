import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccordionActions from '@mui/material/AccordionActions';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PendingIcon from '@mui/icons-material/Pending';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ErrorIcon from '@mui/icons-material/Error';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import Typography from '@mui/material/Typography';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import LowPriorityIcon from '@mui/icons-material/LowPriority';

const ListView = props => {
    const statusIcons = {
        'complete': <TaskAltIcon sx={{color:'#14A44D'}}/>,
        'overdue': <ErrorIcon sx={{color:'#DC4C64'}}/>,
        'on hold': <StopCircleIcon sx={{color:'#E4A11B'}}/>,
        'in progress': <PendingIcon sx={{color:'#54B4D3'}}/>
    };

    const categoryTitles = {
        'task': 'Maintenance task',
        'deadline': 'Production deadline'
    };

    const priorityIcons = {
        'high': <PriorityHighIcon sx={{color:'rgb(213,43,0)'}}/>,
        'low': <LowPriorityIcon sx={{color:'rgb(8,114,182)'}}/>
    }

    const formatDate = timeString => {
        const date = new Date(timeString);

        const options = {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        };
      
        return date.toLocaleString('en-US', options);
    }

    return(
        <>
        {
            props.tasks.map((task, index) => {
                return(
                    <Accordion style={{width: (props.oppView || props.condensedView)  ? '100%' : '50vw', margin:'auto', /*backgroundColor: task.status === 'complete' ? 'rgba(0,175,180,0.1)' : task.status === 'overdue' ? 'rgba(213,43,0,0.1)' : 'rgba(247,230,83,0.1)'*/}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{borderTop:'1px solid #f7f7f7', boxShadow:'0 4px 4px -2px rgba(0,0,0,0.25)'}}
                        >
                            
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1} // Adjust spacing between the icon and text
                                sx={{ width: '46%', flexShrink: 0, overflow: 'hidden' }}
                            >
                                {(props.oppView || props.condensedView) ? <></> : task.category === 'task' ? <AssignmentIcon sx={{ color:'rgb(8,114,182)' }}/> : task.category === 'deadline' ? <AccessTimeIcon sx={{ color:'rgb(8,114,182)'}}/> : <BorderColorIcon sx={{ color:'rgb(8,114,182)'}}/>}
                                <Typography noWrap>
                                    {task.title}
                                </Typography>
                                {statusIcons[task.status]}
                                {
                                    task.priority != null &&
                                    priorityIcons[task.priority]
                                }
                            </Stack>
                            <Typography sx={{ color: 'text.secondary', marginLeft:'5%' }}>{formatDate(task.deadline)}</Typography>
                        </AccordionSummary>

                        <AccordionDetails style={{backgroundColor:'white'}}>
                            {
                                task.priority && 
                                <Typography>
                                    {task.priority === 'high' ? <strong style={{color:'rgb(213,43,0)'}}>High priority</strong> : <strong style={{color:'rgb(8,114,182)'}}>Low priority</strong>}
                                </Typography>
                            }
                            <Typography variant="subtitle1">
                                    {task.title} <EditIcon style={{color:'rgb(8,114,182)', cursor: 'pointer'}} onClick={() => {
                                        props.setTaskToEdit(task);
                                        props.setEditMode('task');
                                        props.setEditModalOpen(true);
                                    }}/>
                                </Typography>
                            <Typography variant="subtitle2" color={'grey'}>
                                {task.category === 'custom' ? task.custom_category : categoryTitles[task.category]}
                            </Typography>
                            <br/>
                            <Typography variant="body2">
                                {task.description}
                            </Typography>
                            <br/>
                            <Typography variant="body2" gutterBottom>
                                Status: {task.status}
                            </Typography>
                            {
                                (!props.oppView && task.opportunity_id) &&
                                <Typography variant="body2">
                                    <a href={`/opportunities/${task.opportunity_id}`} target='_blank'>This task is related to a conservation opportunity</a>
                                </Typography>
                            }
                        </AccordionDetails>

                        <AccordionActions style={{borderBottom:'1px solid #f7f7f7', backgroundColor:'#f6f6f6'}}>
                            <Box sx={{ flex: '1 1 auto' }}>
                                <Button color='error' onClick={() => {
                                    // props.deleteTask(task.id)
                                    props.setTaskToDelete(task);
                                    props.setDeleteModalOpen(true);
                                }}>Delete</Button>
                            </Box>
                            <Button onClick={() => {
                                props.setTaskToEdit(task);
                                props.setEditMode('status');
                                props.setEditModalOpen(true);
                            }}>
                                Change status
                            </Button>
                        </AccordionActions>
                    </Accordion>
                );
            })
        }
        </>
    )
}

export default ListView;