import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Modal from 'react-bootstrap/Modal';
import './styling/ModalStyle.css';

const EmissionsModal = props => {
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.showModal}
    >
      <Modal.Header closeButton onHide={props.closeModal}>
        <Modal.Title id="contained-modal-title-vcenter">
            CO<sub>2</sub>e Emission Factors
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{height:'40vh', overflowY:'scroll'}}>
        <Tabs
        defaultActiveKey="Ontario"
        id="uncontrolled-tab-example"
        className="mb-3"
        >
            <Tab eventKey="Ontario" title="Ontario">
                <div>
                    <h5 className="modal-text">Electricity</h5>
                    <p className="modal-text">0.043 kg CO<sub>2</sub>e/kWh</p>
                </div>
                
                <br/>

                <div>
                    <h5 className="modal-text">Natural Gas</h5>
                    <p className="modal-text">1.888 kg CO<sub>2</sub>e/m<sup>3</sup></p>
                </div>

                <br/>

                <div>
                    <h5 className="modal-text">Water Treatment & Distribution</h5>
                    <p className="modal-text">0.036 kg CO<sub>2</sub>e/m<sup>3</sup></p>
                </div>
            </Tab>

            <Tab eventKey="Gas" title="General Gas Factors">
                <p className="modal-text">Generally accepted emissions factors for various fossil fuels are listed below and can be used to approximate emissions 
                if a region's aggregate factor is unknown.</p>

                <br/>

                <p className="modal-text">These factors may vary between regions by accounting for delivery protocols and leakage rates within a jurisdiction's infrastructure.</p>

                <br/>

                <div style={{display:'flex', flexWrap:'wrap'}}>
                    <div style={{marginBottom:'3%'}}>
                        <h5 className="modal-text">Natural gas</h5>
                        <p className="modal-text">2.200 kg CO<sub>2</sub>e/m<sup>3</sup></p>
                        <p className="modal-text">35,300 BTU/m<sup>3</sup></p>
                    </div>
                
                    <div style={{width:'2vw'}}></div>

                    <div style={{marginBottom:'3%'}}>
                        <h5 className="modal-text">Propane</h5>
                        <p className="modal-text">1.560 kg CO<sub>2</sub>e/L</p>
                        <p className="modal-text">23,700 BTU/L</p>
                    </div>
                
                    <div style={{width:'2vw'}}></div>

                    <div style={{marginBottom:'3%'}}>
                        <h5 className="modal-text">Diesel</h5>
                        <p className="modal-text">2.700 kg CO<sub>2</sub>e/L</p>
                        <p className="modal-text">36,300 BTU/L</p>
                    </div>

                    <div style={{width:'2vw'}}></div>
                    
                    <div style={{marginBottom:'3%'}}>
                        <h5 className="modal-text">No.2 Heating Oil</h5>
                        <p className="modal-text">3.160 kg CO<sub>2</sub>e/L</p>
                        <p className="modal-text">36,500 BTU/L</p>
                    </div>
               
                    <div style={{width:'2vw'}}></div>

                    <div style={{marginBottom:'3%'}}>
                        <h5 className="modal-text">Gasoline</h5>
                        <p className="modal-text">2.300 kg CO<sub>2</sub>e/L</p>
                        <p className="modal-text">30,400 BTU/L</p>
                    </div>
                </div>
            </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
}

export default EmissionsModal;