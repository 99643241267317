import React, { useEffect, useRef, useState } from "react";
import useAxiosProtect from '../../../hooks/useAxiosProtect';
import AddCardIcon from '@mui/icons-material/AddCard';
import SiteModal from "./SiteModal";
import SiteCard from "../dashboard_components/sites/SiteCard";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import OpportunityTable from "../../opportunities/OpportunityTable";

const OrgManagementPage = props => {
    const axiosProtect = useAxiosProtect();

    const [sites, setSites] = useState([]);
    const [opps, setOpps] = useState(null);
    const [siteModalOpen, setSiteModalOpen] = useState(false);

    var init = useRef(true);

    useEffect(() => {
        if(init.current){
            init.current = false;

            const controller = new AbortController();

            const getSites = async () => {
                try{
                    let orgID = window.location.href.split('/')[window.location.href.split('/').length - 1];

                    const response = await axiosProtect.get(`/s/sites?org_id=${orgID}`, {
                        signal:  controller.signal
                    });
    
                    setSites([...response.data.sites]);

                    let opps = await axiosProtect.get(`/opp/opportunities/get-opps?org_id=${window.location.href.split('/').slice(-1)}`);
                    if(typeof opps.data.opportunities === 'object') setOpps(opps.data.opportunities);

                }catch(err) {
                    console.log(err)
                }
            }
    
            getSites();
        }
    }, []);

    const closeSiteModal = () => {
        setSiteModalOpen(false);
    }

    const addNewSite = site => {
        let temp = sites;
        temp.push(site);
        setSites(temp);
    }

    const deleteSite = async siteID => {
        const controller = new AbortController();

        const response = await axiosProtect.delete(`/s/sites?id=${siteID}`, {
            signal:  controller.signal
        });

        if(response.status === 200){
            let temp = [];

            for(let i = 0;i<sites.length;++i){
                if(sites[i].id === siteID) continue;
                temp.push(sites[i]);
            }

            setSites([...temp]);
        }
    }

    return (
        <div style={{padding:'2%', minHeight:'95vh'}} >
            {siteModalOpen && <SiteModal closeSiteModal={closeSiteModal} siteModalOpen={siteModalOpen} addNewSite={addNewSite}/>}

            <h4 className="primary">Sites<span style={{marginLeft:'1%', cursor:'pointer'}}
                onClick={() => {
                    setSiteModalOpen(true);
                }}><AddCardIcon/></span>
            </h4>

            <br/>

            <div style={{display:'flex', flexWrap:'wrap'}}>
                {sites.map((site, _) => (
                    <>
                        <SiteCard id={site.id} siteName={site.name} address={site.address} activeSensors={site.active_sensors} navRef={`/sites/${site.id}`}
                        imageURL={site.image} hasWater={site.has_water} hasElec={site.has_elec} hasGas={site.has_gas} hasProduct={site.has_product} hasSRCM={site.has_srcm}
                        sqft={site.sqft} inactiveSensors={site.disabled_sensors} deleteSite={deleteSite}/>
                    </>
                ))}
            </div>

            {
                opps != null &&
                <div>
                    <h4>Opportunities by site</h4>
                    {
                        Object.keys(opps).map(key => (
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                    <h4 style={{fontSize:'1.1rem'}}>{key}</h4>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <OpportunityTable presetOpps={opps[key]} hideDelete={true}/>
                                </AccordionDetails>
                            </Accordion>
                        ))
                    }
                </div>
            }
        </div>
    );
}

export default OrgManagementPage;