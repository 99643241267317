import React, { useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ASBRSiteModal from './ASBRSiteModal';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import useAxiosProtect from '../../../hooks/useAxiosProtect';

const ASBRSiteView = props => {
    const [sites, setSites] = useState([]);
    const [asbrModalOpen, setASBRModalOpen] = useState(false);
    const [modalState, setModalState] = useState('create');

    const [createForm, setCreateForm] = useState({
        name: '',
        nameError: '',
        address: '',
        addressError: '',
        latitude: '',
        latitudeError: '',
        longitude: '',
        longitudeError: ''
    });

    const [siteToDelete, setSiteToDelete] = useState(null);

    const axiosProtect = useAxiosProtect();

    var init = useRef(true);

    useEffect(() => {
        if(init.current){
            axiosProtect.get('/asbr/blueroof').then(response => {
                setSites(response.data.asbrSites);
            }).catch(err => {});
        }
    }, []);

    const validateCreateForm = () => {
        let formCopy = {...createForm};
        let isValid = true;

        if(formCopy.name === ''){
            isValid = false;
            formCopy.nameError = 'Required';
        }else formCopy.nameError = '';

        if(formCopy.address === ''){
            isValid = false;
            formCopy.addressError = 'Required';
        }else formCopy.addressError = '';

        let latValue = parseFloat(formCopy.latitude);

        if(formCopy.latitude === ''){
            isValid = false;
            formCopy.latitudeError = 'Required';

        }else if(isNaN(latValue)){
            isValid = false;
            formCopy.latitudeError = "Invalid input"

        }else if(latValue < -90 || latValue > 90){
            isValid = false;
            formCopy.latitudeError = 'Latitude range must be [-90, 90]';

        }else formCopy.latitudeError = '';

        let longValue = parseFloat(formCopy.longitude);

        if(formCopy.longitude === ''){
            isValid = false;
            formCopy.longitudeError = 'Required';

        }else if(isNaN(longValue)){
            isValid = false;
            formCopy.longitudeError = "Invalid input"

        }else if(longValue < -180 || longValue >= 180){
            isValid = false;
            formCopy.longitudeError = 'Longitude range must be [-180, 180)';

        }else formCopy.longitudeError = '';

        setCreateForm(formCopy);
        return isValid;
    }

    const submitSiteCreation = async () => {
        if(!validateCreateForm()) return;

        try{
            let response = await axiosProtect.post('asbr/blueroof', {
                name: createForm.name,
                address: createForm.address,
                latitude: parseFloat(createForm.latitude),
                longitude: parseFloat(createForm.longitude)
            });

            setSites(prev => [...prev, response.data.asbrSite]);

        }catch(err){}

        setASBRModalOpen(false);
        setCreateForm({
            name: '',
            nameError: '',
            address: '',
            addressError: '',
            latitude: '',
            latitudeError: '',
            longitude: '',
            longitudeError: ''
        });
    }

    const deleteSite = () => {
        axiosProtect.delete(`/asbr/blueroof?id=${siteToDelete.id}`).then(response => {
            let sitesCopy = new Array(sites.length - 1);
            let ctr = 0;

            for(let i = 0;i<sites.length;++i){
                if(sites[i].id === response.data.deletedSite.id) continue;
                sitesCopy[ctr++] = sites[i];
            }

            setSites(sitesCopy);
            
            setASBRModalOpen(false);
            setSiteToDelete(null);
        }).catch(err => {
            console.log(err);
            setASBRModalOpen(false);
            setSiteToDelete(null);
        });
    }

    return(
        <div>
            {asbrModalOpen && <ASBRSiteModal asbrModalOpen={asbrModalOpen} setASBRModalOpen={setASBRModalOpen} 
                modalState={modalState} siteToDelete={siteToDelete} createForm={createForm} setCreateForm={setCreateForm} 
                submitSiteCreation={submitSiteCreation} deleteSite={deleteSite}/>}

            <Button onClick={() => {
                setModalState('create');
                setASBRModalOpen(true);
            }}>Add site</Button>
            
            <br/>
            <br/>
            
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {
                    sites.map((site, i) => (
                        <div key={i} style={{display:'flex', flexDirection:'row'}}>
                            <ListItemButton sx={{height:'fit-content'}} onClick={() => window.location.href = `/blueroof/${site.id}`}>
                                <ListItemAvatar>
                                    <Avatar>
                                        <ImageIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={site.name} secondary={site.address} />
                            </ListItemButton>
                            
                            <div style={{width:'1%'}}></div>

                            <IconButton style={{width:'auto', height:'auto', backgroundColor:'transparent'}} onClick={() => {
                                setModalState('delete');
                                setSiteToDelete(site);
                                setASBRModalOpen(true);
                            }}>
                                <DeleteIcon style={{color:'red'}}/>
                            </IconButton>
                        </div>
                    ))
                }
            </List>
        </div>
    );
}

export default ASBRSiteView;
