import React, { useEffect, useRef, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import GasModal from './GasModal';
import useAxiosProtect from '../../../../../../hooks/useAxiosProtect';
import useAuth from '../../../../../../hooks/useAuth';
import jwtDecode from 'jwt-decode';
import { Form } from 'react-bootstrap';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { IconButton } from '@mui/material';

const GasRegistry = props => {
    const [showGasModal, setShowGasModal] = useState(false);
    const [modalType, setModalType] = useState('create');
    const [pendingGasSensorDelName, setPendingGasSensorDelName] = useState('');
    const [gasIDToDelete, setGasIDToDelete] = useState(null);
    var pendingToggleID = useRef(-1);

    const [gasUnits, setGasUnits] = useState([]);
    const siteID = window.location.href.split('/')[window.location.href.split('/').length - 1];

    const axiosProtect = useAxiosProtect();
    const {auth, persist} = useAuth();
    var init = useRef(true);

    useEffect(() => {
        if(!init.current) return;
        init.current = false;

        if(auth?.accessToken){
            let tokenObj = jwtDecode(auth.accessToken);
            
            axiosProtect.get(`/gsr/gas-sensors/get-sensors?org_id=${tokenObj.org_id}&siteID=${siteID}`).then(response => {
                setGasUnits(response.data.gas_sensors);
                props.updateGasUnitCount(true, response.data.gas_sensors.length);
            });
        }

    }, []);

    const closeModal = () => {
        setShowGasModal(false);
    }

    const addGasUnit = unit => {
        setGasUnits(prev => [...prev, unit]);
        props.updateGasUnitCount(true, 1);
    }

    const removeGasUnit = index => {
        setGasUnits(prev => prev.filter((_, i) => i !== index));
    }

    const resolveUnitType = unitType => {
        if(unitType === 'fluid_dt') return 'Fluid flow dT';
        return 'Gas meter';
    }

    const toggleGasSensorStatus = () => {
        axiosProtect.put(`/gsr/gas-sensors/toggle-sensor?sensor_id=${pendingToggleID.current}`).then(response => {
            let temp = [...gasUnits];

            for(let i = 0;i<temp.length;++i){
                console.log(temp[i])
                if(temp[i].id == pendingToggleID.current){
                    temp[i].active = !temp[i].active;
                    setGasUnits(temp);
                    setShowGasModal(false);
                    return;
                }
            }
        });

        setShowGasModal(false);
    }

    const handleCTDeletion = async id => {
        let response = await axiosProtect.delete(`/gsr/gas-sensors/delete-sensor?id=${id}`);

        if(response.data.deletedID != null){
            for(let i = 0;i<gasUnits.length;++i){
                if(gasUnits[i].id == id){
                    removeGasUnit(i);
                    props.updateGasUnitCount(false, 1)
                    break;
                }
            }
        }

        closeModal();
    }

    return(
        <div>
            {showGasModal && <GasModal showGasModal={showGasModal} setShowGasModal={setShowGasModal} addGasUnit={addGasUnit} 
            closeModal={closeModal} modalType={modalType} pendingGasSensorDelName={pendingGasSensorDelName} toggleGasSensorStatus={toggleGasSensorStatus}
            handleCTDeletion={handleCTDeletion} gasIDToDelete={gasIDToDelete}/>}

            <Button onClick={() => {
                setModalType('create');
                setShowGasModal(true);   
            }} variant='outline-dark' style={{marginBottom:'1.5%'}}>
                <strong>Add gas unit</strong>
            </Button>

            <Table>
                <thead>
                    <th>Name</th>
                    <th>Active</th>
                    <th>Type</th>
                    {/* <th>Last sync</th> */}
                    <th>Actions</th>
                </thead>

                <tbody>
                    {gasUnits.map((unit, i) => (
                        <tr key={`gas${i}`}>
                            <td>{unit.name}</td>
                            <td>
                                <Form.Check
                                    type="switch"
                                    id={`gas-sensor-active-switch-${i}`}
                                    className='gas-table-switch'
                                    onClick={() => {
                                        pendingToggleID.current = unit.id;
                                        if(unit.active){
                                            setModalType('toggle');
                                            setShowGasModal(true);
                                        }else{
                                            toggleGasSensorStatus();
                                        }
                                    }}
                                    label={unit.active ? 'active' : 'disabled'}
                                    checked={unit.active}
                                />
                            </td>
                            <td>{resolveUnitType(unit.unit_type)}</td>
                            {/* <td>{unit.last_sync ? '' : 'N/A'}</td> */}
                            <td>
                                <IconButton style={{height:'fit-parent', width:'auto'}} onClick={() => {
                                    setPendingGasSensorDelName(unit.name);
                                    setGasIDToDelete(unit.id);
                                    setModalType('delete');
                                    setShowGasModal(true);
                                }}>
                                    <DeleteTwoToneIcon style={{fill: "red"}}/>
                                </IconButton>
                            </td>
                        </tr>
                    ))}

                </tbody>
            </Table>
        </div>
    );
}

export default GasRegistry;