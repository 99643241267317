import React, { useEffect, useRef, useState} from "react";
import { Button } from "react-bootstrap";
import UtilityReadingsModal from './UtilityReadingsModal'
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { IconButton, Box } from '@mui/material';
import { DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,} from '@mui/x-data-grid';
import useAxiosProtect from "../../../hooks/useAxiosProtect";
import demand_units_list from '../../../example_data/dropdowns/clientModal/demand';
import gas_units_list from '../../../example_data/dropdowns/clientModal/gasUnits';
import currencies_list from '../../../example_data/dropdowns/clientModal/currencies';
import months_list from '../../../example_data/dropdowns/clientModal/months';
import UtilityGraph from "./UtiilityGraph";

const UtilityReadings = props => {
    var init = useRef(true);
    const axiosProtect = useAxiosProtect();

    // Edit and delete a row
    const [delRow, setDelRow] = useState('')
    const [editRow, setEdRow] = useState('')

    const [utilityReadings, setUtilityReadings] = useState('');
    const [siteID, setSiteID] = useState('');
    
    const [baselineData, setBaselineData] = useState(null);

    // Change modal state
    const [modalState, setModalState] = useState(undefined);
    const toggleModalState = newState => {
        setModalState(newState)
    }

    
    useEffect(() => {
        const controller = new AbortController();
        let site_id = parseInt(window.location.href.split('/').slice(-1))
        if (site_id) {
            // Set the site id
            setSiteID(site_id)
        }
        
        const getBaselineData = async () => {
            try{
                let response = await axiosProtect.get(`/u/utilities/metrics?site_id=${site_id}`);
                if(response.status === 200){
                    if(response.data.metrics.length === 0) return;
                    setBaselineData(response.data.metrics[0])
                }
            }catch(error){
                console.log(error)
            }
        }

        const getUtilityReadings = async () => {
            try {
                const response = await axiosProtect.get(`/u/utilities/utilities?site_id=${site_id}`, {
                    signal:  controller.signal
                });
                setUtilityReadings(response.data.utility_readings);

                await getBaselineData();

            } catch(err) {
                console.log(err)
            }
        }

        if(init.current){
            init.current = false;
            getUtilityReadings();
        }
    }, []);

    // Set the row to be deleted
    const setDeleteRow = (id, month, year) => {
        setDelRow({
            id,
            month,
            year,
        })
    }

    const removeRow = id => {
        let tempArray = [...utilityReadings];

        for(let i = 0;i<tempArray.length;++i){
            if(tempArray[i].id === id){
                tempArray.splice(i, 1);
                break;
            }
        }
        setUtilityReadings(tempArray)
    }

    const setEditRow = (id, month, year, cdd, hdd, wr, wuc, er, euc, dr, duc, dus, pf, gr, guc, gus, curr) => {
        setEdRow({
            id, month, year, cdd, hdd, wr, wuc, er, euc, dr, duc, dus, pf, gr, guc, gus, curr
        })
        
        // Toggle modal state after setting edit row
        toggleModalState('edit');
    }

    // Make demand units easier to understand
    const readableDemandUnits = (params) => {
        for (let i = 0; i < demand_units_list.length; i++) {
            if (demand_units_list[i][0] === params.row.demand_units) {
                return(demand_units_list[i][1]);
            }
        }
        return '';
    }

    // Make gas units easier to understand
    const readableGasUnits = (params) => {
        for (let i = 0; i < gas_units_list.length; i++) {
            if (gas_units_list[i][0] === params.row.gas_units) {
                return(gas_units_list[i][1]);
            }
        }
        return '';
    }

    // Make currencies easier to understand
    const readableCurrencies = (params) => {
        for (let i = 0; i < currencies_list.length; i++) {
            if (currencies_list[i][0] === params.row.currency) {
                return(currencies_list[i][1]);
            }
        }
        return '';
    }

    // Make months easier to understand
    const readableMonths = (params) => {
        for (let i = 0; i < months_list.length; i++) {
            if (months_list[i][0] === params.row.month) {
                return(months_list[i][1]);
            }
        }
        return '';
    }

    // Edit and delete buttons for each row 984447
    const renderButtons = (params) => {
        return (
            <span>
                <IconButton onClick={() => {toggleModalState('delete'); 
                    setDeleteRow(params.row.id, params.row.month, params.row.year);
                    }}>
                    <DeleteTwoToneIcon style={{fill:  '#A3333D'}}/>
                    
                </IconButton>
                <IconButton>
                    <EditTwoToneIcon onClick={() => {
                    setEditRow(params.row.id, params.row.month, params.row.year, params.row.cdd_baseline_18c, params.row.hdd_baseline_18c, params.row.water_reading_m3, params.row.water_unit_cost, params.row.elec_reading_kwh, params.row.elec_unit_cost, params.row.demand_reading, params.row.demand_unit_cost, params.row.demand_units, params.row.power_factor, params.row.gas_reading, params.row.gas_unit_cost, params.row.gas_units, params.row.currency)
                    }}/>
                </IconButton>
            </span>
        )
    }

    const columns = [
        {field: 'id', headerName: 'ID', hideable: false},
        {field: 'month',
            minWidth: 100,
            renderHeader: () => {return(<strong>Month</strong>)},
            renderCell: readableMonths,
            flex: 1,
        },
        {field: 'year',
            minWidth: 75,
            renderHeader: () => {return(<strong>Year</strong>)},
            flex: 1,
        },
        {field: 'cdd_baseline_18c',
            minWidth: 125,
            renderHeader: () => {return(<strong>CDD Baseline</strong>)},
            flex: 1,
        },
        {field: 'hdd_baseline_18c',
            minWidth: 125,
            renderHeader: () => {return(<strong>HDD Baseline</strong>)},
            flex: 1,
        },
        {field: 'water_reading_m3',
            minWidth: 155,
            renderHeader: () => {return(<strong>Water Reading (m<sup>3</sup>)</strong>)},
            flex: 1,
        },
        {field: 'water_unit_cost',
            minWidth: 140,
            renderHeader: () => {return(<strong>Water Unit Cost</strong>)},
            flex: 1,
        },
        {field: 'elec_reading_kwh',
            minWidth: 195,
            renderHeader: () => {return(<strong>Electricity Reading (kWh)</strong>)},
            flex: 1,
        },
        {field: 'elec_unit_cost',
            minWidth: 160,
            renderHeader: () => {return(<strong>Electricity Unit Cost</strong>)},
            flex: 1,
        },
        {field: 'demand_reading',
            minWidth: 140,
            renderHeader: () => {return(<strong>Demand Reading (kW)</strong>)},
            flex: 1,
        },
        {field: 'demand_unit_cost',
            minWidth: 150,
            renderHeader: () => {return(<strong>Demand Unit Cost</strong>)},
            flex: 1,
        },
        {field: 'demand_units',
            minWidth: 125,
            renderHeader: () => {return(<strong>Demand Units</strong>)},
            flex: 1,
            renderCell: readableDemandUnits,
        },
        {field: 'power_factor',
            minWidth: 115,
            renderHeader: () => {return(<strong>Power Factor</strong>)},
            flex: 1,
        },
        {field: 'gas_reading',
            minWidth: 115,
            renderHeader: () => {return(<strong>Gas Reading (m<sup>3</sup>)</strong>)},
            flex: 1,
        },
        {field: 'gas_unit_cost',
            minWidth: 125,
            renderHeader: () => {return(<strong>Gas Unit Cost</strong>)},
            flex: 1,
        },
        {field: 'gas_units',
            minWidth: 115,
            renderHeader: () => {return(<strong>Gas Units</strong>)},
            flex: 1,
            renderCell: readableGasUnits,
        },
        {field: 'currency',
            minWidth: 100,
            renderHeader: () => {return(<strong>Currency</strong>)},
            flex: 1,
            renderCell: readableCurrencies,
        },
        {field: 'buttons', 
            renderHeader: () => {return(<strong>Delete or Edit</strong>)},
            flex: 1,
            renderCell: renderButtons,
            disableClickEventBubbling: true,
            minWidth: 150,
        },
    ]

    const GridAddButton = () => {
        return (
            <Box textAlign='right'>
                <Button onClick={() => {toggleModalState("add")}} variant='outlined' style={{color:'#08b4ac', fontsize: 14}}>
                    <strong>ADD UTILITY READINGS</strong>
                </Button>
            </Box>
        );
    }

    function CustomToolbar() {
        return (
          <GridToolbarContainer style={{backgroundColor:'white'}}>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport />
            <GridAddButton />
          </GridToolbarContainer>
        );
      }

    return (
        <>
            {
                baselineData &&
                <div style={{width:'98%', height:'fit-content', backgroundColor:'white', margin:'2% auto auto auto', padding:'0.25% 0.5%', boxShadow:'2px 6px 12px rgb(164, 164, 164)', borderRadius:'1%'}}>
                    <UtilityGraph baselineData={baselineData} utilityReadings={utilityReadings} isReport={false} isWater={false} isElec={false} isGas={false}/>
                </div>
            }
            <div style={{margin: 20}}>
                <DataGrid
                    rows={utilityReadings}
                    columns={columns}
                    initialState={{
                        columns: {
                            columnVisibilityModel : {
                                // Hide columns status and traderName, the other columns will remain visible
                                id: false,
                                cdd_baseline_18c: false,
                                hdd_baseline_18c: false,
                                water_unit_cost: false,
                                elec_unit_cost: false,
                                demand_unit_cost: false,
                                demand_units: false,
                                power_factor: false,
                                gas_unit_cost: false,
                                gas_units: false,
                                currency: false,
                            }
                        }
                    }}
                    autoHeight={true}
                    hideFooterPagination
                    hideFooterSelectedRowCount
                    headerHeight={80} 
                    disableColumnMenu={true}
                    style={{boxShadow:'2px 6px 12px rgb(164, 164, 164)'}}
                    sx={{
                        '& .MuiDataGrid-columnHeaderTitle': {
                            textOverflow: "clip",
                            whiteSpace: "break-spaces",
                            lineHeight: 1,
                            color: "white"
                        },
                        '& .MuiDataGrid-columnHeader': {
                        backgroundColor: "white"
                        },
                        '& .MuiDataGrid-footerContainer': {
                            backgroundColor: "white"
                        },
                        '& .MuiDataGrid-columnSeparator': {
                            backgroundColor: "rgba(0, 0, 0, 0.12)"
                        },
                    }}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                />
            </div>            

            <UtilityReadingsModal modalState={modalState} toggleModalState={toggleModalState} delRow={delRow} removeRow={removeRow} siteID={siteID} setUtilityReadings={setUtilityReadings} editRow={editRow}/>
        </>
        
    );
}

export default UtilityReadings;