import React, { useEffect, useRef, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ESLogo from '../../../media/logo.png';

const OpportunityTablePage = props => {
    const [sortedOpps, setSortedOpps] = useState([]);
    var init = useRef(true);
    var currentUtility = '';

    useEffect(() => {
        if(!init.current) return;
        init.current = false;

        let oppCopy = [...props.opps];
        const order = { water: 1, electricity: 2, gas: 3 };

        oppCopy.sort((a, b) => {
            return order[a.category] - order[b.category];
        });

        setSortedOpps(oppCopy);
    }, []);

    const generateTableRow = opp => {
        let water = {
            savings: opp.water_savings_m3_year,
            percent: opp.water_savings_m3_year / props.metrics.total_water_m3,
            costSavings: opp.water_savings_m3_year * opp.water_unit_cost_m3,
            ghgSavingsTonnes: opp.water_savings_m3_year * opp.water_kg_co2_m3 / 1000
        }

        let elec = {
            savings: opp.electricity_savings_kwh_year,
            percent: opp.electricity_savings_kwh_year / props.metrics.total_elec_kwh,
            costSavings: opp.electricity_savings_kwh_year * opp.electricity_unit_cost_kwh,
            ghgSavingsTonnes: opp.electricity_savings_kwh_year * opp.electricity_kg_co2_kwh / 1000
        }

        let gas = {
            savings: opp.gas_savings_units_year,
            percent: opp.gas_savings_units_year / props.metrics.total_gas,
            costSavings: opp.gas_savings_units_year * opp.gas_unit_cost,
            ghgSavingsTonnes: opp.gas_savings_units_year * opp.gas_btu_per_unit * opp.gas_kg_co2_btu / 1000
        }

        return(
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell padding='none' colSpan={3}><p style={{margin:'0',fontSize:'50%', padding:'1%'}}>{opp.title}</p></TableCell>

                <TableCell align='center'><p style={{margin:'0',fontSize:'50%'}}>{water.savings === 0 ? '' : water.savings.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p></TableCell>
                <TableCell align='center'><p style={{margin:'0',fontSize:'50%'}}>{water.savings === 0 ? '' : `${Math.round(100 * 100 * water.percent) / 100}%`}</p></TableCell>
                <TableCell align='center'><p style={{margin:'0',fontSize:'50%'}}>{water.savings === 0 ? '' : `$${Math.round(water.costSavings).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</p></TableCell>

                <TableCell align='center' style={{backgroundColor:'#F3F8FB'}}><p style={{margin:'0',fontSize:'50%'}}>{elec.savings=== 0 ? '' : elec.savings.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p></TableCell>
                <TableCell align='center' style={{backgroundColor:'#F3F8FB'}}><p style={{margin:'0',fontSize:'50%'}}>{elec.savings === 0 ? '' : `${Math.round(100 * 10 * elec.percent) / 10}%`}</p></TableCell>
                <TableCell align='center' style={{backgroundColor:'#F3F8FB'}}><p style={{margin:'0',fontSize:'50%'}}>{elec.savings === 0 ? '' : `$${Math.round(elec.costSavings).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</p></TableCell>

                <TableCell align='center'><p style={{margin:'0',fontSize:'50%'}}>{gas.savings === 0 ? '' : gas.savings.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p></TableCell>
                <TableCell align='center'><p style={{margin:'0',fontSize:'50%'}}>{gas.savings === 0 ? '' : `${Math.round(100 * 100 * gas.percent) / 100}%`}</p></TableCell>
                <TableCell align='center'><p style={{margin:'0',fontSize:'50%'}}>{gas.savings === 0 ? '' : `$${Math.round(gas.costSavings).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</p></TableCell>

                <TableCell align='center' style={{backgroundColor:'#E7F8F7'}}><p style={{margin:'0',fontSize:'50%'}}>{`$${Math.round(water.costSavings + elec.costSavings + gas.costSavings).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</p></TableCell>
                <TableCell align='center'><p style={{margin:'0',fontSize:'50%'}}>{`${Number(Math.round(100 * (water.ghgSavingsTonnes + elec.ghgSavingsTonnes + gas.ghgSavingsTonnes)) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</p></TableCell>

                <TableCell align='center' style={{backgroundColor:'#E7F8F7'}}><p style={{margin:'0',fontSize:'50%'}}>{`$${Math.round(opp.project_cost).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</p></TableCell>
                <TableCell align='center'><p style={{margin:'0',fontSize:'50%'}}>{`${Number(Math.round(10 * opp.payback_years) / 10).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</p></TableCell>
            </TableRow>
        )
    }

    return(
        <div className="rmis-report-page" style={{height:'8.5in', width:'11in', margin:'auto', backgroundColor:'white', padding: '0.5in 0.25in'}} id='opportunity-table-page'>
            <div style={{height:'0.3in', width:'9.75in', position:'absolute', marginTop:'7.5in', marginLeft:'0.5in', display:'flex', flexDirection:'row'}}>
                <img src={ESLogo} style={{height:'100%', width:'auto'}}/>
                <div style={{flex:'10'}}></div>
                <p style={{margin:'0', flex:'1', fontSize:'0.9rem'}}>page {props.page}</p>
            </div>

            <TableContainer>
                <Table aria-label="collapsible table" size="small" 
                sx={{
                        [`& .${tableCellClasses.root}`]: {
                        borderBottom: "none"
                        }
                }}
                >
                    <TableHead style={{backgroundColor:'rgb(3,117,176)'}}>
                        <TableRow>
                            <TableCell style={{color:'white'}} colSpan={3}></TableCell>
                            <TableCell style={{color:'white', border:'1px solid white', borderWidth:'0 1px', fontSize:'0.6rem'}} align='center' colSpan={3}><strong>WATER</strong></TableCell>
                            <TableCell style={{color:'white', border:'1px solid white', borderWidth:'0 1px', fontSize:'0.6rem'}} align='center' colSpan={3}><strong>ELECTRICITY</strong></TableCell>
                            <TableCell style={{color:'white', border:'1px solid white', borderWidth:'0 1px', fontSize:'0.6rem'}} align='center' colSpan={3}><strong>NATURAL GAS</strong></TableCell>
                            <TableCell style={{color:'white', border:'1px solid white', borderWidth:'0 1px', fontSize:'0.6rem', backgroundColor:'#05B2A9'}} align='center'><strong>TOTAL SAVINGS</strong></TableCell>
                            <TableCell style={{color:'white', border:'1px solid white', borderWidth:'0 1px', fontSize:'0.6rem', backgroundColor:'#05B2A9'}} align='center'><strong>SCOPE 1 & 2 GHGs</strong></TableCell>
                            <TableCell style={{color:'white', border:'1px solid white', borderWidth:'0 1px', fontSize:'0.6rem', backgroundColor:'#05B2A9'}} align='center'><strong>PROJECT COST</strong></TableCell>
                            <TableCell style={{color:'white', border:'1px solid white', borderWidth:'0 1px 0 0', fontSize:'0.6rem', backgroundColor:'#05B2A9'}} align='center'><strong>PAYBACK</strong></TableCell>
                        </TableRow>
                        <TableRow style={{backgroundColor:'#4796C0'}}>
                            <TableCell style={{color:'white', fontSize:'0.5rem'}} padding='none' align='center' colSpan={3}>Opportunity</TableCell>
                            <TableCell style={{color:'white', border:'1px solid white', borderWidth:'0 1px', fontSize:'0.5rem'}} padding='none' align='center'><strong>m<sup>3</sup>/yr</strong></TableCell>
                            <TableCell style={{color:'white', border:'1px solid white', borderWidth:'0 1px', fontSize:'0.5rem'}} padding='none' align='center'><strong>%</strong></TableCell>
                            <TableCell style={{color:'white', border:'1px solid white', borderWidth:'0 1px', fontSize:'0.5rem'}} padding='none' align='center'><strong>$/yr</strong></TableCell>

                            <TableCell style={{color:'white', border:'1px solid white', borderWidth:'0 1px', fontSize:'0.5rem'}} padding='none' align='center'><strong>kWh/yr</strong></TableCell>
                            <TableCell style={{color:'white', border:'1px solid white', borderWidth:'0 1px', fontSize:'0.5rem'}} padding='none' align='center'><strong>%</strong></TableCell>
                            <TableCell style={{color:'white', border:'1px solid white', borderWidth:'0 1px', fontSize:'0.5rem'}} padding='none' align='center'><strong>$/yr</strong></TableCell>

                            <TableCell style={{color:'white', border:'1px solid white', borderWidth:'0 1px', fontSize:'0.5rem'}} padding='none' align='center'><strong>m<sup>3</sup>/yr</strong></TableCell>
                            <TableCell style={{color:'white', border:'1px solid white', borderWidth:'0 1px', fontSize:'0.5rem'}} padding='none' align='center'><strong>%</strong></TableCell>
                            <TableCell style={{color:'white', border:'1px solid white', borderWidth:'0 1px', fontSize:'0.5rem'}} padding='none' align='center'><strong>$/yr</strong></TableCell>

                            <TableCell style={{color:'white', border:'1px solid white', borderWidth:'0 1px', fontSize:'0.5rem', backgroundColor:'#72D5D0'}} padding='none' align='center'><strong>$/yr</strong></TableCell>
                            <TableCell style={{color:'white', border:'1px solid white', borderWidth:'0 1px', fontSize:'0.5rem', backgroundColor:'#72D5D0'}} padding='none' align='center'><strong>MT CO<sub>2</sub>e/yr</strong></TableCell>
                            <TableCell style={{color:'white', border:'1px solid white', borderWidth:'0 1px', fontSize:'0.5rem', backgroundColor:'#72D5D0'}} padding='none' align='center'><strong>$</strong></TableCell>
                            <TableCell style={{color:'white', border:'1px solid white', borderWidth:'0 1px', fontSize:'0.5rem', backgroundColor:'#72D5D0'}} padding='none' align='center'><strong>years</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            sortedOpps.map((opp, i) => {
                                if(currentUtility !== opp.category){
                                    currentUtility = opp.category;
                                    return(
                                        <>
                                        <TableRow>
                                            <TableCell colSpan={6} style={{margin:'0',fontSize:'75%', paddingLeft:'0'}}><span style={{color:'#05B2A9', fontWeight:'bold'}}>{currentUtility.charAt(0).toUpperCase() + currentUtility.slice(1).toLowerCase()}</span></TableCell>
                                            <TableCell colSpan={3} style={{backgroundColor:'#F3F8FB'}}></TableCell>
                                            <TableCell colSpan={3}></TableCell>
                                            <TableCell style={{backgroundColor:'#E7F8F7'}}></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell style={{backgroundColor:'#E7F8F7'}}></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                        {generateTableRow(opp)}
                                        </>
                                    )
                                }else{
                                    return generateTableRow(opp);
                                }
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default OpportunityTablePage;