import React, {useEffect, useState} from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ProgressBar from 'react-bootstrap/ProgressBar';
import './ProjectStyle.css';
import ProjectTable from './dashboard_components/ProjectTable';
import RadarSpendingChart from './dashboard_components/RadarSpendingChart';
import InfoPanel from './dashboard_components/InfoPanel';
import FundingBreakdownChart from './dashboard_components/FundingBreakdownChart';

const ProjectManagementContainer = props => {
    const [projectStats, setProjectStats] = useState({
        Water: {
            color: 'rgb(213,43,0)'
        },
        Electricity: {
            color: 'rgb(8,114,182)'
        },
        Thermal: {
            color:'rgb(0,177,168)'
        },
        Other: {
            color: 'rgb(78,79,83)'
        }
    });

    const [containerHeight, setContainerHeight] = useState('95vh');

    useEffect(() => {
        const updateContainerHeight = () => {
          let navbar = document.querySelector('.navbar');
          let breadcrumbNav = document.getElementsByClassName('col s12 es-breadcrumb')[0];

          let navbarHeight = navbar.offsetHeight + breadcrumbNav.offsetHeight;
          let windowHeight = window.innerHeight;
          let remainingHeight = windowHeight - navbarHeight;

          setContainerHeight(`${remainingHeight}px`);
        };
    
        updateContainerHeight();
    
        window.addEventListener('resize', updateContainerHeight);
    
        return () => {
          window.removeEventListener('resize', updateContainerHeight);
        };
      }, []);

    return(
        <div style={{padding:'0 2%', backgroundColor:'#f5f5f5', height: containerHeight}}>
            <div style={{height:'100%', display:'flex', flexDirection:'row'}}>
                <div style={{flex:'40', overflowY:'scroll', padding:'2% 1vw 0 1vw'}} className='hide-scrollbar'>
                    <div style={{display:'flex', flexDirection:'row', marginBottom:'4vh'}}>
                        {
                            Object.keys(projectStats).map((resource, i) => {
                                return (
                                    <>
                                    {i !== 0 && <div style={{flex:'1'}}></div>}

                                    <Card sx={{ flex:10, boxShadow:'2px 6px 12px rgb(164, 164, 164)' }}>
                                        <CardContent>
                                            <Typography gutterBottom variant="h4" sx={{m:0, color: projectStats[resource].color}} component="div">
                                                {resource}
                                            </Typography>
                                            <p style={{fontSize:'0.95rem'}}>projects</p>

                                            <div>
                                                <p style={{fontSize:'0.95rem', margin:'0'}}>{(resource === 'Other' || resource === 'Water') ? 'No projects' : resource === 'Thermal' ? '0/2 complete' : '2/3 complete'}</p>
                                                <ProgressBar id={`${resource}-bar`} variant='custom' now={(resource === 'Other' || resource === 'Water') ? 0 : resource === 'Thermal' ? 0 : 66.7}/>
                                            </div>
                                        </CardContent>
                                        <CardActions>
                                            {/* <Button size="small">Share</Button> */}
                                            {/* <Button size="small">Learn More</Button> */}
                                        </CardActions>
                                    </Card>
                                    </>
                                );
                            })
                        }
                    </div>

                    <div style={{marginBottom:'4vh'}}>
                        <ProjectTable/>
                    </div>

                    <div style={{display:'flex', flexDirection:'row', height:'42vh', width:'100%', marginBottom:'4vh'}}>
                        <div style={{flex:'11', backgroundColor:'white', boxShadow:'2px 6px 12px rgb(164, 164, 164)', borderRadius:'1%', padding:'1%'}}>
                            <h4 style={{margin:'0'}}>Project financing to date</h4>
                            <RadarSpendingChart/>
                        </div>

                        <div style={{flex:'1'}}></div>

                        <div style={{flex:'11', backgroundColor:'white', boxShadow:'2px 6px 12px rgb(164, 164, 164)', borderRadius:'1%', padding:'1%'}}>
                            <h4 style={{margin:'0'}}>Project cost breakdown</h4>
                            <small style={{margin:'0'}}>Percent of total implementation costs per utility</small>
                            <FundingBreakdownChart/>
                        </div>

                        {/* <div style={{flex:'1'}}></div>

                        <div style={{flex:'11', backgroundColor:'white', boxShadow:'2px 6px 12px rgb(164, 164, 164)', borderRadius:'1%', padding:'1%'}}>
                            <h4>Spending</h4>
                            <RadarSpendingChart/>

                        </div> */}

                        {/* <div style={{flex:'1'}}></div>

                        <div style={{flex:'8', backgroundColor:'white'}}>

                        </div> */}
                    </div>
                </div>

                <div style={{flex:'0.5', /*backgroundColor:'purple'*/}}></div>

                <div style={{flex:'11', padding:'2% 1vw 0 1vw'}}>
                    <InfoPanel/>
                </div>
            </div>
        </div>
    )
}

export default ProjectManagementContainer;