import React, {useEffect, useRef, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import './styling.css';
import useAxiosProtect from '../../../../../hooks/useAxiosProtect';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { Button } from 'react-bootstrap';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ArchiveIcon from '@mui/icons-material/Archive';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import Checkbox from '@mui/material/Checkbox';
import { IconButton, MenuItem, Select } from '@mui/material';
import useAuth from '../../../../../hooks/useAuth';

const AlertsModal = props => {
    const floatRegex = /^(-?\d*\.?\d*)?$/;
    const integerRegex = /^(\d*)$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    var init = useRef(true);
    const axiosProtect = useAxiosProtect();

    const [isLoading, setIsLoading] = useState(true);

    const [formFields, setFormFields] = useState({
        email: '',
        emailErr: '',
        autoEmail: false,
        sensor: '',
        criteria: 'average',
        averageCriteria: 'min',
        thresholdValue: '',
        thresholdErr: '',
        sampleDays: '',
        sampleErr: '',
        notifyOnce: false,
        objectiveCode: 'ops',
        objectiveText: '',
        objectiveErr: '',
        objectiveMaxTextLength: 64 
    });

    const [tabValue, setTabValue] = useState('create');

    const [activeAlerts, setActiveAlerts] = useState([]);
    const [archivedAlerts, setArchivedAlerts] = useState([]);
    const [alertPendingArchival, setAlertPendingArchival] = useState(-1);
    const [alertPendingDeletion, setAlertPendingDeletion] = useState(-1);

    const {auth} = useAuth();

    const [sensors, setSensors] = useState([]);

    useEffect(() => {
        if(!init.current) return;
        init.current = false;

        let siteID = window.location.href.split('/').slice(-1);

        axiosProtect.get(`/a/alerts/raw-data-alerts?site_id=${siteID}`).then(response => {
            let activeAlertsArray = response.data.alerts.filter(alert => !alert.archived);
            let archivedAlertsArray = response.data.alerts.filter(alert => alert.archived);

            activeAlertsArray.sort((a, b) => new Date(b.date_created) - new Date(a.date_created));
            archivedAlertsArray.sort((a, b) => new Date(b.archive_date) - new Date(a.archive_date));

            setActiveAlerts(activeAlertsArray);
            setArchivedAlerts(archivedAlertsArray);
        }).catch(error => {
            console.log(error);
        });

        axiosProtect.get(`/s/sites/get-site-sensors-by-sid?site_id=${siteID}`).then(response => {
            for(let sType in response.data.sensors){
                if(response.data.sensors[sType] == null) delete response.data.sensors[sType];
            }

            setSensors(response.data.sensors);

            let activeSensor = '';
            if(response.data.sensors.water.length > 0){
                activeSensor = `water-${response.data.sensors.water[0].id}`;
            }
            else if(response.data.sensors.electricity.length > 0){
                activeSensor = `electricity-${response.data.sensors.electricity[0].id}`;
            }else if(response.data.sensors.gas.length > 0){
                activeSensor = `gas-${response.data.sensors.gas[0].id}`;
            }

            setFormFields(prev => ({
                ...prev,
                sensor: activeSensor
            }))
        }).catch(err => {})
    }, []);

    const handleTabChange = (e, v) => {
        setTabValue(v);
    }

    const validateAlertForm = () => {
        let isValid = true;
        let tempForm = {...formFields};

        if(tempForm.email === ''){
            tempForm.emailErr = 'Required';
            isValid = false;
        }else if(!emailRegex.test(tempForm.email)){
            tempForm.emailErr = 'Invalid email address';
            isValid = false;
        }else tempForm.emailErr = '';

        if(tempForm.thresholdValue === ''){
            tempForm.thresholdErr = 'Required';
            isValid = false;
        }else if(parseFloat(tempForm.thresholdValue) < 0){
            tempForm.thresholdErr = 'Threshold value must be greater than or equal to 0.';
            isValid = false;
        }else tempForm.thresholdErr = '';

        if(tempForm.sampleDays === '' && tempForm.criteria === 'average'){
            tempForm.sampleErr = 'Required';
            isValid = false;
        }else if((parseInt(tempForm.sampleDays) < 1  && tempForm.criteria === 'average') || (parseInt(tempForm.sampleDays) > 14 && tempForm.criteria === 'average')){
            tempForm.sampleErr = 'Period minimum is 1 day, maximum 14 days.';
            isValid = false;
        }else tempForm.sampleErr = '';

        if(tempForm.objectiveCode === 'custom' && tempForm.objectiveText.trim() === ''){
            tempForm.objectiveErr = 'Required';
            isValid = false;
        }else tempForm.objectiveErr = '';

        setFormFields(tempForm);

        return isValid;
    }

    const createNewAlert = () => {
        if(!validateAlertForm()) return;

        let utility = formFields.sensor.split('-')[0];
        let sensorType = utility === 'water' ? 'flowies' : utility === 'electricity' ? 'centrica_ct' : 'gas_users';
        let sensorID = parseInt(formFields.sensor.split('-')[1]);

        let payload = {
            site_id: parseInt(window.location.href.split('/').slice(-1)),
            utility,
            threshold_value: parseFloat(formFields.thresholdValue),
            threshold_criteria: formFields.criteria,
            average_threshold: formFields.criteria === 'average' ? formFields.averageCriteria : null,
            sensor_id: sensorID,
            sensor_type: sensorType,
            notify_once: formFields.criteria === 'average' ? formFields.notifyOnce : true,
            email: formFields.email,
            creator_uid: auth.id,
            creator_email: auth.email,
            sample_days: formFields.criteria === 'average' ? parseInt(formFields.sampleDays) : null,
            objective_code: formFields.objectiveCode,
            objective_text: formFields.objectiveText.trim()
        }

        axiosProtect.post('/a/alerts/add-raw-data-alert', payload).then(res => {
            let newAlertArray = [...activeAlerts];

            let alertToAdd = res.data.newAlert;
            alertToAdd.name = res.data.sensorName;
            newAlertArray.unshift(res.data.newAlert);

            setActiveAlerts(newAlertArray);

            setTabValue('alerts');

            setFormFields({
                email: '',
                emailErr: '',
                autoEmail: false,
                sensor: '',
                criteria: 'average',
                averageCriteria: 'min',
                thresholdValue: '',
                thresholdErr: '',
                sampleDays: '',
                sampleErr: '',
                notifyOnce: false,
                objectiveCode: 'ops',
                objectiveText: '',
                objectiveErr: '',
                objectiveMaxTextLength: 64 
            });
        }).catch(err => {
            console.log(err);
        });
    }

    const archiveAlert = () => {
        let tempArch = [...archivedAlerts];
        let tempActive = [...activeAlerts];

        let sensorName = '';
        for(let i = 0;i<tempActive.length;++i){
            if(tempActive[i].id === alertPendingArchival){
                sensorName = tempActive[i].name;
                break;
            }
        }

        axiosProtect.put(`/a/alerts/toggle-archive-alert`, {
            alert_id: alertPendingArchival,
            alert_type: 'raw_data'
        }).then(response => {
            for(let i = 0;i<tempActive.length;++i){
                if(tempActive[i].id === alertPendingArchival){
                    tempActive.splice(i, 1);
                    break;
                }
            }

            let archivedAlert = response.data.alert;
            archivedAlert.name = sensorName;
            tempArch.unshift(archivedAlert);

            setArchivedAlerts(tempArch);
            setActiveAlerts(tempActive);
            setAlertPendingArchival(-1);
        }).catch(error => {
            console.log(error);
        });  
    }

    const reinstateAlert = alertID => {
        let tempArch = [...archivedAlerts];
        let tempActive = [...activeAlerts];

        axiosProtect.put(`/a/alerts/toggle-archive-alert`, {
            alert_id: alertID,
            alert_type: 'raw_data'
        }).then(response => {
            for(let i = 0;i<tempArch.length;++i){
                if(tempArch[i].id === alertID){
                    tempActive.push(tempArch[i]);
                    tempArch.splice(i, 1);
                    break;
                }
            }

            tempActive.sort((a, b) => new Date(b.date_created) - new Date(a.date_created));

            setArchivedAlerts(tempArch);
            setActiveAlerts(tempActive);
        }).catch(error => {
            console.log(error);
        });  
    }

    const deleteAlert = () => {
        let tempArr = [];

        axiosProtect.delete(`/a/alerts/delete-raw-data-alert?raw_data_alert_id=${alertPendingDeletion}`).then(response => {
            for(let i = 0;i<archivedAlerts.length;++i){
                if(archivedAlerts[i].id === alertPendingDeletion) continue;
                tempArr.push(archivedAlerts[i]);
            }

            setArchivedAlerts(tempArr);
            setAlertPendingDeletion(-1);
        }).catch(error => {
            console.log(error);
        });        
    }

    const generateMessageString = (code, text) => {
        if(code === 'custom') return text;

        return code === 'ops' ? 'Ensure operational consistency' : code === 'env' ? 'Enforce environmental impact' : 'Enforce financial savings'
    }

    return(
        <Modal
            show={props.alertsModalOpen}
            onHide={() => props.setAlertsModalOpen(false)}
            backdrop="static"
            centered
            dialogClassName="opp-modal-cw"
        >
            <Modal.Header closeButton>
                <Box sx={{width: '100%', backgroundColor:'rgba(255,255,255,0.4)'}}>
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        sx={{
                            '& .MuiTabs-indicator': {
                                backgroundColor: 'rgb(0,177,168)'
                            },
                            '& .MuiTab-textColorSecondary.Mui-selected': {
                                color: 'rgb(0,177,168)'
                            },
                            '& .MuiTab-textColorSecondary': {
                                color: 'rgb(8,114,182)'
                            },
                        }}
                        aria-label="secondary tabs example"
                    >
                        <Tab value="create" label="Create new alert" />
                        <Tab value="alerts" label="Alerts" />
                    </Tabs>
                </Box>
            </Modal.Header>

            <Modal.Body style={{maxHeight:'75vh', overflowY:'scroll'}}>
                {
                    tabValue === 'create' ? 
                    <div>
                        <p style={{margin:'0'}}>Send a notification to</p>

                        <div style={{display:'flex', flexDirection:'row'}}>
                            <div style={{flex:'10'}}>
                                <FormControl fullWidth>
                                    <OutlinedInput
                                        id="outlined-adornment-amount"
                                        placeholder='Email'
                                        disabled={formFields.autoEmail}
                                        value={formFields.email}
                                        error={formFields.emailErr !== ''}
                                        onChange={e => {
                                            setFormFields(prev => ({
                                                ...prev,
                                                email: e.target.value
                                            }));
                                        }}
                                    />
                                    <small style={{visibility:`${formFields.emailErr === '' ? 'hidden' : 'visible'}`, color:'red'}}>{formFields.emailErr}</small>
                                </FormControl>
                            </div>

                            <div style={{flex:'1'}}></div>

                            <div style={{flex:'4'}}>
                                <FormControlLabel control={<Checkbox checked={formFields.autoEmail} onChange={() => {
                                    setFormFields(prev => ({
                                        ...prev,
                                        email: formFields.autoEmail ? '' : auth.email,
                                        autoEmail: !formFields.autoEmail
                                    }))
                                }}/>} label="Use my email" />
                            </div>
                        </div>

                        <br/>

                        <p style={{margin:'0'}}>When</p>

                        <FormControl style={{width:'100%', marginBottom:'1.25vh'}}>
                            <Select native defaultValue="" id="grouped-native-select" label="" value={formFields.sensor} onChange={e => {
                                setFormFields(prev => ({
                                    ...prev,
                                    sensor: e.target.value
                                }));
                            }}>
                                {
                                    Object.keys(sensors).map((utility, i) => (
                                        <optgroup label={utility.charAt(0).toUpperCase() + utility.slice(1).toLowerCase()}>
                                            {sensors[utility].map((sensor, j) => (
                                                <option value={`${utility}-${sensor.id}`}>{sensor.name}</option>
                                            ))}
                                        </optgroup>
                                    ))
                                }
                            </Select>
                        </FormControl>

                        <br/>

                        <FormControl style={{width:'100%', marginBottom:'1.25vh'}}>
                            <Select native id="grouped-native-select" label="" value={formFields.criteria} onChange={e => {
                                setFormFields(prev => ({
                                    ...prev,
                                    criteria: e.target.value
                                }))
                            }}>
                                <option value='average'>Has an average consumption that:</option>
                                <option value='max'>Ever exceeds:</option>
                                <option value='min'>Ever subceeds:</option>
                            </Select>
                        </FormControl>

                        <br/>

                        <FormControl fullWidth>
                            <InputLabel htmlFor="outlined-adornment-amount"></InputLabel>
                            <OutlinedInput
                            style={{paddingLeft:'0'}}
                                id="outlined-adornment-amount"
                                startAdornment={
                                    formFields.criteria === 'average' &&
                                    <InputAdornment position="start" style={{width:'fit-content'}}>
                                            <Select native value={formFields.averageCriteria} id="grouped-native-select" label="" style={{width:'10rem'}} onChange={e => {
                                                setFormFields(prev => ({
                                                    ...prev,
                                                    averageCriteria: e.target.value
                                                }));
                                            }}>
                                                <option value='min'>Falls below</option>
                                                <option value='max'>Exceeds</option>
                                            </Select>
                                    </InputAdornment>
                                }
                                endAdornment={<InputAdornment position="end">{
                                    formFields.sensor.startsWith('water') ? 'LPM' : formFields.sensor.startsWith('electricity') ? 'A' : 
                                    formFields.sensor.startsWith('gas') ? 'm3' : ''
                                }</InputAdornment>}
                                label=""
                                value={formFields.thresholdValue}
                                error={formFields.thresholdErr !== ''}
                                onChange={e => {
                                    if(!floatRegex.test(e.target.value)) return;
                                    setFormFields(prev => ({
                                        ...prev,
                                        thresholdValue: e.target.value
                                    }));
                                }}
                            />
                            <small style={{visibility:`${formFields.thresholdErr === '' ? 'hidden' : 'visible'}`, color:'red'}}>{formFields.thresholdErr}</small>
                        </FormControl>

                        <br/>
                        <br/>

                        {
                            formFields.criteria === 'average' && 
                            <>
                            <p style={{margin:'0'}}>Over a consecutive period of</p>

                            <FormControl fullWidth>
                                <InputLabel htmlFor="outlined-adornment-amount"></InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-amount"
                                    endAdornment={<InputAdornment position="end">days</InputAdornment>}
                                    label=""
                                    value={formFields.sampleDays}
                                    error={formFields.sampleErr !== ''}
                                    onChange={e => {
                                        if(!integerRegex.test(e.target.value)) return;
                                        setFormFields(prev => ({
                                            ...prev,
                                            sampleDays: e.target.value
                                        }));
                                    }}
                                />
                                <small style={{visibility:`${formFields.sampleErr === '' ? 'hidden' : 'visible'}`, color:'red'}}>{formFields.sampleErr}</small>
                            </FormControl>

                            <br/>
                            </>
                        }
                        
                        <FormControlLabel control={<Checkbox checked={formFields.notifyOnce || formFields.criteria !== 'average'} disabled={formFields.criteria !== 'average'} onChange={() => {
                            setFormFields(prev => ({
                                ...prev,
                                notifyOnce: !formFields.notifyOnce
                            }))
                        }}/>} label="Disable this alert once triggered" />

                        <br/>
                        <br/>

                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <p style={{margin: '0', display: 'inline-flex', alignItems: 'center'}}>Objective</p>
                            <Tooltip title='The primary purpose for setting this alert'>
                                <IconButton style={{display: 'inline-flex', alignItems: 'center', marginLeft:'1%'}}>
                                    <HelpCenterIcon/>
                                </IconButton>
                            </Tooltip>
                        </div>
                        <FormControl style={{width:'100%', marginBottom:'1.25vh'}}>
                            <Select
                                value={formFields.objectiveCode}
                                onChange={e => {
                                    setFormFields(prev => ({
                                        ...prev,
                                        objectiveCode: e.target.value
                                    }));
                                }}
                            >
                                <MenuItem value='ops'>Ensure operational consistency</MenuItem>
                                <MenuItem value='env'>Enforce environmental impact</MenuItem>
                                <MenuItem value='cost'>Enforce financial savings</MenuItem>
                                <MenuItem value='custom'>Custom (text entry)</MenuItem>
                            </Select>
                        </FormControl>

                        {
                        formFields.objectiveCode === 'custom' &&
                        <>
                        <br/>

                        <FormControl fullWidth>
                            <OutlinedInput
                                id="outlined-adornment-amount"
                                placeholder='Custom objective'
                                value={formFields.objectiveText}
                                error={formFields.objectiveErr !== ''}
                                onChange={e => {
                                    if(e.target.value.length > formFields.objectiveMaxTextLength) return;
                                    setFormFields(prev => ({
                                        ...prev,
                                        objectiveText: e.target.value
                                    }));
                                }}
                            />
                            <small style={{
                                color:`${formFields.objectiveErr === '' ? 'grey' : 'red'}`
                            }}>
                                {formFields.objectiveErr === '' ? `${formFields.objectiveMaxTextLength - formFields.objectiveText.length} character${formFields.objectiveMaxTextLength - formFields.objectiveText.length === 1 ? '' : 's'} left` : formFields.objectiveErr}
                            </small>
                        </FormControl>
                        </>
                        }
                    </div>
                    :
                    <div>
                        <Table style={{width:'100%'}} colSpan={6}>
                            <TableBody>
                                <div>
                                <TableRow>{`Active Alerts (${activeAlerts.length})`}</TableRow>
                                {activeAlerts.map((alert, i) => (
                                    <>
                                    <TableRow>
                                        <TableCell style={{borderBottom:'none'}} sx={{width:'34vw'}}>
                                            <span>Notify <strong style={{color:'rgb(8,114,182)'}}>{alert.email}</strong> when <strong>{alert.name}</strong> {
                                                alert.threshold_criteria === 'average' ? 
                                                <span>{alert.average_criteria === 'min' ? 'falls below' : 'exceeds'} an average consumption of {alert.threshold_value} 
                                                {alert.utility === 'water' ? ' LPM' : alert.utility === 'electricity' ? ' A' : ' m3'} for {alert.sample_days} consecutive days {`(${alert.notify_once ? 'one time' : 'recurring'})`}</span>
                                                :
                                                <span>{alert.threshold_criteria === 'min' ? 'falls below' : 'exceeds'} {alert.threshold_value} {alert.utility === 'water' ? ' LPM' : alert.utility === 'electricity' ? ' A' : ' m3'}</span>
                                            }</span>
                                            <br/>
                                            <br/>
                                            <small>{generateMessageString(alert.objective_code, alert.objective_text)}</small>
                                        </TableCell>
                                        <TableCell style={{borderBottom:'none'}} sx={{width:'20%'}}>
                                            <div style={{display:'flex', flexDirection:'row'}}>
                                                <div style={{flex:'1'}}></div>
                                                
                                                <Tooltip title='Archive'>
                                                    <IconButton onClick={() => {
                                                        setAlertPendingArchival(alert.id);
                                                    }}>
                                                        <ArchiveIcon style={{color:'rgb(0,177,168)'}}/>
                                                    </IconButton>
                                                </Tooltip>

                                                <div style={{flex:'1'}}></div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    {alert.id === alertPendingArchival && 
                                    <Zoom in={alert.id === alertPendingArchival}>
                                        <TableRow>
                                            <TableCell colSpan={6} style={{height:'fit-content', backgroundColor:'rgb(0,177,168)', borderBottom:'none'}}>
                                                <div style={{display:'flex', flexDirection:'row'}}>
                                                    <Button style={{flex:'6', textAlign:'center', border:'none', backgroundColor:'rgba(0,0,0,0)', cursor:'default'}}>
                                                        <div >
                                                            <span>Archive this alert?</span>
                                                            <br/>
                                                            <small>The alert will not trigger while archived</small>
                                                        </div>
                                                    </Button>

                                                    <Button style={{flex:'2', backgroundColor:'white', border:'2px solid white'}} onClick={() => {
                                                        archiveAlert();
                                                    }}><span style={{color:'rgb(0,177,168)'}}>Archive</span></Button>

                                                    <div style={{flex:'0.5'}}></div>

                                                    <Button style={{flex:'2', border:'2px solid white', backgroundColor:'rgba(0,0,0,0)'}} onClick={() => {
                                                        setAlertPendingArchival(-1);
                                                    }}>Cancel</Button>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </Zoom>
                                    }
                                    </>
                                ))}

                                <br/>

                                <TableRow>{`Archived Alerts (${archivedAlerts.length})`}</TableRow>
                                {archivedAlerts.map((alert, i) => (
                                    <>
                                    <TableRow>
                                        <TableCell style={{borderBottom:'none'}} sx={{width:'34vw'}}>
                                            <span>Notify <strong style={{color:'rgb(8,114,182)'}}>{alert.email}</strong> when <strong>{alert.name}</strong> {
                                                alert.threshold_criteria === 'average' ? 
                                                <span>{alert.average_criteria === 'min' ? 'falls below' : 'exceeds'} an average consumption of {alert.threshold_value} 
                                                {alert.utility === 'water' ? ' LPM' : alert.utility === 'electricity' ? ' A' : ' m3'} for {alert.sample_days} consecutive days {`(${alert.notify_once ? 'one time' : 'recurring'})`}</span>
                                                :
                                                <span>{alert.threshold_criteria === 'min' ? 'falls below' : 'exceeds'} {alert.threshold_value} {alert.utility === 'water' ? ' LPM' : alert.utility === 'electricity' ? ' A' : ' m3'}</span>
                                            }</span>
                                            <br/>
                                            <br/>
                                            <small>{generateMessageString(alert.objective_code, alert.objective_text)}</small>
                                        </TableCell>
                                        <TableCell style={{borderBottom:'none'}} sx={{width:'20%'}}>
                                            <div style={{display:'flex', flexDirection:'row'}}>
                                                <div style={{flex:'1'}}></div>

                                                <Tooltip title='Re-enable'>
                                                    <IconButton onClick={() => {
                                                        reinstateAlert(alert.id);
                                                    }}>
                                                        <UnarchiveIcon style={{color:'rgb(8,114,182)'}}/>
                                                    </IconButton>
                                                </Tooltip>

                                                <div style={{flex:'1'}}></div>

                                                <Tooltip title='Delete'>
                                                    <IconButton onClick={() => {
                                                        setAlertPendingDeletion(alert.id)
                                                    }}>
                                                        <DeleteForeverIcon style={{color:'rgb(220, 53, 69)'}}/>
                                                    </IconButton>
                                                </Tooltip>

                                                <div style={{flex:'1'}}></div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    {alert.id === alertPendingDeletion && 
                                    <Zoom in={alert.id === alertPendingDeletion}>
                                        <TableRow>
                                            <TableCell colSpan={2} style={{height:'fit-content', backgroundColor:'red', borderBottom:'none'}}>
                                                <div style={{display:'flex', flexDirection:'row'}}>
                                                    <Button style={{flex:'6', textAlign:'center', border:'none', backgroundColor:'rgba(0,0,0,0)', cursor:'default'}}>Delete this alert?</Button>

                                                    <Button style={{flex:'2', backgroundColor:'white', border:'2px solid white'}} onClick={() => {
                                                        deleteAlert();
                                                    }}><span style={{color:'red'}}>Delete</span></Button>

                                                    <div style={{flex:'0.5'}}></div>

                                                    <Button style={{flex:'2', border:'2px solid white', backgroundColor:'rgba(0,0,0,0)'}} onClick={() => {
                                                        setAlertPendingDeletion(-1);
                                                    }}>Cancel</Button>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </Zoom>
                                    }
                                    </>
                                ))}
                                </div>
                            </TableBody>
                        </Table>
                    </div>
                }
            </Modal.Body>
            
            <Modal.Footer>
                <Button style={{width:'fit-content', marginTop:'2%', backgroundColor:'rgb(8,114,182)', border:'none'}} onClick={() => {
                    tabValue === 'create' ? createNewAlert() : props.setAlertsModalOpen(false);
                }}>{`${tabValue === 'create' ? 'Add alert' : 'Done'}`}</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AlertsModal;