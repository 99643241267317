import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

const DeleteTaskModal = props => {
    const [deleteType, setDeleteType] = useState('instance');

    const formatDate = timeString => {
        const date = new Date(timeString);

        const options = {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        };
      
        return date.toLocaleString('en-US', options);
    }

    return(
        <Modal
            show={props.deleteModalOpen}
            onHide={() => props.setDeleteModalOpen(false)}
            backdrop="static"
            centered
        >
            <Modal.Header closeButton style={{backgroundColor: props.modalLoading ? 'rgba(0,0,0,0.125)' : 'inherit'}}>
                <h4>Delete task?</h4>
            </Modal.Header>

            {props.modalLoading && (
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1040
                }}>
                    <CircularProgress />
                </div>
            )}

            <Modal.Body style={{overflow:'hidden', textAlign:'center', backgroundColor: props.modalLoading ? 'rgba(0,0,0,0.125)' : 'inherit'}}>
               <p style={{margin:'0', textAlign:'center'}}>{props.taskToDelete.title}</p>
               <p style={{textAlign:'center', color:'grey', fontSize:'0.9rem'}}>{formatDate(props.taskToDelete.deadline)}</p>

               {
                props.taskToDelete.is_recurring &&
                <FormControl>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={deleteType}
                        onChange={e => {
                            setDeleteType(e.target.value);
                        }}
                    >
                        <FormControlLabel value="instance" control={<Radio />} label="Delete this instance" />
                        <FormControlLabel value="all" control={<Radio />} label="Delete entire series" />
                    </RadioGroup>
                </FormControl>
               }
            </Modal.Body>

            <Modal.Footer style={{backgroundColor: props.modalLoading ? 'rgba(0,0,0,0.125)' : 'inherit'}}>
                <div style={{display:'flex', width:'100%'}}>
                    <Button style={{flex:'1'}} variant='danger' onClick={() => {
                        props.deleteTask(props.taskToDelete.id, props.taskToDelete.is_recurring, deleteType);
                    }} disabled={props.modalLoading}>
                        Delete
                    </Button>

                    <div style={{flex:'4'}}></div>

                    <Button style={{flex:'1'}} variant="secondary" onClick={() => {
                        props.setDeleteModalOpen(false);
                    }} disabled={props.modalLoading}>Cancel</Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default DeleteTaskModal;