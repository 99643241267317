import React, { useEffect, useRef, useState } from 'react';
import UtilityReadings from '../../staff_pages/utility_data/UtilityReadings';
import SensorContainer from '../dashboard_components/sites/sensors/SensorContainer';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import UtilityGraphContainer from '../../metric_graphs/utilities/raw_data/UtilityGraphContainer';
import useAxiosProtect from '../../../hooks/useAxiosProtect';
import OpportunityContainer from '../../opportunities/OpportunityContainer';
import SettingsContainer from './settings/SettingsContainer';
import EventsContainer from '../../events/EventsContainer';

const SiteManagementPage = props => {
    const axiosProtect = useAxiosProtect();

    const [siteName, setSiteName] = useState('')
    const [value, setValue] = useState('opportunities');
    
    var init = useRef(true);
    const siteID = window.location.href.split('/')[window.location.href.split('/').length - 1];

    useEffect(() => {
        if(init.current){
            init.current = false;

            axiosProtect.get(`/s/sites/get-site-fields?fields=%5B%22name%22%5D&siteID=${siteID}`).then(response => {
                if(response.status === 200){
                    setSiteName(response.data.siteData.name)
                }
            }).catch(err => {});
        }
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return(
        <div style={{padding:'2%', backgroundColor:'#f5f5f5', minHeight:'95vh'}}>
            <h4>{siteName}</h4>

            <br/>

            <Box sx={{width: '100%', backgroundColor:'rgba(255,255,255,0.4)'}}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    sx={{
                        '& .MuiTabs-indicator': {
                            backgroundColor: 'rgb(0,177,168)'
                        },
                        '& .MuiTab-textColorSecondary.Mui-selected': {
                            color: 'rgb(0,177,168)'
                        },
                        '& .MuiTab-textColorSecondary': {
                            color: 'rgb(8,114,182)'
                        },
                    }}
                    aria-label="secondary tabs example"
                >
                    <Tab value="opportunities" label="Opportunities" />
                    <Tab value="metrics" label="Metrics" />
                    <Tab value="utilities" label="Utilities" />
                    <Tab value="sensors" label="Sensors" />
                    <Tab value="tasks" label="Tasks" />
                    <Tab value="settings" label="Settings" />
                </Tabs>
            </Box>

            <br/>

            {value === 'metrics' && <UtilityGraphContainer/>}
            {value === 'utilities' && <UtilityReadings/>}
            {value === 'sensors' && <SensorContainer/>}
            {value === 'opportunities' && <OpportunityContainer/>}
            {value === 'tasks' && <EventsContainer/>}
            {value === 'settings' && <SettingsContainer/>}
        </div>
    );
}

export default SiteManagementPage;