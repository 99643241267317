import React, {useEffect, useRef, useState} from "react";
import useAxiosProtect from "../../../../../../hooks/useAxiosProtect";
import UtilityGraphContainer from "../../UtilityGraphContainer";

const ClientGraphContainer = props => {
    const axiosProtect = useAxiosProtect();

    const [authIsDefined, setAuthIsDefined] = useState(false);
    var authIsReset = useRef(false);

    const [siteList, setSiteList] = useState([]);

    useEffect(() => {
      if(props.userData == null) return;
      if(props.userData === undefined) return;
      if(Object.keys(props.userData).length === 0) return;

      setAuthIsDefined(true);
    }, [props.userData]);

    useEffect(() => {
      if(!authIsDefined && !authIsReset.current) return;

      authIsReset.current = false;

      if(window.location.pathname.startsWith('/energy-metrics')){
        axiosProtect.get(`/auth/query/list-client-site-access?uid=${props.userData.id}`).then(response => {
          setSiteList(response.data.sites);
        });
      }

      setAuthIsDefined(true);

    }, [authIsDefined]);

    const resetAuthCheck = () => {
      authIsReset.current = true;
      setAuthIsDefined(false);
    }

    return(
        <div style={{backgroundColor:'whitesmoke', padding:'1vw'}}>
            {authIsDefined && <UtilityGraphContainer siteID={window.location.href.split('/').slice(-1)} isClient={
              props.userData.role == process.env.REACT_APP_CLIENT_ADMIN || props.userData.role == process.env.REACT_APP_CLIENT_STAFF
              } sites={siteList} resetAuthCheck={resetAuthCheck}/>}
        </div>
    );
}

export default ClientGraphContainer;