import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ErrorIcon from '@mui/icons-material/Error';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CreateOppModal from './CreateOppModal';
import DeleteOppModal from './DeleteOppModal';
import StatusModal from './StatusModal';
import useAxiosProtect from '../../hooks/useAxiosProtect';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import SortIcon from '@mui/icons-material/Sort';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import useAuth from '../../hooks/useAuth';

const OpportunityCard = props => {
  const {auth} = useAuth();
  var init = useRef(true);
  const axiosProtect = useAxiosProtect();

  const [createModalOpen, setCreateModalOpen] = useState(false);
  
  const [statusModalOpen, setStatusModalOpen] = useState(false);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [oppToDelete, setOppToDelete] = useState(null);

  const [rows, setRows] = useState([]);

  const [openSortTooltip, setOpenSortTooltip] = useState(false);
  const [sortType, setSortType] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');

  const Row = props => {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
  
    const generateDateString = unixMS => {
      let date = new Date(parseInt(unixMS));
  
      let dateOptions = {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      }
  
      return date.toLocaleDateString('en-US', dateOptions);
    }
  
    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} id={`opp-table-entry-${props.rowIndex}`}>
          <TableCell align="center">
            {
              row.low_savings_alert &&
              <Tooltip title="Savings less than 75% over last 7 days">
                <IconButton
                  aria-label="expand row"
                >
                  <ErrorIcon style={{color:'red'}}/>
                </IconButton>
              </Tooltip>
            }
            <IconButton
              aria-label="expand row"
              // size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
  
          <TableCell component="th" scope="row">
            <span id={`opp-table-entry-name-${props.rowIndex}`}
            style={{cursor:'pointer'}}
            onClick={() => {
                window.location.href = `/opportunities/${row.id}`
            }}
            onMouseEnter={() => {
                let oppText = document.getElementById(`opp-table-entry-name-${props.rowIndex}`);
                oppText.style.textDecoration = 'underline blue 2px';
  
            }}
            onMouseLeave={() => {
                let oppText = document.getElementById(`opp-table-entry-name-${props.rowIndex}`);
                oppText.style.textDecoration = 'none';
                
            }}
            >{row.name}</span>
          </TableCell>
          
          <TableCell align="center">{row.category}</TableCell>
          <TableCell align="center">${Number(Math.round(row.savings)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
          <TableCell align="center">{Number((Math.round(row.ghg * 100) / 100) / 1000).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
          <TableCell align="center">${Number(Math.round(row.cost)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}*</TableCell>
          <TableCell align="center">{Number((Math.round(row.payback * 100) / 100)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
          <TableCell align="center">{Number(Math.round(row.progress * 100)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%</TableCell>
          <TableCell align="center">{isNaN(parseInt(row.start)) ? 'TBD' : generateDateString(row.start)}</TableCell>
          <TableCell align="center">{isNaN(parseInt(row.end)) ? 'TBD' : generateDateString(row.end)}</TableCell>
          {parseInt(auth.role) >= parseInt(process.env.REACT_APP_ES_USER) && <TableCell align="center">{row.manager}</TableCell>}
          <TableCell align="center">{row.status}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Info
                </Typography>
                <div>
                    <Table>
                      <tr>
                        <td>
                          <Typography variant="p" gutterBottom component="div"><span style={{marginRight:'1%'}}>{row.waterData.included ? <CheckIcon style={{color:'#28a745'}}/> : <CloseIcon style={{color:'#dc3545'}}/>} Water</span></Typography>
                        </td>
                        <td>
                          <Typography variant="p" gutterBottom component="div"><span style={{marginRight:'1%', visibility:`${row.waterData.included ? 'visible' : 'hidden'}`}}>{Math.round(row.waterData.utilitySavings).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} m<sup>3</sup>/year</span></Typography>
                        </td>
                        <td>
                          <Typography variant="p" gutterBottom component="div"><span style={{marginRight:'1%', visibility:`${row.waterData.included ? 'visible' : 'hidden'}`}}>${Math.round(row.waterData.costSavings).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/year</span></Typography>
                        </td>
                        <td>
                          <Typography variant="p" gutterBottom component="div"><span style={{visibility:`${row.waterData.included ? 'visible' : 'hidden'}`}}>{((Math.round(row.waterData.ghgSavings * 100) / 100) / 1000).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} MT CO<sub>2</sub>e/year</span></Typography>
                        </td>
                      </tr>
  
                      <tr>
                        <td>
                        <Typography variant="p" gutterBottom component="div"><span style={{marginRight:'3%'}}>{row.elecData.included ? <CheckIcon style={{color:'#28a745'}}/> : <CloseIcon style={{color:'#dc3545'}}/>} Electricity</span></Typography>
                        </td>
                        <td>
                          <Typography variant="p" gutterBottom component="div"><span style={{marginRight:'3%', visibility:`${row.elecData.included ? 'visible' : 'hidden'}`}}>{Math.round(row.elecData.utilitySavings).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} kWh/year</span></Typography>
                        </td>
                        <td>
                          <Typography variant="p" gutterBottom component="div"><span style={{marginRight:'3%', visibility:`${row.elecData.included ? 'visible' : 'hidden'}`}}>${Math.round(row.elecData.costSavings).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/year</span></Typography>
                        </td>
                        <td>
                          <Typography variant="p" gutterBottom component="div"><span style={{visibility:`${row.elecData.included ? 'visible' : 'hidden'}`}}>{((Math.round(row.elecData.ghgSavings * 100) / 100) / 1000).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} MT CO<sub>2</sub>e/year</span></Typography>
                        </td>
                      </tr>
  
                      <tr>
                        <td>
                        <Typography variant="p" gutterBottom component="div"><span style={{marginRight:'3%'}}>{row.gasData.included ? <CheckIcon style={{color:'#28a745'}}/> : <CloseIcon style={{color:'#dc3545'}}/>} {row.gasData.gasType === '' ? 'Gas' : row.gasData.gasType}</span></Typography>
                        </td>
                        <td>
                          <Typography variant="p" gutterBottom component="div"><span style={{marginRight:'3%', visibility:`${row.gasData.included ? 'visible' : 'hidden'}`}}>{Math.round(row.gasData.utilitySavings).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} m<sup>3</sup>/year</span></Typography>
                        </td>
                        <td>
                          <Typography variant="p" gutterBottom component="div"><span style={{marginRight:'3%', visibility:`${row.gasData.included ? 'visible' : 'hidden'}`}}>${Math.round(row.gasData.costSavings).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/year</span></Typography>
                        </td>
                        <td>
                          <Typography variant="p" gutterBottom component="div"><span style={{visibility:`${row.gasData.included ? 'visible' : 'hidden'}`}}>{((Math.round(row.gasData.ghgSavings * 100) / 100) / 1000).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} MT CO<sub>2</sub>e/year</span></Typography>
                        </td>
                      </tr>
                    </Table>
                </div>
  
                <br/>
  
                {parseInt(auth.role) >= parseInt(process.env.REACT_APP_ES_USER) && <Typography variant="p" gutterBottom component="div" id={`opp-del-${row.id}`} style={{color:'#dc3545'}} onMouseEnter={() => {
                      let delOpp = document.getElementById(`opp-del-${row.id}`);
  
                      delOpp.style.textDecoration = 'underline';
                      delOpp.style.cursor = 'pointer';
                    }} onMouseLeave={() => {
                      let delOpp = document.getElementById(`opp-del-${row.id}`);
  
                      delOpp.style.textDecoration = 'none';
                      delOpp.style.cursor = 'default';
                    }} onClick={() => {
                      props.setOppToDelete(row);
                      props.setDeleteModalOpen(true);
                    }}>Delete opportunity
                </Typography>}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  useEffect(() => {
    if(!init.current) return;
    init.current = false;

    let siteID = window.location.href.split('/')[window.location.href.split('/').length - 1];
    if(isNaN(parseInt(siteID))){
      siteID = props.siteID;
    }

    if(props.presetOpps === undefined){
      axiosProtect.get(`/opp/opportunities/get-opps?site_id=${siteID}`).then(response => {
        let opps = [];
  
        for(let i = 0;i<response.data.opportunities.length;++i){
          let opp = response.data.opportunities[i];
  
          opps.push(createData(
            opp.id, 
            opp.title,
            opp.category.charAt(0).toUpperCase() + opp.category.slice(1),
            (opp.water_savings_m3_year * opp.water_unit_cost_m3) + (opp.electricity_savings_kwh_year * opp.electricity_unit_cost_kwh) + (opp.gas_savings_units_year * opp.gas_unit_cost),
            (opp.water_savings_m3_year * opp.water_kg_co2_m3) + (opp.electricity_savings_kwh_year * opp.electricity_kg_co2_kwh) + (opp.gas_savings_units_year * opp.gas_kg_co2_btu * opp.gas_btu_per_unit),
            opp.project_cost,
            opp.payback_years,
            opp.project_progress_percent,
            opp.start_date ? opp.start_date : 'TBD',
            opp.end_date ? opp.end_date : 'TBD',
            `${opp.first_name.substring(0,1)}. ${opp.last_name}`,
            opp.project_progress_status.charAt(0).toUpperCase() + opp.project_progress_status.slice(1),
            {
              included: opp.has_water,
              utilitySavings: opp.water_savings_m3_year,
              costSavings: opp.water_unit_cost_m3 * opp.water_savings_m3_year,
              ghgSavings: opp.water_kg_co2_m3 * opp.water_savings_m3_year
            },
            {
              included: opp.has_electricity,
              utilitySavings: opp.electricity_savings_kwh_year,
              costSavings: opp.electricity_unit_cost_kwh * opp.electricity_savings_kwh_year,
              ghgSavings: opp.electricity_kg_co2_kwh * opp.electricity_savings_kwh_year
            },
            {
              included: opp.has_gas,
              utilitySavings: opp.gas_savings_units_year,
              costSavings: opp.gas_unit_cost * opp.gas_savings_units_year,
              ghgSavings: opp.gas_kg_co2_btu * opp.gas_savings_units_year * opp.gas_btu_per_unit,
              gasType: opp.gas_type
            },
            opp.low_savings_alert
          ));
        }
        
        setRows(opps);
  
      }).catch(err => {
        console.log("Could not get opps")
      });
    }

    else{
      let opps = [];

      for(let i = 0;i<props.presetOpps.length;++i){
        let opp = props.presetOpps[i];

        opps.push(createData(
          opp.id, 
          opp.title,
          opp.category.charAt(0).toUpperCase() + opp.category.slice(1),
          (opp.water_savings_m3_year * opp.water_unit_cost_m3) + (opp.electricity_savings_kwh_year * opp.electricity_unit_cost_kwh) + (opp.gas_savings_units_year * opp.gas_unit_cost),
          (opp.water_savings_m3_year * opp.water_kg_co2_m3) + (opp.electricity_savings_kwh_year * opp.electricity_kg_co2_kwh) + (opp.gas_savings_units_year * opp.gas_kg_co2_btu * opp.gas_btu_per_unit),
          opp.project_cost,
          opp.payback_years,
          opp.project_progress_percent,
          opp.start_date ? opp.start_date : 'TBD',
          opp.end_date ? opp.end_date : 'TBD',
          `${opp.first_name.substring(0,1)}. ${opp.last_name}`,
          opp.project_progress_status.charAt(0).toUpperCase() + opp.project_progress_status.slice(1),
          {
            included: opp.has_water,
            utilitySavings: opp.water_savings_m3_year,
            costSavings: opp.water_unit_cost_m3 * opp.water_savings_m3_year,
            ghgSavings: opp.water_kg_co2_m3 * opp.water_savings_m3_year
          },
          {
            included: opp.has_electricity,
            utilitySavings: opp.electricity_savings_kwh_year,
            costSavings: opp.electricity_unit_cost_kwh * opp.electricity_savings_kwh_year,
            ghgSavings: opp.electricity_kg_co2_kwh * opp.electricity_savings_kwh_year
          },
          {
            included: opp.has_gas,
            utilitySavings: opp.gas_savings_units_year,
            costSavings: opp.gas_unit_cost * opp.gas_savings_units_year,
            ghgSavings: opp.gas_kg_co2_btu * opp.gas_savings_units_year * opp.gas_btu_per_unit,
            gasType: opp.gas_type
          },
          opp.low_savings_alert
        ));
      }

      setRows(opps);
    }
  }, []);

  const createData = (id, name, category, savings, ghg, cost, payback, progress, start, end, manager, status, waterData, elecData, gasData, low_savings_alert) => {
    let payload = {
      id,
      name,
      category,
      savings,
      ghg,
      cost,
      payback,
      progress,
      start,
      end,
      // manager,
      status,
      waterData,
      elecData,
      gasData,
      low_savings_alert
    }
  
    if(parseInt(auth.role) >= parseInt(process.env.REACT_APP_ES_USER)) payload.manager = manager;
  
    return payload;
  }

  const addOpportunity = opp => {
    let opps = [...rows];

    opps.push(createData(
      opp.id, 
      opp.title,
      opp.category.charAt(0).toUpperCase() + opp.category.slice(1),
      (opp.water_savings_m3_year * opp.water_unit_cost_m3) + (opp.electricity_savings_kwh_year * opp.electricity_unit_cost_kwh) + (opp.gas_savings_units_year * opp.gas_unit_cost),
      (opp.water_savings_m3_year * opp.water_kg_co2_m3) + (opp.electricity_savings_kwh_year * opp.electricity_kg_co2_kwh) + (opp.gas_savings_units_year * opp.gas_kg_co2_btu * opp.gas_btu_per_unit),
      opp.project_cost,
      opp.payback_years,
      opp.project_progress_percent,
      opp.start_date ? opp.start_date : 'TBD',
      opp.end_date ? opp.end_date : 'TBD',
      `${opp.first_name.substring(0,1)}. ${opp.last_name}`,
      opp.project_progress_status.charAt(0).toUpperCase() + opp.project_progress_status.slice(1),
      {
        included: opp.has_water,
        utilitySavings: opp.water_savings_m3_year,
        costSavings: opp.water_unit_cost_m3 * opp.water_savings_m3_year,
        ghgSavings: opp.water_kg_co2_m3 * opp.water_savings_m3_year
      },
      {
        included: opp.has_electricity,
        utilitySavings: opp.electricity_savings_kwh_year,
        costSavings: opp.electricity_unit_cost_kwh * opp.electricity_savings_kwh_year,
        ghgSavings: opp.electricity_kg_co2_kwh * opp.electricity_savings_kwh_year
      },
      {
        included: opp.has_gas,
        utilitySavings: opp.gas_savings_units_year,
        costSavings: opp.gas_unit_cost * opp.gas_savings_units_year,
        ghgSavings: opp.gas_kg_co2_btu * opp.gas_savings_units_year * opp.gas_btu_per_unit,
        gasType: opp.gas_type
      },
      opp.low_savings_alert
    ));

    setRows(opps);
  }

  const deleteOpportunity = async () => {
    try{
      let response = await axiosProtect.delete(`/opp/opportunities/delete-opp?opp_id=${oppToDelete.id}`);
      if(response.status === 200){
        let opps = [];

        for(let i = 0;i<rows.length;++i){
          if(rows[i].id == oppToDelete.id) continue;
          opps.push(rows[i]);
        }

        setRows(opps);
      }
    }catch(error){}

    setDeleteModalOpen(false);
    setOppToDelete(null);
  }

  const sortOpps = () => {
    let tempRows = [...rows];

    tempRows.sort((a,b) => {
      let valueA = a[sortType];
      let valueB = b[sortType];

      if(valueA < valueB) return sortDirection === 'asc' ? -1 : 1;

      if(valueA > valueB) return sortDirection === 'asc' ? 1 : -1;

      return 0;
    });

    setRows(tempRows);
    setOpenSortTooltip(false);
  }

  return(
    <>
    {createModalOpen && <CreateOppModal createModalOpen={createModalOpen} setCreateModalOpen={setCreateModalOpen} addOpportunity={addOpportunity}/>}
    {deleteModalOpen && <DeleteOppModal deleteModalOpen={deleteModalOpen} setDeleteModalOpen={setDeleteModalOpen} oppToDelete={oppToDelete} deleteOpportunity={deleteOpportunity}/>}
    {statusModalOpen && <StatusModal statusModalOpen={statusModalOpen} setStatusModalOpen={setStatusModalOpen}/>}

    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead style={{backgroundColor:'rgb(3,117,176)'}}>
        <TableRow>
          <TableCell>
            <div style={{display:'flex', flexDirection:'row'}}>
              <IconButton>
                <AddCircleIcon style={{color:'white', visibility:`${parseInt(auth.role) >= parseInt(process.env.REACT_APP_ES_USER) ? 'visible' : 'hidden'}`}} onClick={() => setCreateModalOpen(true)}/>
              </IconButton>
              <Tooltip
                onClose={() => setOpenSortTooltip(false)}
                open={openSortTooltip}
                title={
                  <React.Fragment>
                    <Typography color="inherit">Sort by</Typography>

                    <FormControl fullWidth style={{color:'white'}}>
                        <InputLabel id="demo-simple-select-label"></InputLabel>
                        <Select
                            value={sortType}
                            onChange={e => setSortType(e.target.value)}
                            style={{color:'whitesmoke'}}
                            MenuProps={{
                              style: {zIndex: 35001}
                          }}
                            sx={{
                              height: '3rem',
                              color: 'white',
                              '& .MuiOutlinedInput-notchedOutline': {
                                  borderColor: 'white'
                              },
                              '& .MuiSvgIcon-root': {
                                  color: 'white'
                              },
                          }}
                        >
                            <MenuItem value='name'>Title</MenuItem>
                            <MenuItem value='category'>Category</MenuItem>
                            <MenuItem value='savings'>$ savings</MenuItem>
                            <MenuItem value='ghg'>GHG savings</MenuItem>
                            <MenuItem value='cost'>Project cost</MenuItem>
                            <MenuItem value='payback'>Payback</MenuItem>
                            <MenuItem value='progress'>Progress</MenuItem>
                            <MenuItem value='start'>Start date</MenuItem>
                            <MenuItem value='end'>End date</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl>
                      <RadioGroup
                        value={sortDirection}
                        onChange={e => setSortDirection(e.target.value)}
                      >
                        <FormControlLabel value="asc" control={<Radio />} label="Ascending" />
                        <FormControlLabel value="desc" control={<Radio />} label="Descending" />
                      </RadioGroup>
                    </FormControl>

                    <div style={{display:'flex:',flexDirection:'row'}}>
                      <IconButton style={{fontSize:'1rem', color:'whitesmoke'}} onClick={() => setOpenSortTooltip(false)}>Close</IconButton>
                      <IconButton style={{fontSize:'1rem', color:'whitesmoke'}} onClick={() => sortOpps()}>Sort</IconButton>
                    </div>
                  </React.Fragment>
                }
                arrow
                placement="right"
                PopperProps={{
                  disablePortal: true
                }}
                disableFocusListener
                disableHoverListener
                disableTouchListener
              >
                <IconButton onClick={() => setOpenSortTooltip(true)}>
                  <SortIcon style={{color:'whitesmoke'}}/>
                </IconButton>
              </Tooltip>
            </div>
          </TableCell>
          <TableCell style={{color:'whitesmoke'}}>Opportunity</TableCell>
          <TableCell align="center" style={{color:'whitesmoke'}}>Category</TableCell>
          <TableCell align="center" style={{color:'whitesmoke'}}>Potential savings ($/yr)</TableCell>
          <TableCell align="center" style={{color:'whitesmoke'}}>GHG savings (MT/yr)</TableCell>
          <TableCell align="center" style={{color:'whitesmoke'/*, textDecoration:'underline', cursor:'pointer'*/}} /*onClick={() => window.location.href = '/projects/86'}*/>Implementation cost ($/yr)</TableCell>
          <TableCell align="center" style={{color:'whitesmoke'}}>
            <Tooltip title="Years needed to recover capital investment. Unadjusted for inflation or depreciation" placement="top">
              <span style={{textDecoration:'underline', cursor:'default'}}>Payback (years)</span>
            </Tooltip>
          </TableCell>
          <TableCell align="center" style={{color:'whitesmoke'}}>Progress (%)</TableCell>
          <TableCell align="center" style={{color:'whitesmoke'}}>Start</TableCell>
          <TableCell align="center" style={{color:'whitesmoke'}}>End</TableCell>
          {parseInt(auth.role) >= parseInt(process.env.REACT_APP_ES_USER) && <TableCell align="center" style={{color:'whitesmoke'}}>Project lead</TableCell>}
          <TableCell align="center" style={{color:'whitesmoke'}}>
              <span onClick={() => setStatusModalOpen(true)} style={{textDecoration:'underline', cursor:'pointer'}}>Status</span>
          </TableCell>
        </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <Row key={row.name} row={row} id={`opp-table-row-${i}`} rowIndex={i} setDeleteModalOpen={setDeleteModalOpen} oppToDelete={oppToDelete} setOppToDelete={setOppToDelete}/>
          ))}
          <TableRow>
            <TableCell colSpan={10}><small>*Estimated value: quotes, co-funding opportunities and/or payback analysis pending</small></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
}

export default OpportunityCard;