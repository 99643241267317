import React, { useEffect, useRef, useState } from 'react';
import tpBackground from '../../../media/titlePagebg.png';

const TitlePage = props => {
    const [today, setToday] = useState('');
    const [reportBounds, setReportBounds] = useState(['', '']);

    var init = useRef(true);

    const getLocalDate = (seconds=null) => {
        if(seconds == null){
            const now = new Date();

            const day = now.getDate();
            const monthIndex = now.getMonth();
            const year = now.getFullYear();

            const monthNames = [
                "January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"
            ];

            return `${day} ${monthNames[monthIndex]} ${year}`;
        }
        
        const date = seconds === 0 ? new Date(props.siteData.date_created) : new Date(seconds * 1000);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
        
        return `${day} ${month} ${year}`;
    }

    useEffect(() => {
        if(init.current){
            init.current = false;

            setToday(getLocalDate());

            setReportBounds([getLocalDate(props.start), getLocalDate(props.end)])
        }

    }, []);

    const backgroundStyle = {
        backgroundImage: `url(${tpBackground})`,
        backgroundSize: 'cover',
    };
      
    return(
        <div className="rmis-report-page" style={{height:'11in', width:'8.5in', margin:'auto', backgroundColor:'white', padding: '0.25in', ...backgroundStyle}} id='title-page'>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                <div style={{ height: 'fit-content', width: '5in', marginTop: '-1in', marginLeft:'1in'}}>
                    <p style={{fontWeight:'bold', color:'rgb(247,230,83)', margin:'0'}}>REPORT FOR:</p>
                    <h4 style={{fontWeight:'bold', fontSize:'3.25rem', color:'white'}}>{props.siteData?.name}</h4>
                    <p style={{color:'white'}} id='title-page-date-bounds'>{reportBounds[0]} - {reportBounds[1]}</p>
                </div>

                <div style={{ height: '0.8in', width: '3.5in', marginTop: '6.75in', marginLeft:'4in', position:'absolute', textAlign:'right', overflow:'hidden'}}>
                    <p style={{color:'white', margin:'0', fontWeight:'bold'}}>{today}</p>
                    <p style={{color:'white', margin:'0', fontWeight:'bold'}}>Utility Conservation Report</p>
                </div>
            </div>
        </div>
    );
}

export default TitlePage;