import React, {useEffect, useRef, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import useAxiosProtect from '../../hooks/useAxiosProtect';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ESLogo from '../../media/mainLogo.png'

const UserAgreement = props => {
    const axiosProtect = useAxiosProtect();

    const [userAgreed, setUserAgreed] = useState(false);

    return(
        <Modal
            show={props.userAgreementOpen}
            backdrop="static"
            centered
            dialogClassName="opp-modal-cw"
            className='rmis-userag-modal'
        >
            <Modal.Header>
                <h4 style={{color:'rgb(8,114,182)', fontWeight:'bold'}}>Terms of service & end user license agreement.</h4>
            </Modal.Header>

            <Modal.Body style={{maxHeight:'70vh', overflowY:'scroll'}}>
                <div style={{height:'fit-content', width:'90%', margin:'auto'}}>
                    <img src={ESLogo} style={{width:'30%', height:'auto', marginLeft:'35%'}}/>

                    <br/>
                    <br/>

                    <p style={{textAlign:'justify', fontSize:'0.85rem'}}>Enviro-Stewards, Inc. (“Enviro-Stewards”) provides: (1) a user account website that may be accessed at
                    https://enviro-stewards-rmis.netlify.app (the “Site”), (2) services accessible through the Site (“Web
                    Apps”), (3) software that may be downloaded to your smartphone or tablet to access services (“Mobile
                    Apps”), and 3rd party utility monitoring products (“Monitoring Products”) all for use in conjunction with
                    Enviro-Stewards’ Professional Services (“Services”). </p>

                    <p style={{textAlign:'justify', fontSize:'0.85rem'}}>The term “Services” means professional and consulting activities conducted by Enviro-Stewards staff for
                    the purpose of identifying opportunities to increase efficiency of processes, utility usage, address
                    environmental compliance issues, reduce greenhouse gas emissions, and provide services and
                    recommendations to solve production yield inefficiencies and all associated data.</p>

                    <p style={{textAlign:'justify', fontSize:'0.85rem'}}>These Terms govern your access to and use of the Site, Web Apps, Mobile Apps and Services, defined
                    collectively as the term (“Product”). Please read these Terms carefully. The term “you,” as used in these
                    Terms, means any person or entity who uses the Product or Services and any person or entity who creates
                    an Account (as defined below) and accepts these Terms.</p>

                    <p style={{textAlign:'justify', fontSize:'0.85rem'}}>These Terms constitute a legal agreement. By acknowledging the agreement or by accessing
                    and using the Product or the Services, you are accepting and agreeing to these Terms on behalf of yourself
                    or the entity you represent in connection with the access. You represent and warrant that you have the
                    right, authority, and capacity to accept and agree to these Terms on behalf of yourself or the entity you
                    represent.</p>

                    <p style={{textAlign:'justify', fontSize:'0.85rem'}}>Enviro-Stewards reserves the right to make changes to these Terms. Enviro-Stewards will provide
                    commercially reasonable notice of changes to these Terms. Continued use of the Product or Services
                    following notice of such changes shall indicate your acknowledgment of such changes and agreement to
                    be bound by the revised Terms.</p>

                    <p style={{textAlign:'justify', fontSize:'0.85rem'}}>THESE TERMS DESCRIBE IMPORTANT LIMITATIONS OF THE PRODUCT AND SERVICES. THESE
                    TERMS ALSO CONTAIN IMPORTANT INFORMATION ABOUT YOUR RIGHT TO MAKE LEGAL
                    CLAIMS AGAINST ENVIRO-STEWARDS. THESE TERMS INCLUDE A CLASS ACTION AND JURY
                    TRIAL WAIVER IN SECTION S. PLEASE READ THESE DISCLOSURES CAREFULLY, AS YOU ARE
                    ACKNOWLEDGING AND ACCEPTING THEM.</p>

                    <p style={{textAlign:'justify', fontSize:'0.85rem'}}>If you do not agree with any of the provisions of these terms, you should cancel your subscription and use
                    of the Product and cease using the Product and Services.</p>

                    <p style={{textAlign:'justify', fontSize:'1rem', fontWeight:'bold'}}>A. Your account</p>

                    <p style={{textAlign:'justify', fontSize:'0.85rem'}}>To use the Services, you must register for a user account (“Account”) and provide certain information
                    about yourself and your company as prompted by the applicable registration form. You represent and
                    warrant that: (a) all required registration information you submit is truthful and accurate; and (b) you will
                    maintain the accuracy of such information. You are entirely responsible for maintaining the
                    confidentiality of your Account login information and for all activities that occur under your Account. You
                    agree to immediately notify Enviro-Stewards of any unauthorized use, or suspected unauthorized use, of
                    your Account or any other breach of security. Enviro-Stewards may provide notifications to you as
                    required by law or for marketing or other purposes via (at its option) email to the primary email
                    associated with your Account, mobile notifications, hard copy, or posting of such notice on the Site.</p>

                    <p style={{textAlign:'justify', fontSize:'1rem', fontWeight:'bold'}}>B. License</p>

                    <p style={{textAlign:'justify', fontSize:'0.85rem'}}>Subject to Certain Restrictions, Enviro-Stewards grants you a personal, non-transferable, non-exclusive,
                    right (without the right to sublicense) to access and use the Product by (i) using Monitoring Products to
                    monitor or otherwise access and supply data to the Product explicitly provided by Enviro-Stewards for
                    your use and (ii) installing and using supported Monitoring Products solely on your own and solely for
                    your use. You acknowledge that installing and using supported Monitoring Products may require the
                    assistance of Enviro-Stewards staff to integrate to the Product for your use, and that such assistance of
                    Enviro-Stewards staff may carry an installation fee. Estimates of installation fees will be provided to you
                    by Enviro-Stewards staff in advance and you will have the option to accept or decline the assistance and
                    corresponding fees. You acknowledge that not all Monitoring Products will be compatible with the
                    Product and that it is your responsibility to only purchase Monitoring Products intended to be used with
                    the Product as certified by Enviro-Stewards and you agree to absolve Enviro-Stewards of any costs,
                    liabilities, or any other charge resulting from incompatible and unsupported Monitoring Products.</p>

                    <p style={{textAlign:'justify', fontSize:'0.85rem'}}>Certain Restrictions: The rights granted to you in these Terms are subject to the following restrictions: (i)
                    you agree not to license, sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially
                    exploit the Product or Services (unless you have a separate legal agreement with Enviro-Stewards to act as
                    an authorized re-seller); (ii) you agree not to modify, make derivative works of, disassemble, reverse
                    compile, or reverse engineer any part of the Product or Services; (iii) you agree not to access the Product
                    or Services in order to build a similar or competitive service; (iv) except as expressly stated herein, no part
                    of the Product or Services may be copied, reproduced, distributed, republished, downloaded, displayed,
                    posted, or transmitted in any form or by any means; (v) you agree not to upload, transmit, or distribute
                    any computer viruses, worms, or any software intended to damage or alter a computer or communication
                    network, computer, handheld mobile device, data, the Products or any other system, device or property;
                    (vi) you agree not to interfere with, disrupt, or attempt to gain unauthorized access to, the servers or
                    networks connected to the Product or violate the regulations, policies, or procedures of such networks;
                    (vii) you agree not to access (or attempt to access) any of the Product or Services by means other than
                    through the interface that is provided by Enviro-Stewards; (viii) you agree not to remove, obscure, or alter
                    any proprietary rights notices (including copyrights and trademark notices) which may be contained in or
                    displayed in connection with the Product or Services. Any future release, update, or other addition to
                    functionality of the Product shall be subject to these Terms; and (ix) you agree not to use the Product or
                    Services in any way that violates any applicable local, national, or international law.</p>

                    <p style={{textAlign:'justify', fontSize:'1rem', fontWeight:'bold'}}>C. Term & termination of services</p>

                    <p style={{textAlign:'justify', fontSize:'0.85rem'}}>These Terms and the licenses granted hereunder are effective on the date you first purchase the Products
                    or Services and shall continue for as long as you own the Products or until terminated in accordance with
                    the provisions of these Terms. At any time, Enviro-Stewards may (i) suspend or terminate your rights to
                    access or use the Services, or (ii) terminate the license if Enviro-Stewards in good faith believes that you
                    have used the Products or Services in violation of these Terms, including any incorporated guidelines,
                    terms, or rules. Upon termination of the license, your Account and your right to use the Product and
                    Services and the licenses granted hereunder will terminate, but the Terms of this Agreement will remain
                    in effect after any such termination.</p>

                    <p style={{textAlign:'justify', fontSize:'1rem', fontWeight:'bold'}}>D. Assignment</p>

                    <p style={{textAlign:'justify', fontSize:'0.85rem'}}>If you transfer a Product to a new owner, your right to use the Product and Services with respect to that
                    Product automatically terminates, and the new owner will have no right to use the Product or Services
                    under your Account. These Terms, and any associated rights or obligations, may not be assigned or
                    otherwise transferred by you without Enviro-Stewards’ prior written consent. These Terms may be
                    assigned by Enviro-Stewards without restriction. These Terms are binding upon any permitted assignee.</p>

                    <p style={{textAlign:'justify', fontSize:'1rem', fontWeight:'bold'}}>E. Intellectual property & ownership</p>

                    <p style={{textAlign:'justify', fontSize:'0.85rem'}}>You acknowledge that all intellectual property rights, including without limitation copyrights, patents,
                    trademarks, and trade secrets in the Products and Services are the exclusive property of Enviro-Stewards,
                    its affiliates, or our licensors. Enviro-Stewards and its affiliates and licensors and suppliers reserve all
                    rights not granted in these Terms. The Products are licensed to you, not sold, under these Terms. Your
                    possession, access, and use of the Products and Services does not transfer any rights, title, or interest in or
                    to such intellectual property rights to you or any third party. There are no implied licenses under these
                    Terms.</p>

                    <p style={{textAlign:'justify', fontSize:'0.85rem'}}>All trademarks, logos, and service marks (“Marks”) displayed on the Products or Services are the exclusive
                    property of Enviro-Stewards or of their respective holders. You are not permitted to use any of the Marks
                    without the prior written consent of Enviro-Stewards or such respective holders.</p>

                    <p style={{textAlign:'justify', fontSize:'0.85rem'}}>You may not use the content of the Product or Services in any other public or commercial way, nor may
                    you copy or incorporate any of the content of the Product or Services into any other work, including your
                    own website, without the written consent of Enviro-Stewards. You must have a license from us before you
                    can post or redistribute any portion of the Product or Services. Enviro-Stewards retains full and complete
                    title to all content in the Products and Services, including any data that accompanies it. You must not
                    copy, modify or in any way reproduce or damage the structure or presentation of the Products or any
                    content therein.</p>

                    <p style={{textAlign:'justify', fontSize:'0.85rem'}}>You may choose to, or Enviro-Stewards may invite you to submit comments, suggestions, or ideas about
                    the Product or Services, including how to improve the Product or Services (“Ideas”). By submitting any
                    Ideas, you agree that your submissions are voluntary, gratuitous, unsolicited, and without restriction and 
                    RMIS Usage Agreement – v01 20240226 pg. 4 of 7
                    will not place Enviro-Stewards under any fiduciary or other obligation. Enviro-Stewards may use, copy,
                    modify, publish, or redistribute the submission and its contents for any purpose and in any way without
                    any compensation to you.</p>

                    <p style={{textAlign:'justify', fontSize:'1rem', fontWeight:'bold'}}>F. Data ownership</p>

                    <p style={{textAlign:'justify', fontSize:'0.85rem'}}>We will own and may share non-personal customer information (for example, aggregated or anonymized
                    customer data) publicly and with our partners. For example, we may publish trends about utility use or
                    conservation measures. This information may also be shared with other users to help them better
                    understand their utility usage compared to others or raise awareness about other issues. We may also
                    share non-personal information with our partners.</p>

                    <p style={{textAlign:'justify', fontSize:'1rem', fontWeight:'bold'}}>G. Open source</p>

                    <p style={{textAlign:'justify', fontSize:'0.85rem'}}>Certain items of software included with the Product may be subject to “open source” or “free software”
                    licenses (“Open Source Software”). Some of the Open Source Software is owned by third parties. The
                    Open Source Software is not subject to these Terms and, instead, each item of Open Source Software is
                    licensed under the terms of the end user license that accompanies such Open Source Software. Nothing in
                    these Terms limits your rights under, or grants you rights that supersede, the terms and conditions of any
                    applicable end user license for the Open Source Software.</p>

                    <p style={{textAlign:'justify', fontSize:'1rem', fontWeight:'bold'}}>H. Automatic software updates</p>

                    <p style={{textAlign:'justify', fontSize:'0.85rem'}}>Enviro-Stewards may from time to time develop patches, bug fixes, updates, upgrades, and other
                    modifications to improve the performance of the Product Software and Services (“Updates”). These may
                    be automatically installed without providing any additional notice or receiving any additional consent.
                    You hereby consent to these automatic Updates. If you do not want such Updates, you must cease using
                    the Products. If you do not cease using the Products, you will receive Updates automatically.</p>

                    <p style={{textAlign:'justify', fontSize:'1rem', fontWeight:'bold'}}>I. Modification</p>

                    <p style={{textAlign:'justify', fontSize:'0.85rem'}}>Enviro-Stewards reserves the right, at any time, to modify, suspend, or discontinue the Product or
                    Services, or any part thereof, or pricing, provided that Enviro-Stewards shall use commercially reasonable
                    efforts to provide notice as soon as possible. You agree that Enviro-Stewards will not be liable to you or to
                    any third party for any modification, suspension, or discontinuance of the Product or Services, or any part
                    thereof.</p>

                    <p style={{textAlign:'justify', fontSize:'1rem', fontWeight:'bold'}}>J. Agreed usage & limitations</p>

                    <p style={{textAlign:'justify', fontSize:'0.85rem'}}>Intended Use: The Products, or Services may be subject to sporadic interruptions and failures for a variety
                    of reasons beyond Enviro-Stewards’ control, including data connection intermittency, service provider
                    uptime, mobile notifications and carriers, physical installation, and connected devices, among others. You
                    acknowledge these limitations and agree that Enviro-Stewards is not responsible for any damages
                    allegedly caused by the failure or delay of the Products, Monitoring Products, or Services.</p>

                    <p style={{textAlign:'justify', fontSize:'0.85rem'}}>Reliability of Notifications: You acknowledge that the Products or Services, including remote access and
                    mobile notifications, are not intended to be 100% reliable and 100% available. We do not guarantee that 
                    RMIS Usage Agreement – v01 20240226 pg. 5 of 7
                    you will receive notifications for every possible situation. YOU AGREE THAT YOU WILL NOT RELY ON
                    THE PRODUCTS, PRODUCT SOFTWARE OR SERVICES FOR ANY LIFE SAFETY OR CRITICAL
                    PURPOSES. MOBILE NOTIFICATIONS REGARDING THE STATUS AND ALARMS ON YOUR
                    PRODUCTS OR SERVICES ARE PROVIDED FOR INFORMATIONAL PURPOSES ONLY. You
                    acknowledge that it is your responsibility to educate yourself on how to respond to an emergency and to
                    respond according to the specifics of your situation.</p>

                    <p style={{textAlign:'justify', fontSize:'0.85rem'}}>Temporary Suspension: The Products or Services may be suspended temporarily without notice for
                    security reasons, system failure, maintenance and repair, or other circumstances. You agree that you will
                    not be entitled to any refund or rebate for such suspensions.</p>

                    <p style={{textAlign:'justify', fontSize:'0.85rem'}}>Equipment, ISP, and Carrier. You acknowledge that the availability of the Products, Monitoring Products,
                    or Services is dependent on (i) your computer, mobile device, commercial or battery power as
                    appropriate, home wiring, and other related equipment, (ii) your Internet service provider (“ISP”), and
                    (iii) your mobile device carrier (“Carrier”). You acknowledge that you are responsible for compliance with
                    all applicable agreements, terms of use/service, and other policies of your ISP and Carrier.</p>

                    <p style={{textAlign:'justify', fontSize:'1rem', fontWeight:'bold'}}>K. Fees & payment</p>

                    <p style={{textAlign:'justify', fontSize:'0.85rem'}}>Certain Services may be provided for a fee. You shall promptly pay all applicable fees in connection with
                    the Products, Monitoring Products, and Services selected by you.</p>

                    <p style={{textAlign:'justify', fontSize:'1rem', fontWeight:'bold'}}>L. Security</p>

                    <p style={{textAlign:'justify', fontSize:'0.85rem'}}>You hereby acknowledge that the internet and telecommunications providers’ networks are inherently
                    insecure, and while Enviro-Stewards makes reasonable efforts to follow or implement security best
                    practices, Enviro-Stewards cannot guarantee that unauthorized third parties will not be able to defeat our
                    security measures or use your personal information for improper purposes. Enviro-Stewards’ Privacy
                    Statement is available at <a href='https://enviro-stewards.com/privacy-policy/' target='_blank'>https://enviro-stewards.com/privacy-policy/</a>.</p>

                    <p style={{textAlign:'justify', fontSize:'1rem', fontWeight:'bold'}}>M. Product, monitoring products & services disclaimer</p>

                    <p style={{textAlign:'justify', fontSize:'0.85rem'}}>NOTWITHSTANDING ANYTHING TO THE CONTRARY AND TO THE MAXIMUM EXTENT
                    PERMITTED BY APPLICABLE LAW, ENVIRO-STEWARDS PROVIDES THE PRODUCTS,
                    MONITORING PRODUCTS, AND SERVICES “AS-IS” AND DISCLAIMS ALL WARRANTIES AND
                    CONDITIONS, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING THE WARRANTIES
                    OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT,
                    ACCURACY, AND NON-INFRINGEMENT OF THIRD-PARTY RIGHTS. ENVIRO-STEWARDS DOES
                    NOT GUARANTEE ANY SPECIFIC RESULTS FROM THE USE OF THE PRODUCT SOFTWARE OR
                    SERVICES. ENVIRO-STEWARDS MAKES NO WARRANTY THAT THE PRODUCT SOFTWARE OR
                    SERVICES WILL BE UNINTERRUPTED, FREE OF VIRUSES OR OTHER HARMFUL CODE, TIMELY,
                    SECURE, OR ERROR-FREE.</p>

                    <p style={{textAlign:'justify', fontSize:'0.85rem'}}>ENVIRO-STEWARDS AND OUR LICENSORS, SUPPLIERS, AUTHORIZED RE-SELLERS AND SUBCONTRACTORS MAKE NO WARRANTY THAT DEFECTS WILL BE CORRECTED OR THAT THE
                    PRODUCT SOFTWARE OR SERVICES: (I) WILL MEET YOUR REQUIREMENTS; (II) WILL BE 
                    RMIS Usage Agreement – v01 20240226 pg. 6 of 7
                    COMPATIBLE WITH YOUR NETWORK, COMPUTER, OR MOBILE DEVICE; (III) WILL BE
                    AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS; OR (IV) WILL
                    BE ACCURATE OR RELIABLE. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN,
                    OBTAINED BY YOU FROM ENVIRO-STEWARDS OR THROUGH THE SERVICES SHALL CREATE
                    ANY WARRANTY</p>

                    <p style={{textAlign:'justify', fontSize:'1rem', fontWeight:'bold'}}>N. Monitoring products usage agreements, warranty & disclaimer</p>

                    <p style={{textAlign:'justify', fontSize:'0.85rem'}}>Enviro-Stewards does not warrant your Monitoring Products purchased through Enviro-Stewards to be
                    free from defects in materials and workmanship. All warranties associated to Monitoring Products are
                    provided by the manufacturer, and you agree to their product warranty terms and conditions including
                    return policies. No Enviro-Stewards reseller, distributor, agent, or employee is authorized to make any
                    modification, extension, or addition to the limited Product warranty supplied by any manufacturer of
                    Monitoring Products.</p>

                    <p style={{textAlign:'justify', fontSize:'0.85rem'}}>Monitoring Products sourced through Enviro-Stewards are not subject to these Terms unless explicitly
                    stated herein and, instead, each Monitoring Product is licensed under the terms and conditions that
                    accompany such Monitoring Product as specified by the manufacturer of the Monitoring Product. Nothing
                    in these Terms limits your rights under, or grants you rights that supersede, the terms and conditions of
                    any applicable Monitoring Product.</p>

                    <p style={{textAlign:'justify', fontSize:'1rem', fontWeight:'bold'}}>O. Limitations – safety & savings</p>

                    <p style={{textAlign:'justify', fontSize:'0.85rem'}}>You acknowledge and agree that the Product, Monitoring Products, and Services, whether standing alone
                    or when interfaced with third-party products or services are not certified for emergency response. EnviroStewards makes no warranty or representation that use of the Product, Product Monitoring Products, or
                    Services will affect or increase any level of safety. YOU UNDERSTAND THAT THE PRODUCTS,
                    PRODUCT SOFTWARE ,AND SERVICES, WHETHER STANDING ALONE OR INTERFACED WITH
                    THIRD-PARTY PRODUCTS OR SERVICES, ARE NOT A THIRD-PARTY MONITORED EMERGENCY
                    NOTIFICATION SYSTEM – ENVIRO-STEWARDS WILL NOT DISPATCH EMERGENCY AUTHORITIES
                    IN THE EVENT OF AN EMERGENCY. The Product, Monitoring Products, and Services are no substitute
                    for emergency services and all life threatening and emergency events should be directed to the
                    appropriate response services.</p>

                    <p style={{textAlign:'justify', fontSize:'0.85rem'}}>Enviro-Stewards does not guarantee or promise any specific level of utilities savings or other monetary
                    benefit from the use of the Product or Services or any feature of them. Actual utilities savings and
                    monetary benefits vary with factors beyond Enviro-Stewards’ control or knowledge. From time to time,
                    Enviro-Stewards may use the Product or Services to provide you with information that is unique to you
                    and your utilities usage and suggest an opportunity to save money on bills if you adopt suggestions or
                    features of the Product or Services. We do this to highlight an opportunity based on our analysis and
                    information. You acknowledge that this information is not a guarantee of actual savings, and you agree
                    not to seek monetary or other remedies from Enviro-Stewards if your savings differ.</p>

                    <p style={{textAlign:'justify', fontSize:'1rem', fontWeight:'bold'}}>P. Limitation of liability</p>

                    <p style={{textAlign:'justify', fontSize:'0.85rem'}}>Nothing in these Terms and in particular within this "Limitation of Liability" clause shall attempt to
                    exclude liability that cannot be excluded under applicable law. YOU USE ALL THE PRODUCT, 
                    RMIS Usage Agreement – v01 20240226 pg. 7 of 7
                    SERVICES, AND MONITORING PRODUCTS AT YOUR OWN DISCRETION AND RISK. YOU WILL BE
                    SOLELY RESPONSIBLE FOR (AND ENVIRO-STEWARDS DISCLAIMS) ANY AND ALL LOSS,
                    LIABILITY, DAMAGES, PERSONAL INJURY, LOSS OF LIFE, AND/OR PROPERTY DAMAGE,
                    INCLUDING DAMAGE TO YOUR STRUCTURE, HVAC SYSTEM, PLUMBING, WIRING, OTHER
                    PERIPHERALS CONNECTED TO OR USING THE PRODUCTS OR SERVICES, AND ALL OTHER
                    ITEMS, RESULTING FROM YOUR USE OF THE PRODUCT SOFTWARE, SERVICES, OR PRODUCTS.
                    IN NO EVENT WILL (A) ENVIRO-STEWARDS BE LIABLE FOR ANY INDIRECT, CONSEQUENTIAL,
                    EXEMPLARY, SPECIAL, OR INCIDENTAL DAMAGES, INCLUDING ANY DAMAGES FOR LOST DATA
                    OR LOST PROFITS, ARISING FROM OR RELATING TO THE PRODUCT SOFTWARE, SERVICES OR
                    PRODUCTS, AND (B) ENVIRO-STEWARDS’ TOTAL CUMULATIVE LIABILITY ARISING FROM OR
                    RELATED TO THE PRODUCT SOFTWARE, SERVICES AND PRODUCTS, WHETHER IN CONTRACT
                    OR TORT OR OTHERWISE, SHALL NOT EXCEED THE FEES ACTUALLY PAID BY YOU TO ENVIROSTEWARDS OR ENVIRO-STEWARDS’ AUTHORIZED RESELLER FOR THE PRODUCT OR SERVICES.
                    THIS LIMITATION IS CUMULATIVE AND WILL NOT BE INCREASED BY THE EXISTENCE OF MORE
                    THAN ONE INCIDENT OR CLAIM. ENVIRO-STEWARDS DISCLAIMS ALL LIABILITY OF ANY KIND
                    OF ENVIRO-STEWARDS’ LICENSORS, SUPPLIERS, AUTHORIZED RE-SELLERS, OR SUBCONTRACTORS.</p>

                    <p style={{textAlign:'justify', fontSize:'1rem', fontWeight:'bold'}}>Q. Third party indemnification</p>

                    <p style={{textAlign:'justify', fontSize:'0.85rem'}}>You agree to defend, indemnify and hold Enviro-Stewards and its licensors, suppliers, authorized resellers, and sub-contractors harmless from any damages, liabilities, claims demands, lawsuits, legal
                    action, settlements, or judgments (including costs and attorneys’ fees) by any third party due to or arising
                    out of (i) your use of the Product, Services, or purchased Monitoring Products from Enviro-Stewards, (ii)
                    your violation of these Terms, or (iii) your violation of any law or the rights of any third party. “Third
                    party” is defined herein to include any person or entity who did not electronically sign this agreement,
                    including, but not limited to, a family member, guest, neighbor, tenant, customer, employee, or insurance
                    company.</p>

                    <p style={{textAlign:'justify', fontSize:'1rem', fontWeight:'bold'}}>R. Governing law & jurisdiction</p>

                    <p style={{textAlign:'justify', fontSize:'0.85rem'}}>This Agreement is governed by and is to be construed and interpreted in accordance with the laws of
                    Canada applicable in the Province of Ontario. You irrevocably and unconditionally submit and attorn to
                    the exclusive jurisdiction of the courts of the Province of Ontario to determine all issues, whether at law or
                    in equity, arising from this Agreement. You hereby expressly waive any objection or defense that such
                    venue is an inconvenient or otherwise improper forum for any dispute arising under or in any way related
                    to this Agreement. You hereby waive any and all objections to personal jurisdiction as they may relate to
                    the enforcement of the terms of this Agreement in the Province of Ontario.</p>

                    <p style={{textAlign:'justify', fontSize:'1rem', fontWeight:'bold'}}>S. Class action & jury trial waiver</p>

                    <p style={{textAlign:'justify', fontSize:'0.85rem'}}>YOU HEREBY KNOWINGLY, IRREVOCABLY, VOLUNTARILY, AND INTENTIONALLY AGREE THAT
                    ANY ACTION, DEFENSE, COUNTERCLAIM, OR OTHER PROCEEDING ARISING UNDER OR IN ANY
                    WAY RELATED TO THIS AGREEMENT WILL BE CONDUCTED ONLY ON AN INDIVIDUAL BASIS
                    AND NOT IN A CLASS, REPRESENTATIVE, OR PRIVATE ATTORNEY GENERAL ACTION. FURTHER,
                    YOU AGREE THAT THE COURT MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS
                    AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR CLASS 
                    RMIS Usage Agreement – v01 20240226 pg. 8 of 7
                    PROCEEDING. YOU HEREBY KNOWINGLY, IRREVOCABLY, VOLUNTARILY, AND INTENTIONALLY
                    WAIVE ANY RIGHT YOU MAY HAVE TO A TRIAL BY JURY WITH RESPECT TO ANY ACTION,
                    DEFENSE, COUNTERCLAIM, OR OTHER PROCEEDING ARISING UNDER OR IN ANY WAY
                    RELATED TO THIS AGREEMENT.</p>

                    <p style={{textAlign:'justify', fontSize:'1rem', fontWeight:'bold'}}>T. Entire agreement/severability</p>

                    <p style={{textAlign:'justify', fontSize:'0.85rem'}}>These Terms constitute the entire agreement between you and Enviro-Stewards regarding the use of the
                    Product and Services. Any failure by Enviro-Stewards to exercise or enforce any right or provision of these
                    Terms shall not operate as a waiver of such right or provision. The section titles in these Terms are for
                    convenience only and have no legal or contractual effect. If any provision of these Terms is, for any
                    reason, held to be invalid or unenforceable, the other provisions of these Terms will be unimpaired, and
                    the invalid or unenforceable provision will be deemed modified so that it is valid and enforceable to the
                    maximum extent permitted by law. Neither party is an agent or partner of the other party.</p>
                </div>

                <div style={{textAlign:'center'}}>
                    <small>RMIS Usage Agreement - v01 20240226</small>
                </div>

            </Modal.Body>
            
            <Modal.Footer>
                <div style={{display:'flex', flexDirection:'column', width:'100%'}}>
                    <FormControlLabel style={{margin:'auto'}} control={<Checkbox checked={userAgreed} onChange={() => setUserAgreed(!userAgreed)} />} label="I agree to the terms of service and end user license" />

                    <div style={{height:'0.5vh'}}></div>

                    <Button style={{width:'fit-content', backgroundColor:'rgb(8,114,182)', border:'none', margin:'auto', cursor:'pointer', visibility:`${userAgreed ? 'visible' : 'hidden'}`}} onClick={async () => {
                        try{
                            let response = await axiosProtect.post('/auth/tos', {
                                uid: props.auth.id,
                                tos_status: true
                            });

                            console.log(response.status);

                            props.setUserAgreementOpen(false);
                        }catch(error){
                            props.setUserAgreementOpen(false);
                        }
                    }}>Continue</Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default UserAgreement;