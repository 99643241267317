const waterUnits = [
    {value:"m3", text:"m3"},
    {value:"usgal", text:"USG"},
    {value:"impgal", text:"Imperial gallons"},
    {value:"l", text:"Litres"},
    {value:"cf", text:"CF"},
    {value:"mcf", text:"MCF"},
    {value:"acreft", text:"Acre-foot"},
];

const elecUnits = [
    {value:"kwh", text:"kWh"},
    {value:"mwh", text:"MWh"},
    {value:"wh", text:"Wh"},
    {value:"kva", text:"kVA"},
];

const demandUnits = [
    {value:"kw", text:"kW"},
    {value:"kva", text:"kVA"},
];

const gasUnits = [
    {value:"m3", text:"m3"},
    {value:"mcf", text:"MCF"},
    {value:"mmbtu", text:"MMBTU"},
    {value:"gj", text:"GJ"},
    {value:"therm", text:"Therm"},
    {value:"l", text:"Litres"},
];

module.exports = {
    waterUnits,
    elecUnits,
    demandUnits,
    gasUnits
}