import React from "react";
import { Area, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, AreaChart } from 'recharts';

const data = [
    {
      "name": "2023-01-20",
      "actual": 2000,
      "target": 4400,
      "amt": 2400
    },
    {
      "name": "2023-01-27",
      "actual": 2000,
      "target": 3398,
      "amt": 2210
    },
    {
      "name": "2023-02-03",
      "actual": 2000,
      "target": 9800,
      "amt": 2290
    },
    {
      "name": "2023-02-10",
      "actual": 2000,
      "target": 3908,
      "amt": 2000
    },
    {
      "name": "2023-02-17",
      "actual": 2000,
      "target": 4800,
      "amt": 2181
    },
    {
      "name": "2023-02-24",
      "actual": 2000,
      "target": 3800,
      "amt": 2500
    },
    {
      "name": "2023-03-04",
      "actual": 2000,
      "target": 3200,
      "amt": 2100,
    }
  ]

const TargetEmissions = props => {
    return(
        <ResponsiveContainer height='92%' width='98%'>
            <AreaChart data={data}
                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                <defs>
                    <linearGradient id={`${props.graphId}`} x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={props.graphColor} stopOpacity={0}/>
                    <stop offset="95%" stopColor={props.graphColor} stopOpacity={0}/>
                    </linearGradient>
                </defs>
                <XAxis  dataKey="name" />
                <YAxis  />
                {/* <CartesianGrid strokeDasharray="1 1" /> */}
                <Tooltip wrapperStyle={{ outline: 'none' }}/>
                <Area type="monotone" dataKey="actual" stroke={props.graphColor2} fillOpacity={0} fill={`url(#${props.graphId})`} />
                <Area type="monotone" dataKey="target" stroke={props.graphColor} fillOpacity={0} fill={`url(#${props.graphId})`} />
            </AreaChart>
        </ResponsiveContainer>
    )
}

export default TargetEmissions;