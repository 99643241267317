import React, {useState} from 'react';

const SiteGHGDashboard = props => {
    return(
        <>
            <h4>Sites</h4>

            <p>This page will show the GHG & financial targets and projections on a per-site basis</p>
            <p>Will allow user to assess and rank each site's performance based on the metrics listed, and show a composite of each site's impact on the org's overall carbon footprint</p>
        </>
    );
}

export default SiteGHGDashboard;