import React, {useState} from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { InputAdornment, MenuItem, OutlinedInput, Select, TextField, Checkbox, Typography, FormHelperText } from '@mui/material';

const numberRegex = /^(\d+\.?\d*|\.\d+)?$/;

const HotWaterForm = props => { 
    const updateFormData = (field, subField, value) => {
        if(subField === 'fixed_value' && !numberRegex.test(value)) return;

        props.setHotFluidFormData(prev => ({
            ...prev,
            [field]: {
                ...prev[field],
                [subField]: value
            }
        }))
    }
    
    return(
        <div>

            <div>
                <FormControl style={{width:'100%'}}>
                    <FormLabel id="demo-radio-buttons-group-label">Initial temperature (T<sub>i</sub>)</FormLabel>

                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={props.hotFluidFormData.ti.type}
                        name="radio-buttons-group"
                        style={{display:'flex', flexWrap:'wrap', flexDirection:'row'}}
                    >
                        <FormControlLabel value="fixed" control={<Radio onClick={e => updateFormData('ti', 'type', 'fixed')} checked={props.hotFluidFormData.ti.type === 'fixed'}/>} label="Fixed value" />
                        <FormControlLabel value="calc" control={<Radio onClick={e => updateFormData('ti', 'type', 'calc')} checked={props.hotFluidFormData.ti.type === 'calc'}/>} label="Measured value" />
                    </RadioGroup>

                    <OutlinedInput
                        id="outlined-adornment-weight"
                        style={{padding:'0'}}
                        value={props.hotFluidFormData.ti.type === 'fixed' ? props.hotFluidFormData.ti.fixed_value : props.hotFluidFormData.ti.measured_value}
                        onChange={e => updateFormData('ti', `${props.hotFluidFormData.ti.type === 'fixed' ? 'fixed' : 'measured'}_value`, e.target.value)}
                        placeholder={props.hotFluidFormData.ti.type === 'calc' ? 'AlertLabs device name' : 'Initial temperature'}
                        error={props.hotFluidFormData.ti.error !== ''}
                        endAdornment={
                            props.hotFluidFormData.ti.type === 'calc' ? <></> : 
                            <InputAdornment position="end">
                                <Select
                                    id="demo-simple-select"
                                    style={{width:'fit-content'}}
                                    value={props.hotFluidFormData.ti.units}
                                    onChange={e => props.setHotFluidFormData(prev => ({
                                        ...prev,
                                        ti: {
                                            ...prev.ti,
                                            units: e.target.value
                                        }
                                    }))}
                                >
                                    <MenuItem value='celsius'>&deg;C</MenuItem>
                                    <MenuItem value='fahrenheit'>&deg;F</MenuItem>
                                </Select>
                            </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                        'aria-label': 'weight',
                        }}
                    />
                    {props.hotFluidFormData.ti.error !== '' &&
                        <FormHelperText error>
                            {props.hotFluidFormData.ti.error}
                        </FormHelperText>
                    }
                </FormControl>
            </div>

            <br/>

            <div>
                <FormControl style={{width:'100%'}}>
                    <FormLabel id="demo-radio-buttons-group-label">Final temperature (T<sub>f</sub>)</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={props.hotFluidFormData.tf.type}
                        name="radio-buttons-group"
                        style={{display:'flex', flexWrap:'wrap', flexDirection:'row'}}
                    >
                        <FormControlLabel value="fixed" control={<Radio onClick={e => updateFormData('tf', 'type', 'fixed')} checked={props.hotFluidFormData.tf.type === 'fixed'}/>} label="Fixed value" />
                        <FormControlLabel value="calc" control={<Radio onClick={e => updateFormData('tf', 'type', 'calc')} checked={props.hotFluidFormData.tf.type === 'calc'}/>} label="Measured value" />
                    </RadioGroup>

                    <OutlinedInput
                        id="outlined-adornment-weight"
                        style={{padding:'0'}}
                        value={props.hotFluidFormData.tf.type === 'fixed' ? props.hotFluidFormData.tf.fixed_value : props.hotFluidFormData.tf.measured_value}
                        onChange={e => updateFormData('tf', `${props.hotFluidFormData.tf.type === 'fixed' ? 'fixed' : 'measured'}_value`, e.target.value)}
                        placeholder={props.hotFluidFormData.tf.type === 'calc' ? 'AlertLabs device name' : 'Final temperature'}
                        error={props.hotFluidFormData.tf.error !== ''}
                        endAdornment={
                            props.hotFluidFormData.tf.type === 'calc' ? <></> :
                            <InputAdornment position="end">
                                <Select
                                    id="demo-simple-select"
                                    style={{width:'fit-content'}}
                                    value={props.hotFluidFormData.tf.units}
                                    onChange={e => props.setHotFluidFormData(prev => ({
                                        ...prev,
                                        tf: {
                                            ...prev.tf,
                                            units: e.target.value
                                        }
                                    }))}
                                >
                                    <MenuItem value='celsius'>&deg;C</MenuItem>
                                    <MenuItem value='fahrenheit'>&deg;F</MenuItem>
                                </Select>
                            </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                        'aria-label': 'weight',
                        }}
                    />
                    {props.hotFluidFormData.tf.error !== '' &&
                        <FormHelperText error>
                            {props.hotFluidFormData.tf.error}
                        </FormHelperText>
                    }
                </FormControl>
            </div>

            <br/>

            <div>
                <FormControl style={{width:'100%'}}>
                    <FormLabel id="demo-radio-buttons-group-label">Flow rate (Q)</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={props.hotFluidFormData.flow.type}
                        name="radio-buttons-group"
                        style={{display:'flex', flexWrap:'wrap', flexDirection:'row'}}
                    >
                        <FormControlLabel value="fixed" control={<Radio onClick={e => updateFormData('flow', 'type', 'fixed')} disabled checked={props.hotFluidFormData.flow.type === 'fixed'}/>} label="Fixed value" />
                        <FormControlLabel value="calc" control={<Radio onClick={e => updateFormData('flow', 'type', 'calc')} checked={props.hotFluidFormData.flow.type === 'calc'}/>} label="Measured value" />
                    </RadioGroup>

                    <OutlinedInput
                        id="outlined-adornment-weight"
                        style={{padding:'0'}}
                        value={props.hotFluidFormData.flow.type === 'fixed' ? props.hotFluidFormData.flow.fixed_value : props.hotFluidFormData.flow.measured_value}
                        onChange={e => updateFormData('flow', `${props.hotFluidFormData.flow.type === 'fixed' ? 'fixed' : 'measured'}_value`, e.target.value)}
                        placeholder={props.hotFluidFormData.flow.type === 'calc' ? 'AlertLabs Flowie name' : 'Flow rate'}
                        error={props.hotFluidFormData.flow.error !== ''}
                        helperText={props.hotFluidFormData.flow.error}
                        endAdornment={
                            props.hotFluidFormData.flow.type === 'calc' ? <></> :
                            <InputAdornment position="end">
                                <Select
                                    id="demo-simple-select"
                                    style={{width:'fit-content'}}
                                    value={props.hotFluidFormData.flow.units}
                                    onChange={e => props.setHotFluidFormData(prev => ({
                                        ...prev,
                                        flow: {
                                            ...prev.flow,
                                            units: e.target.value
                                        }
                                    }))}
                                >
                                    <MenuItem value='lpm'>LPM</MenuItem>
                                    <MenuItem value='usgpm'>USGPM</MenuItem>
                                </Select>
                            </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                        'aria-label': 'weight',
                        }}
                    />
                    {props.hotFluidFormData.flow.error !== '' &&
                        <FormHelperText error>
                            {props.hotFluidFormData.flow.error}
                        </FormHelperText>
                    }
                </FormControl>

                <br/><br/>

                {(props.hotFluidFormData.flow.type === 'calc' || props.hotFluidFormData.ti.type === 'calc' || props.hotFluidFormData.tf.type === 'calc') &&
                    <>
                        <TextField
                            variant='outlined'
                            style={{width:'100%'}}
                            label='AlertLabs location'
                            value={props.hotFluidFormData.alertLabsLocation}
                            onChange={e => {
                                props.setHotFluidFormData(prev => ({
                                    ...prev,
                                    alertLabsLocation: e.target.value
                                }))
                            }}
                            error={props.hotFluidFormData.locationError !== ''}
                            helperText={`${props.hotFluidFormData.locationError !== '' ? 'Required: ' : ''}All measured values' devices must be in same AlertLabs location`}
                        />
                        <br/>
                    </>
                }

                <FormControlLabel control={
                    <Checkbox checked={props.hotFluidFormData.isWater} onClick={
                        () => props.setHotFluidFormData(prev => ({
                            ...prev,
                            isWater: !props.hotFluidFormData.isWater
                        }))}
                    />} 
                    label="Medium is water"
                />

                <br/>

                {!props.hotFluidFormData.isWater && 
                    <>
                        <TextField
                            placeholder='Fluid name'
                            style={{width:'100%'}}
                            value={props.hotFluidFormData.fluidName}
                            onChange={e => {
                                props.setHotFluidFormData(prev => ({
                                    ...prev,
                                    fluidName: e.target.value
                                }));
                            }}
                            error={props.hotFluidFormData.fluidNameError !== ''}
                            helperText={props.hotFluidFormData.fluidNameError}
                        />

                        <br/>
                        <br/>

                        <>
                            <OutlinedInput
                                id="outlined-adornment-weight"
                                style={{padding:'0', width:'100%'}}
                                value={props.hotFluidFormData.fluidCp}
                                onChange={e => {
                                    if(!numberRegex.test(e.target.value)) return;
                                    
                                    props.setHotFluidFormData(prev => ({
                                        ...prev,
                                        fluidCp: e.target.value
                                    }));
                                }}
                                placeholder='Specific heat capacity @ SATP'
                                error={props.hotFluidFormData.fluidCpError !== ''}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <Typography style={{marginRight:'1em'}}>BTU/lb&#183;&#176;F</Typography>
                                    </InputAdornment>
                                }
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                'aria-label': 'weight',
                                }}
                            />
                            {props.hotFluidFormData.fluidCpError !== '' &&
                                <FormHelperText error>
                                    {props.hotFluidFormData.fluidCpError}
                                </FormHelperText>
                            }
                        </>
                    </>
                }

                <br/>
                {!props.hotFluidFormData.isWater && <br/>}

                <FormControl style={{width:'100%'}}>
                    <FormLabel id="demo-radio-buttons-group-label">Heat transfer efficiency</FormLabel>

                    <OutlinedInput
                        id="outlined-adornment-weight"
                        style={{padding:'0'}}
                        value={props.hotFluidFormData.htEfficiency}
                        onChange={e => {
                            if(!numberRegex.test(e.target.value)) return;
                            
                            props.setHotFluidFormData(prev => ({
                                ...prev,
                                htEfficiency: e.target.value
                            }));
                        }}
                        error={props.hotFluidFormData.efficiencyError !== ''}
                        endAdornment={
                            <InputAdornment position="end">
                                <Typography style={{marginRight:'1em'}}>%</Typography>
                            </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                        'aria-label': 'weight',
                        }}
                    />
                    {props.hotFluidFormData.efficiencyError !== '' ?
                        <FormHelperText error>
                            {props.hotFluidFormData.efficiencyError}
                        </FormHelperText> : 
                        <FormHelperText>
                            Compound all relevant efficiency values. (E.g., &eta;<sub>boiler</sub> = 80%, &eta;<sub>HX</sub> = 90%; &eta;<sub>overall</sub> = 72%)
                        </FormHelperText>
                    }
                </FormControl>
            </div>
        </div>
    );
}

export default HotWaterForm;