import React from 'react';
import Cofunding from './Cofunding';
import RecentActivity from './RecentActivity';

const InfoPanel = props => {
    return(
        <div style={{display:'flex', flexDirection:'column', height:'96%'}}>
            <div style={{flex:'18'}}>
                <Cofunding/>
            </div>

            <div style={{flex:'2'}}></div>

            <div style={{flex:'18'}}>
                <RecentActivity/>
            </div>
        </div>
    );
}

export default InfoPanel;