import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ButtonGroup from '@mui/material/ButtonGroup';

const InventoryTable = props => {
    return(
        <TableContainer style={{backgroundColor:'white', boxShadow:'2px 6px 12px rgb(164, 164, 164)'}}>
            <Table sx={{
                [`& .${tableCellClasses.root}`]: {
                    borderBottom: "none"
                }
            }} aria-label="simple table">
                <TableHead>
                    <Button onClick={() => props.setDrawerState('cap_cost')}>Add hardware</Button>
                    <TableRow>
                        <TableCell style={{backgroundColor:'rgb(3,117,176)', color:'white'}}>Hardware</TableCell>
                        <TableCell style={{backgroundColor:'rgb(3,117,176)', color:'white'}}>Supplier</TableCell>
                        <TableCell style={{backgroundColor:'rgb(3,117,176)', color:'white'}}>Type</TableCell>
                        <TableCell align="center" style={{backgroundColor:'rgb(3,117,176)', color:'white'}}>Currency</TableCell>
                        <TableCell align="center" style={{backgroundColor:'rgb(3,117,176)', color:'white'}}>MSRP</TableCell>
                        {props.isDetailedView && <TableCell align="center" style={{backgroundColor:'rgb(3,117,176)', color:'white'}}>Discount (%)</TableCell>}
                        {props.isDetailedView && <TableCell align="center" style={{backgroundColor:'rgb(3,117,176)', color:'white'}}>E-S Price</TableCell>}
                        <TableCell align="center" style={{backgroundColor:'rgb(3,117,176)', color:'white'}}>Quantity</TableCell>
                        <TableCell align="center" style={{backgroundColor:'rgb(3,117,176)', color:'white'}}>Total Equipment MSRP</TableCell>
                        {props.isDetailedView && <TableCell align="center" style={{backgroundColor:'#05B2A9', color:'white'}}>Total E-S Price</TableCell>}
                        <TableCell align="center" style={{backgroundColor:'#05B2A9', color:'white'}}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.values(props.equipmentItems).filter(item => item.cost_type.startsWith('cap_cost')).every(item => !item.selected) && <TableRow><TableCell colSpan={10} align='center'><p style={{margin:'0', fontWeight:'bold'}}>No hardware</p></TableCell></TableRow>}
                    {
                        Object.keys(props.equipmentItems).map(item => {
                            if(props.equipmentItems[item].selected && props.equipmentItems[item].cost_type.startsWith('cap_cost')) return(
                                <TableRow
                                    key={item}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell /*style={{backgroundColor:'#E7F8F7'}}*/>{item}</TableCell>
                                    <TableCell style={{backgroundColor:'#F3F8FB'}}>{props.equipmentItems[item].supplier}</TableCell>
                                    <TableCell slign='center'>{props.equipmentItems[item].cost_type.split('/')[1].charAt(0).toUpperCase() + props.equipmentItems[item].cost_type.split('/')[1].slice(1)}</TableCell>
                                    <TableCell align="center" style={{backgroundColor:'#F3F8FB'}}>CAD</TableCell>
                                    <TableCell align="right">${(Math.round(parseFloat(props.equipmentItems[item].msrp_cad) * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                                    {props.isDetailedView && <TableCell style={{backgroundColor:'#F3F8FB'}} align="center">{(Math.round(parseFloat(100 * props.equipmentItems[item].discount) * 10) / 10).toFixed(1)}%</TableCell>}
                                    {props.isDetailedView && <TableCell align="right">${(Math.round(parseFloat((1 - props.equipmentItems[item].discount) * props.equipmentItems[item].msrp_cad) * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>}
                                    <TableCell align="center" style={{padding:'0', backgroundColor:'#F3F8FB'}}>
                                        <ButtonGroup size='small'aria-label="Medium-sized button group">
                                            <IconButton key="one" style={{
                                                height:'2rem', width:'2rem', visibility:`${props.equipmentItems[item].quantity > 1 ? 'visible' : 'hidden'}`
                                                }} onClick={() => {
                                                    props.setEquipmentItems(prev => ({
                                                        ...prev,
                                                        [`${item}`]: {
                                                            ...prev[`${item}`],
                                                            quantity: Math.max(props.equipmentItems[item].quantity - 1, 1)
                                                        }
                                                    }))
                                                }}>-</IconButton>
                                            <p style={{margin:'0.5rem 1vh 0 1vh', height:'100%'}}>{props.equipmentItems[item].quantity}</p>
                                            <IconButton key="one" style={{height:'2rem', width:'2rem'}} onClick={() => {
                                                props.setEquipmentItems(prev => ({
                                                    ...prev,
                                                    [`${item}`]: {
                                                        ...prev[`${item}`],
                                                        quantity: props.equipmentItems[item].quantity + 1
                                                    }
                                                }))
                                            }}>+</IconButton>
                                        </ButtonGroup>
                                    </TableCell>
                                    <TableCell align="right">${(Math.round(parseFloat(props.equipmentItems[item].msrp_cad * props.equipmentItems[item].quantity) * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                                    {props.isDetailedView && <TableCell style={{backgroundColor:'#F3F8FB'}} align="right">${(Math.round(parseFloat((1 - props.equipmentItems[item].discount) * props.equipmentItems[item].msrp_cad * props.equipmentItems[item].quantity) * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>}
                                    <TableCell align="right">
                                        <p style={{color:'rgb(213,43,0)', margin:'0', fontSize:'0.75rem'}} id={`selected-item-${props.equipmentItems[item].id}`}
                                            onMouseEnter={() => {
                                                document.getElementById(`selected-item-${props.equipmentItems[item].id}`).style.textDecoration = 'underline';
                                                document.getElementById(`selected-item-${props.equipmentItems[item].id}`).style.cursor = 'pointer';
                                            }}
                                            onMouseLeave={() => {
                                                document.getElementById(`selected-item-${props.equipmentItems[item].id}`).style.textDecoration = 'none';
                                                document.getElementById(`selected-item-${props.equipmentItems[item].id}`).style.cursor = 'default';
                                            }}
                                            onClick={() => {
                                                props.setEquipmentItems(prev => ({
                                                    ...prev,
                                                    [`${item}`]: {
                                                        ...prev[`${item}`],
                                                        quantity: 0,
                                                        selected: false
                                                    }
                                                }))
                                            }}
                                        >
                                            Remove
                                        </p>
                                    </TableCell>
                                </TableRow>
                            );
                        })
                    }
                </TableBody>
                <TableHead>
                    <Button onClick={() => props.setDrawerState('subscription')}>Add subscriptions</Button>
                    <TableRow>
                        <TableCell style={{backgroundColor:'rgb(3,117,176)', color:'white'}}>Subscription</TableCell>
                        <TableCell style={{backgroundColor:'rgb(3,117,176)', color:'white'}}>Supplier</TableCell>
                        <TableCell style={{backgroundColor:'rgb(3,117,176)', color:'white'}}>Type</TableCell>
                        <TableCell align="center" style={{backgroundColor:'rgb(3,117,176)', color:'white'}}>Currency</TableCell>
                        <TableCell align="center" style={{backgroundColor:'rgb(3,117,176)', color:'white'}}>MSRP</TableCell>
                        {props.isDetailedView && <TableCell align="center" style={{backgroundColor:'rgb(3,117,176)', color:'white'}}>Discount (%)</TableCell>}
                        {props.isDetailedView && <TableCell align="center" style={{backgroundColor:'rgb(3,117,176)', color:'white'}}>E-S Price</TableCell>}
                        <TableCell align="center" style={{backgroundColor:'rgb(3,117,176)', color:'white'}}>Quantity</TableCell>
                        <TableCell align="center" style={{backgroundColor:'rgb(3,117,176)', color:'white'}}>Total Equipment MSRP</TableCell>
                        {props.isDetailedView && <TableCell align="center" style={{backgroundColor:'#05B2A9', color:'white'}}>Total E-S Price</TableCell>}
                        <TableCell align="center" style={{backgroundColor:'#05B2A9', color:'white'}}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.values(props.equipmentItems).filter(item => item.cost_type === 'subscription/annual').every(item => !item.selected) && <TableRow><TableCell colSpan={10} align='center'><p style={{margin:'0', fontWeight:'bold'}}>No subscriptions</p></TableCell></TableRow>}
                    {
                        Object.keys(props.equipmentItems).map(item => {
                            if(props.equipmentItems[item].selected && props.equipmentItems[item].cost_type === 'subscription/annual') return(
                                <TableRow
                                    key={item}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell /*style={{backgroundColor:'#E7F8F7'}}*/>{item}</TableCell>
                                    <TableCell style={{backgroundColor:'#F3F8FB'}}>{props.equipmentItems[item].supplier}</TableCell>
                                    <TableCell slign='center'>Annual fee</TableCell>
                                    <TableCell style={{backgroundColor:'#F3F8FB'}} align="center">CAD</TableCell>
                                    <TableCell align="right">${(Math.round(parseFloat(props.equipmentItems[item].msrp_cad) * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                                    {props.isDetailedView && <TableCell style={{backgroundColor:'#F3F8FB'}} align="center">{(Math.round(parseFloat(100 * props.equipmentItems[item].discount) * 10) / 10).toFixed(1)}%</TableCell>}
                                    {props.isDetailedView && <TableCell align="right">${(Math.round(parseFloat((1 - props.equipmentItems[item].discount) * props.equipmentItems[item].msrp_cad) * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>}
                                    <TableCell align="center" style={{padding:'0', backgroundColor:'#F3F8FB'}}>
                                        <ButtonGroup size='small'aria-label="Medium-sized button group">
                                            <IconButton key="one" style={{
                                                height:'2rem', width:'2rem', visibility:`${props.equipmentItems[item].quantity > 1 ? 'visible' : 'hidden'}`
                                                }} onClick={() => {
                                                    props.setEquipmentItems(prev => ({
                                                        ...prev,
                                                        [`${item}`]: {
                                                            ...prev[`${item}`],
                                                            quantity: Math.max(props.equipmentItems[item].quantity - 1, 1)
                                                        }
                                                    }))
                                                }}>-</IconButton>
                                            <p style={{margin:'0.5rem 1vh 0 1vh', height:'100%'}}>{props.equipmentItems[item].quantity}</p>
                                            <IconButton key="one" style={{height:'2rem', width:'2rem'}} onClick={() => {
                                                props.setEquipmentItems(prev => ({
                                                    ...prev,
                                                    [`${item}`]: {
                                                        ...prev[`${item}`],
                                                        quantity: props.equipmentItems[item].quantity + 1
                                                    }
                                                }))
                                            }}>+</IconButton>
                                        </ButtonGroup>
                                    </TableCell>
                                    <TableCell align="right">${(Math.round(parseFloat(props.equipmentItems[item].msrp_cad * props.equipmentItems[item].quantity) * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                                    {props.isDetailedView && <TableCell style={{backgroundColor:'#F3F8FB'}} align="right">${(Math.round(parseFloat((1 - props.equipmentItems[item].discount) * props.equipmentItems[item].msrp_cad * props.equipmentItems[item].quantity) * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>}
                                    <TableCell align="right">
                                        <p style={{color:'rgb(213,43,0)', margin:'0', fontSize:'0.75rem'}} id={`selected-item-${props.equipmentItems[item].id}`}
                                            onMouseEnter={() => {
                                                document.getElementById(`selected-item-${props.equipmentItems[item].id}`).style.textDecoration = 'underline';
                                                document.getElementById(`selected-item-${props.equipmentItems[item].id}`).style.cursor = 'pointer';
                                            }}
                                            onMouseLeave={() => {
                                                document.getElementById(`selected-item-${props.equipmentItems[item].id}`).style.textDecoration = 'none';
                                                document.getElementById(`selected-item-${props.equipmentItems[item].id}`).style.cursor = 'default';
                                            }}
                                            onClick={() => {
                                                props.setEquipmentItems(prev => ({
                                                    ...prev,
                                                    [`${item}`]: {
                                                        ...prev[`${item}`],
                                                        quantity: 0,
                                                        selected: false
                                                    }
                                                }))
                                            }}
                                        >
                                            Remove
                                        </p>
                                    </TableCell>
                                </TableRow>
                            );
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default InventoryTable;