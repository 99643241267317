import React, { useEffect, useRef, useState } from 'react';
import { Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, ComposedChart, Line, Legend } from 'recharts';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const UtilityGraph = props => {
    var init = useRef(true);
    const [data, setData] = useState([]);
    const [activeUtility, setActiveUtility] = useState('electricity');

    useEffect(() => {
        if(!init.current) return;
        init.current = false;

        if(props.utilityReadings === '') return;
        if(props.utilityReadings.length < 12) return;

        let utilities = [...props.utilityReadings].sort((a, b) => {
            if(a.year !== b.year) return a.year - b.year;
            return a.month - b.month;
        });

        let startIndex = -1, endIndex = -1;

        let startDate = new Date(props.baselineData.start_date);
        let endDate = new Date(props.baselineData.end_date);

        for(let i = 0;i<utilities.length;++i){
            if(startDate.getFullYear() === utilities[i].year && startDate.getMonth() === utilities[i].month - 1) startIndex = i;
            else if(endDate.getFullYear() === utilities[i].year && endDate.getMonth() === utilities[i].month - 1) endIndex = i;

            if(startIndex !== -1 && endIndex !== -1){
                populateData(utilities.slice(startIndex, endIndex + 1));
                break;
            }
        }
    }, []);

    const populateData = readings => {
        let chartData = [];
        for(let i = 0;i<readings.length;++i){
            let dataPoint = {
                //utilities
                water_reading_m3: readings[i].water_reading_m3,
                elec_reading_kwh: readings[i].elec_reading_kwh,
                gas_reading: readings[i].gas_reading,
                cdd_baseline_18c: readings[i].cdd_baseline_18c,
                hdd_baseline_18c: readings[i].hdd_baseline_18c,
                // constants
                elec_baseline_kwh: props.baselineData.elec_baseline_kwh,
                elec_baseline_pct: props.baselineData.elec_baseline_pct,
                gas_baseline: props.baselineData.gas_baseline,
                gas_baseline_pct: props.baselineData.gas_baseline_pct,
                date: new Date(readings[i].year, readings[i].month, 1)
            }

            chartData.push(dataPoint);
        }

        setData(chartData);
    }

    const formatReadings = reading => {
        return Math.round(reading).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const formatYAxisTick = tick => {
        if (Math.abs(tick) >= 1e6) return (tick / 1e6).toFixed(1) + 'M'; 
        else if (Math.abs(tick) >= 1e3) return (tick / 1e3).toFixed(0) + 'k';
        else return tick.toLocaleString();
    }

    const formatXAxisTick = (tick) => {
        let dateOptions = {
            month: 'short',
            year: 'numeric',
        }

        return tick.toLocaleDateString('en-US', dateOptions);
    }

    return(
        // <div></div>
        <div style={{height:'fit-content', width:'100%'}}>
            <div style={{display:'inline-block', marginBottom:'10px'}}>
                <ToggleButtonGroup
                    value={activeUtility}
                    exclusive
                    onChange={(_, newValue) => {
                        if(newValue == null) return;
                        setActiveUtility(newValue)
                    }}
                    aria-label="text alignment"
                    size='small'
                >
                    <ToggleButton value="water" aria-label="left aligned">
                        <p style={{margin:'0'}}>Water</p>
                    </ToggleButton>
                    <ToggleButton value="electricity" aria-label="centered">
                        <p style={{margin:'0'}}>Electricity</p>
                    </ToggleButton>
                    <ToggleButton value="gas" aria-label="centered">
                        <p style={{margin:'0'}}>Gas</p>
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>

            <ResponsiveContainer height={500} width='100%'>
                <ComposedChart data={data}>
                    <XAxis dataKey="date" tickLine={false} axisLine={false} tick={{ fontSize: '14px', fill: 'rgb(78,79,83)'}} 
                        tickFormatter={formatXAxisTick} textAnchor='middle'/>

                    <YAxis yAxisId="left" tickLine={false} axisLine={false} tickFormatter={formatYAxisTick} tick={{ fontSize: '14px', fill: 'rgb(78,79,83)'}}
                        label={{value: `${activeUtility?.substring(0,1).toLocaleUpperCase()}${activeUtility?.substring(1, activeUtility.length)} consumption (${activeUtility === 'electricity' ? 'kWh' : 'm3'})`,
                        position: 'outside', offset: 0, angle: -90, dx: -24, style: { fontSize: '12px', fill: 'rgb(78,79,83)'}}}/>
                    
                    <YAxis yAxisId="right" orientation='right' tickLine={false} axisLine={false} tickFormatter={formatYAxisTick} tick={{ fontSize: '14px', fill: 'rgb(78,79,83)'}}
                        label={{value: `${activeUtility === 'water' ? '' : `${activeUtility === 'electricity' ? 'Cooling' : 'Heating'} degree days (${activeUtility === 'electricity' ? 'above' : 'below'} 18C)`}`,
                        position: 'outside', offset: 0, angle: 90, dx: 24, style: { fontSize: '12px', fill: 'rgb(78,79,83)' }}}
                    />
                    
                    <Tooltip 
                        contentStyle={{
                            backgroundColor: '#F5F5F5',
                            color: '#333',
                            border:'1px solid lightgrey',
                            fontSize: '0.75em',
                        }}
                        labelFormatter={formatXAxisTick}
                        wrapperStyle={{ outline: "none" }}
                        formatter={formatReadings}
                    />

                    <Legend verticalAlign='top' iconType='line' align='center' iconSize={8} 
                        payload={
                            activeUtility === 'water' ? [
                                {value: 'Consumption (m3)', type: 'circle', color:'rgb(8,114,182)'},
                            ] : 
                            activeUtility === 'electricity' ? [
                                {value: 'Consumption (kWh)', type: 'circle', color:'rgb(8,114,182)'},
                                {value: `Baseline (${Math.round(props.baselineData.elec_baseline_pct * 100)}%)`, type: 'circle', color:'rgb(213,43,0)'},
                                {value: `CDD (above 18C)`, type: 'circle', color:'rgb(0,177,168)'},
                            ] :
                            [
                                {value: 'Consumption (m3)', type: 'circle', color:'rgb(8,114,182)'},
                                {value: `Baseline (${Math.round(props.baselineData.gas_baseline_pct * 100)}%)`, type: 'circle', color:'rgb(213,43,0)'},
                                {value: `HDD (below 18C)`, type: 'circle', color:'rgb(0,177,168)'},
                            ]
                        }
                    />

                    <CartesianGrid stroke="#f5f5f5"/>
                    
                    <Bar yAxisId="left" dataKey={activeUtility === 'water' ? 'water_reading_m3' : activeUtility ==='electricity' ? 'elec_reading_kwh' : 'gas_reading'} fill={'rgb(8,114,182)'}  />
                    {activeUtility !== 'water' && <Line yAxisId="left" type="monotone" dataKey={activeUtility === 'electricity' ? 'elec_baseline_kwh' : 'gas_baseline'} strokeWidth={3} stroke={'rgb(213,43,0)'} dot={false}/>}
                    {activeUtility !== 'water' && <Line yAxisId="right" type="monotone" dataKey={`${activeUtility === 'electricity' ? 'c' : 'h'}dd_baseline_18c`} strokeWidth={3} stroke={'rgb(0,177,168)'} dot={false}/>}
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    );
}

export default UtilityGraph;