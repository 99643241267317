import React, {useEffect, useRef, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import './styling.css';
import useAxiosProtect from '../../../../hooks/useAxiosProtect';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Button } from 'react-bootstrap';
import Table from '@mui/material/Table';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CircularProgress from '@mui/material/CircularProgress';

const RulesModal = props => {
    const floatRegex = /^(-?\d*\.?\d*)?$/;

    var init = useRef(true);
    const axiosProtect = useAxiosProtect();
    const [isLoading, setIsLoading] = useState(true);

    const [usesRules, setUsesRules] = useState(false);
    const [rules, setRules] = useState([]);

    const [ruleForm, setRuleForm] = useState({
        showHint: false,
        type: 'water',
        sensor: null,
        limitValue: '',
        targetValue: '',
        timeframe: 'always',
        startTime: dayjs('2024-01-01T15:30'),
        endTime: dayjs('2024-01-01T15:30'),
        //errors
        sensorError: '',
        limitValueError: '',
        targetValueError: '',
    });

    const [siteSensors, setSiteSensors] = useState(null)

    useEffect(() => {
        if(!init.current) return;
        init.current = false;

        let oppID = window.location.href.split('/').slice(-1);

        axiosProtect.get(`s/sites/get-site-sensors?opp_id=${oppID}`).then(response => {
            setSiteSensors(response.data.sensors);
        }).catch(error => {
            console.log(error);
        });

        axiosProtect.get(`/opp/opportunities/opp-rules?opp_id=${oppID}`).then(response => {
            if(response.status === 200){
                setUsesRules(response.data.calc_type.calc_type === 'calc')
                setRules(response.data.rules);
                setIsLoading(false);
            }
        }).catch(error => {
            console.log(error);
        });
    }, []);

    const updateRuleForm = (field, value) => {
        setRuleForm(prev => ({
            ...prev,
            [`${field}`]: value
        }));
    }

    const validateRule = () => {
        let isValid = true;
        let ruleCopy = {...ruleForm};

        if(!ruleCopy.sensor){
            isValid = false;
            ruleCopy.sensorError = 'Required';
        }else{
            ruleCopy.sensorError = '';
        }

        if(ruleCopy.limitValue === ''){
            isValid = false;
            ruleCopy.limitValueError = 'Required';
        }else{
            ruleCopy.limitValueError = '';
        }

        if(ruleCopy.targetValue === ''){
            isValid = false;
            ruleCopy.targetValueError = 'Required';
        }else{
            ruleCopy.targetValueError = '';
        }

        setRuleForm(ruleCopy);
        return isValid;
    }

    const submitRule = () => {
        if(!validateRule()) return;

        let payload = {
            opp_id: parseInt(window.location.href.split('/').slice(-1)),
            limit_value: parseFloat(ruleForm.limitValue),
            target_value: parseFloat(ruleForm.targetValue),
            sensor_id: parseInt(ruleForm.sensor.id),
            sensor_name: ruleForm.sensor.name,
            utility_type: ruleForm.type,
            savings_time: ruleForm.timeframe
        };

        if(ruleForm.timeframe === 'weekends-and-time' || ruleForm.timeframe === 'timeframe'){
            payload.start_time = `${ruleForm.startTime['$H']}:${ruleForm.startTime['$m']}`;
            payload.end_time = `${ruleForm.endTime['$H']}:${ruleForm.endTime['$m']}`;
        }else{
            payload.start_time = null;
            payload.end_time = null;
        }

        axiosProtect.post('/opp/opportunities/add-rule', payload).then(response => {
            if(response.status === 200){
                setRules([...rules, response.data.rule]);

                setRuleForm({
                    showHint: false,
                    type: 'water',
                    sensor: null,
                    limitValue: '',
                    targetValue: '',
                    timeframe: 'always',
                    startTime: dayjs('2024-01-01T15:30'),
                    endTime: dayjs('2024-01-01T15:30'),
                    sensorError: '',
                    limitValueError: '',
                    targetValueError: '',
                });
            }
        }).catch(error => {
            console.log(error);
        });
    }

    const generateTimeframeString = (timeframe, start, end) => {
        if(timeframe === 'weekends-and-time') return `${start} - ${end} daily + wkd`;

        else if(timeframe === 'timeframe') return `${start} - ${end}`;

        return timeframe;
    }

    const deleteRule = id => {
        axiosProtect.delete(`/opp/opportunities/delete-rule?rule_id=${id}`).then(response => {
            if(response.status === 200){
                let ruleCopy = rules.filter(rule => rule.id != id);
                setRules(ruleCopy);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    const closeModalAndSave = () => {
        axiosProtect.put('/opp/opportunities/edit-opp', {
            id: window.location.href.split('/').slice(-1),
            fields: [
                {
                    column: 'calc_type',
                    value: usesRules ? 'calc' : 'fixed'
                }
            ]
        }).then(response => {
            props.setRulesModalOpen(false);
        }).catch(error => {
            console.log(error);
            props.setRulesModalOpen(false);
        })
    }

    return(
        <Modal
            show={props.rulesModalOpen}
            onHide={() => props.setRulesModalOpen(false)}
            backdrop="static"
            centered
            dialogClassName="opp-modal-cw"
        >
            <Modal.Header closeButton>
                <h4 style={{fontSize:'1.2rem', margin:'0'}}>Savings Criteria</h4>
            </Modal.Header>

            <Modal.Body style={{maxHeight:'70vh', overflowY:'scroll'}}>
                {
                    isLoading ? 
                    <div style={{width:'100%', textAlign:'center'}}>
                        <Box sx={{margin:'auto' }}>
                            <CircularProgress />
                        </Box>
                    </div>
                    :
                    <>
                    <div>
                        <p style={{margin:'0'}}>How are savings calculated?</p>
                        <FormControlLabel value="fixed" control={<Radio onClick={e => setUsesRules(false)} checked={!usesRules}/>} label="Fixed daily rate" />
                        <FormControlLabel value="calc" control={<Radio onClick={e => setUsesRules(true)} checked={usesRules}/>} label="Savings rules" />
                        <br/>
                        <small>{usesRules ? 'Daily savings will be determined based on metering criteria below (will use fixed daily rate if no rules present)' : 'Listed annual savings will be compounded on a daily basis'}</small>
                    </div>

                    {
                        usesRules &&
                        <div style={{width:'100%', height:'fit-content', backgroundColor:'rgba(0,0,0,0.07)', marginTop:'1%', borderRadius:'1%', padding:'1% 2%'}}>
                            <p style={{margin:'0'}}>Savings rule <span style={{marginLeft:'1%'}}><HelpOutlineIcon style={{cursor:'pointer'}} onClick={() => updateRuleForm('showHint', true)}/></span></p>
                            {
                                ruleForm.showHint &&
                                <>
                                <small>'Max value' represents the opportunity's consumption prior to implementation. 'Target' represents the intended consumption where 100% of savings will be realized.
                                    E.g., Water line was running at a minimum of 35 LPM. During nights, the line could be turned off and reduced to 0 LPM. 'Max value' = 35 LPM and 'Target' = 0. <span style={{cursor:'pointer', color:'blue', textDecoration: 'underline'}} onClick={() => updateRuleForm('showHint', false)}>close</span>
                                </small>
                                <br/>
                                </>
                            }

                            <FormControlLabel value="water" control={<Radio onClick={e => updateRuleForm('type', e.target.value)} checked={ruleForm.type === 'water'}/>} label="Water" />
                            <FormControlLabel value="electricity" control={<Radio onClick={e => updateRuleForm('type', e.target.value)} checked={ruleForm.type === 'electricity'}/>} label="Electricity" />
                            <FormControlLabel value="gas" control={<Radio onClick={e => updateRuleForm('type', e.target.value)} checked={ruleForm.type === 'gas'}/>} label="Gas" />

                            <Autocomplete
                                style={{flex:'20'}}
                                options={siteSensors && siteSensors[`${ruleForm.type}`]}
                                value={ruleForm.sensor}
                                fullWidth
                                getOptionLabel={option => `${option.name}`}
                                onChange={(_, option) => {
                                    setRuleForm({
                                        ...ruleForm,
                                        sensor: option
                                    })
                                }}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props}>
                                        <span>{`${option.name}`}</span>
                                    </Box>
                                )}
                                renderInput={(params) => <TextField {...params} inputProps={{...params.inputProps}} label="Sensor" />}
                            />
                            {ruleForm.sensorError !== '' && <small style={{marginBottom:'10px', color:'red'}}>{ruleForm.sensorError}</small>}

                            <br/>

                            <div style={{display:'flex', flexDirection: 'row', marginTop:'1%'}}>
                                <div style={{flex:'20'}}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-amount">Max value</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-amount"
                                            error={ruleForm.limitValueError !== ''}
                                            endAdornment={<InputAdornment position="end">{`${ruleForm.type === 'water' ? 'LPM' : ruleForm.type === 'electricity' ? 'A' : 'm3'}`}</InputAdornment>}
                                            label="Max value"
                                            value={ruleForm.limitValue}
                                            onChange={e => {
                                                if(!floatRegex.test(e.target.value)) return;
                                                setRuleForm(prev => ({
                                                    ...prev,
                                                    limitValue: e.target.value
                                                }))
                                            }}
                                        />
                                        <small style={{visibility:`${ruleForm.limitValueError === '' ? 'hidden' : 'visible'}`, color:'red'}}>{ruleForm.limitValueError}</small>
                                    </FormControl>
                                </div>

                                <div style={{flex:'2'}}></div>

                                <div style={{flex:'20'}}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-amount">Target</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-amount"
                                            endAdornment={<InputAdornment position="end">{`${ruleForm.type === 'water' ? 'LPM' : ruleForm.type === 'electricity' ? 'A' : 'm3'}`}</InputAdornment>}
                                            label="Target"
                                            value={ruleForm.targetValue}
                                            error={ruleForm.targetValueError !== ''}
                                            onChange={e => {
                                                if(!floatRegex.test(e.target.value)) return;
                                                setRuleForm(prev => ({
                                                    ...prev,
                                                    targetValue: e.target.value
                                                }));
                                            }}
                                        />
                                        <small style={{visibility:`${ruleForm.limitValueError === '' ? 'hidden' : 'visible'}`, color:'red'}}>{ruleForm.targetValueError}</small>
                                    </FormControl>
                                </div>
                            </div>

                            <br/>

                            <p style={{margin:'0'}}>Applicable during:</p>
                            <FormControlLabel value="always" control={<Radio onClick={e => updateRuleForm('timeframe', e.target.value)} checked={ruleForm.timeframe === 'always'}/>} label="Always" />
                            <FormControlLabel value="timeframe" control={<Radio onClick={e => updateRuleForm('timeframe', e.target.value)} checked={ruleForm.timeframe === 'timeframe'}/>} label="Specific times" />
                            <FormControlLabel value="weekends" control={<Radio onClick={e => updateRuleForm('timeframe', e.target.value)} checked={ruleForm.timeframe === 'weekends'}/>} label="Weekends" />
                            <FormControlLabel value="weekends-and-time" control={<Radio onClick={e => updateRuleForm('timeframe', e.target.value)} checked={ruleForm.timeframe === 'weekends-and-time'}/>} label="Weekends and specific times" />

                            {
                                (ruleForm.timeframe === 'timeframe' || ruleForm.timeframe === 'weekends-and-time') &&
                                <>
                                <br/>
                                <small>Specific times occur daily, therefore rollovers are allowed. If 'From' value is later in the day than 'Until', next calendar day is implied.
                                    {ruleForm.timeframe === 'weekends-and-time' && ' With this option, weekends at ALL times of day will be monitored. Specific timeframes will only be exclusively measured during weekdays'}
                                </small>
                                <br/>
                                <div style={{display:'flex', flexDirection: 'row', marginTop:'1%'}}>
                                    <div style={{flex:'20'}}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['TimePicker', 'TimePicker']} >
                                                <TimePicker
                                                
                                                label="From"
                                                value={ruleForm.startTime}
                                                onChange={newValue => updateRuleForm('startTime', newValue)}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>

                                    <div style={{flex:'2'}}></div>

                                    <div style={{flex:'20'}}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['TimePicker', 'TimePicker']} >
                                                <TimePicker
                                                
                                                label="Until"
                                                value={ruleForm.endTime}
                                                onChange={newValue => updateRuleForm('endTime', newValue)}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>
                                </div>
                                </>
                            }

                            <Button disabled={rules.length > 0} style={{width:'100%', marginTop:'2%', backgroundColor:'rgb(8,114,182)', border:'none'}} onClick={() => {
                                submitRule();
                            }}>Add rule</Button>
                        </div>
                    }

                    <br/>

                    {
                        usesRules &&
                        <div>
                            {
                                rules.length === 0 ? 
                                <p>No active rules</p> : 
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Sensor</th>
                                            <th>Limit</th>
                                            <th>Target</th>
                                            <th>Time(s)</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            rules.map((rule, i) => (
                                                <tr>
                                                    <td>{rule.sensor_name}</td>
                                                    <td>{rule.limit_value}</td>
                                                    <td>{rule.target_value}</td>
                                                    <td>{generateTimeframeString(rule.savings_time, rule.start_time, rule.end_time)}</td>
                                                    <td><DeleteForeverIcon style={{color:'red', cursor:'pointer'}} onClick={() => {
                                                        deleteRule(rule.id);
                                                    }}/></td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            }
                        </div>
                    }
                    </>
                }
            </Modal.Body>
            
            <Modal.Footer>
                <Button style={{width:'fit-content', marginTop:'2%', backgroundColor:'rgb(8,114,182)', border:'none'}} onClick={() => {
                    closeModalAndSave();
                }}>Update</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default RulesModal;