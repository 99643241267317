import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const DeleteSiteModal = props => {
    return(
        <Modal
            show={true}
            backdrop="static"
            centered
        >
            <Modal.Header>
                <h4>Delete site?</h4>
            </Modal.Header>

            <Modal.Footer>
                <div style={{display:'flex', width:'100%'}}>
                    <Button style={{flex:'1', backgroundColor:'rgb(8,114,182)'}} onClick={() => {
                        props.invokeSiteDeletion();
                    }}>
                        Confirm
                    </Button>

                    <div style={{flex:'4'}}></div>

                    <Button style={{flex:'1'}} variant="secondary" onClick={() => {
                        props.setShowDeleteModal(false)
                    }}>Cancel</Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default DeleteSiteModal;