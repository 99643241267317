import React from 'react';
import { BarChart, CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis, ComposedChart, Bar, Cell } from 'recharts';

const data = [
    {month: 'Dec', leaks: 31, threshold: 15, ideal: 10},
    {month: 'Jan', leaks: 25, threshold: 15, ideal: 10},
    {month: 'Feb', leaks: 22, threshold: 15, ideal: 10},
    {month: 'Mar', leaks: 19, threshold: 15, ideal: 10},
    {month: 'Apr', leaks: 14, threshold: 15, ideal: 10},
    {month: 'May', leaks: 10, threshold: 15, ideal: 10},
    {month: 'Jun', leaks: 11, threshold: 15, ideal: 10},
    {month: 'Jul', leaks: 10, threshold: 15, ideal: 10},
    {month: 'Aug', leaks: 15, threshold: 15, ideal: 10},
    {month: 'Sep', leaks: 16, threshold: 15, ideal: 10},
    {month: 'Oct', leaks: 18, threshold: 15, ideal: 10},
    {month: 'Nov', leaks: 20, threshold: 15, ideal: 10},
];

const LeakGraph = props => {
    return(
        <ResponsiveContainer height='100%' width='96%'>
            <ComposedChart data={data}>
                <XAxis dataKey="month" tickLine={false} axisLine={false} tick={{ fontSize: '14px', fill: 'rgb(78,79,83)'}} 
                    interval={Math.ceil(data.length/12)} textAnchor='start'/>

                <YAxis yAxisId="left" tickLine={false} axisLine={false} tick={{ fontSize: '14px', fill: 'rgb(78,79,83)'}} domain={[0,50]}/>
                
                <Tooltip 
                    // contentStyle={{
                    //     backgroundColor: '#F5F5F5',
                    //     color: '#333',
                    //     border:'1px solid lightgrey',
                    //     fontSize: '0.75em',
                    // }}
                    // labelFormatter={formatXAxisTick}
                    // wrapperStyle={{ outline: "none" }}
                    // formatter={formatReadings}
                />

                <Legend verticalAlign='top' iconType='line' align='right' iconSize={10} 
                payload={[
                    {value: 'Threshold for compressed air leak audit', type: 'circle', color:'rgb(213,43,0)'},
                    {value: 'Ideal leak rate (10%)', type: 'circle', color:'#b5990e'},
                ]}/>

                <CartesianGrid stroke="#f5f5f5" />
                <Bar yAxisId="left" dataKey='leaks' 
                
                >
                    {
                        data.map((_, i) => (
                            <Cell fill={data[i].leaks <= 15   ? 'rgba(0,177,168, 0.75)' : 'rgba(8,114,182, 0.75)' }/>
                        ))
                    }
                </Bar>
                <Line yAxisId="left" type="monotone" dataKey="threshold" dot={false} stroke='rgb(213,43,0)' strokeDasharray='4 3' strokeWidth={2}/>
                <Line yAxisId="left" type="monotone" dataKey="ideal" dot={false} stroke='rgb(247,230,83)' strokeWidth={2}/>
            </ComposedChart>
        </ResponsiveContainer>
    );
}

export default LeakGraph;