import React, { useEffect, useRef, useState } from 'react';
import ASBRSiteView from './overall/ASBRSiteView';
import BlueRoofSitePage from './site_page/BlueRoofSitePage';

const BlueRoofContainer = props => {
    const [containerHeight, setContainerHeight] = useState('95vh');
    const [specificSite, setSpecificSite] = useState(!isNaN(parseInt(window.location.href.split('/').slice(-1)[0])));

    useEffect(() => {

        const updateContainerHeight = () => {
            let navbar = document.querySelector('.navbar');
            let breadcrumbNav = document.getElementsByClassName('col s12 es-breadcrumb')[0];
  
            let navbarHeight = navbar.offsetHeight + breadcrumbNav.offsetHeight;
            let windowHeight = window.innerHeight;
            let remainingHeight = windowHeight - navbarHeight;
  
            setContainerHeight(`${remainingHeight}px`);
        };
      
        updateContainerHeight();
    
        window.addEventListener('resize', updateContainerHeight);
    
        return () => {
            window.removeEventListener('resize', updateContainerHeight);
        };
    }, []);

    return(
        <div style={{padding:'1% 2%', backgroundColor:'#f5f5f5', height: containerHeight, overflowX:'hidden'}}>
            {
                specificSite ? 
                <BlueRoofSitePage/>
                :
                <ASBRSiteView/>
            }
        </div>
    );
}

export default BlueRoofContainer;