import React, { useEffect, useState } from 'react';
import Badge from '@mui/material/Badge';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import dayjs from 'dayjs';

const CalendarView = props => {
    const [highlightedDays, setHighlightedDays] = useState([1, 2, 15]);

    const fetchHighlightedDays = () => {
        setHighlightedDays(props.data.map((obj, index) => (obj.value > 0 ? index + 1 : null)).filter(index => index !== null));
    };

    useEffect(() => {
        fetchHighlightedDays(props.startDate);
    }, [props.data]);

    const handleMonthChange = (date) => {
        props.setStartDate(date);
        setHighlightedDays([]);
    };

    const handleYearChange = (date) => {
        props.setStartDate(date);
        setHighlightedDays([]);
    };

    const ServerDay = metaData => {
        const { highlightedDays = [], day, outsideCurrentMonth, ...other } = metaData;
    
        const isSelected = !metaData.outsideCurrentMonth && highlightedDays.indexOf(metaData.day.date()) >= 0;
    
        return(
            <Badge
                key={metaData.day.toString()}
                overlap="circular"
                badgeContent={isSelected ? props.data[metaData.day['$D'] - 1]?.value : undefined}
                sx={{ "& .MuiBadge-badge": { color:'white', backgroundColor:'rgb(213,43,0)' } }}
            >
                <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} onClick={() => {
                    props.setCircledDate(day);
                    
                    if(props.data[day['$D'] - 1].tasks.length === 0){
                        props.setSelectedDate(props.formatDate(day.toString()))
                        props.setSelectedTasks([])
                    }else{
                        props.setSelectedDate(props.formatDate(props.data[day['$D'] - 1].tasks[0].deadline))
                        props.setSelectedTasks(props.data[day['$D'] - 1].tasks)
                    }
                }}/>
            </Badge>
        );
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
                sx={{
                    '&.MuiDateCalendar-root': {
                        margin: 'auto',
                        height: 'fit-content',
                        width: '100%',
                        maxHeight: 'none',
                        '& .MuiDayCalendar-weekDayLabel': {
                            fontSize: '1rem',
                            marginBottom:'0vh'
                        },
                        '& div[role="row"]': {
                            justifyContent: 'space-around',
                            padding:'0.1rem 0',
                        },
                        '& .MuiDayCalendar-slideTransition': {
                            minHeight: '21rem',
                            // height:'fit-content'
                        },
                        '& .MuiPickersDay-root': {
                            height: '50px',
                            width: '50px',
                            fontSize: '1rem',
                        },
                        '& .MuiDayCalendar-loadingContainer': {
                            margin: 'auto',
                        height: 'fit-content',
                        width: '100%',
                        maxHeight: 'none',
                        },
                        '& .MuiDayCalendarSkeleton-root': {
                            margin: 'auto',
                        height: 'fit-content',
                        width: '100%',
                        maxHeight: 'none',
                        },
                        '& .MuiDayCalendarSkeleton-week': {
                            margin: 'auto',
                        height: 'fit-content',
                        width: '100%',
                        maxHeight: 'none',
                        },
                        '& .MuiDayCalendarSkeleton-daySkeleton': {
                            margin: '0.5rem auto 0.5rem auto',
                        height: 'fit-content',
                        width: '100%',
                        maxHeight: 'none',
                        },
                    },
                }}
                value={props.circledDate}
                loading={props.isLoading}
                onMonthChange={handleMonthChange}
                onYearChange={handleYearChange}
                renderLoading={() => <DayCalendarSkeleton />}
                slots={{
                    day: ServerDay,
                }}
                slotProps={{
                day: {
                    highlightedDays,
                },
                }}
            />
        </LocalizationProvider>
    );
}

export default CalendarView;
