import React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import GasMeterIcon from '@mui/icons-material/GasMeter';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import EngineeringIcon from '@mui/icons-material/Engineering';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import ListAltIcon from '@mui/icons-material/ListAlt';
import BarChartIcon from '@mui/icons-material/BarChart';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import WaterDamageIcon from '@mui/icons-material/WaterDamage';
import axios from './api/axios';

const staffCrumbs = {
    home: {
        icon: <HomeIcon sx={{ mr: 0.5, color:'whitesmoke' }} fontSize="inherit" />,
        linkURL: '/dashboard',
        title: 'Home'
    },
    client: {
        icon: <CorporateFareIcon sx={{ mr: 0.5, color:'whitesmoke' }} fontSize="inherit" />,
        linkURL: '',
        title: ''
    },
    site: {
        icon: <LocationOnIcon sx={{ mr: 0.5, color:'whitesmoke' }} fontSize="inherit" />,
        linkURL: '',
        title: ''
    },
    opportunity: {
        icon: <LightbulbIcon sx={{ mr: 0.5, color:'whitesmoke' }} fontSize="inherit" />,
        linkURL: '',
        title: ''
    },
    ghgFactors: {
        icon: <GasMeterIcon sx={{ mr: 0.5, color:'whitesmoke' }} fontSize="inherit" />,
        linkURL: '/ghg-factors',
        title: 'GHG Factors'
    },
    profile: {
        icon: <ManageAccountsIcon sx={{ mr: 0.5, color:'whitesmoke' }} fontSize="inherit" />,
        linkURL: '/profile',
        title: 'Profile'
    },
    faq: {
        icon: <LiveHelpIcon sx={{ mr: 0.5, color:'whitesmoke' }} fontSize="inherit" />,
        linkURL: '/faq',
        title: 'FAQ'
    },
    projects: {
        icon: <ListAltIcon sx={{ mr: 0.5, color:'whitesmoke' }} fontSize="inherit" />,
        linkURL: '/projects',
        title: 'Projects'
    },
    blueroof: {
        icon: <WaterDamageIcon sx={{ mr: 0.5, color:'whitesmoke' }} fontSize="inherit" />,
        linkURL: '/blueroof',
        title: 'Blue Roof'
    }
};

const clientCrumbs = {
    maintenance: {
        icon: <EngineeringIcon sx={{ mr: 0.5, color:'whitesmoke' }} fontSize="inherit" />,
        linkURL: '/emissions',
        title: 'Maintenance Portal'
    },
    metrics: {
        icon: <BarChartIcon sx={{ mr: 0.5, color:'whitesmoke' }} fontSize="inherit" />,
        linkURL: '',
        title: 'Raw data'
    },
    schedule: {
        icon: <AssignmentTurnedInIcon sx={{ mr: 0.5, color:'whitesmoke' }} fontSize="inherit" />,
        linkURL: '',
        title: 'Task Schedule'
    }
}

const generateStaffCrumbs = async segments => {
    if(segments.length === 0) return [];
    else if(segments[0] === 'dashboard') return [];

    if(segments[0] === 'client'){
        try{
            let response = await axios.get(`/auth/refresh/path-data?org_id=${segments[1]}`);
            let clientCrumb = staffCrumbs.client;
            clientCrumb.linkURL = `/client/${response.data.orgData._id}`
            clientCrumb.title = response.data.orgData.name;
            
            return [clientCrumb];
        }catch(err){
            return[];
        };
    }

    if(segments[0] === 'sites'){
        try{
            let response = await axios.get(`/auth/refresh/path-data?site_id=${segments[1]}`);

            let clientCrumb = staffCrumbs.client;
            clientCrumb.linkURL = `/client/${response.data.orgData._id}`
            clientCrumb.title = response.data.orgData.name;

            let siteCrumb = staffCrumbs.site;
            siteCrumb.linkURL = `/sites/${segments[1]}`;
            siteCrumb.title = response.data.siteData.name;

            return [clientCrumb, siteCrumb];
        }catch(err){
            return[];
        };
    }

    if(segments[0] === 'opportunities'){
        try{
            let response = await axios.get(`/auth/refresh/path-data?opp_id=${segments[1]}`);

            let clientCrumb = staffCrumbs.client;
            clientCrumb.linkURL = `/client/${response.data.orgData._id}`
            clientCrumb.title = response.data.orgData.name;

            let siteCrumb = staffCrumbs.site;
            siteCrumb.linkURL = `/sites/${response.data.oppData.site_id}`;
            siteCrumb.title = response.data.siteData.name;

            let oppCrumb = staffCrumbs.opportunity;
            oppCrumb.linkURL = `/opportunities/${segments[1]}`;
            oppCrumb.title = response.data.oppData.title;

            return [clientCrumb, siteCrumb, oppCrumb];
        }catch(err){
            return[];
        };
    }

    // if(segments[0] === 'projects'){
    //     try{
    //         let response = await axios.get(`/auth/refresh/path-data?site_id=${86}`);

    //         let clientCrumb = staffCrumbs.client;
    //         clientCrumb.linkURL = `/client/${response.data.orgData._id}`
    //         clientCrumb.title = response.data.orgData.name;

    //         let siteCrumb = staffCrumbs.site;
    //         siteCrumb.linkURL = `/sites/${segments[1]}`;
    //         siteCrumb.title = response.data.siteData.name;

    //         return [clientCrumb, siteCrumb, staffCrumbs.projects];
    //     }catch(err){
    //         return[];
    //     };
    // }

    if(segments[0] === 'ghg-factors') return [staffCrumbs.ghgFactors];
    if(segments[0] === 'profile') return [staffCrumbs.profile];
    if(segments[0] === 'faq') return [staffCrumbs.faq];

    if(segments[0] === 'blueroof'){
        if(segments.length === 1){
            return [staffCrumbs.blueroof];
        }else{
            let siteCrumb = staffCrumbs.site;
            siteCrumb.title = 'Blue Roof Site'
            return [staffCrumbs.blueroof, siteCrumb];
        }
    }
    
    return [];
}

const generateClientCrumbs = async segments => {
    if(segments.length === 0) return [];
    else if(segments[0] === 'dashboard') return [];

    if(segments[0] === 'site'){
        try{
            let response = await axios.get(`/auth/refresh/path-data?site_id=${segments[1]}`);

            let siteCrumb = staffCrumbs.site;
            siteCrumb.linkURL = `/sites/${segments[1]}`;
            siteCrumb.title = response.data.siteData.name;

            return [siteCrumb];
        }catch(err){
            return[];
        };
    }

    if(segments[0] === 'opportunities'){
        if(segments.length === 1){
            let oppCrumb = staffCrumbs.opportunity;
            oppCrumb.title = 'Opportunities';

            return [oppCrumb];
        }else{
            try{
                let response = await axios.get(`/auth/refresh/path-data?opp_id=${segments[1]}`);
    
                let siteCrumb = staffCrumbs.site;
                siteCrumb.linkURL = `/sites/${response.data.oppData.site_id}`;
                siteCrumb.title = response.data.siteData.name;
    
                let oppCrumb = staffCrumbs.opportunity;
                oppCrumb.linkURL = `/opportunities/${segments[1]}`;
                oppCrumb.title = response.data.oppData.title;
    
                return [siteCrumb, oppCrumb];
            }catch(err){
                return[];
            };
        }
    }

    if(segments[0] === 'ghg-factors' || segments[0] === 'emissions'){
        let emissionsCrumb = staffCrumbs.ghgFactors;
        emissionsCrumb.title = 'Emissions';
        return [emissionsCrumb];
    }
    
    if(segments[0] === 'projects'){
        try{
            let response = await axios.get(`/auth/refresh/path-data?site_id=${86}`);

            let clientCrumb = staffCrumbs.client;
            clientCrumb.linkURL = `/client/${response.data.orgData._id}`
            clientCrumb.title = response.data.orgData.name;

            let siteCrumb = staffCrumbs.site;
            siteCrumb.linkURL = `/sites/${segments[1]}`;
            siteCrumb.title = response.data.siteData.name;

            return [clientCrumb, siteCrumb, staffCrumbs.projects];
        }catch(err){
            return[];
        };
    }

    if(segments[0] === 'energy-metrics') return [clientCrumbs.metrics];
    
    if(segments[0] === 'maintenance') return [clientCrumbs.maintenance];
    if(segments[0] === 'profile') return [staffCrumbs.profile];
    if(segments[0] === 'faq') return [staffCrumbs.faq];
    if(segments[0] === 'schedule') return [clientCrumbs.schedule];
    
    return [];
}

export {staffCrumbs, clientCrumbs, generateStaffCrumbs, generateClientCrumbs}