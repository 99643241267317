import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import TextField from '@mui/material/TextField';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DoneIcon from '@mui/icons-material/Done';
import GradingIcon from '@mui/icons-material/Grading';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import gas_types from '../../../example_data/dropdowns/clientModal/gasTypes';
import { waterUnits, elecUnits, demandUnits, gasUnits } from '../../../example_data/dropdowns/clientModal/units';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import Autocomplete from '@mui/material/Autocomplete';
import useAxiosProtect from '../../../hooks/useAxiosProtect';
import Box from '@mui/material/Box';
import timezones from '../../../utils/timezones';

const SiteModal = props => {
    const [page, setPage] = useState(1);

    // form 1 - general data
    const [generalData, setGeneralData] = useState({
        name: {
            value: "",
            error: ""
        },
        address: {
            value: "",
            error: ""
        },
        sqft: {
            value: "",
            error: ""
        },
        latitude: {
            value: "",
            error: ""
        },
        longitude: {
            value: "",
            error: ""
        },
        timezone:{
            value: null,
            error: ""
        }
    });

    // form 2 - define scope
    const [scopeData, setScopeData] = useState({
        scopeErr: "",
        waterscope: {active: true},
        elecscope: {active: true},
        gasscope: {active: true},
        prodscope: {active: false},
        srcmscope: {active: false},
    });

    // form 3 - populate relevant utility data
    const [utilityData, setUtilityData] = useState({
        water: {
            cost: '0', // refers to unit costs
            units: 'm3',
            costError: ""
        },
        elec: {
            cost: '0',
            units: 'kwh',
            costError: "",
            pf: 0.9,
            pfError: ""
        },
        demand: {
            cost: '0',
            units: 'kw',
            costError: ""
        },
        gas: {
            cost: '0',
            units: 'm3',
            costError: "",
            gasType: "ng",
        }
    });

    const [unitLabels, setUnitLabels] = useState({
        waterUnits: 'm3',
        elecUnits: 'kWh',
        demandUnits: 'kW',
        gasUnits: 'm3',
    });

    // form 4 - GHG data
    const [ghgData, setGHGData] = useState({
        water_kg_co2_m3: '0',
        elec_kg_co2_kwh: '0',
        gas_kg_co2_btu: '0',
        waterErr: '',
        elecErr: '',
        gasErr: ''
    });

    const [usingGHGSet, setUsingGHGSet] = useState(true);
    const [autoCompleteValue, setAutoCompleteValue] = useState(null);
    const [ghgSets, setGHGSets] = useState([]);

    var init = useRef(true);

    const axiosProtect = useAxiosProtect();

    useEffect(() => {
        if(init.current){
            init.current = false;

            const controller = new AbortController();

            const getGHGSets = async () => {
                try{
                    const response = await axiosProtect.get(`/ghg/ghg-factors`, {
                        signal:  controller.signal
                    });

                    // convert to json from array
                    setGHGSets(response.data.factors);

                }catch(err) {
                    console.log(err)
                }
            }
    
            getGHGSets();
        }
    }, []);

    const intRegex = /^(|\d+)$/;
    const floatRegex = /^(-?\d*\.?\d*)?$/;

    const handleGeneralDataChange = e => {
        let field = e.target.id.split('-')[2];

        if(field === 'sqft' && !intRegex.test(e.target.value)) return;

        if((field === 'longitude' || field === 'latitude') && !floatRegex.test(e.target.value)) return;

        setGeneralData(prev => ({
            ...prev,
            [field]: {
                ...prev[field],
                value: e.target.value
            }
        }))
    }

    const isValidGeneralData = () => {
        let isValid = true;
        let temp = {...generalData};

        for(var key in generalData){
            if(key === 'timezone'){
                if(generalData[key].value == null){
                    temp[key].error = "Required"
                    isValid = false;
                }
            }else{
                if(generalData[key].value === ''){
                    temp[key].error = "Required"
                    isValid = false;
    
                }else if(key === 'sqft' && isNaN(parseInt(generalData[key].value))){
                    temp[key].error = "Invalid"
                    isValid = false;
    
                }else if((key === 'longitude' || key === 'latitude') && isNaN(parseFloat(generalData[key].value))){
                    temp[key].error = "Invalid"
                    isValid = false;
                }else{
                    temp[key].error = ""
                }
            }
        }

        setGeneralData(temp)
        return isValid;
    }

    const toggleScope = e => {
        let field = e.target.id.split('-')[2];
        let temp = !scopeData[field].active;

        setScopeData(prev => ({
            ...prev,
            [field]: {
                ...prev[field],
                active: temp
            }
        }))
    }

    const isValidScope = () => {
        for(var key in scopeData){
            if(key === 'scopeErr') continue;

            if(scopeData[key].active){
                setScopeData((prevState) => ({
                    ...prevState,
                    scopeErr: "",
                  }));

                return true;
            }
        }

        setScopeData((prevState) => ({
            ...prevState,
            scopeErr: "Site must have at least one component in its scope",
          }));

        return false;
    }

    const handleUtilityDropdown = (e, field, param) => {
        setUtilityData(prev => ({
            ...prev,
            [field]: {
                ...prev[field],
                [param]: e.target.value
            }
        }));

        if(param === 'units'){
            let unitGroup = field === 'water' ? waterUnits : field === 'elec' ? elecUnits : field === 'demand' ? demandUnits : gasUnits;
            let unitLabel = '';

            for(let i = 0;i<unitGroup.length;++i){
                if(unitGroup[i].value === e.target.value){
                    unitLabel = unitGroup[i].text;
                    break;
                }
            }

            setUnitLabels(prev => ({
                ...prev,
                [`${field}Units`]: unitLabel
            }));
        }
    }

    const handleUtilityInput = e => {
        if(!floatRegex.test(e.target.value)){
            return;
        }

        let objName = e.target.id.split('-');
        let field = objName[2], param = objName[3];

        setUtilityData(prev => ({
            ...prev,
            [field]: {
                ...prev[field],
                [param]: e.target.value
            }
        }));
    }

    const isValidUtilityInputs = () => {
        let isValid = true;
        let temp = {...utilityData};

        for(var key in utilityData){

            for(var field in utilityData[key]){

                if(field === 'cost' || field === 'pf'){
                    let floatValue = parseFloat(utilityData[key][field]);

                    if(utilityData[key][field] === ''){
                        temp[key][`${field}Error`] = "Required";
                        isValid = false;

                    }else if(isNaN(floatValue) || floatValue < 0){
                        temp[key][`${field}Error`] = "Invalid";
                        isValid = false;

                    }else if(field === 'pf' && floatValue > 1){
                        temp[key][`${field}Error`] = 'Power factor must from 0 to 1'
                        isValid = false;
                    }else {
                        temp[key][`${field}Error`] = '';
                    }
                }
            }
        }

        setUtilityData(temp);

        return isValid;
    }

    const updateGHGfield = (e, autoPopulate=false) => {
        if(autoPopulate){
            setAutoCompleteValue(e);

            setGHGData(prev => ({
                ...prev,
                water_kg_co2_m3: e.water_kg_co2_m3,
                elec_kg_co2_kwh: e.elec_kg_co2_kwh,
                gas_kg_co2_btu: e.gas_kg_co2_btu,
                waterErr: '',
                elecErr: '',
                gasErr: '',
            }));

            return;
        }

        if(!floatRegex.test(e.target.value)) return;

        setAutoCompleteValue(null)

        setGHGData(prev => ({
            ...prev,
            [e.target.id.split('-')[2]]: e.target.value
        }));
    }

    const isValidGHGInputs = () => {
        let waterErr = '', elecErr = '', gasErr = '';
        let isValid = true;

        if(ghgData.water_kg_co2_m3 === ''){
            waterErr = 'Required';
            isValid = false;
        }

        if(ghgData.elec_kg_co2_kwh === ''){
            elecErr = 'Required';
            isValid = false;
        }

        if(ghgData.gas_kg_co2_btu === ''){
            gasErr = 'Required';
            isValid = false;
        }

        setGHGData(prev => ({
            ...prev,
            waterErr: waterErr,
            elecErr: elecErr,
            gasErr: gasErr
        }));

        return isValid;
    }

    const incrementPage = e => {
        e.preventDefault();

        if(page === 1){
            if(isValidGeneralData()){
                setPage(2);
            }
        }else if(page === 2 && isValidScope()){
            setPage(3)
            
        }else if(page === 3 && isValidUtilityInputs()){
            setPage(4)

        }else if(page === 4 && isValidGHGInputs()){
            setPage(5);

        }else if(page === 5){
            createNewSite();
        }
    }

    const decrementPage = e => {
        e.preventDefault();

        setPage(page - 1);
    }

    const resetModal = () => {
        //reset states here
        setPage(1);

        setGeneralData({
            name: {
                value: "",
                error: ""
            },
            address: {
                value: "",
                error: ""
            },
            sqft: {
                value: "",
                error: ""
            },
            latitude: {
                value: "",
                error: ""
            },
            longitude: {
                value: "",
                error: ""
            }
        });

        setScopeData({
            scopeErr: "",
            waterscope: {active: true},
            elecscope: {active: true},
            gasscope: {active: true},
            prodscope: {active: false},
            srcmscope: {active: false},
        });

        setUtilityData({
            water: {
                cost: '0',
                units: 'm3',
                costError: ""
            },
            elec: {
                cost: '0',
                units: 'kwh',
                costError: "",
                pf: 0.9,
                pfError: ""
            },
            demand: {
                cost: '0',
                units: 'kw',
                costError: ""
            },
            gas: {
                cost: '0',
                units: 'm3',
                costError: "",
                gasType: "ng",
            }
        });

        setGHGData({
            water_kg_co2_m3: '0',
            elec_kg_co2_kwh: '0',
            gas_kg_co2_btu: '0',
            waterErr: '',
            elecErr: '',
            gasErr: ''
        });

        setUsingGHGSet(true);
        setGHGSets([]);

        props.closeSiteModal();
    }

    const createNewSite = async () => {
        //axios post new site
        let payload = {
            org_id: window.location.href.split('/')[window.location.href.split('/').length - 1],
            // general
            name: generalData.name.value,
            address: generalData.address.value,
            sqft: parseInt(generalData.sqft.value),
            latitude: parseFloat(generalData.latitude.value),
            longitude: parseFloat(generalData.longitude.value),
            tz_name: generalData.timezone.value?.name,
            tz_offset: generalData.timezone.value?.offset,
            // scope
            has_water: scopeData.waterscope.active,
            has_elec: scopeData.elecscope.active,
            has_gas: scopeData.gasscope.active,
            has_product: scopeData.prodscope.active,
            has_srcm: scopeData.srcmscope.active,
            // utility
            water_units: utilityData.water.units,
            elec_units: utilityData.elec.units,
            demand_units: utilityData.demand.units,
            gas_units: utilityData.gas.units,
            gas_type: utilityData.gas.gasType,
            water_unit_cost: parseFloat(utilityData.water.cost),
            elec_unit_cost: parseFloat(utilityData.elec.cost),
            demand_unit_cost: parseFloat(utilityData.demand.cost),
            gas_unit_cost: parseFloat(utilityData.gas.cost),
            power_factor: parseFloat(utilityData.elec.pf),
            // prod - unused for now
            prod_ghg_factor_kg_kg:0,
            prod_unit_cost:0
        }

        if(autoCompleteValue){
            payload.ghg_factor_id = autoCompleteValue.id;
        }else{
            payload.water_kg_co2_m3 = parseFloat(ghgData.water_kg_co2_m3);
            payload.elec_kg_co2_kwh = parseFloat(ghgData.elec_kg_co2_kwh);
            payload.gas_kg_co2_btu = parseFloat(ghgData.gas_kg_co2_btu);
        }
        //add new site to site mgmt page component
        try{
            let response = await axiosProtect.post('/s/sites', payload);
            if(response.status === 200 && response.data.site) props.addNewSite(response.data.site);
        }catch{
            console.log("Could not create site")
        }

        resetModal();
    }
    
    return(
        <Modal
            show={props.siteModalOpen}
            onHide={() => resetModal()}
            backdrop="static"
            centered
        >
            <Modal.Header closeButton>
                <h4>Create site</h4>
            </Modal.Header>

            <Modal.Body style={{overflow:'hidden'}}>

                {page === 1 && <div>
                    <TextField
                        style={{width:'100%', marginBottom:'1.4vh'}}
                        autoComplete='off'
                        label='Site name'
                        id='site-modal-name'
                        value={generalData.name.value}
                        onChange={handleGeneralDataChange}
                        error={generalData.name.error !== ''}
                        helperText={generalData.name.error}
                    />

                    <TextField
                        style={{width:'100%', marginBottom:'1.4vh'}}
                        autoComplete='off'
                        label='Address'
                        id='site-modal-address'
                        value={generalData.address.value}
                        onChange={handleGeneralDataChange}
                        error={generalData.address.error !== ''}
                        helperText={generalData.address.error}
                    />

                    <TextField
                        style={{width:'100%', marginBottom:'1.4vh'}}
                        autoComplete='off'
                        label='Area (sqft)'
                        id='site-modal-sqft'
                        value={generalData.sqft.value}
                        onChange={handleGeneralDataChange}
                        error={generalData.sqft.error !== ''}
                        helperText={generalData.sqft.error}
                    />

                    <TextField
                        style={{width:'100%', marginBottom:'1.4vh'}}
                        autoComplete='off'
                        label='Longitude'
                        id='site-modal-longitude'
                        value={generalData.longitude.value}
                        onChange={handleGeneralDataChange}
                        error={generalData.longitude.error !== ''}
                        helperText={generalData.longitude.error}
                    />

                    <TextField
                        style={{width:'100%', marginBottom:'1.4vh'}}
                        autoComplete='off'
                        label='Latitude'
                        id='site-modal-latitude'
                        value={generalData.latitude.value}
                        onChange={handleGeneralDataChange}
                        error={generalData.latitude.error !== ''}
                        helperText={generalData.latitude.error}
                    />

                    <Autocomplete
                        id='site-modal-tz'
                        options={timezones}
                        value={generalData.timezone.value}
                        sx={{ width: '100%', marginBottom: '0.4vh' }}
                        getOptionLabel={option => option.name}
                        onChange={(_, option) => {
                            setGeneralData(prevState => ({
                                ...prevState,
                                timezone: {
                                  ...prevState.timezone,
                                  value: option,
                                  error: ""
                                }
                              }));
                        }}
                        renderOption={(props, option) => (
                            <Box component="li" {...props}>
                                <span>{option.name} ({option.offset})</span>
                            </Box>
                        )}
                        renderInput={(params) => <TextField {...params} inputProps={{...params.inputProps}} label="Select timezone" />}
                    />

                    {generalData.timezone.error !== '' && <small style={{color:'red'}}>{generalData.timezone.error}</small>}
                </div>}

                {page === 2 && <Slide direction="left" in={page === 2} mountOnEnter unmountOnExit>
                    <div>
                        <p style={{margin:'0'}}>What is included in the scope?</p>
                        <small>Modifiable scopes coming soon. Continue for now.</small>
                        <br/>
                        <br/>
                        {scopeData.scopeErr !== '' && <small style={{color:'rgb(213,43,0)'}}>{scopeData.scopeErr}</small>}
                        <FormGroup>
                            <FormControlLabel style={{width:'fit-content'}} control={<Checkbox id="site-modal-waterscope" disabled checked={scopeData.waterscope.active} onChange={toggleScope} />} label="Water" />
                            <FormControlLabel style={{width:'fit-content'}} control={<Checkbox id="site-modal-elecscope" disabled checked={scopeData.elecscope.active} onChange={toggleScope} />} label="Electricity" />
                            <FormControlLabel style={{width:'fit-content'}} control={<Checkbox id="site-modal-gasscope" disabled checked={scopeData.gasscope.active} onChange={toggleScope} />} label="Gas" />
                            <FormControlLabel style={{width:'fit-content'}} control={<Checkbox disabled id="site-modal-prodscope" checked={scopeData.prodscope.active} onChange={toggleScope} />} label="Product" />
                            <FormControlLabel style={{width:'fit-content'}} control={<Checkbox disabled id="site-modal-srcmscope" checked={scopeData.srcmscope.active} onChange={toggleScope} />} label="SRCM" />
                        </FormGroup>
                    </div>
                </Slide>}

                {page === 3 && <Slide direction="left" in={page === 3} mountOnEnter unmountOnExit>
                    <div>
                        <div style={{backgroundColor:`${scopeData.waterscope.active ? 'rgba(0,0,0,0)' : '#ededed'}`, padding:'2% 1%'}}>
                            <p>Water</p>
                            <div style={{display:'flex', flexWrap:'wrap'}}>
                                <TextField
                                    style={{width:'45%', marginBottom:'1.4vh'}}
                                    autoComplete='off'
                                    label='Unit cost ($/m3)'
                                    id='site-modal-water-cost'
                                    value={utilityData.water.cost}
                                    onChange={handleUtilityInput}
                                    error={utilityData.water.costError !== ''}
                                    helperText={utilityData.water.costError}
                                    disabled={!scopeData.waterscope.active}
                                />

                                <div style={{width:'5%'}}></div>

                                <FormControl style={{width:'45%'}}>
                                    <InputLabel id="demo-simple-select-label">Display units</InputLabel>
                                    <Select
                                        id="demo-simple-select"
                                        value={utilityData.water.units}
                                        label="Display units"
                                        onChange={e => handleUtilityDropdown(e, 'water', 'units')}
                                        disabled={!scopeData.waterscope.active}
                                    >
                                        {waterUnits.map((_, i) => (
                                            <MenuItem value={waterUnits[i].value}>{
                                                waterUnits[i].text === 'm3' ? <span>m<sup>3</sup></span> : waterUnits[i].text
                                            }</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div style={{backgroundColor:`${scopeData.elecscope.active ? 'rgba(0,0,0,0)' : '#ededed'}`, padding:'2% 1%'}}>
                            <p>Electricity</p>

                            <div style={{display:'flex', flexWrap:'wrap'}}>
                                
                                <TextField
                                    style={{width:'45%', marginBottom:'1.4vh'}}
                                    autoComplete='off'
                                    label='Unit cost ($/kWh)'
                                    id='site-modal-elec-cost'
                                    value={utilityData.elec.cost}
                                    onChange={handleUtilityInput}
                                    error={utilityData.elec.costError !== ''}
                                    helperText={utilityData.elec.costError}
                                    disabled={!scopeData.elecscope.active}
                                />

                                <div style={{width:'5%'}}></div>

                                <FormControl style={{width:'45%'}}>
                                    <InputLabel id="demo-simple-select-label">Display units</InputLabel>
                                    <Select
                                        id="demo-simple-select"
                                        value={utilityData.elec.units}
                                        label="Display units"
                                        onChange={e => handleUtilityDropdown(e, 'elec', 'units')}
                                        disabled={!scopeData.elecscope.active}
                                    >
                                        {elecUnits.map((_, i) => (
                                            <MenuItem value={elecUnits[i].value}>{elecUnits[i].text}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <div style={{width:'5%'}}></div>

                                <TextField
                                    style={{width:'45%', marginBottom:'1.4vh'}}
                                    autoComplete='off'
                                    label='Power factor'
                                    id='site-modal-elec-pf'
                                    value={utilityData.elec.pf}
                                    onChange={handleUtilityInput}
                                    error={utilityData.elec.pfError !== ''}
                                    helperText={utilityData.elec.pfError}
                                    disabled={!scopeData.elecscope.active}
                                />
                            </div>
                        </div>

                        <div style={{backgroundColor:`${scopeData.elecscope.active ? 'rgba(0,0,0,0)' : '#ededed'}`, padding:'2% 1%'}}>
                            <p>Demand</p>
                            <div style={{display:'flex', flexWrap:'wrap'}}>
                                <TextField
                                    style={{width:'45%', marginBottom:'1.4vh'}}
                                    autoComplete='off'
                                    label='Unit cost ($/kW)'
                                    id='site-modal-demand-cost'
                                    value={utilityData.demand.cost}
                                    onChange={handleUtilityInput}
                                    error={utilityData.demand.costError !== ''}
                                    helperText={utilityData.demand.costError}
                                    disabled={!scopeData.elecscope.active}
                                />

                                <div style={{width:'5%'}}></div>

                                <FormControl style={{width:'45%'}}>
                                    <InputLabel id="demo-simple-select-label">Display units</InputLabel>
                                    <Select
                                        id="demo-simple-select"
                                        value={utilityData.demand.units}
                                        label="Display units"
                                        onChange={e => handleUtilityDropdown(e, 'demand', 'units')}
                                        disabled={!scopeData.elecscope.active}
                                    >
                                        {demandUnits.map((_, i) => (
                                            <MenuItem value={demandUnits[i].value}>{demandUnits[i].text}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div style={{backgroundColor:`${scopeData.gasscope.active ? 'rgba(0,0,0,0)' : '#ededed'}`, padding:'2% 1%'}}>
                            <p>Gas</p>
                            <div style={{display:'flex', flexWrap:'wrap'}}>
                                <TextField
                                    style={{width:'45%', marginBottom:'1.4vh'}}
                                    autoComplete='off'
                                    label='Unit cost ($/specified unit)'
                                    id='site-modal-gas-cost'
                                    value={utilityData.gas.cost}
                                    onChange={handleUtilityInput}
                                    error={utilityData.gas.costError !== ''}
                                    helperText={utilityData.gas.costError}
                                    disabled={!scopeData.gasscope.active}
                                />

                                <div style={{width:'5%'}}></div>

                                <FormControl style={{width:'45%'}}>
                                    <InputLabel>Gas type</InputLabel>
                                    <Select
                                        id="site-modal-gas-type"
                                        value={utilityData.gas.gasType}
                                        label="Gas type"
                                        onChange={e => handleUtilityDropdown(e, 'gas', 'gasType')}
                                        disabled={!scopeData.gasscope.active}
                                    >
                                        {gas_types.map((_, i) => (
                                            <MenuItem value={gas_types[i][0]}>{gas_types[i][1]}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <div style={{width:'5%'}}></div>

                                <FormControl style={{width:'45%'}}>
                                    <InputLabel id="demo-simple-select-label">Specified units</InputLabel>
                                    <Select
                                        id="demo-simple-select"
                                        value={utilityData.gas.units}
                                        label="Specified units"
                                        onChange={e => handleUtilityDropdown(e, 'gas', 'units')}
                                        disabled={!scopeData.gasscope.active}
                                    >
                                        {gasUnits.map((_, i) => (
                                            <MenuItem value={gasUnits[i].value}>{
                                                gasUnits[i].text === 'm3' ? <span>m<sup>3</sup></span> : gasUnits[i].text
                                            }</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        
                    </div>
                </Slide>}

                {page === 4 && <Slide direction="left" in={page === 4} mountOnEnter unmountOnExit>
                    <div>
                        <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label"><span>Utility CO<sub>2</sub>e intensities</span></FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                <FormControlLabel onClick={() => {let temp = !usingGHGSet; setUsingGHGSet(temp);}} control={<Radio checked={usingGHGSet}/>} label="From GHG set" />
                                <FormControlLabel onClick={() => {let temp = !usingGHGSet; setUsingGHGSet(temp);}}control={<Radio checked={!usingGHGSet}/>} label="Manual input" />
                            </RadioGroup>
                        </FormControl>

                        <br/>
                        
                        <div style={{marginTop:'1.4vh'}}>
                            {usingGHGSet && <Autocomplete
                                id="site-modal-ghg-autocomp"
                                options={ghgSets}
                                value={autoCompleteValue}
                                sx={{ width: '100%', marginBottom: '1.4vh' }}
                                getOptionLabel={option => option.territory}
                                onChange={(_, option) => updateGHGfield(option, true)}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props}>
                                        <span>{option.territory}, {option.country}</span>
                                    </Box>
                                )}
                                renderInput={(params) => <TextField {...params} inputProps={{...params.inputProps}} label="Select GHG region" />}
                            />}

                            <TextField
                                style={{marginBottom:'1.4vh', width:'100%'}}
                                label="Water kg CO2 per m3"
                                autoComplete='off'
                                id="site-modal-water_kg_co2_m3"
                                value={ghgData.water_kg_co2_m3}
                                onChange={updateGHGfield}
                                disabled={usingGHGSet}
                                error={ghgData.waterErr !== ''}
                                helperText={ghgData.waterErr}
                            />

                            <br/>

                            <TextField
                                style={{marginBottom:'1.4vh', width:'100%'}}
                                disabled={usingGHGSet}
                                autoComplete='off'
                                id="site-modal-elec_kg_co2_kwh"
                                value={ghgData.elec_kg_co2_kwh}
                                onChange={updateGHGfield}
                                label="Electricity kg CO2 per kWh"
                                error={ghgData.elecErr!== ''}
                                helperText={ghgData.elecErr}
                            />

                            <br/>

                            <TextField
                                style={{width:'100%'}}
                                autoComplete='off'
                                id="site-modal-gas_kg_co2_btu"
                                disabled={usingGHGSet}
                                value={ghgData.gas_kg_co2_btu}
                                onChange={updateGHGfield}
                                label="Gas kg CO2 per BTU"
                                error={ghgData.gasErr !== ''}
                                helperText={ghgData.gasErr}
                            />
                        </div>
                    </div>
                </Slide>}

                {page === 5 && <Slide direction="left" in={page === 5} mountOnEnter unmountOnExit>
                    <div>
                        <FormLabel className="review-details-modal-label" style={{marginBottom:'1vh'}}>General Data</FormLabel>

                        <p style={{margin:'0', fontSize:'1.5rem'}}>{generalData.name.value}</p>
                        <p style={{margin:'0'}}>{generalData.address.value}{' '}
                        ({generalData.latitude.value}, {generalData.longitude.value})</p>
                        <p style={{margin:'0'}}>{generalData.sqft.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ft<sup>2</sup></p>

                        <br/>

                        <FormLabel className="review-details-modal-label" style={{marginBottom:'1vh'}}>Scope</FormLabel>
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                            <p style={{margin:'0 5% 0 0'}}>{scopeData.waterscope.active ? <CheckIcon style={{color:'#28a745'}}/> : <CloseIcon style={{color:'#dc3545'}}/>} Water</p>
                            <p style={{margin:'0 5% 0 0'}}>{scopeData.elecscope.active ? <CheckIcon style={{color:'#28a745'}}/> : <CloseIcon style={{color:'#dc3545'}}/>} Electricity</p>
                            <p style={{margin:'0 5% 0 0'}}>{scopeData.gasscope.active ? <CheckIcon style={{color:'#28a745'}}/> : <CloseIcon style={{color:'#dc3545'}}/>} Gas</p>
                            <p style={{margin:'0 5% 0 0'}}>{scopeData.prodscope.active ? <CheckIcon style={{color:'#28a745'}}/> : <CloseIcon style={{color:'#dc3545'}}/>} Product</p>
                            <p style={{margin:'0 5% 0 0'}}>{scopeData.srcmscope.active ? <CheckIcon style={{color:'#28a745'}}/> : <CloseIcon style={{color:'#dc3545'}}/>} SRCM</p>
                        </div>

                        <br/>

                        <FormLabel className="review-details-modal-label" style={{marginBottom:'1vh'}}>Utility Info</FormLabel>
                        <div>
                            {scopeData.waterscope.active && <p style={{margin:'0'}}>Water: ${utilityData.water.cost}/{unitLabels.waterUnits === 'm3' ? <span>m<sup>3</sup></span> : unitLabels.waterUnits}</p>}
                            {scopeData.elecscope.active && <p style={{margin:'0'}}>Electricity: ${utilityData.elec.cost}/{unitLabels.elecUnits} | PF = {100 * utilityData.elec.pf}%</p>}
                            {scopeData.elecscope.active && <p style={{margin:'0'}}>Demand: ${utilityData.demand.cost}/{unitLabels.demandUnits}</p>}
                            {scopeData.gasscope.active && <p style={{margin:'0'}}>Gas: ${utilityData.gas.cost}/{unitLabels.gasUnits === 'm3' ? <span>m<sup>3</sup></span> : unitLabels.gasUnits}</p>}
                        </div>

                        <br/>

                        <FormLabel className="review-details-modal-label" style={{marginBottom:'1vh'}}>GHG Intensities</FormLabel>
                        <div>
                            {autoCompleteValue ? <p>Scope 1 & 2 intensities inherited from {autoCompleteValue.territory}, {autoCompleteValue.country} values:</p> : 
                            <p>Scope 1 & 2 intensities from manual input:</p>}
                            <p style={{margin:'0'}}><strong style={{color:'rgb(8,114,182)'}}>{ghgData.water_kg_co2_m3}</strong> kg CO<sub>2</sub>e/m<sup>3</sup> water</p>
                            <p style={{margin:'0'}}><strong style={{color:'rgb(8,114,182)'}}>{ghgData.elec_kg_co2_kwh}</strong> kg CO<sub>2</sub>e/kWh electricity</p>
                            <p style={{margin:'0'}}><strong style={{color:'rgb(8,114,182)'}}>{ghgData.gas_kg_co2_btu}</strong> kg CO<sub>2</sub>e/BTU gas</p>
                        </div>
                    </div>
                </Slide>}

            </Modal.Body>
            <Modal.Footer>
                <div style={{display:'flex', flexDirection:'row', width:'100%'}}>
                    {page > 1 && <IconButton aria-label="site-create-back" onClick={decrementPage}>
                        <ArrowBackIcon />
                    </IconButton>}

                    <div style={{flex:'10'}}></div>

                    <IconButton aria-label="site-create-next" onClick={incrementPage}>
                        {page < 4 ? <ArrowForwardIcon /> : page === 5 ? <DoneIcon /> : <GradingIcon/>}
                    </IconButton>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default SiteModal;