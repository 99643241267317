import React, { useEffect, useRef, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import useAxiosProtect from '../../hooks/useAxiosProtect';
import InventoryDrawer from './equipment/InventoryDrawer';
import InventoryTable from './equipment/InventoryTable';
import InventorySummary from './equipment/InventorySummary';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import DetailTables from './details/DetailTables';
import ProjectionsTable from './projections/ProjectionsTable';
import ReviewPage from './review/ReviewPage';

const PricingContainer = props => {
    // GLOBAL
    const [containerHeight, setContainerHeight] = useState('95vh');

    const [isLoading, setIsLoading] = useState(true);
    const [isDetailedView, setIsDetailedView] = useState(false);

    var init = useRef(true);
    const axiosProtect = useAxiosProtect();

    const [pageValue, setPageValue] = useState('equipment');
    const [prevPageValue, setPrevPageValue] = useState('equipment');

    // EQUIPMENT PAGE
    const [drawerState, setDrawerState] = useState('');

    const [equipmentItems, setEquipmentItems] = useState({});

    const [inventorySearch, setInventorySearch] = useState('');

    // DETAILS PAGE
    const [contractSpecs, setContractSpecs] = useState({
        meetingsPerYear: 1,
        deposit: 0,
        contractYears: 3,
        billRate: 300
    });

    const [unitCostSpecs, setUnitCostSpecs] = useState({
        platformUC: 15,
        meetingUC: 75,
        setupUC: 10,
        maintenanceUC: 2,
    });

    const [paymentSpecs, setPaymentSpecs] = useState({
        frequency: {
            value: 'upfront',
            disabled: false
        },
        method: {
            value: 'purchase order',
            disabled: false
        },
        terms: {
            value: 'net 30',
            disabled: false
        }
    });

    const [invoiceData, setInvoiceData] = useState({
        'Equipment': {
            multiplier: 1.25,
            cost: 0,
            interval: 'Fixed',
            monthly: 0
        },
        'LTE/subscription fees': {
            multiplier: 1.25,
            cost: 0,
            interval: 'Recurring',
            monthly: 0
        },
        'Meetings': {
            multiplier: 1.25,
            cost: 0,
            interval: 'Recurring',
            monthly: 0
        },
        'Platform': {
            multiplier: 1,
            cost: 0,
            interval: 'Recurring',
            monthly: 0
        },
        'System setup': {
            multiplier: 1,
            cost: 0,
            interval: 'Fixed',
            monthly: 0
        },
        'Maintenance': {
            multiplier: 1,
            cost: 0,
            interval: 'Recurring',
            monthly: 0
        }
    });

    // PROJECTIONS PAGE

    useEffect(() => {
        if(init.current){
            init.current = false;

            axiosProtect.get('/pr/pricing/hardware').then(response => {
                setEquipmentItems({...response.data.items})
            })
        }

        const updateContainerHeight = () => {
            let navbar = document.querySelector('.navbar');
            let breadcrumbNav = document.getElementsByClassName('col s12 es-breadcrumb')[0];
  
            let navbarHeight = navbar.offsetHeight + breadcrumbNav.offsetHeight;
            let windowHeight = window.innerHeight;
            let remainingHeight = windowHeight - navbarHeight;
  
            setContainerHeight(`${remainingHeight}px`);
        };
      
        updateContainerHeight();
    
        window.addEventListener('resize', updateContainerHeight);
    
        return () => {
        window.removeEventListener('resize', updateContainerHeight);
        };
    }, []);

    const resetPendingSelections = () => {
        let temp = {...equipmentItems};

        for(var key in temp){
            temp[key].pendingSelect = false;
            temp[key].visible = true;
        }

        setEquipmentItems(temp);
    }

    const getDirection = () => {
        const pages = ['equipment', 'details', 'projections', 'review'];
        return pages.indexOf(pageValue) > pages.indexOf(prevPageValue) ? 'left' : 'right';
    }

    const computeMonthlyCost = (unitCost, minValue) => {
        return Math.max(
            minValue,
            100 * Object.values(equipmentItems).filter(item => item.selected && item.cost_type === 'cap_cost/sensor').reduce((sum, item) => sum + item.quantity, 0) * unitCost / 100
        );
    }

    const computeInvoiceCosts = field => {
        if(field === 'Equipment'){
            let deposit = parseFloat(contractSpecs.deposit.toString().replace(/,/g,''));

            let capitalCost = Object.values(equipmentItems)
            .filter(item => item.selected && item.cost_type.startsWith('cap_cost'))
            .reduce((sum, item) => sum + (item.quantity * item.msrp_cad), 0);

            let upfrontCost = invoiceData[field].multiplier * capitalCost - deposit;

            return {
                upfront: upfrontCost,
                monthly: upfrontCost / (12 * contractSpecs.contractYears)
            };

        }else if(field === 'LTE/subscription fees'){
            let opCost = Object.values(equipmentItems)
            .filter(item => item.selected && item.cost_type.startsWith('subscription'))
            .reduce((sum, item) => sum + (item.quantity * item.msrp_cad), 0);

            let subCost = invoiceData[field].multiplier * opCost;

            return {
                upfront: subCost,
                monthly: subCost / 12
            };

        }else if(field === 'Meetings'){
            let meetingMonthlyCost = invoiceData[field].multiplier * contractSpecs.meetingsPerYear * computeMonthlyCost(unitCostSpecs.meetingUC, 1500) / 12;

            return {
                upfront: '-',
                monthly: meetingMonthlyCost
            };

        }else if(field === 'Platform'){
            return {
                upfront: '-',
                monthly: computeMonthlyCost(unitCostSpecs.platformUC, 150)
            };

        }else if(field === 'System setup'){
            let setupMonthlyCost = invoiceData[field].multiplier * computeMonthlyCost(unitCostSpecs.setupUC, 500) / (12 * contractSpecs.contractYears);

            return {
                upfront: '-',
                monthly: setupMonthlyCost
            };

        }else if(field === 'Maintenance'){
            return {
                upfront: '-',
                monthly: computeMonthlyCost(unitCostSpecs.maintenanceUC, unitCostSpecs.maintenanceUC)
            };
        }
    }

    const computeTotalCost = paidUpfront => {
        let params = ['Equipment', 'LTE/subscription fees', 'Meetings', 'Platform', 'System setup', 'Maintenance'];

        let totalCost = 0;

        for(let i = 0;i<params.length;++i){
            let costItem = computeInvoiceCosts(params[i]).monthly;

            totalCost += isNaN(parseFloat(costItem)) ? 0 : parseFloat(costItem);
        }

        totalCost *= paidUpfront ? 1 : 1.1

        return totalCost;
    }

    const updateMultipliers = contractYears => {
        let newMultiplier = Math.max(1.35 - 0.05 * (contractYears - 1), 1.15);

        newMultiplier = (Math.round(100 * newMultiplier) / 100).toFixed(2);

        setInvoiceData(prev => ({
            ...prev,
            ['Equipment']: {
                ...prev['Equipment'],
                multiplier: newMultiplier
            },
            'LTE/subscription fees': {
                ...prev['LTE/subscription fees'],
                multiplier: newMultiplier
            },
            'Meetings': {
                ...prev['Meetings'],
                multiplier: newMultiplier
            }
        }));
    }

    return(
        <div style={{padding:'1% 2%', backgroundColor:'#f5f5f5', minHeight: containerHeight, overflowX:'hidden'}}>
            <Box sx={{width: '100%', backgroundColor:'rgba(255,255,255,0.4)'}}>
                <Tabs
                    value={pageValue}
                    onChange={(e, value) => {
                        let temp = pageValue;
                        setPrevPageValue(temp);
                        setPageValue(value);
                    }}
                    variant="fullWidth"
                    sx={{
                        '& .MuiTabs-indicator': {
                            backgroundColor: 'rgb(0,177,168)'
                        },
                        '& .MuiTab-textColorSecondary.Mui-selected': {
                            color: 'rgb(0,177,168)'
                        },
                        '& .MuiTab-textColorSecondary': {
                            color: 'rgb(8,114,182)'
                        },
                    }}
                    aria-label="secondary tabs example"
                >
                    <Tab value="equipment" label="Equipment" sx={{ flexGrow: 1, textAlign: 'center' }}/>
                    <Tab value="details" label="Details" disabled={!Object.values(equipmentItems).some(item => item.selected)} sx={{ flexGrow: 1, textAlign: 'center' }}/>
                    {isDetailedView && <Tab value="projections" disabled={!Object.values(equipmentItems).some(item => item.selected)} label="Projections" sx={{ flexGrow: 1, textAlign: 'center' }}/>}
                    <Tab value="review" disabled={!Object.values(equipmentItems).some(item => item.selected)} label="Review" sx={{ flexGrow: 1, textAlign: 'center' }}/>
                </Tabs>
            </Box>
            <br/>
            {
                pageValue === 'equipment' && 
                <Slide direction={getDirection()} in={pageValue === 'equipment'} timeout={300}mountOnEnter unmountOnExit>
                    <div>
                        <Drawer open={drawerState !== ''} onClose={() => {
                            resetPendingSelections();
                            setInventorySearch('');
                            setDrawerState('');
                        }}>
                            <InventoryDrawer 
                                drawerState={drawerState} setDrawerState={setDrawerState} 
                                equipmentItems={equipmentItems} setEquipmentItems={setEquipmentItems}
                                inventorySearch={inventorySearch} setInventorySearch={setInventorySearch}
                            />
                        </Drawer>

                        <div style={{marginBottom:'1%'}}>
                            {
                                Object.values(equipmentItems).some(item => item.selected) &&
                                <Button style={{backgroundColor:'rgba(8,114,182, 0.2)'}} onClick={() => {
                                    let temp = pageValue;
                                    setPrevPageValue(temp);
                                    setPageValue('details');
                                }}>
                                    Continue to details
                                </Button>
                            }
                        </div>
                    
                        <InventoryTable setDrawerState={setDrawerState} equipmentItems={equipmentItems} setEquipmentItems={setEquipmentItems} isDetailedView={isDetailedView}/>

                        <br/>
                        
                        <div style={{display:'flex', flexDirection:'row', width:'100%', marginTop:'1vh'}}>
                            <InventorySummary style={{flex:'2'}} equipmentItems={equipmentItems} isDetailedView={isDetailedView}/>
                        </div>
                    </div>
                </Slide>
            }
            {

                pageValue === 'details' && 
                <Slide direction={getDirection()} in={pageValue === 'details'} timeout={300} mountOnEnter unmountOnExit>
                    <div>
                        <DetailTables 
                            equipmentItems={equipmentItems} setEquipmentItems={setEquipmentItems}
                            contractSpecs={contractSpecs} setContractSpecs={setContractSpecs} 
                            unitCostSpecs={unitCostSpecs} setUnitCostSpecs={setUnitCostSpecs}
                            invoiceData={invoiceData} setInvoiceData={setInvoiceData}
                            paymentSpecs={paymentSpecs} setPaymentSpecs={setPaymentSpecs}
                            computeMonthlyCost={computeMonthlyCost} computeInvoiceCosts={computeInvoiceCosts}
                            computeTotalCost={computeTotalCost} isDetailedView={isDetailedView}
                            updateMultipliers={updateMultipliers}
                        />
                    </div>
                </Slide>
            }
            {
                pageValue === 'projections' && 
                <Slide direction={getDirection()} in={pageValue === 'projections'} timeout={300} mountOnEnter unmountOnExit>
                    <div>
                        <ProjectionsTable
                            equipmentItems={equipmentItems} setEquipmentItems={setEquipmentItems}
                            contractSpecs={contractSpecs} setContractSpecs={setContractSpecs} 
                            unitCostSpecs={unitCostSpecs} setUnitCostSpecs={setUnitCostSpecs}
                            invoiceData={invoiceData} setInvoiceData={setInvoiceData}
                            paymentSpecs={paymentSpecs} setPaymentSpecs={setPaymentSpecs}
                            computeMonthlyCost={computeMonthlyCost} computeInvoiceCosts={computeInvoiceCosts}
                            computeTotalCost={computeTotalCost}
                        />
                    </div>
                </Slide>
            }
            {
                pageValue === 'review' && 
                <Slide direction={getDirection()} in={pageValue === 'review'} timeout={300} mountOnEnter unmountOnExit>
                    <div>
                        <ReviewPage
                            equipmentItems={equipmentItems} setEquipmentItems={setEquipmentItems}
                            contractSpecs={contractSpecs} setContractSpecs={setContractSpecs} 
                            unitCostSpecs={unitCostSpecs} setUnitCostSpecs={setUnitCostSpecs}
                            invoiceData={invoiceData} setInvoiceData={setInvoiceData}
                            paymentSpecs={paymentSpecs} setPaymentSpecs={setPaymentSpecs}
                            computeMonthlyCost={computeMonthlyCost} computeInvoiceCosts={computeInvoiceCosts}
                            computeTotalCost={computeTotalCost}
                        />
                    </div>
                </Slide>
            }
        </div>
    );
}

export default PricingContainer;