import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';

const ValveTable = props => {
    const columns = [
        {
            id: 'valve_name',
            label: 'Valve name',
            minWidth: 'fit-content',
            align: 'left'
        },
        {
            id: 'valve_state',
            label: 'State',
            minWidth: 'fit-content',
            align: 'center'
        },
        {
            id: 'date_modified',
            label: 'Last modified',
            minWidth: 'fit-content',
            align: 'right'
        },
    ];

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                        {columns.map((column) => (
                            <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                            >
                                <strong>{column.label}</strong>
                            </TableCell>
                        ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.valves.map((valve, i) => (
                            <TableRow key={valve.id}>
                                <TableCell align='left'>{valve.valve_name}</TableCell>
                                <TableCell align='center'>{valve.is_open ? 'open' : 'closed'}</TableCell>
                                <TableCell align='right'>{props.formatDate(valve.last_modified)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

export default ValveTable;