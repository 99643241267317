import React, { useRef } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import ButtonGroup from '@mui/material/ButtonGroup';
import Divider from '@mui/material/Divider';

const DetailTables = props => {
    // const intRegex = /^(|\d+)$/;
    const floatRegex = /^\d*\.?\d*$/;

    var editableKeyToFocus = useRef(null);

    const InputTables = () => {
        return(
            <div style={{width:'100%', display:'flex', flexDirection:'row'}}>
                <TableContainer style={{backgroundColor:'white', boxShadow:'2px 6px 12px rgb(164, 164, 164)', flex:'7'}}>
                    <Table sx={{
                        [`& .${tableCellClasses.root}`]: {
                        borderBottom: "none"
                        }
                    }} aria-label="simple table">
                        <TableHead>
                            <TableCell style={{backgroundColor:'#05B2A9', color:'white'}}>Contract parameter</TableCell>
                            <TableCell style={{backgroundColor:'#05B2A9', color:'white'}} align='center'>Quantity</TableCell>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Meetings frequency</TableCell>
                                <TableCell align='center'>
                                    <ButtonGroup size='small'aria-label="Medium-sized button group">
                                        <IconButton key="one" style={{
                                            height:'2rem', width:'2rem', visibility:`${props.contractSpecs.meetingsPerYear > 0 ? 'visible' : 'hidden'}`
                                            }} onClick={() => {
                                                props.setContractSpecs(prev => ({
                                                    ...prev,
                                                    ['meetingsPerYear']: props.contractSpecs.meetingsPerYear - 1
                                                }))
                                            }}>-</IconButton>
                                        <p style={{margin:'0.5rem 1vh 0 1vh', height:'100%'}}>{props.contractSpecs.meetingsPerYear}</p>
                                        <IconButton key="one" style={{height:'2rem', width:'2rem'}} onClick={() => {
                                            props.setContractSpecs(prev => ({
                                                ...prev,
                                                ['meetingsPerYear']: props.contractSpecs.meetingsPerYear + 1
                                            }))
                                        }}>+</IconButton>
                                    </ButtonGroup>
                                    <span style={{marginLeft:'1rem', color:'#717171'}}>meeting{props.contractSpecs.meetingsPerYear === 1 ? '' : 's'}/year</span>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Deposit</TableCell>
                                <TableCell align='center'>
                                    <Input
                                        id="standard-adornment-amount"
                                        style={{width:'65%'}}
                                        key='pricing-summary-deposit'
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        value={props.contractSpecs.deposit}
                                        disabled={props.paymentSpecs.frequency.value === 'upfront'}
                                        onChange={e => {
                                            editableKeyToFocus.current = 'pricing-summary-deposit';
                                            
                                            let newInput = e.target.value.replace(/,/g,'');

                                            if(floatRegex.test(newInput)){
                                                props.setContractSpecs(prev => ({
                                                    ...prev,
                                                    ['deposit']: newInput.trim() === '' ? newInput : newInput.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                }))
                                            }
                                        }}
                                        autoFocus={'pricing-summary-deposit' === editableKeyToFocus.current}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Contract duration</TableCell>
                                <TableCell align='center'>
                                     <ButtonGroup size='small'aria-label="Medium-sized button group">
                                        <IconButton key="one" style={{
                                            height:'2rem', width:'2rem', visibility:`${props.contractSpecs.contractYears > 1 ? 'visible' : 'hidden'}`
                                            }} onClick={() => {
                                                if(props.contractSpecs.contractYears - 1 == 1){
                                                    props.setPaymentSpecs(prev => ({
                                                        ...prev,
                                                        ['frequency']: {
                                                            ...prev['frequency'],
                                                            value: 'upfront'
                                                        },
                                                        ['method']: {
                                                            ...prev['method'],
                                                            value: 'purchase order'
                                                        },
                                                        ['terms']: {
                                                            ...prev['terms'],
                                                            value: 'net 30'
                                                        }
                                                    }));
                                                }

                                                props.updateMultipliers(props.contractSpecs.contractYears - 1);
                                                
                                                props.setContractSpecs(prev => ({
                                                    ...prev,
                                                    ['contractYears']: props.contractSpecs.contractYears - 1
                                                }))
                                            }}>-</IconButton>
                                        <p style={{margin:'0.5rem 1vh 0 1vh', height:'100%'}}>{props.contractSpecs.contractYears}</p>
                                        <IconButton key="one" style={{
                                            height:'2rem', width:'2rem', visibility:`${props.contractSpecs.contractYears < 5 ? 'visible' : 'hidden'}`
                                        }} onClick={() => {
                                            props.updateMultipliers(props.contractSpecs.contractYears + 1);

                                            props.setContractSpecs(prev => ({
                                                ...prev,
                                                ['contractYears']: props.contractSpecs.contractYears + 1
                                            }))
                                        }}>+</IconButton>
                                    </ButtonGroup>
                                    <span style={{marginLeft:'1rem', color:'#717171'}}>year{props.contractSpecs.contractYears === 1 ? '' : 's'}</span>
                                </TableCell>
                            </TableRow>
                            {props.isDetailedView && <TableRow>
                                <TableCell>Max bill out rate</TableCell>
                                <TableCell align='center'>
                                    <Input
                                        id="standard-adornment-amount"
                                        style={{width:'65%'}}
                                        key='pricing-summary-billrate'
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        endAdornment={<InputAdornment position="start">per hour</InputAdornment>}
                                        value={props.contractSpecs.billRate}
                                        // error={props.contractSpecs.billRate === ''}
                                        disabled
                                        onChange={e => {
                                            editableKeyToFocus.current = 'pricing-summary-billrate';
                                            
                                            let newInput = e.target.value.replace(/,/g,'');

                                            if(floatRegex.test(newInput)){
                                                props.setContractSpecs(prev => ({
                                                    ...prev,
                                                    ['billRate']: newInput.trim() === '' ? newInput : newInput.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                }))
                                            }
                                        }}
                                        autoFocus={'pricing-summary-billrate' === editableKeyToFocus.current}
                                    />
                                </TableCell>
                            </TableRow>}
                        </TableBody>
                    </Table>
                </TableContainer>

                {props.isDetailedView && <div style={{flex:'1'}}></div>}

                {props.isDetailedView && <TableContainer style={{backgroundColor:'white', boxShadow:'2px 6px 12px rgb(164, 164, 164)', flex:'9'}}>
                    <Table sx={{
                        [`& .${tableCellClasses.root}`]: {
                        borderBottom: "none"
                        }
                    }} aria-label="simple table">
                        <TableHead>
                            <TableCell style={{backgroundColor:'#05B2A9', color:'white'}}>Item</TableCell>
                            <TableCell style={{backgroundColor:'#05B2A9', color:'white'}}>Cost per sensor</TableCell>
                            <TableCell style={{backgroundColor:'#05B2A9', color:'white'}} align='center'>Sensors</TableCell>
                            <TableCell style={{backgroundColor:'#05B2A9', color:'white'}} align='right'>Cost</TableCell>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Monthly platform fee</TableCell>
                                <TableCell>
                                    <Input
                                        id="standard-adornment-amount"
                                        style={{width:'65%'}}
                                        key='pricing-summary-monthly-pf'
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        value={props.unitCostSpecs.platformUC}
                                        onChange={e => {
                                            editableKeyToFocus.current = 'pricing-summary-monthly-pf';
                                            
                                            let newInput = e.target.value.replace(/,/g,'');

                                            if(floatRegex.test(newInput)){
                                                props.setUnitCostSpecs(prev => ({
                                                    ...prev,
                                                    ['platformUC']: newInput.trim() === '' ? newInput : newInput.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                }))
                                            }
                                        }}
                                        autoFocus={'pricing-summary-monthly-pf' === editableKeyToFocus.current}
                                        disabled
                                    />
                                </TableCell>
                                <TableCell align='center'>{Object.values(props.equipmentItems).filter(item => item.selected && item.cost_type === 'cap_cost/sensor').reduce((sum, item) => sum + item.quantity, 0)}</TableCell>
                                <TableCell align='right'>${Math.round(props.computeMonthlyCost(props.unitCostSpecs.platformUC, 150).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Monthly meeting cost</TableCell>
                                <TableCell>
                                    <Input
                                        id="standard-adornment-amount"
                                        style={{width:'65%'}}
                                        key='pricing-summary-monthly-meeting'
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        value={props.unitCostSpecs.meetingUC}
                                        onChange={e => {
                                            editableKeyToFocus.current = 'pricing-summary-monthly-meeting';
                                            
                                            let newInput = e.target.value.replace(/,/g,'');

                                            if(floatRegex.test(newInput)){
                                                props.setUnitCostSpecs(prev => ({
                                                    ...prev,
                                                    ['meetingUC']: newInput.trim() === '' ? newInput : parseFloat(newInput).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                }))
                                            }
                                        }}
                                        autoFocus={'pricing-summary-monthly-meeting' === editableKeyToFocus.current}
                                        disabled
                                    />
                                </TableCell>
                                <TableCell align='center'>{Object.values(props.equipmentItems).filter(item => item.selected && item.cost_type === 'cap_cost/sensor').reduce((sum, item) => sum + item.quantity, 0)}</TableCell>
                                <TableCell align='right'>${Math.round(props.computeMonthlyCost(props.unitCostSpecs.meetingUC, 1500)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>System setup cost</TableCell>
                                <TableCell>
                                    <Input
                                        id="standard-adornment-amount"
                                        style={{width:'65%'}}
                                        key='pricing-summary-monthly-setup'
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        value={props.unitCostSpecs.setupUC}
                                        onChange={e => {
                                            editableKeyToFocus.current = 'pricing-summary-monthly-setup';
                                            
                                            let newInput = e.target.value.replace(/,/g,'');

                                            if(floatRegex.test(newInput)){
                                                props.setUnitCostSpecs(prev => ({
                                                    ...prev,
                                                    ['setupUC']: newInput.trim() === '' ? newInput : newInput.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                }))
                                            }
                                        }}
                                        autoFocus={'pricing-summary-monthly-setup' === editableKeyToFocus.current}
                                        disabled
                                    />
                                </TableCell>
                                <TableCell align='center'>{Object.values(props.equipmentItems).filter(item => item.selected && item.cost_type === 'cap_cost/sensor').reduce((sum, item) => sum + item.quantity, 0)}</TableCell>
                                <TableCell align='right'>${Math.round(props.computeMonthlyCost(props.unitCostSpecs.setupUC, 500)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Monthly maintenance cost</TableCell>
                                <TableCell>
                                    <Input
                                        id="standard-adornment-amount"
                                        style={{width:'65%'}}
                                        key='pricing-summary-monthly-maintenance'
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        value={props.unitCostSpecs.maintenanceUC}
                                        onChange={e => {
                                            editableKeyToFocus.current = 'pricing-summary-monthly-maintenance';
                                            
                                            let newInput = e.target.value.replace(/,/g,'');

                                            if(floatRegex.test(newInput)){
                                                props.setUnitCostSpecs(prev => ({
                                                    ...prev,
                                                    ['maintenanceUC']: newInput.trim() === '' ? newInput : newInput.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                }))
                                            }
                                        }}
                                        autoFocus={'pricing-summary-monthly-maintenance' === editableKeyToFocus.current}
                                        disabled
                                    />
                                </TableCell>
                                <TableCell align='center'>{Object.values(props.equipmentItems).filter(item => item.selected && item.cost_type === 'cap_cost/sensor').reduce((sum, item) => sum + item.quantity, 0)}</TableCell>
                                <TableCell align='right'>${Math.round(props.computeMonthlyCost(props.unitCostSpecs.maintenanceUC, props.unitCostSpecs.maintenanceUC)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>}
            </div>
        );
    };

    const InvoiceTable = () => {
        return(
            <TableContainer style={{backgroundColor:'white', boxShadow:'2px 6px 12px rgb(164, 164, 164)', width:'100%'}}>
                <Table sx={{
                        [`& .${tableCellClasses.root}`]: {
                        borderBottom: "none"
                        }
                }} aria-label="simple table">
                    <TableHead>
                        {
                            props.isDetailedView ? 
                            <>
                            <TableCell style={{backgroundColor:'rgb(3,117,176)', color:'white'}}><strong>Components</strong></TableCell>
                            <TableCell style={{backgroundColor:'rgb(3,117,176)', color:'white'}} align='center'><strong>Multiplier</strong></TableCell>
                            <TableCell style={{backgroundColor:'rgb(3,117,176)', color:'white'}} align='center'><strong>Cost</strong></TableCell>
                            <TableCell style={{backgroundColor:'rgb(3,117,176)', color:'white'}} align='center'><strong>Interval</strong></TableCell>
                            <TableCell style={{backgroundColor:'rgb(3,117,176)', color:'white'}} align='center'><strong>Monthly cost</strong></TableCell>
                            </> 
                            :
                            <>
                            <TableCell style={{backgroundColor:'rgb(3,117,176)', color:'white'}} colSpan={4}><strong>Payment Frequency</strong></TableCell>
                            <TableCell style={{backgroundColor:'rgb(3,117,176)', color:'white'}} align={props.isDetailedView ? 'center' : 'right'}><strong>Monthly cost</strong></TableCell>
                            </>
                        }
                    </TableHead>
                    <TableBody>
                        {
                            props.isDetailedView && 
                            Object.keys(props.invoiceData).map((item, i) => {
                                let costData = props.computeInvoiceCosts(item);
                                return (
                                    <TableRow>
                                        <TableCell>{item}</TableCell>
                                        <TableCell align='center'>
                                            {
                                                ['Platform', 'System setup', 'Maintenance'].includes(item) ?
                                                <span>-</span> : 
                                                <TextField variant='standard' value={props.invoiceData[item].multiplier} inputProps={{min: 0, style: { textAlign: 'center', padding:'0' }}} aria-readonly disabled/>
                                            }
                                        </TableCell>
                                        <TableCell align='right'>{isNaN(parseFloat(costData.upfront)) ? '-' : `$${(Math.round(100 * costData.upfront) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</TableCell>
                                        <TableCell align='center'>{props.invoiceData[item].interval}</TableCell>
                                        <TableCell align='right'>${(Math.round(100 * costData.monthly) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                                    </TableRow>
                                );
                            })
                        }
                        <Divider/>
                        <TableRow style={{backgroundColor:`${props.paymentSpecs.frequency.value === 'upfront' ? 'rgba(0,177,168,0.5)' : 'lightgrey'}`}}>
                            <TableCell colSpan={props.isDetailedView ? 1 : 4}>Total (if paid upfront)</TableCell>
                            {props.isDetailedView && <TableCell colSpan={3}></TableCell>}
                            <TableCell align='right'>${(Math.round(100 * props.computeTotalCost(true)) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                        </TableRow>
                        <Divider/>
                        <TableRow style={{backgroundColor:`${props.paymentSpecs.frequency.value !== 'upfront' ? 'rgba(0,177,168,0.5)' : 'lightgrey'}`}}>
                            <TableCell colSpan={props.isDetailedView ? 1 : 4}>Total (if paid annually or monthly)</TableCell>
                            {props.isDetailedView && <TableCell colSpan={3} align='center'>10%</TableCell>}
                            <TableCell align='right'>${(Math.round(100 * props.computeTotalCost(false)) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    return(
        <>
            <InputTables/>
            
            <br/>

            <div style={{display:'flex', flexDirection:'row'}}>
                <FormControl style={{flex:'4', margin:'1vh 0'}}>
                    <InputLabel id="demo-simple-select-label">Payment frequency</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={props.paymentSpecs.frequency.value}
                        label="Payment frequency"
                        disabled={props.paymentSpecs.frequency.disabled}
                        onChange={e => {
                            if(e.target.value === 'upfront'){
                                props.setContractSpecs(prev => ({
                                    ...prev,
                                    ['deposit']: '0'
                                }));
                            }

                            props.setPaymentSpecs(prev => ({
                                ...prev,
                                ['frequency']: {
                                    ...prev['frequency'],
                                    value: e.target.value
                                },
                                ['method']: {
                                    ...prev['method'],
                                    value: e.target.value === 'upfront' ? 'purchase order' : props.paymentSpecs.method.value
                                },
                                ['terms']: {
                                    ...prev['terms'],
                                    value: e.target.value === 'upfront' && props.paymentSpecs.terms.value === 'n/a' ? 'net 30' : props.paymentSpecs.terms.value
                                }
                            }));
                        }}
                    >
                        <MenuItem value='upfront'>Upfront (lump sum)</MenuItem>
                        {props.contractSpecs.contractYears > 1 && <MenuItem value='annual'>Annually</MenuItem>}
                        <MenuItem value='monthly'>Monthly</MenuItem>
                    </Select>
                </FormControl>

                <div style={{flex:'1'}}></div>

                <FormControl style={{flex:'4', margin:'1vh 0'}}>
                    <InputLabel id="demo-simple-select-label">Payment method</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={props.paymentSpecs.method.value}
                        label="Payment method"
                        disabled={props.paymentSpecs.method.disabled}
                        onChange={e => {
                            props.setPaymentSpecs(prev => ({
                                ...prev,
                                ['method']: {
                                    ...prev['method'],
                                    value: e.target.value
                                },
                                ['terms']: {
                                    ...prev['terms'],
                                    value: e.target.value === 'purchase order' ? 'net 30' : 'n/a'
                                }
                            }));
                        }}
                    >
                        <MenuItem value='purchase order'>Purchase order</MenuItem>
                        {props.paymentSpecs.frequency.value !== 'upfront' && <MenuItem value='credit card'>Credit card</MenuItem>}
                    </Select>
                </FormControl>

                <div style={{flex:'1'}}></div>

                <FormControl style={{flex:'4', margin:'1vh 0'}}>
                    <InputLabel id="demo-simple-select-label">Payment terms</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={props.paymentSpecs.terms.value}
                        label="Payment terms"
                        disabled={props.paymentSpecs.terms.disabled}
                        onChange={e => {
                            props.setPaymentSpecs(prev => ({
                                ...prev,
                                ['terms']: {
                                    ...prev['terms'],
                                    value: e.target.value
                                }
                            }));
                        }}
                    >
                        {props.paymentSpecs.method.value === 'credit card' && <MenuItem value='n/a'>N/A</MenuItem>}
                        {props.paymentSpecs.method.value === 'purchase order' && <MenuItem value='net 30'>Net 30</MenuItem>}
                        {props.paymentSpecs.method.value === 'purchase order' && <MenuItem value='net 60'>Net 60</MenuItem>}
                        {props.paymentSpecs.method.value === 'purchase order' && <MenuItem value='net 90'>Net 90</MenuItem>}
                    </Select>
                </FormControl>
            </div>

            <InvoiceTable/>
        </>
    );
}

export default DetailTables;