import React, { useEffect, useRef, useState } from 'react';
import useAxiosProtect from '../../hooks/useAxiosProtect';
import {
    ResponsiveContainer,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    Cell,
    Legend
} from "recharts";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';

const CustomLegend = ({ payload }) => {
    const legendItems = [
        { color: 'rgb(111,173,71)', value: 'Implemented or planned for implementation' },
        { color: 'rgb(8,114,182)', value: 'Not implemented' },
    ];

    return(
        <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
            {legendItems.map((item, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', margin: '0 10px' }}>
                <span
                    style={{
                        display: 'inline-block',
                        width: '12px',
                        height: '12px',
                        backgroundColor: item.color,
                        marginRight: '5px'
                    }}
                />
                <span>{item.value}</span>
                </div>
            ))}
        </div>
    );
};

const OpportunityImpactGraph = props => {
    const axiosProtect = useAxiosProtect();
    var init = useRef(true);

    const [opportunities, setOpportunities] = useState([]);
    const [highlightedIndex, setHighlightedIndex] = useState(null);
    const [selectedOpp, setSelectedOpp] = useState(null);

    const [sortCriteria, setSortCriteria] = useState('ghg');

    const [showContent, setShowContent] = useState(true);

    useEffect(() => {
        if(init.current){
            init.current = false;

            axiosProtect.get(`/opp/opportunities/get-opps?site_id=${props.siteID}`).then(response => {
                let opps = [];
    
                for(let i = 0;i<response.data.opportunities.length;++i){
                    let opp = response.data.opportunities[i];
                    
                    //total ghg savings
                    let waterGHG = opp.has_water ? opp.water_savings_m3_year * opp.water_kg_co2_m3 : 0;
                    let elecGHG = opp.has_electricity ? opp.electricity_savings_kwh_year * opp.electricity_kg_co2_kwh : 0;
                    let gasGHG = opp.has_gas ? opp.gas_savings_units_year * opp.gas_kg_co2_btu * opp.gas_btu_per_unit : 0;
                    
                    let ghgSavingsTonnes = (waterGHG + elecGHG + gasGHG) / 1000;
                    opp.ghg_savings_tonnes = ghgSavingsTonnes;
    
                    //total financial savings
                    let waterCost = opp.has_water ? opp.water_savings_m3_year * opp.water_unit_cost_m3 : 0;
                    let elecCost = opp.has_electricity ? opp.electricity_savings_kwh_year * opp.electricity_unit_cost_kwh : 0;
                    let gasCost = opp.has_gas ? opp.gas_savings_units_year * opp.gas_unit_cost : 0;
                    
                    let ghgSavingsFinancial = waterCost + elecCost + gasCost
                    opp.savings_financial = ghgSavingsFinancial;
    
                    opps.push(opp);
                }
    
                setOpportunities(opps.sort((a, b) => b.ghg_savings_tonnes - a.ghg_savings_tonnes));
            });
        }
        
        const updateShowState = () => {
            setShowContent(false);
        };
      
        updateShowState();
    
        window.addEventListener('resize', updateShowState);
    
        return () => {
            window.removeEventListener('resize', updateShowState);
        };
    }, []);

    useEffect(() => {
        if(!showContent) setShowContent(true);
    }, [showContent]);

    const formatXAxisTick = tick => {
        if(sortCriteria === 'ghg'){
            return (Math.round(100 * tick) / 100).toFixed(2);
        }else{
            return `$${(Math.round(tick * 100) / 100).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
        }
    };

    const formatYAxisTick = tick => {
        return tick;
    }

    const formatReadings = reading => {
        if(sortCriteria === 'ghg'){
            return [`${(Math.round(100 * reading) / 100).toFixed(2)} tonnes CO2/year`, 'GHG savings'];
        }else{
            return [`$${(Math.round(reading * 100) / 100).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/year`, 'Financial savings'];
        }
        
    }

    // const handleBarClick = (data, index) => {
    //     setSelectedOpp(opportunities[index]);
    // };

    const generateDateString = unixMS => {
        let date = new Date(parseInt(unixMS));

        let dateOptions = {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        }

        return date.toLocaleDateString('en-US', dateOptions);
    }

    return(
        <>
        {
            showContent &&
        
            <div style={{display:'flex', flexDirection:'row', height:`${Math.max(60,10 * opportunities.length)}vh`}}>
                <div style={{flex:"3", textAlign:'center'}}>
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={sortCriteria}
                            onChange={e => {
                                if(e.target.value === sortCriteria) return;
                                
                                setSelectedOpp(null);
                                let opps = e.target.value === 'ghg' ? opportunities.sort((a, b) => b.ghg_savings_tonnes - a.ghg_savings_tonnes) : opportunities.sort((a, b) => b.savings_financial - a.savings_financial)
                                setSortCriteria(e.target.value);
                                setOpportunities(opps)
                            }}
                        >
                            <FormControlLabel value="ghg" control={<Radio />} label="GHG reduction (tonnes CO2/year)" />
                            <FormControlLabel value="cost" control={<Radio />} label="Financial savings ($/yr)" />
                        </RadioGroup>
                    </FormControl>

                    <ResponsiveContainer height='92%' width='100%'>
                        <BarChart data={opportunities} layout='vertical'>
                            <XAxis tickLine={false} axisLine={false} type='number' tickFormatter={formatXAxisTick} orientation="top" style={{fontSize:'0.9rem'}}/>
                            <YAxis dataKey="title" tickLine={false} axisLine={false} type='category' width={125} tickFormatter={formatYAxisTick} style={{fontSize:'0.9rem'}}/>
                            <Tooltip 
                                contentStyle={{
                                    backgroundColor: '#F5F5F5',
                                    color: '#333',
                                    border:'1px solid lightgrey',
                                    fontSize: '0.75em',
                                }}
                                labelFormatter={formatYAxisTick}
                                wrapperStyle={{ outline: "none" }}
                                formatter={formatReadings}
                            />
                            <Legend content={<CustomLegend />} />
                            
                            <Bar dataKey={sortCriteria === 'ghg' ? 'ghg_savings_tonnes' : 'savings_financial'} onClick={(data, index) => {}}
                                onMouseEnter={(data, index) => {
                                    setHighlightedIndex(index);
                                    setSelectedOpp(opportunities[index]);
                                }}
                                onMouseLeave={() => {
                                    setHighlightedIndex(null);
                                    setSelectedOpp(null);
                                }}>
                                {opportunities.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={
                                        index === highlightedIndex ? 'rgb(0,177,168)' : (entry.project_progress_status === 'complete' || entry.start_date != null || entry.end_date != null) ? 'rgb(111,173,71)' : 'rgb(8,114,182)'} 
                                        style={{cursor:'pointer'}}/>
                                ))}
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </div>

                <div style={{flex:"1", padding:'2%'}}>
                    {
                        selectedOpp &&
                        <div style={{textAlign:'center', padding:'2%', backgroundColor: props.adminView ? 'white' : '#f5f5f5'}}>
                            <Typography variant="h6" gutterBottom>
                                {selectedOpp.title}
                            </Typography>
                            {
                                selectedOpp.has_water && 
                                <Typography variant="subtitle2">
                                    Water savings: {(Math.round(selectedOpp.water_savings_m3_year * 100) / 100).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} m3/year
                                </Typography>
                            }
                            {
                                selectedOpp.has_electricity && 
                                <Typography variant="subtitle2">
                                    Electricity savings: {(Math.round(selectedOpp.electricity_savings_kwh_year * 100) / 100).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} m3/year
                                </Typography>
                            }
                            {
                                selectedOpp.has_gas && 
                                <Typography variant="subtitle2">
                                    Gas savings: {(Math.round(selectedOpp.gas_savings_units_year * 100) / 100).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} m3/year
                                </Typography>
                            }
                            <Typography variant="subtitle2">
                                GHG savings: {(Math.round(selectedOpp.ghg_savings_tonnes * 100) / 100).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} tonnes CO<sub>2</sub>eq/year
                            </Typography>
                            <Typography variant="subtitle2">
                                Financial Savings: ${(Math.round(selectedOpp.savings_financial * 100) / 100).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/year
                            </Typography>
                            
                            <br/>

                            <Typography variant="subtitle2" gutterBottom>
                                <strong>
                                    {
                                        selectedOpp.project_progress_status === 'complete' ? 
                                        'This project has been implemented'
                                        :
                                        selectedOpp.start_date != null ?
                                        `This project's implementation is scheduled to start on ${generateDateString(selectedOpp.start_date)}`
                                        :
                                        selectedOpp.end_date != null ?
                                        `This project's implementation is scheduled to be completed by ${generateDateString(selectedOpp.end_date)}`
                                        :
                                        'This project has not been assigned a start or completion deadline'
                                    }
                                </strong>
                            </Typography>

                            {/* <Typography variant="subtitle2" style={{color:'red', textDecoration:'underline', cursor:'pointer'}}>
                                Dismiss
                            </Typography> */}
                        </div>
                    }
                </div>
            </div>
        }
        </>
    )
}

export default OpportunityImpactGraph;