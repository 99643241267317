import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';

const columns = [
  { field: 'id', headerName: '', flex:1 },
  { field: 'title', headerName: 'Title', flex:10 },
  { field: 'cost', headerName: 'Project cost', flex:4 },
  { field: 'cofunding', headerName: 'Cofunding', flex:4 },
  { field: 'payback', headerName: 'Payback (years)', type: 'number', flex:1},
];

const rows = [
  { id: 1, title: 'Install controls on forklift overhead doors', cost: '$5,000', cofunding: '$0', payback: 2.41 },
  { id: 2, title: 'Reduce electricity consumption on heater panel', cost: '$2,000', cofunding: '$0', payback: 0.56 },
  { id: 3, title: 'Install timer to shut off mills at night', cost: '$1,000', cofunding: '$0', payback: 0.16 },
  { id: 4, title: 'Install smaller air compressor with timer to shut down at night', cost: '$5,000', cofunding: '$0', payback: 2.79 },
  { id: 5, title: 'Reduce air exchange by conditionally running wall exhausts', cost: '$1,000', cofunding: '$0', payback: 0.10 },
];

export default function ProjectTable() {
    return (
        <Paper style={{ height: 'fit-content', width: '100%', padding:'1%', boxShadow: '2px 6px 12px rgb(164, 164, 164)' }}>
            <header className="projects-header">
                <h4 className='tabletitle'>Conservation Projects</h4>
                <div className="count">| 5 Projects</div>
            </header>

            <DataGrid
                sx={{
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                     },
                    border:'none'
                }}
                rows={rows}
                columns={columns}
                initialState={{
                pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                },
                }}
                pageSizeOptions={[5, 10]}
                rowSelection={false}
            />
        </Paper>
    );
}
