import React, {useState, useEffect, useRef} from 'react';
import ListSlide from './slides/ListSlide';
import './InfoStyle.css';
import MetricsSlide from './slides/MetricsSlide';

const InfographicContainer = props => {
    const [slideCount, setSlideCount] = useState(0);
    const [slideTimer, setSlideTimer] = useState(0);
    var timerCount = useRef(0);
    var slideRef = useRef(0);

    useEffect(() => {
        const infographicTimer = setInterval(() => {
            if(timerCount.current >= 1000){
                slideRef.current = slideRef.current === 0 ? 1 : 0;
                setSlideCount(slideRef.current);
                timerCount.current = 0;
            } 
            else ++timerCount.current;
            setSlideTimer(timerCount.current * 0.001);

        }, 15);

        return () => {
            clearInterval(infographicTimer);
        };
    }, []);

    return(
        <div style={{width:'100vw', height:'100vh', overflow:'hidden'}} id={`${slideCount === 1 ? 'slide-bg1' : 'slide-bg0'}`}>
            <div style={{position:'absolute', width:'100vw', height:'1vh', backgroundColor:'rgba(0,0,0,0)'}}>
                <div style={{height:'1vh', width:`${100 * slideTimer}vw`, backgroundColor:'rgb(8,114,182)'}}></div>
            </div>

            {slideCount === 1 ? 
            <ListSlide/> : 
            <MetricsSlide/>}
        </div>
    );
}

export default InfographicContainer;