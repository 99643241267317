import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Button } from 'react-bootstrap';
import useAxiosProtect from '../../../hooks/useAxiosProtect';

const PropertiesTab = props => {
    const axiosProtect = useAxiosProtect();

    const integerRegex = /^(\d*)$/;
    const [projectProgress, setProjectProgress] = useState(Math.round(100 * props.opportunity.project_progress_percent));
    const [progressHelp, setProgressHelp] = useState('Percent complete (integer value from 0 - 100)');

    const validateProgress = () => {
        if(projectProgress === ''){
            setProgressHelp('Required');
            return false;
        }

        setProgressHelp('Percent complete (integer value from 0 - 100)');
        return true;
    }

    const updateProgress = () => {
        if(!validateProgress()) return;

        axiosProtect.put('/opp/opportunities/edit-opp', {
            id: props.oppID,
            fields: [
                {
                    column: 'project_progress_percent',
                    value: parseInt(projectProgress) / 100
                }
            ]
        }).then(response => {
            props.setOpportunity(prev => ({
                ...prev,
                project_progress_percent: parseInt(projectProgress) / 100
            }));

            props.setTabValue('table');
        }).catch(err => {
            setProjectProgress(Math.round(100 * props.opportunity.project_progress_percent))
        });
    }

    return(
        <div>
            <TextField
                label="Implementation status"
                id="outlined-prg-status-field"
                value={projectProgress}
                onChange={e => {
                    if(!integerRegex.test(e.target.value)) return;
                    let intValue = parseInt(e.target.value);
                    if(!isNaN(intValue) && intValue > 100) return;
                    setProjectProgress(e.target.value);
                }}
                sx={{ m: 1}}
                helperText={progressHelp}
                error={progressHelp === 'Required'}
                fullWidth
                InputProps={{
                    endAdornment: <InputAdornment position="start">%</InputAdornment>,
                }}
            />

            <Button style={{width:'100%', marginTop:'2%', backgroundColor:'rgb(8,114,182)', border:'none'}} onClick={() => {
                updateProgress();
            }}>Update</Button>
        </div>
    );
}

export default PropertiesTab;