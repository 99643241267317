import React from 'react';
import { Modal } from 'react-bootstrap';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';

const StatusModal = props => {
    return(
        <Modal
            show={props.statusModalOpen}
            onHide={() => props.setStatusModalOpen(false)}
            backdrop="static"
            centered
        >
            <Modal.Header closeButton>
                <h4 style={{fontSize:'1.2rem', margin:'0'}}>Status Definitions</h4>
            </Modal.Header>

            <Modal.Body>
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead style={{backgroundColor:'rgb(3,117,176)'}}>
                            <TableRow>
                                <TableCell style={{color:'whitesmoke'}}>Status</TableCell>
                                <TableCell style={{color:'whitesmoke'}}>Description</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Inactive</TableCell>
                                <TableCell>Default status upon registering an opportunity with an indeterminate or future implementation start date.</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>In progress</TableCell>
                                <TableCell>Default status for a project currently undergoing implementation.</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Not doing</TableCell>
                                <TableCell>Project that is not implemented, nor is the client planning on undertaking the initiative. Use this option if you wish to keep a record of the opportunity without deleting it.</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>On hold</TableCell>
                                <TableCell>Implementation steps have been taken, but the project's completion date is indeterminate.</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Complete</TableCell>
                                <TableCell>The project has been implemented and has started generating savings. <strong>This is the only status that will enable the opportunity to track savings.</strong></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Modal.Body>
        </Modal>
    );
}

export default StatusModal;