import React, { useEffect, useRef, useState } from 'react';
import UtilityGraphReport from '../graphs/UtilityGraphReport';
import ESLogo from '../../../media/logo.png';

const UtilityPage = props => {
    var init = useRef(true);
    let [dateRange, setDateRange] = useState({
        start: '',
        end: ''
    });

    const months = {
        "1": "January",
        "2": "February",
        "3": "March",
        "4": "April",
        "5": "May",
        "6": "June",
        "7": "July",
        "8": "August",
        "9": "September",
        "10": "October",
        "11": "November",
        "12": "December"
    }

    useEffect(() => {
        if(!init.current) return;
        init.current = false;

        let temp = [...props.utilities];
        temp.sort((a, b) => {
            if (a.year !== b.year) {
                return a.year - b.year;
            }

            return a.month - b.month;
        });

        setDateRange(prev => ({
            ...prev,
            start: `${months[`${temp[0].month}`]} ${temp[0].year}`,
            end: `${months[`${temp[temp.length - 1].month}`]} ${temp[temp.length - 1].year}`
        }));

        // console.log(temp[0])
        console.log(temp)
        // console.log(temp[temp.length - 1])
    }, []);  

    return(
        <div className="rmis-report-utility-page" style={{height:'8.5in', width:'11in', margin:'auto', backgroundColor:'white', padding: '1in 0.75in'}}>
            <div style={{height:'0.3in', width:'9.75in', position:'absolute', marginTop:'7in', display:'flex', flexDirection:'row'}}>
                <img src={ESLogo} style={{height:'100%', width:'auto'}}/>
                <div style={{flex:'10'}}></div>
                <p style={{margin:'0', flex:'1', fontSize:'0.9rem'}}>page {props.page + 1}</p>
            </div>
            
            <h4 style={{fontWeight:'bold', fontSize:'1.78rem', color:'rgb(8,114,182)'}}>{`${props.name}`} consumption.</h4>
            <br/>
            <h4 style={{fontSize:'1.3rem', color:'rgb(78,79,83)', fontWeight:'bold', marginBottom:'1rem'}}>{`${dateRange.start} - ${dateRange.end}`}</h4>

            <br/>

            <UtilityGraphReport baselineData={props.metrics} utilityReadings={props.utilities} isReport={false} isWater={props.page === 2} isElec={props.page === 3} isGas={props.page === 4} gasUnits={props.gasUnits}/>
        </div>
    );
}

export default UtilityPage;