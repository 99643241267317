import React, {useEffect, useState} from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const ScrollToTop = props => {
    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        let scrollPct = (window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 100;
        setIsVisible(scrollPct >= 5);
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return(
        <div style={{
            width:'3vw', height:'3vw', position:'fixed', right:'0', bottom:'0',
            zIndex: '1000', cursor:'pointer', margin:'0 1vw 2vw 0', borderRadius:'50%', justifyContent:'center',
            alignItems:'center', display:'flex', backgroundColor:'rgba(8,114,182, 0.2)', visibility:`${isVisible ? 'visible' : 'hidden'}`
        }}>
            <KeyboardArrowUpIcon style={{height:'2vw', width:'auto', color:'rgb(8,114,182)'}} onClick={() => {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }}/>
        </div>
    );
}

export default ScrollToTop;