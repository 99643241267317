import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Area, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, AreaChart } from 'recharts';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const KPICardDemo = props => {
    return(
        <Card sx={{ width:'20vw', boxShadow:'2px 6px 12px rgb(164, 164, 164)', marginBottom:'2%' }}>
            
            <CardMedia sx={{ height: 140 }}>
                <ResponsiveContainer height='100%' width='100%'>
                    <AreaChart data={props.data}
                    margin={{ top: 5, right: 0, left: 0, bottom: 0 }}>
                        <defs>
                            <linearGradient id={`${props.graphId}`} x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={props.graphColor} stopOpacity={0.8}/>
                            <stop offset="95%" stopColor={props.graphColor} stopOpacity={0}/>
                            </linearGradient>
                        </defs>
                        <XAxis hide dataKey="name" />
                        <YAxis hide />
                        <Tooltip wrapperStyle={{ outline: 'none' }}/>
                        <Area type="monotone" dataKey="use" stroke={props.graphColor} fillOpacity={1} fill={`url(#${props.graphId})`} />
                    </AreaChart>
                </ResponsiveContainer>
            </CardMedia>

            <CardContent>
                <Typography gutterBottom variant="h2" component="div">
                    <div style={{display:'flex', flexDirection:'row', width:'100%'}}>
                        <span style={{flex:'1', fontSize:'1.32rem'}}>{props.parameter}</span>
                        
                        {/* <div style={{flex:'1'}}></div> */}

                        <span style={{flex:'1', textAlign:'right', fontSize:'1.32rem'}}>
                            <strong style={{color:'rgb(8,114,182)'}}>
                                {props.value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </strong> <small style={{fontSize:'1.05rem'}}>{props.units}</small>
                        </span>
                    </div>
                
                </Typography>

                <Typography variant="body2" color="text.secondary">
                    <p style={{margin:'0'}}>
                        <strong style={{color:`${props.pctChange > 0 ? 'rgb(213,43,0)' : 'rgb(0,177,168)'}`}}>{props.pctChange > 0 ? <ArrowDropUpIcon style={{marginTop:'-0.5%'}}/> : <ArrowDropDownIcon style={{marginTop:'-0.5%'}}/>}
                        {Math.abs(props.pctChange)}%</strong> from yesterday
                    </p>
                    
                    {props.events === 0 ? <p style={{margin:'0'}}>No new events past 7 days</p> :
                    <p style={{margin:'0', fontWeight:'bold'}}>{`${props.events} new event${props.events === 1 ? '' : 's'} this week`}</p>}
                    
                </Typography>
            </CardContent>

            <CardActions>
                <Button size="small">Learn More</Button>
            </CardActions>
        </Card>
    );
}

export default KPICardDemo;