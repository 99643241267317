import React, { useEffect, useState } from 'react';
import useAxiosProtect from '../../hooks/useAxiosProtect';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import OpportunityTable from './OpportunityTable';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import OpportunityImpactGraph from './OpportunityImpactGraph';

const OpportunityListContainer = props => {
    const axiosProtect = useAxiosProtect();
    const [containerHeight, setContainerHeight] = useState(0);
    const [authIsDefined, setAuthIsDefined] = useState(false);
    const [sites, setSites] = useState([]);
    const [tabValue, setTabValue] = useState('list');

    useEffect(() => {
        const handleResize = () => {
            try{
                setContainerHeight(window.innerHeight - document.getElementsByClassName('navbar navbar-expand-lg navbar-light sticky-top')[0].offsetHeight);
            }catch(err){
                setContainerHeight(-1);
            }
        }

        handleResize();

        if(props.userData == null) return;
        if(props.userData === undefined) return;
        if(Object.keys(props.userData).length === 0) return;

        axiosProtect.get(`/auth/query/list-client-site-access?uid=${props.userData.id}`).then(async response => {
            setSites([...response.data.sites]);
            setAuthIsDefined(true);
        }).catch(err => {

        });
    }, [props.userData]);

    return(
        <div style={{backgroundColor:'whitesmoke', padding:'1vw', height:`${containerHeight < 0 ? 'fit-content' : `${containerHeight}px`}`}}>
            <Box sx={{width: '100%', backgroundColor:'rgba(255,255,255,0.4)'}}>
                <Tabs
                    value={tabValue}
                    onChange={(e, v) => setTabValue(v)}
                    sx={{
                        '& .MuiTabs-indicator': {
                            backgroundColor: 'rgb(0,177,168)'
                        },
                        '& .MuiTab-textColorSecondary.Mui-selected': {
                            color: 'rgb(0,177,168)'
                        },
                        '& .MuiTab-textColorSecondary': {
                            color: 'rgb(8,114,182)'
                        },
                    }}
                    aria-label="secondary tabs example"
                >
                    <Tab value="list" label="List" />
                    <Tab value="projects" label="Project Impacts" />
                </Tabs>
            </Box>
            {
                sites.map((site, i) => (
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <h4 style={{fontSize:'1.1rem'}}>{site.name}</h4>
                        </AccordionSummary>
                        <AccordionDetails>
                            {
                                tabValue === 'list' ? 
                                <OpportunityTable siteID={site.id} hideDelete={true}/>
                                :
                                <OpportunityImpactGraph siteID={site.id} adminView={false}/>
                            }
                        </AccordionDetails>
                    </Accordion>
                ))
            }
        </div>
    );
}

export default OpportunityListContainer;